.delivery-details {
    padding-top: $navbarHeight + 5px;
    position: relative;
    padding-bottom: 80px;
    height: 100%;
    overflow: auto;
    @include desktop {
        padding-top: 100px;
        max-width: $desktopContentWidth;
    }
    .map-open {
        a {
            color: #007bff !important;
        }
        .m-icon {
            margin-right: 3px;
            font-size: 1.2em;
            vertical-align: middle;
        }
    }
    // height: 100vh;
    .input-parent {
        padding: 0 15px 15px 15px;
        .l-icon {
            position: absolute;
            left: 28px;
            font-size: 22px;
            top: 13px;
            color: #bfbfbf;
        }
        input {
            font-size: 1.2em;
            // line-height: 50px;
            padding-left: 44px;
            height: 50px;
            box-shadow: 0px 8px 20px 5px rgba(0, 0, 0, 0.06);
            border: 1px solid #f7f7f7;
        }
    }
    .bottom-btns {
        background-color: white;
        // background-color: var(--body-background-color);
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 15px;
        .btn {
            border-radius: 5px;
        }
        @include desktop {
            // position: static;
            // margin-top: 1rem !important;
            // box-shadow: none;
            padding: 15px 80px;
        }
    }
    .date-time-box-parent {
        .inner {
            padding: 10px 10px 0 10px;
        }
        border-bottom: 1px solid #dadada;
        input {
            padding-left: 0;
            border: 0;
            border-radius: 0;
            font-size: 1em;
        }
    }

    .addressbox-parent {
        &:last-child {
            border-bottom: 1px solid $dividerColor;
        }

        input {
            height: auto;
        }
        input,
        select {
            border: 0px solid transparent;
            border-radius: 0;
            font-size: 1em;
            padding-top: 14px;
            padding-bottom: 14px;
            &:focus {
                border: 0;
                box-shadow: none;
            }
        }

        &.border-top {
            border-top: 1px solid $dividerColor;
        }
        &.border-bottom {
            border-bottom: 1px solid $dividerColor;
        }
    }
    @include desktop {
    }
}
.address-selector {
    // margin-bottom: 35px;
}

.delivery-details-popup {
    background-color: $modalBGColor;
    &.delivery-popup-loader {
        .modal-body {
            min-height: 70vh;
            @include desktop {
                min-height: 50vh;
            }

            &.pdp-loader {
                .loader-wrapper {
                    position: absolute;
                    top: 50%;
                    left: 50%;

                    .inline-loader {
                        right: 50%;
                        white-space: nowrap;
                        transform: translate(50%, -50%);
                        padding-right: 0;
                        &::after {
                            height: 30px;
                            width: 30px;
                            right: calc(50% - 15px);
                            top: -20px;
                        }
                    }
                }
            }
        }
    }
    .custom-radio label {
        padding-left: 30px;
    }
    .modal-dialog {
        width: 100% !important;
        @include desktop {
            width: 650px !important;
            min-width: 650px !important
        }

        .modal-content{
            @include desktop {
                min-height: 400px;
            }
        }
    }
    .modal-header {
        border-bottom: 0;
        padding: 50px 25px 0px !important;

        button {
            position: absolute;
            right: 10px;
            top: 16px;
        }
    }
    .modal-body {
        .logo-container {
            min-height: 20px;
            margin: 0 auto;
            width: 130px;
            height: 150px;
        }
        padding: 16px 25px !important;
        h6 {
            margin-bottom: 20px;
        }
        .form-control:focus {
            box-shadow: none;
            box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.06);
        }
        .address-selector-title {
            font-size: 14px;
        }
        .map-open {
            a {
                color: #283cd5 !important;
                padding-left: 0px;
            }
            .m-icon {
                color: var(--primary) !important;
            }
        }
        max-height: calc(100vh - 172px);
        overflow: auto;
    }
    .address-selector {
        margin-bottom: 60px;
    }
    .a-btn-container {
        position: absolute;
        // box-shadow: 0px -1px 5px rgb(0 0 0 / 12%);
        bottom: 0px;
        left: 0;
        right: 0;
        background-color: white;
        button {
            border-radius: 7px;
            padding: 10px 60px;
        }

        @include desktop {
            bottom: 10px;
        }
    }
    .modal-footer {
        border-top: none;
        // padding-top: 30px;
        button {
            border-radius: 7px;
            padding: 10px 60px;
        }
    }
    .icon-location-pin {
        width: 40px;
        height: 40px;
        background: rgba(var(--primary-rgb), 0.2);
        border-radius: 50%;
        line-height: 40px;
        font-size: 19px;
        display: inline-block;
        text-align: center;
        color: var(--primary);
        margin-bottom: 10px;
    }
    .input-parent {
        // padding: 0 15px 15px 15px;
        .l-icon {
            position: absolute;
            left: 13px;
            font-size: 22px;
            top: 13px;
            color: #bfbfbf;
        }
        input {
            font-size: 14px;
            // line-height: 50px;
            padding-left: 44px;
            height: 45px;
            // box-shadow: 0px 8px 20px 5px rgba(0, 0, 0, 0.06);
            border: 1px solid #dee2e6;
            border-radius: 7px;
        }
    }
    .bottom-btns {
        // background-color: white;
        // background-color: var(--body-background-color);
        // position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 15px;
        .btn {
            border-radius: 5px;
        }
        @include desktop {
            // position: static;
            // margin-top: 1rem !important;
            // box-shadow: none;
            // padding: 15px 80px;
        }
    }
    .date-time-box-parent {
        .inner {
            padding: 10px 10px 0 10px;
        }
        border-bottom: 1px solid #dadada;
        input {
            padding-left: 0;
            border: 0;
            border-radius: 0;
            font-size: 1em;
        }
    }
    .addressbox-parent {
        &:last-child {
            border-bottom: 1px solid $dividerColor;
        }

        input {
            height: auto;
        }
        input,
        select {
            border: 0px solid transparent;
            border-radius: 0;
            font-size: 1em;
            padding-top: 14px;
            padding-bottom: 14px;
            &:focus {
                border: 0;
                box-shadow: none;
            }
        }

        &.border-top {
            border-top: 1px solid $dividerColor;
        }
        &.border-bottom {
            border-bottom: 1px solid $dividerColor;
        }
    }
    .address-selector {
        .custom-radio {
            display: block;
            label {
                &:before {
                    width: 16px;
                    height: 16px;
                    left: 3px;
                    top: 3px;
                    border: 1px solid var(--primary);
                }
                &:after {
                    width: 10px;
                    height: 10px;
                    left: 6px;
                    top: 6px;
                }
            }
        }
    }

    &.no-top-img-modal{
        .logo-container{
            display: none;
        }
        .modal-header {
            button{
                z-index: 2;
            }
        }
        .modal-body{
            padding-top: 0 !important;
            margin-top: -30px;
        }
    }
}
