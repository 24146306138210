.referral-banner{
	text-decoration:none!important;
	position:relative;
	color:white;
	background: none;
	&:hover{
		color:white;
	}
	@extend %brand-gradient;
	// background: linear-gradient(#0097aa, #004f59)!important;
	display: block;
	img{
		// position: absolute;
		position: relative;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		width: 100%;
		height: auto;
		object-fit: cover;
		// opacity: 0.2;
	}


	//border: 2px solid white;
	h5{
		font-size:16px;
	}
	.ref-text{
		margin-bottom: 0!important;
		font-weight:normal;
		line-height: 1.3;
				margin-top: 7px;
				font-size:12px;
	}
	.dummy-link{
		display:none;
	}
}
