.ledger-green-cart-payment{
  .card-parent.entered{
    padding-right: 0 !important;
  }
  .license-text{
    line-height: 1.2;
    .sub-text{
      font-size: 12px;
      color: #717171;
      margin: 5px 5px 0 28px;
      line-height: 1.2;
      @include desktop(){
        margin-top: 0;
      }
    }
  }
  .photo-id-block{
    padding: 10px;
    background-color: #f5f5f5;
    .icon-u-card{
      font-size: 20px;
    }
    .my-file-input{
      label{
        border: 1px solid var(--primary);
        padding: 5px 8px;
        font-size: 12px;
        margin-top: 5px;
      }
    }
    .icon-check-circle{
      font-size: 20px;
      color: #4CAF50;
    }
    .img-container{
      img{
        width: 40px;
      }
      margin-top: 5px;
      padding: 0;
      background-color: #f5f5f5;
    }
  }
  .card-list{
    .custom-radio{
      .edit-btn{
          border: 1px solid var(--primary);
          padding: 5px 8px;
          font-size: 12px;
      }
    }
  }
  .id-upload .medical-id-block{
    padding: 0;
  }
  .ledger-green-view-inner{
    .confirm{
      .modal-body{
        position: relative;
        .modal-title{
          position: absolute;
          top: -30px;
          left: 25px;
        }
      }
    }
  }
  .payment-radio-parent {
    .sub-text{
      color: #717171;
      font-size: 12px;
    }
  }
}
.ledger-green-popup{
  align-items:end;
  @include desktop{
    align-items: center;
  }
  .modal-dialog{
    margin: 0;
    width: 500px;
    max-width: 100%;
    max-height: 90vh;
    overflow: auto;
    border-radius: 15px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    @include desktop{
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }
  .modal-content{
    padding: 0 15px;
    max-height: 90vh;
    overflow: auto;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    @include desktop{
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    // &::-webkit-scrollbar {
    //   width: 5px;
    // }
    // &::-webkit-scrollbar-track {
    //   background: #ccc; 
    // }
    // &::-webkit-scrollbar-thumb {
    //   background: #888; 
    // }
    // &::-webkit-scrollbar-thumb:hover {
    //   background: #555; 
    // }
  }
  .modal-header{
    font-size: 16px;
    padding: 0;
    border: none;
    padding-top: 15px;
    .small-text{
      font-size: 12px;
      margin-top: -3px;
      display: block;
    }
  }
  .top-sticky{
      position: sticky;
      top: 0;
      background: #fff;
      z-index: 1;
    .close-btn{
      top: 8px;
      right: -10px;
    }
  }
  .bottom-sticky{
    position: sticky;
    bottom: 0px;
    background: #fff;
    box-shadow: 0px -4px 9px 0px rgba(0, 0, 0, 0.1);
    margin-left: -15px;
    margin-right: -15px;
    padding: 0 15px 15px;
    p{
      padding-top: 8px;
      margin-bottom: 8px;
    }
  }
  .form-group{
    .custom-checkbox label{
      &:before{
        border-color: var(--primary);
        top: 5px;
      }
      &:after{
        top: 12px !important;
      }
    }
  }
  .ledger-green-agreement{
    font-size: 13px;
    color: #444;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 15px;
  }
  .clear-btn{
    border: none;
    display: flex;
    margin-left: auto;
  }
  .sig-pad{
    height: 220px;
    max-width: 100%;
  }
  .close-btn{
    position: absolute;
    top: 5px;
    right: 5px;
  }
  .sign-container{
    border: 2px dotted #CCCCCC;
    border-radius: 15px;
    cursor: crosshair;
  }
}