.product-page-popup {
	background-color: $modalBGColor;
	.modal-dialog {
		// padding-top: 50px;
		top: 5px;
		max-height: 100%;
		@include desktop {
			width: 1000px;
			max-width: 100%;
		}

		.modal-content {
			@include notDesktop() {
				// border:none;
				// border-radius: 0px;
			}
		}
	}
	.modal-body {
		height: calc(100vh - 66px);
		padding: 15px;
		overflow: auto;
		position: relative;
		&.pdp-loader {
			.loader-wrapper {
				position: relative;
				top: 50%;
				.inline-loader {
					right: 50%;
					white-space: nowrap;
					transform: translate(50%, -50%);
					padding-right: 0;
					&::after {
						height: 30px;
						width: 30px;
						right: calc(50% - 15px);
						top: -20px;
					}
				}
			}
		}
		&.loader-height-adjust {
			height: calc(100vh);
			@include desktop {
				height: calc(100vh - 66px);
			}
		}

		.fresh-drop {
			margin-top: 40px;
			@include desktop() {
				margin-top: 50px;
				margin-left: -20px;
			}
		}
	}
	.close-btn {
		position: absolute;
		right: 0;
		z-index: 3;
		font-size: 14px;
		color: grey;
		top: 5px;
		&.loader {
			font-size: 1rem;
			color: #212529;
			right: -5px;
			top: 10px;
		}
	}
	.product-page {
		padding-top: 0 !important;
		padding: 0;
		padding-bottom: 60px;
		.inner {
			@include desktop {
				margin-top: 0 !important;
			}
		}
		.h-list-parent .scroll-btn.left {
			left: -10px;
		}
		.h-list-parent .scroll-btn.right {
			right: -10px;
		}
		@include desktop {
			padding-bottom: 0;
		}
		.img-grid-carousel {
			@include desktop {
				padding-top: 1rem;
			}
		}
	}
	.delivery-tabs {
		top: -15px;
		@include desktop {
			margin-left: -15px;
			margin-right: -15px;
		}
	}
}
