.benefit-carousel-wrapper{
  margin-bottom: 35px;
  // overflow: hidden;
  padding: 15px;
  background-color: #fff;
  border-radius: 12px;
  position: relative;
  box-sizing: border-box;
  color: #616161;
  background: linear-gradient(to right, #403E94, #CB54B1);
  background-clip: padding-box;
  border: solid 1px transparent;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    margin: 2px;
    border-radius: 10px;
    background: linear-gradient(90deg, rgba(153, 72, 182, 0.1) -0.22%, rgba(253, 171, 95, 0.1) 99.78%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
  }
  @include desktop{
    margin-bottom: 20px;
    overflow: hidden;
  }
  // .benefit-carousel-main{
  //   &:has(> :last-child:nth-child(3)) .benefit-carousel{
  //     width: 33%;
  //   }
  //   &:has(> :last-child:nth-child(2)) .benefit-carousel{
  //     width: 50%;
  //   }
  // }
  .benefit-carousel{
    flex: 1;
    @include ipad{
      justify-content: center !important;
    }
    i{
      font-size: 22px;
      background: linear-gradient(90deg, #403E94 -0.22%, #CB54B1 99.78%),
      linear-gradient(0deg, #FFFFFF, #FFFFFF);
      ;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .carousel-text{
      p{
        line-height: 1.2;
      }
      span.bold{
        color: #333;
      }
    }
    .one-icon-present{
      i{
        font-size: 36px;
      }
      &+.carousel-text p{
        text-align: left !important;
      }
    }
  }
  .benefit-carousel.gradient-border{
    border-left: 1px solid;
    border-image: linear-gradient(270deg, #403E94 0%, #CB54B1 100%)1;
  }
  .carousel{
    overflow: visible;
    .slide{
      background: none;
    }
    .benefit-carousel .carousel-text span.bold{
      color: #616161;
    }
    .control-dots {
      // margin: 8px 0px 0px 0px;
      position: absolute;
      bottom: -35px;
      margin: 0;
      .dot {
        margin: 0px 8px 0px 0px;
        width: 8px;
        height: 8px;
        opacity: 0.75;
        display: inline-block;
        background: #C9CFD5;
        border-radius: 19px;
        cursor: pointer;
        transition: opacity 0.3s, background-color 0.3s, width 0.3s;
        transition-delay: 0.3s, 0.3s, 0s;
        box-shadow: none;
        &.selected {
          background: var(--primary);
          width: 24px;
          opacity: 1;
          transition-delay: 0s;
        }
      }
    }
  }
  .sphere-carousel{
    width: 80px;
    height: 85px;
    background: linear-gradient(306.7deg, #3BB5C8 2.98%, #7DD9D3 82.57%);
    position: absolute;
    border-radius: 50%;
    top: 0;
    right: -50px;
  }
  .confetti{
    position: absolute;
    width: 40px;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }
  .confetti-mobile{
    right: 0px !important;
    width: 100% !important;
    top: 0px !important;
    transform: rotate(0deg) !important;
  }
  
  .lottie-address{
    position: absolute;
    right: -15px;
    top: -25px;
    width: 110px;
    transform: rotate(270deg);
  }
  .lottie-address-desktop{
    position: absolute;
    top: -55px;
    width: 300px;
    &.one{
      transform: rotate(45deg);
      left: -125px;
    }
    &.two{
      transform: rotate(315deg);
      right: 50%;
      transform: translate(50%);
    }
    &.three{
      transform: rotate(270deg);
      right: -155px;
    }
  }
}