.faq {
    padding: 0 10px;
    padding-top: calc(var(--navbar-height) + 20px);
    // height: 100%;
    // overflow: auto;
    line-height: 1.8;
    font-size: 15px;
    margin-bottom: 4rem;
    @include desktop {
        max-width: $desktopContentWidth;
        height: auto;
        margin: 0 auto;
    }
		@include min-height-desktop;
    .accordion {
        border-radius: 0;
        border: none;
        .panel {
            padding-left: 10px;
            padding-right: 10px;
        }
        .accordion-item {
            border-bottom: 1px solid $dividerColor;
            .title {
                border-radius: 0;
                background-color: #F2F2F2;
                padding: 10px;
                padding-right: 30px;
                position: relative;
            }
            &.active {
                .title {
                    background-color: #f2f2f2;
                }
                .panel {
                    padding: 10px;
                    overflow: auto;
                }
            }
        }
    }
    .accordion .accordion-item .title:after {
        font-family: "icomoon" !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        right: 6px;
        top: 13px;
    }
    .accordion .accordion-item .title:after {
        content: "\e93a" !important;
        transform: rotateZ(90deg);
        font-size: 20px;
    }

    .accordion .accordion-item.active .title:after {
        transform: rotateZ(270deg);
    }
}
.faq-container{
	background-color: #fff;
	padding: 30px 0 36px;
    @include desktop{
        padding: 30px 0 60px;
    }
    .more-btn{
        margin-top:40px;
        font-size: 14px;
        button{
          background-color: transparent;
          border: none;
          color: rgb(0, 0, 0);
          padding: 0px;
          text-decoration: underline;
        }
    }
    .faq-wrapper{
        @include desktop{
            display: grid;
            column-gap: 25px;
            row-gap:20px;
            grid-template-columns: 1fr 1fr;
            padding: 10px;
        }
    }
    .faq-wrapper-hidden{
        display: none;
    }
    .seeMore{
        display: grid;
    }
    .faq-qna{
        border: 1px solid rgb(84 85 65 / 11%);
        border-radius:10px;
        margin-bottom: 25px;
        padding:24px;
        @include desktop{
            margin-bottom: 0px;
        }
        .faq-quest{
            // background: #ececec;
            // padding: 15px 10px;
            font-size: 16px;
            font-size: 18px;
            margin-bottom: 10px;
            p{
                margin-bottom: 0px;
                font-weight:bold;
            }
        }
        .faq-ans{
            p{
                margin-bottom: 0px;
            }
            // padding: 5px 10px;
        }
    }
	h2{
		margin-bottom: 30px;
		font-size: 18px;
        @include desktop{
            margin-bottom: 47px;
            font-size: 30px;
        }
	}
	.accordion{
		background-color: #F2F2F2 !important;
		border: none;
		border-radius:10px ;
        .accordion-item{
            position: relative;
        }
		.title{
			border: 1px solid #DDDDDD;
			padding: 9px 9px 9px 27px;
			border-radius: 0px !important;
			font-size: 14px;
			font-weight: 400;
			background-color: #F2F2F2 !important;
            @include desktop{
                padding: 11px 44px;
                font-size: 20px;
            }
		}
		.title :after{
			content: "\f107" !important;
			font-size: 13px !important;
			color: #000 !important;
			float: left !important;
			margin-right: 14px !important;
		}
		.active .title{
			background-color: #F2F2F2 !important;
			
		}
		.active .title :after{
			content: "\f106" !important;
		}
		.panel{
			border-left: 1px solid #DDDDDD;
			border-right: 1px solid #DDDDDD;
			border-radius: 0px !important;
			font-size: 14px;
			font-weight: normal;
            p{
				padding: 7px 13px;
				margin-bottom: 0 !important;
			}
            @include desktop{
                font-size: 16px;
    			font-weight: 500;
                p{
                    padding: 16px 19px;
                }
            }
			
		}
        
	}
    .accordion-item .title:after {
        font-family: "icomoon" !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        left: 11px;
        top: 7px;
        float: left;
        margin-left: 0px !important;
        margin-right: 7px !important;
        margin-top: 5px !important;
        color: #000000;
        @include desktop{
            // font-size: 16px;
            left: 16px;
            top: 13px;
        }
    }
    .accordion-item .title:after {
        content: "\e93a" !important;
        transform: rotateZ(90deg);
        font-size: 16px;
        @include desktop{
            font-size: 20px;
        }
    }

    .accordion-item.active .title:after {
        transform: rotateZ(270deg);
    }
    .accordion :first-child {
        .accordion-item .title{
            border-radius: 10px 10px 0 0 !important;
        }
    }
    .accordion :last-child {
        .accordion-item .title{
            border-radius: 0 0 10px 10px !important;
        }
        .active .title{
            border-radius: 0 !important;
        }
        .active .panel{
            border-bottom: 1px solid #ddd;
            border-radius: 0 0 10px 10px !important;
        }
    }
}
// .faq-container .accordion :first-child {
//     .accordion-item .title{
//         border-radius: 10px 10px 0 0 !important;
//     }
// }
// .faq-container .accordion :last-child {
//     .accordion-item .title{
//         border-radius: 0 0 10px 10px !important;
//     }
// }
