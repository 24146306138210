.delivery-type-toggle-v2 {
  display: flex;
  vertical-align: sub;
  // transition:
  //   opacity 300ms,
  //   transform 300ms;
  // background: #ebebeb;
  border-radius: 8px;
  padding-left: 0px !important;
  padding: 5px !important;
  width: 100%;
  @include desktop {
    // margin-left: 31px;
    background: transparent;
    border-radius: 8px;
    padding: 5px !important;
    display: flex !important;
    margin-left: -80px;
    width: max-content;
  }
  .btn {
    // width: 50%;
    min-width: 40%;
    min-height: 36px;
    padding: 12px 14px;
    // font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    color: #000 !important;
    border-radius: 8px !important;
    border: unset !important;
    text-align: center;
    cursor: pointer;
    @include desktop {
      min-height: 42px;
      min-width: 200px;
      font-size: 14px;
      height: unset !important;
      border-radius: 8px !important;
      padding: 10px 16px;
      font-weight: 400;
      font-size: 16px;
    }
    @include real-iphone5 {
      padding: 5px 8px;
    }
    i {
      color: #ffffff !important;
      display: none;
      visibility: hidden;
      font-size: 13px;
      @include real-iphone5 {
        display: none !important;
      }
    }
    &.d-selected {
      color: var(--white-text-on-primary) !important;
      background-color: var(--secondary);
      border-radius: 0 8px 8px 0 !important;
      // -webkit-transition: all 0.3s ease;
      // transition: all 0.3s ease;
      // font-size: 14px;
      // padding: 5px 16px;
      @include desktop {
        // font-size: 16px;
        border-radius: 0 8px 8px 0 !important;
      }
      i {
        display: inline-block;
        visibility: visible;
      }

      &:last-child,
      &:first-child {
        span {
          font-weight: 600;
        }
      }
      .font-normal-delivery-date {
        font-weight: 400 !important;

        @media (max-width: 370px) {
          margin-left: -10px;
        }
      }
      .icon-down {
        color: #ffffff;
      }
    }
    &:not(.not-selected) {
      display: inline-block;
    }
    &:first-child {
      border-radius: 8px 0 0 8px !important;
      flex: none;
      @include desktop {
        border-radius: 8px 0 0 8px !important;
      }
    }
    &:last-child {
      flex-grow: 1;
      border-radius: 0px 8px 8px 0px !important;
      @include desktop {
        border-radius: 0 8px 8px 0px !important;
      }
    }
    &:focus {
      box-shadow: none;
    }
  }
  .schedule-tab {
    min-width: 210px;

    @media (max-width: 370px) {
      min-width: auto;
    }
  }
  .only-schedule {
    &.d-selected {
      color: #000000 !important;
      background-color: var(--body-background-color) !important;
      span {
        color: #000000 !important;
      }
    }
    // display: none !important;
    @include desktop {
      min-width: 410px !important;
    }
    .delivery-timing-text {
      color: #000000 !important;
    }
  }
}

.asapcounter {
  background: #dcd9d9;
  border: unset;
  border-radius: 8px;
  display: none;
  // background: #ebebeb;
  border: 1px solid var(--secondary);
  padding: 5px 6px;
  @include desktop {
    margin-left: 31px;
    display: flex;
    align-items: center;
    flex-direction: row;
    // padding: 10px 16px;
    border-radius: 8px !important;
    background-color: transparent;
    border: 1px solid var(--secondary);
    padding: 5px 0;
    padding-right: 6px;
  }
  .counterheader {
    min-width: 94px;
    text-align: center;
    padding: 10px 8px 10px 8px;
    color: #000;
    @include desktop {
      // padding: 0px 8px 0px 12px;
      min-width: 200px;
      padding: 10px 16px;
    }
    span {
      line-height: 15px;
      color: #000;
    }
    .counter {
      line-height: 15px;
      // color: var(--primary-dark);
      font-variant-numeric: tabular-nums;
      margin-top: 5px;
      span {
        font-weight: 400;
      }
    }
  }
  .scheduled-tab {
    line-height: 17px;
    color: #ffffff !important;
    // background: var(--primary);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    padding: 10px 16px;
    border-radius: 39px;
    min-width: 80px;
    font-variant-numeric: tabular-nums;
    min-height: 36px;
    text-align: center;
    background-color: var(--secondary);
    i {
      color: #ffffff;
      font-size: 14px;
      margin-right: 5px;
    }
    @include desktop {
      min-height: auto;
      // padding: 8px 16px;
      min-width: 235px;
      border-radius: 0 8px 8px 0 !important;
      padding: 12px 16px;
      font-weight: 400;
      font-size: 16px;
    }
  }

  &.special {
    display: flex;
    border-radius: 8px;
    background-color: transparent;
    border: 1px solid var(--secondary);
    .counterheader {
      min-width: 45%;
      @include desktop() {
        min-width: 50%;
      }
      @media (max-width: 370px) {
        min-width: 43%;
      }
    }
    .scheduled-tab {
      min-width: 55%;
      border-radius: 0 8px 8px 0 !important;
      @include desktop() {
        min-width: 50%;
      }
      @include mobile {
        padding-left: 5px;
      }
      @media (max-width: 370px) {
        min-width: 56.5%;
      }
    }

    @include mobile {
      padding: 5px;
    }
  }
}

.d-timing {
  margin-left: 0px;
  @include desktop {
    margin-left: 12px;
    column-gap: 12px;
  }
  .next-slot {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #999999 !important;
    // margin-right: 12px;
    .next-slot-time {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #616161 !important;
    }
  }
  .time-table {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: var(--primary) !important;
    text-decoration: underline;
    cursor: pointer;
  }
  @include ipadPro {
    flex-direction: column;
    align-items: flex-start;
  }
  @include ipadProLandscape {
    flex-direction: column;
    align-items: flex-start;
  }
  @include ipadLandscape {
    flex-direction: column;
    align-items: flex-start;
  }
}
