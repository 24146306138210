.otp-verify {
  background-color: $modalBGColor;
  .modal-content {
    min-height: 100vh;
    @include desktop{
      min-height: 300px;
    }
    .modal-header {
      justify-content: flex-start;
      align-items: center;
      border:none;
      h5 {
        margin: 0;
        margin-top: -1px;
      }
    }
    .modal-body{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      .otp-wrapper{
        display: flex;
        justify-content: center;
        div{
          margin-top: 0 !important;
        }
        input{
          width: 40px;
          margin: 0 0.5rem;
        }
      }
      .button-section{
        display: flex;
        .timer{
          border: 1px solid #e5e5e5;
          font-variant-numeric: tabular-nums;
        }
        .btn-primary{
          .loader{
            span{
              background-color: $primary;
            }
          }
        }

      }
    }
  }
  .loader{
    top:-3px;
  }
}
