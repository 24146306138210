
.wishlist{

  // background-color: burlywood;
  padding-top: calc(var(--navbar-height) + 5px) !important;
  .title{
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }
  .wishlist-tabs{
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    background-color: transparent;
    border:none;
    padding: 8px 16px;
    color: #616161;
   
  }
  .active-tab{
    border-bottom: 2px solid var(--primary);
    font-weight: 700;
  }
  .no-product-img{
    // width: 200px;
    height: 185px;
  
  }
  .special-container[class*="recommended"] {
    margin-top: 16px !important;
    margin-bottom: 1rem;
    @include desktop{
      margin-bottom: 3rem;
    }
    .deals-caption {
      .deals-title {
        font-size: 24px;
        @include desktop() {
          font-size: 43px !important;
        }
        span{
          font-size: 26px;
        }
      }
    }
  }
  .no-data{
    font-weight: bold !important;
}
.gd-secondary-banner {
  margin-bottom: 3rem;
}
 
}
.reco-products{

  padding: 0px 15px;
  .you-may-like, .saved-for-later{
    cursor: pointer;
    background-color: #ffff;
    border-radius: 20px;
    border:none;
    color:#333333;
    border: 1px solid #333333;
    padding: 8px 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    &:disabled{
      color: #999999;
      border:1px solid #999999;
      cursor: default;
    }
  }
  .active-tab{
    background-color: var(--primary);
    color:#ffffff;
    border: none;
  }
  
}