$radioSideLength: 22px;
$radioInnerLength: 12px;
$radioBorderColor: #b3b3b3;

.custom-radio {
    position: relative;
    display: inline-block;

    // & + .custom-radio{
    //   margin-left:20px;
    // }
    input {
        display: none;
        &:checked + label {
            &:after {
                transform: rotateZ(-45deg) scale(1);
            }
            &:before {
                transform: scale(1.1);
                border: 2px solid $primary;
                border: 2px solid var(--primary);
            }
        }
    }
    label {
        cursor: pointer;
        padding-left: $radioSideLength + 6px;
        display: inline-block;
        vertical-align: middle;
        line-height: $radioSideLength;
        position: relative;
        &:before {
            content: "";
            position: absolute;
            width: $radioSideLength;
            height: $radioSideLength;
            border: 2px solid $radioBorderColor;
            //   box-shadow: 1px 1px 2px 0px #c7c7c7;
            left: 0;
            box-sizing: border-box;
            border-radius: 50%;
            transform: scale(0.9);
            transition: 0.3s all cubic-bezier(0.41, 0.71, 0.7, 1.52);
        }
        &:after {
            content: "";
            position: absolute;
            width: $radioInnerLength;
            height: $radioInnerLength;
            background-color: $primary;
            background-color: var(--primary);
            border-radius: 50%;
            transform: scale(0);
            top: 5px;
            left: 5px;
            transition: 0.3s all cubic-bezier(0.41, 0.71, 0.7, 1.52);
        }
    }
    &.joined-style {
        label {
            margin: 0;
            padding-left: 0;
            padding: 10px;
            border-radius: 0;
            border: 1px solid var(--primary);
            width: 100%;
            text-align: center;
            font-size: 12px;
            font-weight: 500;
            height: 100%;
            line-height: 1.4;
            display: flex;
            justify-content: center;
            align-items: center;
            &:before,
            &:after {
                display: none;
            }
            &.joined-style-checked {
                background: var(--primary);
                color: white;
            }
        }
        input {
            &:checked + label {
                background: var(--primary);
                color: white;
            }
        }

        &:first-child {
            label {
                border-radius: 5px 0 0 5px;
            }
        }
        &:last-child {
            label {
                border-radius: 0px 5px 5px 0;
            }
        }
    }
}

.joined-radio-parent {
    display: flex;
    & > * {
        flex: 1;
    }
}
