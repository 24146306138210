.deals{
	$modalMargin:1rem;
	.close-btn{
		position: absolute;
    right: 5px;
    top: 3px;
    padding: 5px;
    z-index: 2;
    color: white;
	}
	.deal-header{
		margin-bottom: 2rem;
		position: absolute;
		top: 0;
		z-index: 1;
		width: 100%;
		&:before{
			content: "";
			display: block;
			height: 26px;
			position: absolute;
			bottom: -5px;
			left: -15px;
			right: -15px;
			background: inherit;
			transform: rotate(-2deg);
			z-index: -1;
			@include tab{
			bottom: -6px;
				height: 30px;
     transform: rotate(-1deg);
			}
		}
	}
	.add-btn-fix{
		position: relative;
		top:1px;
	}
	.shop-full-menu{
		min-height:auto;
		padding:0;
	}
	.image-holder{
		width:300px;
	}
	.product{
	}
	.p-parent{
		padding:7.5px;
	}
	.modal-body{
		max-height: 645px;
		overflow: auto;
		overscroll-behavior: contain;
		overflow-x: hidden;
		background-color: #fdfdfd;
		padding: 0.5rem;
		padding-top: 100px;
		@include iphone5{
			max-height: 500px;
		}
		@include tab{
			max-height:80vh;
		}
	}
	.modal-dialog{
		display: flex;
    align-items: flex-end;
		min-height: 100%;
		margin:	$modalMargin;
		@include tab{
			align-items: center;
			margin:auto;
		}
	}
	.modal-content{
		overflow: hidden;
	}
	.info-btn {
		position: absolute;
		top: 7px;
		@include desktop {
			top: 8px;
		}
		right: 8px;
		padding: 0px;
		width: 20px;
		height: 20px;
		font-size: 0.8em;
		line-height: 20px;
		border-radius: 50%;
		background-color: white;
		@extend %increase-touch-area;
		pointer-events: all;
	}
	.product .price-container {
		color: #fff;
		border-radius: 3px;
		padding: 1px 5px;
		background: #fff;
		background: var(--primary);
		position: absolute;
		top: 3px;
		font-size: .9em;
	}
	.modal-footer{
		position: fixed;
    bottom: 0px;
    right: $modalMargin;
    left: $modalMargin;
    padding: 0;
		border-top: 0;
		@include tab{
			position: absolute;
			bottom: 10px;
		}
	}
	.proceed-btn{
		padding:8px;
		width:100%;
		@include tab{
			width:300px;
		}
	}
	.product-container{
		// padding-bottom: 65px;
	}
	.iphone-fix{
		height:90px;
		@include iphone6{
			height:130px;
		}
	}
	 .updating-cart-loader{
		 .inner{
			 padding-top: 2px;
		 }
			z-index:999;
	 }
	  .p-btn{
			margin: 3px 0 5px 0;
			border-radius: 6px;
		}

	 .add-btn{
		//position: absolute;
    //top: -5px;
    //right: -10px;
    //width: auto;
    //margin: 0;
		//border: 0;

    //border-top-right-radius: 12px;
    //padding: 8px 11px 5px;
    //font-size: 18px;
		//border-bottom-left-radius: 12px;
		//background-color:transparentize($primary,0.25);
    //background-color: rgba(var(--primary-rgb), 0.25);
		//color:darken($primary,0.75);
		//color:var(--primary-dark-5);
		//&:focus{
			//box-shadow: none;
		//}
	 }

	 //.added-btn{
		//background-color: rgba(244,67,54,0.25);
    //color: #F44336;
	 //}


	//.add-btn:hover,.add-btn:active{
				//background-color:transparentize($primary,0.25);
				//background-color: rgba(var(--primary-rgb), 0.25);
				//color:darken($primary,0.75);
				//color:var(--primary-dark-5);
	 //}
	 //.added-btn:hover,.added-btn:active{
		//background-color: rgba(244,67,54,0.25);
    //color: #F44336;
	 //}

	 @include notDesktop{
		 .add-btn:hover,.add-btn:active{
			 color:$primary;
			 color: var(--primary);
			 background-color:transparent;
		 }
		 .added-btn:hover,.added-btn:active{
			 color: #6c757d;
			 background-color:transparent;
		 }
	 }

	 .deal-heading {
		margin-top: 20px;

	 }

}
