.product {
  p {
    // line-height: 1.2;
    line-height: 1.2;
    // max-height: 33px;
    height: auto;
    color: #333;
    @include desktop {
      line-height: 1.1;
      // height: 40px;
    }
  }

  .info-btn {
    position: absolute;
    top: 8px;

    @include desktop {
      top: 16px;
    }

    right: 8px;
    padding: 0px;
    width: 20px;
    height: 20px;
    font-size: 18px;
    line-height: 20px;
    border-radius: 50%;
    // background-color: white;
    @extend %increase-touch-area;
    pointer-events: all;
  }

  .sold-out {
    padding-top: 50px;
    @include desktop {
      padding-top: 55px;
    }
  }

  .sold-out-label {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: var(--primary);
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 5px;
    color: white;
    text-align: center;
    height: auto !important;
    // font-weight: bold;
    font-size: 0.7rem;
    display: block;
  }

  .thc-cbd,
  .weight-container {
    font-size: 14px;
    @include iphone5 {
      font-size: 1em;
    }
  }
  .weight-container {
    font-size: 12px;
    color: #999;
  }
  .thc-cbd {
    .value {
      color: var(--primary);
      font-size: 12px;
      font-weight: bold;
    }
    .title {
      font-size: 9px;
      font-weight: normal;
      color: #999;
    }
  }
  .product-name-weight {
    font-size: 13px;
    font-weight: bold;
    text-align: left;
    // color: #333 !important;
    .product-name {
      // color: #333 !important;
    }
  }
  .brand-name {
    text-align: left;
    color: #333;
    font-size: 13px;
    font-weight: normal;
    font-family: Roboto;
    u {
      text-decoration: none;
    }
  }
  .strain-container {
    font-size: 14px;
    color: #000;
    padding: 2px 5px;
    border-radius: 3px;

    /* border: 1px solid $primary; */
    //background: $primary;
    //background: var(--primary);
    // font-size: 0.9em;

    &.mobile {
      display: inline-block;
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
      height: auto !important;
      width: auto;
    }
  }
  &.deals-product {
    // text-align:left;
    p {
      margin-top: 10px;
      font-size: 16px;
      height: 40px;
      margin-bottom: 3px;

      @include tab {
        height: 38px;
      }
    }

    .inner {
      padding-top: 25px;
    }

    height: 100%;
    padding: 5px 10px !important;
    background-color: white;
    //box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
    //border:1px solid $dividerColor;
    border-radius: 12px;
    box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.07);

    .img-wrapper {
      height: 130px;

      @include desktop {
        height: 180px;
      }

      img {
        object-fit: contain;
        margin: 0 auto;
        height: 100%;
      }
    }
    margin-right: 0;
  }

  .sold-out {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 75%;
    margin: auto;
    height: 100%;
    object-fit: contain;
  }

  .weight-container {
    // color: #4c4c4c;
    margin-top: 3px;
  }

  .price-container-static {
    .price {
      font-size: 18px;
      vertical-align: middle;
      @include desktop {
        font-size: 24px;
      }
    }
    .strike-text {
      vertical-align: middle;
      font-size: 14px;
    }
    .strike-text:after {
      // background-color: rgba(255, 99, 71, 0.6);
      background-color: var(--primary);
      transform: translateY(-50%) rotate(0deg);
    }

    @include iphone5 {
      font-size: 16px;
    }
  }

  .added-btn {
    border-color: $dividerColor;
  }

  .price-container {
    // padding: 1px 0px;
    // color: var(--primary);
    position: absolute;
    top: 4px;
    font-weight: 500;
    .discount {
      color: rgb(255, 127, 104);
    }
    .strike-text {
      margin-left: 3px;
      vertical-align: middle;
      font-size: 14px;

      &:after {
        transform: translateY(-50%) rotate(0deg);
        background-color: #555;
      }
    }
    font-size: 17px;
    @include desktop {
      top: 0px;
      font-size: 19px;
    }
  }

  .price-container {
    // font-weight: 500;
    // font-size: 1em;
  }

  .sale {
    position: absolute;
    top: 30px;
    left: 0;
    z-index: 1;
    color: rgb(255, 255, 255) !important;
    background: rgb(255, 127, 104);
    border-radius: 3px;
    padding: 4px 5px;
    // padding: 3px 3px 3px 0;
    line-height: 1;
    // background-color:transparentize($color: #fff, $amount: 0.14);
    @include desktop {
      top: 35px;
    }
  }

  .buy-more-text {
    height: auto !important;
    // padding: 3px 3px 3px 0;
    text-align: left;
    font-size: 13px;
    position: absolute;
    top: 38px;
    left: 0;
    z-index: 1;
    // background-color: rgba(255, 255, 255, 0.86);

    @include desktop {
      top: 40px;
      font-size: 15px;
    }
  }
  .product-img-custom-padding {
    padding-top: 50px;
    @include desktop {
      padding-top: 55px;
    }
  }
  .link-wrapper {
    text-decoration: none;
    display: block;
  }
  .new-add-to-cart {
    position: absolute;
    right: 5px;
    top: 5px;
    .add-to-cart {
      position: relative;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
      .icon-plus {
        position: absolute;
        left: 50%;
        top: 50%;
        font-size: 8px;
        transform: translate(-50%, -50%);
      }
      .c-icon {
        position: relative;
        // top: 1px;
        // left: 0px;
        // font-size: 20px;
        top: 3px;
        left: -1px;
        font-size: 18px;

        @include desktop {
          top: 3px;
          left: 0px;
        }
      }
      &.opacify {
        border: 1px solid var(--primary);
        span {
          opacity: 0.1;
        }
        .icon-plus {
          display: none;
        }
      }
      &.hide-button {
        visibility: hidden;
      }
			.inner-icon{
			}
			padding: 2px 4px;
				width: 32px;
				height: 32px;
				border-radius: 50%;
				// border:1px solid var(--primary);
			@include desktop{
				//padding: 4px 8px;
				// width: 45px;
				// height: 45px;
				//box-shadow: 0 0 0 1px #13c7cd;
				.c-icon{
					// font-size: 20px;
					//left: 1px;
				}
			}
			&:focus{
				 box-shadow: 0px 2px 4px rgba(0 ,0 ,0,0.16);
			}
		}
    .wishlist-btn{
      &:focus{
        outline: none;
        box-shadow: none;
      }
    }
	}
	box-shadow: 0px 2px 10px 5px rgb(0 0 0 / 2%);
  transition:box-shadow 0.3s ease-in, transform 0.3s ease-in;
  &:hover{
    @include desktop{
      transform: scale(1.01);
      box-shadow: 0px 2px 15px 10px rgb(0 0 0 / 5%)
    }
  }
  box-shadow: 0px 2px 10px 5px rgb(0 0 0 / 2%);
  transition: box-shadow 0.3s ease-in, transform 0.3s ease-in;
  &:hover {
    @include desktop {
      transform: scale(1.01);
      box-shadow: 0px 2px 15px 10px rgb(0 0 0 / 5%);
    }
  }
  // border-radius:9px;

  &.skeleton-product {
  }
  .product-space-between-container {
    height: 100%;
    /* background: #c2c2c2; */
    display: flex;
    flex-direction: column;
    /* align-content: space-between; */
    justify-content: space-between;

    .bottom-container {
      .xy-product-offer {
        color: var(--primary);
        margin-bottom: 4px;

        .icon-offer-tag {
          font-size: 14px;
        }

        a {
          color: inherit;
          font-size: 15px;
          padding-left: 6px;
          display: inline-block;
          text-decoration: underline;
        }
      }
    }
  }
  .offer-holder {
    .sale,
    .buy-more-text {
      position: static;
    }
    position: absolute;
    top: 30px;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .xy-product-offer {
    &.product-list-section {
      text-align: center;
      font-size: 15px;
      text-transform: uppercase;
      margin-top: 15px;
      margin-bottom: 10px;
      color: var(--primary);
      font-weight: bold;
      letter-spacing: 0.8px;

      .icon-offer-tag {
        margin-right: 5px;
      }

      a {
        text-decoration: underline;
        font-size: 14px;
      }
    }
  }
}

img.sold-out {
  //display:none;
}
.asap {
  display: inline-block;
  background-color: #ffb100;
  margin-bottom: 5px;
  color: #fff;
  font-size: 10px;
  padding: 4px;
  font-weight: bold;
  border-radius: 3px;
  // i{
  //   margin-left: -6px;
  //   position: relative;
  //   font-size: 12px;
  // }
  span {
    letter-spacing: 1px;
  }
}

.v-list {
  // margin-top: 18px !important;
  .product {
    margin-right: 0 !important;
  }
}

.best-seller {
  text-transform: uppercase;
  color: #ff9800 !important;
  font-weight: bold;
  font-size: 12px;
  // letter-spacing: 0.6px;
  &:before {
    content: "\e9ac";
    font-family: "icomoon" !important;
    margin-right: 3px;
  }
}
