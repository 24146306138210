.vana-category-menu {
  --border-color: #e3e4e5;
  --caption-text: #3a4148;
  --dark-purple: #51284f;


  .gc-inner  {
    // margin: 0 -15px;
    border-radius: 10px;
    // padding-left:15px;
    // padding-right: 15px;
    display: grid;
    grid-gap: 10px;
    background-color: transparent;
    border-bottom:0px solid $dividerColor;
    box-shadow: 0px 0px 2px 0px var(--border-color);
    grid-template-columns: 100%;

    @include desktop {
      grid-template-columns: 190px calc(100% - 190px);
    }
  }

  .category-title {
    h3 {
      color: var(--dark-purple);
    }
  }

  .category-item {

    .h-list-parent {
      .scroll-btn{
        background-color: transparent!important;
      }
      button{
        display: none!important;
      }
      .h-list {
        padding: 17px 5px;
      }
    }
    

    .d-item {
      @include desktop{
        flex: 0 0 103px;
      }
      flex: 0 0 90px;
      
      padding: 0;

      a {

        .outer-circle {
          @include desktop{
            min-width: 90px;
            min-height: 130px;
          }
          display: block;
          background-image: none;
          min-height: 120px;
          min-width: 80px;
          border-radius: 7px;
          // box-shadow: 0px 0px 2px 0px var(--border-color);        
          padding-bottom: 15px;
          position: relative;
          padding: 0 0 15px 0;
          img{
            padding: 15px;
          }
          

          .figcaption {
            color: var(--caption-text);
            text-align: center;
          }

          .new-tag {
            position: absolute;
            bottom: -10px;
            transform: translateX(-50%);
            left: 50%;
            background: #ff6d6a;
            padding: 2px 8px;
            border-radius: 10px;
            font-size: 10px;
            color: white;
            text-transform: uppercase;
            letter-spacing: 1px;
          }

        }
      }
     &.c-selected {
       &:after{
         content: none;
       }
       .outer-circle{
        box-shadow: 0px 0px 0px 2px var(--primary)!important;
       }
     }
    }
  }

}