.schedule-slot-popup{
  z-index: 9999;
  background-color: $modalBGColor;
  .custom-radio label{
    margin-bottom: 12px;
  }
  .modal-content{
    max-height: -webkit-fill-available;
  }

  .modal-dialog{

    @include desktop(){
      max-width: 660px;
      width: 660px;
    }

    .modal-body{
      border: 1px solid #F3F3F3;
      // margin: 20px;

      @include desktop(){
        border: none;
        margin: 0;
      }

      .time-slot-holder{
        [class^="col-"]{
          padding-bottom: 15px;

          @include desktop(){
            padding-right: 8px;

            &:nth-child(2n){
              padding-left: 8px;
              padding-right: 15px;
            }
          }

          .time-slots{
            border: 1px solid #efefef;
            padding: 0 10px;
            height: 45px;
            line-height: 41px;
            border-radius: 4px;
            font-size: 13px;
            position: relative;

            &.custom-radio{
              width: 100%;

              label{
                margin-bottom: 0;

                &:after{
                  top: 15px;
                }

                .discount{
                  right: 0;
                }
              }
            }

            label{
              display: flex;
              height: 100%;
              flex-direction: column;
              line-height: 1.4;
              justify-content: center;

              @include desktop(){
                justify-content: space-between;
                align-items: center;
                flex-direction: row;
              }
            }

            .discount{
              background: rgba(var(--primary-rgb), 0.2);
              padding: 0 8px;
              border-radius: 4px;
              color: var(--primary);
              font-weight: 600;
              font-size: 11px;
              height: 22px;
              line-height: 23px;
              position: absolute;
              right: 10px;
            }

            .delivery-fee{
              color: #999999;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}