.gift-card {

  & +.footer{
    .footer-bottom-section.footer-bottom-section{
      padding-bottom: 50px!important;
      @include desktop {
        padding-bottom: 12px!important;
      }
    }
  }
  // --primary: #35bec7;
  padding-bottom: 0px;
  .gc-box{
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0,  0.07);
    border: 1px solid #d8dee3;
    border-radius: 5px;
    padding: 15px;
    @include desktop{
      padding: 22px;
    }
  }
  .banner {
    // background: var(--primary);

    .container {
      display: flex;
      align-items: center;
      padding: 0 15px;
      color: white;
      height: auto;
      justify-content: center;
      flex-direction: column;
      margin-top: 30px;

      .below-image{
        font-size: 1.2rem;
        @include desktop{
          font-size: 1.4rem;
          margin: 20px 0;
        }
      }

      @include desktop() {
        // height: 245px;
        margin-top: 15px;
        // padding-left: 8rem;
      }

      .main-logo {
        width: 150px;
        margin-bottom: 25px;
        display: block !important;
        img{
          width: 100%;
        }
        @include desktop() {
          width: 150px;
        }
      }

      h2 {
        color: var(--primary);
        font-size: 2rem;
        width: 88%;
        line-height: 47px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 1rem;
        // animation-name: example;
        // animation-duration: 1s;
        // position: relative;
      }
      // @keyframes example {
      //   from {
      //     opacity: 0;
      //     top: 50px;
      //   }
      //   to {
      //     opacity: 1;
      //     top: 0px;
      //   }
      // }

      p {
        font-size: 1.5rem;
        width: 100%;
        text-align: center;
        line-height: 26px;
      }

      .card-images {
        width: 220px;
        position: relative;
        min-height: 280px;
        margin-top: 35px;

        @include desktop() {
          width: 350px;
          min-height: 420px;
        }

        @media (max-width: 320px) {
          width: 170px;
        }

        img {
          width: 100%;
          border-radius: 10px;
          position: absolute;

          &:nth-of-type(1) {
            transform: rotate(-20deg);
            left: 3rem;
            top: 5rem;
            box-shadow: 7px 7px 20px 0px rgba(0, 0, 0, 0.28);
            @include desktop() {
              left: 7rem;
              top: 7rem;
            }
          }

          &:nth-of-type(2) {
            transform: rotate(10deg);
            left: -3rem;
            box-shadow: 20px 17px 20px 5px rgba(0, 0, 0, 0.28);
            @include desktop() {
              left: -6rem;
            }
          }
        }
      }
    }

    .img-container {
      max-width: 66px;
      max-height: 66px;
      margin-right: 15px;

      @include desktop() {
        max-height: 139px;
        max-width: 139px;
        margin-right: 55px;
      }

      img {
        width: 100%;
      }
    }

    .text-container {
      padding-top: 25px;

      h3 {
        margin-bottom: 5px;
        font-weight: bold;

        @include desktop() {
          font-size: 40px;
        }
      }

      p {
        @include desktop() {
          font-size: 20px;
        }
      }
    }
  }

  .slider-selected-container {
    // padding: 0;
    // display: flex;
    // flex-direction: column-reverse;
    padding-bottom: 25px;

    @include desktop() {
      // flex-direction: row;
      // padding: 0 15px;
      // justify-content: space-between;
    }

    .sliders-wrapper {
      @include desktop() {
        border-right: 1px solid #E6E6E6;
        padding-right: 0;
      }
    }

    .slider-container {
      overflow: hidden;
      // padding: 0 0px 0 15px;
      margin-bottom: 15px;

      @include desktop() {
        // padding: 0;
        // margin-bottom: 0;
      }

      .slider {
        display: flex;
        padding-bottom: 20px;
        margin-bottom: 5px;
        flex-wrap: nowrap;
        overflow: auto;
        padding-top: 7px;
        padding-right: 10px;
        padding-left: 6px;

        @include desktop() {
          flex-wrap: wrap;
          padding-right: 0;
        }
      }

      &.card-styles {
        .card-style {
          min-width: 141px;
          height: 100%;
          margin-right: 10px;
          border-radius: 7px;
          position: relative;
          display: flex;
          cursor: pointer;

          &.selected {
            &:after {
              content: "";
              position: absolute;
              border: 2px solid var(--primary);
              border-radius: 11px;
							top: -5px;
							right: -5px;
							bottom: -5px;
							left: -5px;
            }
          }

          @include desktop() {
            margin-right: 15px;
            margin-bottom: 20px;
            min-width: 151px;
            height: 110px;
          }

          &:last-of-type {
            margin-right: 10px;

            @include desktop(){
              margin-right: 0px;
            }
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 7px;
          }

        }

      }

      &.amount {
        .slider {
          padding-left: 0;

          @include desktop() {
            padding-left: 6px;
          }
        }
        .card-amount {
          min-width: 93px;
          height: 44px;
          background: white;
          margin-right: 10px;
          border-radius: 7px;
          border: 1px solid #D3D3D3;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          color: #333333;
          font-weight: bold;
          cursor: pointer;

          &.selected {
            border: 2px solid var(--primary);
          }

          @include desktop() {
            margin-bottom: 10px;
            min-width: 98px;
          }

          &:last-of-type {
            margin-right: 0;
          }
        }
      }

      p {
        margin-bottom: 8px;
        font-size: 20px;
        color: #333333;
        font-weight: bold;

        @include desktop() {
          padding-left: 6px;
        }
      }

    }
    .gift-card-slider{
      white-space: nowrap;
      overflow: auto;
      padding: 10px;
      padding-bottom: 15px;
      margin-left: -5px;
      .gift-card-style{
          width: 141px;
          height: 93px;
          margin-right: 10px;
          border-radius: 7px;
          position: relative;
          cursor: pointer;
          display:inline-block;
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 7px;
          }
          &.selected{
            &:after{
              content: "";
              position: absolute;
              border: 2px solid var(--primary);
              border-radius: 11px;
							top: -5px;
							right: -5px;
							bottom: -5px;
							left: -5px;
            }
          }
      }
      @include desktop() {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 1fr 1fr 1fr;
        margin-left: 0;
        .gift-card-style{
          width: 166px;
          height: auto;
          margin-right: 0;
          width:100%;
        }
      }
    }

    .form-container {
      // padding: 0 15px;

      @include desktop() {
        padding: 0 14px 0 6px
      }

      label {
        font-size: 14px;
        color: #333333;
        font-weight: bold;
        margin-bottom: 7px;
      }

      input {
        border: 1px solid #ced4da;
        background: #fcfdfe;
        min-height: 50px;


        &#phoneNumber {
          padding-left: 70px;
        }
      }

      .field-container {
        display: flex;

        select {

          margin-right: 0;
          display: block;
          color: #a09fa0;
          position: absolute;
          top: 29px;
          line-height: 38px;
          border: 0;
          width: 70px;
          background: transparent;
          -webkit-appearance: none;
          min-height: 50px;
          padding-right: 0;
          @include desktop() {

            width: 65px;

          }
          &:focus{
            box-shadow:none;
          }
        }
      }
    }
    .preview {
      margin-bottom: 8px;
      font-size: 20px;
      color: #333333;
      font-weight: bold;
    }
    .selected-style-wrapper {
      // width: 100%;
      // padding: 0 15px 20px;

      @include desktop() {
        // padding: 0;
        // width: 48%;
        // padding-top: 2rem;
      }



      .selected-style {
        border-radius: 7px;
        min-height: 220px;
        display: flex;
        position: relative;
        padding-top: 7px;
        margin-bottom: 25px;
        @include desktop() {
          min-height: 310px;
          margin-bottom: 10px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 7px;
        }
      }

      .price {
        font-size: 25px;
        position: absolute;
        bottom: 10px;
        left: 25px;
        font-weight: bold;
        color: white;
      }

      .checkout-btn-holder{
        display: flex;
        justify-content: center;
        margin-top: 25px;

        @include notDesktop(){
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          height: auto;
          z-index: 2;
          // pointer-events: none;
          background-color: #f3f3f3;
          padding: 15px;
          justify-content: flex-end;
          margin-top: 0;
          box-shadow: -1px -3px 8px 0px rgba(0, 0, 0, 0.05);
        }

        .checkout-btn {
          width: 50%;
        }
      }
    }
  }

  .top-left {
    align-items: flex-start;
    justify-content: flex-start;
  }

  .top-center {
    align-items: flex-start;
    justify-content: center;
  }

  .top-right {
    justify-content: flex-end;
  }

  .mid-left {
    align-items: center;
    justify-content: flex-start;
  }

  .mid-center {
    align-items: center;
    justify-content: center;
  }

  .mid-right {
    align-items: center;
    justify-content: flex-end;
  }

  .bottom-left {
    align-items: flex-end;
  }

  .bottom-center {
    align-items: flex-end;
    justify-content: center;
  }

  .bottom-right {
    align-items: flex-end;
    justify-content: flex-end;
  }

  .sticky-btn {
    position: sticky;
    bottom: 0;
    display: flex;
    padding: 8px 0;
    background:white;

    .price{
      .new-price{
        font-size: 1.5em;
        margin-right: 0.3em;
        line-height: 25px;
        vertical-align: middle;
      }
    }
  }

}

@media only screen and (min-width: 1140px) {
  .gift-card .slider-selected-container .slider-container.card-styles .card-style {
    min-width: 166px;
  }
}

.gift-card-detail {
  width: 100%;

  .title {
    font-size: 16px;
  }

  .detail-container {
    border: 1px solid var(--primary);
    border-radius: 10px;
    padding: 0rem 0rem 0.5rem;
    position: relative;

    @include desktop() {
      padding: 1rem 1rem 0.5rem;
    }

    .details {
      padding: 0 15px;

      @include desktop() {
        padding: 0;
      }
    }

    img {
      width: 100%;
      object-fit: contain;
      border-radius: 9px 9px  0px 0px;
      display: block;
      margin: auto;
      top: -0.5px;
      position: relative;

      @include desktop() {
        border-radius: 10px;
      }
    }

    .label {
      margin: 0 0 5px;
      color: #8f8f8f;
    }

    .field-container {
      margin-bottom: 0.6rem;
      .label {
        margin: 0;
        color: #8f8f8f;
      }
      .value {
        margin: 0;
      }
    }

    .price-container {
      position: absolute;
      top: 0px;
      right: 10px;
      font-size: 1.5rem;
      color: var(--primary);
      font-weight: 500;

      i {
        margin-right: 7px;
      }
    }
  }

}

.gift-modal {
  position: relative !important;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  margin: auto;



  .modal-content {
    transform: translateX(-50%) translateY(50%);
    left: 50%;
    width: 95%;
    padding: 1rem;
    @media only screen and (min-width: 768px) {
      transform: translateX(-50%) translateY(0%);
      width: 80%;
    }
  }

  .modal-body {
    text-align: center;
    padding-bottom: 0;

    .title {
      font-size: 16px;
      font-weight: bold;
      margin: 20px 0 3px;
    }

    .subtitle {
      font-size: 14px;
      margin: 0;
      color: gray;
    }

    .balance-container {
      border: 1px solid var(--primary);
      border-radius: 7px;
      padding: 0.5rem;
      position: relative;
      margin-top: 1rem;

      .balance {
        justify-content: space-between;

        .redeemed {
          color: #212529;
        }

        .amount {
          color: var(--primary);
        }
      }
    }
  }
}
.from-redeem {
  .balance-container {
    border: none !important;
    padding: 0.75rem 1rem !important;
    margin-top: 10px !important;
  }
  .subtext{
    font-weight: bold;
  }
  .redeem-hide-text{
    display: none !important;
  }
}


.hidesearch {
  .s-sec {
    display: none !important;
  }
}

.fade-in-bottom {
	-webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

          &.delay {
            animation-delay: 0.1s;
          }
}

 @-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

//card 2 animation
.fade-card2 {
	-webkit-animation: fade-card2 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-card2  0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
          animation-delay: 0.7s;
}

@-webkit-keyframes fade-card2  {
  0% {
    -webkit-transform: translateY(50px) rotate(10deg);
            transform: translateY(50px) rotate(10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotate(10deg);
            transform: translateY(0) rotate(10deg);
    opacity: 1;
  }
}
@keyframes fade-card2  {
  0% {
    -webkit-transform: translateY(50px) rotate(10deg);
            transform: translateY(50px) rotate(10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotate(10deg);
            transform: translateY(0) rotate(10deg);
    opacity: 1;
  }
}

//card 1 animation
.fade-card1 {
	-webkit-animation: fade-card1 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-card1  0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
          animation-delay: 0.9s;
}

@-webkit-keyframes fade-card1  {
  0% {
    -webkit-transform: translateY(50px) rotate(-20deg);
            transform: translateY(50px) rotate(-20deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotate(-20deg);
            transform: translateY(0) rotate(-20deg);
    opacity: 1;
  }
}
@keyframes fade-card1  {
  0% {
    -webkit-transform: translateY(50px) rotate(-20deg);
            transform: translateY(50px) rotate(-20deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotate(-20deg);
            transform: translateY(0) rotate(-20deg);
    opacity: 1;
  }
}

.gift-faq {
  padding-top: 25px;
  .faq-acord-icon {
    position: absolute;
    right: 25px;
  }

  .rc-collapse {
    background-color: transparent;
    border: none;

    .rc-collapse-content {
      padding-left: 0;
       background-color: transparent;
    }
  }

  .rc-collapse > .rc-collapse-item > .rc-collapse-header {
    padding-left: 0;
  }

}

.gc-redeem{
  .gc-redeem-box{
    margin-bottom: 120px;
  }
  .label-text {
    font-size: 18px;
    color: #333333;
    font-weight: bold;
  }
}


