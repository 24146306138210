$modalMargin: 0rem;

.search-product {
  .filter-btn-mobile {
    align-self: stretch;
    border-bottom: 1px solid $dividerColor;
    display: flex;
    align-items: center;
    padding-left: 5px;
  }

  .row.inner {
    margin-top: 25px;
  }

  .img-grid-carousel {
    .image-video-grid .c-thumbs-parent {
      width: 60px;

      .c-thumb {
        width: 40px;
        height: 40px;
      }
    }
    @include desktop {
      .image-video-grid {
        height: 330px;
      }
    }
  }

  &.u-product-popup {
    background-color: $modalBGColor;

    .modal-body {
      overflow: auto;
    }
  }

  &.algo-search {
    background-color: $modalBGColor;
    @include tab {
      display: flex;
      align-items: center;
    }
    .modal-dialog {
      display: block;
      min-height: auto;
      top: 0;
      @include tab {
        width: 800px;
        top: auto;
      }
    }

    .modal-body {
      overflow: auto;

      @include desktop {
        overflow: hidden;
      }
    }
  }

  &.product-page-parent {
    .modal-header {
      box-shadow: 0 1px 10px #ededed;
      z-index: 3;
      background-color: #fff;
      padding: 10px 10px;
    }

    background-color: $modalBGColor;

    .h-list-parent .scroll-btn.left {
      left: -10px;
    }

    .h-list-parent .scroll-btn.right {
      right: -10px;
    }

    .inner {
      margin-top: 0 !important;
    }

    .delivery-tabs.sticky-menu {
      top: 0;
      margin: 0 -15px;
    }

    .img-grid-carousel .image-video-grid {
      height: 400px;
    }

    .product-page {
      padding-top: 0px !important;
    }

    .product-page .img-container {
      width: 80%;
    }

    .modal-body {
      // padding-top: 15px;
      overflow: auto;
    }

    .img-grid-carousel {
      padding-top: 15px;
    }
  }

  .search-btn {
    font-size: 20px;
    padding: 0 10px;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .close-btn {
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .category-list-holder {
    padding: 15px;
  }

  .c-item-holder {
    margin: 0 -6px;
  }

  .category-pill-btn {
    border: 1px solid $dividerColor;
    margin: 6px 6px;
    padding: 10px 20px;
    border-radius: 4px;
  }

  .product-description {
    margin-bottom: 4rem;

    @include desktop {
      margin-bottom: 1rem;
    }
  }

  .product-page {
    @include desktop {
      padding-bottom: 40px;
    }
  }

  .modal-body {
    //  overflow: auto;
    //  @include desktop{
    // 	 overflow:hidden;
    //  }
    // overscroll-behavior: contain;
    // overflow-x: hidden;
    // background-color: #fdfdfd;
    padding: 0rem;
    height: calc(100vh - 66px);

    @include desktop {
      height: 80vh;
    }
  }

  .modal-dialog {
    display: flex;
    align-items: flex-end;
    min-height: 100%;
    margin: $modalMargin;

    @include tab {
      //align-items: center;
      margin: 0 auto;
    }
  }
  .modal-content {
    overflow: hidden;
    // max-height: 100%;
    // overflow: auto;
  }

  .modal-header {
    // background-color: #f3f3f3;
    border-bottom: 0;
  }

  .modal-header .close {
    font-size: 2rem;
    padding: 10px 15px;
  }

  .search-box-parent {
    min-height: 56px;
    padding: 0 15px;
  }

  .ais-SearchBox {
    margin: 0;
    border: 1px solid $dividerColor;
    //position: sticky;
    //top:0;
    //z-index: 1;
    background: white;
    border-radius: 5px;
    // position: sticky;
    top: 0px;
    z-index: 1;

    .ais-SearchBox-submit {
      left: 0.5rem;
    }

    .ais-SearchBox-reset {
      right: 0.5rem;
    }

    input {
      border: 1px solid transparent;
      outline: none;
      padding: 10px;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    ::-webkit-input-placeholder {
      /* Edge */
      color: #e8e8e8;
      font-weight: bold;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #e8e8e8;
      font-weight: bold;
    }

    ::placeholder {
      color: #e8e8e8;
      font-weight: bold;
    }
  }

  .ais-Hits {
    // border-top: 1px solid $dividerColor;
    .ais-Hits-list {
      margin: 0;
      flex-direction: column;
      flex-wrap: nowrap;
    }

    .ais-Highlight-highlighted,
    .ais-Snippet-highlighted {
      background-color: transparent;
      color: var(--primary);
      font-style: normal;
      font-weight: bold;
    }

    .ais-Hits-item {
      margin: 0;
      width: 100%;
      padding: 15px 10px;
      //margin-bottom: 0.5rem;
      border: 0;
      //border-bottom: 1px solid #f1f1f1;
      cursor: pointer;
      box-shadow: none;
      position: relative;
      padding-left: 70px;

      @include desktop {
        padding-left: 100px;
      }

      &:hover {
        background: #f3f3f3;
      }

      &:last-child {
        margin-bottom: 80px;
      }

      img {
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
        width: 60px;
        height: 60px;
        object-fit: contain;

        @include desktop {
          width: 70px;
          height: 70px;
          left: 15px;
        }
      }

      .bull {
        font-size: 30px;
        line-height: 16px;
        vertical-align: sub;
        color: grey;
      }

      .hit-item-right-sec {
      }

      .hit-name {
        word-break: break-word;

        span,
        em {
          font-size: 14px !important;
        }
      }

      .hit-category,
      .hit-brand,
      .weight {
        span,
        em {
          font-size: 11.2px;
        }

        font-size: 11.2px;
      }

      .info-btn {
        position: absolute;
        top: 12px;
        right: 0;
        padding: 3px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        font-size: 12px;
      }
    }
  }

  .filter-container {
    .filter-box-parent {
      width: 100%;
    }

    .f-inner {
      border-bottom: 1px solid $dividerColor;
    }

    //
    padding-top: 10px;
    // margin-left: -15px;
    // margin-right: -15px;
    padding-right: 10px;
    // position: sticky;
    top: 53px;
    background: white;
    z-index: 1;

    @include desktop {
      margin-top: 0px;
      padding-left: 0px;
      margin-left: 0px;
      margin-right: 0px;
      top: 0px;
      padding-top: 0;
      // padding-bottom: 10px;
    }
  }

  .filter-container + .no-results {
    // border-top: 1px solid $dividerColor;
  }

  .filter-items-holder {
    .f-left-sec {
      flex-basis: 300px;

      @include desktop {
        padding-right: 10px;
      }

      // border-right: 1px solid lightgray;
      // border-left: 1px solid lightgray;
      .f-close-btn {
        display: none;
      }

      .sidebarBanner {
        padding: 8px 9px;
        border-bottom: 1px solid $dividerColor;
      }

      .ais-ClearRefinements-button {
        color: $primary;
        color: var(--primary);
        font-size: 14px;
      }

      .title {
        font-size: 14px;

        @include desktop {
          font-size: 16px;
        }
      }
    }

    .f-right-sec {
      flex: 1;

      // padding: 0 15px;
      @include desktop {
        padding: 0;
      }
    }

    .f-left-sec .menu-list,
    .f-right-sec .ais-Hits {
      height: calc(100vh - 175px);

      @include desktop {
        height: 68vh;
      }

      padding: 0 10px;
      overflow: auto;
      overscroll-behavior: contain;
    }

    .f-left-sec .menu-list {
      @include desktop {
        padding-bottom: 2rem;
      }
    }
  }

  .ais-ClearRefinements {
    button {
      background: transparent;
      font-size: 16px;
    }
  }

  .ais-Hits {
    padding-top: 10px;
  }

  .rc-collapse-item:last-child {
    margin-bottom: 50px;
  }

  // .ais-Hits,.menu-list{
  // 	height: calc(100vh - 185px);
  //   overflow: auto;
  // }

  .product-page {
    padding-top: 0 !important;

    .img-container {
      width: 100%;
      height: 350px;
      flex: 1;
    }
    .video-container {
      flex: 1;
    }
  }
}

.search-product-page {
  & + .footer {
    display: none !important;
  }

  //padding: 15px;
  padding-top: $navbarHeight + 15px;

  //height: 100%;
  //overflow: auto;
  @include desktop {
    //max-width: $desktopContentWidth;
    //margin:auto;
    //height: auto;
    // padding-bottom: 150px;
  }

  @include min-height-desktop;

  // .sticky-container{
  // 	position:sticky;
  // 	top:$navbarHeight+15px;
  // 	z-index:1;
  // 	background:white;
  // }
}

.menu-list {
  .rc-collapse {
    .rc-collapse-item > .rc-collapse-header {
      font-weight: 400;
    }

    .rc-collapse-item:last-child {
      margin-bottom: 0px !important;
    }
  }
}
