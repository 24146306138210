.referral {
  padding: 0px 15px;
  padding-top: calc(var(--navbar-height));
  padding-bottom: 50px;
  //height: 100%;
  //overflow: auto;
  background-color: #f5f6fa;
  &.in-app-referral {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  &.in-app {
    padding: 0px;
  }
  .banner-ref {
    background-color: var(--primary);
    height: 216px;
  }
  .referral-block {
    max-width: 1140px;
    margin: 30px auto;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    @include notDesktop {
      margin-bottom: 26px;
      max-width: 720px;
      flex-direction: column;
    }
    .cust-name {
      // padding: 0 15px;
      flex: 50%;
      margin: auto 0;
      @include desktop {
        padding: 0;
      }
    }
    .reward-balance {
      border-radius: 15px;
      display: flex;
      // padding: 0 15px;
      // align-items: center;
      // position: absolute;
      // top: 7%;
      // left: 0;
      // right: 0;
      // height: auto;;
      @include desktop {
        position: relative;
        justify-content: center !important;
        padding: 0;
      }
      p {
        font-size: 12px;
        // width: 110px;
        color: #666;
        // margin-right: 0px !important;
        // @include desktop{
        //   margin-right: 20px !important;
        // }
      }
      .btn {
        border-radius: 10px;
        // width: 140px;
        justify-content: center;
      }
      .giftcard-balance,
      .giftcard-referrals {
        background: #fff;
        padding: 5px 5px 5px 0px;
        border: 1px solid rgba(5, 160, 168, 0.2);
        border-radius: 5px;
      }
      .giftcard-referrals-disabled {
        opacity: 0.5;
        cursor: default;
        box-shadow: none;
      }
      .giftcard-balance,
      .giftcard-referrals {
        i {
          font-size: 24px;
          vertical-align: middle;
        }
      }
    }
  }
  .referral-inner {
    max-width: $desktopContentWidth;
    @include desktop() {
      max-width: 1140px;
    }
    margin: auto;
    height: auto;
    // padding-bottom: 150px;
    border-radius: 20px;
    // margin-top: -216px;
    background-color: #fff;
    padding: 25px;
    @include notDesktop {
      margin: 0 auto;
      padding: 15px 15px;
    }
    .referral-gift-card {
      // box-shadow: -5px 0px 30px #00000029;
      border-radius: 10px;
      padding: 25px 0;

      [data-type-error-recipientFirstName="true"] {
        border-color: #f44336;
      }
      [data-type-error-email="true"] {
        border-color: #f44336;
      }
      [data-type-error-phone="true"] {
        border-color: #f44336;
      }

      .carousel-form-wrapper {
        .carousel-wrapper {
          width: 100%;
          flex-direction: column;
          @include desktop {
            margin-right: 40px;
            width: 50%;
          }
          .category-item {
            img {
              border-radius: 10px;
              margin-right: 10px;
            }
            .h-list {
              padding: 20px 0 10px 0;
            }
            button {
              top: 70px;
            }
            .left {
              left: -35px;
            }
            .right {
              right: -35px;
            }
          }
          .carousel-root {
            .thumbs-wrapper {
              margin: 20px 0 0;
              ul {
                padding: 0;
                margin: 0;
              }
              .thumb {
                border: none;
                //   padding: 5px;
                //   vertical-align: middle;
                //   width: 33% !important;
                &:hover {
                  border: none;
                }
              }
            }
            .control-dots {
              display: none;
            }
            .control-arrow:hover {
              background: none;
            }
            img {
              border-radius: 10px;
            }
          }
          .selected-giftcard-img {
            margin-bottom: 20px;
            img {
              width: 100%;
              border-radius: 10px;
            }
          }
        }
        .form-container {
          flex: 100%;
          @include desktop {
            flex: 35%;
          }
          .mini-label {
            font-size: 14px;
          }
        }
      }
      .form-container {
        @include notDesktop {
          margin: 15px auto 0;
        }
        .referral-flex-container {
          display: flex;
          flex-wrap: wrap;
          div {
            // padding: 0px 5px 0px 0px;
            margin: auto;
          }
          input {
            width: 100%;
            @include desktop {
              width: 75%;
            }
          }
          .phone-no {
            flex: 100%;
          }
          .email-id {
            flex: 100%;
          }
          .age-check {
            flex: 100%;
            text-align: left;
            padding-top: 7px;
            .custom-checkbox {
              // margin: 1rem auto 1rem 0;
              margin-left: 0px;
              padding: 0;
              margin-top: 2px;
              @include desktop {
                margin-top: 1rem;
              }
            }
          }
          .send-btn {
            padding-top: 20px;
            margin: 0;
            width: 30%;
            button {
              width: 100%;
              font-size: 18px;
              font-weight: 500;
              padding: 10px 30px;
            }
          }
          .submit-icon {
            padding: 8px 12px;
            width: auto;
            // margin: 7px 0;
            i {
              font-size: 24px;
              position: relative;
              top: 3px;
            }
          }
          @include notDesktop {
            div {
              padding: 8px 0px 0px 0px;
            }
            .phone-no,
            .email-id {
              flex: 100%;
            }
            .age-check {
              padding: 0px;
              margin-top: 10px;
            }
            .send-btn {
              margin-bottom: 10px;
              button {
                width: 100%;
              }
            }
          }
        }
        .form-group .star-impt {
          color: #f44336;
        }
      }
    }
    .referral-card {
      box-shadow: -5px 0px 30px #00000029;
      border-radius: 10px;
      padding: 30px 65px 10px;
      margin-top: 30px;
      @include notDesktop {
        padding: 24px 25px 0;
        margin-top: 221px;
      }
    }
    .invite-friend {
      padding: 25px 0px 0;
      border-top: 1px solid #eee;
      .stepper-title {
        height: 45px;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 15px;
        h6 {
          font-size: 14px;
          font-weight: 500;
          margin: 0;
        }
        p {
          font-size: 14px;
        }
      }
      .stepper {
        flex: 100%;
        @include desktop {
          flex: 60%;
        }
        .stepper-data {
          width: 65%;
          @include desktop() {
            width: 80%;
          }
        }
        .stepper-section-wrapper {
          flex-direction: column;
          @include desktop {
            flex-direction: row;
          }
        }
        .stepper-section {
          flex: 33%;
          position: relative;
          flex-direction: row;
          @include desktop {
            flex-direction: column;
          }
        }
      }
      .referral-faq {
        .rc-collapse,
        .rc-collapse-item {
          border: none;
        }
        .rc-collapse-header {
          height: 50px;
          font-size: 18px;
          font-weight: 500;
          color: #222222;
          background-color: white;
          border-top: 1px solid #d9d9d9;
          border-bottom: 1px solid #d9d9d9;
          .faq-acord-icon {
            position: absolute;
            right: 5%;
          }
        }
        .rc-collapse-content-box p {
          font-size: 15px;
          color: #222222;
          background-color: none;
          border: none;
        }
      }
      .reward-details {
        flex: 100%;
        padding-top: 30px;
        border-top: 1px solid #e0dfe4;
        margin-top: 10px;
        @include desktop {
          flex: 40%;
          border-left: 1px solid #e0dfe4;
          border-top: none;
          padding-left: 30px;
          padding-top: 0px;
          margin-top: 0;
        }
        .reward-details-title {
          font-size: 16px;
        }
        .reward-data {
        }
        .reward-table {
          width: 100%;
          .table-heading {
            background-color: #dee4eb;
            font-weight: 500;
          }
          tr,
          td {
            padding: 2px 0px;
            text-align: center;
          }
          tr {
            border-bottom: 1px solid #eee;
          }
        }
      }
    }
    .referral-heading-wrapper {
      padding: 0 0 10px;
      .referral-main-heading {
        font-size: 20px;
        @include desktop {
          font-size: 28px;
        }
        span {
          font-size: 22px;
          position: relative;
          top: 2px;
          color: var(--primary);
          @include desktop {
            font-size: 32px;
          }
        }
      }
    }
    .email-link-toggle {
      padding: 0px;
      font-size: 18px;
      button {
        font-size: 18px;
        border: 1px solid #ccc;
        padding: 10px 30px;
        width: 180px;
        background: #fff;
        color: #000;
        font-weight: 500;
        box-shadow: none;
        border-radius: 0;
      }
      .d-selected {
        background-color: var(--primary);
        color: #fff;
      }
      .email {
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
      }
      .link {
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
      }
      .email-link-btn {
        display: flex;
        margin-top: 10px;
        @include desktop {
          margin-top: 0;
          display: inline-block;
        }
      }
      .giftcard-dtc {
        border-radius: 5px;
        cursor: default;
      }
    }
  }
  .id-upload {
    margin-left: -15px;
    margin-right: -15px;
  }
  @include min-height-desktop;
  .referral-img-container {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    // margin: 0 -15px;
    margin-bottom: 1rem;
    // .blur-container{
    // 	backdrop-filter: blur(20px);
    // }
    img {
      width: 100%;
      @include desktop {
        width: 720px;
      }
      margin: auto;
      height: auto;
    }
  }
  .error {
    color: #f44336;
    font-size: 1em;
  }
  .wallet-card {
    padding: 15px;
    margin-bottom: 15px;
    background-color: white;
  }
  .clipboard-text-box {
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none;
    position: absolute;
    top: 5px;
    opacity: 0;
    pointer-events: none;
    z-index: -25;
  }
  .small-text {
    font-size: 12px;
    color: blue;
  }
  .referral-link-wrapper {
    padding: 30px 0;
    .link-share-btn {
      height: 48px;
      font-size: 20px;
      background: var(--primary);
      border: none;
      color: #fff;
      border-radius: 6px;
      padding: 0;
      .share-btn-desktop {
        width: 50px;
        @include desktop {
          width: 135px;
        }
      }
    }
    .link-share-icon {
      width: 50px;
      @include desktop {
        width: 65px;
      }
    }
  }
  .ref-link-parent {
    margin-right: 10px;
    width: 80%;
    height: 48px;
    @include desktop {
      width: 50%;
    }
    position: relative;
    padding: 9px 15px 8px;

    border-radius: 3px;
    color: var(--primary);
    border: 2px dashed var(--primary);
    .ref-link {
      padding-right: 30px;
      font-size: 18px;
      // @include desktop{
      //     padding-right: 10px;
      // }
    }
    .copy-btn {
      color: var(--primary);
      position: absolute;
      right: 0;
      background: transparent;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1.5em;
      padding: 0;
      min-width: 40px;
      height: 40px;
      // line-height: 40px;

      .l-text {
        font-size: 14px;
        vertical-align: middle;
        margin-right: 5px;
      }
      &:focus {
        // background: #ccc;
      }
      i {
        line-height: inherit;
        vertical-align: middle;
      }
      @include desktop {
        padding-right: 30px;
        padding-left: 30px;
        border-left: 1px solid var(--primary);
        border-radius: 0;
      }
    }
  }
  .promo-code-container {
    input {
      //font-size: 1em;
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .apply-btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .promo-messsage {
      font-size: 1.2em;
    }
  }
  .code-list {
    .c-list-item {
      border-bottom: 1px solid $dividerColor;
      padding: 2px 5px;
      margin-bottom: 5px;
    }
    .right-sec {
      .btn + .btn {
        margin-left: 5px;
      }
    }
  }
  .mini-share {
    position: relative;
    right: 0px;
    top: 0px;
    margin-left: 10px;
    padding: 2px;
    border-radius: 3px;
    z-index: 1;
    max-width: 140px;
    .btn {
      margin: 5px;
      font-size: 1em;
      padding: 0;
      width: 35px;
      height: 35px;
      line-height: 35px;
      // &:last-child {
      //     margin-right: 0;
      // }
      i {
        line-height: inherit;
      }
    }
  }

  .mini-share-mobile {
    position: absolute;
    right: 30px;
  }

  .back-mask {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    //background-color:red;
  }
  .share-btn-container {
    .btn {
      margin-right: 16px;
      color: $primary;
      color: var(--primary);
      padding: 0;
      width: 100%;
      height: 46px;
      line-height: 46px;
      border-bottom: 1px solid $dividerColor;
      i {
        font-size: 1.5em;
        line-height: inherit;
      }
    }
  }
  .line {
    position: relative;
    &:after {
      content: "";
      width: 1px;
      background: $dividerColor;
      left: 50%;
      top: 15px;
      position: absolute;
      bottom: 15px;
    }
  }
  .ambassador-card {
    position: relative;
    .info-icon {
      font-size: 10px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid #b5b5b5;
      display: inline-block;
      line-height: 15px;
      color: #9e9e9e;
      margin-right: 1px;
    }
    .btn {
      // position: absolute;
      // bottom: 0;
      // right: 0;
      // left: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      padding: 10px;
    }
  }
  .g-tag {
    padding: 5px;
    background: #bbb;
    color: white;
  }
  .add-bank-account {
  }
  .table {
    border-bottom: 1px solid #eeefee;
    background-color: white;
    thead {
      background-color: #fbfbfb;
      th {
        border: 1px solid transparent;
        vertical-align: middle;
      }
    }
    tbody {
      td {
        padding: 0.55rem;
        border-top: 1px solid #ececec;
      }
    }
  }
  .disclaimer {
    font-size: 12px;
    color: #606060;
  }
  .carousel {
    .slider {
    }
    .slide {
      // padding:10px;
      background: white;
      // height: 200px;
      div {
        position: relative;
      }
    }
    .selected {
      // border:2px dotted var(--primary) !important;
      // border-radius:7px;
      padding: 0px !important;
      height: 100%;
    }
  }
  .price {
    font-size: 25px;
    position: absolute;
    bottom: 10px;
    left: 25px;
    font-weight: bold;
    color: white;
  }
  .preview {
    font-size: 18px;
    font-weight: 500;
  }
  .gc-box {
    @include desktop {
      display: flex;
      justify-content: space-between;
    }
    .mini-label {
      font-size: 14px;
      color: black;
    }
  }
  .country-code {
    font-size: 1em;
    display: block;
    color: #a09fa0;
    position: absolute;
    left: 11px;
    top: 60%;
    transform: translateY(-50%);
    width: 60px;
    padding: 0;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    line-height: 35px;
    border-radius: 5px !important;
    &:focus {
      border: 0;
      box-shadow: none;
    }
    @include desktop {
      top: 50%;
    }

    @include iphone5 {
      // top: 4px;
      width: 50px;
      line-height: 31px;
    }

    @include desktop {
    }
  }
  .input-box-icon input,
  .input-box-icon textarea {
    padding-left: 57px !important;
  }
  .referral-icons {
    a {
      color: #fff !important;
      text-decoration: none;
    }
  }
  .ref-facebook {
    background-color: #3b5998;
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
    margin-right: 15px;
  }
  .ref-mail {
    background-color: #bb001b;
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
    margin-right: 15px;
  }
  .ref-sms {
    background-color: #25d366;
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
    margin-right: 15px;
  }
  .ref-twitter {
    background-color: #55acee;
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
    margin-right: 15px;
    cursor: pointer;
  }
  .ref-instagram {
    background-color: #fb3958;
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
  }
  .stepper {
    .line {
      height: 100%;
      border-left: 2px dashed #ccc;
      position: absolute;
      top: 25px;
      left: 12px;
      @include desktop() {
        height: 0;
        width: 100%;
        border-top: 2px dashed #ccc;
        position: absolute;
        top: 12px;
        left: 25px;
      }
      &:after {
        background-color: #fff;
      }
    }
    .lead {
      font-size: 14px;
    }
    .step-no {
      padding: 2px 9px;
      border-radius: 50%;
      background-color: #ccc;
      color: #fff;
      width: 25px;
    }
    .ref-follwup {
      button {
        background-color: #fff;
        border: none;
        text-decoration: underline;
        font-size: 12px;
        color: var(--primary);
      }
    }
  }
  .referral-details-discount {
    .line-behind-text {
      &::before {
        width: 75% !important;
      }
    }
  }
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .referral {
    .referral-img-container {
      .blur-container {
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter: blur(20px);
      }
    }
  }
}

/* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .referral {
    .referral-img-container {
      .blur-container {
        background-color: var(--primary);
      }
    }
  }
}
.notdefault .referral {
  .banner-ref {
    background-color: inherit !important;
  }
}

/* Chrome version 29 and above */
@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .referral .country-code {
    left: 0px !important;
    padding: 0px 10px !important;
  }
}

.giftcard-terms-faq {
  // padding: 0 15px;
  max-width: $desktopContentWidth;
  margin: 0 auto;
  @include desktop() {
    max-width: 1140px;
    padding: 0 25px;
  }
  .rc-collapse {
    background: none;
    border: none;
  }
  .rc-collapse > .rc-collapse-item {
    padding: 16px 0;
  }
  .rc-collapse-content {
    background: none;
    color: #333;
  }
  .rc-collapse-header {
    position: relative;
    padding: 0px !important;
    font-size: 20px;
    font-weight: bold;
    color: #333 !important;
  }
  .faq-acord-icon {
    position: absolute;
    right: 15px;
  }
  .faq-container {
    padding: 0px;
    .faq-wrapper {
      grid-template-columns: auto;
      padding: 0;
      .faq-qna {
        padding: 0 0 10px;
        border: none;
        .faq-quest {
          margin-bottom: 0;
          font-size: 16px;
        }
      }
    }
  }
}

.send-referral-popup {
  .modal-dialog {
    .modal-content {
      border-radius: 15px;
      .modal-header {
        // background: url(../images/bg.svg);
        background-position: 50% 5%;
        background-size: cover;
        height: 50px;
        border: none;
        background-size: 50rem;
        border-radius: 15px;
        button {
          position: absolute;
          top: 0px;
          right: 0px;
          padding: 24px;
          font-size: 15px;
        }
        .modal-img {
          text-align: center;
          margin: 37px auto 0;
        }
      }
      .modal-body {
        .medium {
          font-weight: 500;
        }
        font-size: 14px;
        color: #000;
        text-align: center;
        .modal-title {
          color: var(--primary);
          margin-top: 10px;
        }
        button {
          font-size: 16px;
          padding: 11px;
          background-color: var(--primary);
          border-radius: 5px;
          color: #fff;
          margin-bottom: 20px;
          i {
            padding: 0 10px 0 5px;
            font-size: 21px;
          }
        }
        .icon-share {
          content: "\e80d";
        }
        .modal-para {
          max-width: 300px;
          margin: auto !important;
          margin: 25px auto !important;
          @include notDesktop {
            margin: 31px 0px;
          }
          .highlight-color {
            color: var(--primary);
          }
        }
      }
    }
    @include notDesktop {
      top: 110px;
      bottom: auto;
      padding: 40px;
    }
  }
  .sms-share-btn {
    border: 1px solid var(--primary);
    width: 120px;
    margin: 0 auto 10px;
    border-radius: 10px;
  }
}

.referral-history-popup {
  .modal-dialog {
    .modal-content {
      border-radius: 10px 10px 0 0;
      width: 660px;
      @include desktop {
        border-radius: 15px;
      }
      .modal-header {
        border: none;
        padding: 25px;
        @include notDesktop {
          padding: 24px 25px 32px 25px;
        }
        button {
          // position: absolute;
          top: 0px;
          padding: 6px 0px 0px;
          font-size: 16px;
        }
        h6 {
          font-size: 22px;
        }
      }
      .modal-body {
        padding: 0px 25px 25px !important;
        font-size: 16px;
        color: #000;
        text-align: left;
        .ref-phone-text {
          position: relative;
          border-bottom: 1px solid #eee;
          :nth-child(2) {
            // margin-bottom: 31px;
          }
          .joined {
            color: #ff8800;
          }
          .expires {
            color: #2b38e8;
          }
          .expired {
            color: #ff0000;
          }
          .success {
            color: #28a745;
          }
          i {
            font-size: 20px;
            vertical-align: text-top;
          }
          .by-giftcard-icons {
            font-size: 16px;
            vertical-align: inherit;
          }
          .recipient-phone-status-wrapper {
            @include desktop {
              flex-direction: row !important;
            }
          }
          .recipient-giftcard-status {
            border-left: none;
            margin-left: 0;
            padding-left: 0;
            @include desktop {
              border-left: 1px solid #333;
              margin-left: 0.5rem;
              padding-left: 0.5rem;
            }
          }
        }
        .ref-follwup {
          // position: absolute;
          // top: 35%;
          // right: 0px;
          margin: auto 0;
          border: 1px solid var(--primary);
          border-radius: 10px;
          padding: 2px 5px;
          button {
            background-color: #fff;
            border: none;
            font-size: 14px;
            color: var(--primary);
          }
        }
        .giftcard-success-status {
          border: none;
          p {
            font-size: 14px;
          }
        }
        .load-more-btn {
          // visibility: hidden;
          border: none;
          background: var(--primary);
          color: white;
          border-radius: 5px;
          font-size: 14px;
          padding: 5px 10px;
        }
      }
    }
    @include notDesktop {
      // top: 90px;
      // bottom: auto;
      // padding: 0 15px;
    }
    .by-giftcard {
      margin-right: -25px;
    }
    .by-giftcard-list {
      max-height: 250px;
      overflow-y: auto;
      padding-right: 25px;
      .empty-holder {
        background: #eee;
        padding: 5px;
        border-radius: 6px;
        opacity: 0.7;
      }
    }
  }
}
