.new-sidebar{
  .sidebar-top-section{
    background-color: var(--primary-dark);
    padding:16px;
    border-top-right-radius: 12px;
    .user-info{
      .user-pp{
        width: 64px;
        height: 64px;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.5);
        box-shadow: 0px 1.70667px 1.70667px rgba(0, 0, 0, 0.15);
      img{
        // box-shadow: 0px 2.02667px 2.02667px rgba(0, 0, 0, 0.15);
        // border-radius: 50%;
      }
      .icon{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 45px;
        color: var(--primary-dark);
        font-weight: 600;
      }
      }
      .user-detail{
        h6{
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: #ffffff;
          margin-bottom: 1px;
        }
        a{
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          text-decoration-line: underline;
          color: #FFFFFF;
        }
      }
    }
    .btn-wrap{
      .btn{
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        border: 1px solid #FFFFFF;
        border-radius: 6px;
        padding: 7px 10px;
        width: 100%;
        cursor: pointer;
      }
      .btn-login{
        background-color: #ffffff;
        color: var(--primary-dark);
      }
      .btn:hover{
        color: var(--primary-dark);
        background: #FFFFFF;
      }
      .btn-logout{
        border: 1px solid #FFFFFF;
        color: #ffffff;
      }
      .btn:hover{
        color: var(--primary-dark);
        background: #FFFFFF;
      }
      // .btn:hover{
      //   color: #056D76;
      //   background: #FFFFFF;
      // }
    }
  }
  .sidebar-wrap{
    .top-sec{
      padding: 10px 17px 10px 0px;
      height: 60px;
      border-bottom: 1px solid #eeeeee;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .main-logo{
        height: 43px;

        img{
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      span{
        color:#37475B;
      }
      // .sidebar-logo{
      //   height: 45px;
      //   img{
      //       height: 100%;
      //       width: 100%;
      //   }
      // }
    }
    .menu-list{
      padding-top: 17px;
      max-height: calc(100vh - 100px);
      padding-bottom: 50px;
      .sidebarItem{
        .sidebarItemStyle{
          padding: 16px 17px;
          align-items: center;
          .icon{
            color: #000;
            padding-right:12px !important;
            font-size: 18px;
          }
          .item-n{
            font-size: 14px;
            font-weight: 600;
            line-height: 17px;
            color: #37475B;
            @include desktop {
              font-size: 16px;
              line-height: 19px;
            }
          }
          .icon-down-arrow-dark{
            color: #37475B;
            font-size: 10px;
            padding-right: 0px;
            white-space: unset;
          }
          span{
            line-height: 19px;
            color: #37475B;
          }
        }
        .submenu{
          // background-color: #ffffff;
          // .sidebarItem{
            .sidebarItemStyle{
              padding: 10px 18px 10px 12px !important;
              span{
                color: #37475B;
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                @include desktop {
                  font-size: 16px;
                  line-height: 19px;
                }
              }
            }
          // }
        }
      }
    }
    .help-support{
      border-radius: 16px;
      position: absolute;
      bottom: 0;
      padding: 12px 18px;
      right: 0;
      left: 0;
      justify-content: space-between;
      // border-top: 2px solid #31C0C8;
      border-top: 2px solid var(--primary);
      // padding: 14px 24px;
      padding: 12px 24px 10px;
      z-index: 10;
      background: #ffffff;
      cursor: default;
      h6{
        // background: linear-gradient(0deg, #056D76 -4.89%, #31C0C8 104.88%);
        background: linear-gradient(0deg, var(--secondary) -4.89%, var(--primary) 104.88%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        // color:#056D76;
        // text-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
        line-height: 19px;
        font-size: 18px !important;
      }
      i{
        // background: linear-gradient(0deg, #056D76 -4.89%, #31C0C8 104.88%);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        // background-clip: text;
        // text-fill-color: transparent;
        text-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
        // font-size: 45px !important;
        // // color:#31C0C8 !important;
        // position: absolute;
        // top: -10px;
        // right: 21px;
        font-size: 50px !important;
        position: absolute;
        top: -12px;
        right: 24px;
        z-index: 11;
        background: linear-gradient(0deg, var(--secondary) -4.89%, var(--primary) 104.88%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        padding-bottom: 2px;
      }
    }
  }
  .content {
		// position: fixed;
		// top: 0;
		// left: 0;
		// right: 18%;
		// height: 100%;
		//overflow: auto;
    
    // border-top-right-radius: 12px;
    // border-bottom-right-radius: 12px;
    right:22%;
    width: 290px;
		@include desktop {
			right: 85%;
		}
		@include ipadPro {
			right: 72%;
		}
    @include ipadProLandscape {
			right: 80%;
		}
    @include ipadLandscape{
      right: 75%;
    }
    @include ipad {
			right: 66%;
		}
  }
}