//$primary: #00aa69;
//$primary: #13c7cd;
$primary: #ffffff;
$facebook: #3b5998;
$twitter: #1da1f2;
$whatsapp: #25d366;
$danger: #f44336;
$pink: #ed356a;
$modalBGColor: rgba(0, 0, 0, 0.69);
$font-family-sans-serif: "PublicSans", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
$navbarHeight: 64px;
$currentOrderInfoHeight: 66px;
$currentOrderInfoHeight_s: 60px;
$dividerColor: #dadada;
//$slick-font-path: "~slick-carousel/slick/fonts/";
//$slick-loader-path: "~slick-carousel/slick/";
$iphone5-width: 374px;
$iphone6-width: 375px;
$iphone5-real-width: 320px;
$medium-phone-width: 321px;
$tab-width: 768px;
$desktop-width: 992px;
$x-desktop-width: 1200px;
$bottomSheetHeight: 300px;
$bottomSheetHeight_s: 260px;
$allProductsSliderHeight: 190px;
$allProductsSliderHeight_s: 154px;
// $brandGradientBottom: #0ab3e5;
// $brandGradientTop: #2af499;
$brandGradientBottom: var(--brand-gradient-bottom);
$brandGradientTop: var(--brand-gradient-top);
$desktopContentWidth: 720px;
$bodyBackgroundColor: #fff;
$footerHeight: 51px;
$footerMobileHeight: 129px;
$footerIphone5Height: 119px;
$footerBackgroundColor: #fff;

$enable-print-styles: false;
$enable-prefers-reduced-motion-media-query: false;
$embed-responsive-aspect-ratios: ();

$sizes: ();
$sizes: map-merge(
    (
        100: 100%,
        auto: auto
    ),
    $sizes
);

//profile module
$text-light: #616161;
$text-lighter: #999999;
$primary-dark: #154133;
$primary-light: #05a0a8;

$grid-breakpoints: (
    xs: 0,
    //   sm: 576px,
    //   md: 768px,
    lg: 992px,
    xl: 1200px
);

$container-max-widths: (
    lg: 1140px,
    xl: 1300px
);

$friends-text-color: #003f47;
$friends-background: linear-gradient(180deg, #5ac194 0%, #36c0c8 100%);

@import "~nprogress/nprogress.css";
@import "bootstrapImports";
// @import "~uppy/src/scss/uppy.scss";
@import "~react-responsive-carousel/lib/styles/carousel.min.css";
@import "~slick-carousel/slick/slick.scss";
@import "~react-toastify/dist/ReactToastify.css";
// @import "./components/slickTheme.scss";
@import "~slick-carousel/slick/slick-theme.scss";
// @import "~react-credit-cards/es/styles.scss";
@import "~react-light-accordion/demo/css/index.css";
@import "~mapbox-gl/dist/mapbox-gl.css";
@import "~react-html5-camera-photo/build/css/index.css";
@import "~rc-collapse/assets/index.css";
@import "~instantsearch.css/themes/algolia.css";
@import "~react-tabs/style/react-tabs.scss";
// @import "~slick-carousel/slick/slick.css";
// @import "~slick-carousel/slick/slick-theme.css";
// @import "~react-datepicker/dist/react-datepicker.css";
@import "~rc-datepicker/lib/style.css";
// @import url('https://cdn.jsdelivr.net/npm/instantsearch.css@7.3.1/themes/algolia-min.css');
@import "~rc-datepicker/lib/style.css";

@import "./mixins.scss";
@import "./icon.scss";
@import "./_font.scss";
@import "./helpers.scss";
@import "./bootstrapOverides.scss";
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --primary: #ffffff;
    --gd-secondary-color: #fce68c;
    --gd-black-color: #333333;
    --primary-rgb: 255, 255, 255;
    --primary-dark-5: #ffffff; //darken(5%)
    --primary-dark-7_5: #ffffff; //darken(7.5%)
    --primary-dark-10: #ffffff; //darken(10%)
    --primary-dark-12_5: #ffffff; //darken(12.5%)
    --primary-dark-15: #ffffff; //darken(15%)

    --primary-light-7_5: #ffffff; //ligten(7.5%)
    --primary-light-10: #ffffff; //ligten(10%)
    --primary-light-25: #ffffff; //ligten(25%)
    --primary-light-45: #ffffff; //ligten(45%)
    --primary-light-50: #ffffff; //ligten(50%)

    --body-background-color: #fff;
    --text-on-background-color: #333; //TODO:need more lighter variations
    --text-on-primary-color: #fff;
    --footer-background-color: #ffffff;
    --text-on-footer-background-color: #ffffff;
    --brand-gradient-bottom: #ffffff;
    --brand-gradient-top: #ffffff;
    --headline-font: "GothamPro-Black";
    --navbar-height: 70px;
    --secondary-navbar-height: 0px;
    --delivery-info-height: 0px;
    --pricing-table-border: #dee2e6;
    --grassdoor-secondary-color: #004f59;
    --delivery-tab-height: 0;
    --cat-menu-tab-height: 0;
    --gray-text-color: #333333;
    --ink-color: #1a0dab;
    --primary-light: #65cdac;
    --primary-dark: #071712;
    --white-text-on-primary: #ffffff;
}

html,
body {
    // -webkit-overflow-scrolling: touch !important;
}

html,
body,
#app,
#__next {
    height: 100%;
}

@include iphone5 {
    body {
        font-size: 11px;
    }
}

@include medium-phone {
    body {
        font-size: 14px;
    }
}

body {
    background-color: var(--body-background-color);
}

// Components
// @import "./components/reactDateTime.scss";
// @import "./components/addCard.scss";
@import "./components/loaders.scss";
@import "./components/orientation.scss";
@import "./components/customRadio.scss";
@import "./components/customCheckbox.scss";
@import "./components/header.scss";
@import "./components/newHeader.scss";
// @import "./components/getCurrentLocation.scss";
@import "./components/addressBox.scss";
@import "./components/productListing.scss";
@import "./components/fullScreenModal.scss";
@import "./components/currentOrderInfo.scss";
@import "./components/deliveryDetails.scss";
@import "./components/customRadioLong.scss";
@import "./components/cart.scss";
@import "./components/cartItem.scss"; // DELETED
@import "./components/customerInfo.scss";
@import "./components/sideBar.scss";
@import "./components/confirmation.scss";
@import "./components/myOrders.scss";
@import "./components/paymentSettings.scss";
@import "./components/driverRating.scss";
@import "./components/cardList.scss";
// @import "./components/offerContainer.scss";
@import "./components/confirm.scss";
@import "./components/prescription.scss";
@import "./components/locationSelect.scss";
@import "./components/retry.scss";
@import "./components/login.scss";
@import "./components/settings.scss";
@import "./components/loyalty.scss";
@import "./components/privacypolicy.scss";
@import "./components/termsandconditions.scss";
@import "./components/referral.scss";
@import "./components/promoOffer.scss";
// @import "./components/investors.scss";
@import "./components/contact.scss";
@import "./components/aboutus.scss";
// @import "./components/stripeAddCard.scss";
@import "./components/bankDetails.scss";
@import "./components/partner.scss";
@import "./components/tipDriver.scss";
@import "./components/idUpload.scss";
@import "./components/marketingPopUp.scss";
@import "./components/locationPopup.scss";
@import "./components/cartWidget.scss";
@import "./components/wishlist.scss";
@import "./components/agePopup.scss";
@import "./components/productPage.scss";
@import "./components/productPageCopy.scss";
@import "./components/orderSummary.scss";
@import "./components/shopFullMenu.scss";
@import "./components/categoryProduct.scss";
@import "./components/deliveryLocations.scss";
@import "./components/footer.scss";
@import "./components/faq.scss";
@import "./components/banner.scss";
@import "./components/addressBottomSheet.scss";
@import "./components/filterDrawer.scss";
@import "./components/animModal.scss";
@import "./components/itemNotFound.scss";
@import "./components/brands.scss";
@import "./components/grassdoorLicense";
@import "./components/handAnimation.scss";
@import "./components/product.scss";
@import "./components/customRadioBox.scss";
@import "./components/deals.scss";
@import "./components/productCounter";
@import "./components/menuTabs";
@import "./components/slideUpModal";
@import "./components/bccPopup";
@import "./components/searchProduct";
@import "./components/filterBox";
@import "./components/customPillCheckbox";
@import "./components/customUnderlineRadio";
@import "./components/referralBanner";
@import "./components/bulkProductPopup";
@import "./components/filterProductsPage";
@import "./components/deliveryTabs";
@import "./components/ccpacompliance";
@import "./components/imageGridCarousel";
@import "./components/categoryVolumePricing";
@import "./components/productPageDTC.scss";
@import "./components/marketingBanner";
@import "./components/categoryMenu.scss";
@import "./components/locationPages.scss";
@import "./components/selectedFilters.scss";
@import "./components/_dealhub.scss";
@import "./components/_orderTracking.scss";
@import "./components/welcomePopUp.scss";
@import "./components/advertBannerHeader.scss";
@import "./components/_orderDetails_v2.scss";
@import "./components/_rateUs.scss";
@import "./components/_benefitCarousel";

@import "./components/dtcHeader";
@import "./components/marquee";
@import "./components/friendsFamily";
@import "./components/friendsFamilyLoginPopup";
@import "./components/friendsFamilyCartBenefitsPopup";
@import "./components/rangeSlider";
@import "./components/homepageloading";
@import "./components/productpageloading";
@import "./components/scheduleSlotPopup";
@import "./components/productTileControls";
@import "./components/brandCircleSlider";
@import "./components/categoryMenuNew";
@import "./components/citypages";
@import "./components/asapScheduledPopup";
@import "./components/_xyProductOffers.scss";
@import "./components/productPagePopup";
@import "./components/giftCard";
@import "./components/deliveryCities";
@import "./components/ambassador";
@import "./components/otpVerify";

@import "./components/brandMenu";

@import "./components/loadImageNew";
@import "./components/_profileAccount.scss";
@import "./components/compare_products";
@import "./components/compareButton";
@import "./components/comparePopup";
@import "./components/compareProductSearchBox";
@import "./components/comparePage";
@import "./components/miniTabs";
@import "./components/myOrdersNew";
@import "./components/orders";
@import "./components/rateReview";
@import "./components/orderCompletePopup";
@import "./components/_weedyMedCard.scss";
@import "./components/ChatBot";
@import "./components/promocode";
@import "./components/winatesla.scss";
@import "./components/featuredTiles.scss";
@import "./components/ledgerGreenPayment";
@import "./components/fixedBottomBar";
@import "./components/freightMessageBanner"; // #modal-root > div {
@import "./components/searchNewDrop";
@import "./components/searchPage";
@import "./components/loginDrawer";
@import "./components/userDropdown"; // #modal-root > div {
@import "./components/loginComponent";
@import "./components/newSidebar";
@import "./components/newProduct";
@import "./components/searchTabs";
@import "./components/happyHours";
@import "./components/countDown";
@import "./components/teslaTimer";
@import "./components/deliveryToggle";
@import "./components/homeBottomSection";
@import "./components/slickSlider";
@import "./components/newCategoryVolPopup";
@import "./components/downloadAppStore";
@import "./components/similarProductPopup";
// #modal-root > div {
//     > div {
// display: none !important;
//     }
//     > div:first-child {
//         display: block !important;
//     }
// }
// #modal-root {
//     > div {
//         display: none !important;
//     }
//     > div:first-child {
//         display: block !important;
//     }
// }
.bottom-sheet {
    position: fixed;
    bottom: -70px;
    width: 100%;
    background-color: white;
    @include desktop {
        background-color: var(--body-background-color);
    }

    transition: bottom 0.3s ease-in-out;
    // height:$bottomSheetHeight;
    height: auto;
    //box-shadow: 0px -5px 26px 2px rgba(0, 0, 0, 0.17);
    @include iphone5 {
        bottom: -50px;
    }
    &.open {
        bottom: 0px;
    }

    @include desktop {
        position: static;
    }
}

.text-black {
    color: #000000;
}

.card-shadow {
    box-shadow: 0px 8px 20px 5px rgba(0, 0, 0, 0.06);
    border: 1px solid #f7f7f7;
}
.min-width-btn {
    min-width: 120px;
}
.text-with-disc {
    position: relative;
    margin-left: 10px;
    &::before {
        content: "";
        position: absolute;
        left: -10px;
        top: 50%;
        transform: translateY(-50%);
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.56);
    }
}
.img-list-item {
    --image-display: inline-block;
    position: relative;
    padding: 10px 0;
    border-bottom: 1px solid $dividerColor;
    img {
        display: inline-block;
        width: 35px;
        height: 35px;
        vertical-align: middle;
    }
    .item-name {
        display: inline-block;
        width: calc(100% - 100px);
        vertical-align: middle;
        padding-left: 10px;
        padding-right: 10px;
    }
    .item-price {
        width: 65px;
    }
}

.input-box-icon {
    position: relative;
    .l-icon {
        position: absolute;
        left: 12px;
        font-size: 22px;
        top: 13px;
    }
    .l-select-icon {
        position: absolute;
        left: 22px;
        font-size: 22px;
        top: 6px;
    }
    input,
    textarea {
        padding-left: 44px;
    }
}
.btn-link:focus {
    text-decoration: none;
}
.modal-header .close {
    font-size: 1rem;
}
.view-all {
    text-decoration: none;
}
.view-all :hover {
    text-decoration: underline;
}

// @keyframes zoomIn {
//   from {
//     opacity: 0;
//     transform: scale3d(.3, .3, .3);
//   }

//   50% {
//     opacity: 1;
//   }
// }

// .zoomIn {
//   animation-name: zoomIn;
// }

// @keyframes zoomOut {
//   from {
//     opacity: 1;
//   }

//   50% {
//     opacity: 0;
//     transform: scale3d(.3, .3, .3);
//   }

//   to {
//     opacity: 0;
//   }
// }

// .zoomOut {
//   animation-name: zoomOut;
// }

@keyframes slidedown {
    0% {
        opacity: 0;
        transform: translate3d(0, -3000px, 0);
    }

    // 60% {
    //   opacity: 1;
    //   transform: translate3d(0, 25px, 0);
    // }
    // 75% {
    //   transform: translate3d(0, -10px, 0);
    // }
    99% {
        opacity: 1;
        transform: translate3d(0, 0px, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
@keyframes slideup {
    // 20% {
    //       transform: translate3d(0, -10px, 0);
    //     }
    //     40%  {
    //       opacity: 1;
    //       transform: translate3d(0, 20px, 0);
    //     }
    //     45% {
    //       opacity: 1;
    //       transform: translate3d(0, 20px, 0);
    //     }
    0% {
        transform: translate3d(0, 0px, 0);
        opacity: 0;
    }
    to {
        opacity: 0;
        transform: translate3d(0, -3000px, 0);
    }
}

.slideDown {
    animation-name: slidedown;
}
.slideUp {
    animation-name: slideup;
}
.animate {
    animation-duration: 500ms;
}

// enter: {
//   '0%': {
//     opacity: 0,
//     transform: 'translate3d(0, -3000px, 0)'
//   },
//   '60%': {
//     opacity: 1,
//     transform: 'translate3d(0, 25px, 0)'
//   },
//   '75%': {
//     transform: 'translate3d(0, -10px, 0)'
//   },
//   '90%': {
//     transform: 'translate3d(0, 5px, 0)'
//   },
//   to: {
//     transform: 'none'
//   }
// },
// exit: {
//   '20%': {
//     transform: 'translate3d(0, -10px, 0)'
//   },
//   '40%, 45%': {
//     opacity: 1,
//     transform: 'translate3d(0, 20px, 0)'
//   },
//   to: {
//     opacity: 0,
//     transform: 'translate3d(0, -2000px, 0)'
//   }
// }
.btn-primary.disabled,
.btn-primary:disabled {
    background-color: #808080;
    border-color: #808080;
}
.facebook-bg {
    background: $facebook;
}

.twitter-bg {
    background: $twitter;
}
.whatsapp-bg {
    background: $whatsapp;
}
.info-box {
    border: 1px solid #dadada;
    padding: 10px 5px;
    position: relative;
    padding-left: 33px;
    color: #616161;
    border-radius: 3px;
    span {
        position: absolute;
        left: 8px;
        top: 8px;
        font-size: 23px;
    }
    i {
        font-size: 10px;
        border: 1px solid #8c8c8c;
        width: 18px;
        height: 18px;
        display: inline-block;
        padding: 3px;
        border-radius: 50%;
        color: #949494;
        position: absolute;
        left: 8px;
        top: 11px;
    }
}

.line-through-text {
    position: relative;
    z-index: 1;
}
.line-through-text:before {
    border-top: 1px solid #c5c5c5;
    content: "";
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 95%;
    z-index: -1;
}
.line-through-text span {
    /* to hide the lines from behind the text, you have to set the background color the same as the container */
    background: $bodyBackgroundColor;
    background: var(--body-background-color);
    padding: 0 15px;
}

.follow-us {
    .icon-instagram {
        font-size: 25px;
        margin-right: 9px;
        margin-top: 3px;
    }
}
.g-badge {
    display: inline-block;
    padding: 4px 6px;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    margin-left: 5px;
    text-align: center;
    white-space: nowrap;
    vertical-align: top;
    border-radius: 2px;
}

.error {
    color: #f44336;
    // font-size: 1em;
}
.g-alert {
    padding: 10px;
}
.my-file-input {
    input {
        width: 0px;
        height: 0px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
        display: none;
    }
    label {
        // margin: 0; // display: inline-block; // font-weight: 400; // text-align: center; // white-space: nowrap;
        // vertical-align: middle; // -webkit-user-select: none; // -moz-user-select: none; // -ms-user-select: none; // user-select: none; // border: 1px solid transparent; // padding: 0.25rem 0.5rem;
        // font-size: 0.875rem;
        // line-height: 1.5;
        // border-radius: 0.25rem;
        // margin-left:-0.5rem;
    }
    .title {
        vertical-align: middle;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
        width: calc(100% - 128px);
    }
    &[data-acsb-hidden="true"] input {
        position: static;
    }
    &[data-acsb-hidden="false"] input {
        position: static;
    }
}
.small-alert {
    padding: 5px;
}

.set-delivery-address-btn {
    width: 100%;
    padding: 0 100px 0 50px;
    text-align: center;
    i {
        margin-right: 5px;
    }
    a,
    span {
        color: #333;
        text-decoration: none;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        display: inline-block;
        vertical-align: middle;
    }
}

// .v-center {
//     transform: translateY(-50%) !important;
//     top: 50% !important;
// }

.form-control {
    &.err-validate {
        background-color: rgba(255, 187, 187, 0.5);
    }
}
.info-alert {
    background-color: white;
    // border: 1px #afafaf solid;
    margin-left: 5px;
    border-radius: 9px;
    height: 16px;
    width: 16px;
    padding: 0;
    font-size: 10px;
    box-shadow: 0 0 0 1px $primary;
    box-shadow: 0 0 0 1px var(--primary);
    span {
        color: $primary !important;
        color: var(--primary) !important;
    }
}
.offer-box {
    img {
        width: 80px;
        height: auto;

        @include iphone5 {
            width: 70px;
        }
        @include desktop {
            width: 90px;
        }
    }
    .o-icon {
        font-size: 45px;
    }
    .right-sec {
        padding-left: 12px;
    }
    background: $primary;
    background: var(--primary);
    border-radius: 6px;
    padding: 10px 15px;
    margin-bottom: 10px;
    // box-shadow: 0px 2px 6px 0px rgba(135, 135, 135, 0.6);

    .sub-text {
        font-size: 12px;
    }
}
.offer-box-2 {
    img {
        width: 80px;
        height: auto;

        @include iphone5 {
            width: 70px;
        }
        @include desktop {
            width: 90px;
        }
    }
    .o-icon {
        font-size: 45px;
        position: relative;
        top: 10px;
    }
    .right-sec {
        padding-left: 12px;
    }
    .reward-container {
        background-color: var(--primary);
        padding: 10px;
        margin: 10px 0;
        color: #fff;
        border-radius: 4px;
        justify-content: center;
    }
    .o-text {
        padding: 0 5px;
        padding-right: 10px;
        line-height: 1.3;
        border-right: 1px solid #fff;
    }
    .o-discount {
        padding: 5px 10px;
    }
    //   background: $primary;
    background: #fce68c;
    border-radius: 15px;
    padding: 15px;
    margin-bottom: 10px;
    // box-shadow: 0px 2px 6px 0px rgba(135, 135, 135, 0.6);

    .sub-text {
        font-size: 12px;
        // line-height:1.4;
    }
    .e-title {
        font-size: 16px;
        line-height: 1.4;
        margin-bottom: 0.3rem;
    }
    .sub-text-2 {
        font-size: 13px;
        line-height: 1.4;
    }
    @include desktop {
        .e-title {
            font-size: 17px;
        }
        .sub-text-2 {
            font-size: 14px;
        }
        .reward-container {
            // width:200px;
            margin: 10px 0px;
            .o-text {
                font-size: 17px;
                padding-right: 5px;
            }
            .o-discount {
                padding: 5px 7px;
                font-size: 16px;
            }
        }
    }
    &.friend-box {
        background: $friends-background;
    }
}

.cart-banner {
    margin-bottom: 10px;
    img {
        width: 100%;
        display: inline-block;
        filter: drop-shadow(0px 2px 6px rgba(135, 135, 135, 0.6));
    }
}

.invoice {
    .strike-text {
        &:after {
            background-color: var(--primary);
            transform: translateY(-50%) rotate(0deg);
        }
        color: #868686;
    }
    .more-info {
        padding: 3px 3px 1px 3px;
        line-height: 1;
        font-size: 12px;
        vertical-align: baseline;
        &:active,
        &:hover {
            background-color: transparent !important;
            color: $primary !important;
            color: var(--primary) !important;
        }
    }
    .tax-item {
        &:last-child {
            margin-bottom: 8px;
        }
    }
    .t-item {
        margin: 4px 0;
    }
}

.disable-social {
    pointer-events: none;
    opacity: 0.5;
}

.blur-link {
    filter: blur(5px);
    pointer-events: none;
}

.date-mask {
    background: white;
    position: absolute;
    top: 14px;
    left: 44px;
    padding: 0 10px 0 0px;
    pointer-events: none;
    min-width: 150px;
}

.otp-on-call {
    padding: 15px 0 15px 0;
    .btn-primary {
        min-width: 290px;
    }
    .btn-primary:disabled {
        background-color: #c2c2c2;
        border-color: #cecece;
    }
    .blink-me {
        animation: blinker 1s linear 3;
    }
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.line-behind-text {
    position: relative;
    z-index: 1;

    &:before {
        border-top: 1px solid $dividerColor;
        content: "";
        margin: 0 auto; /* this centers the line to the full width
		specified */
        position: absolute; /* positioning must be absolute here, and relative positioning must be
applied to the parent */
        top: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        width: 40%;
        z-index: -1;
    }

    span {
        /* to hide the lines from behind the text, you have to
								 * set the background color the same as the container */
        background: $bodyBackgroundColor;
        background: var(--body-background-color);
        padding: 0 10px;
    }
}

.map-container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    // height: 100vh !important;
    width: 100%;
    background-color: #eceeed;
    .mapboxgl-canvas {
        width: 100% !important;
    }
    .mapboxgl-control-container {
        display: none;
    }
}
.time-dropdown {
    font-size: 1em;
    padding: 8px 15px 6px 40px;
    background: transparent;

    //border: 0;
    //border-bottom: 1px solid #ced4da;
    -webkit-appearance: none;
    position: relative;

    &:focus {
        box-shadow: none;
        // border: 0;
        // border-bottom: 1px solid #ced4da;
        background: transparent;
    }

    @include iphone5 {
        line-height: 15px;
    }
}
.time-dropdown-parent {
    .l-select-icon {
        @include real-iphone5 {
            font-size: 20px;
            top: 4px;
        }
    }
}

.mapboxgl-control-container {
    display: none;
}
.mapboxgl-canvas-container {
    height: 100%;
}

.lazy-load-image-background {
    background-size: cover !important;
    background-repeat: repeat !important;
    background-position: center !important;
}

.fullscreen-camera {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
    background: #000;
    justify-content: center;
    align-items: center;
    display: flex;
    .close-camera {
        position: absolute;
        right: 40px;
        top: 40px;
        z-index: 99;
        background: none;
        border: none;
        cursor: pointer;
        color: #fff;
    }
    .react-html5-camera-photo {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #container-circles {
        bottom: 100px;
        cursor: pointer;
    }
}
.schedule-title {
    @include notTab {
        font-size: 5.3vw;
    }
    @include iphone5 {
        margin-bottom: 3px;
        margin-right: 6px;
    }
}
.custom-toast {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    color: #ffffff;
    padding: 10px 15px;
    border: 2px solid white;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.34);
    white-space: nowrap;
}
.h-list-parent {
    position: relative;
    // margin: 0 -15px;
    @include desktop {
        margin: 0 0px;
    }

    // margin-bottom: 3rem;

    .h-list {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        padding: 20px 15px 33px 0px;
        position: relative;
        //   margin-bottom: 1rem;
        @include desktop {
            scrollbar-width: none;
            padding: 6px 15px 45px 15px;
        }

        &:after {
            content: "";
            flex: 0 0 20px;
            height: 1px;
        }
    }
    @include desktop {
        .h-list::-webkit-scrollbar {
            display: none;
        }
    }
    .scroll-btn {
        display: none;
        border-width: 2px;
        font-size: 1.6rem;
        width: 45px;
        height: 45px;
        line-height: 45px;
        padding: 0;
        background-color: #fff;
        color: $primary;
        color: var(--primary);
        position: absolute;
        top: 45%;
        transition: transform 0.2s ease-out;
        transform: translateY(-50%) scale(0);
        &.right {
            right: 0px;
        }
        &.left {
            left: 0px;
            i {
                display: inline-block;
                transform: scaleX(-1);
            }
        }
        @include ipadPro {
            display: inline-block;
        }
        @include desktop {
            display: inline-block;
        }
        border-radius: 8px;
        border: 0;
        box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.53);
    }
    &:hover {
        .scroll-btn {
            transform: translateY(-50%) scale(1);
        }
    }
}

.product {
    // flex: 0 0 45%;
    // height: auto;
    // // margin-right: 15px;
    // &:last-child {
    //     margin-right:20px;
    // }
    flex: 0 0 190px;
    margin-right: 20px;
    padding: 2px 0px !important;
    margin-top: 10px;
    border-radius: 10px;
    @include tab {
        flex: 0 0 205px;
    }
    @include desktop {
        flex: 0 0 215px;
        margin-right: 35px;
    }
    .product-counter-parent {
        top: 3px;
        @include desktop {
            top: 0px;
        }
        .count {
            font-size: 16px;
            font-weight: bold;
        }
    }
    .product-space-between-container {
        justify-content: start;
        .top-container {
            border-radius: 10px;
            padding: 7px 11px 6px;
            // width: 160px;
            position: relative;
            .img-wrapper {
                height: 140px;
                padding: 0px;
                margin-top: 53px;
                img {
                    // object-fit: contain;
                    margin: 0 auto;
                    height: 100%;
                    object-fit: contain;
                    object-position: center center;
                    width: 100%;
                }
            }
            .offer-holder {
                top: 39px;
                left: 11px;
            }
            .price-container {
                position: absolute;
                top: 10px;
                .price {
                    font-size: 20px;
                    color: var(--text-on-background-color);
                }
                .strike-text {
                    color: #555;
                }
            }
            .new-add-to-cart {
                position: absolute;
                top: 9px;
                right: 11px;
                z-index: 1;
                .add-to-cart {
                    position: relative;
                    width: 34px;
                    height: 34px;
                    border: none;
                    .c-icon {
                        font-size: 12px;
                        top: 0px;
                        left: 0px;
                        font-weight: bold;
                        // color: #000 !important;
                    }
                }
            }
        }
        .bottom-container {
            padding: 6px 11px 8px 11px !important;
            position: relative;
            .strain-container {
            }
            .product-name-weight {
                margin: 0px 0 10px;
                // height: 30px;
                p {
                    font-size: 16px;
                    color: var(--text-on-background-color);
                    // @include desktop{
                    //     font-size: 18px;
                    // }
                }
                .weight-container {
                    color: var(--text-on-background-color);
                    font-size: 14px;
                    display: inherit;
                }
            }
            .thc-cbd {
                margin-bottom: 0px !important;
                .title,
                .value {
                    color: var(--text-on-background-color);
                    font-size: 14px;
                    font-weight: normal;
                }
            }
            .brand-name {
                font-size: 14px;
                text-transform: uppercase;
            }
            .product-star-ratings {
                height: 18px;
                margin: 10px 0px;
                .icon-star-6 {
                    color: #ffc107 !important;
                    font-size: 18px !important;
                    margin: 10px 6px 10px 0px;
                }
            }
        }
    }
}
.product-in-tile {
    .loader-with-count {
        &:after {
            top: 50% !important;
            right: 9px !important;
        }
    }
}
.latest-product {
    // flex: 0 0 45%;
    // height: auto;
    // // margin-right: 15px;
    // &:last-child {
    //     margin-right:20px;
    // }
    // flex: 0 0 190px;
    // margin-right: 20px;
    // padding: 2px 0px !important;
    // margin-top: 10px;
    // border-radius: 10px;
    // @include tab {
    //     flex: 0 0 205px;
    // }
    // @include desktop {
    //     flex: 0 0 215px;
    //     margin-right: 35px;
    // }
    .product-counter-parent {
        top: 3px;
        @include desktop {
            top: 0px;
        }
        .count {
            font-size: 15px;
            font-weight: bold;
            line-height: 1;
        }
    }
    .product-space-between-container {
        justify-content: start;
        .top-container {
            border-radius: 10px;
            padding: 7px 11px 6px;
            // width: 160px;
            position: relative;
            .strain-fix-container {
                min-height: 25px;
            }
            .img-wrapper {
                height: 140px;
                padding: 0px;
                margin-top: 20px;
                img {
                    // object-fit: contain;
                    margin: 0 auto;
                    height: 100%;
                    object-fit: contain;
                    object-position: center center;
                    width: 100%;
                }
            }
            .offer-holder {
                top: 39px;
                left: 11px;
            }
            .price-container {
                // position: absolute;
                // top: 10px;
                .price {
                    font-size: 20px;
                    color: var(--text-on-background-color);
                }
                .strike-text {
                    color: #b9b9b9;
                }
            }
            .new-add-to-cart {
                // position: absolute;
                // top: 9px;
                // right: 11px;
                // z-index: 1;
                .add-to-cart {
                    position: relative;
                    width: 34px;
                    height: 34px;
                    border: none;
                    .c-icon {
                        font-size: 12px;
                        top: 0px;
                        left: 0px;
                        font-weight: bold;
                        // color: #000 !important;
                    }
                }
            }
        }
        .bottom-container {
            .product-name-weight {
                // min-height: 40px;
                margin: 0px 0 5px;
                // height: 30px;
                p {
                    font-size: 16px;
                    color: var(--text-on-background-color);
                    // @include desktop{
                    //     font-size: 18px;
                    // }
                }
                .weight-container {
                    color: var(--text-on-background-color);
                    font-size: 14px;
                    display: inherit;
                }
            }
            .thc-cbd {
                margin-bottom: 0px;
                .title,
                .value {
                    color: var(--text-on-background-color);
                    font-size: 14px;
                    font-weight: normal;
                }
            }
            .brand-name {
                font-size: 13px;
                text-transform: uppercase;
            }
            .product-star-ratings {
                height: 18px;
                margin: 10px 0px;
                .icon-star-6 {
                    color: #ffc107 !important;
                    font-size: 18px !important;
                    margin: 10px 6px 10px 0px;
                }
            }
        }
    }
}
.product-soldout-limit {
    color: #ff7f68;
    text-transform: uppercase;
    font-weight: bold;
    // position: absolute;
    // bottom: 3px;
    // right: 0;
}
.soldout-limit {
    // position:absolute;
    // right:0;
    // bottom:0;
    // height: auto!important;
    color: #ff7f68;
    text-transform: uppercase;
    // text-shadow: 0 0px 2px white;
    font-weight: bold;
    position: relative;
    &:before {
        // content: "";
        width: 6px;
        height: 6px;
        background: currentColor;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        top: -2px;
        margin-right: 3px;
    }
}
.discount-savings {
    color: #ff7f68;
    position: relative !important;
    font-weight: bold;
    top: 0px !important;
    right: 0px !important;
}
.promo-copy-box {
    border: 1px solid #ced4da;
    padding: 2px 5px 2px 12px;
    border-radius: 0.25rem;
}
.subhead {
    letter-spacing: 0.09rem;
    font-size: 15px;
    @include desktop {
        font-size: 1.5rem;
    }
}

.mini-label {
    margin-bottom: 2px;
    font-size: 12px;
    color: $primary;
    color: var(--primary);
}

.card-details {
    .form-control {
        // border-radius:0;
    }
}
.deal-loader {
    height: 80px;
    margin-top: -40px;
    margin-bottom: 20px;
}
.no-products {
    .n-title {
        font-size: 20px;
        @include desktop {
            font-size: 22px;
        }
    }
    .n-subtitle {
        font-size: 16px;
        @include desktop {
            font-size: 18px;
        }
    }
    img {
        width: 250px;
        @include desktop {
            width: 350px;
        }
    }
}

.warning-logo {
    font-size: 24px;
    color: $primary;
    color: var(--primary);
}

.sale {
    padding: 3px 0px;
    color: #ff7f68 !important;
    // z-index: 1;
    // font-weight: 500;
    // border-radius: 3px;
    // color: white;
    /* font-size: 14px; */
}
.page-404 {
    @include min-height-desktop;
}

span.marker {
    background: #ff0;
}

.pricing-table {
    // margin:0 -15px;
    // @include desktop{
    //     margin:0;
    // }
    .section-header {
        background: #eff3f6;
        font-size: 16px;
        // margin: 0 -15px;
        text-align: center;
        // border: 1px solid #dadada;
    }
    .table-parent {
        border-left: 1px solid var(--pricing-table-border);
        border-right: 1px solid var(--pricing-table-border);
        border-bottom: 1px solid var(--pricing-table-border);
        // padding: 0 6px;
        margin-bottom: 1rem;
    }
    table {
        margin: 0;
        margin-bottom: 1rem;

        @include desktop {
            table-layout: fixed;
        }
        tbody {
            border-spacing: 0px 15px;
        }
        td {
            padding: 0.35rem 1rem;
            border: none !important;
        }
        th:first-child {
            border-radius: 4px 0 0 4px;
        }

        th:last-child {
            border-radius: 0 4px 4px 0;
        }

        tbody tr:last-child td {
            // border-bottom: 1px solid #dee2e6;
        }

        thead {
            font-size: 14px;
            th {
                border: none !important;
                // border-bottom: 1px solid var(--pricing-table-border);
                background-color: #f7f7f7;
                padding: 0.25rem 1rem !important;
                color: #37475b;
                font-weight: 300;
            }
        }
        tr {
            border-bottom: 1px solid #dee2e6 !important;
        }
        // tr{
        //     border:1px solid #DEE4EB;

        // }
        tbody tr td:first-child {
            // border-top:none;
            // border-left: 2px solid white;
        }
        tbody tr td:last-child {
            // border-top:none;
            // border-right: 2px solid white;
        }
    }
}

.search-input {
    position: relative;
    i {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
    input {
        padding-left: 30px;
    }
}

.filter-indicator {
    // display:none;
}

.status-message {
    background: #f7f7f7;
    padding: 10px;
    text-align: center;
    font-weight: 500;

    &.lp-s {
        padding: 5rem 10px;
    }
}
.cms-page {
    padding: 0 15px;
    padding-top: calc(var(--navbar-height) + 20px);
    margin-bottom: 4rem;
    padding-bottom: 4rem;
    @include min-height-desktop;

    h4 {
        font-size: 18px;
        font-weight: bold;
        margin-top: 25px;
        margin-bottom: 4px;
    }
    // a{
    //     height: 40px;
    //     display: inline-block;
    //     line-height: 40px;
    //     padding: 0 15px;
    //     border-radius: 5px;
    //     background: var(--primary);
    //     color: #fff;

    //     &:hover{
    //         text-decoration: none;
    //         background: var(--primary-dark);
    //     }
    // }
}
.h-tags {
    position: relative;
    margin-bottom: 2rem;
    padding-left: 15px;

    .logo {
        width: 200px;
        position: absolute;
        left: 25px;
        top: 50%;
        transform: translateY(-50%);
        display: none;
    }
    h1 {
        font-size: 20px;
    }
    h2 {
        font-size: 16px;
    }
    @include desktop {
        h1 {
            font-size: 25px;
        }
        h2 {
            font-size: 18px;
        }
        .logo {
            display: block;
        }
    }
}
.description {
    color: #535353;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
}

.category-hub-description {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
}

.mini-description {
    color: #6c6c6c;
    font-size: 13px;
    line-height: 1.3;
    margin-bottom: 0px !important;
    font-style: normal;
    word-wrap: break-word;
    word-break: break-word;
}

.v-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    // margin: 0 -10px;
    @include desktop {
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 30px;
    }
}

// .lazy-load-category-height {
//     min-height: 405px;
//     @include desktop {
//         min-height: 460px;
//     }
// }

// .lazy-load-product-height {
//     min-height: 325px;
//     @include desktop {
//         min-height: 320px;
//     }
// }
.skeleton-product-list {
    overflow-x: auto;
    padding: 15px;
    .skeleton-product {
        text-decoration: none;
        flex: 0 0 140px;
        margin-right: 15px;
        padding: 10px;
        display: flex;
        align-items: center;
        min-height: 210px;
    }
}

.disable-tab {
    @include desktop {
        color: #808080 !important;
        * {
            color: #808080 !important;
        }
    }
}
.schedule-tab .m-item,
.asap-tab .m-item {
    border-radius: 8px !important;
}
.descriptive-text {
    .h6 {
        font-size: 16px;
    }
    @include notDesktop {
        .h3 {
            font-size: 1.4rem;
        }
    }

    position: relative;
    padding: 38px 15px 24px !important;
    background: #ecf8f8;
    overflow: hidden;
    border-radius: 0px;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 0;
    margin-bottom: 0;

    @include desktop {
        border-radius: 10px;
        margin-top: 1rem;
        padding: 54px 15px 35px !important;
    }
    .container {
        padding: 0px;
        @include desktop {
            padding: 0px 15px;
        }
    }
    &.bottom-text-holder {
        margin: 0px 0 -30px;
        border-radius: 0px;
        background: #f5f5f5;

        @include notDesktop() {
            padding: 15px 0;
            // margin-top: -80px;
            margin-top: 0px;
            margin-bottom: -20px;
        }

        h1 {
            line-height: 1.4;
            color: #000 !important;
            // @include notDesktop(){
            //     text-align: center;
            // }
        }

        h2 {
            font-weight: normal;
            // line-height: 1.5;

            @include desktop() {
                // font-size: 15px;
                line-height: 1.6;
            }
        }
    }

    * {
        // color:white !important;
    }
    h2 {
        margin-bottom: 0;
    }
    .img-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: transform 8s ease;
        transform-origin: right;

        img {
            width: 200%;
            position: absolute;
            right: -90%;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0.6;
            animation: imgRotate 50s linear infinite;
            @include desktop {
                height: 1110px;
                // right:-90%;
            }
        }
    }
    &:hover {
        .img-container {
            transform: scale(1.5);
        }
    }

    // .descriptive-content-more{
    //     @include notDesktop() {
    //         height: 55px;
    //         -webkit-line-clamp: 3;
    //         overflow: hidden;
    //         -webkit-box-orient: vertical;
    //         text-overflow: ellipsis;
    //         display: -webkit-box;
    //         line-height: 1.3;
    //         margin-bottom: 40px;
    //     }
    // }

    // .show-more-less-btn{
    //     position: absolute;
    //     bottom: 60px;
    //     left: 0;
    //     width: 100%;
    //     background: linear-gradient(to right, #ecf8f8ad, #ecf8f8);
    //     border: none;
    //     color: #004f5a;
    //     font-weight: bold;
    //     text-decoration: underline;

    //     @include desktop(){
    //         display: none;
    //     }
    // }

    // .descriptive-content-less{
    //     padding-bottom: 40px;

    //     .show-more-less-btn{
    //         bottom: 35px;
    //     }
    // }

    button {
        &:focus {
            outline: none;
        }
    }
    @include ipadPro {
        padding-left: 0;
        padding-right: 0;
    }
}

@keyframes imgRotate {
    from {
        transform: translateY(-50%) rotate(0deg);
    }
    to {
        transform: translateY(-50%) rotate(360deg);
    }
}

.schedule-title {
    font-size: 18px;
    margin-bottom: 5px;
}
.cat-menu-title {
    font-size: 23px;
}
.freight-limit {
    .fl-inner {
        position: relative;
        color: #6f6f6f;
        border-radius: 0px;
        margin: 0 -15px;
        padding: 10px 15px 10px;
        text-align: center;
        line-height: 1.4;
        font-weight: bold;
        .icon-truck {
            font-size: 20px;
            position: relative;
            top: -1px;
            // display:none;
        }
        @include desktop {
            margin: 0 auto;
            font-size: 16px;
            padding: 12px 33px 10px;
            max-width: 800px;
        }
        .btn {
            position: absolute;
            right: 8px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 8px;
            padding: 0;
            width: 23px;
            height: 23px;
            line-height: 23px;
            border-radius: 50%;

            color: white;
        }
    }
    &:empty + .remaining-time {
        margin-top: 10px;
    }
    &.gd-freight-limit {
        margin-bottom: 5px;
        .a-timings {
            border: 1px solid #ededed;
            border-radius: 8px;
            padding: 6px 10px;
            margin-top: 10px;
            background: #ffffff;
            position: relative;
            padding-left: 43px;
        }
        .timings-inner {
            font-size: 12px;
            .time-holder {
                margin-right: 10px;
            }
        }
        .timer-icon {
            // background: -webkit-linear-gradient(#36BFC4, #71C273);
            // -webkit-background-clip: text;
            // background-clip: text;
            // -webkit-text-fill-color: transparent;
            color: var(--primary);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            font-size: 24px;
            left: 10px;
        }
        @include desktop {
            margin-bottom: 0px;
            .a-timings {
                // display:flex;
                // align-items:center;
                // justify-content:center;
                border: none;
                // gap:10px;
                text-align: center;
                padding: 0;
                margin-top: 15px;
            }
            .timer-icon {
                position: static;
                transform: translateY(0%);
                margin-right: 10px;
            }
            .timings-inner {
                font-size: 16px;
                font-weight: 600;
                .time-holder {
                    margin-right: 15px;
                    i {
                        font-size: 25px;
                        vertical-align: middle;
                        margin-right: 2px;
                        color: #505050;
                    }
                    span {
                        vertical-align: middle;
                    }
                }
            }
            .fl-inner {
                padding-top: 5px;
            }
        }
    }
}

.enlarged-image {
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: 0px solid transparent;
    width: 520px !important;
    height: 520px !important;
}

.thc-price-sort-radio {
    margin-bottom: 0.5rem;
    display: block;
    label {
        padding-left: 32px;
        &:before {
            // border-width: 1px;
        }
    }
}

.acsb-trigger.acsb-trigger-size-medium {
    width: 56px !important;
    height: 56px !important;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
}

.acsb-trigger.acsb-trigger-size-small {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
}

.acsb-trigger.acsb-trigger-size-small {
    @extend %increase-touch-area;
}

.h-h1 {
    font-size: 1.4rem;
}

.breadcrumb-parent {
    padding-top: 15px;
    @include desktop {
        font-size: 16px;
        // margin-left: -14px;
    }
    .icon-home {
        font-weight: bold;
        color: #283cd5;
    }
}
.delivery-threshold {
    // background-color: #d0fdfc;
    background-color: var(--light-primary);
    color: #000000;
    border-radius: 6px;
    padding: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    .truck-icon {
        font-size: 20px;
        vertical-align: middle;
        position: relative;
        top: -1px;
    }

    &.delivery-bg {
        margin: 10px 15px;
        @include desktop {
            margin: 0 15px;
        }
    }
    @include desktop {
        border-radius: 6px;
        margin-bottom: 0;
    }
    & + .promo-code-container {
        border-top: 0 !important;
    }
}

.yt-video-popup {
    z-index: 9999;
    .modal-content {
        background-color: transparent;
    }
    .modal-body {
        padding: 0;
    }
    iframe {
        width: 100%;
    }
    .yt-close-btn {
        width: 30px;
        height: 30px;
        padding: 0;
        font-size: 10px;
        color: white;
        border: 1px solid white;
        border-radius: 50%;
        line-height: 30px;
        text-align: center;
        position: absolute;
        right: 5px;
        top: -40px;
    }
}
.error-msg {
    color: #f44336;
    margin-top: 2px;
}

.stronghold-list {
    padding: 15px 0;
    .custom-radio {
        padding: 2px 0;
        padding-right: 35px;
    }
}

.white-btn {
    background-color: $primary;
    color: #36c0c8;
    font-weight: 600;
    border-radius: 8px;

    &:hover {
        color: var(--primary-dark-7_5);
    }
}

.delivery-message {
    padding: 15px;
    .dm-inner {
        padding: 8px;
        padding-left: 50px;
        /* border: 1px solid black; */
        border-radius: 5px;
        position: relative;
        background: var(--gd-secondary-color);
        color: #626262;
        line-height: 1.4;
        font-size: 13px;
        i {
            position: absolute;
            top: 46%;
            transform: translateY(-50%);
            font-size: 20px;
            left: 10px;
        }
        @include desktop {
            padding: 15px;
            padding-left: 58px;
            i {
                left: 15px;
            }
        }
    }
}

.popup-title-icon {
    width: 40px;
    height: 40px;
    background: rgba(var(--primary-rgb), 0.2);
    border-radius: 50%;
    line-height: 40px;
    font-size: 16px;
    display: inline-block;
    text-align: center;
    color: var(--primary);
    margin-bottom: 10px;
}
.mobile-bottom-sticky-desktop-center-popup {
    .modal-content {
        max-height: 100%;
        overflow: auto;
    }
    .modal-dialog {
        align-items: flex-end;
        margin: 0;
        margin-left: auto;
        margin-right: auto;
        height: 100%;
        display: flex;
        max-height: 100%;
    }
    @include desktop {
        display: block !important;
        .modal-dialog {
            margin: 0 auto;
            align-items: center;
        }
    }
}
//following css is for the ios and android mobile app to hide navbar for certain pages
.g-hide-navbar {
    nav {
        display: none !important;
    }
    .cms-page,
    .faq,
    .contact,
    .ccpacompliance,
    .privacy-policy {
        padding-top: 0;
    }
}
//http://192.168.31.238:3000/faq
.scheduled-delivery-message-handle {
    @include notDesktop() {
        // display: flex;
        // flex-direction: column;
    }

    .delivery-dates-popup {
        color: var(--primary);
        cursor: pointer;
        text-decoration: underline;

        &:hover {
            color: var(--primary-dark-15);
        }
    }
}
.asap-delivery-message-handle {
    color: rgb(167, 167, 167);
}
.no-product-available {
    text-align: center;
    color: #8a9090;

    span {
        font-size: 80px;
    }

    h4 {
        margin-top: 25px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    div {
        font-size: 15px;
        margin-bottom: 15px;
    }
    img {
        margin: 0 auto;
    }
}

.friends-terms-modal {
    .modal-dialog {
        @include desktop() {
            max-width: 650px;
            width: 650px;
            margin-top: 60px;
            margin-bottom: 40px;
        }

        .close-holder {
            text-align: right;
            margin-top: 8px;

            button {
                color: #b4b4b4;
            }
        }

        .modal-body {
            padding: 0;

            .title {
                color: #36c0c8;
            }

            .cms-page {
                padding-top: 0px;
                // margin-top: -20px;
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
    }
}

.whats-this {
    display: none;
    &:before {
        content: "";
        position: absolute;
        top: -10px;
        bottom: -10px;
        left: -10px;
        right: -5px;
    }
    i {
        width: 20px;
        height: 20px;
        font-size: 10px;
        border: 1px solid currentColor;
        border-radius: 50%;
        font-weight: bold;
        line-height: 20px;
        display: inline-block;
    }
    position: absolute;
    right: 6px;
    top: 15px;
    transform: translateY(-50%);
    padding: 0;
    color: #6d6d6d;
    .w-text {
        font-size: 13px;
        font-weight: lighter;
        display: inline-block;
        margin-left: 6px;
    }
    @include desktop {
        position: relative;
        align-self: center;
        margin-left: 20px;
        top: auto;
        transform: none;
    }
}
.empty-cart-popup {
    .modal-dialog {
        margin: auto;
    }
    .modal-body {
        // padding: 20px 0px 50px;
        img {
            width: 250px;
        }
    }
}
.product-close-btn {
    z-index: 2;
    color: #333;
    border: none;
    position: absolute;
    right: 10px;
    top: 10px;
}
.new-product-header {
    border: none;
    padding: 0;
}

.fancy-close-btn {
    color: var(--primary);
    border: 2px solid var(--primary);
    border-radius: 3px;
    text-align: center;
    padding: 2px 9px;
    i {
        font-size: 11px;
        margin-right: 6px;
        vertical-align: middle;
    }
    span {
        vertical-align: middle;
        font-size: 14px;
    }
}
// .medicalID-btn{
//     display: block;

//     @include desktop{
//         display: none;
//     }
//     &:hover{

//             color: var(--primary);
//             background-color: white;
//             border-color: var(--primary);

//     }
//     &:focus{
//         outline:0;
//         box-shadow: none;
//     }
// }

// .full-height{
//     @include notDesktop{
//        display: block;
//      }
// }
// .hide-height{
//       @include notDesktop{
//           display: none;
//       }
// }

@include notDesktop {
    .hide-acsb-icon .acsb-trigger.acsb-ready {
        display: none !important;
    }

    body .acsb-trigger {
        width: 20px !important;
        height: 20px !important;
        z-index: 1049 !important;
    }
}

.view-offer-details-popup {
    background: rgba(0, 0, 0, 0.3);

    .modal-content {
        // box-shadow: 0px 2px 10px #eee;
        border-color: #eee;

        .modal-header {
            border: none;
            padding-bottom: 0;
            padding-right: 5px;

            .c-text {
                color: var(--primary);
            }
        }

        .single-product {
            @include desktop() {
                justify-content: space-between;
            }

            &.product-info {
                @include notDesktop() {
                    justify-content: space-between;
                }
            }

            .img-holder {
                margin-right: 15px;
                border-radius: 5px;
                border: 1px solid #f2f2f2;
                align-self: flex-start;
                img {
                    width: 60px;
                    height: 60px;
                    object-fit: contain;
                }
            }

            .product-content {
                .price-container {
                    font-size: 15px;
                    color: var(--primary);

                    .price {
                        font-weight: bold;
                        margin-right: 6px;
                    }
                }
            }

            .cart-widget-parent {
                @include notDesktop() {
                    width: max-content;
                }
                .item {
                    padding: 0;
                    .count-holder {
                        @include notDesktop() {
                            margin-left: 75px;
                        }
                    }
                }
            }
        }
    }
}
.apply-cashback-section {
    .w-inner {
        background-color: #fafafa;
        padding: 10px;
        border-radius: 5px;
        i {
            margin-right: 5px;
        }
        img {
            width: 35px;
            margin-right: 15px;
        }
        .custom-checkbox {
            label {
                padding-left: 25px;
            }
        }
    }
}

.wallet-check-icons {
    margin-right: 0 !important;
}

.rc-collapse-header:focus {
    outline: 0 !important;
}

.bg-overlay {
    background-color: $modalBGColor;
}
.auth-status {
    padding: 14px;
    border-radius: 3px;
    margin-bottom: 10px;
    margin-top: -13px;
    text-align: center;
    background-color: #f5f6fa;
    i {
        font-size: 20px;
        margin-right: 6px;
        margin-bottom: 2px;
        color: var(--primary);
    }
    .auth-msg {
        color: var(--primary);
        font-weight: 400;
    }
    @include desktop {
        padding: 18px 22px;
        border-radius: 3px 3px 0 0;
        margin-bottom: 12px;
        margin-top: -22px;
        margin-left: -22px;
        margin-right: -22px;
    }
}

.Toastify__close-button {
    width: 30px;
    align-self: center;
}
.priority-delivery-popup {
    .modal-header {
        background: url(../images/bg.svg);
        background-position: center;
        background-size: cover;
        border-bottom: none;
        padding-top: 36px;
        padding-bottom: 36px;
        div {
            width: 100%;
        }
        .schedule-title {
            font-size: 25px;
            color: #ee585d;
            align-self: center;
        }
    }
    .modal-body {
        color: #ee585d;
        .text-error {
            color: #ee585d;
        }
        .box-holder {
            padding: 20px 15px;
            -webkit-flex-direction: column;
            flex-direction: column;
            height: 100%;
            box-shadow: 1px 1px 6px #00000017;
            border-radius: 5px;
            padding: 12px 15px 15px;
            // text-align: center;
            .content {
                font-size: 12px;
                color: #5f5f5f;
                margin-top: 36px;
            }
            h3 {
                line-height: 18px;
                span {
                    font-size: 20px;
                }
            }
        }
    }
    .btn-close {
        position: absolute;
        right: 0;
        top: 4px;
        font-size: 14px;
    }
}

.tax-tag {
    font-size: 13px;
    color: grey !important;
    font-weight: 200;
    font-style: italic;
}

.hide-address-bar {
    .s-sec-i {
        display: none !important;
    }
}

.referral-wrapper {
    height: auto;
    min-height: -webkit-calc(100vh - 51px);
    min-height: -moz-calc(100vh - 51px);
    min-height: calc(100vh - 51px);
}
.main-page-wrapper {
    height: auto;
    min-height: -webkit-calc(100vh - 51px);
    min-height: -moz-calc(100vh - 51px);
    min-height: calc(100vh - 51px);
}

.ais-Hits-item:empty {
    display: none;
}
.rounded-card {
    border-radius: 10px;
    background-color: #ffff;
    border: 1px solid #dedede;
}
.redeem-gc:focus {
    box-shadow: none;
}

.gd-secondary-banner {
    margin-top: 0px;
    display: inline-block;
    width: 100%;
    .h-list {
        padding-bottom: 11px !important;
        padding-top: 8px !important;
        margin-bottom: 6px !important;
        @include desktop {
            padding-top: 21px !important;
            margin-bottom: 15px !important;
        }
    }
    .h-list {
        padding-bottom: 0px !important;
    }
    .carousel-item {
        flex: 0 0 90%;
        position: relative;
        margin-right: 13px;
        @include desktop {
            margin-right: 21px;
        }
        img {
            min-height: 108px;
            object-fit: cover;
            // width: 300px;
            height: auto;
            border-radius: 10px;
            @include desktop {
                min-height: 224px;
                object-fit: cover;
                // width: 507px;
                border-radius: 13px;
            }
        }
    }
    .scroll-btn.scroll-btn {
        color: var(--primary);
        background-color: #fff;
        border-color: #fff;
        top: 50%;
    }
    @include desktop {
        .carousel-item {
            flex: 0 0 72%;
        }
    }
    .secondary-banner-box {
        border-radius: 16px;
        text-align: center;
        .image-container {
            img {
                border-radius: 20px;
                box-shadow: 0px 10px 20px #888;
                // min-height: 200px;
                object-fit: cover;
            }
        }
    }
    .fnf-box {
        background: linear-gradient(180deg, #f3e8ff 0%, #edffd7 100%);
        .perk-box {
            .perk {
                flex-basis: 100%;
            }
            .v-line {
                width: 1px;
                height: 25px;
                background-color: #00000011;
            }
        }
        @include desktop {
            font-size: 16px;
        }
        p {
            font-size: 14px;
            font-weight: 500;
        }
    }
    .pay-box {
        background: linear-gradient(180deg, #fcffe8 0%, #ffedd8 100%);

        .credit {
            display: inline-block;
            font-size: 18px;
            min-height: 60px;
            line-height: 2;
            @include desktop {
                font-size: 24px;
            }
        }
    }
    .btn:focus {
        filter: brightness(0.8);
    }
}

.modal-content {
    border-radius: 10px;
}

.metric-tag-container {
    line-height: 1.2;
    margin-top: 3px;

    span {
        font-size: 12px;
        display: inline-block;
    }
    .metric-tag {
        margin-right: 5px;
        &:after {
            content: ",";
        }
        &:last-child {
            margin-right: 0px;
            &:after {
                content: "";
            }
        }
    }
}

.desktop-width {
    margin-left: auto !important;
    margin-left: auto !important;
    @include desktop {
        margin-left: -15px !important;
        margin-left: -15px !important;
    }
}

.categories-parent {
    .category-page-tabs {
        justify-content: center;
        @include desktop {
            margin-left: 0;
            margin-right: 0;
        }
    }
    .filter-product-page {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
}

.cms-description {
    h1 {
        font-size: 2rem;
    }
    h2 {
        font-size: 1.5rem;
    }
    h3 {
        font-size: 1.25rem;
    }
    h4 {
        font-size: 1rem;
    }
}
.progress-bar-new {
    width: 100%;
    height: 6px;
    position: relative;
    background-color: #f2f2f2;
    border-radius: 6px;
    overflow: hidden;
    span {
        position: absolute;
        height: 6px;
        left: 0px;
        top: 0px;
        background-color: rgb(122, 130, 218);
    }
}

.weed-delivery-cities {
    font-size: 22px;
    @include desktop {
        font-size: 30px;
    }
}

.share-btn-container {
    .btn {
        margin-right: 16px;
        color: $primary;
        color: var(--primary);
        padding: 0;
        width: 100%;
        height: 46px;
        line-height: 46px;
        border-bottom: 1px solid $dividerColor;
        i {
            font-size: 1.5em;
            line-height: inherit;
        }
    }
}
.see-more-content {
    input {
        display: none;
    }

    label {
        cursor: "pointer";
        font-size: 16px;
        color: #283cd5;
        cursor: pointer;
    }

    input:not(:checked) + div {
        // display: -webkit-box;
        // -webkit-line-clamp: 3;
        // -moz-line-clamp: 3;
        // -ms-line-clamp: 3;
        // -o-line-clamp: 3;
        // line-clamp: 3;
        // -webkit-box-orient: vertical;
        // height: 74px;
        overflow: hidden;
        position: relative;
        // background: red;
        &:after {
            content: "";
            display: block;
            width: 100%;
            position: absolute;
            height: 30px;
            background-image: linear-gradient(rgb(255 255 255 / 0%), white);
            bottom: 0px;
        }
        &.not-overflowing {
            &:after {
                content: none;
            }
        }
    }

    input:checked + div {
        height: auto !important;
        max-height: none !important;
        // -webkit-line-clamp: unset;
        // -moz-line-clamp: unset;
        // -ms-line-clamp: unset;
        // -o-line-clamp: unset;
    }

    p {
        margin-bottom: 0px;
        // display: inline;
    }
}

.seo-section {
    margin-top: 18px;
    // display: none !important;
    @include desktop {
        margin-top: 30px;
    }
    h1 {
        @include desktop() {
            font-size: 24px;
        }
        font-size: 14px;
        // color: #8d8d8d;
        color: #55677c;
        font-weight: bold;
    }
    .line {
        background-color: #55677c33;
        height: 15px;
        width: 1px;
        margin: 0 10px;
    }
    h2 {
        @include desktop() {
            font-size: 22px;
            font-weight: bold;
        }
        font-size: 14px;
        font-weight: 400;
        color: #8a9cb4;
    }
}

.kind-tap-banner {
    position: relative;
    padding: 10px;
    border: 1px solid $dividerColor;
    border-radius: 5px;
    padding-left: 93px;
    margin-bottom: 1rem;
    img {
        position: absolute;
        width: 68px;
        left: 11px;
        top: 50%;
        transform: translateY(-50%);
    }
    .content {
        line-height: 1.35;
        margin-top: 9px;
        opacity: 0.9;
    }
    .k-offer-text {
        position: absolute;
        top: -16px;
        right: 10px;
        border-radius: 5px;
        background: #4caf50;
        padding: 2px 10px;
        letter-spacing: -0.02rem;
        color: rgb(255 255 255 / 87%);
        font-weight: 500;
        box-shadow: 0 2px 5px rgb(0 0 0 / 29%);
    }
    @include desktop {
        padding: 15px;
        padding-left: 115px;
        margin-top: 0.5rem;
        img {
            width: 80px;
            left: 20px;
        }
        .content {
            line-height: 1.4;
        }
        .k-offer-text {
            padding: 4px 15px;
            letter-spacing: 0px;
        }
    }
}
.bento-bottom-catalog {
    .more-btn {
        margin-top: 40px;
        font-size: 14px;
        button {
            background-color: transparent;
            border: none;
            color: rgb(0, 0, 0);
            padding: 0px;
            text-decoration: underline;
        }
    }
    .bottom-catalog-wrapper {
        // padding: 37px 0px 40px;
        &.zipcodes-listing {
            background-color: #ddd !important;
            p {
                color: #333 !important;
                font-size: 18px;
                @include desktop {
                    font-size: 16px;
                }
            }
            .bottom-catalog-title {
                color: #333 !important;
            }
            .bottom-catalog-row {
                color: #333 !important;
                font-variant-numeric: tabular-nums;
                text-align: center;
                text-decoration: none;
            }
            .seeMore {
                height: 60px !important;
            }
            .more-btn {
                // display: grid;
                // grid-template-columns: repeat(6, 1fr);

                button {
                    color: #333;
                }
            }
            .bottom-catalog-listing {
                grid-template-columns: repeat(3, 1fr);
                @include desktop {
                    grid-template-columns: repeat(8, 1fr);
                }
                @include xDesktop {
                    margin: 0 -44px;
                }
                @include ipadProLandscape {
                    margin: 0;
                }
            }
        }
        @include desktop {
            // padding: 64px 0 64px;
        }
        .bottom-catalog-title {
            color: #000;
            font-size: 18px;
            margin-bottom: 5px;
            @include desktop {
                font-size: 30px;
            }
        }
        .bottom-catalog-listing {
            font-size: 14px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-row-gap: 10px;
            margin-bottom: 0px;
            @include desktop {
                grid-template-columns: repeat(6, 1fr);
            }
            .bottom-catalog-row {
                color: #000;
                font-size: 16px;
            }
        }
        .more-btn {
            margin-top: 28px;
            font-size: 14px;
            @include desktop {
                margin-top: 33px;
            }
            button {
                background-color: transparent;
                border: none;
                color: #000;
                padding: 0px;
                text-decoration: underline;
            }
        }

        .seeMore {
            overflow: hidden;
            // height: 155px;
        }
    }
}
.outline-btn {
    border: 1px solid var(--primary);
    border-radius: 6px;
    height: 45px;
    color: var(--primary);
    min-width: 120px;
    font-weight: 700;
    font-size: 18px;

    &:hover {
        background-color: var(--primary);
        border-color: var(--primary);
    }
}

.kt-sm-text {
    font-size: 12px;
    line-height: 1.4;
    display: inline-block;
}

.blob {
    border-radius: 50%;
    background: rgba(51, 217, 178, 1);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
    margin: 10px;
    height: 20px;
    width: 20px;
    transform: scale(1);
    animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 30px rgba(51, 217, 178, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
    }
}

.animated-radio {
    .subtitle {
        span {
            font-weight: bold;
            padding: 0 3px;
            border-radius: 3px;
        }
    }
}

.start-animate {
    .subtitle {
        animation: shake-text 0.2s 2;
        span {
            animation: blink 0.8s 0.5s 5;
        }
    }
}

@keyframes blink {
    0% {
        opacity: 0.2;
        background-color: #fce68c;
    }
    50% {
        opacity: 1;
        background-color: #fce68c;
    }
    100% {
        opacity: 0.2;
        background-color: #fce68c;
    }
}

@keyframes shake-text {
    0% {
        transform: translateX(0);
    }

    30% {
        transform: translateX(-8px);
    }

    50% {
        transform: translateX(5px);
    }

    70% {
        transform: translateX(-7px);
    }

    100% {
        transform: translateX(0);
    }
}

.Toastify__toast-container {
    --toastify-toast-width: 400px;
}

.replace-pop-up {
    .modal-dialog {
        margin: auto !important;
    }
}
.asap-delivery-toast {
    --toastify-font-family: $font-family-sans-serif;
    * {
        color: #333;
    }
    .Toastify__toast-icon {
        align-self: flex-start;
        margin-top: 4px;
    }
    .t-header {
        font-size: 16px;
        line-height: 1.25;
    }
    .t-body {
        line-height: 1.35;
        margin: 3px 0;
    }
    .t-footer {
        font-size: 13px;
        i {
            vertical-align: middle;
        }
    }
}
//check again
.address-type {
    width: 100%;
    row-gap: 15px;
    flex-direction: column;
    @include desktop() {
        column-gap: 15px;
        row-gap: 0;
        flex-direction: row;
    }
    .type {
        color: $text-light;
        border: 1px solid #c9cfd5;
        border-radius: 8px;
        font-size: 16px;
        flex: 1;
        background: transparent;
        height: 35px;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;

        &.active {
            background: var(--primary-light);
            color: var(--primary);
            border-color: var(--primary);
        }

        span[class^="icon-"] {
            color: var(--primary-dark);
            font-size: 20px;
            display: inline-block;
            margin-right: 8px;
        }
        &.other {
        }
    }
}
.card-parent .card-list .ledger-green-view-inner {
    .custom-radio {
        border-radius: 0;
        &:first-child {
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
        }
        &:last-child {
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
        }
    }
}

.qtn-progress {
    flex: 1;
    height: 16px;
    background: #ebebeb;
    display: flex;
    // line-height: 0;
    font-size: 0.75rem;
    border-radius: 18px;
    position: relative;

    .qtn-progress-bar {
        // position: relative;
        background-color: #ebe7f3;
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        transition: width 0.6s ease;

        // border-left-color: orange;
        background: linear-gradient(90deg, #e0f0d8 -0.22%, #b1c7b0 99.78%);
        // min-width: 60px;
        border-radius: 18px;

        span {
            background: linear-gradient(90deg, #3e8a94 -0.22%, #41bf92 99.78%);
            width: 60px;
            padding: 0 5px;
            border-radius: 18px;
            position: absolute;
            color: white;
            height: 16px;
            line-height: 1.4;
        }
    }
}

.replace-pop-up {
    .modal-dialog {
        margin: auto !important;
    }
}

.star-impt {
    color: #f44336;
}

.main-page-loader {
    height: 100vh;
}

.cropper-point {
    width: 15px !important;
    height: 15px !important;
}
.help-phone-number {
    padding: 10px 15px;
    text-align: center;
    background: var(--primary);
    // position: sticky;
    // top: var(--navbar-height);
    color: #fff;
    // z-index: 2;
    span {
        vertical-align: middle;
    }
    i {
        margin-right: 5px;

        vertical-align: middle;
    }
    a {
        text-decoration: underline;
        color: white;
        font-size: 1.1em;
    }
    @include desktop {
        margin-top: -1rem;
        font-size: 20px;
    }
}

.see-more-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    position: relative;
    span {
        border: 1px solid var(--primary);
        color: var(--primary);
        padding: 0 5px;
        margin-left: 5px;
    }
    &.restrict-two {
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -moz-line-clamp: 2;
        -ms-line-clamp: 2;
        -o-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        height: 36px;
        span {
            display: flex;
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: white;
            border: 0;
            color: inherit;
            margin-left: 0;
        }
        u {
            color: #283cd5;
            text-decoration: underline;
        }
    }
}
.tesla-cart-wrapper {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

.view-all {
    font-weight: 400;
    font-size: 14px;
    @include desktop {
        font-size: 16px;
    }
}
.special-container {
    .category-banner-extra {
        .btn {
            border: 1px solid var(--primary);
            color: var(--primary);
        }
    }
}
.upsell-product {
    .new-add-to-cart {
        position: absolute;
        top: 9px;
        right: 11px;
        z-index: 1;
        color: #000;
        .add-to-cart {
            position: relative;
            width: 28px;
            height: 28px;
            border: none;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
            padding: 2px 4px;
            border-radius: 50%;
            background-color: var(--primary);
            .c-icon {
                font-size: 12px;
                top: 0px;
                left: 0px;
                font-weight: bold;
                color: #fff !important;
            }

            &.opacify {
                .c-icon {
                    display: none;
                }
            }
        }

        // .add-to-cart {
        //     position: relative;
        //     width: 34px;
        //     height: 34px;
        //     border: none;
        //     .c-icon {
        //         font-size: 12px;
        //         top: 0px;
        //         left: 0px;
        //         font-weight: bold;
        //         // color: #000 !important;
        //     }
        // }
        &:hover .add-to-cart {
            background-color: transparent;
            border: 1px solid var(--primary);
            .c-icon {
                color: var(--primary) !important;
            }
            & + .p-count .count {
                color: var(--primary);
            }
        }
    }
    // .top-container {
    //     cursor: default;
    //     img {
    //         cursor: default;
    //     }
    //     &:hover {
    //         .new-add-to-cart {
    //             .add-to-cart {
    //                 background-color: #fff;
    //             }
    //         }
    //     }
    // }
    .product-counter-parent {
        height: auto !important;
        position: absolute;
        bottom: 0;
        background-color: white;
        right: 0;
        transition: all 0.2s ease-out;
        transform-origin: right;
        .count {
            @include notDesktop {
                height: auto;
                line-height: 1;
            }
        }
        .btn {
            transition: all 0.2s linear 0s;
            color: $primary;
            color: var(--primary);
            height: 25px;
            width: 25px;
            padding: 6px;
            line-height: 1;
            font-weight: bold;
            font-size: 0.9em;
            & ~ .btn {
                //transition-delay:0.1s;
            }
            i {
                //transition:all 0.2s linear 0.0s;
            }
            &:disabled {
                color: grey !important;
            }
        }
        //left:auto;
        &.p-counter {
            //width:100%;
            left: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.19);
            border-color: transparent;
            @include real-iphone5 {
                .inline-loader {
                    &:after {
                        right: 8px;
                    }
                }
            }
        }
        &.p-count {
            width: 30px !important;
            height: 30px !important;
            border-radius: 50%;
            border: 1px solid $primary;
            border: 1px solid var(--primary);
            //right: -15px;
            padding: 4px;
            left: calc(100% - 25px);
            .inline-loader {
                top: -22px;
                left: -8px;
                @include desktop {
                    top: -20px;
                    left: -6px;
                }
                @include real-iphone5 {
                    top: -20px;
                    left: -11px;
                }
            }
            .btn {
                //display: none;
                //position: absolute;
                width: 0;
                overflow: hidden;
                padding: 0;
                line-height: 0;
                font-size: 0;
                border: 0;
                height: 0;
                &:focus {
                    box-shadow: none;
                }
                i {
                    font-size: 0;
                }
            }
        }
        // &.lp{
        // 	&.p-count{
        // 		left:calc(100% - 20px);
        // 	}
        // }
        @include desktop {
            .count {
                font-size: 1rem;
            }
            right: 40px;
            &.p-counter {
                left: 40px;
                width: auto !important;
            }
            &.p-count {
                left: calc(100% - 40px);
                height: 30px !important;
                width: 30px !important;
                padding: 6px;
                border-width: 2px;
            }
            .btn {
                height: 32px;
                width: 32px;
                font-size: 1em;
            }
        }
        @include real-iphone5 {
            &.p-count {
                height: 25px !important;
                width: 25px !important;
                padding: 4px;
            }
            .btn {
                padding: 0;
                width: 20px;
            }
        }

        &.version-2 {
            .btn:focus {
                box-shadow: none;
            }
            &.p-counter {
                right: 0;
                /* transform: translateX(50%); */
                top: 0px;
                z-index: 1;
                left: auto;
                border: 1px solid var(--primary);
                bottom: auto;
                border-radius: 2px;
                padding: 4px 6px;
                .count {
                    font-size: 18px;
                    width: 45px;
                    text-align: center;
                    color: var(--primary);
                }
                @include desktop {
                    padding: 1px 6px;
                }
                &:after {
                    // content: "";
                    // position: absolute;
                    // width: 10px;
                    // height: 10px;
                    // transform: rotate(45deg) translateX(-50%);
                    // border: 1px solid var(--primary);
                    // left: 50%;
                    // background: inherit;
                    // z-index: 0;
                    // top: -3px;
                    // border-bottom-color: transparent;
                    // border-right-color: transparent;
                }
                .a-loader {
                    width: 45px !important;
                    &:after {
                        right: 15px;
                    }
                }
            }
            &.p-count {
                left: auto;
                right: 0px;
                text-align: center;
                top: 0px;
                pointer-events: none;
                background: transparent;
                border: none;
                .btn {
                    width: 0;
                    height: 0;
                    box-shadow: none;
                    font-size: 0;
                    padding: 0;
                    position: absolute;
                }
                @include desktop {
                    top: -3px;
                    right: 0px;
                }
            }
            .count {
                color: #fff;
                font-size: 15px;
                font-weight: 500;
            }
            .btn {
                font-size: 16px;
                // height: 14px;
                // width: 14px;
                // border-radius: 50%;

                // box-shadow: 0px 0px 0px 1px #13c7cd;
                padding: 0;
                @include desktop {
                    padding: 6px;
                }
                // line-height: 15px;
                &.no-circle {
                    // box-shadow:none;
                    // font-size: 12px;
                }
                * {
                    color: var(--primary);
                }
                &:disabled {
                    i {
                        color: grey !important;
                        cursor: no-drop;
                    }
                }
            }
        }
    }
}

.bottom-catalog-wrapper {
    padding: 37px 0px 40px;
    &.brands-listing {
        background-color: #f7f7f7 !important;
        p {
            color: #000 !important;
            // font-size: 18px;
        }
    }
    &.zipcodes-listing {
        background-color: #ddd !important;
        p {
            color: #333 !important;
            font-size: 18px;
            @include desktop {
                font-size: 16px;
            }
        }
        .bottom-catalog-title {
            color: #333 !important;
        }
        .bottom-catalog-row {
            color: #333 !important;
            font-variant-numeric: tabular-nums;
            text-align: center;
            text-decoration: none;
        }
        .seeMore {
            height: 60px !important;
        }
        .more-btn {
            // display: grid;
            // grid-template-columns: repeat(6, 1fr);

            button {
                color: #333;
            }
        }
        .bottom-catalog-listing {
            grid-template-columns: repeat(3, 1fr);
            @include desktop {
                grid-template-columns: repeat(8, 1fr);
            }
            @include xDesktop {
                margin: 0 -44px;
            }
            @include ipadProLandscape {
                margin: 0;
            }
        }
    }
    &.cities-listing {
        // background-color: #353034;
        background-color: #f7f7f7 !important;
        * {
            color: #000;
        }
        .google-review-link {
            color: #283cd5 !important;
        }
    }
    @include desktop {
        padding: 64px 0 64px;
    }
    .bottom-catalog-title {
        color: #000;
        font-size: 18px;
        margin-bottom: 5px;
        @include desktop {
            font-size: 30px;
        }
    }
    .bottom-catalog-listing {
        font-size: 14px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 10px;
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: space-between;
        // color: #fff;
        margin-bottom: 0px;
        @include desktop {
            grid-template-columns: repeat(6, 1fr);
        }
        // height: 135px;
        // overflow: hidden;
        .bottom-catalog-row {
            // margin-bottom: 6px !important;
            color: #000;
            font-size: 16px;
            // flex: 50%;
            // @include desktop{
            //   flex: 15%;
            // }
        }
    }
    .more-btn {
        margin-top: 28px;
        font-size: 14px;
        @include desktop {
            margin-top: 33px;
        }
        button {
            background-color: transparent;
            border: none;
            color: #000;
            padding: 0px;
            text-decoration: underline;
        }
    }

    .seeMore {
        overflow: hidden;
        height: 155px;
    }
}
.view-all {
    font-weight: 400;
    font-size: 14px;
    @include desktop {
        font-size: 16px;
    }
}
.special-container {
    .category-banner-extra {
        .btn {
            border: 1px solid var(--primary);
            color: var(--primary);
        }
    }
}
@keyframes pulse {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(0.9);
    }
    50% {
        transform: scale(1.3);
    }
    75% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(1);
    }
}

//test
.version-2 {
    .product-page {
        .search-category-wrapper {
            position: sticky;
            top: var(--navbar-height);
            background: var(--body-background-color);
            z-index: 9;
            // @include desktop {
            //     position: static !important;
            // }
        }
        .outer-layer-pdp {
            // padding-top: var(--navbar-height) !important;
            @include desktop {
                padding-top: 0px !important;
                // margin-top: 1rem !important
            }
        }
        .latest-product {
            .bottom-container .brand-name {
                color: #262626 !important;
            }
        }
    }
    .product-page-popup {
        .product-page .outer-layer-pdp {
            padding-top: 0.5rem !important;
            @include desktop {
                padding-top: 0px !important;
            }
        }
    }
    .profile-account {
        margin-top: var(--navbar-height);
        @include desktop {
            margin-top: 0px;
        }
    }
    .categoryp-wrap,
    .cityp-wrap {
        padding-top: 0;
        @include desktop {
            padding-top: var(--navbar-height);
        }
    }
    // .freight-message-banner{
    //     background-color: var(--primary-lightest);
    // }
    .gc-redeem {
        margin-top: var(--navbar-height);
        @include desktop {
            margin-top: calc(var(--navbar-height) + 38px);
        }
    }
}
.minus-sale {
    display: none;
}
.without-toggle {
    flex-grow: 1;
    // background-color: red;
    // padding: 14px 3px !important;
    padding: 7px 3px !important;
    min-height: 50px !important;
    .bottom-right {
        flex-grow: 1;
        gap: 0 !important;
        .v-gradient-d {
            position: relative;
            &::after {
                content: "";
                position: absolute;
                right: 0;
                height: 47px;
                border-width: 0.5px;
                border-image-source: linear-gradient(
                    to bottom,
                    rgba(208, 208, 208, 0) 0.05%,
                    #cacaca 48.16%,
                    rgba(204, 204, 204, 0) 101.56%
                );
                border-style: solid;
                border-image: linear-gradient;
                border-image-slice: 1;
            }
        }
        .filter-btn,
        .help-icon-info {
            flex-grow: 1;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .gd-number {
            flex-grow: 1;
            text-align: center;
            font-weight: 600;
            line-height: 15px;
            color: #37475b;
            display: flex;
            align-items: center;
            justify-content: center;
            .icon {
                font-size: 20px;
                vertical-align: middle;
                margin-right: 10px;
                color: #37475b;
            }
        }
    }
}
.fresh-drop {
    background: linear-gradient(138deg, #05a0a8 0%, #404dc1 100%);
    color: #ffffff;
    font-size: 11px;
    z-index: 1;
    position: absolute;
    margin-left: 8px;
    margin-top: 7px;
    font-weight: 700;
    letter-spacing: 1.2px;
    width: 46px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    border-top-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.shop-full-menu {
    .mobile-toggle {
        // margin-top: 20px;
        @include desktop {
            display: none;
        }
    }
    .filter-product-page {
        .v-list {
            &.product-category {
                margin-bottom: 3rem;
                @include mobile {
                    grid-template-columns: 1fr 1fr;
                }
                @include mobileLandscape {
                    grid-template-columns: 1fr 1fr;
                }

                .latest-product .strain-fix-container {
                    @include mobile {
                        display: block !important;
                    }
                    .thc-cbd {
                        @include mobile {
                            margin-left: 0px;
                            margin-top: 6px !important;
                            &::before {
                                content: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

.loader-parent-cart + hr {
    display: none;
}
.help-icon-info {
    display: block;
    @include desktop {
        // margin-right: 35px !important;
        display: block;
        display: flex !important;
        cursor: pointer;
    }
    .icon {
        font-size: 22px;
        font-weight: 500 !important;
        color: #37475b !important;
    }
}
.conc-non-progress {
    height: 10px;
    margin: 2px 0px;
}
.shop-full-menu {
    .product-grid-structure {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 24px;
    }
}
.animated-popup-wrapper {
    .modal-dialog {
        animation: modal-open-anim 0.8s;
        animation-fill-mode: forwards;
    }

    @keyframes modal-open-anim {
        0% {
            opacity: 0;
            transform: scale(0.75);
        }

        100% {
            opacity: 1;
            transform: scale(1);
        }
    }
}

// .add-cart-img{
//     .img-wrapper:hover{

//         picture{
//                 display: block !important;
//                 // position: fixed;
//                 animation-name: add-img-cart;
//                 animation-duration: 3s;
//                 transition: transform 0.3s ease, opacity 0.3s ease;
//                 transform: scale(0.5);
//                 z-index: 9999;
//                 position: relative;
//                     top: 0px;
//                     left: 0px;

//         }
//                 @keyframes add-img-cart {
//                     0% {
//                         position: fixed;
//                         left: 0;
//                         opacity: 0.9;
//                         transform: scale(0.3);
//                         width: 100%;
//                             height: 100%;
//                             top: 0;

//                     }

//                     100% {

//                                 position: fixed;

//                         opacity: 0;
//                         transform: scale(0.02);
//                             top: -30%;
//                                 left: 90vh;
//                                 width: 100%;
//                                     height: 100%;
//                     }
//                 }
//     }
// }

.top-container {
    display: block;
    .sim {
        picture {
            display: inline-block !important;
            animation-name: add-img-cart;
            animation-duration: 3s;
            animation: add-img-cart 5s forwards infinite;
            transition:
                transform 0.3s ease,
                opacity 0.3s ease;
            transform: scale(0.5);
            z-index: 9999;
            position: fixed;

            width: 100px;
            height: 60px;
        }
        @keyframes add-img-cart {
            0% {
                // top: calc(100% - 10px);
                right: calc(100% - 10px);
                opacity: 0.9;
            }

            100% {
                top: 30px;
                right: -20px;
                opacity: 0;
            }
        }
    }
}

.btn-purple {
    background: var(--primary);
    color: var(--white-text-on-primary);

    &:hover {
        background: var(--primary);
        color: var((--white-text-on-primary));
        outline: 2px solid var(--light-primary);
    }
}

.no-product-img {
    margin: 0 auto;
}

.carousel-banner-img {
    max-height: 570px;
    object-fit: cover;
}

.max-content-height {
    height: max-content;
}

body.version-2 {
    .wishlist {
        .v-list {
            @include mobile {
                grid-template-columns: repeat(2, 1fr);
                gap: 12px;
            }
        }
    }
}

body.default-site {
    .btn-primary {
        background-color: var(--primary);
        border-color: var(--primary);
        color: #fff;

        &:disabled {
            opacity: 0.6;
            cursor: no-drop;
        }
    }

    .outline-btn {
        height: 40px;
        font-weight: 400;
        font-size: 16px;
        &:hover {
            color: #fff;
        }
    }
}

.strike-text {
    font-weight: bold !important;
}

.delivery-type-selector-popup {
    .modal-dialog {
        @media (max-width: 768px) {
            margin: 1.75rem auto;
        }
    }
    .modal-content {
        height: 450px !important;
        max-height: unset !important;
        @include notDesktop{
            height: unset !important;
        }
        .content-holder {
            .img-holder {
                display: flex;
                align-items: center;

                span {
                    background: var(--light-primary);
                    width: 50px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &.icon1 {
                        border-top-left-radius: 12px;
                        border-bottom-left-radius: 12px;
                    }
                    &.icon2 {
                        border-top-right-radius: 12px;
                        border-bottom-right-radius: 12px;
                    }
                }
            }
            .btn-primary {
                align-items: center;
                display: flex;
                margin: auto;
                width: 150px;
                margin-bottom: 6px;
                justify-content: center;
                span {
                    margin-right: 6px;
                }
            }
            .line {
                position: relative;
                @include notDesktop {
                    margin-bottom: 32px;
                }
                &::before {
                    content: "";
                    position: absolute;
                    right: 0px;
                    top: 0;
                    width: 1px;
                    height: 100%;
                    background: #e0e0e0;

                    @include notDesktop {
                        right: unset;
                        transform: translateX(-50%);
                        width: 80%;
                        height: 1px;
                        top: unset;
                        bottom: -15px;
                        left: 50%;
                    }
                }
            }
        }
    }
}

.Toastify__toast-container {
    font-family: $font-family-sans-serif;
}

.Toastify__toast--success {
    background: linear-gradient(270deg, #1b5e20 0%, #6abe6d 100%) !important;
    color: #ffffff;
    button {
        color: #ffffff;
        opacity: 1;
    }

    .Toastify__toast-icon {
        svg {
            fill: #ffffff;
        }
    }
}

.Toastify__toast--error {
    background: linear-gradient(270deg, #c62828 0%, #f88d8b 100%) !important;
    color: #ffffff;
    button {
        color: #ffffff;
        opacity: 1;
    }

    .Toastify__toast-icon {
        svg {
            fill: #ffffff;
        }
    }
}

.Toastify__toast--warning {
    background: linear-gradient(270deg, #e06500 0%, #ea8e06 100%) !important;
    color: #ffffff;
    button {
        color: #ffffff;
        opacity: 1;
    }

    .Toastify__toast-icon {
        svg {
            fill: #ffffff;
        }
    }
}

.category-product {
    .filter-product-page {
        .product-category {
            .latest-product {
                .thc-cbdx {
                    font-size: 12px;
                    margin-top: 0;
                    color: rgba(0, 0, 0, 0.56);
                    line-height: 15px;
                }
            }
        }
    }
}

.view-all-products-card{
    border: 1px solid rgba(0, 0, 0, .12);
    border-radius: 8px;
    background: #fff;
    box-shadow: 4px 1px 8px 0 #eee;
    flex: 0 0 200px;
    margin-right: 18px;
    min-height: 365px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10px;

    @include desktop {
        flex: 0 0 240px;
    }

    @include mobile(){
        min-height: 395px;
    }
}

.product-category{
    .a-category-name{
        &.title{
            width: calc(100% - 100px);
        }
    }
    .compare-link{
        &.compare{
            position: absolute;
            right: 0;
            padding-top: 0;
            padding-bottom: 0;
            @include notDesktop{
                font-size: 15px;
            }
        }
    }
}

body.default-site {
   .product-page{
        .shop-full-menu{
            @include mobile{
                padding-top: 0;
            }
        }
    } 
}