$desktop-width: 992px;
$tab-width: 768px;
$x-desktop-width: 1200px;

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}
@mixin tab {
  @media (min-width: #{$tab-width}) {
    @content;
  }
}

@mixin notTab {
  @media (max-width: #{$tab-width - 0.02}) {
    @content;
  }
}
@mixin xDesktop {
  @media (min-width: #{$x-desktop-width}) {
    @content;
  }
}

@mixin ipadProLandscape {
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) and (hover: none) {
    @content;
  }
}
@mixin mobile {
  @media (max-width: #{$tab-width - 1px}) {
    @content;
  }
}

:root {
  --navbar-height: calc(70px + var(--hh-height) + var(--delivery-info-height));
  // --navbar-height: calc(70px + var(--delivery-info-height)); //homepage
  --secondary-navbar-height: 50px;
  // --primary: #05a0a8 !important;
  --primary: #000000;
  // --primary: #ff0000 !important;
  --primary-light: rgba(138, 100, 206, 0.5) !important;
  --new-secondary: #6da738 !important;
  --primary-dark: #6da738 !important;
  // --primary-light: #f8ffff;
  // --primary-lightest: #edf6f4;
  --primary-light: #d9f4ee;
  --primary-lightest: #f2edf6;
  --cat-menu-tab-height: 40px;
  //  --cat-menu-tab-height: 41px; //homepage
  //  --cat-menu-tab-height:41px;
  //--primary:#36c1c8!important;

  // --brand-gradient-bottom:#0097aa!important;
  // --brand-gradient-top: #004f59!important;
}

@include desktop {
  :root {
    --navbar-height: calc(94px + var(--hh-height) + var(--delivery-info-height));
    --hh-height: 0px !important;
  }
}
.strike-text:after {
  background: red;
	height: 2px;
}
body.default-site {
  &.is-cart-page {
    .cart {
      @include desktop {
        width: 1140px;
      }
    }
    .footer {
      display: none;
      @include desktop {
        display: block;
      }
    }
  }

  .sidebar .content .sidebarBanner,
  .compare-btn,
  .sidebarBg,
  .offer-box,
  .deal-header,
  .auth-status,
  .xy-product-offer,
  .bulk-product-popup .product-conter-box .bottom-section,
  .add-to-cart-holder,
  .filter-drawer .content .sidebarBanner,
  .referral .blur-container,
  .delivery-threshold,
  .filter-btn,
  .checkout-text i,
  .gift-card,
  .xy-product-offers-wrapper {
    // --primary: #154133 !important;
  }
  .btn-primary,
  .btn-outline-primary {
    // --primary: #154133 !important;
    --primary-dark-7_5: #04553b !important;
    --primary-dark-10: #04432f !important;
  }
  .custom-radio.joined-style {
    // --primary: #154133 !important;
  }
  .default-l-primary {
    // --primary: #154133 !important;
  }
  .gd-dark-primary {
    color: var(--primary) !important;
  }
  .age-popup {
    .logo-container {
      height: 110px;
      min-height: 20px;
      margin: 0 auto;
      width: 125px;
    }
    @include notTab {
      .modal-dialog {
        margin-top: 0;
        margin-bottom: 0;
        min-height: 100%;
        .modal-content {
          border-radius: 0px;
          height: 100vh;
          display: flex;
          justify-content: center;
        }
        .modal-body {
          flex: none;
        }
      }
    }
  }
  .footer {
    box-shadow: none !important;
    .footer-links {
      color: #fff !important;
    }
    background-color: #333;
    .logo {
      height: 60px !important;
      margin-bottom: 20px !important;
      @include desktop {
        margin-bottom: 0px !important;
        height: 68px !important;
      }
    }
    .footer-top-section {
      background-color: #333;
      padding-top: 20px;
      padding-bottom: 60px;
      * {
        color: #afafaf;
      }
      @include desktop {
        padding-top: 0;
        padding-bottom: 0px;
      }
      .footer-top-section-inner {
        padding: 30px 0px 0;
        @include desktop {
          padding: 70px 0px 0;
          padding-bottom: 100px !important;
        }
      }
    }
    .footer-bottom-section {
      left: 0;
      right: 0;
      bottom: 0;
      // background-color: #EBEBEB !important;
      bottom: -90px;
      @include desktop {
        bottom: 0px;
      }
      padding: 12px 0 !important;
      padding: 20px 0 !important;
      background-color: #242424 !important;
      * {
        color: #fff;
      }
      .icon-holder {
        display: none;
      }
    }
  }

  .navbar .set-delivery-address-btn {
    // padding: 0 140px 0 50px;  //uncoment this if search is enabled for mobile
  }

  .shop-full-menu,
  .category-product,
  .confirmation-parent {
    padding-top: var(--navbar-height);
    @include desktop {
      padding-top: var(--navbar-height);
    }
  }
  .partner {
    padding-top: 15px;
  }

  // new Driver page css
  .partner.new-driver {
    --grey: #f1f1f1;
    --dark-text: #212529;
    --teal: #58bcad;
    .banner {
      padding: 5rem 0 3rem;
      background: #eaf7f7;
      margin-bottom: 0;

      .container {
        @include tab {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
        }
      }

      .left-content {
        text-align: center;
        margin-bottom: 2rem;

        @include tab {
          text-align: left;
        }

        .logo {
          @include tab {
            margin-bottom: 2rem;
          }
          img {
            height: 40px;
            width: auto;

            @include tab {
              height: 50px;
            }

            @include desktop {
              height: 60px;
            }
          }
        }
        .banner-text {
          .title {
            margin-bottom: 1rem;
          }
          p {
            font-size: 13px;
            line-height: 20px;
            color: var(--dark-text);
            font-weight: 500;
          }
        }
      }

      .right-content {
        text-align: center;

        // @include tab {
        //   text-align: left;
        // }

        img {
          width: 300px;
          height: 300px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
    }

    .form-icon-container {
      .container {
        padding-left: 0;
        padding-right: 0;
        @include tab {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
        }
      }

      &#driversignup {
        &:target:before {
          content: "";
          display: block;
          height: 100px;
          margin: -100px 0 0;

          @include desktop {
            height: 170px;
            margin: -40px 0 0;
          }
        }
      }
    }

    .form-container {
      text-align: center;
      padding: 2rem 0px;
      @include tab {
        position: relative;
        top: -7rem;
        padding-bottom: 0;
      }

      form {
        background: var(--primary-dark-7_5);
        margin: auto;
        padding: 2rem 1.5rem 1.5rem;
        border-radius: 15px;

        @include tab {
          padding: 2.5rem 2rem;
        }

        h4 {
          color: #fff;
          font-weight: 600;
          margin-bottom: 1rem;
        }

        .selectbox {
          text-align: left;
        }

        label {
          color: white;
        }

        button {
          background: white;
          color: var(--primary-dark-7_5);
          font-weight: 700;
          border-radius: 10px;
          padding: 0.5rem 2rem;
          margin-top: 1rem;
        }
      }
    }

    .openings-container {
      padding: 0;

      .title-container {
        background: #f6f6f6;
        padding: 2rem 0;
        h4 {
        }
        p {
          margin: 0;
          padding-right: 1rem;
          line-height: 19px;
        }
      }

      .opening-content {
        padding: 2rem 0;

        ul {
          list-style: none;
          padding-inline-start: 0;
          li {
            margin-bottom: 2rem;
            ul {
              list-style: disc;
              padding-inline-start: 40px;
              margin-top: 2rem;
            }
          }
        }
      }

      .apply-container {
        padding: 2rem 15px;
        background: var(--grey);

        h4 {
          text-align: center;
          padding: 0 1rem;
          font-weight: 700;
          color: var(--primary);
        }

        .apply-btn-container {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          padding: 1rem 0 0;

          .left-content,
          .right-content {
            text-align: center;
            padding: 1rem 0;

            p {
              font-size: 1rem;
              color: var(--primary);
              font-weight: 700;
              line-height: 18px;
            }

            a {
              padding: 0.4rem 0.6rem;
              background: var(--primary);
              border-radius: 10px;
              color: white;
              font-weight: 700;
              font-size: 1rem;
              text-decoration: none;
              box-shadow: 0px 2px #6a6a6a;
            }
          }

          .left-content {
            border-right: 1px solid var(--primary);
          }
        }
      }
    }

    .testimonial-container {
      padding: 2rem 0;

      h4 {
        font-weight: 700;
        color: var(--primary);
        padding-right: 4rem;
      }
    }

    .refer-form-container {
      padding: 2rem 0;
      background: var(--primary-dark-7_5);
      color: white;

      .container {
        @include tab {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 2rem;
        }
      }

      h4 {
        font-weight: 700;
        padding-right: 4rem;
      }

      p {
        color: white;
        font-size: 1rem;
        line-height: 20px;
        margin-top: 1rem;
        margin-bottom: 3rem;
      }

      .refer-form {
        text-align: center;

        button {
          background: white;
          color: var(--primary-dark-7_5);
          margin-top: 1rem;
          box-shadow: 0px 2px #6a6a6a;
        }
      }
    }

    .captcha {
      // margin-bottom: 1rem;
      flex-direction: column-reverse;
    }

    .error-msg,
    .error {
      color: #ffb5b0;
    }
  }

  .icons-container {
    padding: 2rem 15px;
    display: flex;
    justify-content: center;

    @include tab {
      padding: 5rem 15px;
      padding-bottom: 0;
    }
    ul {
      list-style: none;
      padding-inline-start: 0;
      padding: 0 1.5rem;

      li {
        display: grid;
        grid-template-columns: 100px 177px;
        align-items: center;
        margin-bottom: 2.5rem;
        span {
          width: 70px;
          height: 70px;
          background: var(--grey);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          i {
            font-size: 2.5rem;
            padding-bottom: 3px;
            color: var(--primary);
          }
        }
        p {
          font-size: 1.2rem;
          margin-bottom: 0;
          color: var(--primary);
          font-weight: bold;
        }
      }
    }
  }

  .testimonial-content {
    margin: 2.5rem 0;

    @include tab {
      margin: 4.5rem 0;
    }

    .testimonial-card {
      display: grid;
      grid-template-rows: max-content auto;
      row-gap: 1rem;
      margin: 2rem 0;

      @include tab {
        grid-template-rows: 0;
        row-gap: 3rem;
        margin: 0 0 8rem;
      }

      .img-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 2.2rem;
        margin-bottom: 0.7rem;
        // display: grid;
        // grid-template-columns: repeat(2,1fr);
        // align-items: center;
        // column-gap: 1.1rem;

        @include tab {
          width: max-content;
          align-items: flex-start;
          justify-content: center;
        }

        img {
          border-radius: 50%;
          width: 150px;
          height: 150px;
          object-fit: cover;

          @include tab {
            width: 300px;
            height: 300px;
            margin-right: 3rem;
          }
        }

        .driver-name {
          max-width: 130px;
          @include tab {
            max-width: none;
          }
          h4 {
            color: var(--primary-dark-7_5);
            font-weight: 700;
            font-size: 1.6rem;
            padding: 0;
          }
          p {
            color: var(--primary);
            font-weight: 700;
            font-size: 1.2rem;
          }
        }
      }

      .text-container {
        @include tab {
          padding-left: 22rem;
          padding-top: 3rem;
        }
        p {
          font-size: 1rem;
          color: var(--primary);
        }
      }

      &:nth-child(even) {
        .img-container {
          flex-direction: row-reverse;
          @include tab {
            justify-content: space-between;
            width: 100%;
          }

          img {
            @include tab {
              margin-right: 0;
              margin-left: 3rem;
            }
          }
        }

        .text-container {
          @include tab {
            padding-left: 0;
            padding-right: 22rem;
          }
        }
      }
    }
  }

  // .paymentSettings{
  // 	//--secondary-navbar-height: calc(-1 * (var(--secondary-navbar-height) - 10px));
  // 	--secondary-navbar-height:-54px;
  // 	@include desktop {
  // 		--secondary-navbar-height: 0px;
  // 	}
  // }

  .product-page,
  .prescription {
    padding-top: var(--navbar-height);
  }
  .navbar-height-padding-top {
    padding-top: var(--navbar-height);
  }
  .confirmation-parent {
    height: auto;
    @include desktop {
      padding-top: 0 !important;
    }
  }

  .product-page {
    // padding-top: 0;
    @include desktop {
      padding-top: var(--navbar-height);
    }
  }

  .default-site-banner {
    .just-a-container {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .click-capture-tag {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      cursor: pointer;
    }

    @include desktop {
      margin-bottom: 1rem;

      .address-box {
        //width:100%;
        text-align: center;
        position: absolute;
        bottom: 55px;
        top: auto;
        left: 50%;
        transform: translateX(-50%);

        a {
          //box-shadow: none;
          //width: 550px;
          //margin: auto;
          //display:flex;
          //justify-content:center;
          border-radius: 6px;

          .flex-item {
            //flex-grow: 0;
            //width: 40px;
          }

          .adddress {
            //max-width: calc(100% - 40px);
            //width: auto;
          }
        }
      }

      .info-sec {
        background: white;
        margin-top: 2rem;
        padding-top: 0;
      }

      .control-dots {
        width: auto;
        right: 10px;
      }
    }
    &.with-secondary-banner {
      // margin: 65px 0 0 !important;
      min-height: 400px;
      margin: 0 !important;
      height: 100%;
      @include tab() {
        min-height: 400px;
        height: 100%;
      }
      @include desktop() {
        min-height: 500px;
        margin: 0 !important;
        height: 100%;
      }
      .banner-inner {
        height: 100%;
      }
      .carousel .slide {
        padding: 0;
        min-height: 400px;
        height: 100%;
        @include tab() {
          min-height: 400px;
          height: 100%;
        }
        @include desktop() {
          min-height: 500px;
          margin: 0 !important;
          height: 100%;
        }
        picture {
          height: 100%;
        }
        img {
          height: 100%;
          object-fit: cover;
        }
      }
      + .search-category-wrapper {
        margin-top: 10px;
      }
    }
  }
  .search-tabs-control-enter-done {
    + .shop-full-menu .default-site-banner {
      &.with-secondary-banner {
        @include mobile {
          // margin-top: 140px !important;
        }
      }
    }
  }
  .light-bg-product {
    overflow: hidden;
    .img-wrapper {
      background: #bbe5e3;
      margin: 0px -10px;
      margin-bottom: 0;
      padding-bottom: 0px;
      height: 205px !important;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: -20px;
        background: inherit;
        height: 20px;
        z-index: 0;
      }
      @include desktop {
        height: 242px !important;
      }
    }
    @include desktop {
      height: 242px !important;
    }
  }
  &.product-grid {
    .img-wrapper {
      height: 215px !important;
      @include desktop {
        height: 265px !important;
      }
    }
  }
  .freight-message-banner {
    background-color: var(--light-primary) !important;
  }
}

// .gift-card {
//   background: #e8feff;
//   display: flex;
//   padding: 50px 20px;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   text-align: center;
//   @include desktop() {
//     flex-direction: row;
//     gap: 96px;
//     text-align: left;
//   }
//   .earn-text {
//     font-size: 24px;
//   }
//   .btn:focus {
//     filter: brightness(0.8);
//   }
// }

.filter-product-page {
  .k-filter-content {
    display: none !important;
  }
  display: block !important;
  @include desktop {
    .new-filters {
      .sidebarBanner {
        padding: 12px 0 12px 15px;
      }

      .menu-list-parent {
        height: calc(100vh - var(--navbar-height) - 70px);
      }
    }
  }
}
.brands .filter-product-page {
  // @include desktop{
  //  .new-filters{
  //     .sidebarBanner {
  //       padding: 2px 0 2px 15px;
  //     }
  //   .menu-list-parent{
  //     height: calc(100vh - var(--navbar-height) - 50px);
  //   }
  //  }
  // }
}
.algo-search .modal-header,
.search-product.product-page-parent .modal-header {
  // background-color: var(--primary);
  // *{
  //   color:white;
  // }
}
.customer-info .delivery-type .custom-radio label i {
  color: #13c7cd;
}

.bottom-catalog-wrapper {
  padding: 37px 0px 40px;
  &.brands-listing {
    background-color: #f7f7f7 !important;
    p {
      color: #000 !important;
      // font-size: 18px;
    }
  }
  &.zipcodes-listing {
    background-color: #ddd !important;
    p {
      color: #333 !important;
      font-size: 18px;
      @include desktop {
        font-size: 16px;
      }
    }
    .bottom-catalog-title {
      color: #333 !important;
    }
    .bottom-catalog-row {
      color: #333 !important;
      font-variant-numeric: tabular-nums;
      text-align: center;
      text-decoration: none;
    }
    .seeMore {
      height: 60px !important;
    }
    .more-btn {
      // display: grid;
      // grid-template-columns: repeat(6, 1fr);

      button {
        color: #333;
      }
    }
    .bottom-catalog-listing {
      grid-template-columns: repeat(3, 1fr);
      @include desktop {
        grid-template-columns: repeat(8, 1fr);
      }
      @include xDesktop {
        margin: 0 -44px;
      }
    }
    .more-btn {
      margin-top: 28px;
      font-size: 14px;
      @include desktop {
        margin-top: 33px;
      }
      button {
        background-color: transparent;
        border: none;
        color: #000;
        padding: 0px;
        text-decoration: underline;
      }
    }

    .seeMore {
      overflow: hidden;
      height: 155px;
    }
  }
  .shop-full-menu .b-title {
    margin-bottom: 1rem;
    margin-top: 1rem;
    font-size: 1.4rem;
    @include desktop {
      margin-bottom: 2rem;
      // padding-left:15px;
      font-weight: bold;
      font-size: 28px;
    }
  }
  .customer-info .delivery-message {
    background: var(--gd-secondary-color) !important;
  }
  .cart .rounded-card {
    border: none !important;
  }

  .delivery-tabs .m-item .t-selected {
    background-color: var(--primary);
  }
  .category-item {
    .h-list-parent {
      .h-list {
        @include xDesktop {
          justify-content: left;
        }
        @include ipadProLandscape {
          justify-content: left;
        }
      }
    }
    .d-item {
      background-color: #f9f9fb;
      margin-right: 12px;
      @include desktop {
        margin-right: 38px;
      }
    }
    img {
      height: 65px !important;
      @include desktop {
        height: 100px !important;
      }
      @include ipadProLandscape {
        margin: 0;
      }
    }
  }
  &.cities-listing {
    // background-color: #353034;
    background-color: #f7f7f7 !important;
    * {
      color: #000;
    }
    .google-review-link {
      color: #283cd5 !important;
    }
  }
  @include desktop {
    padding: 64px 0 64px;
  }
  .bottom-catalog-title {
    color: #000;
    font-size: 18px;
    margin-bottom: 5px;
    @include desktop {
      font-size: 30px;
    }
  }
  .bottom-catalog-listing {
    font-size: 14px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 10px;
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    // color: #fff;
    margin-bottom: 0px;
    @include desktop {
      grid-template-columns: repeat(6, 1fr);
    }
    // height: 135px;
    // overflow: hidden;
    .bottom-catalog-row {
      // margin-bottom: 6px !important;
      color: #000;
      font-size: 16px;
      // flex: 50%;
      // @include desktop{
      //   flex: 15%;
      // }
    }
  }
  .more-btn {
    margin-top: 28px;
    font-size: 14px;
    @include desktop {
      margin-top: 33px;
    }
    button {
      background-color: transparent;
      border: none;
      color: #000;
      padding: 0px;
      text-decoration: underline;
    }
  }

  .seeMore {
    overflow: hidden;
    height: 155px;
  }
}
.shop-full-menu .b-title {
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: 1.4rem;
  @include desktop {
    margin-bottom: 2rem;
    // padding-left:15px;
    font-weight: bold;
    font-size: 28px;
  }
}
.customer-info .delivery-message {
  background: var(--gd-secondary-color) !important;
}
.cart .rounded-card {
  border: none !important;
}

.delivery-tabs .m-item .t-selected {
  background-color: var(--primary);
}
.category-item {
  .h-list-parent {
    .h-list {
      @include xDesktop {
        justify-content: left;
      }
      @include ipadProLandscape {
        justify-content: left;
      }
    }
  }
  .d-item {
    background-color: #f9f9fb;
    margin-right: 12px;
    @include desktop {
      margin-right: 38px;
    }
  }
  img {
    height: 65px !important;
    @include desktop {
      height: 100px !important;
    }
  }
}
.header {
  .left-btn {
    top: 13px !important;
  }
  .h-cart-btn {
    width: 57px;
    height: 36px;
    background-color: #fce68c;
    border-radius: 5px;

    .icon-cart-outline-2 {
      position: absolute;
      top: 9px;
      left: 6px;
      color: #000;
    }

    .cart-item-count {
      background-color: #000 !important;
      top: 9px !important;
      right: 7px !important;
      width: 18px !important;
      height: 18px !important;
      line-height: 17px !important;

      @include desktop {
        line-height: 18px !important;
      }
    }
  }
}
.product {
  .top-container:hover {
    .add-to-cart {
      background-color: #fce68c;
    }
  }
  .strain-container[class*="Hybrid"] {
    background-color: #60eb73 !important;
  }
  .strain-container[class*="Indica"] {
    background-color: #46dfff !important;
  }
  .strain-container[class*="Sativa"] {
    background-color: #ffd83a !important;
  }
  .strain-container[class*="CBD"] {
    background-color: #e53935 !important;
    color: white;
  }
  .strain-container[class*="CBD:THC"] {
    background-color: #e91e63 !important;
    color: white;
  }
  .strain-container[class*="Indica-Hybrid"] {
    background-color: #9c27b0 !important;
  }
  .strain-container[class*="CBG"] {
    background-color: var(--primary) !important;
  }
  .strain-container[class*="Sativa-Hybrid"] {
    background-color: #3f51b5 !important;
  }
  .strain-container[class*="CBN"] {
    background-color: #00bcd4 !important;
  }
  .strain-container[class*="THC"] {
    background-color: #4caf50 !important;
    color: white;
  }
  .strain-container[class*="THCa"] {
    background-color: #4caf50 !important;
    color: white;
  }
  .strain-container[class*="Total Cannabinoids"] {
    background-color: #ffc107 !important;
  }
  .strain-container[class*="THCV"] {
    background-color: #795548 !important;
    color: white;
  }
  .strain-container[class*="CBDa"] {
    background-color: #607d8b !important;
  }
  .new-add-to-cart .add-to-cart .c-icon {
    color: #000 !important;
  }
}

.new-header .n-container {
  * {
    // color: #000;
  }
  .delivery-type-toggle {
    padding-left: 15px !important;
  }
}
.right-btn-container {
  @include desktop() {
    align-items: center;
    display: flex;
  }
  .h-search-btn {
    margin-right: 8px;
    position: relative;
    bottom: -2px;
    i {
      color: #000 !important;
      font-size: 20px;
    }

    @media (max-width: 325px) {
      bottom: 0px;
      margin-right: 4px;
    }
  }
  .cancel-order-btn {
    color: red;
    box-shadow: none;
    font-size: 16px;
    width: fit-content;
  }
  .help-btn {
    margin-right: 10px !important;
    margin-left: 0px !important;
    i {
      color: #000 !important;
      font-size: 20px;
    }
  }
  // .help-btn{
  //   margin-right: 15px;
  //   margin-left: 0px !important;
  //   i{
  //     color: #000 !important;
  //     font-size: 20px;
  //   }
  // }
  .my-account-btn {
    width: auto;
    margin-right: 11px;
    @include desktop {
      margin-right: 20px;
    }
    i {
      width: 20px;
      height: 20px;
      font-size: 20px;
      margin-right: 7px;
      color: #000;
    }
    span {
      font-size: 14px;
      color: #383838;
      vertical-align: top;
      font-weight: 500;
    }
  }
  .h-cart-btn {
    width: 34px;
    height: 36px;
    background-color: #fce68c;
    border-radius: 5px;
    .icon-cart-outline-2 {
      position: absolute;
      top: 8px;
      color: #000;
      @include desktop {
        top: 9px;
        left: 6px;
      }
    }
    .cart-item-count {
      background-color: #000 !important;
      top: 10px !important;
      right: 8px !important;
      width: 16px !important;
      height: 16px !important;
      @include desktop {
        top: 9px !important;
        right: 7px !important;
        width: 18px !important;
        height: 18px !important;
      }
    }
  }
  .cart-count {
    width: 57px;
  }
}

.card-parent {
  padding-right: 0;
  .card-list {
    .custom-radio {
      border-radius: 10px;
    }
  }
}
