
.loader-main-image{
  transition: 0.6s;
  
}
.fully-loaded{
  filter: blur(0px);

}
.not-fully-loaded{
  filter: blur(8px);
}