.mini-tabs {
  transition: transform 0.3s linear;
  position: fixed;
  left: 0;
  top: var(--navbar-height);
  z-index: 3;
  background-color: white;
  right: 0;
  .mini-tab-section {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  i {
    margin-right: 5px;
  }
  .m-item-content {
    display: none !important;
  }
  .delivery-tabs {
    margin-top: 2px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .default-category-menu {
    .h-list {
      padding-bottom: 8px !important;
    }

    .d-item {
      background: white;
      border: none;
      padding-top: 8px;
      flex: 0 0 auto;
      &:hover {
        border: none;
      }
    }
    .c-selected {
      padding: 6px 5px 3px !important;
    }
  }
}

.mini-tabs-control-appear {
  transform: translateY(-100%);
}
.mini-tabs-control-appear-active {
  transform: translateY(0%);
}
.mini-tabs-control-enter {
  transform: translateY(-100%);
}
.mini-tabs-control-enter-active {
  transform: translateY(0%);
}
.mini-tabs-control-exit {
  transform: translateY(0%);
}
.mini-tabs-control-exit-active {
  transform: translateY(-100%);
}
