.product-counter-add-cart {
	// background-color: var(--primary);
	padding: 6px 10px;
	border: 1px solid #e5e5e5;
	span {
		// color: $primary;
		font-size: 16px;
	}
}
.product-counter-parent {
	height: auto !important;
	position: absolute;
	bottom: 0;
	background-color: white;
	right: 0;
	transition: all 0.2s ease-out;
	transform-origin: right;
	@include notDesktop{
		background-color: var(--light-primary);
	}
	.count {
		@include notDesktop {
			height: auto;
			line-height: 1;
		}
	}
	.btn {
		transition: all 0.2s linear 0s;
		color: $primary;
		color: var(--primary);
		height: 25px;
		width: 25px;
		padding: 6px;
		line-height: 1;
		font-weight: bold;
		font-size: 0.9em;
		& ~ .btn {
			//transition-delay:0.1s;
		}
		i {
			//transition:all 0.2s linear 0.0s;
		}
		&:disabled {
			color: grey !important;
		}
		@include mobile{
			color: var(--dark-primary);
		}
	}
	//left:auto;
	&.p-counter {
		//width:100%;
		left: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.19);
		border-color: transparent;
		@include real-iphone5 {
			.inline-loader {
				&:after {
					right: 8px;
				}
			}
		}
	}
	&.p-count {
		width: 30px !important;
		height: 30px !important;
		border-radius: 50%;
		border: 1px solid $primary;
		border: 1px solid var(--primary);
		//right: -15px;
		padding: 4px;
		left: calc(100% - 25px);
		@include mobile{
			border-radius: 0;
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
		}
		.inline-loader {
			top: -22px;
			left: -8px;
			@include desktop {
				top: -20px;
				left: -6px;
			}
			@include real-iphone5 {
				top: -20px;
				left: -11px;
			}
		}
		.btn {
			//display: none;
			//position: absolute;
			width: 0;
			overflow: hidden;
			padding: 0;
			line-height: 0;
			font-size: 0;
			border: 0;
			height: 0;
			&:focus {
				box-shadow: none;
			}
			i {
				font-size: 0;
			}
		}
	}
	// &.lp{
	// 	&.p-count{
	// 		left:calc(100% - 20px);
	// 	}
	// }
	@include desktop {
		.count {
			font-size: 1rem;
		}
		right: 40px;
		&.p-counter {
			left: 40px;
			width: auto !important;
		}
		&.p-count {
			left: calc(100% - 40px);
			height: 30px !important;
			width: 30px !important;
			padding: 6px;
			border-width: 2px;
		}
		.btn {
			height: 25px;
			width: 15px;
			font-size: 1em;
		}
	}
	@include real-iphone5 {
		&.p-count {
			height: 25px !important;
			width: 25px !important;
			padding: 4px;
		}
		.btn {
			padding: 0;
			width: 20px;
		}
	}

	&.version-2 {
		.btn:focus {
			box-shadow: none;
		}
		&.p-counter {
			right: 0;
			/* transform: translateX(50%); */
			top: 0px;
			z-index: 1;
			left: auto;
			border: 1px solid var(--primary);
			bottom: auto;
			border-radius: 2px;
			padding: 4px 6px;
			border-radius: 8px;
			.count {
				font-size: 18px;
				width: 40px;
				text-align: center;
				color: #333 !important;
				@include mobile{
					width: auto;
				}
			}

			&:after {
				// content: "";
				// position: absolute;
				// width: 10px;
				// height: 10px;
				// transform: rotate(45deg) translateX(-50%);
				// border: 1px solid var(--primary);
				// left: 50%;
				// background: inherit;
				// z-index: 0;
				// top: -3px;
				// border-bottom-color: transparent;
				// border-right-color: transparent;
			}
			.a-loader {
				width: 45px !important;
				&:after {
					right: 15px;
				}
			}
			.loader-with-count {
				width: 40px !important;
				@include mobile{
					color: var(--dark-primary);
				}
				&:after {
					background-color: transparent;
					height: 25px;
					width: 25px;
					right: 8px;
				}
			}
			.btn {
				padding: 0;
			}
		}
		&.p-count {
			left: auto;
			right: 3px;
			text-align: center;
			top: 3px;
			pointer-events: none;
			background: transparent;
			border: none;
			.btn {
				width: 0;
				height: 0;
				box-shadow: none;
				font-size: 0;
				padding: 0;
				position: absolute;
			}
			@include desktop {
				top: 1px;
				right: 2px;
			}
			@include mobile{
				background: var(--light-primary);
			}
		}
		.count {
			color: #000;
			font-size: 17px;
			font-weight: 500;
		}
		.btn {
			font-size: 16px;
			// height: 14px;
			// width: 14px;
			// border-radius: 50%;

			// box-shadow: 0px 0px 0px 1px #13c7cd;
			padding: 0;
			@include desktop {
				padding: 6px;
			}
			// line-height: 15px;
			&.no-circle {
				// box-shadow:none;
				// font-size: 12px;
			}
			* {
				color: var(--primary);
			}
			&:disabled {
				i {
					color: grey !important;
				}
			}
		}
	}
}

// .lp-product{
// 	.p-count{
// 		left:calc(100% - 20px);
// 	}
// }

// .lp-product.product-3{
// 	.p-count{
// 		left:calc(100% - 30px);
// 	}
// }

.product-counter-anim-enter {
	opacity: 0;
	transform: scale(0.1);
}
.product-counter-anim-enter-active {
	opacity: 1;
	transform: scale(1);
	transition:
		opacity 300ms,
		transform 300ms;
}
.product-counter-anim-exit {
	opacity: 1;
	transform: scale(1);
}
.product-counter-anim-exit-active {
	opacity: 0;
	transform: scale(0);
	transition:
		opacity 300ms,
		transform 300ms;
}
