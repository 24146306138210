.marketingPopUp {
  .modal-dialog {
    max-width: 530px;
  }
  .modal-content{
    border: none !important;
    // min-height: 590px;
    .close-btn{
      position: absolute;
      right: 0;
      top: 0;
      z-index: 99;
      color: var(--white-text-on-primary);
      box-shadow: none;
    }
  }
  &.fancy-popup {
    .modal-content{
      min-height: 600px;
    }
    .close-btn{
      width: 34px;
      height: 34px;
      border-radius: 50%;
      background: rgba(255, 255, 255,  0.37);
      color: black;
      padding: 0;
      line-height: 34px;
      font-size: 13px;
      font-weight: bold;
      top: 5px;
      right: 5px;
    }
  }
  .bg-image{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .img-container{
    margin-bottom: 0;
  }
  .banner-image{
    position: absolute;
    top: 0;
    left: 0;
    right:0;
    bottom: 0;
    overflow: hidden;
    margin-bottom: 0;
    .img-format{
      height: 100%;
      width: 100%;
    object-fit: cover;
    }
  }
  .mini-label{
    display:none;
  }
   .logo-holder{
    position: relative;
    margin: 20px;
    img{
      max-height: 130px;
    }
   }
   .theme2 .bottom-container{
    position: relative;
    @include notDesktop{
      padding: 30px;
    }
    h2, h6{
      font-weight: bold;
      text-transform: uppercase;
      line-height: 1;
    }
    h2{
      font-size: 60px;
    }
    span, div.sign-in-line-2{
      font-size: 14px;
    }
    .otp-on-call, .one-rem-mt, .half-rem-mt {
      padding: 0 !important;
      margin: 0 !important;
    }
      button{
        width: 100%;
        padding: 10px 0;
        color: var(--white-text-on-primary);
        span, div.sign-in-line-1{
          text-transform: uppercase;
          font-weight: bold;
          font-size: 14px;
          line-height:14px;
        }   
    }
    .loginForm-container{
      @include desktop{
        width:60%;
        margin: 0 auto;
      }
      .inline-loader {
        top: 5px;
        right: 5px;
      }
      }
      .i-inner{.phone-number {
        font-size: 1em;
        padding: 10px 10px 10px 65px;
    }}
      .country-code {
        font-size: 1em;
        display: block;
        color: #a09fa0;
        position: absolute;
        left: 11px;
        top: 0;
        width: 60px;
        padding: 0;
        background: transparent;
        border: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        line-height: 35px;
    
        &:focus {
          border: 0;
          box-shadow: none;
        }
    
        @include iphone5 {
          // top: 4px;
          width: 50px;
          line-height: 31px;
        }
    }  
   }
   .custom-checkbox{
     --primary : var(--p-text-color);
      label{
        &:before, &:after{
          border-color: var(--p-text-color);
        }
        .small-text{
        color: var(--p-text-color);
        }
      }
   }
   .error-msg{
      background: #f4433678;
      color: #e4e4e4;
      text-align: left;
      padding: 2px 10px;
      margin-top: 5px;
      border-radius: 3px;
   }
   .backend-text{
     a, a:hover{
    color: var(--p-text-color);
      }    
      color: var(--p-text-color);
    }
}
