.promocode-popup {
  // background-color: $modalBGColor;
  background: linear-gradient(90deg, rgba(0, 171, 231, 0.7) 0%, rgba(146, 122, 226, 0.7) 100%);
  .lottie-cp {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
  .modal-dialog {
    position: absolute;
    @include desktop {
      position: relative;
    }
    @include tab {
      position: relative;
    }
  }
  .modal-content {
    background-color: unset !important;
    .img-t-center {
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 12;
    }
    .modal-header {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background-color: #ffffff;
      h6 {
        color: #616161;
        line-height: 17px;
        font-weight: 600;
        letter-spacing: 0.2px;
      }
    }
    .cut-arc {
      height: 24px;
      margin: 0 10px;
      position: relative;
      background-color: #ffffff;
      .promo-d {
        width: 100%;
        border: 1px dashed #999999;
      }
    }
    .cut-arc::before,
    .cut-arc::after {
      content: "";
      position: absolute;
      width: 30px;
      height: 30px;
      top: 50%;
      -webkit-transform: translate(-50%, -50%) rotate(45deg);
      transform: translate(-50%, -50%) rotate(45deg);
      border: 5px solid transparent;
      border-top-color: #fff;
      border-right-color: #fff;
      border-radius: 100%;
      pointer-events: none;
    }
    .cut-arc:after {
      -webkit-transform: translate(-50%, -50%) rotate(225deg);
      transform: translate(-50%, -50%) rotate(225deg);
      right: -40px;
    }
    .cut-arc:before {
      left: -10px;
    }
    .modal-body {
      background-color: #ffffff;
      h2 {
        background: linear-gradient(98.02deg, #6b3bbf 1.72%, #138c91 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }
    .modal-footer {
      background-color: #ffffff;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      button {
        border-radius: 8px;
        z-index: 2;
        padding: 5px 16px;
        font-weight: 600;
      }
    }
  }
}

//discount card carousel code starts here
.discount-carousel {
  margin-top: 1.4rem;
  @include desktop {
    margin-top: 0;
    // margin-top: 2rem;
  }
  .control-dots {
    padding-right: 25.5px;
    margin: 5px 0px;
    .dot {
      width: 4px;
      height: 4px;
      margin: 0 2px;
      background: #c9cfd5;
    }
    .selected {
      width: 5px;
      height: 5px;
      background: #ffffff;
    }
    @include desktop {
      margin: 6px 0px;
    }
  }
  .carousel-status {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    margin: 3px 0px;
    padding-right: 23px;
    text-shadow: unset;
    @include desktop {
      margin: 5px 0px;
    }
  }
  .slide {
    background: none;
  }
  .discount-card {
    background: linear-gradient(271.56deg, #05a0a8 -11.04%, #4b40c1 100%);
    border-radius: 4px;
    padding: 10px 20px;
    .icon {
      font-size: 22px;
      color: white;
      @include desktop {
        font-size: 26px;
      }
    }
    p {
      font-size: 14px;
      color: #ffffff;
      margin-bottom: 0;
      @include desktop {
        font-size: 16px;
      }
    }
  }
  .discount-card2 {
    background: linear-gradient(271.56deg, var(--primary) -11.04%, #4b40c1 100%);
  }
  .control-dots {
    width: auto !important;
    position: absolute;
    right: 0;
    top: 15px;
  }
}
.discount-carousel .carousel:last-child {
  display: none;
}
//discount card carousel cod ends here

.loader-minH {
  min-height: 90px;
}
//promo code cart page css starts here
.offer-benefits {
  .promo-code-container {
    //   padding:20px 20px 10px;
    min-height: 90px;
    overflow: hidden;
    .view-more-codes {
      border-top: 1px dashed #999999;
      button {
        color: #616161;
        box-shadow: none;
      }
    }
    .view-more-codes::before,
    .view-more-codes::after {
      position: absolute;
      content: "";
      background-color: #f2f1f6;
      width: 22px;
      height: 22px;
      top: -12px;
      left: -28px;
      border-radius: 50%;
      box-shadow: inset 1px 1px 1px 1px rgb(0 0 0 / 5%);
    }
    .view-more-codes::after {
      right: -28px !important;
      left: unset !important;
    }
    .promocode {
      padding: 2px;
      p {
        color: #000000;
      }

      //   &::before{
      //     content: " ";
      //     width: 16px;
      //     height: 16px;
      //     background-color: #f2f1f6;
      //     top: 0%;
      //     position: absolute;
      //     left: 12%;
      //     border-radius: 50%;
      //   }
      //   &::after{
      //     content: " ";
      //     width: 16px;
      //     height: 16px;
      //     background-color: #f2f1f6;
      //     bottom: 32%;
      //     position: absolute;
      //     left: 12%;
      //     border-radius: 50%;
      //   }
    }
    .loader-min-height-promo-section {
      min-height: 100px;
    }
  }
  +.cart-white-card{
    margin-top: 12px;
  }
}

.cart-couponcode {
  background-color: #ffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  min-height: 100px;
  margin-top: 5px;
  border: unset;
  h6 {
    color: var(--primary);
    font-weight: 700;
  }
  a {
    font-weight: 600;
  }
  .remove-btn {
    color: #d86039;
  }
  .round1 {
    height: 20px;
    top: -10px;
    position: absolute;
    width: 20px;
    /* left: 100%; */
    right: -10px;
    background-color: #f2f1f6;
    /* position: absolute; */
    border-radius: 50%;
    //  margin-top: -10px;
  }
  .image-block {
    position: relative;
    height: auto;
    display: flex;
    padding: 15px;
  }
  .active-line {
    border-right: 2px dashed var(--primary);
  }
  .round2 {
    position: absolute;
    height: 20px;
    width: 20px;
    bottom: -10px;
    right: -10px;
    background-color: #f2f1f6;
    border-radius: 50%;
  }
}

//promo code cart page css ends here

//promo code side bar css starts here
.promocode-info {
  margin-top: 0px;
  //   padding:15px 12px;
  justify-content: center;
  border: 1.5px dashed var(--primary);
  border-radius: 10px;
  stroke-width: 4;
  // box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  h6 {
    color: #333333;
    line-height: 17px;
    font-weight: 600;
  }
  p {
    line-height: 19px;
    margin-bottom: 0px;
    color: #000000;
  }
  a {
    color: var(--primary);
  }
  .round1 {
    height: 20px;
    top: -10px;
    position: absolute;
    width: 20px;
    /* left: 100%; */
    right: -10px;
    background-color: var(--body-background-color);
    /* position: absolute; */
    border-radius: 50%;
    //  margin-top: -10px;
    border: 1px dashed var(--primary);
    border-bottom: 1px dashed var(--primary);
    border-right: 1px dashed var(--primary);
    border-left: 1px dashed transparent;
    border-top: 1px dashed transparent;
    transform: rotate(45deg);
    // .rect{
    //   height: 10px;
    //   position: absolute;
    //   background-color: #ffffff;
    //   width: 20px;
    //   top: -2px;
    //   left: -1px;
    // }
  }
  .image-block {
    position: relative;
    height: auto;
    display: flex;
    padding: 15px;
    border-right: 1px dashed var(--primary);
  }
  .round2 {
    position: absolute;
    height: 20px;
    width: 20px;
    bottom: -10px;
    right: -10px;
    background-color: var(--body-background-color);
    border-radius: 50%;
    // border:1px dashed var(--primary);
    // box-shadow: inset 0px 1px 0px 0px var(--primary);
    // .rect{
    //   height: 10px;
    //   position: absolute;
    //   background-color: #ffffff;
    //   width: 20px;
    //   bottom: -2px;
    //   left: -1px;
    // }
    border-bottom: 1px dashed transparent;
    border-right: 1px dashed transparent;
    border-left: 1px dashed var(--primary);
    border-top: 1px dashed var(--primary);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .remove-btn {
    color: #d86039;
  }
  .active-line {
    border-right: 1px dashed var(--primary);
  }
}

.sidebar-couponcode {
  border: 1px solid var(--primary) !important;
  // box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  h6 {
    color: #333333 !important;
    font-weight: 600 !important;
  }
  a {
    color: #d86039;
    font-weight: 600;
  }
  .icon-scissor {
    top: -6px;
    left: 40px;
    color: var(--primary);
    font-size: 12px;
  }
  .round1 {
    height: 20px;
    top: -10px;
    position: absolute;
    width: 20px;
    right: -10px;
    background-color: var(--body-background-color);
    border-radius: 50%;
    border: 1px solid var(--primary);
    border-bottom: 1px solid var(--primary);
    border-right: 1px solid var(--primary);
    border-left: 1px solid transparent;
    border-top: 1px solid transparent;
    transform: rotate(45deg);
    //second method for cutoff using rectangle
    // .rect{
    //   height: 10px;
    //   position: absolute;
    //   background-color: #ffffff;
    //   width: 20px;
    //   top: -2px;
    //   left: -1px;
  }
  .image-block {
    position: relative;
    height: auto;
    display: flex;
    padding: 15px;
    border-right: 2px dashed var(--primary);
  }
  .round2 {
    position: absolute;
    height: 20px;
    width: 20px;
    bottom: -10px;
    right: -10px;
    background-color: var(--body-background-color);
    border-radius: 50%;
    // border: 1px solid var(--primary);
    //second method for cutoff using rectangle
    // .rect{
    //   height: 10px;
    //   position: absolute;
    //   background-color: #ffffff;
    //   width: 20px;
    //   bottom: -2px;
    //   left: -1px;
    // }
    border-bottom: 1px solid transparent;
    border-right: 1px solid transparent;
    border-left: 1px solid var(--primary);
    border-top: 1px solid var(--primary);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  // .sidebar-badge{
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: space-between;
  //     padding-top: 5px !important;
  // }
}

//promo code sidebar css ends here
.icon-coupon-badge {
  margin-top: -2px;
  font-size: 30px;
  right: 40px;
  position: absolute;
  color: var(--primary);
}
