.contact {
    padding: 15px;
    padding-top: calc(var(--navbar-height) + 20px);
    height: 100%;
    overflow: auto;
    @include desktop {
        max-width: 420px;
        height: auto;
    }
		@include min-height-desktop;
    .submit {
        padding: 6px 30px;
    }
    input,
    textarea {
        margin-top: 0.5rem;
    }
    .big-icon {
        font-size: 6em;
        margin: 4rem 0rem;
        display: block;
        opacity: 0.5;
    }
    .btn-holder {
        font-size: 0;
        .btn {
            width: 48%;
            i {
                margin-right: 5px;
            }
        }
    }
}
