.user-dropdown-parent {
  position: relative;
  z-index: 1032;
  @include xDesktop {
    max-width: 1600px;
  }
  .user-dropdown-box {
    position: fixed;
    // bottom: 0;
    right: 0;
    top: var(--navbar-height);
    left: auto;
    bottom: auto;
    // width: 100%;
    min-width: 250px;
    transition: transform 0.3s linear, opacity 0.3s linear;
    background: white;
    height: auto;
    width: auto;
    background-color: white;
    box-shadow: -6px 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-right: 0px;
    @include desktop {
      position: absolute;
      top: 0;
    }
    @include ipad {
      margin-right: 12px;
    }
    @include ipadLandscape{
      margin-right: 12px;
    }
    @include ipadPro {
      margin-right: 12px;
    }
    @include ipadProLandscape {
      margin-right: 12px;
    }
    .btn {
      .dd-item {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #616161;
        padding: 13px 16px;
        span {
          i {
            font-size: 18px;
            color: var(--primary);
            margin-right: 12px;
          }
        }
        .price-value {
          font-weight: 600;
          line-height: 17px;
          color: var(--primary);
        }
      }
    }
    .user-top-section {
      background-color: var(--primary);
      padding: 8px 16px;
      border-radius: 8px 8px 0px 0px;
      .user-info {
        .user-pp {
          width: 46px;
          height: 46px;
          position: relative;
          overflow: hidden;
          border-radius: 50%;
          background: rgba(255, 255, 255, 0.5);
          box-shadow: 0px 1.70667px 1.70667px rgba(0, 0, 0, 0.15);
          img {
          }
          .icon{
            position: absolute;
            font-size: 47px;
            top: 48.3%;
            left: 50%;
            transform: translate(-50%,-50%);
            border-radius: 50%;
            color: var(--primary);
            box-shadow: 0px 1.6150000095367432px 1.6150000095367432px 0px #00000026;
          }
          // .icon{
          //   position: absolute;
          //   bottom: 0;
          //   left: 0;
          //   right: 0;
          //   top: -1px;
          //   text-align: center;
          //   font-size: 46px;
          //   color: #056D76;
          //   font-weight: 600;
          //   box-shadow: 0px 1.6150000095367432px 1.6150000095367432px 0px #00000026;
          // }
        }
        .user-detail {
          h6 {
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #ffffff;
            margin-bottom: 0px;
          }
          // a{
          //   font-weight: 400;
          //   font-size: 12px;
          //   line-height: 15px;
          //   text-decoration-line: underline;
          //   color: #FFFFFF;
          // }
        }
      }
    }
    .btn-wrap {
      padding: 0 16px;
      .btn {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        border: 1px solid #ffffff;
        border-radius: 6px;
        cursor: pointer;
        padding: 7px 7px;
        height: auto;
        width: 104px;
        outline: none;
      }
      .btn-logout {
        color: var(--primary);
        background-color: #ffffff;
        border: 1px solid var(--primary) !important;
      }
      .btn-logout:hover {
        color: #ffffff;
        background: var(--primary);
      }
      // .btn:hover{
      //   color: #056D76;
      //   background: #FFFFFF;
      // }
    }
        .no-text-decoration {
          display: block !important;
          text-decoration: none !important;
          background-color: white;
          width: 100%;
          border: none;

          &:focus{
            outline: none;
            box-shadow: none;
          }
        }
  }

  // &.slideup-cart-enter .user-dropdown-box {
  //   transform: translateY(100%);
  //   opacity: 0;
  // }
  // &.slideup-cart-enter-active .user-dropdown-box {
  //   transform: translateY(0%);
  //   opacity: 1;
  // }
  // &.slideup-cart-exit .user-dropdown-box {
  //   transform: translateY(0%);
  //   opacity: 1;
  // }
  // &.slideup-cart-exit-active .user-dropdown-box {
  //   opacity: 1;
  //   transform: translateY(100%);
  // }
  // @include desktop {
  &.slideup-cart-enter .user-dropdown-box {
    transform: translateY(-10%);
    opacity: 0;
  }
  &.slideup-cart-enter-active .user-dropdown-box {
    transform: translateY(0%);
    opacity: 1;
  }
  &.slideup-cart-exit .user-dropdown-box {
    transform: translateY(0%);
    opacity: 1;
  }
  &.slideup-cart-exit-active .user-dropdown-box {
    opacity: 0;
    transform: translateY(-10%);
  }
  // }
}
// .long-user {
//   top: 0 !important;
//   right: 0 !important;
//   left: auto !important;
//   @include desktop {
//     top: -10px;
//   }
// }
