.rate-review-popup {
  background-color: $modalBGColor;
  color: #333333 !important;
  .text-gray {
    color: #333333;
  }
  .modal-dialog {
    top: 0;
    max-height: 100%;
    @include desktop {
      width: 720px;
      max-width: 100%;
      // max-height: 550px;
      max-height: calc(100vh - 100px);
      // min-height: 80vh;
    }

    .modal-content {
      border-radius: 20px;
      overflow: hidden;
      .modal-header {
        z-index: 2;
        // box-shadow: 0px 3px 6px #00000029;
        // border-bottom: 1px solid #dee2e6;
        justify-content: space-between !important;
        align-items: center;
        padding: 20px;
        box-shadow: 0px 1px 16px #00000007;
        @include desktop {
          padding: 20px 35px;
        }
        h5 {
          margin: 0;
          color: #333333;
          font-size: 18px;
          span {
            color: var(--ink-color);
          }
          i {
            color: #333333;
            font-size: 14px;
            color: var(--ink-color);
          }
        }
        button {
          position: relative;
          right: -0.75rem;
        }
        .fancy-close-btn {
          border: 0;
          .icon-cross {
            margin: 0;
            font-size: 18px;
          }
        }
      }
      .modal-body {
        height: calc(100vh - 66px);
        padding: 0rem 20px;
        background-color: #f2f1f6;
        @include desktop {
          height: auto;
          padding: 0rem 35px;
        }
        .rate-review-header-wrapper {
          background-color: #ffffff;
          position: sticky;
          top: 0;
          z-index: 1;
        }
        .rate-review-header {
          background-color: #f2f1f6;
          padding: 1rem 1rem;
          border-radius: 13px 13px 0 0;
          border: 1px solid #d6d6d6;
          @include desktop {
            padding: 1rem 1.5rem;
          }
          p {
            margin: 0;
            color: #333333;
            font-size: 16px;
            span {
              color: var(--ink-color);
            }
            i {
              color: #333333;
            }
          }
        }
        .service-rating-wrapper {
          // border: 1px solid #d6d6d6;
          border-top: 0;
          padding: 20px;
          background-color: $primary;
          box-shadow: 0px 1px 16px #00000007;
          border-radius: 10px;
        }
        .product-rating-wrapper {
          // border: 1px solid #d6d6d6;
          padding: 20px;
          border-top: 0;
          margin-bottom: 2rem;
          background-color: $primary;
          box-shadow: 0px 1px 16px #00000007;
          border-radius: 10px;
          @include desktop {
            padding: 20px;
          }
        }
      }
      .inline-loader-wrapper {
      }
      .modal-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        .btn {
          // width: 25%;
          padding: 8px 40px;
        }
      }
      @include notDesktop() {
        border: none;
        border-radius: 0px;
      }
      .rrp-submit {
        width: 100%;
        text-align: left;
        padding-top: 10px;
        button {
          background-color: var(--primary);
          color: $primary;
          font-size: 12px;
          padding: 4px 12px;
          border-radius: 4px;
          @include desktop {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.review-card-wrapper {
  // margin-bottom: 2rem;
  text-align: left;
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 1.5rem;
  &:last-child {
    padding-bottom: 0;
  }
  &.order-card-wrapper {
    margin-bottom: 1rem;
    padding-bottom: 0;
    .stars-wrapper {
      margin: 0 !important;
    }
  }
  &:last-child {
    margin-bottom: 0 !important;
    border-bottom: 0;
  }

  // width: 100%;
  .loader {
    margin: 0 !important;
  }
  h5 {
    font-size: 20px;
    color: #808080;
    margin-top: 5px !important;
  }
  p,
  h5,
  h6 {
    margin: 0;
  }
  .rc-order-id {
    font-size: 16px;
    margin: 4px 0 12px;
  }
  .btn-link {
    font-size: 12px;
    padding-left: 3px;
  }
  .rc-product {
    font-size: 16px;
    // margin: 10px 0;
    display: flex;
    align-items: center;
    p {
      margin: 0;
      margin-left: 1rem;
      i {
        font-size: 10px;
        font-weight: 100;
        margin: 0 0.5rem;
      }
    }
  }
  .rc-star-section {
    &.for-products {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .star-section-left {
      // width: calc(100% - 100px);
      .stars-wrapper {
        // display: flex;
        // margin-top: 1.5rem;
        i {
          font-size: 22px;
          margin-right: 14px;
          cursor: pointer;
          transform: scale(1);
          transition: 0.3s;
          &:hover {
            transform: scale(1.2);
          }
        }
        .colored-star {
          color: #ffc107;
        }
        .gray-star {
          color: #dddddd;
        }
      }
      .stars-info {
        display: flex;
        align-items: center;
        margin-top: 12px;
        span {
          height: 7px;
          width: 7px;
          border-radius: 50%;
          background-color: var(--primary);
          margin-right: 5px;
        }
        p {
          margin: 0;
          font-weight: 500;
        }
      }
    }
    .star-section-right {
      img {
        height: 80px;
        width: 80px;
        object-fit: contain;
      }
    }
  }
  .rating-bottom {
    transition: 0.5s;
    &.close-section {
      transform: scaleY(0);
      height: 0;
    }
    &.open-section {
      height: auto;
      transform: scaleY(1);
    }

    .rc-in-details {
      // margin-bottom: 1rem;

      p {
        margin: 0;
        font-size: 14px;
        margin-bottom: 12px;
        // margin-bottom: 10px;
        // color: var(--primary);
      }
      .load-btn {
        .loader {
          height: 18px !important;
          padding: 0;
          align-items: center;
          span {
            background-color: #ffffff !important;
          }
        }
      }
      div {
        display: flex;
        flex-wrap: wrap;
        // overflow: auto;
        button {
          background: #efefef 0% 0% no-repeat padding-box;
          color: #333333 !important;
          border: 1px solid #dedede;
          margin-right: 8px;
          margin-bottom: 8px;
          font-size: 12px;
          white-space: nowrap;
          padding: 7px 15px;
          position: relative;
          border-radius: 6px;
          &:hover {
            border: 1px solid #dedede;
          }
          .loader {
            align-items: center;
            padding-top: 0;
            span {
              background-color: $primary;
            }
          }
          &:focus {
            outline: none !important;
            box-shadow: none !important;
          }
        }

        .selected-specific {
          background-color: #d0fdfc;
          border: 1px solid #d0fdfc;

          // color: $primary;
        }
      }
    }
    .rc-comment {
      // padding-top: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      span {
        color: var(--primary);
      }
    }
    .rc-textarea {
      width: 100%;
      // margin-top: 20px;
      position: relative;
      textarea {
        width: 100%;
        resize: none;
        @include desktop {
          min-height: 80px;
          width: 75%;
        }
      }
      p {
        text-align: right;
        position: absolute;
        bottom: 4px;
        right: 4px;
        opacity: 0.5;
        @include desktop {
          right: calc(25% + 5px);
        }

        &.mandate {
          left: 4px;
          right: auto;
          transform: translateY(calc(100% + 4px));
          color: tomato;
          opacity: 1;
          font-size: 14px;
        }
      }
      ::-webkit-input-placeholder {
        /* Edge */
        color: #cccccc;
        font-size: 14px;
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #cccccc;
        font-size: 14px;
      }

      ::placeholder {
        color: #cccccc;
        font-size: 14px;
      }
    }
    .rc-image-section {
      // margin-top: 20px;
      p {
        margin: 0;
        font-size: 14px;
        // color: var(--primary);
      }
      .preview-image-wrapper {
        width: 100%;
        // overflow: auto;
        display: flex;
        // flex-wrap: nowrap;
        flex-wrap: wrap;
        padding-top: 12px;
        &.no-pointer-events-rr {
          opacity: 0.5;
        }
        .prev-img-div {
          position: relative;
          button {
            background-color: tomato;
            position: absolute;
            top: 5px;
            right: 5px;
            transform: translate(0%, -50%);
            padding: 4px;
            height: 18px;
            width: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 0;

            .icon-cross {
              font-size: 8px;
              color: $primary;
            }
          }
        }
        .preview-img {
          height: 76px;
          width: 76px;
          // border: 2px solid #e5e5e5;
          margin-right: 10px;
          margin-bottom: 10px;
          object-fit: cover;
          flex: none;
          border-radius: 5px;
        }
        .add-image {
          input {
            display: none;
          }
          label {
            width: 76px;
            height: 76px;
            border: 2px dashed #777777;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
          i {
            color: #777777;
            font-size: 25px;
          }
        }
      }
      .my-file-input {
        label {
          // width: 100%;
          text-align: center;
          padding: 8px 0;
          color: var(--primary);
          font-size: 14px;
          cursor: pointer;
          padding-left: 20px;
        }
        &:before {
          // content: "+";
          font-size: 12px;
          color: var(--primary);
          border: 1px solid var(--primary);
          position: absolute;
          padding: 0 2px 0px 2px;
          line-height: 12px;
          border-radius: 3px;
          margin-top: 11px;
        }
        //   &.upload:before{
        //     content: ' \002B';
        //   }
        //   &.clear:before{
        //     content: "\002D";
        //     padding: 0 4px 0px 4px;
        // }
      }
    }
  }
  &.center-aligned-card {
    .stars-wrapper {
      width: 100%;
      text-align: center;
    }
    .rc-textarea {
      textarea {
        width: 100%;
        @include desktop {
          width: 100%;
        }
      }
      p {
        @include desktop {
          right: calc(0% + 5px);
        }
      }
    }

    .rc-in-details {
      p {
        text-align: center;
      }
      div {
        text-align: center;
        justify-content: center;
      }
    }
  }
}
.mta-4 {
  margin-top: calc(1.5rem - 8px) !important;
}
.font-18 {
  font-size: 18px;
}
.rating-redirects {
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: center;
  // margin-top: 2rem;
  p {
    margin-bottom: 10px;
  }

  div {
    width: 100%;
    @include desktop {
      flex-direction: row;
    }
    button {
      background-color: $primary;
      border: 1px solid #d6d6d6;
      border-radius: 10px;
      padding: 12px 16px;
      width: 100%;
      &:hover {
        background-color: #f1f1f1;
      }
      @include desktop {
        width: 220px;
        margin-right: 1.5rem;
      }
      &:nth-child(1) {
        margin-bottom: 10px;
        @include desktop {
          margin-bottom: 0;
        }
      }
    }
  }
}
.no-pointer-events-rr {
  pointer-events: none;
}
.border-danger {
  border-color: #dc3545;
  color: #dc3545;
  &::placeholder {
    color: #dc3545 !important;
  }
}
.inline-loader-rate-review {
  position: static !important;
  display: flex;
  justify-content: center;
  &::after {
    position: static !important;
    width: 30px;
    height: 30px;
    border: 5px solid var(--primary);
    border-bottom: 5px solid transparent;
  }
}
.icon-star-filled {
  color: #ffc107 !important;
}
.icon-star-outline {
  color: #cccccc !important;
}

.slider-wrapper-pr {
  position: relative;
  width: 100%;
  @include desktop{
    width: 60%;
  }
  .slider-component-wrapper {
    position: relative;
    left: -10px;
    &:before {
      content: "";
      height: 10px;
      width: 12px;
      background-color: $primary;
      position: absolute;
      top: calc(18px);
      z-index: 1;
    }
    &:after {
      content: "";
      height: 10px;
      width: 12px;
      background-color: $primary;
      position: absolute;
      top: calc(18px);
      right: -1px;
      z-index: 1;
    }
  }

  .slider-labels {
    width: 100%;
    justify-content: space-between;
    p {
      &:nth-child(2) {
        position: relative;
        right: 20px;
      }
    }
  }
  .horizontal-slider {
    width: 100%;
    height: 50px;
  }

  .pr-slider-thumb {
    font-size: 0.9em;
    text-align: center;
    // background-color: black;
    color: black;
    cursor: pointer;
    box-sizing: border-box;
    box-shadow: 0px 5px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
  .pr-slider-mark {
    background-color: var(--primary);
    height: 15px;
    width: 1px;
    top: 16px;
    border-radius: 50%;
    transform: translateX(12.5px);
    &:nth-of-type(1), &:nth-last-of-type(1){
      height: 30px;
      top: 9px;
    }
  }

  .pr-slider-track {
    position: relative;
    background: #ddd;
    height: 6px !important;
  }

  .horizontal-slider .pr-slider-track {
    top: 20px;
    height: 10px;
  }

  .horizontal-slider .pr-slider-thumb {
    top: 10px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    border-radius: 50%;
    font-weight: 600;
    outline: none;
    background-color: white;
    position: relative;
    z-index: 10 !important;
    &:focus {
      div {
        display: block;
      }
    }
    div {
      background-color: #f1f1f1;
      position: absolute;
      top: -35px;
      padding: 0 10px;
      left: 0;
      transform: translateX(-25%);
      display: none;
      border-radius: 4px;

      &:after {
        position: absolute;
        left: 50%;
        bottom: -8px;
        height: 10px;
        width: 10px;
        background-color: #f1f1f1;
        z-index: 4;
        content: "";
        transform: rotate(45deg) translateX(-5px);
      }
    }
  }
}
