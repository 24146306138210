.brand-info{
    
    // @include desktop{
    //     padding-top: calc(var(--navbar-height) + 0px);
    // }
    font-size: 16px;
    background-color:#FFFAE8 ;  
    .round-box img {
     border-radius: 20px;
    }

}

.city-list{
    background-color:#F9FAFC ;
    a{
        width: 50%;
        display: inline-block;
        margin-bottom: 1.2rem;
        font-size: 16px;
        padding-right: 10px;
        vertical-align: top;
        color:#333;
        &:hover{
            color:var(--primary); 
        }
        @include desktop{
            width:20%;
            font-size: 18px;
        }
    }
}
.delivery-cities {   
    
    // span,p{
    //     font-size: 16px;
    //     line-height: 1.8;
    // }
    .delivery-title{
        margin:30px 0px 30px 0px;   
        text-align: left;
    }
    .sub-title{
        margin-top: calc(30px - 1.2rem);
        margin-bottom: 20px
    }

	// @include min-height-desktop;
    .delivery-city {
        width: 50%;
       display: inline-block;
       margin-bottom: 1.2rem;
       font-size: 16px;
       padding-right: 10px;
       vertical-align: top;
       color:#555;
       &:hover{
           color:var(--primary); 
       }
       @include desktop{
           width:25%;
           font-size: 20px;
       }
    }
    @include desktop {
        max-width: auto; 
        height: auto; 
        padding-bottom: 10px;
    }

   
	.faq-collapse.rc-collapse{
		background-color: transparent;
		border-radius: 0px;
		border:none;
		.rc-collapse-header{
			font-size:16px;
			line-height:1.8;
			font-weight:bold; 
			position: relative;
            background-color: #ececec;
            border: 1px solid #EAEAEA;
            border-radius: 7px;
            padding: 10px 30px 10px 10px;
            color: #333;
		}
        .rc-collapse-item-active{
            .rc-collapse-header{
                border-radius:7px 7px 0 0;
            }
        }
        .rc-collapse-content{
            border: 1px solid #EAEAEA;
            margin: 0;
            padding: 15px;
            color:#333;
            background-color: transparent;
            border-radius: 0 0 7px 7px;
        }
		.faq-acord-icon{
			margin:0;
			position:absolute;
			right:10px; 
		}
        .rc-collapse-item{
            margin-bottom: 20px;
            border: none;
            padding: 0;
            @include desktop{
                padding: 0 15px;
            }
        }
        
        
        .r-text {
            font-size: 15px;
            line-height: 1.8;
           
        }
	}
    
    .main-title{ 
        font-size: 2rem;
        @include desktop{
            font-size: 2.5rem;
        }
    }
    .category-box{
        border: 1px solid #eee;
        border-radius: 20px; 
    }

}

.faq-section{ 
    background-color: #F2F2F2;
   
}
.additional-faq{
    width: 100%;
    .preview {
      margin-bottom: 8px;
      font-size: 20px;
      color: #333333;
      font-weight: 500;
    }
    .faq-collapse{
        width: 100%;
        .rc-collapse-item{
            border-color: initial;
        }
        .rc-collapse-header{
            color: #212529;
        }
        .rc-collapse-content-box {
            margin-top: 0;
        }
    }
}

.faq {
    width: 100%;
    margin: 0 auto;
    padding-top: 15px;
    max-width: 100%;
  }
  
  @media only screen and (max-width: 768px) {
    .faq-collapse{
      margin: auto;
    }
  }
  
  .centerplease {
    text-align:center;
    margin: 0 auto;
    font-size: 40px;
  }
  
  .question {
    color:#333;
    font-size: 16px;
    width: 100%;
    font-weight: bold;
    position: relative;
    background: #ececec;
    margin: 0 auto;
    opacity: 1;
    padding: 2px 10px 2px 10px;
    display: block;
    cursor: pointer;
    border-radius: 6px;
  }

  .questions:checked ~ .question{
    border-radius: 6px 6px 0 0;
  }
  
  .answers {
    border-top: 1px solid #2a4163;
    border-radius: 0 0 6px 6px;
    padding: 0px 15px !important;
    margin: 0 0 7px 0;
    height: 0;
    overflow: hidden;
    z-index: 1;
    position: relative;
    opacity: 0;
    -webkit-transition: .7s ease;
    -moz-transition: .7s ease;
    -o-transition: .7s ease;
    transition: .7s ease;
  }
  
  .questions:checked ~ .answers{
    height: auto;
    opacity: 1;
    padding: 15px !important;
  }
  
  .plus {
    margin-left: 10px;
    z-index: 5;
    font-size: 2.3em;
    font-weight: 500;
    -webkit-user-select: none;    
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-transition: .3s ease;
    -moz-transition: .3s ease;
    -o-transition: .3s ease;
    transition: .3s ease;
  }
  
  .questions:checked ~ .question .plus {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  .questions {
    display: none;
  }
