.card-list {
    .delete-btn {
        right: 5px;
        position: absolute;
        background: transparent;
        // top: 6px;

        &.delete-btn-edited{
            padding-top: 0;
            padding-bottom: 0;
            padding-right: 5px;
        }
    }
    .custom-radio {
        display: block;
        padding: 15px;
        background-color: #f8f8f8;
    }
    .icon-close{
        // padding: 5px 0px 15px 15px;
        font-size: 12px;
    }

    .debit{
        &.delete-btn{
            top: 6px !important;
        }
    }

    .strong-hold{
        &.delete-btn{
            top: 1px !important;
        }
    }
}
