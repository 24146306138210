.location-select {
    height: 100%;
    .map-parent {
        height: 100%;
        position: relative;
    }

    .set-location {
        background-color: var(--primary) !important;
        .s-text {
            margin-right: 7px;
        }
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 1em;
        height: 36px;
        position: absolute;
        top: calc(50% - 35px);
        left: 50%;
        transform: translate(-50%, -50%);
        &:before {
            content: "";
            position: absolute;
            height: 60px;
            width: 2px;
            background-color: inherit;
            left: 50%;
            top: 0;
            z-index: -1;
        }
        white-space: nowrap;
    }
    .icon-down {
        transform: rotate(270deg);

        display: block;
    }
}
