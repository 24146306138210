.delivery-tabs {
	// box-shadow:inset 0 -2px 0px 0px rgba(0, 0, 0,  0.05);
	// margin:0 -15px;
	margin: 20px 0px 0px;
	justify-content: center;
	//margin-bottom: 1rem;
	.m-item {
		.mini-tab-section{
			display: none;
		}

		width:50%;
		cursor: pointer;
		padding: 13px 10px 13px 15px;
		position: relative;
		background-color: #F2F1F6;
		color: #000;
		.products-availability{
				color:#555;
				padding-left: 0px !important;
				@include desktop{
					padding-left: 15px !important;
					display: flex;
					align-items: flex-end;
					strong{
						margin-right: 5px;
					}
				}
		}
		&.t-selected {
			// box-shadow: inset 0px -3px 0 var(--primary);//TODO check for DTC
			background-color: var(--primary);			
			* {
				color: #fff;
			}
		}
		@include desktop{
			width:320px;
			padding:18px 10px;
		}
		.content{
			h3{
				font-size: 18px;
				@include desktop{
					font-size: 20px;
				}
				@include real-iphone5{
					font-size: 15px;
				}
			}
		}
		
	}
	.content > * {
		vertical-align:middle;
	}

	.m-title {
		font-size: 1rem;
		color:#333333;
		@include desktop{
			padding-left: 5px;
		}
	}
	.m-title-browse {
		font-size: 25px;
		color:#333333;
		@include desktop{
			padding-left: 5px;
		}
	}

	.subtitle {
		font-size: 13px;
	}

	.l-icon {
		font-size: 19px;
		position: relative;
		top: 0px;
		@include desktop{
			margin-right: 9px;
		}
		//position: absolute;
		//left: 25px;
		//top: 50%;
		//transform: translateY(-50%);
	}

	.icon-calendar{
		left:-2px;
	}


	&.sticky-menu {
		position: sticky;
		top: var(--navbar-height);
		z-index: 3;
		background:white;

		@include desktop {
		}
	}
	@include desktop {
		margin-left: -20px;
		margin-right: -20px;
	}
	&.n-delivery-tabs{

		.m-item{
			overflow: hidden;
			background:#c4c4c4;
			*{
				color:white;
			}

			&.t-selected{
				// --primary:var(--grassdoor-secondary-color);
				*{
					color:var(--primary);
				}
				background:#bce6e5;
				box-shadow:none;
				position:relative;
				&:after{
					content: "";
					width: 15px;
					height: 15px;
					background: white;
					position: absolute;
					left: 50%;
					bottom: 0;
					transform: translate(-50%, 50%) rotate(45deg);
				}
			}
		}
		@include desktop {
			// --primary:var(--grassdoor-secondary-color);
			box-shadow: inset 0px -1px 0 $dividerColor;
			.m-item{

				box-shadow: inset 0px -1px 0 $dividerColor;
				background:white;
			*{
				color:var(--primary);
			}
				&.t-selected{
					background:var(--primary);
					*{
						color:white;

					}
					&:after{
						content: none;
					}
				}
			}
		}
	}

	&.desktop-only{
		display:none!important;
		@include desktop{
			display:flex!important;
		}
	}

	&.mobile-only{
		margin:0;
		// margin-bottom: 10px;
		@include desktop{
			display:none!important;
		}
	}
	&.category-page-tabs{
		// top:calc(var(--navbar-height) + var(--cat-menu-tab-height));
		@include desktop{
			top:var(--navbar-height);
		}
	}
	:first-child{
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
	}
	:nth-child(2){
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
	}
	@include real-iphone5{
		.unified-title{
			font-size: 20px;
		}
	}

	& + .remaining-time{
		margin-top: 10px;
	}

}
