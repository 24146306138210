.current-order-info {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
    z-index: 2;
    pointer-events: none;
    background-color: #FFFFFF;
    box-shadow: 0px -5px 6px #0000001F;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .btn {
        pointer-events: all;
    }

    @include desktop {

        position: fixed;
        // @supports (position:sticky) { 
        position: sticky;
        position: -webkit-sticky;
        // }
        z-index: 5;
        display: block;
        background-color: #fff;
        box-shadow: -1px -3px 2px 0 #00000012;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }

    .discount-strip {
        width: 100%;
        background-color: $primary;
        background-color: var(--primary);
        z-index: 1;
        text-align: center;
        padding: 3px;
        left: 0;
        right: 0;
        color: white;
        pointer-events: none;
        font-size: 12px;

        @include desktop {
            font-size: 14px;
        }
    }

    .ci-inner {
        display: flex;
        align-items: center;
        padding: 10px 0px 15px 0;

        // height: 75px;
        // margin: 5px 0px 10px;
        // .right-sec,.left-sec{
        //   padding:0 5px;
        // }
        @include desktop {
            padding: 15px;
            padding-right: 0;
        }

        .strike-text:after {
            background-color: #f44336;

            transform: translateY(-50%) rotate(0deg);
        }

        .title {
            //line-height:1;
        }

        // @include iphone5 {
        //     height: $currentOrderInfoHeight_s;
        // }

        // .price {
        // font-size: 2em;
        //vertical-align: middle;
        //margin-left: 5px;
        //display: inline-block;
        span+span {}

        .discount {
            font-size: 1.1em;
            margin-left: 0.4em;
            letter-spacing: -0.6px;
        }

        .old-price {
            margin-right: 5px;
            font-size: 1.3em;
            // text-decoration: line-through;
            color: #868686;
            // &:empty + .discount{
            //   display:none;
            // }
        }

        .new-price {
            //font-size: 1.7em;
            //margin-right: 0.3em;
            font-size: 1.5em;
            margin-right: 0.3em;
            line-height: 25px;
            // vertical-align: middle;
            // font-weight: bold;
        }

        // @include iphone5 {
        //    .old-price{
        //         font-size: 1.1em;
        //    }
        //    .new-price {
        //     font-size: 1.3em;
        //     line-height: 18px;
        //    }
        // }
        // }
        .checkout-btn {
            border-radius: 50px;
            color: white;
            // padding: 6px 24px;
        }

        .icon-info {
            font-size: 1.7em;
            vertical-align: middle;
            margin-right: 1px;
        }
    }

    .left-sec {
        width: 0%;
        display: flex;

        @include desktop {
            width: 55%;
        }

    }

    .right-sec {
        padding-left: 0px;
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include desktop {
            width: 48%;
            // padding-left: 32px;
        }

        .current-order-info-btn {
            max-width: 16.66%;
        }
    }

    .history-addr-block {
        padding: 15px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        box-shadow: 0px -5px 6px #0000001F;
        background-color: #FFFFFF;
        pointer-events: auto;

        input {
            margin-top: 5px;
            padding-left: 30px;
            font-size: 14px;

            &:focus {
                box-shadow: none;
                border-color: $dividerColor;
            }

            @include desktop {
                margin-top: 15px;
            }
        }


    }

    .set-delivery-block {
        background-color: #FFFFFF;
        padding: 8px 15px 5px 15px;
        border: 1px solid #EFEFEF;

        .abs-btn {
            margin-right: -10px;

            &:focus {
                box-shadow: none;
            }
        }

        @include desktop {
            padding: 15px;
        }

        @include notDesktop {
            pointer-events: all;
        }

        .error-message {
            color: var(--red);
            font-size: 90%;
            margin-left: 0 !important;

            @include desktop {
                margin-left: 5px;
            }

            button {
                height: 12px;
                width: 12px;
                border: 1px solid var(--red);
                border-radius: 50%;
                background-color: transparent;
                padding: 0;

                i {
                    font-size: 6px;
                    line-height: 12px;
                    color: var(--red);
                    position: relative;
                    top: -6px;
                }
            }
        }
    }

    .link-color {
        color: #1A0DAB;
    }

    .address-sticky {
        display: flex;
        flex-direction: column-reverse;
        // box-shadow: 0px -5px 6px #0000001F;
    }

    .reduce-address-width {
        @include notDesktop {
            width: calc(100vw - 30px)
        }
    }

    .icon-close {
        padding: 5px 5px 5px 15px;
        font-size: 12px;
    }

    .container {
        width: 100%;

        @include desktop {
            width: 1140px !important;
        }

    }


}