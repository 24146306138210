.friends-family-login-popup{
  background-color: $modalBGColor;
  .modal-dialog{
    .modal-content{
      border: none;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-image: $friends-background;
      color: white;
      text-align: center;

      @include desktop(){
        border-radius: 8px;
      }

      .close-holder{
        margin: 10px 10px 6px auto;

        @include desktop(){
          display: flex;
          justify-content: flex-end;
          margin: 0px;
        }
        
        .close-btn{
          color: white;
          font-size: 13px;
        }
      }

      .modal-body{
        padding: 10px 25px 0;

        .congrats-box{
          border: 1px solid rgba(255, 255, 255, 0.65);
          padding: 25px 10px;

          @include desktop(){
            padding: 25px 20px;
          }

          h2{
            font-weight: 800;
            letter-spacing: 1px;
            position: relative;

            span[class^="icon-"] {
              font-size: 28px;
              display: inline-block;
              transform: rotate(10deg);
              padding-right: 8px;

              @include desktop(){
                font-size: 38px;
              }

              +span[class^="icon-"]{
                transform: rotate(90deg);
                padding-right: 0;
                padding-left: 5px;
                margin-left: 8px;

                @include desktop(){
                  padding-left: 5px;
                }
              }
            }
          }

          p{
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0.5px;
            margin-top: 8px;
          }
        }

        .content{
          font-size: 17px;
          padding: 20px 25px 0px;
          letter-spacing: 0.6px;
        }
      }

      .modal-footer{
        button{
          background-color: $primary;
          color: #36C0C8;
          font-weight: 600;
          border-radius: 10px;
          border: none;
          min-width: 120px;
        }

        p{
          padding-top: 10px;
          font-size: 12px;
        }
      }
    }
  }
}