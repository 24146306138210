.friends-family-cart-benefits-popup{
  background-color: $modalBGColor;
 
  @include notDesktop() {
    display: flex !important;
  }
  
  .modal-dialog-centered{
    @include notDesktop() {
      align-self: flex-end !important;
      margin: 0px !important;
      align-items: normal;
    }
  }

  .modal-dialog{
    
    @include desktop(){
      max-width: 670px;
    }

    .modal-content{
      border: none;
      border-radius: 8px;
      background-image: $friends-background;
      color: white;

      @include notDesktop() {
        border-radius: 0px;
      }

      .close-holder{
        margin: 10px 10px 6px auto;

        @include desktop(){
          display: flex;
          justify-content: flex-end;
          margin: 0px;
        }

        .close-btn{
          color: white;
          font-size: 13px;
          height: 35px;
        }
      }

      .modal-body{
        padding: 0 25px 5px;

        @include desktop {
          padding: 0 25px 35px;
        }

        .logo-section{
          border: 1px solid rgba(255, 255, 255, 0.5);
          padding: 25px 20px;
          border-radius: 10px;
          text-align: center;

          img{
            height: 80px;
            object-fit: contain;
            object-position: center;
          }

          h5{
            line-height: 1.4;
          }

          .limited-offer{
            text-transform: uppercase;
            font-size: 18px;
            font-weight: 800;
            letter-spacing: 1px;
            margin-top: 10px;
            background: rgba(255, 255, 255, 0.20);
            display: inline-block;
            padding: 4px 15px;
            line-height: 1.6;
            margin-bottom: 5px;

            @include desktop(){
              margin-top: 30px;
              margin-bottom: 10px;
              font-size: 20px;
            }
          }
        }

        .benefits-section{
          @include notDesktop() {
            margin-top: 15px;
            text-align: center;
          }
          
          h5{
            letter-spacing: 1px;

            @include notDesktop(){
              margin-bottom: 25px;
              position: relative;
            }

            &:before {
              @include notDesktop(){
                content: '';
                position: absolute;
                bottom: -6px;
                left: 50%;
                transform: translateX(-50%);
                width: 75%;
                height: 1px;
                background-color: rgba(255, 255, 255, 0.6);
              }
            }
          }

          ul{
            padding-left: 15px;
            margin-top: 15px;
            line-height: 1;

            @include notDesktop() {
              list-style: none;
            }

            li{
              font-size:15px;

              +li{
                margin-top: 10px;
              }
            }
          }

          .check-holder{
            @include notDesktop() {
              justify-content: center;
              margin-bottom: 5px;
            }

            margin-bottom: 10px;

            .custom-checkbox{
              label{
                &:before{
                  border-color: $primary;
                }
              }
            }

            div{
              font-size: 14px;

              a{
                color: $primary;
                text-decoration: underline;
              }
            }
          }
        }

        .btn-handler{
          button{
            background-color: $primary;
            color: #36C0C8;
            font-weight: 600;
            border-radius: 10px;
            border: none;
            min-width: 160px;
            margin-top: 15px;

            &:hover{
              background-color: rgba(255, 255, 255, 0.9);
            }
          }

          p{
            font-size: 12px;
            color: $primary;
            margin-top: 15px;
          }
        }

        &.upgrade-benefits-content{
          // padding-bottom: 0;
          @include notDesktop(){
            padding-bottom: 25px;
          }
          
          .logo-section{
            margin-bottom: 25px;
            border: 0;
            padding: 0;
            margin-top: -15px;

            .limited-offer{
              margin-top: 10px;
              margin-bottom: 0;
              font-size: 15px;
            }
          }

          [class*="col-"]{
            @include desktop(){
              padding-right: 10px;
            }

            .benefits-section{
              border: 1px solid rgba(255, 255, 255, 0.4);
              border-radius: 20px;
              padding: 15px 15px 0;

              @include desktop(){
                height: 100%;
              }
              
              h5{
                font-weight: bold;
                font-size: 1.1rem;
              }

              p{
                font-size: 9px;

                // @include desktop(){
                //   margin-bottom: 0;
                // }
              }
            }

            +[class*="col-"]{
              @include desktop(){
                padding-left: 10px;
              }
            }
          }

          .btn-handler{
            &.current-offer-btn {
              @include desktop(){
                margin-top: 145px;
              }
            }

            button{
              margin-top: 5px;
              font-size: 15px;
              width: 100%;
            }
          }
        }
      }
    }
  }
}