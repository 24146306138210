.delivery-locations {
    padding: 15px;
    padding-top: calc(var(--navbar-height)  + 10px);
    height: 100%;
    // overflow: auto;

    .zip-holder {
        .item {
            display: block;
            padding: 4px 22px;
            border-radius: 4px;
            background-color: #ffffff;
            // border: 1px solid $primary;
            margin: 0 4px 11px 4px;
            color: $primary;
            color:var(--primary);
            letter-spacing: 1px;
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.19);
            text-align: center;
        }
    }
}
