.compare-btn {
   height: 50px;
   width: 50px;
   right: 20px;
   bottom: 180px;
   transition: width 0.4s ease-in;
   border-radius: 25px;
   padding: 0 14px;

   cursor: pointer;
   // justify-content: center;
   // align-items: center;
   // display: flex;
   position: fixed;
   background-color: var(--primary);
   z-index: 104;
   box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.37);
   white-space: nowrap;
   display: flex;
   align-items: center;

   @include desktop {
      bottom: 80px;
   }
   //   overflow: hidden;
   .c-count {
      position: absolute;
      top: 0;
      right: 0;
      height: 25px;
      width: 25px;
      text-align: center;
      color: #333;
      background-color: white;
      border-radius: 50%;
      border: 1px solid #d9d9d9;
      transform: translate(25%, -25%);
   }
   @include desktop {
      right: 50px;
      bottom: 20px;
   }

   .icon {
      color: white;
      line-height: 50px;
      display: inline-block;
      text-align: center;
      font-size: 22px;
      vertical-align: middle;
   }
   span {
      // color: $primary !important;
   }
   .expand-btn-text {
      vertical-align: middle;
      display: inline-block;
      color: #ffffff;
      line-height: 50px;
      margin-left: 10px;
   }
   &.scroll-float {
      width: 126px;
      // transition: width 1s ease 0s;
   }
}
