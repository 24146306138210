.timer-sec {
    display: block;
    min-width: 300px;
    position: relative;

    // @include ipadPro {
    //     margin-right: 8px;
    //     width: 125px;
    // }

    // @include ipadProLandscape {
    //     width: 260px;
    // }

    // @include ipadLandscape {
    //     margin-right: 8px;
    //     width: 125px;
    // }

    .hh-tooltip {
        position: relative;
        // display: inline-block;
        border-bottom: 1px dotted black;

        // &:hover {
        //     .hht-sec {
        //         visibility: visible;
        //     }
        // }

        .hht-sec {
            // visibility: hidden;
            left: -1px;
            right: -1px;
            background-color: #616161;
            color: #fff;
            text-align: center;
            padding: 5px;
            top: 100%;
            position: absolute;
            z-index: 1;
            border-radius: 0px 0px 8px 8px;
        }
        &.benefits-open{
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }
    }

    .timer-content {
        cursor: pointer;
        position: relative;
        box-sizing: border-box;
        $border: 8px;
        color: #FFF;
        background: #fff;
        background-clip: padding-box;
        border: solid 1px transparent;
        border-radius: 0px;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -1px;
            border-radius: inherit;
            background: linear-gradient(to right, #6B3BBF, #138C91);
        }

        padding: 7px 10px;
        justify-content: space-between;

        @include desktop {
            border-radius: 8px;
        }

        // @include ipadPro {
        //     display: block !important;
        //     padding: 3px 0px 3px 5px;
        //     // width: 125px;
        // }
        // @include ipadProLandscape {
        //     display: block !important;
        //     padding: 3px 0px 3px 5px;
        //     // width: 125px;
        // }
        // @include ipadLandscape {
        //     display: block !important;
        //     padding: 3px 0px 3px 5px;
        //     // width: 125px;
        // }

        .timer-head {
            background: linear-gradient(91.01deg, #683FBB -0.34%, #1D8598 102.36%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            font-size: 12px;
            font-weight: 700;
            // line-height: 14.62px;
            //   white-space: nowrap;
            margin-right: 15px;
            // width: unset !important;
            white-space: nowrap;
        }

        .timer {
           
            font-weight: 400;
            font-size: 16px;
            // line-height: 19.49px;
            color: #E14662 !important;
            letter-spacing: 0.16em;

            @include ipadPro {
                margin-top: 2px;
                font-size: 14px;
            }

            @include ipadProLandscape {
                font-size: 14px;
            }

            @include ipadLandscape {
                margin-top: 2px;
                font-size: 14px;
            }

            b {
                color: #E14662 !important;
            }
            
        }

        .cc-info {
            box-shadow: none;
            color: var(--primary) !important;
            border:none;
            margin-left: 0px;
            background-color: transparent;
            border-radius: 50%;
            position: relative;
            top: 1px;
        }

        .timer-head-wrapper {
            justify-content: center;
            // margin-left: 60px;
        }
    }
}

@include ipadPro {
    .new-header {
        .h-login-btn {
            margin-left: 8px;
        }

        .n-container .s-sec {
            padding: 0 5px 0 10px !important;
            flex: 1;
        }

        .main-logo {
            padding-left: 38px !important;
        }
    }

    .right-btn-container {
        .h-search-btn {
            margin-right: 8px !important;
        }

        .help-btn {
            margin-right: 8px !important;
        }
    }

}

@include ipadLandscape {
    .new-header {
        .h-login-btn {
            margin-left: 8px;
        }

        .n-container .s-sec {
            padding: 0 5px 0 10px !important;
            flex: 1;
        }

        .main-logo {
            padding-left: 38px !important;
        }
    }

    .right-btn-container {
        .help-btn {
            margin-right: 8px !important;
        }

        .h-search-btn {
            margin-right: 8px !important;
        }
    }
}