.confirm {
    background-color: $modalBGColor;
    .modal-dialog{
        max-width: 420px;
    }
    .inner-text {
        max-height: 400px;
        overflow: auto;
    }
    .modal-header{
    padding-top: 12px;
    padding-bottom: 0px;
    }
    .button-wrapper{
        grid-gap: 13px;
        
        .btn-outline-secondary{
        &:hover{
            color: unset;
            background-color:unset;
            border-color:unset;
        }
        }
    }   
    .img-min-height{
        min-height: 150px;
    }
}

.version-change-popup{
    .modal-dialog{
        width: 100% !important;
        @include desktop{
            width: 390px !important;
        }
    }
    .modal-header {
        border-bottom:0;
        padding: 0px 25px !important;
        .logo-container {
            min-height: 20px;
            margin: 0 auto;
            margin-top: 25px;
            width: 100px;
            padding: 10px;
            height: 100px;
            transform: scale(1.3);
        }
        h5{
            margin: 15px 0px;
        }
    }
    .modal-body{
        padding: 15px 25px 30px !important;
        button{
            border-radius: 7px;
            padding: 10px 60px;
            margin:20px 0px 0px !important;
        }
    }
    
}
