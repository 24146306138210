.chat-bot-container {
  // position: fixed;
  // bottom: 0px;
  // z-index: 9999;
  // min-height: 200px;
  // background-color: #f5f6fa;
  // font-size: 14px;
  // cursor: default;
  // // background-color: #F2F1F6;

  // &.minimize{
  //   min-height: unset;
  // }
  // a{
  //   color: #05a0a8;
  // }

  // textarea {
  //   font-size: 13px;
  // }

  // @include desktop{
  // 	width: 350px;
  // 	right: 15px;
  // 	bottom: 15px;
  // 	padding-bottom: 5px;
  // 	// right:auto;
  // 	border-radius: 10px;
  //   box-shadow: 0px 3px 8px rgba(155, 155, 155, 0.35);
  // }

  // .header{
  //   background-color: #05a0a8;
  //   color: #ffffff;
  //   padding: 10px 15px 5px;
  //   border-top-right-radius: 10px;
  //   border-top-left-radius: 10px;
  //   height: 100%;
  //   display: flex;

  //   @include desktop(){
  //     justify-content: space-between;
  //   }

  //   .title{
  //     font-size: 16px;
  //   }

  //   .product-id{
  //     font-size: 13px;
  //   }

  //   .icon-btn {
  //     color: #ffffff;

  //     .icon-down{
  //       font-size: 20px;
  //     }

  //     .icon-close{
  //       font-size: 15px;
  //     }
  //   }
  // }
  .error {
    flex: 1;
  }
  @include notDesktop() {
    background: #ffffff;
  }
  .modal-dialog {
    @include desktop() {
      max-width: 450px;
    }

    @include notDesktop {
      max-width: 100%;
    }
    .modal-content {
      @include notDesktop() {
        justify-content: flex-start !important;
        width: 100%;
      }
    }
  }
  .modal-header {
    @include desktop() {
      padding-left: 20px;
      padding-right: 20px;
    }
    .modal-title {
      @include notDesktop() {
        font-size: 15px;
      }
    }
    .popup-title-icon {
      font-size: 22px;

      @include notDesktop() {
        font-size: 16px;
        width: 35px;
        height: 35px;
        line-height: 35px;
      }
    }
    .close-btn {
      padding: 0;
      font-size: 13px;
      margin-bottom: 6px;
    }
  }
  .modal-body {
    flex: unset !important;

    .chat-body {
      padding: 0px 15px 20px;
      overflow-y: auto;
      height: 100%;

      &.inactive {
        opacity: 0.5;
      }
      @include notDesktop() {
        font-size: 13px;
      }
      @include desktop() {
        padding-left: 20px;
        padding-right: 20px;
        height: 70vh;
      }
      .chat-text {
        position: relative;
        border-radius: 5px;
        padding: 10px 15px;
        background-color: #ffffff;
        box-shadow: 0px 1px 6px rgba(155, 155, 155, 0.15);
        // width: fit-content;
        word-break: break-word;
        // margin-left: 10px; hide g-avtar

        &::before {
          // content: "\e9ca"; hide g-avtar
          font-family: "icomoon" !important;
          position: absolute;
          font-size: 25px;
          color: #ffffff;
          left: -10px;
          top: -6px;
        }

        .loader-text {
          padding-left: 20px;

          .inline-loader {
            left: 0;
            right: auto;
          }
        }

        .amount {
          font-weight: 700;
        }
      }

      .time {
        color: #a9a9a9;
        font-size: 11px;
        margin-top: 5px;
      }

      .default-bot {
        .g-holder {
          background-color: var(--primary);
          width: 40px;
          height: 35px;
          line-height: 35px;
          border-radius: 50%;
          color: #ffffff;
          text-align: center;
          font-size: 18px;
          font-weight: 600;
        }

        .chat-text {
          background-color: #c7e4e8;

          &::before {
            color: #c7e4e8;
          }
        }

        // .time{
        //   margin-left: 10px;
        // }

        + .default-bot {
          margin-top: 15px;
        }
      }

      .user-bot {
        .chat-text {
          border: 1px solid #eaeaea;
          box-shadow: none;
          margin-left: 0;
          text-align: left;
          // margin-right: 10px; hide g-avtar

          &::before {
            content: "";
          }

          img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            object-fit: cover;
            object-position: center;
            margin-right: 8px;
          }

          .add-details {
            .img-holder {
              margin-right: 0;
              + .img-holder {
                margin-left: 15px;
              }
            }
          }
        }

        .user-img {
          width: 35px;
          height: 35px;
          .name-holder {
            background-color: #626262;
            width: 35px;
            height: 35px;
            line-height: 34px;
            border-radius: 50%;
            color: #ffffff;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            text-transform: capitalize;
          }
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 50%;
          }
        }

        // .time{
        //   margin-right: 10px;
        // }
      }

      .chat-options {
        margin-top: 15px;
        margin-bottom: 15px;
        border: 1px solid #dedede;
        border-radius: 5px;

        .custom-radio {
          display: flex;
          align-items: center;
          // padding: 10px 15px;

          + .custom-radio {
            border-top: 1px solid #dedede;
          }
        }
        .options {
          // background-color: #F8F8F8;
          cursor: pointer;
          line-height: 1.2;
          padding: 10px 10px 10px 40px;
          font-size: 14px;
          display: flex;
          margin-bottom: 0;
          align-items: center;
          width: -webkit-fill-available;
          min-height: 45px;
          // width: fit-content;
          // margin-left: 10px; hide g-avtar
          // margin-right: 10px;
          // margin-top: 12px;
          // margin-bottom: 0;
          // display: inline-block;
          &.disabled {
            opacity: 0.5;
            cursor: no-drop;
            pointer-events: none;
          }
          &::before {
            left: 10px;
          }

          &:hover {
            &::before {
              border-color: var(--primary);
            }
          }
          &::after {
            top: 17px;
            left: 15px;
          }

          @include notDesktop() {
            font-size: 13px;
          }

          &.product-options {
            height: 45px;
            line-height: 45px;
            padding-top: 0;
            padding-bottom: 0;

            img {
              height: 35px;
              width: 35px;
              border-radius: 50%;
              object-fit: cover;
              object-position: center;
              margin-right: 8px;
            }
          }
        }

        // &.yes-no{
        //   .options{
        //     min-width: 80px;
        //     text-align: center;
        //     display: inline-block;
        //   }
        // }

        &.helpful {
          border: none;
          .options {
            background-color: #f8f8f8;
            border: 1px solid #dedede;
            width: fit-content;
            display: inline-block;
            margin-right: 12px;
            padding-left: 10px;
            border-radius: 5px;
            min-height: 40px;
            text-align: center;

            + .options {
              @media (max-width: 370px) {
                margin-top: 15px;
              }
            }
          }
        }

        [class^="icon-thumb"] {
          margin-left: 8px;
          font-size: 16px;
        }
      }

      .add-details {
        border: 1px solid #eaeaea;
        border-radius: 5px;
        padding: 15px;
        margin-top: 15px;
        // margin-left: 10px; hide g-avtar

        .title {
          // font-weight: 500;
          font-size: 16px;
        }

        .sub-text {
          color: #6f6f6f;
          font-size: 12px;
          margin-top: 2px;
          margin-bottom: 10px;
        }

        .upload {
          background: #ffffff;
          box-shadow: none;
          border: 1px solid #efefef;
          width: 50px;
          height: 50px;
          cursor: pointer;
          position: relative;
          color: #ffffff;

          &::before {
            content: "\e9cb";
            font-family: "icomoon" !important;
            position: absolute;
            font-size: 20px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #000000;
          }

          &::-webkit-file-upload-button {
            display: none;
          }
        }

        .img-holder {
          border: 1px solid #efefef;
          width: 60px;
          height: 60px;
          display: inline-block;
          padding: 5px;
          margin-right: 15px;
          position: relative;
          margin-bottom: 15px;
          &.loader {
            border: 2px solid #f3f3f3; /* Light grey */
            border-top: 2px solid var(--primary); /* Blue */
            border-radius: 50%;
            animation: spin 2s linear infinite;
            @keyframes spin {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(360deg);
              }
            }
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }

          span {
            width: 20px;
            height: 20px;
            display: inline-block;
            border-radius: 50%;
            background: #c61818;
            font-size: 10px;
            font-weight: 700;
            line-height: 20px;
            color: #fff;
            text-align: center;
            position: absolute;
            top: -6px;
            right: -8px;
            cursor: pointer;
          }
        }

        &.added-details {
          border: none;
        }

        .form-control {
          &::placeholder {
            color: #6f6f6f;
            font-size: 12px;
          }
        }
      }
    }
  }
  .chat-message {
    padding: 20px;
    font-size: 14px;
    background: #e4e4e4;
    color: #333;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: fixed;
    bottom: -5px;
    left: 0;
    right: 0;
    text-align: center;

    @include desktop() {
      position: absolute;
      bottom: 0px;
      padding: 15px 20px 12px;
    }
  }

  .issue-container {
    margin-top: 20px;
    .issue-container-box {
      padding: 10px;
      cursor: pointer;
      border: 1px solid;
      margin-bottom: 10px;
    }
  }

  .tooltip {
    position: relative;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: max-content;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    top: -30px;
    left: 60%;
    transform: translateX(-50%);
    line-height: normal;
  }

  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
}

.issue-handler {
  padding-bottom: 20px;

  // @include desktop(){
  //     border-top: 1px solid #eee;
  // }

  @include notDesktop() {
    padding: 0 15px;
  }

  .content {
    display: flex;
    align-items: center;
    padding: 15px;
    background: rgba(0, 115, 130, 0.07);
    border-radius: 5px;
    justify-content: space-between;
    &.flex-start {
      justify-content: flex-start;
    }
    @include notDesktop() {
      flex-direction: column;
      font-size: 13px;
    }
    a {
      @include notDesktop() {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 5px;
      }
    }
  }

  .issue-history {
    .title {
      font-weight: 600;
      font-size: 15px;
    }

    .issue {
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      padding: 10px 15px;
      margin-top: 12px;
      position: relative;
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
      .issue-name {
        font-weight: 500;
        width: calc(100% - 70px);
        line-height: 1.4;
        margin-bottom: 4px;
        word-break: break-word;
      }

      .date {
        color: #888888;
        font-size: 12px;
      }
      .resolve {
        position: absolute;
        top: 10px;
        right: 12px;
        display: flex;
        align-items: center;

        span {
          color: rgb(48, 177, 97);
          margin-right: 6px;
          font-size: 17px;
        }
      }
    }
  }
}
