.location-popup {
    .btn-margin {
        margin-right: 15px;
    }
    .input-parent {
        position: relative;
        input {
            padding-left: 35px;
            font-size:14px;
            &:focus{
                box-shadow: none;
                border-color: $dividerColor;
            }
        }
        .l-icon {
            position: absolute;
            left: 10px;
            top: 9px;
            font-size: 17px;
        }
    }
    @include iphone5 {
        .small-btn {
            font-size: 12px;
        }
    }
}
.pac-container {
    z-index: 1080 !important;
}
.delivery-bottom-popup{
		top:auto;
    .modal-dialog{
        position: fixed !important;
        bottom: 0px !important;
        max-width: 100%;
        width: 100% !important;
        min-height: auto !important;
    }
    .modal-content{
        border-radius: 0px;
        box-shadow: 0 -6px 13px rgb(0 0 0 / 20%);
        @include desktop{
            .modal-header, .modal-body{
                width: 40%;
                margin: auto;
            }
        }
    }
    .icon-close{
        font-size: 12px;
    }
    

}
.custom-address-form{
    position:relative;
    button{
        position:absolute;
        right:0;
        top:-2px;
    }
    .icon-pin{
        position:absolute;
        left:10px;
        top:10px;
        color: #bfbfbf;
    }
    input{
        padding-left: 30px;
        font-size:14px;
        &:focus{
            box-shadow: none;
            border-color: $dividerColor;
        }
    }
}

.cs-address-parent{
    position: absolute;
    bottom: 35px;
    background: white;
    border: 1px solid #E6E6E6;
    left: 0;
    right: 0;
    padding: 12px 10px;
    border-radius: 10px;
    box-shadow: 0px 5px 10px rgb(0 0 0 / 16%);
    .cs-address-item{
        padding: 5px;
        padding-left: 22px;
        cursor:pointer;
        position:relative;
        i{
            position: absolute;
            left: 0;
            top: 10px;
            font-size: 13px;
        }
        @include desktop{
            padding-left: 24px;
            i{
                left:5px;
            }
            &:hover{
                background-color:#eeeeee;
                border-radius:5px;
            }
        }
    }
}

.pac-container{
	border-radius:10px;
	padding: 9px 7px;
    background: #fff;
    box-shadow: 1px 6px 12px rgba(0, 0, 0, 0.08);
    // padding-left: 30px;
    max-height: 170px;
    overflow-y: scroll;
	.pac-item{
		border:none;
		cursor:pointer;
        padding-bottom: 5px;
        padding-left: 25px;
		.pac-icon{
			background-image:none;
            position: absolute;
            left: 10px;
            margin-top: 0;
			&:before {
                content: "\e912";
		        font-family:"icomoon"!important;
		        font-size: 13px;
                position: relative;
            }
		}
		@include desktop{
			&:hover{
				background-color:#eeeeee;
				border-radius:5px;
			}
		}
	}
	&:after{
		content: none;
	}

}
