.cart {
    // height: 100%;
    input {
        font-size: 1em;
        min-height: 40px;
        border-radius: 6px;
    }
    @include min-height-desktop;
    // position: relative;
    .cart-item-holder {
        // position: absolute;
        // top: var(--navbar-height);
        // width: 100%;
        // bottom: 0;
        padding-bottom: 200px;
        // overflow: auto;
        // flex-direction: row;
        // -webkit-overflow-scrolling: touch !important;
        // z-index: 1;
        @include desktop {
            padding-bottom: 68px;
        }
        .cart-items {
            .happy-hours {
                margin-bottom: 2rem;
            }
        }
        .cart-white-card {
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
            border: 1px solid rgba(0, 0, 0, 0.12);
            background: #ffffff;
            border-radius: 8px;
            padding: 12px !important;

            + .cart-white-card {
                margin-top: 12px;
            }
        }
    }
    .custoinfo-mb {
        margin-bottom: 10px;
        margin-top: 10px;
        @include desktop {
            margin-top: 48px;
        }
    }
    // .why-chose-us{

    //     @include desktop{
    //         margin-bottom:10px;

    //     }
    // }
    .wcu-pb {
        padding-bottom: 150px;
        @include desktop {
            padding-bottom: 0px;
        }
    }
    .custoinfo-title {
        font-size: 18px;
        color: var(--gd-black-color);
        padding-left: 15px;
        margin-top: 32px;

        @include desktop {
            padding-left: 27px;
            margin-top: 42px;
        }
    }
    .checkout-text {
        padding-top: calc(var(--navbar-height) + 25px);
        padding-left: 15px;
        font-size: 25px;
        margin-bottom: 8px;
        @include desktop {
            padding-top: calc(var(--navbar-height) + 30px);
        }
        i {
            color: var(--primary);
            margin-left: 3px;
        }
    }
    .cart-product {
        margin: 0px;
        // max-height: 400px;
        // overflow: auto;
        @include desktop {
            max-height: 600px;
            overflow: auto;
        }

        .xy-product-offer {
            color: var(--primary);

            &.widget-offer {
                margin-left: 70px;

                a {
                    text-decoration: underline;
                }

                .icon-offer-tag {
                    margin-right: 8px;
                }
            }
        }
        // TODO: remove this after ML AI merge
        & > .item {
            padding-bottom: 0;
        }
    }

    .display-web {
        display: none;
        @include desktop {
            display: block;
        }
    }
    .display-mobile {
        display: block;
        @include desktop {
            display: none;
        }
    }
    .invoice {
        margin-bottom: 10px;
        @include desktop {
            margin-bottom: 0px;
        }
    }
    .cart-items {
        padding: 15px;
        position: relative;
        // height: 58%;
        // overflow: auto;
        width: 100%;
        z-index: 1; // padding-top: $navbarHeight;
        //-webkit-overflow-scrolling: auto !important;
        margin-bottom: 0px;

        @include desktop {
            position: fixed;
            height: 58%;
            overflow: auto;
            padding: 55px 15px 15px 15px;
            margin-bottom: 10px;

            // padding-top: calc(var(--navbar-height) + 60px);
        }

        .promo-section {
            padding: 16px 0 0 !important;
        }

        .info-box-mb {
            @include desktop {
                padding-bottom: 7rem;
            }
        }
        .product-brand {
            margin-top: 3px;
            color: #868686;
            font-size: 12px;
            font-weight: 400;
        }
        .cart-widget-concentrates {
            padding: 15px;
            background-color: #fff;
            // margin-bottom: 35px !important;
            border-radius: 10px;
            border: none;
            margin-top: 16px;
            .cart-widget-non-conc::after {
                content: " ";
                width: 1px;
                height: 100%;
                display: block;
                background-color: #999;
                position: absolute;
                right: -10px;
                top: 0px;
                @include desktop {
                    right: -15px !important;
                }
                @include tab {
                    right: -22px;
                }
            }
            .cart-widget-non-conc,
            .cart-widget-conc {
                width: 47%;
                p {
                    color: #616161;
                    font-size: 12px;
                    @include desktop {
                        font-size: 14px;
                    }
                }
            }
            &.dr-weedy-cart-text {
                height: auto;
                .dr-weedy-link {
                    color: var(--primary);
                    text-decoration: underline;
                    i {
                        top: 2px;
                    }
                }
                & + .c-list {
                    max-height: calc(100% - 385px);
                }
            }
        }

        .invoice-section {
            padding: 0;

            hr {
                margin-top: 8px;
                margin-bottom: 8px;
            }

            .invoice-rewards-section {
                margin: 20px 0px 15px;
            }
        }

        .recommended-products {
            margin-top: 8px !important;
            margin-bottom: -10px;

            hr {
                display: none;
            }

            .latest-product .price-offer-cart-wrapper .new-add-to-cart {
                @include notDesktop {
                    top: 36px;
                    width: calc(100% + 18px);
                }
            }
        }

        .reward-cart-wrapper,
        .free-products-wrapper {
            position: static;
            width: auto;
            margin: -15px -15px 15px;
            @include desktop {
                margin: -20px -20px 15px;
            }
        }
        .promo-code-container {
            padding: 15px;
            @include desktop {
                padding: 20px;
            }
            input {
                //font-size: 1em;
                //border-right: 0;
                font-size: 1em;
                min-height: 40px;
                border-radius: 6px;
            }

            .promo-messsage {
                font-size: 1.2em;
            }
            .promocode-field {
                .apply-coupon {
                    display: flex;
                    flex-grow: 2;
                    margin-bottom: 0px;
                    grid-gap: 10px;
                }
                .btn {
                    flex-grow: 0;
                    text-align: center;
                    height: auto;
                    background-color: transparent;
                    color: var(--primary);
                    border: 1px solid var(--primary);
                    width: unset;
                    font-size: 14px;
                    font-weight: 500;
                    align-self: flex-start;
                    padding: 7px 15px;
                    &:hover {
                        background-color: var(--primary);
                        color: #ffffff;
                    }
                }
            }
            .loader-minH {
                min-height: 84px;
            }
            .loader-min-height-promo-section {
                min-height: 71px;
            }
        }
        .promo-after {
            position: relative;
            // padding-left: 40px;
            .remove-promo {
                position: absolute;
                left: 0;
                top: -1px;
                padding: 0;
                width: 25px;
                height: 25px;
                text-align: center;
                line-height: 25px;
                background: transparent;
                border: 1px solid #c7c7c7;
                color: #888688;
                font-size: 0.7em;
                background: transparent;
                i {
                    font-weight: bold;
                    line-height: inherit;
                }
            }
        }

        .recommend-prod-section {
            margin-bottom: -10px;
            h6 {
                font-size: 18px;
                color: #43474a;
                margin-top: 15px;
                font-weight: bold;
            }
            .reco-products {
                &.tabs-btn {
                    vertical-align: sub;
                    transition:
                        opacity 0.3s,
                        transform 0.3s;
                    background: #ebebeb;
                    border-radius: 39px;
                    padding-left: 0 !important;
                    display: inline-block;
                    margin-top: 15px;
                    margin-bottom: 10px;

                    button {
                        min-height: 42px;
                        min-width: 88px;
                        font-size: 14px;
                        height: unset !important;
                        padding: 5px 16px;
                        width: auto;
                        font-weight: 700;
                        line-height: 15px;
                        color: #37475b !important;
                        border-radius: 39px !important;
                        border: unset !important;
                        text-align: center;
                        background-color: transparent;

                        &:disabled {
                            opacity: 0.65;
                            color: #999999 !important;
                            cursor: no-drop;
                        }
                    }

                    &:disabled {
                        opacity: 0.65;
                        color: #999999 !important;
                    }

                    .active-tab {
                        color: var(--white-text-on-primary) !important;
                        background-color: var(--primary);
                        border-radius: 39px !important;
                        transition: all 0.3s ease;
                    }
                }
            }

            .cart-widget-reco-products {
                .mini-label {
                    margin-bottom: 0;
                }
            }
        }
    }
    .cart-box-shadow-top {
        background-color: #ffff;
        border: none;
        padding-bottom: 0px;
        @include desktop {
            box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
        }
        .promo-code-container {
            input {
                //font-size: 1em;
                //border-right: 0;
                font-size: 1em;
                min-height: 50px;
                background-color: #fcfdfe;
                // color: #a0a0a0;
                // border-radius:0;
            }
            .form-control::placeholder {
                color: #a0a0a0;
            }
            .apply-btn {
                padding: 0px 15px;
                color: #4d4d4d;
                line-height: 17px;
                border-radius: 6px;
            }
            .promo-messsage {
                font-size: 13px;
            }

            .promo-after {
                border: 1px solid lightgray;
                padding: 10px;
                border-radius: 5px;
                background-color: white;
            }
            .remove-promo {
                position: relative;
                left: 0;
                top: -1px;
                padding: 0;
                width: 25px;
                height: 25px;
                text-align: center;
                line-height: 25px;
                background: transparent;
                border: 1px solid #c7c7c7;
                color: #888688;
                font-size: 0.7em;
                background: transparent;
                i {
                    font-weight: bold;
                    line-height: inherit;
                }
            }

            // background-color:  #F8F8F8 ;
            border-top: 15px solid #f5f5f5;
            border-bottom: 15px solid #f5f5f5;
            padding: 15px;

            @include desktop {
                padding: 25px;
            }
        }
    }
    .custo-info-font {
        font-size: 13px;
    }
    .wallet-container {
        .custom-checkbox label {
            padding-left: 22px;
        }
        .w-inner {
            // border:1px solid $primary;
            // border: 1px solid #d8dee3;
            padding: 10px;
            border-radius: 5px;
        }
        .icon-wallet,
        .icon-coin,
        .icon-jerrycan,
        .icon-gift-thin {
            font-size: 18px;
            margin-right: 5px;
        }
        .wallet-amount {
            padding: 5px 0;
        }
        span {
            // vertical-align: middle;
        }
        .amt {
            display: inline-block;
            margin-top: 2px;
        }
        .btn-amt {
            border: 1px solid var(--primary);
            margin-right: 8px;
            padding: 5px 18px;
            border-radius: 6px;
            i {
                color: var(--gd-black-color);
            }
        }
        .btn-amt-cancel {
            border: 1px solid #f44336;
            margin-right: 8px;
            padding: 5px 18px;
            border-radius: 6px;
            i {
                color: var(--gd-black-color);
            }
        }
        .wallet-info {
            padding-left: 35px;
            margin-top: 6px;
        }
        .credit-arrow::-webkit-outer-spin-button,
        .credit-arrow::-webkit-inner-spin-button {
            @include desktop {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }
    .item {
        background: white;
        width: 100%;

        @include desktop {
            margin: 0 0 0px 0;
            padding: 10px 0px 5px 0px;
            background: white;
            width: 100%;
        }
        // border-top:1px solid $dividerColor;
        &:last-child {
            margin-bottom: 0px;
        }
        .count-outline {
            border-radius: 6px;
            border: 1px solid #d6d6d6;
            // padding: 5px;
        }
        .bottom-sec {
            // margin-top: 0.3rem;
        }
        .img-container {
            padding: 0 8px;
            margin-right: 17px;
            border: 1px solid #eeeeee;
            border-radius: 9px;
            text-align: center;
        }
        .product-name {
            //font-size: 0.9em;
            //  padding:0px 15px;
            cursor: pointer;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.1;
        }

        .c-brand-name {
            padding: 0px 15px;
            display: none;
            @include desktop {
                display: block;
            }
        }
        img {
            width: 60px;
            height: 60px;
            // @include iphone5 {
            //     width: 48px;
            //     height: 48px;
            // }
            object-fit: contain;
        }
        .count-holder {
            // font-size: 1em;
            padding: 2px;
            // padding-right: 0;
            margin: auto 0;
        }
        .deletebtn {
            position: absolute;
            top: 7px;
            right: -4px;
            width: 24px;
            height: 24px; // display: flex;
            // align-items: center;
            // justify-content: center;
            font-size: 10px;
            line-height: 25px;
            padding: 0;
            border-radius: 50%;
            background-color: white;
            border: 1px solid #e2e2e2;
            color: #b7b7b7;
            @extend %increase-touch-area;
        }
        .price-container {
            .price {
                font-size: 15px;
                // color:$primary;
                // color: var(--primary);
                // color: #333333;
                vertical-align: middle;
                font-weight: bold;
            }
        }
        .strike-text {
            margin-left: 5px;
            // color:var(--primary);
            color: #333333;
            &:after {
                height: 0.09rem;
                // background: var(--primary);
                background: red;
            }
        }
        .icon-close {
            font-size: 0.7em;
            margin: 0 6px;
            margin-left: 10px;
        }
        .update-count-btn {
            background: transparent;
            padding: 0 5px;
            &:focus {
                box-shadow: none;
            }
            span {
                height: 20px;
                width: 20px;
                // line-height: 1.2;
                // border: 1px solid #333;
                display: inline-block;
                border-radius: 50%;
                color: var(--primary); // padding: 9px 0;
                font-size: 15px;
                .count-btn {
                    color: var(--primary);
                    font-size: 12px;
                }
            }
            &.delBtn {
                span {
                    border: none;
                    position: relative;
                    top: 1px;
                    font-size: 12px;
                }
            }
        }
        .count {
            width: 25px;
            height: 25px;
            // background-color: #FCE68C;
            color: black;
            border: 0;
            text-align: center;
            border-radius: 3px;
            line-height: 1.6; //height: 100%;
            font-size: 18px;
            input {
            }
            select {
                padding: 0;
                width: 30px;
                font-size: 1em;
                height: 26px;
                border: 0;
                background-color: #cacaca;
                color: white;
                text-align: center;
                -webkit-appearance: none;
                padding-left: 12px;
                display: inline-block;
            }
        }
        .weight-category {
            margin-top: 2px;
        }
        .sale-info {
            text-align: left;
            padding-left: 68px;
            color: #858585 !important;
            @include desktop {
            }
        }
    }

    .current-order-info {
        // position: fixed;
        // bottom: 0;
        // width: 100%;
        // height: $currentOrderInfoHeight + 3px;
        // z-index: 1;
        // pointer-events: none;
        // .btn {f
        //     pointer-events: all;
        // }
        // @include desktop{
        //     // display:none;
        // }
    }
    @include desktop {
        .cart-item-holder {
            display: flex;
            position: static;
            overflow: unset;
            margin-top: 100px;
            // flex-direction: row-reverse;
            // & > div {
            //     width: 50%;
            // }
            .cart-items {
                width: 50%;
                padding-left: 35px;
                position: sticky;
                top: 60px;
                overflow-x: hidden;
            }
            .customer-info {
                box-shadow: none;
                width: 50%;
                padding-right: 25px;
            }
        }
        .cart-items {
            position: sticky;
            position: -webkit-sticky;
            // height: calc(100vh - var(--navbar-height) + 10px);
            // top: calc(var(--navbar-height) + 5px);
        }

        .right-cart {
            padding-top: 15px;
            .wallet-with-purchase-wrapper {
                padding: 10px 15px !important;
                & + .item {
                    padding-left: 20px;
                }
            }
        }
        .bottom-holder {
            position: sticky;
            position: -webkit-sticky;
            bottom: 0;
            z-index: 10;
            width: 50%;
            margin-left: auto;
            .discount-strip {
                position: absolute;
                bottom: 69px;
            }
            .current-order-info {
                position: absolute;
                //bottom:$footerHeight;
            }
        }
        @include ipadPro {
            .cart-item-holder {
                .customer-info {
                    padding-right: 15px;
                }
            }
        }
    }

    .friends-family-pop-up {
        .fnf-close-btn,
        .fnf-arrow-btn {
            position: absolute;
            right: 8px;
            top: 17px;
            color: white;
            font-size: 9px;
            font-weight: bold;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            border: 1px solid white;
            padding: 0;
            line-height: 25px;
            text-align: center;
            @include desktop {
                right: 15px;
            }
        }
        // margin: 5px 25px;
        //survi - enable for web
        .friends-family-display_web {
            // position: fixed;
            // bottom: 75px;
            // width: 100%;
            // left: 0px;
            // right:0;
            // background: #36C0C8;
            // color: $primary;
            // z-index: 2;
            // &.is-discount-applied{
            //     bottom:99px;
            // }

            position: relative;
            bottom: auto !important;
            width: auto;
            // left: 15px;
            // background: #FCE68C;
            color: #404040;
            // padding: 15px;
            // z-index: 2;
            border-radius: 16px;
            overflow: hidden;
            // box-shadow: 0px 2px 6px 0px rgba(135, 135, 135, 0.6);
            .ff-block {
                padding: 15px;
                @include desktop {
                    padding: 20px;
                }
                h6 {
                    font-weight: 500;
                }
                .white-btn {
                    font-weight: 300;
                    border: 1px solid var(--primary);
                    line-height: 17px;
                    cursor: pointer;
                    color: #4d4d4d;
                    font-size: 14px;
                }
            }
        }

        .friends-family-cart-rewards {
            .fnf-header {
                @include desktop {
                    font-size: 18px;
                }
            }
            @include notDesktop() {
                // position: fixed;
            }
            .cart-colllapse {
                width: 100%;
                background: transparent;
                border-radius: 0;
                border: none;

                .rc-collapse-item {
                    .rc-collapse-header {
                        color: #fff;
                        line-height: 1.5;
                        outline: 0;
                    }
                    &.rc-collapse-item-active {
                        .f-f-sign-up {
                            display: none;
                        }
                        & + .btn {
                            display: none;
                        }
                    }

                    .rc-collapse-content {
                        background: transparent;
                        color: $primary;
                    }
                }
            }

            .text-content {
                font-size: 13px;

                @include desktop() {
                    padding-right: 0;
                }

                hr {
                    border-color: rgba(255, 255, 255, 0.35);
                }
            }

            .btn-content {
                margin-top: 20px;
                position: relative;
                padding-top: 20px;

                @include desktop() {
                    margin-top: 0;
                    padding-top: 0;
                }

                &:before {
                    content: "";
                    position: absolute;
                    top: 0px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 70%;
                    height: 1px;
                    background: rgba(255, 255, 255, 0.8);

                    @include desktop() {
                        content: none;
                    }
                }

                h5 {
                    font-weight: 800;
                    margin-bottom: 10px;
                    font-size: 13px;
                    line-height: 1.5;
                }

                .check-holder {
                    @include desktop() {
                        align-items: flex-start;
                        justify-content: center;
                    }

                    .custom-checkbox {
                        label {
                            &:before {
                                border-color: $primary;
                            }
                        }
                    }
                }

                .text-underline {
                    color: $primary;
                }
            }
        }

        .discount-strip {
            + .friends-family-cart-rewards {
                bottom: 94px;
            }
        }
    }

    @include notDesktop {
        margin: 0;
        width: 100%;
        max-width: 100%;
    }
    .empty-cart-holder {
        width: 80%;
        @include desktop {
            width: 60%;
        }

        margin: auto;
    }

    // & + .footer{
    //     display:none!important;
    //     @include desktop {
    //         display:block!important;
    //     }
    // }
    .custoinfo-border {
        // border: 1px solid #d8dee3;
        // border-left: none;
        // border-right: none;
        // border-top: none;
        //border-radius: 0px;
        padding: 0px 15px;
        // background-color: #F8F8F8;
        // border-radius: 10px;
        @include desktop {
            //border: 1px solid #d8dee3;
            //border-radius: 5px;
            //padding: 15px;
        }
        &.preloader {
            height: 329px;
        }
    }
    .id-upload {
        margin-bottom: 0;
        .selfie-block,
        .photo-id-block {
            padding: 16px 10px 0;
        }

        .medi-upload {
            margin-top: 0 !important;
            padding-top: 0 !important;
            padding-bottom: 0 !important;

            .medical-id-block {
                padding: 0;
                border: 1px solid #ced4da !important;
                padding: 10px;

                .img-container {
                    margin-top: 0;
                    background-color: transparent;
                    padding: 0;
                    border-radius: 0;
                    margin-right: 0 !important;
                }
            }
        }
    }
    .payment-section {
        padding: 16px 0 0 !important;
    }
    .customer-info .wallets-section {
        padding: 16px 0 0;
    }
    .custoinfo-medicalID {
        border-bottom: none !important;
        margin-bottom: 10px !important;
        @include desktop {
            border: 1px solid #d8dee3;
        }
    }
    .medi-upload.medi-upload {
        // border-bottom: 15px solid #f5f5f5;
        // padding-bottom: 17px !important;
        // padding-top: 10px !important;
        @include desktop {
            border-bottom: none;
        }
    }
    .medicalID-btn {
        display: block;

        @include desktop {
            display: none;
        }
        &:hover {
            color: var(--primary);
            background-color: white;
            border-color: var(--primary);
        }
        &:focus {
            outline: 0;
            box-shadow: none;
        }
    }

    .full-height {
        @include notDesktop {
            display: block;
        }
    }
    .hide-height {
        @include notDesktop {
            display: none;
        }
    }

    .cart-padding {
        padding: 15px;
        @include desktop {
            padding: 20px 20px;
        }
    }
    &.giftcard-cart {
        .right-cart {
            @include desktop {
                padding-top: 20px;
            }
        }
    }
    .promo-block {
        width: fit-content;
        padding: 7px 16px;
        background-color: var(--gd-secondary-color);
        border-radius: 6px;
        color: #333333;
        border: 1px dashed #333333;
        margin-bottom: 5px;
    }
    .promo-messsage {
        p {
            font-size: 14px;
            //    margin-top: 10px;
        }
    }
    .custom-checkbox input:checked + label:before {
        background-color: var(--primary);
        color: #ffffff;
        border: none;
    }
    .custom-checkbox label:after {
        border-left: 3px solid #ffffff;
        border-bottom: 3px solid #ffffff;
    }
    .link-color {
        color: #1a0dab;
    }
    .stronghold-list {
        background-color: #f8f8f8;
        border-radius: 6px;
        padding: 15px 15px 5px 15px;
    }
    .mini-label {
        color: #333333;
        margin-bottom: 5px;
        font-size: 12px;
    }
    .product-label {
        background-color: #fce68c;
        border-radius: 4px;
        font-size: 12px;
        padding: 0px 5px;
    }
    .tesla-cart-wrapper {
        margin: -15px -15px 20px;
        @include desktop {
            margin: -20px -20px 20px;
        }
        a {
            :hover {
                text-decoration: none;
            }
        }
    }
    .cart-widget-parent {
        padding: 0px !important;
    }
    .save-for-later-link {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        text-decoration-line: underline;
        color: #1a0dab;
        margin-left: 15px;
        cursor: pointer;
        white-space: nowrap;
    }
    &.cart-fix-mt {
        // margin-top: 50px;
        &.no-tab {
            // margin-top: 0px;
        }
    }
    .loader-3dots {
        &::before {
            background-color: transparent !important;
        }
        &::after {
            content: none !important;
            display: none;
        }
    }

    .checkout-img-upload {
        border: 1px solid #dedede !important;
        padding: 15px 5px 15px 15px;
        background-color: #fff;
        border-radius: 10px;
        margin-top: 15px;

        // .img-container{
        //     margin-bottom: 18px;
        // }

        .selfie-block {
            position: relative;
            padding-top: 0;
            margin-top: 0;
            border: 1px solid #ced4da !important;
            padding: 10px !important;
            // &::before {
            //     content: " ";
            //     width: 1px;
            //     height: 100%;
            //     display: block;
            //     background-color: #999;
            //     position: absolute;
            //     right: -10px;
            //     top: 0px;
            // }
        }
        .photo-id-block {
            padding-top: 0;
            border: 1px solid #ced4da !important;
            padding: 10px !important;
        }
    }

    .medi-upload.checkout-medi-card {
        .my-file-input {
            // width: 100%;
            margin-top: 6px;
            margin-bottom: 6px;
            label {
                width: 100%;
            }
            .btn-plus {
                width: 100%;
                // border: 1px dashed rgba(0, 0, 0, 0.56);
                // border-radius: 0;
                // height: 78px;
                // font-size: 14px;
                position: relative;
                height: 30px;
                width: 30px;

                .upload-text {
                    display: inline-block !important;
                    color: #172d67;
                    font-size: 13px;
                    font-weight: 500;
                    margin-left: 8px;
                }
            }
            label[class*="disable"] {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
.recommended-products {
    .sale {
        position: absolute;
        left: 10px;
        top: 8px;
        background-color: #ff7f68;
        color: white !important;
        border-radius: 2px;
        padding: 1px 6px;
        font-size: 12px;
        @include desktop {
            // top:4px;
        }
    }

    // position: fixed;
    // left: 0px;
    // right: 0px;
    .price-container {
        .price {
            font-size: 16px;
            color: var(--primary);
            font-weight: 500;
        }

        .strike-text {
            margin-left: 5px;
            color: rgb(211 47 47);
            &:after {
                height: 0.09rem;
                background: #333;
            }
        }
    }

    .product-list-h {
        overflow-x: auto;
        white-space: nowrap;
        margin: 0 -12px;
        padding-bottom: 5px;
        @include desktop {
            margin: 0;
            padding-bottom: 10px;
        }
    }
    .r-title {
        font-size: 16px;
    }
    .atc-btn {
        &:focus {
            box-shadow: none;
        }
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        width: 40px;
        height: 40px !important;
        border-radius: 50%;
        // border: 1px solid var(--primary);
        box-shadow: 0px 2px 4px rgb(0 0 0 / 16%);
        padding: 0;
        background: white;
        color: var(--primary);
        font-size: 20px;
        .icon-close {
            font-size: 15px;
        }
        .inline-loader {
            top: 3px;
            right: 4px;
            @include desktop {
                top: 4px;
            }
        }
        .atc-icon {
            top: 50%;
            left: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
            font-size: 12px;
        }
    }
    .p-item {
        // border: 1px solid var(--primary);
        display: inline-flex;
        justify-content: center;
        flex-direction: column;
        padding: 5px;
        padding-left: 75px;
        padding-right: 70px;
        margin-right: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        margin-left: 3px;
        border: none;
        img {
            position: absolute;
            width: 60px;
            height: 60px;
            object-fit: contain;
            left: 5px;
            top: 50%;
            transform: translateY(-50%);
        }

        @include desktop {
            padding-left: 95px;
            img {
                width: 80px;
                height: 80px;
            }
        }
    }
    .r-adjust-padding {
        padding-left: 92px;
        padding-right: 60px;
    }

    .h-list-parent .h-list {
        padding: 6px 1px;
        padding-bottom: 10px;
        margin-bottom: 0rem;
    }
}

.is-cart-page {
    --navbar-height: 94px;
    background-color: #f2f1f6;
    .header {
        // max-height: calc(70px + var(--hh-height) + var(--delivery-info-height));
        @media (max-width: 990px) {
            max-height: 65px;
        }
        .left-btn.left-btn {
            // top: 7px !important;
        }
    }
    .h-search-btn,
    .t-search-bar,
    .set-delivery-address-btn,
    .s-sec-i {
        display: none !important;
    }
    .footer {
        display: none;
        @include desktop {
            display: block;
        }
    }
    [title="Opens a widget where you can find more information"] {
        bottom: 65px !important;
    }
    #launcher {
        bottom: 65px !important;
    }

    .cart-push-up {
        bottom: 137px !important;
    }

    @include notDesktop {
        #chat-widget-container {
            top: auto;
            bottom: 53px !important;
        }
        .acsb-trigger.acsb-trigger-size-small {
            bottom: 70px !important;
            left: 10px !important;
        }
    }
    @include ipadPro {
        #chat-widget-container {
            top: auto;
            bottom: 53px !important;
        }
        .acsb-trigger.acsb-trigger-size-small {
            bottom: 70px !important;
            left: 10px !important;
        }
    }
    &.chat-opened {
        @include notDesktop {
            #chat-widget-container {
                top: 0 !important;
            }
        }
    }
    &.chat-minimized {
        @include notDesktop {
            #chat-widget-container {
                top: auto !important;
            }
        }
    }
    .fat-border {
        border-top: 15px solid #f5f5f5;
    }
    .tipinfo-popup {
        background-color: rgba(0, 0, 0, 0.69);
        .modal-dialog {
            @include desktop {
                width: 390px;
            }
        }
        .modal-header {
            img {
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
            }
            button {
                position: absolute;
                right: 0px;
                margin-right: 25px;
                margin-top: 15px;
                @include desktop {
                    margin-right: 15px;
                }
            }
        }
    }
    .couponcode-popup {
        background-color: rgba(0, 0, 0, 0.69);

        .modal-content {
            background-color: #f5f6fa;
            padding: 20px;
            min-height: 320px;
            .apply-coupon {
                input:focus {
                    box-shadow: none;
                }
                // display: flex;
                // justify-content: space-between;
                // padding: 20px 16px;
                button {
                    border: 1px solid var(--primary);
                    // padding: 7px 15px;
                    border-radius: 6px;
                    line-height: 17px;
                    // padding-top: 10px;
                }
            }
            .rounded-card {
                box-shadow: 0px 3px 16px #00000002;
                & .promo-after:not(:last-child) {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    padding-bottom: 10px;
                }
            }
            .offers-block {
                .promo-after {
                    .promo-block {
                        width: fit-content;
                        padding: 7px 16px;
                        background-color: var(--gd-secondary-color);
                        border-radius: 6px;
                        color: #333333;
                        border: 1px dashed #333333;
                        margin-bottom: 5px;
                    }
                    .promo-messsage {
                        p {
                            font-size: 14px;
                            margin-top: 10px;
                        }
                    }
                    .link-color {
                        color: #1a0dab;
                    }
                    button {
                        border: 1px solid var(--primary);
                        padding: 7px 15px;
                        border-radius: 6px;
                        line-height: 17px;
                        &:hover {
                            background-color: var(--primary);
                            color: #ffff;
                        }
                    }
                    .promo-applied {
                        color: #4caf50;
                        margin-bottom: 0px;
                    }
                }
                .promo-no-coupon {
                    padding: 20px;
                    i {
                        font-size: 90px;
                        color: #d8d8d8;
                        margin-bottom: 25px;
                        display: block;
                    }
                }
            }
        }
        .loader-minH {
            min-height: 38px;
        }
    }
}
.debit-popup {
    background-color: rgba(0, 0, 0, 0.69);
    input {
        border-radius: 6px;
        font-size: 1em;
        min-height: 40px;
    }
    .mini-label {
        color: #333333;
        margin-bottom: 5px;
        font-size: 12px;
    }
    .modal-content {
        min-height: 427px;
        .loader {
            top: 40%;
        }
    }
}

iframe[id^="lg-iframe"] {
    z-index: 9999;
    position: fixed !important;
    top: 0px !important;
    left: 0 !important;
}
.cart .added-rewards-widget {
    margin: 0 -15px;
    .unclaimed-rewards {
        margin: 15px 15px !important;
    }
    .item {
        padding: 0 15px;
    }
    @include desktop {
        margin: 0 -20px;
        .unclaimed-rewards {
            margin: 15px 20px !important;
        }
        .item {
            padding: 0 20px;
        }
    }
}
.cart-fix-container {
    position: fixed;
    top: var(--navbar-height);
    width: 100%;
    z-index: 1030;
    transition: top 0.3s ease-in-out;
    &.header-in-view {
        top: 0;
    }
    .discount-carousel {
        margin-top: 0px;
        .discount-card {
            border-radius: 0;
        }
    }
}
.cart .right-cart .wallet-with-purchase-wrapper {
    margin: 10px 15px 20px !important;
    @include desktop {
        margin: 20px !important;
    }
}
.loader-min-height-promo-section {
    &.cart-loader {
        width: 100%;
        height: 100%;
        position: fixed;
        left: 50%;
        &::before {
            background-color: rgba(0, 0, 0, 0.05);
        }

        &::after {
            content: none;
        }
    }
}

.cart .cart-items.right-cart {
    .offer-benefits {
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        border: 1px solid rgba(0, 0, 0, 0.12);
        background: #ffffff;
        border-radius: 8px;
        padding: 12px;

        + .cart-white-card {
            margin-top: 12px;
        }
    }
    .recommend-prod-section {
        h6 {
            margin-top: 0;
        }
    }

    .cart-items-holder {
        h6 {
            margin-top: 0;
        }
    }
}
