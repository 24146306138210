.search-page {
  background: var(--body-background-color);
  padding-bottom: 30px;

  .main-search-input {
    border: 1px solid #e8e8e8;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 27px;
    padding: 0.3rem 38px 0.3rem 38px;
    line-height: 17px;
    width: 100%;
    /* height: 100%; */
    min-height: 40px;
    font-size: 16px;
    font-weight: 700;
    &::placeholder {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      color: #999999;
    }
    @include desktop {
      min-height: 58px;
      cursor: text;
      box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.1);
    }
    &:focus-visible {
      outline: none;
    }
  }
  .top-brands-slider {
    h2 {
      color: #212529;
      // font-size: 16px;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
    }
    .img-brand {
      width: 98px;
      height: 98px;
    }
  }
  .search-category-wrapper {
    .searchBox {
      margin-top: 0px;
      // background: #ffffff;
      .search-close-btn,
      .search-btn {
        position: absolute;
        z-index: 1;
        // font-size: 14px;
        transform: translateY(-50%);
        font-size: 14px;
        cursor: pointer;
        top: 52%;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      .search-close-btn {
        right: 15px;
        top: 30px;
        @include desktop() {
          right: 10px;
        }
      }
      .search-btn {
        padding-left: 15px;
        @include notDesktop() {
          top: 32px;
        }
      }
    }
    &.search-scroll {
      @include notDesktop() {
        position: fixed;
        top: -10px;
        width: -webkit-fill-available;
        z-index: 999;
        box-shadow: 0px 15px 20px 2px rgba(0, 0, 0, 0.06);
      }
      .gd-search-address {
        @include notDesktop() {
          display: none !important;
        }
      }
      .selected-filters {
        margin-top: 5px;

        // @include desktop(){
        //   margin-bottom: 5px;
        // }
      }
      .filter-product-page {
        @include notDesktop() {
          margin-top: -15px;
        }
      }
    }
    .filter-btn {
      padding-right: 15px;
      .icon {
        color: #37475b;
        font-size: 24px;
      }
      .filter-indicator {
        top: 0;
        right: 10px;
        width: 8px;
        height: 8px;
      }
    }
    position: sticky;
    top: calc(var(--navbar-height) - 30px);
    z-index: 2;
    background-color: var(--body-background-color);
    @include desktop() {
      top: var(--navbar-height);
    }
  }
  .search-stats {
    .search-no-match {
      color: #c96d46;
      font-size: 16px;
      font-weight: 500;
      padding-top: 6px;
      @include desktop() {
        margin-top: -10px;
        padding-top: 0px;
      }
      + p {
        padding-bottom: 15px;
        margin-bottom: 5px;
        @include desktop() {
          padding-bottom: 10px;
          margin-bottom: 0px;
        }
      }
    }
    .show-results {
      font-style: italic;
      font-size: 16px;
      font-weight: 500;
      padding-bottom: 12px;
      // @include notDesktop(){
      //   padding-top: 10px;
      // }
    }
  }
  .recent-search-text {
    font-size: 20px;
    font-weight: 700;
    margin-top: 15px;
    @include desktop {
      margin-top: 20px;
    }
  }
  .search-pill {
    width: auto;
    min-height: 35px;
    border-radius: 20px;
    background: #fff;
    padding: 0 10px;
    margin-right: 10px;
    margin-top: 10px;
    line-height: 35px;
    word-break: break-word;
    border: 1px solid var(--lightest, #ebebeb);

    i {
      font-size: 12px;
    }
    span {
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      @include notDesktop() {
        display: block;
        width: max-content;
      }
    }
    button {
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
  .gd-brand-menu {
    margin-bottom: 0px !important;
    h2 {
      margin-bottom: 17px;
    }
    .h-list-parent {
      .h-list {
        padding: 6px 0px;
      }
    }
  }
  .search-text {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 17px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    &.top-search {
      margin-bottom: 10px;
    }
  }

  .mobile-scroll-pills {
    flex-wrap: wrap;
    @include notDesktop() {
      overflow-x: scroll;
      padding-bottom: 12px;
      flex-wrap: unset;
    }
    .search-pill {
      @include notDesktop() {
        word-break: unset;
        display: flex;
        align-items: center;
      }
    }
  }

  .search-results-count {
    margin-top: 15px;
    margin-bottom: -20px;
    @include desktop() {
      // background: #ffffff;
      margin-top: -16px;
      margin-bottom: 0;
      padding-top: 8px;
    }
  }
  .filter-product-page {
    .desktop-filter-container {
      @include desktop() {
        padding-bottom: 20px;
      }
      .selected-filters {
        margin-bottom: -10px;
      }
    }
    .selected-filters {
      @include notDesktop() {
        margin-top: -15px;
      }
    }
  }
  .search-tab-title {
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
  }
  .latest-product {
    .strain-fix-container {
      margin-bottom: 8px;
      margin-top: 6px;
      @include desktop() {
        margin-bottom: 12px;
      }
      .strain-container {
        @include notDesktop() {
          margin-bottom: 0 !important;
        }
        span{
          font-size: 12px !important;
        }
        &.search-brand {
          background: inherit;
          [class^="ais-"] {
            font-size: 10px;
            box-sizing: unset;
          }
        }
      }
    }
  }
  .ais-Highlight {
    mark {
      background-color: transparent;
      color: var(--primary);
      padding: 0;
    }
  }
  .all-products-slider {
    background: transparent;
    .h-list {
      padding: 0;
      .product-space-between-container {
        background: #ffffff;
        cursor: default;
      }
      .prod-details-wrap {
        a {
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
  .search-product {
    &.recent-products {
      .latest-product {
        @include desktop {
          flex: 0 0 220px;
          margin-right: 24px;
        }
      }
      .all-products-slider{
        .h-list{
          @include mobile{
            min-height: 400px;
          }
          .latest-product{
            @include mobile{
              height: 100%;
            }
          }
        }
      }
    }
    .v-list {
      display: block;
      position: relative;
      display: grid;
      align-items: start;
      grid-gap: 0 15px;
      @include desktop() {
        // display: grid;
        // grid-gap: 12px;
        // grid-template-columns: repeat(5, 1fr);
        // align-items: start;
        display: flex;
        grid-gap: 12px;
        flex-wrap: wrap;
      }
      @include combineIpad() {
        display: grid;
        align-items: start;
        grid-gap: 0 12px;
      }
      @include ipadProLandscape() {
        grid-gap: 12px;
      }
      @include ipad {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 16px;
      }
      @include ipadLandscape() {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 16px;
      }
      @include ipadPro() {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 16px;
      }
      @include mobile{
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 12px;
      }
      .latest-product {
        margin-right: 0;
        flex: 0 0 240px;
        @include desktop{
          min-height: 305px;
        }
        @include notDesktop() {
          min-height: auto;
          // margin-bottom: 15px;
        }
        @include ipadProLandscape() {
          min-height: 355px;
        }
        @include notDesktop() {
          margin-bottom: 12px;
        }
        @include mobile{
          height: 100%;
          margin-bottom: 0px;
        }
        @include ipad{
          margin-bottom: 0;
        }
        @include ipadPro() {
          margin-bottom: 0px;
        }
        
        // &.similar{
        //   @include desktop(){
        //     margin-bottom: 145%;
        //     // grid-row: 5;
        //   }
        //   @include combineIpad(){
        //     margin-bottom: 50%;
        //   }
        //   @include ipadProLandscape(){
        //     margin-bottom: 175%;
        //   }
        // }
        .product-space-between-container {
          background: #ffffff;
          cursor: default;
          display: flex;
          // align-items: stretch;
          @include desktop() {
            min-height: 345px;
            // flex-direction: column;
            justify-content: space-between;
            // align-items: unset;
            // width: 240px;
          }
          // @include combineIpad(){
          //   min-height: 183px;
          // }
          @include ipadProLandscape() {
            // min-height: 345px;
          }
          @include ipad() {
            height: auto;
          }
          @include mobile() {
            min-height: 335px;
          }
          .product-tag-label {
            position: absolute;
            top: 0px;
            z-index: 1;
            font-size: 13px;
            padding: 0 6px;
            @include desktop() {
              font-size: 14px;
              padding: 0 12px;
            }

            + .top-container {
              margin-top: 30px;
              margin-bottom: 3px;
              @include desktop() {
                margin: auto;
              }
            }
          }
          .top-container {
            padding-top: 6px !important;
            margin: auto;
            @include notDesktop() {
              padding-top: 12px !important;
              padding-right: 0 !important;
            }
            .img-wrapper {
              // @include notDesktop() {
              //   height: 90px !important;
              //   width: 90px;
              // }
            }
          }
          .bottom-container {
            @include desktop() {
              flex: unset;
              padding-top: 0 !important;
              padding-bottom: 8px !important;
            }
            .price-offer-cart-wrapper {
              // margin-top: 4px;
              @include desktop() {
                margin-top: 10px;
              }
              .price-container {
                @include desktop() {
                  margin-top: 4px;
                }
                .price{
                  @include notDesktop(){
                    margin-top: 10px;
                  }
                }
              }
              @include mobile{
                position: unset;
              }
              .new-add-to-cart{
                @include mobile(){
                  top: unset;
                  bottom: -2px;
                  width: 100%;
                  left: 0;
                }
              }
            }
            .product-name-weight{
              margin-bottom: 4px !important;
            }
            .thc-fix-container{
              text-align: left;
              font-weight: 400;
              font-size: 12px !important;
              line-height: 15px;
              color: #262626 !important;
              text-transform: capitalize !important;
              span{
                text-align: left;
                font-weight: 400;
                font-size: 12px !important;
                line-height: 15px;
                color: #262626 !important;
                text-transform: capitalize !important;
              }
            }
            .brand-name{
              span{
                text-align: left;
                font-weight: 400;
                font-size: 12px !important;
                line-height: 15px;
                color: #262626 !important;
                text-transform: capitalize !important;
                position: relative;
              }
            }
          }
          .offer-tag {
            top: 4px;
          }
        }
        .prod-details-wrap {
          a {
            &:hover {
              text-decoration: none;
            }
          }
          .brand-name {
            span[class^="ais-"],
            mark[class^="ais-"] {
              font-size: 12px !important;
            }
          }
        }
      }
    }
    .similar-products {
      border-radius: 15px;
      background: #dedede;
      height: 28px;
      line-height: 28px;
      border-radius: 15px;
      font-size: 12px;
      font-weight: 600;
      border: none;
      padding: 0 8px;
      white-space: nowrap;
      // @include notDesktop(){
      //   position: absolute;
      //   left: -106px;
      //   border-radius: 0;
      //   border-bottom-left-radius: 6px;
      //   border-top-right-radius: 6px;
      //   bottom: -12px;
      // }
      span {
        font-size: 10px;
        display: inline-block;
        margin-left: 3px;
        &.up {
          transform: rotate(180deg);
        }
      }
    }
    .notify-btn {
      background: var(--primary);
      border-radius: 15px;
      height: 28px;
      padding: 0px 8px;
      font-size: 13px;
      font-weight: 600;
      color: #ffffff;
      border: none;
      line-height: 28px;
    }
  }
  .sticky-filter-shadow {
    box-shadow: none;
  }
  .search-brand {
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    row-gap: 20px;
    .single-brand {
      border-radius: 10px;
      background: #ffffff;
      padding: 10px 25px 10px 10px;
      display: flex;
      align-items: center;
      min-width: 240px;
      position: relative;
      cursor: pointer;
      @include notDesktop() {
        width: 100%;
      }
      img {
        height: 45px;
        width: 45px;
        object-fit: contain;
        object-position: center;
        border-radius: 6px;
      }
      .title {
        font-weight: 700;
        margin-left: 10px;
        display: inline-block;
        text-transform: capitalize;
        line-height: 1.4;
        span[class^="ais-"],
        mark[class^="ais-"] {
          font-size: 14px;
        }
      }
      i {
        font-size: 20px;
        color: #acacac;
        position: absolute;
        right: 5px;
      }
    }
  }
  .fixed-bottom-bar {
    .bottom-tab {
      justify-content: center;
    }
  }

  .default-category-section {
    padding-left: 0;
    padding-right: 0;
  }
}
.searchp-section {
  width: 100%;
  padding: 10px 15px 15px;
  @include desktop {
    padding: 0px;
  }
  input {
    &:focus {
      border: 1px solid #707070;
    }
  }
}
.search-tabs .wrapper .search-tabs-header .search-section {
  &.search-page-bar {
    margin-bottom: 5px;
    p {
      .search-btn {
        padding: 0;
      }
      input {
        border: none;
        height: 100%;
        width: 100%;
        padding-right: 8px;
        margin-left: -4px;
        font-weight: 700;
        &:focus {
          outline: none;
        }
        &::placeholder {
          font-style: normal;
          font-weight: 400;
          color: #999999;
        }
      }
      .search-close-btn {
        position: absolute;
        right: 10px;
        padding: 0;
        font-size: 14px;
        top: 10px;
      }
    }
  }
}

body.version-2{
  .search-page{
    .default-category-section .gc-inner .category-items .category-block:first-child {
      margin-left: 0;
    }
    .search-category-wrapper {
      position: unset;
    }
  }
} 