.customer-info {
    // padding-top:158px !important;
    // padding-top: 120px !important; //For Happy Hours
    border-radius: 20px 20px 0 0;
    padding: 15px;
    padding-top: calc(85px + var(--saving-tab-height) + var(--hh-height));
    margin-bottom: 20px;

    @include desktop {
        padding-top: 15px !important;
        margin-top: 0px;
        position: static;
        border-radius: 0;
    }

    @include ipad {
        padding-top: 85px !important;
    }

    @include mobile{
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .phone-no-logged-in {
        font-size: 14px;
        margin-top: 16px;

        label {
            margin-right: 9px;
        }
    }

    .addres-logged-in {
        padding-right: 55px;
        font-size: 14px;

        // margin-top: 0px !important;
        @include desktop {
            margin-top: 0.5rem;
        }
    }

    .offer-text {
        i {
            font-size: 18px;
            position: static;
            margin-right: 10px;
        }
    }

    .cart-user-details {
        width: 100%;
        background-color: $primary;
        border-radius: 10px;
        padding: 16px 0 0;

        .cud-mobile {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0vh;
            }

            p {
                margin: 0;
                display: flex;
                align-items: center;

                .title-icon {
                    font-size: 18px;
                    position: static;
                    margin-right: 7px;
                }

                span {
                    font-weight: 500;
                }
            }

            .icon-check-circle {
                font-size: 20px;
                color: #4caf50;
            }

            button {
                font-size: 14px;
                padding: 0;
            }
        }

        .user-name {
            justify-content: space-between;
            margin-bottom: 15px;

            .user-name-child {
                width: 48%;
            }
        }

        .cud-email-wrapper {
            padding: auto;

            .icon-discount {
                font-size: 18px;
                color: var(--primary);
            }
        }

        .cud-mobile-new-user {
            .btn-verify {
                padding: 8px 15px;
                line-height: 21px;
            }
        }
    }

    // margin-top: 80px;
    .payment-offer-parent {
        background: linear-gradient(180deg, #36bfc5 0%, #71c273 100%) 0% 0% no-repeat;
        box-shadow: 0px 3px 6px #00000029;
        padding: 12px;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 22px;
        padding-right: 15px;

        h6,
        p {
            color: white;
        }

        .payment-offer-content {
            p {
                font-size: 12px;
                margin-bottom: 0;
                line-height: 1.25;

                @include desktop {
                    line-height: 1.4;
                }
            }
        }

        img {
            width: 90px;
        }

        @include desktop {
            border-radius: 5px;
        }
    }

    padding-bottom: 0px !important;

    @include desktop {
        margin-top: 90%;
    }

    .title-icon {
        font-size: 20px;
        display: inline-block;
        position: relative;
        top: 3px;
    }

    .radio-container {
        .col-6:only-child {
            padding-left: 15px !important;
            padding-right: 0;
        }
    }

    .custoinfo-title {
        font-size: 18px;
        color: var(--gd-black-color);
        padding-left: 0px;
        margin-bottom: 0px;
        margin-top: 0px;

        @include desktop {
            margin-bottom: 0;
            color: var(--gd-black-color);
            padding-left: 0px;
            // margin-top: 42px;
        }
    }

    input,
    textarea {
        // background-color: #fcfdfe;
    }

    .custoinfo-padding {
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        border: 1px solid rgba(0, 0, 0, 0.12);
        background: #ffffff;
        border-radius: 8px;
        padding: 12px;

        +.custoinfo-padding{
            margin-top: 16px;
        }
    }

    .delivery-message {
        padding: 15px;
        // background: var(--gd-secondary-color);
        background: #efefef;
        border-radius: 7px;
        margin-top: 10px;
        margin-bottom: 10px;

        button {
            background-color: white;
            border-radius: 7px;
        }
    }

    .delivery-message-block {
        display: flex;
        justify-content: space-between;
        padding-bottom: 14px;

        .icon-moon-star-filled,
        .icon-sun-outline {
            font-size: 30px;
        }
    }

    .special-top-offer {
        padding: 5px;
        background-color: $primary;
        background-color: var(--primary);
        color: white;
        font-size: 13px;
    }

    .form-control:disabled,
    .form-control[readonly] {
        // background-color: #ffffff;
        background-color: #fcfdfe;
        opacity: 1;
        color: #a0a0a0;
    }

    .notes {
        resize: none;
        // border-radius:0;
        font-size: 14px;
        // box-shadow: 0 2px 2px 0 #00000012;
        // box-shadow: 0 2px 2px 0 #00000012;
        // color: #1A0DAB;
        margin-top: 0px !important;
        margin-bottom: 5px;
    }

    .error-msg {
        //padding-left: 44px;
        //padding-bottom: 5px;
        color: #f44336;
        margin-top: 2px;
        font-size: 1em;

        &:empty {
            padding-bottom: 0;
        }
    }

    .error-msg2 {
        padding-bottom: 5px;
        color: #f44336;
        font-size: 1em;

        &:empty {
            padding-bottom: 0;
        }
    }

    .card-parent {
        transform-origin: top;
        transition: all 0.3s linear;
        opacity: 0;
        padding: 0 30px;

        &.entered {
            opacity: 1;
        }
    }

    // padding: 20px;
    top: 0;
    // background: white;
    padding-bottom: 20px;
    border-radius: 20px 20px 0 0;
    // padding: 15px;

    // &:after {
    //     content: "";
    //     position: absolute;
    //     width: 40px;
    //     height: 5px;
    //     background: #9e9e9e;
    //     left: 50%;
    //     -webkit-transform: translateX(-50%);
    //     transform: translateX(-50%);
    //     top: 9px;
    //     border-radius: 4px;
    // }
    //overflow: scroll;
    .customer-info-inner {
        // height: auto;
        // background-color: #fff;
        // pointer-events: all;
        // margin-top: 50vh;
        // height: 610px;
    }

    .wallet-container {
        .w-inner {
            // border:1px solid $primary;
            // border:1px solid var(--primary);
            padding: 10px;
            border-radius: 5px;
        }

        .icon-wallet {
            font-size: 1.5em;
            // margin-right: 10px;
        }

        span {
            // vertical-align: middle;
        }

        .amt {
            display: inline-block;
            margin-top: 0px;
            cursor: pointer;
        }

        .wallet-info {
            padding-left: 35px;
            margin-top: 6px;
        }
    }

    .wallets-section {
        padding: 15px;

        @include desktop {
            padding: 20px;
        }
    }

    .display-mobile {
        display: block;

        @include desktop {
            display: none;
        }
    }

    .drweedy-form {
        height: 675px;
        overflow-y: scroll;
        overflow-x: hidden;

        @include desktop {
            height: auto;
            overflow: hidden;
        }
    }

    .promo-code-container {
        padding: 15px;

        @include desktop {
            padding: 20px;
        }

        input {
            //font-size: 1em;
            //border-right: 0;
            font-size: 1em;
            min-height: 40px;
            border-radius: 6px;
        }

        .apply-btn {
        }

        .promo-messsage {
            font-size: 1.2em;
        }

        .promocode-field {
            .apply-coupon {
                display: flex;
                flex-grow: 2;
                margin-bottom: 0px;
                grid-gap: 10px;
            }

            .btn {
                flex-grow: 0;
                text-align: center;
                height: auto;
                background-color: transparent;
                color: var(--primary);
                border: 1px solid var(--primary);
                width: unset;
                font-size: 14px;
                font-weight: 500;
                align-self: flex-start;
                padding: 7px 15px;

                &:hover {
                    background-color: var(--primary);
                    color: #ffffff;
                }
            }
        }

        .loader-minH {
            min-height: 84px;
        }

        .loader-min-height-promo-section {
            min-height: 100px;
        }
    }

    .promo-after {
        position: relative;

        // padding-left: 40px;
        .remove-promo {
            position: absolute;
            left: 0;
            top: -1px;
            padding: 0;
            width: 25px;
            height: 25px;
            text-align: center;
            line-height: 25px;
            background: transparent;
            border: 1px solid #c7c7c7;
            color: #888688;
            font-size: 0.7em;
            background: transparent;

            i {
                font-weight: bold;
                line-height: inherit;
            }
        }
    }

    .payment-radio-parent {
        margin-bottom: 10px;

        .fee-charge {
            font-weight: 500;
            color: var(--primary);
            font-size: 12px;
        }

        .sub-text {
            color: #717171;
            font-size: 12px;
        }
    }

    // .payment-mb{

    //     &:not(:last-child){
    //         margin-bottom: 10px;
    //     }
    // }
    .otp-on-call {
        padding: 25px 0 15px 0;

        .btn-primary {
            min-width: 250px;
        }
    }

    .radio-date-time {
        border-bottom: 1px solid #dadada;
        padding: 10px 15px;
        padding-bottom: 0;

        input {
            padding-left: 0;
            padding-top: 10px;
            padding-bottom: 10px;
            border: 0px solid transparent;
            border-radius: 0;
            font-size: 1em;
        }
    }

    input[type="time"]::-webkit-clear-button {
        display: none;
    }

    .delivery-type-container {
        i {
            font-size: 1.6em;
            margin-right: 13px;
        }

        .s-text {
            margin-top: 2px;
        }
    }

    .radio-holder {
        .custom-radio-long {
            .iconholder {
                font-size: 22px;
            }

            label {
                padding: 0 34px;
            }
        }
    }

    .bg-gray {
        // background-color: #fafafa;
        border-radius: 6px;
    }

    .delivery-type {
        .custom-radio {
            display: block;

            // margin-bottom: 18px;
            // @include desktop{
            //     margin-bottom: 10px;
            // }
            label {
                width: 100%;
                padding-left: 12px;
                margin-bottom: 0px !important;

                &:before {
                    width: 19px;
                    height: 19px;
                    top: 1px;
                    left: 3px;
                    border: 1px solid var(--primary);
                }

                &:after {
                    width: 11px;
                    height: 11px;
                    top: 5px;
                    left: 7px;
                }

                i {
                    color: var(--primary);
                    font-size: 20px;
                }
            }
        }

        .default-addr {
            label {
                div {
                    margin-left: 16px;
                }
            }
        }

        .history-addr {
            label {
                div {
                    margin-left: 16px;
                }
            }
        }

        .delivery-type-toggle-v2 {
            &.checkout-delivery-type {
                margin-left: 0;
                display: block;
                margin-bottom: 15px;
                border: 1px solid var(--secondary);

                @include mobile{
                    display: flex;
                    white-space: nowrap;
                    height: 80px;
                }

                button {
                    width: 50%;

                    @include mobile{
                        padding-top: 0 !important;
                        padding-bottom: 0 !important;
                    }
                }

                .asap-text{
                    text-align:  center !important;
                }

                .schedule-tab{
                    @include mobile{
                        min-width: unset;
                    }
                }
            }
            @include desktop {
                width: 100% !important;
            }
        }

        .asap-yellow-section {
            background: var(--gd-secondary-color);
            padding: 15px 15px 3px;
            border-radius: 7px;
            position: relative;
            margin-top: 10px;
            width: 100%;

            // @include desktop{
            //     padding: 50px 15px 4px;
            // }

            .subtitle {
                margin-top: 0px !important;
                margin-bottom: 0 !important;
            }

            * {
                color: #212529 !important;
            }
        }
    }

    //   .date-time-box-parent{
    //     .inner{
    //       padding:10px 10px 0 10px;
    //     }
    //    border-bottom:1px solid #dadada;
    //     input{
    //       padding-left:0;
    //       border:0;
    //       border-radius:0;
    //       font-size: 1em;
    //     }
    //   }
    // .card-list {
    //   .delete-btn {
    //     right: 0;
    //     position: absolute;
    //     background: transparent;
    //     top: 0;
    //   }
    //   .custom-radio {
    //     display: block;
    //     padding: 8px 0;
    //   }
    // }
    .address-sticky {
        display: flex;
        flex-direction: column-reverse;
        position: fixed;
        bottom: 57px;
        width: 100%;
        left: 0px;
        right: 0;
        // background: #36C0C8;
        // color: #ffffff;
        z-index: 2;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        margin-bottom: 5px !important;
        background-color: #ffffff;
        box-shadow: -1px 0px 6px #0000001f;

        @include desktop {
            flex-direction: column;
            position: relative;
            bottom: auto !important;
            width: auto;
            // color:#3333;
            // background: #ffffff;
            box-shadow: none;
            padding: 0px;
            z-index: 1;
        }

        .customer-info .delivery-type .custom-radio label {
            padding-left: 30px;
        }

        .set-delivery-block {
            padding: 15px;
            border: 1px solid #d6d6d6;
            margin-bottom: 0px;

            @include desktop {
                padding: 0px;
                border: none;
            }

            // h6{
            //     margin-top: 0px !important;
            //     @include desktop{
            //         margin-top: 36px !important;
            //     }
            // }
            .error-message {
                color: var(--red);
                font-size: 90%;
                margin-left: 0;
                @include desktop {
                    margin-left: 5px;
                }
                button {
                    height: 12px;
                    width: 12px;
                    border: 1px solid var(--red);
                    border-radius: 50%;
                    background-color: transparent;
                    padding: 0;
                    i {
                        font-size: 6px;
                        line-height: 12px;
                        color: var(--red);
                        position: relative;
                        top: -6px;
                    }
                }
            }
        }

        .history-addr-block {
            padding: 15px;

            margin-top: 3px;

            @include desktop {
                padding: 0px;
                margin-top: 10px;
            }

            span {
                padding: 5px 10px;
                font-size: 12px;
            }

            .history-addr-bg {
                background-color: #f8f8f8;
                border-radius: 6px;
                padding: 15px;
                margin-bottom: 18px;
            }

            .history-addr-mb {
                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }
        }
    }

    .notes-close-btn {
        margin-right: -5px;

        i {
            transform: rotate(180deg);
            display: inline-block;
        }
    }

    .addressbox-parent {
        .pac-target-input {
            padding-right: 38px;
        }

        .inline-loader {
            top: 34px;
            right: 5px;
        }

        .abs-btn {
            position: absolute;
            font-size: 14px;
            top: 24px;
            right: 8px;

            @include desktop {
                right: -6px;
                //bottom: 125px;
            }

            &.a-cancel-btn {
                top: 5px;
                right: 10px;
                // font-size: 10px;
            }

            .icon-down {
                transform: rotate(180deg);
                display: inline-block;
            }

            &:focus {
                box-shadow: none;
            }
        }

        .abs-btn-medical {
            position: absolute;
            top: -6px;
            right: 13px;

            @include desktop {
                right: 0px;
            }
        }

        .abs-btn-medical-close {
            position: absolute;
            top: 6px;
            right: 20px;

            @include desktop {
                right: 7px;
            }
        }

        // .special-input:empty + .small-button{
        //   display: none;
        // }
        .form-control::-webkit-input-placeholder {
            // line-height: 45px;
            // border:1px solid red;
        }

        &:last-child {
            //border-bottom: 1px solid $dividerColor;
        }

        .small-button {
            position: absolute;
            right: 10px;
            font-size: 1em;
            top: 11px;
            padding: 0.05rem 0.5rem;
            color: $primary;
            color: var(--primary);
            background-color: transparent;
            background-image: none;
            border-color: $primary;
            border-color: var(--primary);

            &.left {
                right: auto;
                left: 100px;
            }
        }

        .phone-number {
            padding-left: 70px;

            @include iphone5 {
                padding-left: 60px;
            }
        }

        .country-code {
            display: block;
            color: #a09fa0;
            position: absolute;
            //left: 42px;
            top: 27px;
            line-height: 24px;
            // border: 0;
            width: 70px;
            //padding: 0;
            // background: transparent;
            border-radius: 0.25rem;
            -webkit-appearance: none;
            -moz-appearance: none;
        }

        input {
            //height: auto;
        }

        input,
        select {
            //border: 0px solid transparent;
            border-radius: 6px;
            font-size: 1em;
            min-height: 40px;

            // height: 45px;
            // line-height: 45px;
            //padding-top: 14px;
            //padding-bottom: 14px;
            &:focus {
                //border: 0;
                //box-shadow: none;
            }
        }

        textarea {
            //border: 0px solid transparent;
            // border-radius: 0;
            font-size: 1em;
            min-height: 45px;

            //padding-right: 60px;
            &:focus {
                //border: 0;
                box-shadow: none;
            }
        }

        &.border-top {
            //border-top: 1px solid $dividerColor;
        }

        &.border-bottom {
            //border-bottom: 1px solid $dividerColor;
        }

        &.clipped-border {
            position: relative;

            &:before {
                content: "";
                position: absolute;
                height: 1px;
                background-color: #dadada;
                width: calc(100% - 44px);
                right: 0;
                top: 0;
            }
        }

        &.new-input-box {
            .phone-number {
                margin-left: 42px;
                padding-left: 10px;
            }

            .i-inner {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 10px;

                .mobile-input {
                    border: 1px solid #d6d6d6;
                    width: 85%;
                    display: flex;
                    border-radius: 6px;
                    overflow: hidden;
                }

                input {
                    // border: 0;
                    width: 100%;
                    // border-bottom: 1px solid #d9d9d9;
                    // min-height: 30px;
                    padding: 0.375rem 10px;
                    box-shadow: none;
                    outline: none;
                    border: none;
                }

                select {
                    // border-bottom: 1px solid #d9d9d9;
                    border-radius: 6px;
                    width: 50px;
                    // min-height: 30px;
                    // box-shadow: none;
                    // top: 21px;
                    text-align: center;
                    background-color: transparent;
                    border: none;

                    &:focus {
                        box-shadow: none;
                        outline: none;
                    }
                }

                .verify-btn {
                    border: 1px solid var(--primary);
                    font-size: 14px;
                    padding: 4px 20px;
                    height: calc(40px);
                    width: 20%;
                }
            }
        }
    }

    .text-bottom {
        vertical-align: text-bottom !important;
    }

    .email-input-parent {
        .i-inner {
            display: flex;
            align-items: center;

            // border: 1px solid #ced4da;
            input {
                // border:0;
            }

            .small-button {
                margin-right: 0;
                position: absolute;
                top: 35px;
                right: 11px;
                color: #4d4d4d;
                line-height: 17px;
                height: auto;
            }
        }

        .small-button {
            position: static;
            white-space: nowrap;
            margin-right: 10px;
            // padding-left: 0;
            border-radius: 6px;
        }

        //input {
        //padding-right: 80px;
        //}
    }

    .contact-admin-parent {
        border: 1px solid #ff9800;
        padding: 6px 10px;
        /* line-height: 34px; */
        border-radius: 5px;
        margin-top: 0.5rem;
        /* line-height: 1; */
        background-color: #fff3e0;
    }

    .contact-admin {
        color: #333;
        white-space: normal;
        padding-left: 0;
        padding: 0;
        line-height: 1.8;
    }

    .stronghold-signup {
        color: var(--primary);
        white-space: normal;
        padding-left: 0;
        padding: 0;
        line-height: 1.8;
    }

    // .tip-driver{
    //   .amount-wrapper{
    //     position: relative;
    //     input{
    //       padding-left: 25px;
    //     }
    //    &:before{
    //      content:'$';
    //      content: '$';
    //      position: absolute;
    //      top: 50%;
    //      left:11px;
    //      font-size: 1rem;
    //      transform: translateY(-50%);
    //      font-family: inherit;
    //    }
    //   }
    // }
    @include desktop {
        margin-top: 0px;
        position: static;
        border-radius: 0;
        // padding-top: calc(var(--navbar-height) + 10px);
    }

    .line-through-text span,
    .line-behind-text span {
        background-color: white !important;
    }

    .delivery-border {
        // border: none;
        // border-top: 1px solid #d8dee3 !important;
        //border-bottom: none !important;
        border-radius: 10px;
        padding-top: 16px;

        // @include desktop {
        //     padding: 20px;
        // }
    }

    .delivery-time-border {
        border: none !important;
        // border-bottom: 15px solid #f5f5f5!important;
        border-radius: 0px;
        padding: 10px 15px;

        @include desktop {
            margin: 0px 15px;
            border: 0px solid #d8dee3 !important;
            border-top: none !important;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            // box-shadow: 0 2px 2px 0 #00000012;
            box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.1);
        }
    }

    .custoinfo-border {
        border-radius: 10px;
        padding: 25px 15px 15px;

        //border-top: none;
        @include desktop {
            padding: 20px;
        }
    }

    .id-upload {
        .custoinfo-border {
            position: relative;
        }
    }

    .custo-info-font {
        font-size: 12px;
    }

    .notes-padding {
        padding: 10px 15px;

        @include desktop {
            padding: 10px 15px;
        }
    }

    .custo-details {
        span {
            font-size: 16px;
        }

        p {
            font-weight: 400;
        }

        .icon-check-circle {
            color: #71c273;
        }
    }

    // .selfie-block,
    // .photo-id-block
    //  {
    //     width: 100%;
    //     padding: 15px;
    //     margin-top: 20px;
    //     cursor: pointer;
    //     @include desktop{
    //         width: 48%;
    //         padding: 20px;
    //         margin-top: 0px;
    //     }

    //     span{
    //         font-size: 12px;
    //     }
    //     .icon-u-card , .icon-u-selfie{
    //         font-size: 20px;
    //     }
    //     img{
    //         border-radius: 6px;
    //         object-fit: none !important;
    //         height: 40px !important;
    //         width:40px !important;
    //         padding: 0px !important;

    //         @include desktop{
    //             width: 115px;
    //             height: 137px;
    //         }
    //     }
    //     // .icon-reorder{
    //     //         font-size: 16px;
    //     //         border: 1px solid var(--primary);
    //     //         padding: 7px 10px;
    //     //         border-radius: 7px;
    //     //         margin-top: 4px;
    //     //         @include desktop{

    //     //             font-size: 15px;
    //     //             margin-top: 5px;
    //     //         }
    //     // }
    //     .img-container{
    //         display: flex;
    //         align-items: flex-start;
    //         padding:8px;
    //         background-color: #F8F8F8;
    //         margin-top: 15px;
    //         border-radius: 6px;
    //         i{
    //             font-size: 20px;
    //             color: #4CAF50;
    //             border:none;
    //             padding: 0px;
    //             margin-bottom: 0px;
    //         }
    //     }
    //     .btn-amt{
    //         border:1px solid var(--primary);
    //         margin-right: 0px;
    //         padding: 5px 18px;
    //         border-radius: 6px;
    //         font-size: 14px;
    //         i{
    //             color:var(--gd-black-color);
    //         }
    //     }
    //     .btn-plus{
    //         display: flex;
    //         height: 30px;
    //         width: 30px;
    //         align-items: center;
    //         justify-content: center;
    //         font-size: 12px;
    //         border: 1px solid var(--primary);
    //     }
    // }
    // .medical-id-block{
    //   cursor: pointer;
    //   width:100%;
    //   padding: 15px;
    //   @include desktop{
    //     padding: 20px;
    //   }
    //   span{
    //     font-size: 12px;

    // }
    // .icon-u-card{
    //     font-size: 20px;
    // }
    // .btn-plus{
    //     display: flex;
    //     height: 30px;
    //     width: 30px;
    //     align-items: center;
    //     justify-content: center;
    //     font-size: 12px;
    //     border: 1px solid var(--primary);
    // }
    // .img-container{
    //     display: flex;
    //     align-items: flex-start;
    //     padding:8px;
    //     background-color: #F8F8F8;
    //     margin-top: 15px;
    //     border-radius: 6px;
    //     i{
    //         font-size: 20px;
    //         color: #4CAF50;
    //         border:none;
    //         padding: 0px;
    //         margin-bottom: 0px;
    //     }
    //     img{
    //         width: 40px;
    //         height: 40px;
    //         padding: 5px;
    //         object-fit: cover;
    //         padding: 0px !important;
    //         border-radius: 6px;
    //     }
    // }
    // }
    .delivery-type-toggle {
        display: flex;
        align-items: stretch;
        vertical-align: sub;
        transition:
            opacity 300ms,
            transform 300ms;

        .icon-stopwatch,
        .icon-calendar {
            font-size: 18px;
        }

        .btn {
            width: 50%;
            min-width: 70px;
            min-height: 40px;
            padding: 5px;
            line-height: 1;
            font-weight: 500;

            @include desktop {
                font-weight: 700;
                min-height: 45px;
            }

            font-size: 14px;

            &:not(.not-selected) {
                display: none;
            }

            &:first-child {
                border-radius: 8px 0 0 8px;
            }

            &:last-child {
                border-radius: 0px 8px 8px 0px;
            }

            &:focus {
                box-shadow: none;
            }
        }

        .asap-text {
            position: relative;
            top: 1px;
        }

        // @include desktop{
        //   margin-right:15px;
        .btn {
            // border: 1px solid #EBDDFF;
            background-color: #efefef;

            // padding: 5px 0px;
            @include desktop {
                padding: 5px 10px;
            }

            &.d-selected {
                color: var(--white-text-on-primary);
                background-color: var(--primary);
                border: 1px solid var(--primary);
            }

            &:not(.not-selected) {
                display: inline-block;
            }
        }

        // }
    }

    .btn-delivery {
        border: 1px solid var(--primary);
        background-color: #ffff;
        border-radius: 6px;
        font-size: 14px;
        padding: 7px 15px;
        line-height: 17px;
        color: #4d4d4d;
        // &:hover{
        //     background-color: var(--primary);
        //     color:#ffff;
        // }
    }

    .medical-id-block {
        .custom-checkbox label:before {
            top: 5px;
        }

        .custom-checkbox label:after {
            top: 12px;
        }
    }

    .custom-address-form {
        .icon-pin {
            top: 13px;
        }

        button {
            top: 0px;
        }
    }

    .cs-address-parent {
        bottom: auto;
    }

    .asap-not-deliverable {
        background-color: #f7e5e5;
        padding: 15px;
        border-radius: 7px;
        margin-bottom: 10px;

        .c-button-parent {
            .btn {
                padding: 7px 10px;

                @include desktop {
                    padding: 7px 15px;
                }

                @include real-iphone5 {
                    font-size: 12px;
                    padding: 4px 6px;
                }
            }
        }

        .ac-header {
            @include desktop {
                font-size: 16px;
            }
        }

        .btn-dismiss {
            position: absolute;
            top: -5px;
            right: -5px;
        }

        p {
            font-size: 14px;
            margin-top: 5px;
            color: #999999;
        }
    }

    .delivery-remove-items {
        .item {
            background: none;

            img {
                height: 30px;
                width: 30px;
            }
        }
    }
    .id-upload .selfie-block .img-container, .id-upload .photo-id-block .img-container{
        background: #ffffff;
        padding: 0;
        margin-top: 12px;
        border-radius: 0;
        margin-bottom: 12px;

        @include mobile{
            margin-bottom: 0;
        }
    }
    .id-upload{
        &.cart-info-upload-section{
            padding: 0;
            margin-top: 18px;

            .custoinfo-title{
                display: none;
            }

            .checkout-img-upload{
                padding: 0;
                border: none !important;
                border-radius: 0;
                margin: 0;

                @include mobile{
                    margin-bottom: 16px;
                }

                .selfie-block, .photo-id-block{
                    padding: 0;
                    cursor: default;
                    // &::before{
                    //     content: none;
                    // }
                    .title-action-holder{
                        flex-direction: column;
                        align-items: self-start !important;

                        img{
                            margin-bottom: 6px;
                        }

                        .icon-u-selfie, .icon-u-card{
                            display: none;
                        }

                        .title{
                            font-size: 12px;
                            font-weight: bold;
                            margin-bottom: 6px !important;
                            color: #333333;
                        }
                        .my-file-input{
                            width: 100%;
                            .btn-plus, label{
                                width: 100%;
                                border: 1px dashed rgba(0, 0, 0, 0.56);
                                border-radius: 0;
                                height: 78px;
                                font-size: 14px;
                                position: relative;

                                .upload-text{
                                    display: inline-block !important;
                                    color: #172D67;
                                    font-size: 13px;
                                    font-weight: 500;
                                    margin-left: 8px;
                                }
                            }
                            label[class*="disable"]{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                    }
                }
            }
        }
    }
}

.portal-uppy {
    .uppy-Dashboard--modal {
        z-index: 9999;
        position: relative;
    }

    .uppy-DashboardTabs {
        padding-top: 25px;
        padding-bottom: 25px;
        border-bottom: 1px dashed #b9b9b9;
    }

    .uppy-Dashboard-close .UppyIcon {
        width: 22px;
        height: 22px;
    }

    .uppy-Dashboard-close {
        top: 25px;
        right: 25px;
    }

    .uppy-Dashboard-close:focus {
        outline: 0;
    }

    .uppy-Dashboard--modal .uppy-Dashboard-inner {
        position: fixed;
        //top: 70px;
        left: 0;
        border: none;
        // bottom: 69px;
        // height: auto;
    }

    .uppy-StatusBar-actionBtn.uppy-StatusBar-actionBtn--upload {
        background-color: $primary;
        background-color: var(--primary);
    }
}

.why-chose-us {
    @include desktop {
        margin-bottom: 1rem;
    }

    h2 {
        margin-bottom: 20px !important;
    }

    span {
        font-size: 18px;

        @include desktop {
            font-size: 30px;
        }
    }

    .content {
        padding: 15px;

        h5 {
            font-size: 18px;
        }
    }

    img {
        width: 100px !important;
        display: block;
        margin: 0 auto;
    }

    // i {
    //     font-size: 100px;
    //     display: flex;
    //     justify-content: center;
    // }
    .icon-reliable {
        // font-size: 34px;
        // left: 16px;
    }

    .icon-safety {
        // font-size: 43px;
    }

    .holder-wrapper {
        @include desktop {
            display: grid !important;
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: 100px;
            margin-top: 40px;
        }
    }

    .holder {
        position: relative;
        padding: 0px;
        border-radius: 10px;

        @include desktop {
            padding: 20px 10px;
        }

        img {
            height: 100px;

            @include desktop {
                transition: 0.5s ease-in-out;

                &:hover {
                    transform: scale(1.03);
                }
            }
        }

        p {
            margin-top: 0.5rem;
            text-align: center;
        }
    }

    .holder-wrapper-mobile {
        padding: 10px;

        .carousel .slide {
            background: none !important;
        }

        .control-dots {
            position: relative !important;
            margin: 0 !important;
        }
    }
}

.how-default-works {
    .holder-wrapper {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        column-gap: 10px;
    }

    .content {
        padding-top: 0;
    }

    img {
        height: 100px;
    }

    .h-count {
        text-align: center;
        font-weight: bold;
        width: 30px;
        height: 30px;
        background: #fce68c;
        border-radius: 50%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }
}

.new-email-component {
    .i-inner {
        display: flex;
        justify-content: space-between;
        gap: 10px;
    }

    .small-button {
        position: static !important;
        padding: 10px 15px !important;
        height: 31px;
    }

    .email-otp-button {
        border: none;
        width: 25%;
    }
}

.wallet-redeem-card {
    display: flex;
    grid-gap: 10px;

    // padding-top: 15px;
    .redeem-form {
        flex-grow: 2;
        margin-bottom: 0px;

        input {
            text-align: left !important;
            font-size: 14px;
            min-height: 38px;
            border-radius: 6px;
        }
    }

    button,
    .loader {
        flex-grow: 0;
        text-align: center;
        height: auto;
        background-color: transparent;
        color: var(--primary);
        width: unset;
        font-size: 14px;
        font-weight: 500;
        align-self: flex-start;
        padding: 7px 15px;
    }

    button {
        font-weight: 500;
    }
}

.pay-online-popup {
    max-width: 500px !important;
}

.remove-product-popup {
    i {
        font-size: 12px;
    }

    .custom-btn-danger {
        min-height: 38px;

        &:hover,
        &:focus,
        &:active {
            span {
                background-color: white !important;
            }
        }
    }
}

.custom-btn-danger {
    color: #f44336 !important;
    background-color: #fff;
    border-color: #f44336 !important;
    box-shadow: none !important;

    &:hover,
    &:focus,
    &:active {
        background-color: #f44336 !important;
        color: #fff !important;
    }

    .loader-3dots {
        top: -3px;

        span {
            background-color: #f44336 !important;
        }
        &::after {
            content: none;
        }
    }
}
