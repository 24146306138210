
.filter-drawer {
	.custom-radio{
		display: block;
		margin-bottom: 0.5rem;
		&:last-child{
			margin-bottom: 0;
			label{
				margin-bottom: 0;
			}
		}

	}
	.custom-checkbox label{
		margin-bottom: 0;
	}
	.custom-checkbox {
   
    & + .custom-checkbox {
        margin-top: 8px;
    }
	}
	&.open + .landing-page {
		// pointer-events: none;
	}
	.sidebarItem + .sidebarItem{
		margin-top:1rem;
	}

	.menu-list {
		// display: flex;
		// flex-direction: column;
		height: 100%;
		overflow: auto;
		padding-bottom: 50px;
		& > .sidebarItem:last-child , .accordion-item:last-child, .rc-collapse-item:last-child{
			margin-bottom: 80px;
		}

	}
	.content {
		position: fixed;
		top: 0;
		right:0;
		left: 20%;
		height: 100%;
		//overflow: auto;
		@include tab {
			left: 60%;
		}
		@include desktop {
			left: 77%;
		}
		bottom: 0;
		background-color: $bodyBackgroundColor;
		background-color: var(--body-background-color);
		z-index: 2000;
		transform: translateX(100%);
		.sidebarBanner {
			width: 100%;
			height: 64px;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			padding: 0 10px;
			@include desktop{
				padding: 0 15px;
			}
			/* justify-content: center; */
			background-color: $primary;
			background-color: var(--(primary));
			& > *{
				color:white;
			}
		}
		.sidebarContent {
			position: relative;
			padding-top: 64px;
			// overflow: auto;
			height: 100%;
			.icon {
				color: #333;
				font-size: 20px;
			}
		}
		.actions-btn {
			position: absolute;
			bottom: 0;
			right: 0;
			left: 0;
			background-color:white;
			padding:10px;
		}
	}
	.login-container {
		button {
			width: 130px;
		}
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: #def4eb;
		padding: 15px 0;
	}
	.backdrop {
		display: none;
		background-color: rgba(0, 0, 0, 0.39);
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1999;
		transition: all 0.5s ease;
	}
	&.open {
		.content {
			transition: transform 0.5s ease;
			transform: translateX(0%);
		}
		.backdrop {
			display: block;
		}
	}
	&.transition {
		.content {
			transition: transform 0.5s ease;
		}
	}
}

.fd-enter {
 .backdrop{
	display: none;
 }
 .content{
	transform: translateX(100%);
 }
}
.fd-enter-active{
  .backdrop{
		display: block;
	 }
	 .content{
		transform: translateX(0%);
	 }
}
.fd-exit{
	.backdrop{
		display: block;
	 }
	 .content{
		transform: translateX(0%);
	 }
}
.fd-exit-active {
	.backdrop{
		display: none;
	 }
	 .content{
		transform: translateX(100%);
	 }
}

.menu-list{
	.rc-collapse{
		background-color:transparent!important;
		border-radius: 0px!important;
		border: 0px solid #d9d9d9!important;
		padding-top: 10px;
		.rc-collapse-content{
			font-size:13px ;
		}

		 .rc-collapse-content-box {
			margin-top: 5px;
			margin-bottom: 10px;
	}
		.rc-collapse-item > .rc-collapse-header{
			font-weight: bold;
			outline:0;
			font-size: 14px;
			color: #555;
			padding:10px;
			@include desktop{
				padding:10px 20px;
			}
			position: relative;



			.arrow{
				font-family: "icomoon" !important;
				speak: none;
				font-style: normal;
				font-weight: normal;
				font-variant: normal;
				text-transform: none;
				line-height: 1;

				/* Better Font Rendering =========== */
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;

				font-size: 10px;
				border: 1px solid var(--primary);
				width: 20px;
				height: 20px;
				border-radius: 50%;
				line-height: 20px;
				text-align: center;
				color: var(--primary);
				margin: 0;
				position: absolute;
				top: 10px;
				right: 8px;
				&:before{
					content: "\e90d";
				}
			}

		}
		.rc-collapse-item{
			border-top:none;
		}
		.rc-collapse-item-active{
			.arrow{
				transform:rotate(180deg);
			}
		}
		.rc-collapse-item{
			&:last-child{
				border-bottom: 0px solid #d9d9d9;
				&.rc-collapse-item-active{
					border-bottom:0;
				}
			}

		}
	}
}


// loops through 100 times
// @for $i from 1 through 9 {
//   .order-#{$i} {
//     order: $i;
//   }
// }
