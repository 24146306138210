.search-similar-popup{
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  .modal-content{
    // min-height: 240px;
    // @include desktop(){
    //   min-height: 406px;
    // }
      .modal-header{
          width: 100%;
          margin: unset;
          font-size: 18px;
          font-weight: 700;
          padding: 5px 5px 20px 15px;
          @include desktop(){
              padding: 5px 5px 5px 20px;
          }
          .icon-close {
              font-size: 16px;
          } 
      }
      .modal-body{
          width: 100%;
          margin: unset;
          padding: 6px 15px 20px;
          @include desktop(){
            padding: 8px 20px 30px;
          }
          .v-list{
              margin-top: 0 !important;
              margin-bottom: 0 !important;
              display: flex;
              .h-list-parent{
                .h-list{
                  padding: 0;
                  .latest-product{
                    flex: 0 0 290px;
                    margin-right: 14px !important;
                    
                    @include desktop(){
                      flex: 0 0 220px;
                      margin-right: 24px !important;
                    }

                    @include notDesktop(){
                      min-height: 160px;
                    }

                    .product-space-between-container {
                      @include notDesktop(){
                          flex-direction: row;
                          align-items: center;
                          border-color: #eae6e6;
                      } 
                      .top-container .img-wrapper{
                        @include notDesktop(){
                          height: 90px !important;
                          width: 90px;
                        }
                      }
                      .bottom-container{
                        @include notDesktop(){
                          padding-top: 0 !important;
                        }
                      }
                    }
                  }
                }
              }
          }
      }
  }
}