.searchBox {
  // transition: height 0.3s linear;
  .searchBar {
    border-radius: 8px;
    flex-direction: column;
    @include desktop {
      flex-direction: row;
    }
    .gd-search-address {
      padding: 0 5px !important;
      @include desktop {
        width: 32%;
      }
      width: 100%;
      .add-field {
        .render-address {
          font-weight: bold;
          color: #333;
        }
      }
    }

    .ais-SearchBox-form {
      height: 100%;
      .ais-SearchBox-input {
        padding: 0.3rem 2.5rem 0.3rem 0.3rem;
        border-radius: 8px;
        @include desktop {
          height: 100%;
        }
        border: none;
        height: 40px;
        &:focus-visible {
          outline: none;
        }
      }
      .ais-SearchBox-submit {
        width: 32px;
        height: 32px;
        background: #e8e8e8;
        @include desktop {
          width: 40px;
          height: 40px;
        }
        border-radius: 6px;
        right: 5px !important;
        left: unset;
      }
      .ais-SearchBox-reset {
        right: 60px;
        left: unset;
      }
    }
  }
  .search-result-wrapper {
    border-top: 1px solid #aaa;
    box-shadow: 0px 8px 15px #555;
    border-radius: 0 0 8px 8px;
    padding: 10px 5px;
    .sidebarContent {
      width: 100%;
      .sidebarBanner {
        .title {
          font-size: 16px;
          font-weight: normal;
          color: #333 !important;
        }
        .ais-ClearRefinements-button {
          background-color: transparent;
          color: #0096db;
        }
      }
      .menu-list {
        .rc-collapse-item {
          .rc-collapse-header {
            padding: 7px 5px;
            .arrow {
              transform: rotate(90deg);
              border: 1px solid transparent;
              &::before {
                content: "\e93b";
                font-size: 16px;
                color: var(--primary);
              }
            }
          }
          &.rc-collapse-item-active {
            .arrow {
              transform: rotate(270deg);
            }
          }
          .rc-collapse-content {
            .custom-checkbox {
              margin-top: 0px;
            }
          }
        }
      }
    }
    .f-right-sec {
      padding: 0 10px;
      .filter-box-parent {
      }
      .ais-Hits-list {
        .ais-Hits-item {
          .ais-Highlight {
          }
        }
      }
    }
  }
  &.hide-images {
    transition: 0.3s;
    height: 120px;
  }
}

.search-category-wrapper {
  // background-color: white;
  margin-top: var(--hh-height);
  // padding-top: 20px;

  &.remove-bg {
    background-color: #e6f6f6;
    .searchBox {
      margin: 0 !important;
    }
  }
  @include desktop {
    position: sticky;
    top: var(--navbar-height);
    z-index: 100;
    margin-top: 0;
    // margin-top: var(--navbar-height);
  }
  .searchBar {
    @include desktop {
      background: var(--primary-lightest);
      border-radius: 39px !important;
      min-height: 58px;
    }
    // max-width: 680px;
    .v-line {
      display: none;
    }
    .gd-search-address {
      cursor: pointer;
      padding: 0 15px !important;
      margin-bottom: 6px;
      @include desktop {
        // position: absolute;
        background-color: transparent;
        padding: 0;
        width: 32%;
        padding: 0 5px 0 15px !important;
        margin-bottom: 0px;
      }
      // border-radius: 8px;
      // background-color: #0005;
      // padding: 0 5px;
      // left: 5px;
      // gap: 5px;
      display: flex;
      align-items: center;
      width: 100%;
      .delivery-text {
        display: block !important;
        width: auto;
        font-weight: 400;
        font-size: 12.9756px;
        line-height: 16px;
        color: #333333;
        margin-right: 5px;
        @include desktop {
          width: 100%;
        }
      }
      .render-address {
        font-weight: 600 !important;
        font-size: 12.9756px;
        line-height: 16px;
        max-width: 213px;
        color: #333;
        @include desktop {
          max-width: 150px;
        }
      }
      .icon-pin {
        font-weight: 500;
        color: #333;
        font-size: 16px;
        display: flex;
        margin-right: 5px;
        @include desktop {
        }
      }
    }
    .mobile-search-box {
      display: flex;
      min-height: 40px;
      position: relative;
      padding-left: 15px !important;
      width: 100%;
      padding-right: 15px;
      @include desktop {
        padding-right: 0;
      }
      p {
        background: #ffffff;
        border: 1px solid #cdcdcd;
        box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.1);
        border-radius: 27px !important;
        padding: 0 30px !important;
        font-size: 14px;
        font-weight: 400;
        color: #999;
      }
      .icon-search {
        position: absolute;
        left: 27px;
        border-radius: 4px;
        top: 50%;
        transform: translate(0%, -50%);
        font-size: 13px;
        color: #37475b;
        font-weight: bold;
      }
      @include desktop {
        // display: none;
      }
    }
  }
  .ais-SearchBox {
    .ais-SearchBox-form {
      height: 100%;
      &:focus-visible {
        outline: none;
      }
      .ais-SearchBox-input {
        outline: none;
        border: 1px solid #e8e8e8;
        box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.1);
        border-radius: 27px !important;
        padding: 0.3rem 38px 0.3rem 38px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        @include desktop {
          height: 100%;
        }
      }
      .ais-SearchBox-submit {
        background: unset;
        // left:0;
        left: 22px;
        right: unset !important;
        height: unset !important;
        width: unset;
        .ais-SearchBox-submitIcon {
          height: 13px;
          width: 13px;
        }
      }
      .ais-SearchBox-reset {
        right: 13px;
        left: unset;
      }
    }
  }
  .searchBox {
    display: flex;
    justify-content: center;
    // background-image: url(../../images/search-banner.png);
    // background-size: cover;
    width: 100%;
    height: auto;
    @include desktop {
      align-items: center;
      height: 100px;
      margin-top: 7px;
      margin-bottom: 12px;
    }
    .search-result-wrapper {
      position: absolute;
      background-color: white;
      z-index: -1;
      width: 100%;
      top: 34px;
      box-shadow: none;
      border: 1px solid #eeeeee;
      border-top: unset;
      padding-top: 38px;
      border-radius: 0 0 8px 8px !important;
      box-shadow: 0px 6px 15px 0px #c9c9c9;
      .ais-ClearRefinements-button {
        background-color: transparent;
        color: #0096db;
      }
    }
  }
}
