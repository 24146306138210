// @font-face {
// 	font-family: 'Montserrat';
// 	font-style: normal;
// 	font-weight: 400;
// 	font-display: swap;
// 	src: local('Montserrat Regular'),local('Montserrat-Regular'),url(/static/styles/fonts/Montserrat-Regular.ttf);
// }

// @font-face {
// 	font-family: 'Montserrat';
// 	font-style: normal;
// 	font-weight: 600;
// 	font-display: swap;
// 	src: local('Montserrat SemiBold'),local('Montserrat-SemiBold'),url(/static/styles/fonts/Montserrat-SemiBold.ttf);
// }
// @font-face {
// 	font-family: 'Montserrat';
// 	font-style: normal;
// 	font-weight: 700;
// 	font-display: swap;
// 	src: local('Montserrat Bold'),local('Montserrat-Bold'),url(/static/styles/fonts/Montserrat-Bold.ttf);
// }

// @font-face {
// 	font-family: 'Montserrat';
// 	font-style: normal;
// 	font-weight: 800;
// 	font-display: swap;
// 	src: local('Montserrat ExtraBold'),local('Montserrat-ExtraBold'),url(/static/styles/fonts/Montserrat-ExtraBold.ttf);
// }






// @font-face {
// 	font-family: 'Roboto';
// 	font-style: normal;
// 	font-weight: 400;
// 	font-display: swap;
// 	src: local('Roboto Regular'),local('Roboto-Regular'),url("/static/styles/fonts/Roboto-Regular.ttf");
// }

// @font-face {
// 	font-family: 'Roboto';
// 	font-style: normal;
// 	font-weight: 600;
// 	font-display: swap;
// 	src: local('Roboto Medium'),local('Roboto-Medium'),url("/static/styles/fonts/Roboto-Medium.ttf");
// }
// @font-face {
// 	font-family: 'Roboto';
// 	font-style: normal;
// 	font-weight: 800;
// 	font-display: swap;
// 	src: local('Roboto Bold'),local('Roboto-Bold'),url("/static/styles/fonts/Roboto-Bold.ttf");
// }

// @font-face {
// 	font-family: 'Roboto';
// 	font-style: normal;
// 	font-weight: 900;
// 	font-display: swap;
// 	src: local('Roboto Black'),local('Roboto-Black'),url("/static/styles/fonts/Roboto-Black.ttf");
// }
//


//  @font-face {
// 	 font-family: 'Intelo';
// 	 font-style: normal;
// 	 font-weight: bold;
// 	 font-display: swap;
// 	 src: url("/static/styles/fonts/Kastelov - Intelo ExtraBold.otf");
//  }


  @font-face {
  	font-family: 'PublicSans';
  	font-style: normal;
  	font-weight: 400;
  	font-display: swap;
  	src: local('PublicSans Regular'),local('PublicSans-Regular'),url("../styles/fonts/PublicSans-Regular.otf");
  }

  @font-face {
  	font-family: 'PublicSans';
  	font-style: normal;
  	font-weight: 600;
  	font-display: swap;
  	src: local('PublicSans SemiBold'),local('PublicSans-SemiBold'),url("../styles/fonts/PublicSans-SemiBold.otf");
  }
  @font-face {
  	font-family: 'PublicSans';
  	font-style: normal;
  	font-weight: 700;
  	font-display: swap;
  	src: local('PublicSans Bold'),local('PublicSans-Bold'),url("../styles/fonts/PublicSans-Bold.otf");
  }
  
  