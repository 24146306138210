.promo-offer {
    background-color: $modalBGColor;

    .modal-dialog {
        margin: 0 auto;
        max-width: 300px;
    }
    .modal-body {
        padding: 45px 30px 30px 30px;
    }
    .sub-title {
        font-size: 1.1em;
    }

    .img-container {
        margin: 0 auto;
        margin-bottom: 1rem;
        width: 60%;
        margin-bottom: 1.3rem;
				min-height:100px;
        img {
            height: 100%;
            width: 100%;
        }
        svg + svg {
            display: none;
        }
    }
    .logo-container {
        margin: 0 auto;
				min-height:100px;
        margin-bottom: 2rem;
        img {
            height: 100%;
            width: 100%;
        }
    }
    .title {
        margin: 1.2rem 0;
    }
    .ok-btn {
        width: 150px;
    }
    .name {
        font-size: 1.5em;
    }
}
