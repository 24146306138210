.advert-banner-header {
	transition: height 300ms;
	background-color: var(--primary) !important;
	* {
		color: var(--color, #fff) !important;
	}
	width: 100vw;
	overflow: hidden;
	position: relative;
	padding: 8px;
	.a-button {
		position: absolute;
		right: 5px;
		color: white;
		top: 50%;
		background-color: rgba(0, 0, 0, 0.2);
		font-size: 8px;
		font-weight: bold;
		border-radius: 20px;
		width: 20px;
		height: 20px;
		line-height: 20px;
		transform: translateY(-50%);
		z-index: 1;
	}
	// .marquee{
	// 	text-align:center;
	// 	white-space: nowrap;
	// 	&.animate{
	// 		.m-inner{
	// 			animation: marqueeAnim 20s linear infinite;
	// 		}
	// 	}
	// }
	.marquee {
		* {
			margin: 0;
		}
		a {
			text-decoration: underline;
		}
	}
	.m-inner,
	.m-inner-2 {
		// display: inline-block;
		// *{
		// 	margin:0;
		// }
		// a{
		// 	text-decoration:underline;

		// }
	}
	@include desktop {
		padding: 8px;
		.a-button {
			right: 22px;
			// top: 10px;
			width: 25px;
			height: 25px;
			line-height: 25px;
		}
	}
	.an-text {
		* {
			display: inline-block;
		}
	}
}

// @keyframes marqueeAnim {
//   from {
//     transform: translateX(100%);
//   }
//   to {
//     transform: translateX(-100%);
//   }
// }

.anim-announce-appear {
	height: 0;
}
.anim-announce-appear-active {
	height: 37px;
}
.anim-announce-exit {
	height: 37px;
}
.anim-announce-exit-active {
	height: 0px;
}

.anim-announce-enter {
	height: 0;
}
.anim-announce-enter-active {
	height: 37px;
}
