.orientation {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 2001;
    color: #333333;
    display: none;
    justify-content: center;
    align-items: center;

    &.show-message {
        display: flex;
    }

    .inner {
        width: 80%;
    }
    .icon-rotate-phone {
        font-size: 6em;
    }
}

// @media screen and (orientation: landscape) {
//   .orientation {
//     display: flex;
//   }
// }
