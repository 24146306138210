.default-category-menu {
	//   --primary:var(--grassdoor-secondary-color);
	margin-bottom: 0px !important;
	margin-top: 13px !important;
	max-width: 1600px;
	@include desktop {
		margin-bottom: 37px !important;
		margin-top: 30px !important;
	}
	.cat-menu-title {
		font-size: 23px;
	}

	.gc-inner {
		//  margin: 0 -15px;
		background-color: #fff;
		// border-bottom:1px solid $dividerColor;
		padding: 0 15px;
		// margin-bottom: 37px;
		@include desktop {
			// margin: 0 -15px;
			// border-radius: 10px;
			// padding-left:15px;
			// padding-right: 15px;
			// display: grid;
			// grid-template-columns: 200px calc(100% - 230px);
			// grid-gap: 30px;
			// border: 1px solid #ededed;
			// h3{
			// 	margin-bottom: 0;
			// }
		}
	}

	.category-title {
		h3 {
			font-weight: 700;
			color: var(--primary);
		}
	}

	.category-item {
		.h-list-parent {
			margin: 0;
			.h-list {
				padding: 0px;
				margin: 0;
				@include desktop {
					padding: 15px 0;
				}
			}
			.scroll-btn {
				display: inline-block;
				transform: translateY(0%) scale(1);
				background-color: #fff;
				display: none;
				color: #000;
				@include desktop {
					display: block;
					background-color: #fff;
				}
				box-shadow: none;
				width: auto;
				top: 0;
				bottom: 0;
				height: auto;
				border-radius: 0;
				&.right {
					// right: -46px;
					right: 0px;
				}
				&.left {
					left: 0px;
				}
			}
		}
		.d-item {
			flex: 0 0 100px;
			padding: 5px;
			border: 2px solid transparent;
			border-radius: 10px;
			transition: all 0.3s ease-in;
			// &:hover{
			// 	transform: scale(1.01);
			// 	box-shadow: 0px 0px 6px 4px rgb(0 0 0 / 5%);
			// }

			// height: 26px;
			&.c-selected {
				position: relative;
				border: 2px solid var(--primary);

				// &:after{
				// 	content:"";
				// 	position:absolute;
				// 	bottom: -2px;
				// 	left:4px;
				// 	right:0;
				// 	height:2px;
				// 	width: 90%;
				// 	background:#38BFC3;
				// 	@include desktop{
				// 		display: none;
				// 		bottom: -13px;
				// 		height: 5px;
				// 		border-radius: 10px;
				// 		background-image: linear-gradient(to right, #71c273, #36bfc5);
				// 	}
				// }
			}
			@include desktop {
				flex: 0 0 150px;
				padding: 0;
				border-radius: 14px;
				height: auto;
				&:hover {
					background-color: #f2f1f6;
				}
			}
			a {
				text-decoration: none;
				align-items: center;
				display: flex;
				flex-direction: column;
				.outer-circle {
					// background-image:linear-gradient(#71c273,#36bfc5) ;
					padding: 4px;
					// border-radius: 100%;
					// display:none;
					@include desktop {
						display: block;
						padding: 10px 12px 0px 12px;
					}
					img {
						width: auto;
						transition: all 0.3s ease-in;
						height: auto;
						&:hover {
							@include desktop {
								transform: scale(1.05);
							}
						}
					}
				}
			}
			.figcaption {
				display: block;
				font-weight: bold;
				color: var(--text-on-background-color);
				font-size: 14px;
				margin: auto 0px;
				// padding: 5px;
				@include desktop {
					margin: 4px 0px 8px;
					font-size: 16px;
				}
			}
			&.edible-item {
				img {
					position: relative;
					left: 3px;
					@include desktop {
						left: 4px;
					}
				}
			}
		}
	}

	&.full-width {
		background-color: #ecf8f8;
		.gc-inner {
			padding: 0 15px;
			@include desktop {
				width: 1140px;
				margin: auto;
				padding: 0px;
			}
		}
	}

	&.mobile-view {
	}
	&.sticky-cat-menu {
		position: sticky;
		top: var(--navbar-height);
		z-index: 2;
	}
	@include ipadPro {
		padding-right: 15px;
		padding-left: 15px;
	}

	@include notDesktop {
		&.remove-images {
			.outer-circle {
				display: none !important;
			}
		}
	}
}
.top-brands-slider {
	margin-bottom: 40px !important;
	margin-top: 20px !important;
	@include desktop {
		margin-bottom: 62px !important;
		margin-top: 48px !important;
	}
	h2 {
		font-size: 20px;
		font-weight: 700 !important;
		margin-bottom: 22px;
		@include desktop {
			font-size: 26px;
			margin-bottom: 42px;
		}
	}
}

// .grassdoor-category-menu.hide-images {
// 	margin-top: -25px;
// 	transition: all 0.3s;
// 	.outer-circle {
// 		height: 0px !important;
// 		img {
// 			// height: 0!important;
// 		}
// 	}
// }

// .default-category-section {
// 	margin-top: 18px;
// 	@include desktop {
// 		margin-top: 0px;
// 	}
// 	.gc-inner {
// 		.category-items {
// 			.h-list {
// 				padding: 0 0 10px 0 !important;
// 				@include desktop {
// 					padding: 0px !important;
// 				}
// 				@include ipadPro {
// 				}
// 			}
// 			.category-block {
// 				flex: 0 0 90px;
// 				position: relative;
// 				margin-right: 10px;
// 				@include desktop {
// 					flex: 0 0 120px;
// 					margin-right: 15px;
// 				}
// 				a {
// 					.category-icon-wrap {
// 						margin: 0 auto;
// 						height: 48px;
// 						@include desktop {
// 							height: 64px;
// 						}
// 						img {
// 							height: 100%;
// 						}
// 					}
// 					.category-name {
// 						padding-bottom: 6px;
// 						padding-top: 5px;
// 						@include desktop {
// 						}
// 						h4 {
// 							line-height: 18px;
// 							text-align: center;
// 							color: #333333;
// 							@include desktop {
// 								font-size: 16px;
// 								line-height: 24px;
// 							}
// 						}
// 					}
// 				}
// 				&.c-selected::after {
// 					content: "";
// 					position: absolute;
// 					bottom: 0;
// 					left: 0;
// 					right: 0;
// 					background: var(--primary);
// 					height: 4px;
// 					border-radius: 12px;
// 					@include desktop {
// 						height: 5.24px;
// 						border-radius: 18.3514px;
// 					}
// 				}
// 			}
// 			.category-block:last-child {
// 				margin-right: auto;
// 			}
// 			.category-block:first-child {
// 				margin-left: auto;
// 			}
// 		}
// 	}
// }
