.product-listing {
    .product-category {
        p {
            margin-bottom: 0.5rem;
        }
    }
}


.product-category-parent {
    // position: relative;
    // .inner {
    //     position: absolute;
    //     top: 60%;
    //     transform: translateY(-50%);
    //     color: #353535;
    //     background-color: white;
    //     height: 100%;
    //     z-index: 1;
    //     @include iphone5 {
    //         top: 57%;
    //     }
    // }
    
    // .product-category-title {
    //     font-size:0.9rem;
    //     margin-bottom: 0;
    //     padding: 0 6px;
    //     @include iphone5 {
    //         font-size: 13px;
    //     }
    // }
}
.all-products-slider {
        // height: auto;
        // @include notDesktop{
        //     height: $allProductsSliderHeight;
        // }
        // background-color: transparent;
    @include desktop {
        // background-color: $bodyBackgroundColor;
        background-color: transparent;
        // border-bottom: 1px solid #EFEFEF;
        margin-top: 18px !important;
    }
    @include notDesktop {
        // background-color: white;
    }

    // height: 180px;
    // padding: 15px 0 0 0;
    & > div {
        width: 100%;
    }



    .product {
        // -moz-user-select: none;
        // padding:5px 10px;
        // background-color: white;
        // padding: 0 7.5px;
        //cursor: pointer;
        // height: 100%;
        // .thc-cbd{
        //     @include iphone5{
        //         font-size: 1em;
        //     }
        // }
      
        // margin: 0 10px;
        img {
            object-fit: contain;
            margin: 0 auto;
            height: 100%;
        }
        .img-wrapper {
            // height: 90px;
            div,span {
                height:100%;
            }
        }
        .sold-out {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 75%;
        }
        .weight-container {
            // color: #4c4c4c;
            // margin-top: 3px;
            i {
                margin-right: 5px;
            }
			// 			&.landing{
			// 			font-size:12px;
            // @include iphone5{
            //     font-size:11px;
            // }
			// 			}
        }
        // .cbd-thc-container {
        //     font-size: 12px;
        //     @include iphone5{
        //         font-size:10px;
        //     }
        //     @include desktop{
        //         font-size:1rem;
        //     }
        // }
        // p {
            // margin-top: 10px;
            // font-size: 0.8em;
            // margin-bottom: 0;
            // min-height: 33px;
            // @include desktop {
            //     font-size: 14px;
            // }
        // }


        $product-width: 100%;
        @include desktop {
            // width: $product-width;
            // max-width: $product-width;
            // display: inline-block;
            // margin: 0;
            // vertical-align: top;
            // min-height: 235px;
			// 			height: 100%;
            //border-radius: 3px;
            // padding: 10px;
            //box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
            
            .img-wrapper{
                // padding-top: 25px;
                div,span {
                    height:100%;
                }
                // height: 220px;
                        //width: 120px;
            }
        }
    }
}

.asap-disable-home-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.back-info {
    // position: absolute;
    // background: $primary;
    // background: var(--primary);
    // right: 0;
    // top: 50%;
    // z-index: -1;
    // padding: 8px 15px 8px 40px;
    // text-align: center;
    // transform: translateY(-50%);
    // color: white;
    // border-top-left-radius: 5px;
    // border-bottom-left-radius: 5px;
    // font-size: 0.8em;
    // .icon-back {
    //     position: absolute;
    //     left: 11px;
    //     font-weight: bold;
    //     font-size: 12px;
    //     top: 50%;
    //     box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.19);
    //     transform: translateY(-50%);
    //     width: 22px;
    //     height: 22px;
    //     border-radius: 50%;
    //     /* background: white; */
    //     background-color: #ffffff;
    //     display: block;
    //     color: $primary;
    //     color: var(--primary);
    //     line-height: 22px;
    // }
}

.nudge-products {
    animation: nudge 3000ms ease-in 2000ms;
}

@keyframes nudge {
    0% {
        transform: translate3d(0, 0, 0);
    }
    20% {
        transform: translate3d(-50%, 0, 0);
    }
    // 50% {
    //   transform: translate3d(-50%, 0, 0);
    // },
    85% {
        transform: translate3d(-50%, 0, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

.imageBody {
    .infoImage {
        width: 200px;
        height: auto;
    }
}

@include iphone5 {
    .all-products-slider {
        // height: $allProductsSliderHeight_s;
        .product {
            .img-wrapper {
                // height: 70px;
            }
          
        }
    }
}
