.paymentSettings {
    padding: 15px;
    padding-top: calc(var(--navbar-height) + 15px);
    height: 100%;
    overflow: auto;
    @include desktop {
        max-width: $desktopContentWidth;
        // padding-top: $navbarHeight + 15px;
                margin:auto;
                height: auto;
                
    }
		@include min-height-desktop;
    .wallet-card {
        padding: 15px;
        margin-bottom: 15px;
        background-color:white;
    }

    .card-list {
        position: relative;
        .list-item {
            position: relative;

            .right-side-btn {
                position: absolute;
                top: -8px;
                right: 0px;
                background-color: transparent;
            }
        }
    }
}
