.bcc-popup{
	background-color: $modalBGColor;
	.table th, .table td {
		padding: 10px 8px;
	}
	.table tr th:first-child, .table tr td:first-child {
		padding-left:0 ;
	}
	.table tr th:last-child, .table tr td:last-child {
		padding-right:0 ;
	}
	.bcc-progress-parent{
		padding:0 0px;
		margin-bottom: 1rem;
		.bar-parent{
			position: relative;
			height:20px;
			background-color: #c9c9c9;
			border-radius: 5px;
			overflow: hidden;
			// border: 2px solid #c9c9c9;
		}
		.bar{
			position: absolute;
			top: 0px;
			transition: width 0.75s;
			left: 0px;
			bottom:0;
		}
		.value-num{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: 12px;
		}
	
	}
	.badge-status{
		color:#fff;
		background-color: var(--primary);
	}
	.weedy-card{
	 
		box-shadow:0px 0px 7px 0px #00000029;
		border-radius: 7px;
		.weedy-card-left{
			width:70%;
			h6{
				padding-left: 10px;
				color:#505050;
				font-size: 13px;
				@include desktop{
					font-size: 15px;
				}
			}
			p{
				padding-left: 10px;
				font-size: 11px;
				font-weight: 300;
				@include desktop{
					font-size: 13px;
				}
			}
			button{
				margin-left: 8px;
				color:#fff;
				font-weight: bold;
				letter-spacing: 0.5px;
				font-size: 12px;
			}
			.weedy-how-it-works{
				font-size: 11px;
			}
			.pending-txt{
				font-size: 11px;
			}
		
		}
		img{
			height: 100px;
			max-width:100%;
			@include desktop{
				height: auto;
			}
		}
		
	}
	.bcc-offer-section {
		// padding: 10px;
    // border: 1px solid $dividerColor;
    // border-radius: 5px;
		// background: #F8F8F8;
		.xy-product-offers-wrapper{
			margin-top: 20px !important;
		}
	}
}
.bcc-loader{
	margin: 0 1rem;
	display: inline-block;
	width: 8px;
	height: 8px;

	.inline-loader{
		top: -7px;
		right: -11px;
	}
}
