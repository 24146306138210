.dtc-header{
	display:flex;
	flex-direction:column;
	align-items: stretch;
	padding: 0;
	transition:none;
	--navbar-height: calc(64px + var(--delivery-info-height));
	.n-container{
		position: relative;
		 flex:1;
	}
	.main-logo {
    padding-left: 50px;
	}
}
