.myOrders {
    padding: 15px;
    //height: 100%;
    //overflow: auto;
    padding-top: calc(var(--navbar-height) + 15px);

		@include min-height-desktop;
    a {
        display: block;
        text-decoration: none;
    }
    @include desktop {
        max-width: $desktopContentWidth;
        padding-bottom: 150px;
        height: auto;
    }
}

.order-item {
    padding: 15px;
    margin-bottom: 15px;
    background-color:white;
    .card-shadow {
        box-shadow: 0px 8px 20px 5px rgba(0, 0, 0, 0.06);
        border: 1px solid #f7f7f7;
    }

    .icon-truck {
        font-size: 1.5em;
        color: #bebebe;
    }

    .orderStatus {
        padding-left: 10px;
        font-size: 0.8em;
    }

    .dispatched {
        font-weight: bold;
        color: orange;
    }

    .delivered {
        font-weight: bold;
        color: green;
    }

    .failed {
        font-weight: bold;
        color: orangered;
    }

    .refunded {
        font-weight: bold;
        color: rgb(45, 186, 230);
    }

    .p_10 {
        padding-bottom: 10px;
    }

    .order-detail {
        color: black;
    }
}

.friends-family-order-history{
    padding-top: calc(var(--navbar-height) + 15px);
    min-height: 90vh;
    @include desktop(){
        min-height: auto;
    }
    
    .friends-gradient-card{
        padding: 20px;
        border-radius: 5px;
        background: $friends-background;
        color: $primary;
        text-align: center;

        @include desktop(){
            text-align: left;
            padding: 20px 50px;
        }

        .user{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
            border-bottom: 1px solid;
            padding-bottom: 30px;

            @include desktop(){
                margin-bottom: 0;
                border-bottom: none;
                padding-bottom: 0;
                justify-content: flex-start;
            }

            span{
                font-size: 40px;
                margin-right: 15px;
            }

            h4{
                margin-bottom: 0;
                font-weight: bold;
            }
        }

        .credit-balance{
            @include desktop(){
                width: fit-content;
                display: flex;
                flex-direction: column;
                text-align: right;
                align-items: center;
                margin: 0 0 0 auto;
            }
        }

        h4{
            font-weight: bold;
            letter-spacing: 1px;
        }
    }

    .myOrders {
        .empty-holder{
            text-align: center;
            color: #b4b4b4;
        }

        .section-title{
            font-weight: bold;
            font-size: 18px;
            color: $friends-text-color;
            margin-bottom: 15px;
            margin-top: 35px;

            &.mt-title{
                margin-top: -20px;
            }
        }

        .rc-collapse{
            background: transparent;
            border: none;
            position: relative;

            @media (max-width: 320px) {
                padding-bottom: 65px;
                border-bottom: 1px solid #eee;
                margin-bottom: 15px;
            }

            .order-id-link{
                position: absolute;
                left: 170px;
                top: 0px;
                font-size: 13px;
                z-index: 1;

                @include notDesktop(){
                    margin-top: 0 !important;
                }

                @media (max-width: 320px){
                    top: 50px;
                    left: 0;
                }

                &:hover{
                    .heading{
                        .header{
                            text-decoration: underline;
                        }
                    }

                    .content{
                        color: var(--primary-dark);
                        text-decoration: underline;
                    }
                }

                .heading{
                    text-transform: uppercase;
                    font-weight: 500;
                    color: var(--primary-dark);

                    .header{
                        @include notDesktop(){
                            padding: 0;
                        }
                    }
                }

                .content{
                    font-weight: 200;
                    color: #000;
                    margin-top: -2px;
                    text-decoration: underline;
                    
                }
            }

            .rc-collapse-item{
                border-top: 0;

                .rc-collapse-header{
                    text-transform: uppercase;
                    color: #899090;
                    font-weight: 500;
                    font-size: 13px;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 0;
                    outline: none;

                    i{
                        position: absolute;
                        right: 10px;
                        font-size: 25px;
                    }
                }

                .rc-collapse-extra{
                    font-weight: 200;
                    color: #000;
                    margin: 0;
                    margin-top: -5px;
                }

                .rc-collapse-content{
                    padding: 0;
                    
                    .collapse-content{
                        position: relative;

                        .heading{
                            font-size: 12px;
                            font-weight: 500;
                        }

                        .content{
                            font-weight: 200;
                            color: #000;
                            padding-right: 10px;
                        }

                        .order-details{
                            display: flex;
                            justify-content: flex-end;
                            flex-direction: column;
                            margin-top: 15px;

                            @include desktop(){
                                flex-direction: row;
                                margin-top: 0;
                            }

                            .order-images{
                                margin-right: 0px;
                                margin-bottom: 15px;
                                margin-top: -12px;

                                @include desktop(){
                                    margin-right: 10px;
                                    margin-bottom: 0;
                                    margin-top: -6px;
                                }

                                .image{
                                    height: 50px;
                                    margin-top: 8px;
                                    margin-right: 8px;
                                    display: inline-block;

                                    img{
                                        height: 100%;
                                    }
                                }
                            }
                        }

                        .delivered{
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            margin-top: -20px;
                            margin-bottom: 15px;

                            span{
                                color: #bfbfbf;
                                font-size: 20px;
                                margin-right: 10px;
                            }

                            div{
                                color: #26770d;
                                text-transform: uppercase;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }

        .date-credit-section{
            .date-credit{
                color: $friends-text-color;
                display: flex;
                justify-content: space-between;
                padding-bottom: 15px;
                border-bottom: 1px solid #C4C4C4;
                padding-top: 15px;
            }

            .btn-holder{
                margin-bottom: 10px;
                padding-top: 25px;
                text-align: center;

                @include desktop(){
                    text-align: right;
                    border-top: 1px solid #fff;
                    margin-top: -1px;
                    padding-top: 15px;
                }

                button{
                    background: $friends-background;
                    height: 35px;
                    line-height: 34px;
                    padding: 0 20px;
                }
            }
        }
    }
}

.t-text {
    text-transform: capitalize;
}