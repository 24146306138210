/*!
 * Bootstrap (gautam_naik)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

 @import "~bootstrap/scss/functions";
 @import "~bootstrap/scss/variables";
 @import "~bootstrap/scss/mixins";

// @import "~bootstrap/scss/vendor/rfs";

// // Deprecate
// @import "~bootstrap/scss/mixins/deprecate";

// // Utilities
// @import "~bootstrap/scss/mixins/breakpoints";
// @import "~bootstrap/scss/mixins/hover";
// @import "~bootstrap/scss/mixins/image";
// @import "~bootstrap/scss/mixins/badge";
// @import "~bootstrap/scss/mixins/resize";
// // @import "~bootstrap/scss/mixins/screen-reader";
// @import "~bootstrap/scss/mixins/size";
// @import "~bootstrap/scss/mixins/reset-text";
// @import "~bootstrap/scss/mixins/text-emphasis";
// @import "~bootstrap/scss/mixins/text-hide";
// @import "~bootstrap/scss/mixins/text-truncate";
// @import "~bootstrap/scss/mixins/visibility";

// // Components
// @import "~bootstrap/scss/mixins/alert";
// @import "~bootstrap/scss/mixins/buttons";
// @import "~bootstrap/scss/mixins/caret";
// @import "~bootstrap/scss/mixins/pagination";
// @import "~bootstrap/scss/mixins/lists";
// @import "~bootstrap/scss/mixins/list-group";
// @import "~bootstrap/scss/mixins/nav-divider";
// @import "~bootstrap/scss/mixins/forms";
// @import "~bootstrap/scss/mixins/table-row";

// // Skins
// @import "~bootstrap/scss/mixins/background-variant";
// @import "~bootstrap/scss/mixins/border-radius";
// @import "~bootstrap/scss/mixins/box-shadow";
// @import "~bootstrap/scss/mixins/gradients";
// @import "~bootstrap/scss/mixins/transition";

// // Layout
// @import "~bootstrap/scss/mixins/clearfix";
// @import "~bootstrap/scss/mixins/grid-framework";
// @import "~bootstrap/scss/mixins/grid";
// @import "~bootstrap/scss/mixins/float";




 @import "~bootstrap/scss/root";
 @import "~bootstrap/scss/reboot";
 @import "~bootstrap/scss/type";
 @import "~bootstrap/scss/images";
//  @import "~bootstrap/scss/code";
 @import "~bootstrap/scss/grid";
 @import "~bootstrap/scss/tables";
 @import "~bootstrap/scss/forms";
 @import "~bootstrap/scss/buttons";
//  @import "~bootstrap/scss/transitions";
//  @import "~bootstrap/scss/dropdown";
//  @import "~bootstrap/scss/button-group";
//  @import "~bootstrap/scss/input-group";
//  @import "~bootstrap/scss/custom-forms";
//  @import "~bootstrap/scss/nav";
 @import "~bootstrap/scss/navbar";
//  @import "~bootstrap/scss/card";
//  @import "~bootstrap/scss/breadcrumb";
//  @import "~bootstrap/scss/pagination";
 @import "~bootstrap/scss/badge";
//  @import "~bootstrap/scss/jumbotron";
 @import "~bootstrap/scss/alert";
 @import "~bootstrap/scss/progress";
//  @import "~bootstrap/scss/media";
//  @import "~bootstrap/scss/list-group";
 @import "~bootstrap/scss/close";
//  @import "~bootstrap/scss/toasts";
 @import "~bootstrap/scss/modal";
 @import "~bootstrap/scss/tooltip";
//  @import "~bootstrap/scss/popover";
//  @import "~bootstrap/scss/carousel";
//  @import "~bootstrap/scss/spinners";
//  @import "~bootstrap/scss/utilities";
//  @import "~bootstrap/scss/print";
 
@import "~bootstrap/scss/utilities/align";
// @import "~bootstrap/scss/utilities/background";
// @import "~bootstrap/scss/utilities/borders";
// @import "~bootstrap/scss/utilities/clearfix";
@import "~bootstrap/scss/utilities/display";
// @import "~bootstrap/scss/utilities/embed";
@import "~bootstrap/scss/utilities/flex";
// @import "~bootstrap/scss/utilities/float";
@import "~bootstrap/scss/utilities/interactions";
@import "~bootstrap/scss/utilities/overflow";
@import "~bootstrap/scss/utilities/position";
// @import "~bootstrap/scss/utilities/screenreaders";
// @import "~bootstrap/scss/utilities/shadows";
// @import "~bootstrap/scss/utilities/sizing";
@import "~bootstrap/scss/utilities/spacing";
// @import "~bootstrap/scss/utilities/stretched-link";
@import "~bootstrap/scss/utilities/text";
// @import "~bootstrap/scss/utilities/visibility";
