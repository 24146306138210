.partner {
    padding-top: calc(var(--navbar-height) + 5px);
    //height: 100%;
    //overflow: auto;
    padding-bottom: 20px;
    @include desktop {
        padding-bottom: 150px;
    }
    .inner {

    }
    .hero-img-container {
        height: 300px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right;
        .content-box {
            position: absolute;
            left: 0;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            width: 80%;
            @include desktop {
                width: 50%;
            }
            background-color: rgba(0, 0, 0, 0.73);
            padding: 15px;
            box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.49);

            .title {
                color: #f7f7f7;
            }

            .sub-title {
                color: #e4e4e4;
            }
        }

        .hero-img-container-inner {
            position: relative;
            height: 100%;
        }

        &.become-driver {
            background-position: left;
            background-image: url("/static/images/driverbg.png");
            .content-box {
                background-color: rgba(0, 0, 0, 0.8);
            }
        }
        &.learn-more {
            background-image: url("/static/images/partnerbg.png");
        }
        &.submit-op {
            background-image: url("/static/images/submitopbg.png");
            .content-box {
                background-color: rgba(0, 0, 0, 0.8);
            }
        }
    }
    .padding-btn {
        padding-left: 30px;
        padding-right: 30px;
    }
    .contact-box {
        .btn {
            border-radius: 0 0 3px 3px;
        }
        .inner {
            padding: 15px;
            margin-bottom: 5px;
        }
        .main-text {
            font-size: 19px;
            display: inline-block;
            margin-top: 5px;
        }
    }
    .app-title {
        font-size: 18px;
        margin-bottom: 0.5rem;
        display: block;
    }
    .check-list {
        p {
            position: relative;
            padding-left: 15px;
            margin-bottom: 6px;
            line-height: 1.6;
            &:before {
                content: "\2022";
                font-family: "icomoon" !important;
                position: absolute;
                left: 0;
                top: -9px;
                font-weight: bold;
                font-size: 20px;
                color: $primary;
                color: var(--primary);
            }
        }
    }
    .block-text {
        //border: 1px solid #cecece;
        padding: 0 15px;
        /* background-color: #13c7cda3; */
        color: #212529;
        border-radius: 4px;
        /* margin-bottom: 1rem; */
        /* box-shadow: 0px 5px 20px 0px #00000047; */
        border-left: 7px solid $primary;
        border-left: 7px solid var(--primary);
        margin: 20px 0;
    }
    .links {
        a {
            display: block;

            border: 1px solid #dcdcdc;
            padding: 10px;
            margin-bottom: 15px;
            text-decoration: none;
            white-space: normal;
            color: #333;
            border-radius: 4px;
        }
        .inner {
            position: relative;
            padding-left: 15px;
            &:before {
                content: " \2022";
                position: absolute;
                left: -1px;
                top: -9px;
                font-size: 25px;

                color: $primary;
                color: var(--primary);
            }
        }
    }
    .holder-icon {
			& + .holder-icon {
				margin-top: 2rem;
				@include desktop{
					margin-top: 0;
				}
        }
        i {
            font-size: 43px;
            color: $primary;
            color: var(--primary);
        }
    }

    p,
    h6 {
        line-height: 25px;
    }
    h5 {
        line-height: 27px;
    }
    .img-container {
        img {
            width: 100%;
            height: auto;
            border-radius: 7px;
        }
    }
    p {
        color: #7c838a;
    }
    .little-margin-top {
        margin-top: 5px;
    }
    .res-btn-block {
        display: block;
        width: 100%;
        @include desktop {
            width: auto;
            display: inline-block;
        }
    }
}
