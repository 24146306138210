.driver-rating {
  background-color: $modalBGColor;
  //overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: auto !important;
  overflow-y: auto !important;

  .modal-dialog {
    pointer-events: all;
    // height: auto;
    // max-height: 90vh;
    // overflow: auto;
  }

  .modal-sub-title {
    margin-bottom: 20px;
  }

  .img-container {
    height: 60px;
    width: 60px;
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  .name {
    font-size: 1.5em;
  }

  img {
    height: 60px;
    width: 60px;
    object-fit: cover;
    border-radius: 50%;
  }

  textarea {
    resize: none;
  }

  .submit {
    width: 155px;
  }

  .tipAmount {
    width: 50px;
    height: 50px;
    padding: 0;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #00aa69;
    background: white;
    color: $primary;
    color: var(--primary);
    margin: 0px 8px;
  }

  .custom-amount {
    width: 150px;
    text-align: center;
    display: inline-block;
  }
}

.driver-rating-full {
  // padding: 15px;
  padding-top: $navbarHeight + 5px;
  height: 100%;
  overflow: auto;
  position: relative;

  .top-circle {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 233px;

    @include iphone5 {
      height: 213px;
    }

    border-radius: 0px 0px 400px 400px / 0px 0px 50px 50px;
    // z-index: -1;
  }

  $driverImageContainerSize: 70px;

  .img-container {
    height: $driverImageContainerSize;
    width: $driverImageContainerSize;

    @include iphone5 {
      height: $driverImageContainerSize - 10px;
      width: $driverImageContainerSize - 10px;
    }

    margin: 0 auto;
    margin-bottom: 1rem;
  }

  .name {
    font-size: 1.5em;
  }

  img {
    height: $driverImageContainerSize;
    width: $driverImageContainerSize;

    @include iphone5 {
      height: $driverImageContainerSize - 10px;
      width: $driverImageContainerSize - 10px;
    }

    object-fit: cover;
    border-radius: 50%;
  }

  .star-holder {
    background: white;
    border-radius: 8px;
    padding: 6px 16px;
    display: inline-flex;
    align-items: center;
  }

  .emoji {
    padding-left: 13px;
    font-size: 1.6em;
  }

  .star-rating {
    display: inline-block;
    border-right: 1px solid #ececec;
    padding-right: 10px;

    & > span + span {
      margin-left: 13px;
    }
  }

  .tipAmount {
    width: 55px;
    height: 55px;
    padding: 0;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #f9f9f9;
    background: #f9f9f9;
    color: $primary;
    color: var(--primary);
    margin: 0px 10px;
    box-shadow: 0 3px 7px 2px rgba(0, 0, 0, 0.16);

    &.is-active {
      @extend %brand-gradient;
      color: white;
    }
  }

  .custom-amount {
    width: 150px;
    text-align: center;
    display: inline-block;
  }

  .submit {
    padding: 6px 20px;
  }

  .ico-btn {
    i {
      margin-right: 5px;
    }
  }

  .rate-driver-btn-holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sign-container {
    // width: 80%;
    // height: 400px;
    margin: 0 auto;
    border: 1px solid $dividerColor;
    border-radius: 0.25rem;
    background-color: #fff;

    .sig-pad {
      width: 100%;
      // height: 100%;
    }

    .clear-btn {
      position: absolute;
      top: 0px;
      right: 0px;
      font-weight: bold;

      &:focus {
        box-shadow: none;
      }
    }
  }
}
.rate-driver-full-parent {
  .title {
    top: 5px;
  }

  .modal-dialog {
    margin: 0;
    height: 100%;
  }

  .driver-rating-full {
    @include desktop {
      border-radius: 0.3rem;
    }
  }

  .modal-content {
    height: 100%;
    border: 0;
  }
  @include notDesktop {
    .modal-dialog {
      max-width: 100%;
    }
  }

  @include desktop {
    .modal-dialog {
      margin: auto;
      height: auto;
    }
  }
}
