.search-tabs {
  // display: none;
  transition: transform 0.4s linear;
  // position: fixed;
  left: 0;
  top: var(--delivery-info-height);
  z-index: 1031;
  background-color: white;
  right: 0;
  position: fixed;
  .wrapper {
    box-shadow: 0px 2px 27px rgba(0, 0, 0, 0.1);
    .search-tabs-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 15px 7px 15px;
      // border-bottom: 1px solid #eeeeee;
      .left-btn {
        padding: 0;
        color: #37475b;
        font-size: 1.4em;
        display: flex;
        align-items: center;
        @include iphone5 {
          font-size: 1.4em;
        }
        span {
          position: relative;
          color: #37475b;
          font-size: 20px;
          // top: 2px;
        }
      }
      .search-action-btns {
        display: flex;
        .cart-btn-info {
          text-decoration: none;
          // transition: all 1s ease;
          .icon {
            font-size: 24px;
            font-weight: normal !important;
            color: #37475b !important;
          }
          .icon-down {
            font-size: 10px;
          }
          .item-add-count {
            position: absolute;
            // top: -13px;
            // right: -11px;
            top: -12px;
            right: -9px;
            background: #f9d95b;
            border-radius: 20px;
            line-height: 15px;
            color: #000;
            min-height: 17px;
            min-width: 17px;
            width: fit-content !important;
            z-index: 1;
            span {
              padding: 0 4px;
              font-family: "PublicSans";
            }
          }
          .small-text {
            display: none;
            transition: all 50s ease;
            p {
              line-height: 15px;
              text-align: left;
              color: #063a3f;
            }
            p:first-child {
              font-weight: 600;
              color: #37475b;
            }
          }
        }
        .user-info-btn {
          text-decoration: none !important;
          .icon {
            font-size: 24px;
            font-weight: normal !important;
            color: #37475b !important;
          }
        }
        .heart-btn {
          padding: 0;
          display: inline-flex;
          align-items: center;
          i {
            font-weight: bold;
            font-size: 22px;
            color: #37475b;
          }
        }
      }
      .search-section {
        display: flex;
        height: 40px;
        position: relative;
        flex: 1;
        margin: 0 18px 0 18px;
        // padding: 0 15px !important;
        @include desktop {
          display: none;
        }
        p {
          background: #ffffff;
          border: 1px solid #cdcdcd;
          box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.1);
          border-radius: 27px;
          padding: 0 30px !important;
          color: #999999;
        }

        .icon-search {
          position: absolute;
          left: 11px;
          border-radius: 4px;
          top: 50%;
          transform: translate(0%, -50%);
          font-size: 13px;
          color: #37475b;
          font-weight: bold;
        }
      }
    }
    .search-tabs-category {
      margin-top: 6px;
      padding: 0 15px;
      .h-list {
        padding: 0 0 10px 0 !important;
        .category-block {
          flex: 0 0 100px;
          min-height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          // position: relative;
          // &.c-selected {
          //   background-color: red;
          // }
          .category-name h4 {
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #616161;
            white-space: nowrap;
          }
          &.c-selected {
            border: 1px solid var(--primary);
            border-radius: 14px;
          }
          // &.c-selected::after {
          //   content: "";
          //   position: absolute;
          //   bottom: 0;
          //   left: 0;
          //   right: 0;
          //   background: #05a0a8;
          //   height: 4px;
          //   border-radius: 12px;
          //   // @include desktop {
          //   //   height: 5.24px;
          //   //   border-radius: 18.3514px;
          //   // }
          // }
        }
      }
    }
  }
  .selected-filters .h-list-parent .h-list {
    padding: 0px 10px 0px 10px;
    margin: 0;
    .afl-container {
      padding-bottom: 8px;
    }
  }
}
.search-tabs-control-appear {
  transform: translateY(-200%);
}
.search-tabs-control-appear-active {
  transform: translateY(0%);
}
.search-tabs-control-enter {
  transform: translateY(-200%);
}
.search-tabs-control-enter-active {
  transform: translateY(0%);
}
.search-tabs-control-exit {
  transform: translateY(0%);
}
.search-tabs-control-exit-active {
  transform: translateY(-200%);
}
