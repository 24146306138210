.cart-widget-parent {
	//TODO :remove this variable
	// --primary-rgb: 19,199,205;
	position: relative;
	z-index: 1000;
	// z-index: 9;
	@include xDesktop {
		max-width: 1600px;
	}
	.btn {
		height: auto;
		line-height: 1;
	}
	.product-brand {
		font-size: 12px;
		color: #616161 !important;
		margin-top: 2px;
	}
	.icon-close,
	.text-tax {
		color: #333 !important;
		line-height: 1;
	}
	.text-tax * {
		color: #333 !important;
	}
	.c-backdrop {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.39);
	}

	.cart-box {
		position: fixed;
		bottom: 0;
		right: 0;
		top: 0;
		left: 0;
		width: 100%;
		transition:
			transform 0.6s ease,
			opacity 0.3s ease;
		background: white;
		// height: 100vh;
		border: 1px solid $dividerColor;

		animation-name: cart-right;
		animation-duration: 0.5s;

		@include desktop {
			width: 440px;
			position: fixed;
			right: 0px;
			left: auto;
			// border-radius: 10px;
			text-align: left;
			z-index: 9999;
			top: 0px;
			background: white;
			bottom: 0;
			transform: translateX(0%);
			transition:
			transform 1s ease,
			opacity 0.3s ease;
		}

		box-shadow:
			0px 3.9px 16.8px -51px rgba(0, 0, 0, 0.133),
			0px 30.4px 36.6px -51px rgba(0, 0, 0, 0.197),
			0px 110px 60px -51px rgba(0, 0, 0, 0.33);

		// @media screen and (orientation: landscape) {
		// 	transform: none !important;
		// }

		// @include mobile() {
		// 	transform: none !important;
		// }

		// @include combineIpad() {
		// 	transform: none !important;
		// }

		.recommend-prod-section {
			.recommend-prod-title {
				h6 {
					font-size: 16px;
					font-weight: 600;
				}
			}

			.reco-products {
				&.tabs-btn {
					vertical-align: sub;
					transition:
						opacity 0.3s,
						transform 0.3s;
					background: #ebebeb;
					border-radius: 39px;
					padding-left: 0 !important;
					display: inline-block;
					margin-top: 12px;
					margin-bottom: -10px;

					button {
						min-height: 42px;
						min-width: 88px;
						font-size: 14px;
						height: unset !important;
						padding: 5px 16px;
						width: auto;
						font-weight: 700;
						line-height: 15px;
						color: #37475b !important;
						border-radius: 39px !important;
						border: unset !important;
						text-align: center;
						background-color: transparent;
						&:disabled {
							opacity: 0.65;
							color: #999999 !important;
							cursor: no-drop;
						}
					}

					.active-tab {
						color: var(--white-text-on-primary) !important;
						background-color: var(--primary);
						border-radius: 39px !important;
						transition: all 0.3s ease;
					}
				}
			}
		}
	}

	@keyframes cart-right {
		0% {
			right: -100px;
			left: auto;
		}

		100% {
			right: 0px;
			left: auto;
		}
	}

	.c-header {
		padding: 10px 15px;
		border-bottom: 1px solid #ccc;
		height: 50px;
		.c-icon {
			font-size: 22px;
			font-weight: unset;
			margin: 0px;
			&.added {
				font-size: 30px;
			}
		}
		.c-text {
			color: #333;
			font-size: 16px;
		}
	}

	.c-list {
		* {
			color: #333;
			.delete-btn,
			.count-btn {
				color: var(--primary);
				font-size: 12px;
			}
		}
		max-height: 67svh;
		overflow: auto;
		//min-height: 100px;
		overscroll-behavior: contain;
		@include desktop {
			max-height: 71vh;
		}
		@include real-iphone5 {
			max-height: 50svh;
		}

		.xy-product-offer {
			&.widget-offer {
				margin-left: 75px;

				* {
					color: var(--primary);
				}

				a {
					text-decoration: underline;
				}

				.icon-offer-tag {
					margin-right: 8px;
				}
			}
		}
		.logo-container {
			width: 200px;
			height: 180px;
			margin: 0px auto 0;
		}
		// &::-webkit-scrollbar {
		// 	width: 5px;
		// }
		// &::-webkit-scrollbar-track {
		// 	background: #c9c8c8;
		// }

		// &::-webkit-scrollbar-thumb {
		// 	background: #888;
		// }

		// &::-webkit-scrollbar-thumb:hover {
		// 	background: #555;
		// }
		.new-add-to-cart {
			@include mobile {
				top: 35px !important;
				.new-add-product-button {
					border-bottom-left-radius: 8px !important;
					border-bottom-right-radius: 8px !important;
				}
			}
		}
	}
	.wallet-with-purchase-rewards-present {
		max-height: calc(100% - 200px);
	}

	.bottom-section {
		padding-bottom: 10px;
		height: auto;
		padding: 12px 15px;
		position: absolute;
		bottom: 0px;
		width: 100%;
		background-color: white;
		z-index: 10;
		.shopping-btn {
			width: 100%;
			height: 40px;
			border: 1px solid var(--primary);
			// background: var(--primary);
			background-color: white;
			color: var(--primary) !important;
			@include desktop {
				height: auto;
			}
			&:hover {
				border-color: var(--primary-dark);
			}
		}
		.checkout-btn {
			width: 100px;
			background: var(--primary);
			color: white;
			height: 40px;
		}
		.cal-amount {
			color: #ffff;
		}
		.b-text {
			font-size: 14px;
		}
		.cart-widget-btn-container {
			display: flex;
			gap: 15px;
			.checkout-btn,
			.view-cart-btn {
				width: auto;
				flex: 1;
				height: 40px;
			}
			.view-cart-btn {
				background: white;
				color: var(--primary);
				border-color: var(--primary);
			}
		}
	}
	.inline-loader {
		&:after {
			right: 8px !important;
			top: 69% !important;
		}
		&.cart-widget-loader {
			text-align: center;
			padding-left: 1px;
			display: flex;
			justify-content: center;
			padding: 0 5px !important;
			span {
				width: 24px;
			}
			&:after {
				height: 24px;
				width: 24px;
				top: 48% !important;
				left: 5px !important;
				background-color: rgba(255, 255, 255, 0.7);
			}
		}
		&.checkout-loader {
			height: 25px;
			width: 25px !important;
			padding: 0 !important;
			display: flex !important;
			justify-content: center;
			align-items: center;
			span {
				width: auto !important;
				margin-top: 2px;
			}
			&:after {
				top: 57% !important;
				left: 1px !important;
			}
		}
	}

	.item {
		margin: 0 0 0px 0;
		padding: 0px 15px;
		// margin-bottom: 5px;
		// background: white;
		width: 100%;
		// border-top: 1px solid $dividerColor;

		&:first-child {
			// border-bottom: 1px solid $dividerColor;
			// margin-bottom: 130px;
			padding-top: 0;
			margin-top: 0 !important;
		}

		.c-img-container {
			margin: 0 8px;
			margin-left: 0;
			// border: 1px solid $dividerColor;
			border-radius: 5px;
			@include iphone5 {
				padding: 0 5px;
			}
			text-align: center;
		}
		.c-brand-name {
			color: #868686;
		}

		.product-name {
			font-size: 13px;
			font-weight: bold;
			padding-right: 20px;
			cursor: pointer;
			line-height: 1.1;
		}

		img {
			width: 60px !important;
			height: 60px !important;

			@include iphone5 {
				width: 48px !important;
				height: 48px !important;
			}

			object-fit: contain;
		}
		.cart-weight {
			font-size: 14px;
			color: #616161;
			line-height: 1;
			font-weight: 400;
			.cart-details-line {
				color: #e8e8e8 !important;
			}
		}
		.count-holder {
			font-weight: bold;
			// font-size: 13px;
			display: flex;
			align-items: baseline;
			margin-right: -8px !important;
			align-self: center !important;
			border: 1px solid var(--primary);
			border-radius: 25px;
			padding: 0px 10px;
		}
		.count-outline {
			border-radius: 6px;
			border: 1px solid var(--primary);
			padding: 5px;
		}
		.cart-total {
			border-top: 1px dotted $dividerColor;
			display: flex;
			width: 100%;
			justify-content: space-between;
			align-items: center;
			padding-top: 10px;
		}

		.deletebtn {
			position: absolute;
			top: 7px;
			right: -4px;
			width: 24px;
			height: 24px; // display: flex;
			// align-items: center;
			// justify-content: center;
			font-size: 10px;
			line-height: 25px;
			padding: 0;
			border-radius: 50%;
			background-color: white;
			border: 1px solid #e2e2e2;
			color: #b7b7b7;
			@extend %increase-touch-area;
		}

		.price-container {
			// .price {
			font-size: 14px;
			vertical-align: middle;
			font-weight: bold;
			// }
			.price {
				color: var(--primary);
			}
		}

		.strike-text {
			margin-left: 5px;
			color: #616161;

			&:after {
				// height: 1px;
				background: red;
				height: .09rem;
			}
		}
		.update-count-btn {
			// padding: 3px 5px;
			background: transparent;
			width: auto;
			padding: 5px 0px;
			font-size: 18px;
			display: flex;
			color: var(--primary);
			&:focus {
				box-shadow: none;
			}

			span {
				// height: 20px;
				// width: 20px;
				// font-size: 14px;
				// line-height: 1.2;
				// border: 1px solid #333;
				// display: inline-block;
				// border-radius: 50%;
				// color: var(--primary)!important; // padding: 9px 0;
				// i{
				// color: var(--primary)!important;
				// }
				width: 20px;
				height: 20px;

				&.delete-btn {
					height: fit-content;
				}
			}

			// &.delBtn {
			// 	span {
			// 		border: none;
			// 		position: relative;
			// 		top:
			// 		1px;
			// 	}
			// }
		}
		.save-for-later-link {
			font-weight: 600;
			font-size: 12px;
			line-height: 15px;
			text-decoration-line: underline;
			color: #1a0dab;
			margin-left: 15px;
			white-space: nowrap;
			cursor: pointer;
		}
		.count {
			width: 25px;
			height: 18px;
			color: #616161;
			border: 0;
			text-align: center;
			border-radius: 3px;
			line-height: 1;
			font-size: 14px;
			// margin: 0 5px;
		}

		.weight-category {
			margin-top: 2px;
		}

		.sale-info {
			text-align: left;
			padding-left: 68px;
			color: #858585 !important;
			@include desktop {
			}
		}
	}
	&.cart-widget-parent-dtc {
		.cart-box {
			// @include desktop {
			// 	right: 15px;
			// }
		}
	}
	// &.slideup-cart-enter .cart-box {
	// 	transform: translateY(100%);
	// 	// opacity: 0;
	// }
	// &.slideup-cart-enter-active .cart-box {
	// 	transform: translateY(0%);
	// 	// opacity: 1;
	// }
	// &.slideup-cart-exit .cart-box {
	// 	transform: translateY(0%);
	// 	// opacity: 1;
	// }
	// &.slideup-cart-exit-active .cart-box {
	// 	// opacity: 1;
	// 	transform: translateY(100%);
	// }
	// @include desktop {
		&.slideup-cart-enter .cart-box {
			transform: translateX(100%);
			// opacity: 0;
		}
		&.slideup-cart-enter-active .cart-box {
			transform: translateX(0%);
			// opacity: 1;
		}
		&.slideup-cart-exit .cart-box {
			transform: translateX(0%);
			// opacity: 1;
		}
		&.slideup-cart-exit-active .cart-box {
			// opacity: 0;
			transform: translateX(100%);
		}
	// }

	&.slideup-cart-v2-enter .cart-box {
		transform: translateY(100%);
		// opacity: 0;
	}
	&.slideup-cart-v2-enter-active .cart-box {
		transform: translateY(0%);
		// opacity: 1;
	}
	&.slideup-cart-v2-exit .cart-box {
		transform: translateY(0%);
		// opacity: 1;
	}
	&.slideup-cart-v2-exit-active .cart-box {
		// opacity: 1;
		transform: translateY(100%);
	}
	@include desktop {
		&.slideup-cart-v2-enter .cart-box {
			transform: translate(-50%, -50%);
			// opacity: 0;
		}
		&.slideup-cart-v2-enter-active .cart-box {
			transform: translate(-50%, -50%);
			// opacity: 1;
		}
		&.slideup-cart-v2-exit .cart-box {
			transform: translate(-50%, -50%);
			// opacity: 1;
		}
		&.slideup-cart-v2-exit-active .cart-box {
			// opacity: 0;
			transform: translate(-50%, -50%);
		}
	}
	

	&.pdp-product-widget {
		position: absolute;
		z-index: 1000;
		top: 0;
		.cart-box {
			@include desktop {
				// right: 17px;
				// top: 0px;
				// position: absolute;
				// border-radius: 12px;
				position: fixed;
				bottom: 0px;
				top: 0px;
			}
		}
	}
	.cart-widget-reco-products {
		.view-btn {
			background-color: #ffffff;
			color: var(--primary);
			border: 1px solid var(--primary);
			padding: 8px 10px;
			font-size: 14px;
			width: auto;
			&:hover {
				background-color: var(--primary);
				color: #ffff;
			}
		}
		.see-all-wishlist {
			display: flex;
			justify-content: center !important;
			align-items: center;
			background-image: url(/static/images/wishlist-bg.png);
			background-position: right bottom; /*Positioning*/
			background-repeat: no-repeat;
			background-size: 45%;
		}
		.recommended-products {
			margin-top: 1rem !important;
		}
		.loader-parent {
			height: 300px;
			@include desktop {
				height: 0px;
			}
		}
		.p-item {
			min-width: 155px;
			max-width: 155px;
			justify-content: space-between;
			padding: 15px 10px;
			margin: 0;
			// box-shadow:0px 0px 1px 0px rgba(0, 0, 0, 0.23);
			box-shadow: none;
			margin-right: 25px;
			border-radius: 6px;
			border: 1px solid #e8e8e8;

			.brand-name {
				color: #797979 !important;
				font-size: 12px;
				text-transform: uppercase;
				letter-spacing: -0.1px;
			}
			img {
				width: 100%;
				height: 90px;
				margin-top: 35px;
				position: static;
				transform: translateY(0%);
				margin-bottom: 10px;
			}
			.atc-btn {
				position: absolute;
				top: 25px;
				right: 10px;
				font-size: 16px;
				width: 28px;
				height: 28px !important;
				background-color: var(--primary);
				color: #fff;
				border: 1px solid var(--primary);
				&:hover {
					background-color: #fff;
					color: var(--primary);
					.atc-icon {
						color: var(--primary);
					}
				}
				.atc-icon {
					color: #fff;
					font-weight: bolder;
				}
			}
			.r-title {
				font-size: 12px;
				font-weight: bold;
				span {
					font-size: 12px;
					font-weight: 400;
					margin-bottom: 4px;
				}

				.strain-container[class*="Hybrid"] {
					.icon-Hybrid {
						display: inline-block !important;
					}
				}
				.strain-container[class*="Indica"] {
					.icon-Indica {
						display: inline-block !important;
					}
				}
				// .strain-container[class*="Sativa"] {
				// 	background-color: #ffd83a !important;
				// }
				// .strain-container[class*="CBD"] {
				// 	background-color: #e53935 !important;
				// 	color: white;
				// }
				// .strain-container[class*="CBD:THC"] {
				// 	background-color: #e91e63 !important;
				// 	color: white;
				// }
				// .strain-container[class*="Indica-Hybrid"] {
				// 	background-color: #9c27b0 !important;
				// }
				// .strain-container[class*="CBG"] {
				// 	background-color: var(--primary) !important;
				// }
				// .strain-container[class*="Sativa-Hybrid"] {
				// 	background-color: #3f51b5 !important;
				// }
				// .strain-container[class*="CBN"] {
				// 	background-color: #00bcd4 !important;
				// }
				// .strain-container[class*="THC"] {
				// 	background-color: #4caf50 !important;
				// 	color: white;
				// }
				// .strain-container[class*="THCa"] {
				// 	background-color: #4caf50 !important;
				// 	color: white;
				// }
				// .strain-container[class*="Total Cannabinoids"] {
				// 	background-color: #ffc107 !important;
				// }
				// .strain-container[class*="THCV"] {
				// 	background-color: #795548 !important;
				// 	color: white;
				// }
				// .strain-container[class*="CBDa"] {
				// 	background-color: #607d8b !important;
				// }
			}
		}
		.atc-btn {
			position: absolute;
			top: 25px;
			right: 5px;
			font-size: 16px;
			width: 35px;
			height: 35px !important;
		}
		.r-title {
			font-size: 12px;
			font-weight: bold;
			span {
				font-size: 12px;
				font-weight: 400;
			}
		}

		// .price-container {
		// 	position: absolute;
		// 	top: 12px;
		// 	left: 10px;
		// 	// font-size: 12px;
		// 	.price {
		// 		// font-size: 13px;
		// 	}
		// 	.discount {
		// 		// color: #ff7f68;
		// 	}
		// }
		.sale {
			top: 35px;
			* {
				color: white !important;
			}
		}
		.h-list-parent .scroll-btn.left {
			left: -12px;
			font-size: 1.3rem;
			width: 26px;
			height: 30px;
			line-height: 34px;
		}
		.h-list-parent .scroll-btn.right {
			right: -14px;
			font-size: 1.3rem;
			width: 26px;
			height: 30px;
			line-height: 34px;
		}
		.mini-label {
			font-size: 16px;
			margin-bottom: 0.6rem;
			font-weight: bold;
		}
		&:empty {
			margin-top: 0 !important;
		}
	}
	.empty-cart-holder {
		width: 80%;
		@include desktop {
			width: 60%;
		}

		margin: auto;
	}
	.all-product-widget {
		.c-header {
			// box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.10);
		}
	}

	.sm-text {
		color: #858585 !important;
	}

	.product-label {
		background-color: #fce68c;
		border-radius: 4px;
		font-size: 12px;
		padding: 0px 5px;
	}

	.long-cart {
		top: 0;
		.c-list {
			height: calc(100vh - 200px);
			max-height: calc(100vh - 200px);
			padding-bottom: 2rem !important;
			padding-top: 15px;
		}
	}

	.cart-padding {
		.recommended-products {
			.latest-product {
				.product-counter-parent {
					.inline-loader {
						&:after {
							right: 15px !important;
							top: 55% !important;
						}
					}
				}
			}
		}
	}
}

.cart-widget-non-conc::after {
	content: " ";
	width: 1px;
	height: 100%;
	display: block;
	background-color: #999;
	position: absolute;
	right: -20px;
	top: 0px;
	@include desktop {
		right: -20px !important;
	}
	@include tab {
		right: -40px;
	}
}
.cart-widget-concentrates {
	padding: 10px 15px;
	border-bottom-width: 1px;
	border-bottom-style: solid;
	border-image: linear-gradient(270deg, rgba(153, 153, 153, 0) 6.4%, #999999 50.09%, rgba(153, 153, 153, 0) 92%) 1;
	// margin-bottom: 12px !important;
	&.dr-weedy-cart-text {
		height: auto;
		.dr-weedy-link {
			color: var(--primary);
			text-decoration: underline;
			i {
				top: 2px;
			}
		}
		& + .c-list {
			max-height: calc(100% - 322px);
			@include desktop {
				max-height: calc(100% - 300px);
			}
		}
		// &+.wallet-with-purchase-rewards-present{
		// 	max-height: calc(100% - 400px);
		// 	@include desktop{
		// 		max-height: calc(100% - 370px);
		// 	}
		// }
	}
	.conc-non-value {
		float: right;
		@include desktop {
			font-size: 12px;
		}
	}
}

.cart-widget-non-conc,
.cart-widget-conc {
	width: 45%;
	p {
		color: #616161 !important;
	}
	.qtn-progress .qtn-progress-bar span {
		min-width: 70px !important;
		width: auto;
	}
}
.reward-cart-wrapper {
	position: absolute;
	bottom: 88px;
	width: 100%;
	height: 65px;
	.qtn-progress {
		border-radius: 0;
		background-color: #8ea9e5;
		height: 6px;
		.qtn-progress-bar {
			min-width: 6px;
			position: relative;
			// background: linear-gradient(270deg, #FDC500 0%, #FFF510 100%);
			background: linear-gradient(123deg, #fdc500 40%, #e3d4d4, #fff510 60%);
			animation: Animation 3s linear infinite;
			background-size: 200% 200%;
			span {
				height: 6px;
				width: 6px;
				background: white;
				padding: 0;
				right: 0px;
			}
		}
	}
	.reward-cart-widget {
		background: linear-gradient(90deg, #00abe7 0%, #927ae2 100%);
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
		border-radius: 8px 8px 0px 0px;
		padding: 10px;
		color: white;
		i {
			font-size: 22px;
			margin-top: -5px;
			color: #fff;
		}
		p {
			line-height: 1.3;
			padding-right: 20px;
			color: #fff;
			span {
				color: #fff;
			}
		}
		span {
			color: #fff;
		}
	}
	@keyframes Animation {
		0% {
			background-position: 100% 100%;
		}

		100% {
			background-position: 0% 0%;
		}
	}
	&.reward-first-state {
		bottom: 112px;
	}
	&.previous-tier-claimed {
		bottom: 130px;
		@include desktop {
			bottom: 132px;
		}
	}
	&.reward-success-state {
		bottom: 74px;
	}
}
.reward-cart-wrapper-next {
	height: 38px;
}

.free-products-wrapper {
	width: 100%;
	background-color: white;
	.spin-loader {
		border: 2px solid #eee;
		border-radius: 50%;
		border-top: 2px solid #aaa;
		width: 14px;
		height: 14px;
		-webkit-animation: spin 2s linear infinite; /* Safari */
		animation: spin 2s linear infinite;
		margin: auto;
	}
	@-webkit-keyframes spin {
		0% {
			-webkit-transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
	.adding-rewards-loader {
		padding: 15px 0;
		border: 1px solid var(--primary);
		margin: 10px 15px 40px;
		border-radius: 6px;
		p {
		}
	}
	.free-products-widget {
		background: linear-gradient(90deg, #3e8a94 -0.22%, #41bf92 99.78%);
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
		border-radius: 8px 8px 0px 0px;
		padding: 10px;
		height: 45px;
		i {
			font-size: 22px;
		}
		.long-cart {
			top: 0;
			.c-list {
				height: calc(100vh - 280px);
				max-height: calc(100vh - 280px);
				padding-bottom: 5rem !important;
			}
			.bottom-section {
				position: absolute;
				bottom: 0;
				right: 0;
				left: 0;
				z-index: 11;
				background-color: white;
			}
			@include desktop {
				top: -10px;
				.c-list {
					max-height: 50vh;
					height: auto;
				}
				.bottom-section {
					position: static;
					background-color: transparent;
				}
			}
		}
	}
}

.added-rewards-wrapper {
	// position: absolute;
	// bottom: 90px;
	width: 100%;
	.added-rewards-widget {
		.added-rewards {
			background: linear-gradient(90deg, #3e8a94 -0.22%, #41bf92 99.78%);
			box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
			border-radius: 8px 8px 0px 0px;
			padding: 15px;
			height: 45px;
			line-height: 1;
			position: absolute;
			width: 100%;
			z-index: 0;
			top: -60px;
			p {
				color: #fff;
			}
		}
		.item {
			position: relative;
			z-index: 1;
			background-color: #fff;
			border-radius: 8px 8px;
			margin-top: 0px !important;
			padding: 0px 15px 0;
			& + .item {
				padding-top: 15px;
			}
		}
		.claim-products-wrapper {
			.claim-products-widget {
				background: linear-gradient(90deg, #00abe7 0%, #927ae2 100%);
				box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
				border-radius: 8px 8px 0px 0px;
				padding: 10px;
				height: 45px;
				position: absolute;
				width: 100%;
				z-index: 3;
				height: 100%;
				align-items: flex-start !important;
			}
			.add-claim-products {
				height: 95px;
				background: #fff;
				z-index: 18;
				position: absolute;
				bottom: 1px;
				border-radius: 8px 8px 0 0;
				width: calc(100%);
				margin: auto;
				padding: 12px;
			}
		}
		.unclaimed-rewards {
			position: relative;
			padding: 8px 10px;
			border: 1px solid var(--primary);
			border-radius: 6px;
			margin: 15px 15px 15px;
			cursor: pointer;
			i {
				font-size: 20px;
				// position: absolute;
				right: 7px;
				top: 8px;
			}
		}
		.claim-rewards {
			background-color: white;
			height: 140px;
			position: relative;
			border-radius: 8px 8px 0 0;
			width: calc(100%);
			margin: auto;
			padding: 12px;
			z-index: 1;
		}
		button {
			color: #fff;
			background: var(--primary);
			width: 30px;
			height: 30px;
			font-size: 15px;
			border: none;
			border-radius: 50%;
			padding: 5px;
		}
		.wallet-with-purchase-wrapper {
			padding: 10px;
			border-width: 1px;
			border-style: solid;
			border-image: linear-gradient(270deg, #00abe7 100%, #927ae2 100%) 1;
			border-radius: 8px;
			margin: 15px;
			z-index: 0;
			border-radius: 8px;
			position: relative;
			box-sizing: border-box;
			color: #616161;
			background: linear-gradient(to right, #00abe7, #927ae2);
			background-clip: padding-box;
			border: solid 1px transparent;
			&:before {
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: -1;
				margin: 1px;
				border-radius: 6px;
				background: #ffffff;
			}
		}
		.wallet-with-purchase-amt {
			font-size: 32px;
			background: linear-gradient(91.01deg, #403e94 -0.34%, #cb54b1 102.36%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-clip: text;
			margin-right: 10px;
		}
	}
	.center-box {
		@include desktop {
			transition: none;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			bottom: auto;
			right: auto;
			position: fixed;
		}
	}
}

.mini-label {
	font-size: 14px;
	margin-bottom: 0.6rem;
	font-weight: bold;
}

.empty-cart-holder {
	width: 80%;
	@include desktop {
		width: 60%;
	}

	margin: auto;
}
.all-product-widget {
	.c-header {
		// box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.10);
	}
}

.sm-text {
	color: #858585 !important;
}
.product-label {
	background-color: #fce68c;
	border-radius: 4px;
	font-size: 12px;
	padding: 0px 5px;
}

.long-cart {
	top: 0;
	.c-list {
		height: calc(100vh - 200px);
		max-height: calc(100vh - 200px);
		padding-bottom: 150px !important;
		padding-top: 15px;
		@include desktop {
			padding-bottom: 1rem !important;
		}
	}
	.bottom-section {
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		z-index: 11;
		background-color: white;
	}
	@include desktop {
		top: -10px;
		.c-list {
			height: calc(100vh - 200px);
			max-height: calc(100vh - 200px);
			// padding-bottom: 5rem !important;
			// padding-top: 15px;
		}
		.bottom-section {
			position: absolute;
			bottom: 0;
			right: 0;
			left: 0;
			z-index: 11;
			background-color: white;
		}
		@include desktop {
			top: -10px;
			.c-list {
				max-height: 45vh;
				//if not then make it 45vh
				height: auto;
			}
			.bottom-section {
				position: static;
				background-color: transparent;
			}
		}
	}
}
.center-box {
	@include desktop {
		transition: none;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		bottom: auto;
		right: auto;
		position: fixed;
	}
}

.tesla-cart-wrapper {
	.tesla-cart-widget {
		.qtn-progress {
			flex: 1;
			height: 4px;
			background: #ccc;
			display: flex;
			font-size: 0.75rem;
			.qtn-progress-bar {
				min-width: 30px;
				position: relative;
				background-color: #ebe7f3;
				text-align: center;
				white-space: nowrap;
				transition: width 0.6s ease;
				border-radius: 8px;
				background: linear-gradient(270deg, #d66567 45%, #e3d4d4, #c8474e 60%);
				animation: Animation 3s linear infinite;
				background-size: 200% 200%;
				min-width: 10px;
				@keyframes Animation {
					0% {
						background-position: 100% 100%;
					}

					// 50%{background-position:91% 100%}
					100% {
						background-position: 0% 0%;
					}
				}
				span {
					width: 10px;
					border-radius: 18px;
					position: absolute;
					color: white;
					height: 10px;
					background: #fff;
					border: 1px solid #ffffff;
					box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.15);
					right: 0px;
					top: -3.5px;
					z-index: 1;
					img {
						position: absolute;
						top: 6px;
						left: 4px;
						height: 22px;
					}
				}
			}
			.full-qtn-progress-bar {
				min-width: 30px;
				position: relative;
				background-color: #ebe7f3;
				text-align: center;
				white-space: nowrap;
				transition: width 0.6s ease;
				border-radius: 8px;
				background: linear-gradient(270deg, #3db33d 45%, #e6ffe6, #008000 60%);
				animation: Animation 3s linear infinite;
				background-size: 200% 200%;
				min-width: 10px;
				@keyframes Animation {
					0% {
						background-position: 100% 100%;
					}

					// 50%{background-position:91% 100%}
					100% {
						background-position: 0% 0%;
					}
				}
				span {
					width: 10px;
					border-radius: 18px;
					position: absolute;
					color: white;
					height: 10px;
					background: #fff;
					border: 1px solid #ffffff;
					box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.15);
					right: 0px;
					top: -3.5px;
					z-index: 1;
					img {
						position: absolute;
						top: 6px;
						left: 4px;
						height: 22px;
					}
				}
			}
		}
		.tesla-desc {
			overflow: hidden;
			// background-image: url(/static/images/tesla-overlay.png);
			border-radius: 0px 0px 12px 12px;
			background-repeat: no-repeat;
			background-position: bottom;
			background-color: #211e1e;
			background-size: contain;
			min-height: 67px;
			p {
				color: #fff;
				line-height: 1.3;
				padding: 15px 0;
				padding-right: 112px;
				@include desktop {
					padding-right: 142px;
				}
			}
			.tesla-overlay-one {
				position: absolute;
				right: 0px;
				top: -6px;
				mix-blend-mode: lighten;
				height: 105px;
			}
			.tesla-overlay-two {
				position: absolute;
				left: 0px;
				top: -6px;
				mix-blend-mode: lighten;
				height: 85px;
			}
			.tesla-car-flare {
				z-index: 2;
				.tesla-flare {
					height: 75px;
					position: absolute;
					top: 0px;
					right: 0px;
					opacity: 0;
					animation: teslaflare 0.5s 1s ease-in-out;
					animation-fill-mode: forwards;
					transform-origin: left;
				}
				@keyframes teslaflare {
					// from {
					// 	right: -200px;
					// 	top: 30px;
					// 	transform: scale(0);
					// }
					// to {
					// 	right: 0px;
					// 	top:0px;
					// 	transform: scale(1);
					// }
					from {
						// top: 0px;
						// right: 30px;
						transform: scale(0, 0.8);
						opacity: 0;
					}
					to {
						// top: 0px;
						// right: 0px;
						transform: scale(1, 1);
						opacity: 1;
					}
				}
				.tesla-car {
					height: 80px;
					position: absolute;
					top: 5px;
					right: 5px;
					animation: teslacar 1s ease-out;
					animation-fill-mode: forwards;
				}
				@keyframes teslacar {
					from {
						right: -200px;
						top: -50px;
						transform: scale(0);
					}
					to {
						right: 5px;
						top: 5px;
						transform: scale(1);
					}
				}
			}
		}
		.tesla-success {
			// background-image: url(/static/images/tesla-overlay.png);
			border-radius: 0px 0px 12px 12px;
			background-repeat: no-repeat;
			background-position: bottom;
			background-color: #211e1e;
			background-size: contain;
		}
	}
}
.cart {
	.tesla-cart-wrapper {
		.tesla-desc p {
			@include desktop {
				padding-right: 130px;
			}
		}
	}
	.added-rewards-wrapper {
		// margin-top: 55px;
		// .added-rewards-widget .added-rewards{
		// 	top: -45px;
		// }
		.added-rewards-widget .wallet-with-purchase-wrapper {
			border: none;
			padding: 15px 5px 15px 15px;
		}
		.added-rewards-widget .item {
			padding: 0px 15px 15px;
		}
	}
	.reward-cart-wrapper .reward-cart-widget p br {
		display: none;
	}
}
.claim-reward-popup {
	.modal-content {
		border-radius: 10px 10px 0 0;
		@include desktop {
			border-radius: 10px;
		}
	}
	.modal-header .modal-title {
		font-size: 20px;
	}
	.modal-body {
		.reward-tier {
			font-size: 16px;
		}
		.select-tier {
			font-weight: 600;
		}
		.select-tier-wrapper {
			padding: 24px 0;
			border-bottom-width: 1px;
			border-bottom-style: solid;
			border-image: linear-gradient(
					270deg,
					rgba(153, 153, 153, 0) 6.4%,
					#e7e4e4 50.09%,
					rgba(153, 153, 153, 0) 92%
				)
				1;
			.strike-text::after {
				background-color: #616161;
			}
			.claim-discount {
				font-weight: 600;
			}
		}
		.fallback-wallet {
			padding: 24px 0;
			border-bottom-width: 1px;
			border-bottom-style: solid;
			border-image: linear-gradient(
					270deg,
					rgba(153, 153, 153, 0) 6.4%,
					#e7e4e4 50.09%,
					rgba(153, 153, 153, 0) 92%
				)
				1;
			.strike-text::after {
				background-color: #616161;
			}
			.claim-discount {
				font-weight: 600;
			}
		}
	}
	.modal-footer {
		box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.15);
		button {
			border-radius: 6px;
		}
	}
}
.applicable-rewards-popup {
	@include desktop {
		width: 400px;
		position: fixed;
		right: 0px;
		left: auto;
		text-align: left;
		z-index: 9999;
		top: 0px;
		background-color: white !important;
		bottom: auto;
		.modal-dialog {
			height: 100%;
		}
		.modal-footer button {
			background: var(--primary);
			color: white;
			height: 42px;
			padding: 0;
			line-height: 1;
			width: 180px;
		}
		.c-backdrop {
			position: fixed;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			background-color: rgba(0, 0, 0, 0.69);
		}
	}

	.modal-content {
		border-radius: 0;
	}
	.modal-header {
		border-radius: 0;
		padding-left: 0;
		i {
			font-size: 20px;
			transform: rotate(180deg);
		}
		button:focus {
			box-shadow: none;
		}
		button {
			height: auto;
		}
	}
	.modal-footer button {
		background: var(--primary);
		color: white;
		height: 42px;
		padding: 0;
		line-height: 1;
		width: 180px;
	}
	.applicable-reward {
		transition:
			transform 0.3s linear,
			opacity 0.3s linear;
		.cart-widget-concentrates {
			position: sticky;
			top: 0;
			background-color: #fff;
			z-index: 1;
		}
	}
}
.secondary-cart-widget-popup {
	height: auto !important;

	border-radius: 0px;
	@include desktop {
		border-radius: 10px;
	}
	.c-list {
		max-height: calc(100% - 150px);
		overflow-x: hidden;
		margin-bottom: 3.5rem;
	}
}
.qtn-progress-bar.progress-exceed {
	width: 100% !important;
	background: linear-gradient(90deg, #f7dede -0.22%, #b67575 99.78%) !important;
	.progress-slider {
		left: calc(100% - 70px) !important;
		background: linear-gradient(90deg, #943e3e -0.22%, #eb4747 99.78%) !important;
	}
}
.progress-bar.progress-exceed {
	width: 100% !important;
	background-color: #eb4747 !important;
}
.wallet-with-purchase-product-remove {
	.modal-body {
		.remove-text {
			font-weight: 600;
			margin: 2rem auto;
		}
		.remove-img {
			height: 150px;
			width: auto;
			margin: 0 auto 10px;
		}
	}
	.modal-footer {
		button {
			font-size: 16px;
			background: var(--primary);
			border: 1px solid var(--primary);
			border-radius: 6px;
			color: #fff;
			padding: 0px 12px;
			&:hover {
				border: 1px solid var(--primary-dark);
				background-color: var(--primary-dark);
			}
		}
		button:first-child {
			color: var(--primary);
			background-color: #fff;
			&:hover {
				border: 1px solid var(--primary-dark);
				color: var(--primary-dark);
			}
		}
	}
}

.slideright-wrapper-enter .applicable-reward {
	transform: translateX(100%);
	opacity: 0;
}
.slideright-wrapper-enter-active .applicable-reward {
	transform: translateX(0%);
	opacity: 1;
	transition:
		transform 0.3s linear,
		opacity 0.3s linear;
}
.slideright-wrapper-exit .applicable-reward {
	transform: translateX(0%);
	opacity: 1;
}
.slideright-wrapper-exit-active .applicable-reward {
	opacity: 1;
	transform: translateX(100%);
	transition:
		transform 0.3s linear,
		opacity 0.3s linear;
}
@include tab {
	.slideright-wrapper-enter .applicable-reward {
		transform: translateX(10%);
		opacity: 0;
	}
	.slideright-wrapper-enter-active .applicable-reward {
		transform: translateX(0%);
		opacity: 1;
		transition:
			transform 0.3s linear,
			opacity 0.3s linear;
	}
	.slideright-wrapper-exit .applicable-reward {
		transform: translateX(0%);
		opacity: 1;
	}
	.slideright-wrapper-exit-active .applicable-reward {
		opacity: 0;
		transform: translateX(10%);
		transition:
			transform 0.3s linear,
			opacity 0.3s linear;
	}
}

.icon-non-conc,
.icon-conc {
	position: relative;
	top: 1px;
}
.cart .reward-first-state {
	height: 95px;
	@include desktop {
		height: 80px;
	}
}
.cart .reward-success-state {
	height: 45px;
}
.secondary-cart-widget .cart-box {
	top: auto;
	@include desktop {
		top: 64px;
	}
}
