.featured-tiles-wrapper{
  width: 100%;
  margin: 5px 0 0;
  @include desktop{
    margin: 25px 0 0;
  }
  .featured-tile-main{
    position: relative;
    width: 325px;
    min-width: 100%;
    background-color: var(--featuredTileBackgroundColour);
    border-radius: 24px;
    padding: 16px 0;
    margin: 75px 15px 0 0;
    @include desktop{
      min-width: 325px;
      margin-right: 32px;
    }
    @include tab{
      min-width: 325px;
      margin-right: 32px;
    }
    .feat-img{
      position: absolute;
      top: -70px;
      left: 16px;
      border-radius: 24px;
      border: 1px solid var(--featuredTileImgBorderColour);
      width: 145px;
      height: 145px;
      background-color: #fff;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
      overflow: hidden;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .feat-title{
      color: #fff;
      background: rgba(255, 255, 255, 0.2);
      margin-bottom: 35px;
      padding: 0 10px 0 175px;
      line-height: 1;
      height: 50px;
      font-weight: 300;
      @include desktop{
        padding-left: 170px;
      }
    }
    .feat-brand{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: auto;
      padding: 6px 8px;
      color: #fff;
      background: #FFFFFF4D;
      box-shadow: 0px -4px 27px rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      border: 1px solid;
      border-image: linear-gradient(270deg, rgba(153, 153, 153, 0) 7%, #ffffff 50.09%, rgba(153, 153, 153, 0) 100%)1;
    }
    .feat-product-name{
      font-size: 16px;
      line-height: 19px;
      color: #fff;
      margin: 15px 0 2px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 290px;
    }
    .feat-product-details{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 290px;
      color: #fff;
      margin-bottom: 35px;
      span{
        font-weight: 400;
      }
    }
    .vertical-yellow{
      color: #F9D95B;
      margin: 0 3px;
      font-size: 16px;
    }
    .feat-price-container{
      color: #fff;
      position: absolute;
      bottom: 15px;
      width: 100%;
      padding: 0 15px;
      align-items: center;
      .price{
        font-size: 22px;
        font-weight: 700;
        position: relative;
        margin-right: 5px;
        .strike-text::after{
          content: "";
          position: absolute;
          top: 50%;
          width: 100%;
          height: 2px;
          left: 0;
          text-align: center;
          transform: translateY(-50%) rotate(0deg);
          background-color: #fff;
        }
      }
    }
    .new-add-to-cart{
      button{
        width: 32px;
        height: 32px;
        line-height: 1;
        border-radius: 50%;
        padding: 0px !important;
        background-color: #fff;
      }
      .icon-plus{
        font-size: 14px;
        font-weight: 700;
      }
      .hide-button{
        visibility: hidden;
      }
      .product-counter-parent.version-2.p-counter{
        top: 0 !important;
        right: 15px !important;
      }
      .product-counter-parent.version-2.p-count{
        top: 3px;
        right: 16px;
        @include desktop{
          top: 0px;
        }
      }
      .product-counter-parent.version-2 .count{
        font-size: 15px;
        font-weight: bold;
      }
      .add-to-cart.opacify .icon-plus{
        display: none;
      }
    }
    // box-shadow: 0px 2px 10px 5px rgb(0 0 0 / 2%);
    transition: transform 0.3s ease-in;
    &:hover{
      @include desktop{
        transform: scale(1.01);
        // box-shadow: 0px 2px 15px 10px rgb(0 0 0 / 5%)
      }
    }
   
  }
  .h-list{
    margin-left: 0px !important;
    padding: 6px 0 15px 0 !important;
  }
  .wishlist-icon{
   
    .wishlist-btn{
      &:focus{
        outline: none;
        box-shadow: none;
      }
    .icon-heart-outline,  .icon-heart-filled{
      color:#ffff;
      font-size: 20px;
    }
  }
  .wishlist-anim{
    color:#ffff;
    animation: pulse .7s ;
  }
  }
 
}

 
 