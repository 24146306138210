.freight-message-banner {
  width: 100%;
  background: linear-gradient(0deg, rgba(5, 160, 168, 0.1), rgba(5, 160, 168, 0.1)), #ffffff;
  border: 1px solid rgba(55, 71, 91, 0.1);
  box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.15);
  // position: fixed;
  z-index: 100;
  left: 0;
  // bottom: 0;
  text-align: center;
  color: #333;
  padding: 10px 0;
  @include desktop {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .btn {
    font-size: 10px;
    font-weight: 600;
  }
}
