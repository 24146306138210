.category-volume-pricing{
  .product-list-item{
    position: relative;
    padding-left: 80px;
    .price-parent{
      font-size:16px;
    }
		.price{
      color:var(--primary);
      margin-right: 5px;
		}
    .list-img-container{
      position: absolute;
      width:60px;
      height:60px;
      left:10px;
      top:0px;

      img{
        width:100%;
        height: 100%;
        object-fit:contain;
      }
    }
    @include desktop{
      padding-left: 100px;
      .list-img-container{
				height:70px;
				width:70px;
      }
    }
  }

}
