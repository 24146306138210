.header {
    height: var(--navbar-height);
    transition: all 0.3s linear;

    &.white-bg,
    &.white-bg-mobile {
        // *{
        //     color:#333;
        // }
    }
    &.transparent {
        background-color: transparent;
        // *{
        //     color:#fff;
        // }
    }
    &.centered-header {
        color: white;
        button {
            display: none;
        }
        .logo-text {
            text-align: center;
            padding-left: 0;
        }
    }
    &.desktop-super-primary {
        @include desktop {
            * {
                color: $primary;
                color: var(--primary);
            }
        }
    }
    &.desktop-super-black {
        @include desktop {
            * {
                color: #333;
            }
        }
    }
    &.desktop-super-white {
        @include desktop {
            * {
                color: #fff;
            }
        }
    }
    .btn {
        width: 35px;
        height: 50px;
        padding: 0;
        text-align: center;
        line-height: 50px;
        background-color: transparent;
        color: #000;
        @include iphone5 {
            width: 35px;
            height: 45px;
        }
    }
    .left-btn {
        font-size: 1.4em;
        position: absolute;
        left: 10px;
        top: 7px;
        text-shadow: 0 0px 1px rgba(255, 255, 255, 0.6901);
        @include iphone5 {
            left: 0px;
            font-size: 1.4em;
        }
        span {
            position: relative;
            // top: 2px;
        }
    }
    .right-btn-container {
        display: flex;
        justify-content: center;
        align-items: center;
        right: 10px;
        top: 7px;
        text-align: right;
        position: absolute;
        i,
        span {
            font-weight: bold;
        }
        @include real-iphone5() {
            top: 12px;
        }
        .icon-heart-outline {
            color: #37475b !important;
        }
    }
    .right-btn {
        font-size: 1.4em;
        //position: absolute;
        //right: 10px;
        //top: 7px;
    }
    .heart-btn {
        font-size: 22px;

        // margin-right: 15px;
        text-decoration: none;
        width: auto;
        display: inline-flex;
        align-items: center;
        &:focus {
            outline: none;
            box-shadow: none;
        }
        .icon {
            color: #37475b !important;
        }
    }
    .h-cart-btn {
        position: relative;
        font-size: 1.43em;
    }
    .chat-btn {
        @include desktop {
            margin-right: 10px;
        }
        img,
        svg {
            width: 36px;
            height: auto;
            filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.3));
            position: relative;
            top: -3px;
            @include iphone5 {
                width: 30px;
                top: -2px;
            }
        }
    }

    .btn-more-info {
        //font-size: 2.3em;
        position: absolute;
        right: 70px;
        top: 16px;
        font-size: 0.8rem;
        width: 28px;
        height: 28px;
        background: #333;
        color: white;
        display: inline-block;
        border-radius: 50%;
        line-height: 28px;
        @include iphone5 {
            width: 25px;
            right: 65px;
            height: 25px;
            line-height: 25px;
            top: 19px;
        }
    }

    .main-logo {
        font-size: 1em;
        // margin-top: 10px;
        width: 100%;
        padding-left: 40px;
        height: 40px;
        img {
            width: auto;
            height: 100%;
            cursor: pointer;
        }
        @include iphone5 {
            padding-left: 30px;
        }
        // @include desktop{
        //     position: absolute;
        //     top: 24px;
        // }
    }
    .logo-text {
        width: 100%;
        padding-left: 50px;
        font-size: 1.4em;
    }
    .h-cart-btn {
        .icn-area {
            // position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            height: 100%;
            .hover-box {
                // width: 0;
                overflow: hidden;
                text-align: left;
                transition: 0.15s width ease-out;
                @include desktop {
                    padding-left: 5px;
                }

                span {
                    line-height: 1;
                }
                .hover-box-text {
                    font-weight: normal;
                }
                .hover-content {
                    display: flex;
                    flex-direction: column;
                    font-size: 12px;
                    opacity: 0;
                    width: 0;
                    transition: opacity 0.3s linear;
                }
            }
            .icon-down {
                font-size: 12px;
            }
        }
        .cart-item-count {
            position: absolute;
            top: 2px !important;
            right: -4px !important;
            width: 20px !important;
            height: 20px !important;
            background-color: $pink;
            color: white;
            text-align: center;
            border-radius: 50%;
            line-height: 20px !important;
            font-size: 12px;
        }
    }
    .user-info-btn:hover,
    .cart-btn-info:hover {
        @include desktop {
            .small-text {
                // display: block;
                // transition: all 50s ease;
                width: 60px;
                visibility: visible;
                -webkit-transition:
                    width 0.4s ease-in-out,
                    visibility 0.4s linear;
                -moz-transition:
                    width 0.4s ease-in-out,
                    visibility 0.4s linear;
                -o-transition:
                    width 0.4s ease-in-out,
                    visibility 0.4s linear;
                transition:
                    width 0.4s ease-in-out,
                    visibility 0.4s linear;
                // padding-left: 15px;
            }
        }
    }
    .user-info-btn,
    .cart-btn-info {
        text-decoration: none !important;
        // transition: all 1s ease;
        .icon-down {
            font-size: 10px;
            display: none;
            // margin-left: 0px !important;
            @include desktop {
                display: block;
                margin-left: 0px !important;
            }
        }
        .item-add-count {
            position: absolute;
            top: -12px;
            right: -9px;
            background: #f9d95b;
            border-radius: 20px;
            line-height: 15px;
            color: #000;
            min-height: 17px;
            min-width: 17px;
            width: fit-content !important;
            z-index: 1;
            span {
                padding: 0 4px;
                font-family: "PublicSans";
            }
        }
        .small-text {
            // display: none;
            // transition: all 50s ease;
            visibility: hidden;
            width: 1px;
            overflow: hidden;
            white-space: nowrap;
            -webkit-transition:
                width 0.3s ease-in-out,
                visibility 0.3s linear;
            -moz-transition:
                width 0.3s ease-in-out,
                visibility 0.3s linear;
            -o-transition:
                width 0.3s ease-in-out,
                visibility 0.3s linear;
            transition:
                width 0.3s ease-in-out,
                visibility 0.3s linear;
            @include desktop {
                margin-left: 0.5rem;
            }

            p {
                line-height: 15px;
                text-align: left;
                color: #063a3f;
            }
            p:first-child {
                font-weight: 600;
                color: #37475b;
            }
        }
    }
    .cart-btn-info {
        // margin-right: 20px !important;
        @include desktop {
            font-size: 20px;
            // margin-left: 0.5rem !important;
        }
        .icon {
            font-size: 20px;
            font-weight: normal !important;
            color: #37475b !important;
            margin-left: 0px !important;
            @include desktop {
                font-size: 20px;
            }
            &:focus,
            &:hover {
                filter: brightness(0.8);
                color: #999999 !important;
            }
        }

        .animate-stars {
            font-size: 1rem;
            position: absolute;
            bottom: 10px;
            right: 0px;
            width: 60px;
            height: 60px;
            display: none;
        }
        .icon-star {
            color: #f8cb1f !important;
            animation: animate 3s linear;
            position: absolute;
            bottom: 0;
            right: 20px;
            visibility: hidden;

            &:nth-child(1) {
                bottom: 0px;
                right: 30px;
                font-size: 1.2rem;
                animation-delay: 1.5s;
            }

            &:nth-child(2) {
                bottom: 10px;
                right: 20px;
                font-size: 0.9rem;
                animation-delay: 2s;
                animation-duration: 2s;
                opacity: 0.7;
            }

            &:nth-child(3) {
                bottom: 0px;
                right: 3px;
                font-size: 0.5rem;
                animation-delay: 1s;
            }

            &:nth-child(4) {
                bottom: 5px;
                right: 10px;
                font-size: 0.8rem;
                animation-delay: 0.5s;
            }
        }
        .animate-stars2 {
            font-size: 1rem;
            position: absolute;
            bottom: 10px;
            right: 0px;
            width: 60px;
            height: 60px;
            display: none;
        }

        @keyframes animate {
            0% {
                transform: translateY(0) rotate(0deg);
                opacity: 1;
                visibility: visible;
            }

            100% {
                transform: translateY(-50px) rotate(20deg);
                opacity: 0;
                visibility: hidden;
                display: none;
            }
        }
    }
    .user-info-btn {
        // margin-right: 20px !important;
        // @include desktop {
        //     margin-right: 25px !important;
        // }
        .icon {
            font-size: 20px;
            font-weight: normal !important;
            color: #37475b !important;
            @include desktop {
                font-size: 20px;
            }
        }
    }
    .help-icon-info {
        display: none;
        @include desktop {
            // margin-right: 35px !important;
            display: block;
            cursor: pointer;
        }
        .icon {
            font-size: 22px;
            font-weight: 500 !important;
            color: #37475b !important;
        }
    }
    .full-shop-btn {
        width: auto;
        padding: 10px 20px;
        line-height: 1;
        height: auto;
        background-color: $primary;
        background-color: var(--primary);
        color: white;
        margin-left: 15px;
        margin-right: 10px;
    }
    &.transparent {
        .full-shop-btn {
            background-color: transparent;
            border: 2px solid white;
        }
    }
    .icon-question-mark {
        width: 25px;
        height: 25px;
        font-size: 13px;
        line-height: 26px;
        border-radius: 50%;
        display: inline-block;
        vertical-align: text-bottom;
        box-shadow: inset 0 0 0px 2px #004f59;
        text-align: center;
        text-indent: 1px;
    }
    @include iphone5 {
        padding: 0;
        padding-left: 5px;
        padding-right: 5px;
    }

    .help-btn {
        text-decoration: none;
        width: auto;
        display: inline-flex;
        align-items: center;
        margin: 0 5px;
        .h-inner {
            font-size: 13px;
            text-transform: uppercase;
            font-weight: bold;
            border: 1.8px solid var(--primary);
            height: auto;
            line-height: 1.1;
            padding: 3px 4px 2px 4px;
            border-radius: 4px;
        }

        @include desktop {
            margin-right: 10px;
        }
        &:focus,
        &:active {
            outline: none;
            box-shadow: none;
        }
    }
    // .cancel-order{
    //     @media (max-width: 991px) {
    //         display: none;
    //     }
    // }

    .right-btn-container {
        &.anim-1 {
            .cart-btn-info {
                .animate-stars {
                    display: block;
                }
            }
        }
    }
    .right-btn-container {
        &.anim-2 {
            .cart-btn-info {
                .animate-stars2 {
                    display: block;
                }
            }
        }
    }
}
