.my-orders-page-new{
  .orders__main {
    margin-bottom: 50px;
  }
  padding-top: calc(#{$navbarHeight} + 3rem);
  min-height: 100vh;
  .order-card-wrapper{
    margin-bottom: 3rem !important;
    .oc-date {
      @include notDesktop{
        font-size: 12px !important;
      }
      padding: 8px 0 !important;
    }
  .icon-link-open {
    color: #31C0C8;
  }
  .order-card .order-card-header {
    .oct-line{
      .order-status{
          color: #68D385;
          text-transform: uppercase;
          font-weight: bold !important;

      }
      &.oct-upper{

      }
    }
      // .oct-line.oct-upper p:last-child {
      //   background-color: red;
      //   color: #68D385 !important;
      //   text-transform: uppercase;
      //   font-weight: bold !important;
      // }
    }
    .order-card-body{
      @include desktop{
        // padding-left: 15px !important;
        // padding-right: 15px !important;
      }
    }
    .btn-link:hover{
      text-decoration: none !important;
      cursor: pointer;
    }
    .oc-credits{
      @include desktop{
          font-size: 14px !important;
    }
  }
    .order-card-footer{
      padding:  1rem !important;
      @include desktop{
        padding-bottom: 15px !important;
        // padding-left: 15px !important;
        // padding-right: 15px !important;

        button {
          font-size: 14px !important;
      }
      }
    }
  .oct-line:last-child{
    margin: 4px 0;
    @include desktop{
      font-size: 14px !important;
    }
    .icon-stopwatch{
      padding-right: 4px;
      @include notDesktop{
        font-size: 11px;
      }
    }
  }
}
}