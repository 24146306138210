.order-complete-popup {
  width: 100%;
  height: 100%;
  background-color: $modalBGColor;
  h5 {
    font-size: 20px;
  }

  .modal-dialog {
    // height: 100%;
    height: 640px !important;
    // min-height: 100vh;
    // top: 200px;
    .modal-content {
      position: relative;
      overflow: hidden;
      border-radius: 20px 20px 0 0;
      border: none;
      @include desktop {
        border-radius: 20px;
      }
      .service-rating-popup,
      .rate-product-message-popup {
        position: absolute;
        transition: 0.5s;
      }
      .left-aligned {
        transform: translateX(-102%) scale(0.7);
      }
      .center-aligned {
        transform: translateX(0);
      }
      .right-aligned {
        transform: translateX(102%) scale(0.7);
      }
    }
    @include desktop {
      width: 450px;
      min-height: auto;
      max-width: 100% !important;
      height: 640px !important;
      top: auto;
    }
  }
}
.loader-buttin-primary {
  .loader {
    span {
      background-color: $primary !important;
    }
  }
}
@keyframes scale-up {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.service-rating-popup {
  width: 100%;
  height: 100%;
  flex: none;
  position: relative;
  .a-button {
    position: absolute;
    right: 10px;
    top: 20px;
    // color: #fff;
    z-index: 1;
    // transform: translate(100%, -100%);
  }
  p {
    font-size: 14px;
  }
  .src-body {
    width: 100%;
    // height: calc(100% - 70px);
    padding: 0rem 1rem;
    overflow: auto;
    margin-top: 205px;
    border-radius: 12px;
    background: #fff;
    .srp-info {
      text-align: center;
      // margin-bottom: 1rem;
      h5 {
        // margin: 1rem 0;
      }
      .order-id {
        margin: 0;
        span {
          color: var(--ink-color);
        }
        i {
          font-size: 12px;
          color: var(--ink-color);
        }
      }
      .srp-request {
        // width: 70%;
        margin: 0 auto;
        color: var(--gray-text-color);
        font-weight: 600;
        & + p {
          font-weight: 600;
        }
      }
      .img-wrapper {
        background: var(--primary-dark);
        padding: 20px 0;
        margin: -25px -16px 0;
        min-height: 230px;
        position: absolute;
        width: 100%;
        top: 20px;
        z-index: -1;
        img {
          height: auto;
          width: 300px;
        }
      }
      .srp-info-img {
        position: absolute;
        left: 50%;
        top: 50px;
        transform: translate(-50%);
      }
    }
    .review-card-wrapper .stars-wrapper {
      i {
        font-size: 33px;
      }
    }
  }
  .src-footer {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    button {
      padding: 5px 35px;
    }
  }
}
.rate-product-message-popup {
  width: 100%;
  height: 100%;
  flex: none;
  position: relative;
  padding: 0 1rem;
  &.above-section {
    .rpmp-thank-you {
      height: 100%;
    }
    .pmp-other-review {
      height: 0;
      display: none;
    }
  }
  &.spilt-section {
    .rpmp-thank-you {
      height: 68%;
      border-bottom: 1px solid #cccbcb;
    }
    .rpmp-other-review {
      height: 32%;
    }
  }
  .a-button {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 1;
  }
  .rpmp-thank-you {
    display: flex;
    justify-content: center;
    align-items: center;
    .thank-you-content {
      text-align: center;
      h5 {
        margin-top: 2.5rem;
      }
      .fs-16 {
        font-size: 16px !important;
      }
      p {
        width: 70%;
        margin: 1.5rem auto;
      }
      button {
        padding: 8px 35px;
      }
      img{
        margin: 0 auto;
      }
    }
  }
  .rpmp-other-review {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      margin-bottom: 20px;
      @include desktop {
        margin-bottom: 2rem;
      }
    }

    div {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      @include desktop {
        flex-direction: row;
      }
      button {
        background-color: transparent;
        border: 1px solid #d6d6d6;
        border-radius: 10px;
        padding: 12px 16px;
        margin: 0 0.5rem;
        width: 65%;
        @include desktop {
          width: auto;
        }
        &:nth-child(1) {
          margin-bottom: 10px;
          @include desktop {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
.signature-new-popup {
  width: 100%;
  height: 100%;
  flex: none;
  position: relative;
  padding: 0 1rem;
  .signature-new-body {
    height: calc(100% - 70px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    .signature-popup-content {
      text-align: center;
      margin-bottom: 2rem;
      .order-id {
        margin: 1rem 0;
        span,
        i {
          color: var(--ink-color);
        }
      }
    }
    .signature-popup-pad-wrapper {
      .sign-pad-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          margin: 0;
        }
        button {
          color: var(--ink-color);
          font-size: 14px;
          outline: none !important;
          box-shadow: none !important;
        }
      }
      .sign-pad-main {
        border: 1px solid #d6d6d6;
        border-radius: 5px;
      }
    }
  }
  .signature-new-footer {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #f1f1f1;
    button {
      padding: 5px 35px;
    }
  }
}

.notdefault .service-rating-popup .src-body .srp-info .img-wrapper {
  background: #37475b;
}
.order-complete-popup-old {
  .src-body {
    margin-top: 115px !important;
  }
  .modal-dialog {
    height: 555px !important;
  }
}
