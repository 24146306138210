.order-tracking {
	.car {
		height: auto;
		width: 90px;
		margin-right: 5px;
		@include desktop{
			margin-right: 0px;
		}
	}
	position: fixed;
	bottom: 15px;
	z-index: 999;
	background: #fff;
	box-shadow: 0px 2px 9px 0 rgba(0, 0, 0, 0.25);
	padding: 6px;
	border-radius: 5px;
	left: 15px;
	right: 15px;
	display: inline-flex;
	&.rating-widget {
		align-items: center;
		padding: 1rem 0.5rem;

		@include notDesktop(){
			flex-direction: column;
			text-align: center;
		}

		.order-issue-link{
			padding-left: 10px;
			padding-right: 10px;
			line-height: 1.2;
			font-size: 13px;
			color: #797979;

			@include notDesktop(){
				font-size: inherit;
			}

			a{
				display: inline-block;
			}
		}
		.rw-close-btn {
			position: absolute;
			right: 2px;
			top: 2px;
			padding: 0;
			height: 25px;
			width: 25px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: transparent;
			border: 0;
			i {
				font-size: 8px;
			}
		}
		span {
			padding: 0 10px!important;
		}
		.lottie-car {
			height: 60px;
			width: 60px;
			@include desktop {
				height: 160px;
				width: 160px;
			}
		}
		.o-title {
			margin: 0;
			font-weight: 400;
			margin: 0 !important;
			margin-bottom: 5px;
			@include desktop {
				text-align: center;
			}
		}
		img{
			height: 50px;
			width: 50px;
			@include desktop{
				margin-bottom: 1rem;
			}
		}
		.btn {
			padding: 0;
			text-align: left;
			padding: 2px 6px;
			margin-top: 0.5rem;
			
			@include desktop{
				margin-top: 1rem;
				padding: 5px 6px;
			}
			@include desktop {
				width: 100%;
				text-align: center;
				// padding: 6px;
			}
		}
	}
	span {
		padding: 5px 0 0 10px;
		.o-title {
			margin-bottom: 5px;
			color: #474747;
		}
		.btn {
			font-size: 14px;
			&:focus,
			&:active,
			&:active:focus,
			&:not(:disabled):not(.disabled).active:focus {
				box-shadow: none !important;
			}
		}
	}
	@include desktop {
		width: 230px;
		left: auto;
		bottom: 15px;
		padding: 15px;
		padding-bottom: 5px;
		right: 15px;
		border-radius: 8px;
		flex-direction: column;
		justify-content: center;
		box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.16);
		align-items: center;
		//background: var(--primary-light-50);
		background: #fcfcfc;
		.lottie-car {
			height: 115px;
			width: 167px;
		}
		span {
			padding: 0;
			display: flex;
			flex-direction: column;
			align-items: center;

			.o-title {
				display: block;
				margin-bottom: 10px;
				color: #474747;
				font-size: 16px;
				//margin-right: 20px;
			}
		}
		.track-btn {
			width: 80%;
			white-space: nowrap;
		}
	}
	& ~ .filter-btn {
		bottom: 108px !important;
	}
	@include real-iphone5 {
		span .btn {
			font-size: 11px;
		}
	}
}
.text-red,
.text-red:hover {
	color: #fe3838;
}
