%addressBar {
	span {
		// width: auto;
		// max-width: 100%;
		position: relative;
		padding-right: 25px;
		font-size: 14px;
		text-align: center;
		@include desktop {
			text-align: left;
		}
	}
	.d-icon {
		// 	display: inline-block !important;
		// position: absolute;
		// right: 0;
		// font-size: 18px;
		// top: 2px;
		// margin: 0px 0 0 0px;
		// transform: rotate(90deg);
	}
}

.new-header {
	background-color: #f7f7f7;
	// height: auto;
	flex-direction: column;
	align-items: stretch;
	padding: 0;
	// transition:none;
	transition: top 0.5s ease-in-out;
	&.bg-transparent {
		background-color: transparent;
	}
	&.bg-white {
		background-color: #ffffff;
	}
	@include desktop {
		height: var(--navbar-height);
	}
	.main-logo {
		height: 56px;
		@include desktop {
			height: 72px;
		}
		padding-left: 40px !important;
		@include iphone5() {
			padding-left: 18px !important;
		}
	}
	.cart-item-count {
		background-color: var(--primary);
		color: white !important;
	}
	.n-container {
		// height: $navbarHeight;
		// padding: 0.5rem 0px 0px 0px;
		position: relative;
		flex: 1;
		flex-direction: row;
		align-items: stretch;
		@include xDesktop {
			max-width: 1600px;
		}

		//Check this with UI team
		.delivery-type-toggle {
			display: inline-block;
			vertical-align: sub;
			transition:
				opacity 300ms,
				transform 300ms;
			.btn {
				width: auto;
				min-width: 88px;
				height: 36px;
				padding: 5px;
				line-height: 1;
				font-weight: 500;
				@include desktop {
					font-weight: 500;
				}
				font-size: 14px;

				&:not(.not-selected) {
					display: none;
				}
				&:first-child {
					border-radius: 4px 0 0 4px;
				}
				&:last-child {
					border-radius: 0px 4px 4px 0px;
				}
				&:focus {
					box-shadow: none;
				}
			}
			@include desktop {
				margin: auto 0px;
				.btn {
					border: 1px solid #ebddff;
					padding: 5px 10px;
					&.d-selected {
						color: var(--white-text-on-primary);
						background-color: var(--primary);
						border: 1px solid var(--primary);
					}
					&.not-selected {
						// color: #000;
					}
					&:not(.not-selected) {
						display: inline-block;
					}
				}
			}
		}

		.delivery-specific-text {
			.icon-close {
				display: none;
			}
			.asap-on {
				margin-left: 10px;
				i {
					display: none;
				}
				.standard,
				.priority,
				.next-slot {
					color: #999;
					font-weight: 600;
					font-size: 12px;
					line-height: 15px;
					.standard-time,
					.priority-time {
						color: #616161;
						font-weight: 700;
						font-size: 14px;
						line-height: 17px;
					}
				}
			}
			// .schedule-info{
			// 	margin-left: 15px;
			// 	.next-slot{
			// 		color: #999;
			// 		font-weight: bold;
			// 		font-size: 12px;
			// 		.next-slot-time{
			// 			color: #616161;
			// 			font-weight: 700;
			// 			font-size: 14px;
			// 		}
			// 	}
			// 	.time-table{
			// 		font-size: 12px;
			// 		color: #05A0A8;
			// 		text-decoration: underline;
			// 	}
			// }
		}
		.f-sec {
			padding: 3px;
		}
		.delivery-timing {
			display: none;
			@include desktop {
				display: block;
			}
		}
		.s-sec {
			display: none;
		}
		.s-sec {
			justify-content: right !important;
			padding: 0 1rem;
			margin: auto 0px;
			.s-sec-i {
				background-color: #fff;
				width: 240px;
				padding: 0px 9px;
				max-width: 300px;
				border-radius: 7px;
				@include desktop {
					padding-right: 8px;
				}
				@include ipad() {
					padding: 0 0.5rem;
					padding-left: 10px !important;
				}

				.delivery-text {
					display: block;
					color: #555;
					font-weight: normal;
					font-size: 14px;
					line-height: 1.2;
				}
				.bento-address {
					color: #333 !important;
					display: flex;
					align-items: center;
					.add-field {
						color: #333;
					}
				}
				.icon-pin {
					color: #333;
				}
				.set-delivery-address-btn {
					width: 100% !important;
					i {
						font-weight: 600;
						font-size: 20px;
					}
					.add-field {
						padding-right: 0;
						font-size: 14px;
						margin-left: 3px;
						// overflow: hidden;
						// text-overflow: ellipsis;
					}
				}
			}
		}
		.delivery-type-toggle + div[class*="address-section"] {
			@include ipadPro() {
				width: 240px;
				max-width: 300px;
			}
		}
		.t-sec {
			display: none;
			align-items: center;
		}
		.t-search-bar {
			border-radius: 3px;
			overflow: hidden;

			.btn {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				// background-color:grey;
				height: auto;
				line-height: 40px;
				border: 0;
				// border-radius: inherit;
			}
			input {
				// border-radius: inherit;
			}
		}
		* {
			// color: var(--primary);
		}
		.set-delivery-address-btn {
			// width: calc(100vw - 2rem - 50px);
			cursor: pointer;
		}
		.top-filter-btn {
			position: relative;
			right: -12px;
		}
	}
	.delivery-info {
		// min-height:27px;
		// height: var(--delivery-info-height);
		// padding: 3px;
		background-color: #899090;
		text-align: center;
		color: white;
		span {
			display: block;
			padding: 2px;
		}
	}
	@include desktop {
		.n-container {
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			padding-top: 0;
			.f-sec {
				margin: 0;
			}
			.s-sec {
				display: block;
				flex: 1;
				padding: 0 2rem;
				padding-left: 24px !important;
				.t-search-bar {
					// max-width: 600px;
					// width: 90%;
					margin-left: auto;
				}
			}
			.s-sec-i {
				display: flex;
				justify-content: center;
				align-content: center;
				background: #eeeeee;
				padding: 0 8px 0 0;
				padding-left: 0px !important;
				border-radius: 5px;
				width: 600px;
				@include ipadPro {
					width: 550px;
				}
				.t-search-bar {
					// max-width: 600px;
					flex: 0 0 50%;
					// width: 90%;
					position: relative;
					input {
						height: 35px;
						pointer-events: none;
						font-size: 13px;
						border: none;
						background: transparent;
						padding-left: 32px;
						color: #797979;
					}
					i {
						position: absolute;
						left: 10px;
						top: 10px;
						color: #797979;
					}
					&:after {
						content: "";
						right: 12px;
						width: 1px;
						background: #c3c3c3;
						position: absolute;
						top: 6px;
						bottom: 6px;
					}
				}
				.set-delivery-address-btn {
					width: 50%;
					// width: 370px;
					display: flex;
					align-items: center;
					span,
					i {
						color: #797979;
					}
					.d-icon {
						top: 1px;
					}
				}
			}

			.t-sec {
				display: flex;
				padding-right: 10px;
				.set-delivery-address-btn {
					width: auto;
					max-width: 300px;
				}
			}
			.right-btn-container {
				position: static;
			}
		}
	}
	.right-btn-container {
		.help-btn {
			// margin-right: 5px !important;
			// margin-left: 0px !important;
			i {
				color: var(--primary) !important;
				font-size: 20px;
			}
		}
		.timer-sec {
			@include ipadPro() {
				width: auto;
				min-width: auto;
				margin-right: 10px !important;
				margin-left: 10px;
			}
			@include ipadProLandscape() {
				width: auto;
				min-width: auto;
				margin-right: 10px !important;
				margin-left: 10px;
			}
			@include ipadLandscape() {
				width: auto;
				min-width: auto;
				margin-right: 10px !important;
				margin-left: 10px;
			}
			.timer-head-wrapper {
				@include ipadPro() {
					flex-direction: column;
					padding-right: 12px;
				}
				@include ipadProLandscape() {
					flex-direction: column;
					padding-right: 12px;
				}
				@include ipadLandscape() {
					flex-direction: column;
					padding-right: 12px;
				}
				.cc-info {
					@include ipadPro() {
						position: absolute;
						right: 0;
						top: 11px;
					}
					@include ipadProLandscape() {
						position: absolute;
						right: 0;
						top: 11px;
					}
					@include ipadLandscape() {
						position: absolute;
						right: 0;
						top: 11px;
					}
				}
			}
			.hh-tooltip .hht-sec {
				@include ipadPro() {
					padding: 5px 0;
				}
				@include ipadProLandscape() {
					padding: 5px 0;
				}
				@include ipadLandscape() {
					padding: 5px 0;
				}
			}
		}
	}
	&.minimal-navbar {
		//--navbar-height:91px;
		.delivery-info,
		.s-sec,
		.t-sec {
			//display:none;
		}
	}
	.set-delivery-address-btn {
		@extend %addressBar;
	}
	&.hide-features {
		.s-sec,
		.t-sec {
			display: none;
		}
	}
	.h-login-btn {
		height: 36px;
		width: auto;
		margin-left: 15px;
		line-height: 1.5;
		padding: 7px 15px;
		border-radius: 5px;
		background-color: var(--primary);
		color: white !important;
		font-size: 14px;
	}
	@include desktop {
		// box-shadow: 0 1px 0px rgb(0 0 0 / 10%);
	}
	.t-filter-indicator {
		position: relative;
		&:after {
			content: "";
			width: 10px;
			height: 10px;
			background-color: #f44336;
			border-radius: 50%;
			position: absolute;
			right: 2px;
			top: 11px;
		}
	}
	&.hide-header {
		top: -100%;
		transition: top 0.5s ease-in-out;
	}
	.delivery-specific-text {
		display: none;
		@include desktop {
			display: block;
		}
	}
}
.secondary-navbar {
	margin-top: var(--navbar-height);
	// margin-top: 140px; //for happy hours
	background-color: #fff;
	// padding: 5px 1rem 5px;
	// padding-bottom: 0;
	padding: 0 15px;
	position: relative;
	flex: 1;
	flex-direction: column;
	align-items: stretch;
	justify-content: center;
	// box-shadow: inset 0 0 1px 0px $dividerColor, inset 0 1px 1px 0px $dividerColor;
	.f-sec {
		padding: 3px;
		margin-bottom: 9px;
	}
	.t-sec {
		// min-height:50px;
		display: flex;
		align-items: center;
	}
	* {
		color: var(--primary) !important;
	}
	.t-search-bar {
		border-radius: 8px;
		overflow: hidden;
		.btn {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			background-color: grey;
			height: auto;
			line-height: 1;
			border: 0;
		}
	}
	* {
		color: white;
	}
	.set-delivery-address-btn {
		width: auto;
		overflow: hidden;
		flex-grow: 1;
		background: #f2f1f6;
		padding: 10px 6px;
		border-radius: 10px;
		span {
			padding-left: 5px;
			padding-right: 5px;
			display: inline-flex;
			color: #efefef !important;
			justify-content: center;
			.add-field,
			.icon-pin {
				color: #000 !important;
			}
		}
		i {
			color: #797979 !important;
		}
	}
	.top-filter-btn {
		position: relative;
		right: -12px;
		width: 50px;
		height: 50px;
		font-size: 2em;
	}
	@include desktop {
		display: none !important;
		* {
			display: none;
		}
	}
	&.minimal-navbar {
		display: none !important;
	}
	.set-delivery-address-btn {
		@extend %addressBar;
	}
	&.profile {
		padding: 0;
	}
}

.render-address {
	color: #000;
	font-weight: 500;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 300px;
	@include desktop {
		max-width: 200px;
	}
}
