.address-box {
    cursor: pointer;
    top: 60px;
    // top: 15%;

    position: absolute;
    width: 100%;
    padding: 0 20px;
    .icon-calendar,.icon-home {
        font-size: 1.6em;
    }
    a {
        width: 100%;
        display: flex;
        background-color: white;
        text-decoration: none;
        color: black;
        //padding: 10px;
				padding: 4px 7px;
        border-radius: 3px;
        align-items: center;
        min-height: 35px;

        .flex-item {
            flex-grow: 1;
            width: 40px;
        }
        .icon-down {
            font-size: 1.3em;
        }
        .adddress {
            width: calc(100% - 40px);
            padding: 0 10px;
			padding-left:0;
            font-size: 0.9em;

            display: -webkit-box !important;
            -webkit-line-clamp: 2;
            -moz-line-clamp: 2;
            -ms-line-clamp: 2;
            -o-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
            max-height: 38px;
            @include iphone5 {
                max-height: 34px;
            }
        }
    }
    @include desktop {
        position: static;
        padding: 0;
        width: 550px;
        margin:auto;
		    a{
                padding: 10px;
			}
    }
}
