$desktop-width: 992px;

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}
:root {
  --navbar-height: calc(64px + var(--delivery-info-height));
  --primary-light: var(--primary);
  --primary-dark: var(--primary);
}

body.notdefault {
  .payment-offer-parent {
    background: var(--primary);
    * {
      color: white;
    }
  }
  .filters-block {
    border: 1px solid #E6E6E6;
    width: max-content;
    font-size: 16px !important;
    font-weight: 600;
    padding: 0 15px;
    line-height: 40px;

    @include desktop(){
      position: relative;
    }

    .filter-count{
      background: var(--primary);
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      border-radius: 50%;
      width: 28px;
      height: 28px;
      display: inline-block;
      margin-left: 8px;
      line-height: 28px;
      text-align: center;
    }

    .filter-dropdown{
      @include desktop(){
        position: absolute;
        
      }
    }
  }
  .freight-limit {
    display: none !important;
  }

  .chat-btn {
    margin-right: 10px;
  }

  .descriptive-text {
    display: none;
  }

  .driver-rating {
    .top-circle {
      background: var(--primary);
    }
  }

  .delivery-tabs.mobile-only.n-delivery-tabs .m-item.t-selected {
    background: var(--primary-light-45);
  }

  .brands {
    padding-top: var(--navbar-height);

    .dtc-container {
      max-width: 100%;
      padding-left: 0 !important;
      padding-right: 0 !important;
      padding-bottom: 0 !important;
      > .row {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
      .p-lg-2 {
        padding: 0 !important;
      }
      .banner {
        margin-bottom: 0 !important;
      }
      .short-description {
        display: none;
      }
    }

    .banner .banner-inner {
      padding-bottom: 0px;
    }

    @include desktop {
      padding-top: 0;
    }
  }

  .product {
    .strain-container {
      background-color: #e3e3e3;
    }
  }

  .selected-filters {
    // display: none;
    .chip {
      // background-color: #e6e6e6;
    }

    .h-list-parent {
      .scroll-btn {
        background-color: var(--body-background-color);

        &:hover {
          background-color: var(--body-background-color);

          @include desktop {
            background-color: var(--body-background-color);
          }
        }
      }

      &:hover {
        .scroll-btn {
          @include desktop {
            background-color: var(--body-background-color);
          }
        }
      }
    }
  }

  &.isproductpage {
    .footer {
      padding-bottom: 75px;
    }
  }
  .product-page .add-to-cart-holder {
    bottom: 0px;
    right: 0px;
    background: var(--body-background-color);
    background: var(--body-background-color);
    @include desktop {
      // bottom: 12px;
      // right: 60px;
      background: none;
    }
  }

  .xy-product-offers-wrapper {
    background-color: transparent;
    border: 1px solid var(--primary);
  }
  .rate-review-pop-up {
    .selected-specific {
      background-color: var(--primary);
      color: var(--white-text-on-primary);
    }
  }
  .cart-widget-parent {
    &.pdp-product-widget {
      .cart-box {
        @include desktop {
          // right: 20px;
          // top: 0px;
        }
      }
    }
  }

  .navbar {
    .set-delivery-address-btn {
      padding-right: 130px;
    }
  }

  .bundle-list {
    .bundle-product {
      flex: none;
      margin: 0px;
      padding: 15px !important;
    }
  }
  .cann-product-fix {
    padding: 15px !important;
    margin-top: 0 !important;
    .product-tile-controls {
      display: block !important;
    }
    &:hover {
      transform: scale(1) !important;
    }
  }
  &.is-cart-page {
    // background-color: var(--body-background-color);

    // .customer-info .cart-user-details{
    //   background-color: var(--body-background-color);
    // }
    
    // .rounded-card{
    //   background-color: var(--body-background-color);
    // }

    // .customer-info .address-sticky{
    //   background-color: var(--body-background-color);
    // }

    // .cart .id-upload .medi-upload .medical-id-block{
    //   background-color: transparent;
    // }

    .id-upload .medical-id-block .img-container{
      margin-right: 10px;
    }

    // .cart .item{
    //   background-color: var(--body-background-color);
    // }
  }
  .brands {
    .info-sec {
      display: none;
    }
  }
  .tip-driver {
    .icon-close {
      background-color: #fff;
    }
  }

  .filters-dropdown-list {
    .custom-radio {
      display: block;
    }
  }
  .right-btn-container .cancel-order-btn {
    font-size: 16px;
    width: fit-content;
  }
  .order-details .map-section,
  .order-details .od-header-status {
    margin-top: 0px;
    @include desktop {
      margin-top: 60px;
    }
  }
  .profile-account {
    .my-referrals .refer-friend-section img,
    .empty-state img,
    .my-referrals .empty-holder img {
      filter: grayscale(1);
    }
  }
}
.notdefault .cart .cart-widget-concentrates {
  border: 1px solid #dedede !important;
}
.od-navbar .left-btn {
  line-height: 50px;
  height: 50px;
}
