.google-yelp-wrapper{
  background: #FFFFFF;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 30px 15px;
  margin-bottom: 20px;
  .rate-us-right-wrapper{
    width: 100%;
    margin-top: 20px;
    text-align: center;
    @include desktop{
      margin-top: 0;
      width: 385px;
      text-align: left;
    }
  }
  .rate-us-img{
    width: 260px;
    margin: 0 auto;
    @include desktop{
      margin-left: 70px;
    }
  }
  .google-yelp{
    img{
      width: 60px;
    }
    a{
      font-weight: 500;
      color: var(--primary);
    }
    .yelp-rate-us{
      margin-bottom: 25px;
    }
    .google-rate-us{

    }
  }
      
}