.confirmation-parent {
    --mapHeight: 400px;
    height: 100%;
    overflow: auto;
    padding-left: 0;
    padding-right: 0;
    & + .footer {
        //position: fixed!important;
    }

    .left-section {
        @include desktop {
            width: 800px;
            padding-right: 20px;
            position: sticky;
            top: calc(var(--navbar-height) + 15px);
            align-self: flex-start;
        }
    }
    .map-container {
        position: relative;
        height: 400px;
        overflow: hidden;
        @include desktop {
            height: 50vh;
        }
        .driver-location {
            position: absolute;
            right: 10px;
            bottom: 10px;
            @include desktop {
                bottom: 60px;
            }
            background: #ffffff;
            padding: 7px;
            border-radius: 3px;
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
            .s-text {
                font-size: 11px;
            }
            .time {
                font-size: 12px;
                color: $primary;
                color: var(--primary);
            }
        }
    }
    @include desktop {
        --mapHeight: 100vh;
        display: flex;
        height: auto;
        overflow: unset;
        padding-left: 15px;
        padding-right: 15px;
    }
    @include notDesktop {
        .map-container {
        }
        .hide-map {
            display: none;
        }
        .show-map {
            display: block;
        }
    }
    .order-not-exist {
        margin-top: 10rem;
        text-align: center;
        flex-basis: 100%;
        font-size: 16px;
        min-height: calc(100vh - 234px);
    }
    .success-message {
    padding: 15px;


        .confirmation-placed-msg{
            margin-left: -10px;
            margin-bottom: 15px;
            color: #333;
            display: flex;
            align-items: center;

            i{
                font-size: 18px;
                position: relative;
                top: -1px;
                margin-right: 8px;

                &.cancelled, &.failed{
                    color: #ff4500;
                }

                &.placed, &.delivered {
                    color: rgb(48, 177, 97);
                  }

                @include notDesktop(){
                    font-size: 15px;
                    margin-top: 5px;
                }

                .l-icon{
                    @include desktop(){
                        margin-top: 1px
                    }
                    @include notDesktop(){
                        margin-top: 2px;
                    }
                }
            }

            @include notDesktop(){
                font-size: 12px;
                padding-left: 6px;
            }
        }

        span {
            vertical-align: middle;
        }
        .l-icon {
            position: absolute;
            left: 15px;
            top: 18px;
            font-size: 20px;
        }
        .t-message {
            padding-left: 30px;
        }
        @include desktop {
            .t-message {
                font-size: 16px;
                padding-left: 35px;
            }
            border-bottom: 0px solid $dividerColor;
            padding: 15px 0;
            .l-icon {
                left: 0px;
                font-size: 25px;
                top: 15px;
            }
        }
    }
    .switch-payment {
        margin-left: 65px;
    }
    .payment-option-container {
      padding: 10px 0px;
      
      label{
        .fee-charge{
            font-weight: 500;
            color: var(--primary);
            font-size: 12px;
        }
        .sub-text{
            color: #717171;
            font-size: 12px;
        }
      }
    }
    .order-details-header {
        position: sticky;
        top: 70px;
        background: white;
        padding: 15px 15px 0;
        z-index: 2;
        font-weight: 700;
    }

    .selfie-block,
    .photo-id-block
     {
        width: 100%;
        padding: 15px;
        margin-top: 20px;
        cursor: pointer;
        @include desktop{
            width: 48%;
            padding: 15px;
            margin-top: 0px;
        }

        span{
            font-size: 12px;
        }
        .icon-u-card , .icon-u-selfie{
            font-size: 20px;
        }
        img{
            border-radius: 6px;
            object-fit: cover !important;
            height: 40px !important;
            width:40px !important;
            padding: 0px !important;
           
            @include desktop{
                width: 115px;
                height: 137px;
            }
        }
        .img-container{
            display: flex;
            align-items: flex-start;
            padding:8px;
            background-color: #F8F8F8;
            margin-top: 15px;
            border-radius: 6px;
            i{
                font-size: 20px;
                color: #4CAF50;
                border:none;
                padding: 0px;
                margin-bottom: 0px;
            }
        }
        .btn-amt{
            border:1px solid var(--primary);
            margin-right: 0px;
            padding: 5px 18px;
            border-radius: 6px;
            font-size: 14px;
            i{
                color:var(--gd-black-color);
            }
        }
        .btn-plus{
            display: flex;
            height: 30px;
            width: 30px;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            border: 1px solid var(--primary);
        }
    }
    .ledger-green-wrapper{
        width: 100%;
        padding: 10px;
        background-color: #f5f5f5;
        border: none;
        .medical-id-block{
            .img-container{
                background-color: #f5f5f5;
                margin-top: 5px;
                padding: 0;
                img{
                    object-fit: contain !important;
                }
            }
        }
        .license-text{
            line-height: 1.2;
            .sub-text{
              font-size: 12px;
              color: #717171;
              margin: 5px 5px 0 28px;
              line-height: 1.2;
              @include desktop(){
                margin-top: 0;
              }
            }
          }
    }

    .ledger-green-view-inner{
        .confirm{
            .modal-body{
              position: relative;
              .modal-title{
                position: absolute;
                top: -30px;
                left: 25px;
              }
            }
        }
    }

    .schedule-title{
        font-weight: 700;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-radius: 6px;
    }

    .referral-banner{
        margin-top: -15px;
        margin-left: 15px;
        margin-right: 15px;

        @include notDesktop(){
            margin-top: 40px;
        }
    }

    .bring-back{
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 99;
        padding: 20px 15px;
        background: #ffffff;

        @include desktop(){
            position: sticky;
            left: auto;
            right: auto;
        }
    }
        .apply-padding {
            @include desktop {
                padding-top: 70px;
            }
        }
}
.confirmation {
    .payment-offer-parent {
        background: linear-gradient(180deg, #36bfc5 0%, #71c273 100%) 0% 0% no-repeat;
        box-shadow: 0px 3px 6px #00000029;
        padding: 12px;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 22px;
        padding-right: 15px;
        h6,
        p {
            color: white;
        }
        .payment-offer-content {
            p {
                font-size: 12px;
                margin-bottom: 0;
                line-height: 1.25;
                @include desktop {
                    line-height: 1.4;
                }
            }
        }
        img {
            width: 90px;
        }
        @include desktop {
            border-radius: 5px;
        }
    }
    @include desktop {
        border: 1px solid #ddd;
        border-radius: 6px;
    }

    // box-shadow: 0 -4px 9px 0 rgba(0, 0, 0, 0.1);
    .id-upload {
        margin-left: -12px;
        margin-right: -12px;
    }

    .confirm-page-card-list {
        padding-left: 65px;
    }
    background-color: white;
    .invoice {
        .wallet-container {
            span {
                // vertical-align: baseline;
            }
            .icon-wallet,
            .icon-gift-thin,
            .icon-coin,
            .icon-jerrycan {
                font-size: 18px;
                margin-right: 5px;
            }
        }
    }
    padding-bottom: 60px;
    @include desktop {
        .firefox-fix {
            margin-bottom: 5rem;
        }
    }
    @include notDesktop(){
        box-shadow: none;
    }
    .cancel-order {
        padding-left: 0;
    }
    .pay-by-card-parent {
        border-radius: 0px;
        border: 1px solid $dividerColor;
        padding: 12px;
        @include desktop {
            padding: 15px;
        }
    }
    .pay-by-card {
        position: relative;

        padding-left: 65px;
        min-height: 55px;
        @include iphone5 {
            padding: 10px;
            padding-left: 65px;
        }
        .loader {
            margin-left: 18%;
        }
        img {
            position: absolute;
            left: 10px;
            right: 0;
            height: 45px;
            width: 45px;
            @include iphone5 {
                height: 45px;
                width: 45px;
            }
        }
        .btn {
            padding: 6px 30px;
            font-size: 1em;
        }
    }

    .price-box {
        margin-top: 15px;
        // margin-bottom: 20px;
    }
    .poduct-listing {
        margin-bottom: 15px;
    }
    .price {
        font-size: 1.7em;
        color: $primary;
        color: var(--primary);
    }
    .special {
        //font-family: "GothamPro", sans-serif;
        font-weight: 700;
        letter-spacing: 1px;
    }
    .invoice-divider {
        margin: 10px 0;
    }
    .driverContainer {
        margin: 20px 0 20px 0;
        .holder {
            background-color: #f1f1f1;
            border: 1px solid #d4d4d4;
            margin-top: 5px;
            .number {
                border: 1px solid #b5b5b5;
                padding: 5px;
                background-color: white;
                margin-top: 25px;
                @include iphone5 {
                    margin-top: 20px;
                }
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .phone {
                i {
                    margin-right: 5px;
                }
            }
        }
        .img-list-item {
            img {
                border-radius: 50%;
            }
            .item-name {
                padding-left: 15px;
                display: inline-block;
                width: calc(100% - 35px);
                vertical-align: middle;
                padding-left: 10px;
            }
        }
    }

    .re-order-btn {
        width: 100%;
        border-radius: 50px;
        color: white;
    }
    .confirm-order-rating {
        margin: 1rem 15px;
    }
    .thick-border {
        height: 10px;
        // margin: 0 15px;
        background-color: #e5e5e5;
    }
    @include desktop {
        //height: 100vh;
        flex: 0 0 500px;
        //overflow: auto;
        // padding-top: calc(var(--navbar-height) + 0px);
    }

    .poduct-listing .img-list-item {
        img {
            object-fit: contain;
        }
    }    
    .priority-indicator {
        background-image: linear-gradient(to right, #faebef, #ffdde1);
        
        padding-top: 9px;
        padding-bottom: 9px;
        padding-right: 9px;
        position: relative;
        padding-left: 50px;
        .c-text{
            color: #ee585d;
        }
        .pr-right-sec{
            color: #505050;
        }
        img{
            position: absolute;
            top:13px;
            // top: 50%;
            // transform: translateY(-50%);
            left: 10px;
        }
        .pr-flag{
           
            color: white;
            position: relative;
            top: -1px;
            margin-left: 5px;
            &.ontime{
                background: #4caf50;
            }
            &.late{
                background: #ff9800;
            }
        }
        border-radius: 6px;
        &.non-priority-indicator{
            background-image: none;
            background: #F5F6FA;
            .o-left-icon{
                position: absolute;
                left: 13px;
                font-size: 25px;
                top: 15px;
                color: var(--primary);
            }
            .main-time{
                color: #313131;
                margin-left: 6px;
            }
            padding-left: 9px;
            @include desktop{
                padding-left: 50px;
            }
        }
        &.s-line{
            .o-left-icon{
                left: 13px;
                font-size: 22px;
                top: 8px;
            }
        }
        &.no-arrival {
            img {
                top: 4px;
            }
        }
    }
    .predicted-eta-container{
        padding: 10px;
        position: relative;
        border:1px solid $dividerColor;
        border-radius: 5px;
        padding-left: 38px;
        margin-top: 1rem;
        .p-top-text{
            font-size: 16px;
        }
        i{
            position: absolute;
    top: 14px;
    left: 10px;
    color: #2196f3;
    font-size: 18px;
        }
    }
}

.tesla-confirmation{
    .tesla-confirmation-desktop{
        width: 100%;
        }
        
        width:100%;
        
        .tesla-confirmation-desktop {
        padding-top: calc(var(--navbar-height) + 10px);
        margin-bottom: 30px;
    }
    
    .mobile-tesla-banner{
        position: fixed;
        bottom: 0;
        z-index: 5;
        left: 0px;
        right: 0;
        
            img {
                width: 100%;
            }
        .btn{
            position: absolute;
            top: 5px;
            right: 9px;
            width: 26px;
            height: 26px;
            border-radius: 100px;
            background: rgb(255 255 255 / 68%);
            display: -moz-box;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-weight: bold;
        }
        &.t-push-up{
            bottom: 78px;
        }
    }
}
.bring-back{
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding-right: 30px;
    @include desktop{
        padding-right: 15px;
    }
}