.profile-account {
  background: #F2F1F6;
  padding-bottom: 8rem;
  // height: 100%;

  @include desktop() {
    padding-top: 8rem;
  }

  .wrapper {

    @include desktop() {
      display: grid;
      grid-template-columns: 15% 85%;
      gap: 85px;
      height: 100%;
      // overflow: hidden;
    }

    &.mobile {
      @include desktop() {
        display: none;
      }
    }

    &.desktop {
      display: none;

      @include desktop() {
        display: grid;
      }
    }

    .user-name {
      font-size: 24px;
      font-weight: 700;
      color: #333333;
      text-transform: capitalize;
    }
  }

  h3 {
    margin-bottom: 25px;
  }

  .text-gray {
    color: gray;
  }

  .tab-title {
    margin-bottom: 1rem;
    font-size: 16px;
    font-weight: 600;
  }

  .sub-title {
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 25px;
    color: #333333;
  }

  .semi-bold {
    font-weight: 600;
  }

  label {
    color: #555555;
    font-size: 14px;
    margin-bottom: 5px;
  }

  .card {
    background: #ffffff;
    // box-shadow: 0px 3px 16px #00000005;
    border: 1px solid #E8E8E8;
    border-radius: 12px;
    padding: 15px;
  }

  .curve-card {
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 14px;
    margin-top: 20px;
    box-shadow: -4px 0px 12px rgba(0, 0, 0, 0.02);

    @include desktop() {
      margin-top: 40px;
    }

    .header {
      background: var(--primary-light-50);
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      padding: 10px 15px 18px;
      height: inherit;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;

      h6 {
        color: var(--primary);

      }

      a {
        text-decoration: underline;
        font-size: 16px;
        cursor: pointer;
      }

      span[class^="icon-"] {
        color: var(--primary);
        font-size: 22px;
      }

      .order-status {
        font-size: 12px;
        color: #4FAF81;
        display: flex;
        align-items: center;

        i {
          font-size: 10px;
          display: inline-block;
          margin-right: 5px;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          line-height: 13px;
          text-align: center;

          &::before {
            font-size: 8px;
            color: #FFFFFF;
          }

          &.placed {
            background: var(--primary) !important;
          }

          &.cancelled {
            background: #AF4F4F !important;
          }

          &.delivered {
            background: #4FAF81 !important;
          }

          &.failed {
            background: #DD5A11 !important;
          }

          &.refunded {
            background: rgb(45, 186, 230);
          }
        }
      }

      .address-name {
        display: flex;
        align-items: center;
        color: #999999;

        span {
          font-size: 20px;
          display: inline-block;
          margin-right: 8px;
          color: #616161;
        }
      }
    }

    .inner-card {
      border: 1px solid #E8E8E8;
      box-shadow: -4px 0px 12px rgba(0, 0, 0, 0.05);
      border-radius: 12px;
      padding: 15px 15px 10px;
      font-size: 16px;
      margin-top: -10px;
      background: #FFFFFF;

      @include desktop() {
        padding: 20px;
      }

      .btn-holder {
        button {
          background: transparent;
          padding: 0;
          border: 0;
          color: var(--primary);
          font-size: 22px;
          margin-left: 10px;
          margin-right: 10px;
        }
      }

      span[class^="icon-angle"] {
        color: #37475B;
        font-size: 22px;
        font-weight: bold;
      }
    }

    &.default-card {
      border-color: var(--primary);

      .header {
        background: var(--primary);
        color: #ffffff;

        h6 {
          color: #FFFFFF;
          font-size: 14px;

          @include desktop() {
            font-size: 16px;
          }
        }

        span[class^="icon-"] {
          font-size: 20px;
          color: #FFFFFF;
        }

        .address-name {
          color: #FFFFFF;

          span {
            color: #FFFFFF;
          }
        }

        .order-status {
          color: #FFFFFF !important;

          i {
            background: #FFFFFF !important;

            &.placed {
              &::before {
                color: var(--primary) !important;
              }
            }

            &.cancelled {
              &::before {
                color: #AF4F4F !important;
              }
            }

            &.delivered {
              &::before {
                color: #4FAF81 !important;
              }
            }

            &.failed {
              &::before {
                color: #DD5A11 !important
              }
            }

            &.refunded {
              &::before {
                color: rgb(45, 186, 230) !important
              }
            }
          }
        }

        .link {
          color: #FFFFFF;
        }
      }

      .inner-card {
        border-color: var(--primary);
      }
    }
  }

  .confirm {
    background-color: rgba(0, 0, 0, 0.2);

    .icon-close {
      font-size: 15px;
    }

    .text-secondGray {
      white-space: normal;
      font-size: 14px;
      color: #333333;
    }
  }

  .link-btn {
    font-weight: 400;
    font-size: 16px;
    padding: 0;
    border: 0;
    color: var(--primary);
    text-decoration: underline;
    background: transparent;
  }

  .card-data {
    background: #FFFFFF;
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    padding: 15px;
    position: relative;
  }

  .profile-content-holder {
    padding-top: 75px;

    @include desktop {
      max-width: 560px;
      padding-top: 0;
    }

    .accordion {
      border-radius: 0;
      border: 0;

      .accordion-item {
        .title {
          background: #FFFFFF;
          color: #616161;
          font-size: 16px;
          font-weight: 600;
          position: relative;
          padding: 6px 0 15px 40px;
          border-radius: 0;
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;

          &::before {
            position: absolute;
            font-family: "icomoon" !important;
            color: var(--primary);
            left: 5px;
            top: 1px;
            font-size: 22px;
          }

          &::after {
            content: "\f107";
            font-family: "icomoon" !important;
            font-size: 22px;
            color: #063A3F;
            position: absolute;
            right: 5px;
            top: 1px;
          }
        }

        &.active {
          .panel {
            max-height: max-content;
          }

          .title {
            &::after {
              content: "\f106";
            }
          }
        }

        .card {
          border-radius: 0;
          border: 0;
          padding-top: 0;
        }
      }
    }

    .user-names {
      .i-inner {
        position: relative;

        .verify {
          width: auto;
          top: 8px;
        }
      }
    }
  }

  .profile-pic {
    position: relative;
    margin-top: 20px;

    .edit {
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
      width: 35px;
      height: 35px;
      border-radius: 50%;
      position: absolute;
      right: 5px;
      background: #ffffff;
      padding: 0;

      span {
        color: var(--primary);
        font-size: 16px;
        margin-top: 8px;
        display: inline-block;
        margin-right: -2px;
      }
    }
  }

  .profile-sidebar {

    // display: none;
    .menu-items-holder {
      display: grid;
      grid-template-columns: auto auto;
      gap: 20px;
      margin-bottom: 30px;
      border-bottom: 1px solid #EDEDED;
      padding-bottom: 20px;

      @include desktop() {
        display: block;
        border-bottom: 0;
        padding-bottom: 0;
      }

      .menu-items {
        width: auto;
        height: 55px;
        border: 1px solid #C9CFD5;
        border-radius: 8px;
        background: #FFFFFF;
        color: $text-light;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        justify-content: flex-start;
        padding-left: 20px;

        @include desktop() {
          width: 160px;
          margin-bottom: 12px;
        }

        span[class^="icon"] {
          font-size: 20px;
          color: var(--primary);
          padding-right: 10px;
        }

        &.active {
          border-color: var(--primary);
          // background: var(--primary-light-50);
          color: var(--primary);

          span[class^="icon"] {
            color: var(--primary);
          }
        }

        &:hover {
          border-color: var(--primary);
        }
      }
    }

    @include desktop() {
      display: block;
      // height: max-content;
      // position: sticky;
      // top: 0px;

      // &.menu-sticky{
      //   position: sticky;
      //   top: 100px;
      //   z-index: 999;
      //   animation: 500ms ease-in-out 0s;

      //   // +.main-content{
      //   //   width: fit-content;
      //   //   margin-left: 275px;
      //   // }
      // }
    }

    .sidemenu-links {
      p {
        color: $text-lighter;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 20px;
      }

      a {
        color: #37475B;
        font-size: 14px;

        &:hover {
          text-decoration: none;
        }
      }

      .links {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #37475B;
        padding-bottom: 15px;
        border-bottom: 1px solid #c9cfd5;
        margin-bottom: 20px;

        a {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
        }
      }

      .logout {
        // background: transparent;
        font-size: 16px;
        min-width: 100px;
        height: 40px;
      }
    }
  }

  .my-info {
    border-bottom: 1px solid #c9cfd5;

    .accordion-item .title {
      // &::before{
      //   content: "\ea0b";
      // }

      // &::after{
      //   content: "\ea0b";
      // }
    }

    label,
    .label {
      color: #999999;
      font-size: 14px;
    }

    .accordion {
      position: relative;

      .my-info-icon {
        position: absolute;
        color: var(--primary);
        left: 5px;
        top: 8px;
        font-size: 20px;
        z-index: 1;
      }
    }

    .profile-pic {
      display: inline-block;

      img {
        width: 150px;
        height: 150px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
        cursor: pointer;
      }
    }

    .form-group {
      margin-bottom: 1rem;
    }

    .user-names {
      button {
        padding: 0;
        border: none;
        background: transparent;
        text-align: left;
        width: 100%;
      }

      &.display-user {
        @include desktop() {
          display: flex;
          align-items: flex-start;
        }

        >div {
          flex: 1;
        }
      }
    }

    .display-only {
      color: #333333;
      font-size: 15px;
      word-break: break-all;

      @include desktop() {
        width: 50%;
        display: inline-block;

        +.display-only {
          margin-left: 15px;
        }
      }
    }

    .verify-email {
      margin-bottom: 10px;
    }

    .email-input-parent {
      position: relative;

      .verify {
        position: absolute;
        padding: 0;
        top: 34px;
        right: 10px;
        color: #283cd5;
      }

      .inline-loader {
        top: 30px;
      }
    }

    .padding-10-vertical {
      padding-bottom: 0;
    }

    input:disabled {
      cursor: no-drop;
      background-color: #f8f8f8;
      opacity: 0.5;
    }

    button:disabled {
      cursor: no-drop;
    }
  }

  .my-documents {
    position: relative;
    margin-bottom: -20px;

    .accordion-item .title {
      margin-top: 10px;
      margin-bottom: 15px;

    }

    .upload-docs-section {
      border: 1px solid #E8E8E8;
      border-radius: 12px;
      position: relative;
      padding: 20px 15px;

      h6 {
        padding-left: 40px;
        margin-bottom: 0;
      }

      span[class^="icon-angle"] {
        font-size: 20px;
      }

      +.upload-docs-section {
        margin-top: 20px;
      }

      &:last-child {
        margin-bottom: 25px;
      }

      .custom-checkbox {
        input:checked+label:after {
          transform: rotate(-45deg) scale(1);
        }
      }

      .accordion .accordion-item .title {
        margin-bottom: 0;
        padding-bottom: 5px;

        &::after {
          content: "\e9f6";
          color: var(--primary);
        }
      }

      .empty-img {
        width: 110px;
        height: 85px;
        border: 1px dotted #c6c6c6;
        text-align: center;
        line-height: 85px;
        border-radius: 6px;
        position: relative;
        margin: 0 auto;

        label {
          width: 110px;
          height: 85px;
        }

        .icon-plus {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: var(--primary);
        }
      }

      .img-container {
        img {
          height: 100px;
          border-radius: 6px;
          object-fit: cover;
          width: 150px;
        }
      }
    }

    .doc-icon,
    .my-docs-icon {
      position: absolute;
      color: var(--primary);
      left: 5px;
      top: 25px;
      font-size: 22px;
    }

    .doc-icon {
      top: 18px;
      left: 15px;
    }

    .progress {
      height: 6px;
      border-radius: 6px;
    }

    .profile-pic {
      .edit {
        right: -20px;
        top: 25px;
      }
    }

    .card {
      +.card {
        margin-top: 40px;
      }

      [class^="icon-"] {
        font-size: 20px;
        margin-right: 8px;
      }

      .title {
        font-size: 18px;
        font-weight: 600;
      }

      .grey {
        color: #777777;
        font-size: 13px;
      }

      .upload-section {
        margin-top: -30px;
        text-align: center;
        min-height: 90px;

        .upload-img {
          background: #F4F9FF;
          border: 1px dashed #E8F0F9;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }

  .my-orders-tab {
    .all-products-slider {
      width: 100%;
      background: none;

      @include desktop() {
        width: 65vw;
        margin-left: -15px;
        margin-bottom: 0 !important;
      }

      .product {
        background: #FFFFFF;
        height: auto;
        margin-bottom: 8px;

        @include desktop() {
          margin-bottom: 20px;
        }
      }

      .h-list-parent .h-list {
        padding-bottom: 0;
      }
    }
  }

  .my-orders-tab {
    .order-card-wrapper {
      // border: 1px solid var(--primary);
      // background: #FFFFFF;
      // box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.05);
      // // border-radius: 12px;

      // .order-card{
      //   .order-card-header{
      //     background: var(--primary);
      //     border-top-right-radius: 12px;
      //     border-top-left-radius: 12px;
      //     h6{
      //       color: #FFFFFF;
      //     }
      //   }
      // }

      &.curve-card {
        @include desktop() {
          margin-top: 0px;
        }

        .header {

          h6 {
            font-size: 12px;
            display: flex;
            flex-direction: column;

            @include desktop() {
              font-size: 16px;
              display: block;
            }

            @include ipad() {
              font-size: 16px;
              display: block;
            }

            strong {
              font-size: 14px;

              @include desktop() {
                font-size: 16px;
              }
            }
          }
        }
      }

      +.order-card-wrapper {
        margin-top: 40px;
      }

      .order-card {
        border: none;

        .och-above {
          p {
            color: #616161;

            a:hover {
              text-decoration: none;
            }
          }
        }

        .order-card-footer {
          padding-top: 15px;
          padding-bottom: 15px;

          .checkout-btn {
            font-size: 16px;
            font-weight: 600;
          }

          .rate-button {
            height: 38px;
            font-size: 14px;
          }
        }

        .inner-card {
          border: none;
        }


        .order-issues {
          font-size: 12px;
        }

        .och-above {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 12px;

          i {
            color: var(--primary);
            font-size: 16px;
            margin-left: 8px;
          }
        }

        .och-below {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 20px;
          font-weight: 600;

          @include desktop() {
            align-items: center;
          }

          p {
            color: #616161;
            font-size: 14px;
          }

          .tag-name {
            color: #999999;
            font-weight: 400;
          }

          span {
            color: #616161;
          }

          i {
            color: var(--primary);
            font-size: 16px;
          }
        }
      }
    }

    &:first-child {
      margin-top: 0;
    }

    .empty-state {
      justify-content: center;

      img {
        height: 190px;

        @include desktop() {
          height: 230px;
        }
      }
    }

    .orders__main {
      @include desktop() {
        // max-height: 540px;
        overflow: scroll;
        padding-right: 4px;
      }

      @include ipad {
        padding-left: 80px;
        padding-right: 80px;
      }
    }

    .tab-title {
      @include desktop() {
        margin-bottom: 2rem;
      }
    }
  }

  .manage-address {
    .link-btn {
      margin-left: auto;
      display: block;

      @include desktop() {}
    }

    .sub-title {
      font-size: 16px;
      font-weight: 600;
      display: none;

      @include desktop() {
        display: block;
      }
    }

    .card {
      padding: 40px 20px;

      .address-item {
        .address-holder {
          display: flex;
          justify-content: space-between;
          align-items: unset;
          flex-direction: column;

          @include desktop() {
            flex-direction: row;
            align-items: center;
          }

          .address {
            font-size: 16px;
            color: #333333;
            display: flex;
            align-items: center;
            font-size: 16px;
            line-height: 19px;

            span {
              font-size: 22px;
              margin-right: 10px;
            }
          }
        }

        +.address-item {
          padding-top: 40px;
          margin-top: 20px;
          border-top-width: 1px;
          border-top-style: solid;
          border-image: linear-gradient(270deg,
              rgba(153, 153, 153, 0) 6.4%,
              #c6c2c2 50.09%,
              rgba(153, 153, 153, 0) 92%)1;
        }

        .edit {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          position: relative;

          .done-btn {
            height: 26px;
            border-radius: 6px;
            font-size: 16px;
            text-align: center;
            padding: 0;
            min-width: 90px;
            border: 1px solid var(--primary);
            color: var(--primary);
            font-weight: 600;
            min-width: 65px;

            .loader {
              padding-top: 0;
            }
          }

          .close {
            position: absolute;
            bottom: 130px;

            @include desktop() {
              bottom: 115px;
            }
          }
        }
      }

      .btn-holder,
      .edit {
        margin-top: 20px;
        min-width: 90px;
        position: relative;

        @include desktop() {
          margin-top: 0px;
        }

        button {
          padding: 0;
          border: none;
          background: transparent;

          span {
            font-size: 20px;
          }

          &.default {
            color: #283cd5;
          }

          .icon-close {
            font-size: 14px;
            color: #333333;
          }
        }
      }

      .address-type {
        button {
          border: 1px solid #E8E8E8;
          border-radius: 5px;
          background: #fff;
          padding: 8px 12px;
          min-width: 100px;
        }
      }
    }

    .update-address {
      border-top: 1px solid #e8e8e8;
      margin-bottom: 5px;
    }

    .btn-holder {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .done-outline-btn {
        height: 30px;
        border-radius: 6px;
        font-size: 16px;
        text-align: center;
        padding: 0;
        min-width: 90px;
        border: 1px solid var(--primary);

        &:hover {
          background-color: var(--primary);
          border-color: var(--primary);
          color: #FFFFFF;
        }
      }
    }

    .empty-state {
      justify-content: center;

      img {
        height: 190px;

        @include desktop() {
          height: 230px;
        }
      }

      .link-btn {
        margin-left: 0;
      }
    }
  }

  .my-wallet-section {
    .tabs {
      display: flex;
      background: #FFFFFF;
      height: 80px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      border: 1px solid #E8E8E8;
      overflow: hidden;

      button {
        flex: 1;
        background: transparent;
        border: none;
        font-size: 14px;
        color: #616161;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;

        span[class^="icon-"] {
          font-size: 24px;
          margin-right: 10px;
          color: var(--primary);
        }

        &.active {
          background: var(--primary);
          color: #FFFFFF;

          span[class^="icon-"] {
            font-size: 34px;
            color: #FFFFFF;
          }
        }

        .amount {
          font-size: 18px;
          font-weight: 600;
          margin-top: -5px;
        }
      }
    }

    .dark-color {
      color: #063A3F;
    }

    .card-data {
      margin-top: -10px;
      height: 500px;
      overflow-y: scroll;

      .offer {
        color: var(--primary);
        font-size: 14px;
        margin-bottom: 25px;

        div.d-flex {
          flex-direction: column;

          @include desktop() {
            flex-direction: row;
          }
        }

        a {
          color: var(--primary);
          text-decoration: underline;
        }
      }

      .transactions-holder {
        color: $text-light;

        .transactions {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &+.transactions {
            border-top: 1px solid #E8E8E8;
            margin-top: 20px;
            padding-top: 20px;
          }

          a {
            &:hover {
              text-decoration: none;
            }

            i[class^="icon-link"] {
              font-size: 15px;
              margin-left: 8px;
              color: var(--primary);
            }
          }
        }

        .id-holder {
          color: $text-lighter;
          margin-top: 4px;
        }

        .date {
          font-size: 12px;
          margin-bottom: 4px;
        }

        .amount {
          font-size: 16px;
        }
      }
    }

    .empty-state {
      height: 370px;
      display: flex;
      justify-content: center;

      @include desktop() {
        height: 400px;
        margin-top: 0;
        margin-left: -20px;
      }

      img {
        @include desktop() {
          height: 200px;
          margin-bottom: 3rem;
        }
      }
    }

    @include ipad() {
      padding-left: 80px;
      padding-right: 80px;
    }
  }

  .payment {
    width: 100%;

    ::-webkit-scrollbar-thumb {
      background: transparent;
    }

    @include desktop() {
      width: 850px;
    }

    @include combineIpad() {
      width: 700px;
    }

    .head-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @include desktop() {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }

    .sub-title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 8px;

      @include desktop() {
        margin-bottom: 0;
      }

      small {
        color: #616161;
      }
    }

    .add-more {
      background: transparent;
      border: none;
      padding: 0;
      color: #283cd5;
    }

    .no-account {
      color: #9c9c9c;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .curve-card {
      display: inline-block;
      margin-top: 30px;
      width: 100%;
      background: none;
      box-shadow: none;
      border: none;

      @include desktop() {
        margin-top: 20px;
        width: 360px;
        display: inline-block;
      }

      @include combineIpad() {
        margin-top: 20px;
        flex: 0 0 320px;
        display: inline-block;
      }

      +.curve-card {

        @include desktop() {
          margin-left: 20px;
        }

        @include combineIpad() {
          margin-left: 20px;
        }
      }
    }

    .payment-section {

      @include desktop() {
        // display: flex;
        // white-space: nowrap;
        // -webkit-flex-wrap: nowrap;
        // flex-wrap: nowrap;
        // overflow: auto;
        // -webkit-overflow-scrolling: touch;
      }

    }

    .payment-list {
      input:checked~.inner-card {
        border: 1px solid var(--primary);
      }

      label {
        position: absolute;
        top: 30px;
        left: 20px;

        &::before {
          top: 12px;
        }
      }

      .inner-card {
        width: 100%;
        padding: 20px 20px 10px;
        min-height: 110px;

        @include desktop() {
          width: 360px;
          display: inline-block;
        }

        @include combineIpad() {
          width: 320px;
          display: inline-block;
        }

        .details-section {
          color: #333333;
          display: flex;
          // padding-left: 35px;
          align-items: center;
          font-size: 14px;
          line-height: 1.4;
          white-space: normal;

          .logo {
            img {
              object-fit: contain;
              object-position: center;
              height: 55px;
              width: 100%;
            }
          }
        }
      }

      .bank-footer {
        color: #999999;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;

        img {
          height: 15px;
          margin-left: 6px;

          &[class*="kind"] {
            height: 25px;
          }
        }

        .btns {
          background: transparent;
          padding: 0;
          border: 0;
          color: var(--primary);
          font-size: 22px;
        }
      }

      &.others {
        display: block;

        @include desktop() {
          display: inline-block;
        }

        .inner-card .details-section {
          .logo {
            font-size: 35px;
            height: auto;
            color: var(--primary);
          }
        }
      }

      &.bank-linked {
        .inner-card .details-section {
          .logo {
            max-width: 55px;
            min-width: 55px;
            height: 55px;

            @include desktop() {
              max-width: 120px;
            }

            img {
              height: 100%;
            }
          }
        }
      }
    }

    .credit-debit-cards {
      .payment-list {
        .custom-checkbox {
          width: 100%;

          label {
            position: unset;
            display: flex;
            width: 100%;
          }

          .img-holder {
            height: 46px;
            border: 1px solid #EDEDED;
            border-radius: 5px;
            padding: 6px;
            width: 70px;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              object-position: center;
            }
          }
        }
      }
    }

    .no-payment-card {
      width: 100%;
      display: inline-block;
      background: #FFFFFF;
      border: 1px solid #E8E8E8;
      border-radius: 12px;
      padding: 20px;
      height: 125px;

      @include desktop() {
        width: 360px;
      }

      @include combineIpad() {
        width: 360px;
      }

      .gray-section {
        color: #999999;

        span[class^="icon"] {
          font-size: 50px;
        }

        .dots {
          color: #BCBCBC;
          margin-bottom: 6px;

          span {
            display: inline-block;
            height: 25px;
            line-height: 20px;
            font-size: 30px;

            @include desktop() {
              font-size: 36px;
            }

            +span {
              margin-left: 15px;
            }
          }
        }

        .line {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            width: 60px;
            height: 10px;
            background: #D7D5D5;
            border-radius: 10px;
            left: 0;
          }

          &::after {
            content: '';
            position: absolute;
            width: 100px;
            height: 10px;
            background: #D7D5D5;
            border-radius: 10px;
            left: 70px;
          }
        }
      }
    }

    .all-products-slider {
      background: transparent;
      margin-top: 0 !important;

      .h-list-parent .h-list {
        flex-direction: column;
        padding: 0;

        @include desktop() {
          flex-direction: row;
          padding: 6px 0px 15px 0px
        }

        @include combineIpad() {
          flex-direction: row;
          padding: 6px 0px 15px 0px
        }
      }
    }
  }

  .my-referrals {
    @include desktop() {
      position: relative;
    }

    @include ipad() {
      padding-left: 80px;
      padding-right: 80px;
    }

    .reward-balance {
      height: 120px;
      font-size: 20px;
      background: var(--primary);
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      border: 1px solid #E8E8E8;
      padding: 0 20px 10px;
      color: #ffffff;
      font-weight: 600;
      display: flex;
      align-items: center;

      @include desktop() {
        font-size: 24px;
        height: 100px;
      }

      .price {
        font-size: 24px;
        font-weight: 700;

        @include desktop() {
          font-size: 32px;
        }
      }

      .logo-container,
      svg {
        height: 64px;
        width: 64px;
        line-height: normal;

        @include desktop() {
          height: 50px;
          width: 50px;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        align-items: flex-start;

        @include desktop() {
          flex-direction: row;
          align-items: center;
        }
      }
    }

    .card-data {
      margin-top: -10px;
      height: 570px;
      overflow-y: scroll;
    }

    .refer-friend-section {
      display: flex;
      background: #FFFFFF;
      border-radius: 12px;
      width: 100%;
      border: 1px solid var(--primary);
      margin-top: 30px;
      position: relative;
      overflow: hidden;
      padding: 10px 20px;

      @media only screen and (min-width: 1200px) {
        width: 300px;
        position: absolute;
        top: 0;
        margin-top: 0;
        right: -60%;
      }

      @include combineIpad() {
        justify-content: space-between;
        height: 155px;
        padding: 10px 30px;
      }

      // @include ipadLandscape(){
      //   justify-content: space-between;
      //   height: 155px;
      // }
      .content {
        flex-direction: column;
        align-items: flex-start;

        @include combineIpad() {
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          flex-direction: row;
        }

        p {
          @include combineIpad() {
            font-size: 28px;
          }

          font-size: 23px;
          line-height: 1.4;

          .yellow {
            color: #F9D95B;
          }
        }

        button {
          margin-bottom: 10px;
          height: fit-content;
        }
      }

      img {
        height: 150px;
        position: absolute;
        right: -10px;
        bottom: -25px;
        transform: rotate(-21deg);

        @include combineIpad() {
          height: 160px;
          left: 56%;
          bottom: -15px;
          transform: rotate(-6deg) translateX(-56%);
        }
      }
    }

    .empty-holder {
      font-size: 14px;
      font-weight: 600;
      color: #616161;

      img {
        height: 300px;
        text-align: center;
        position: absolute;
        bottom: 0le;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      .btn-primary {
        font-weight: 600;
      }
    }
  }

  .empty-state {
    text-align: center;
    color: #616161;
    display: flex;
    flex-direction: column;
    font-size: 16px;

    @include desktop() {
      position: absolute;
      width: 100%;
      height: 100%;
      margin-left: -90px;
      // margin-top: -30px;
    }

    img {
      height: 190px;
      object-fit: contain;
      object-position: center;
      margin-bottom: 3rem;
      margin-top: 1rem;

      @include desktop() {
        height: 300px;
        justify-content: center;
        margin-bottom: 6rem;
        margin-top: 2rem;
      }
    }

    a {
      text-decoration: underline;
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #d5d5d5;
  }
}

.profile-header {
  // position: relative;
  cursor: pointer;
  display: inline-block;
  color: #707070 !important;
  margin-left: 0px;
  width: 30px;
  height: 30px;
  position: relative;
  bottom: -4px;
  text-align: center;
  margin-right: 10px;

  @include iphone5() {
    margin-right: 5px;
    margin-left: -6px;
  }

  span {
    font-size: 18px;
  }

  .profile {
    img {
      width: 20px;
      height: 20px;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
    }
  }

  .header-profile-menu {
    display: none;
    opacity: 0;
    transition: all 0.5s ease;
    cursor: pointer;
    position: absolute;
    top: 25px;
    right: 0px;
    min-width: 160px;
    background-color: #ffffff;
    box-shadow: 1px 4px 12px 0px rgba(102, 102, 102, 0.13);

    li {
      display: none;
    }
  }

  &:hover {
    .header-profile-menu {
      display: block;
      opacity: 1;

      &:hover {
        li {
          display: block;
          opacity: 1;
        }
      }

      li {
        display: block;
        opacity: 1;
      }
    }
  }


  // .profile{
  //   transition-duration: 0.5s;

  //   &:hover{
  //     +.header-profile-menu{
  //       display: block;
  //       background-color: #f1f1f1;
  //       min-width: 160px;
  //       box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  //       position: absolute;
  //       top: 25px;
  //       right: 0px;

  //       li{
  //         display: block;
  //       }

  //     }
  //   }
  // }
}

.profile-back {
  background: #f7f7f7;
  padding: 0 15px;
  height: 50px;
  line-height: 50px;
  border: none;
  font-size: 18px;
  display: flex;
  align-items: center;
  font-weight: 700;
  width: calc(100% + 30px);
  margin: -5px 0 25px -15px;
  text-transform: capitalize;
  position: fixed;
  z-index: 2;
  color: #333333;

  @include desktop() {
    display: none;
  }

  span[class^="icon-"] {
    font-size: 25px;
    margin-right: 6px;
    transform: scale(-1, 1);
  }
}

.add-address-popup {
  background-color: rgba(0, 0, 0, 0.69);

  .modal-dialog {
    @include desktop() {
      max-width: 540px !important;
    }

    .modal-content{
      @include desktop(){
        min-height: 320px;
      }
    }

    .modal-header {
      border-bottom: 0;
      padding-bottom: 0;
      padding-top: 15px !important;

      .modal-title {
        font-size: 18px;
        font-weight: 700;
        color: #000000;
      }

      button {
        font-size: 14px;
        padding: 0;
        right: 25px;
      }
    }

    .modal-body {
      .logo-container {
        height: 150px;

        svg {
          height: 150px !important;
        }
      }

      label {
        color: #555555;
        font-size: 14px;
        margin-bottom: 5px;
      }

      .pin-location {
        display: flex;
        align-items: center;
        font-size: 1em;

        &:hover {
          // text-decoration: none;
        }

        span {
          color: #000000;
          // font-size: 20px;
        }
      }
      .update-address{
        position: relative;
        .icon-pin{
          display: inline-block !important;
          position: absolute;
          left: 13px;
          font-size: 22px;
          top: 13px;
          color: #bfbfbf;
        }
      }
    }


    .modal-footer {
      border-top: 0;
      padding-top: 0;

      @include notDesktop(){
        position: unset;
      }

      label {
        color: $text-light;
      }

      button {
        // height: 30px;
        // border-radius: 6px;
        // font-size: 16px;
        // text-align: center;
        // padding: 0;
        // min-width: 90px;
      }
    }
  }

}

.link-bank-account {
  .payment-holder {
    label {
      display: flex;
      align-items: flex-start;
      height: 25px;
      position: relative;

      @include desktop() {
        flex-direction: row;
        align-items: center;
      }

      .bottom-text {
        position: absolute;
        bottom: -16px;
        left: 27px;
        font-size: 12px;
        color: #616161;

        &.kindtap {
          bottom: -22px;

          @include desktop() {
            bottom: -16px;
          }
        }
      }

      // &.stronghold, &.kindtap{
      //   flex-direction: column;
      //   @include desktop(){
      //     flex-direction: row;
      //   }
      // }

      &.kindtap {
        .margin-top {
          margin-top: -5px;

          @include desktop() {
            margin-top: 0;
          }
        }
      }

      .input-parent {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        align-content: center;
      }
    }

    .payment-logo {
      height: 22px;
      margin-left: 5px;
      margin-right: 5px;

      @include desktop() {
        height: 22px;
      }

      &.kindtap {
        height: 38px;
      }
    }

    .custom-checkbox {
      margin-bottom: 45px;

      // &:nth-child(odd){
      //   margin-right: 50px;
      // }
      +.custom-checkbox {
        margin-top: 0;
      }

      @include desktop() {
        width: 50%;
        display: inline-block;
      }
    }
  }

  .btn-primary {
    width: 90px;
  }

  .card-details {
    margin-top: -20px;
  }
}

.my-referrals {
  .referral-body {
    h5 {
      font-size: 16px;
      font-weight: 600;
    }

    .recipient-text {
      border-bottom: 1px solid #e6e6e6;

      i {
        font-size: 22px;
      }
    }
  }

  .by-giftcard {
    .by-giftcard-list {
      .gift-details {
        align-items: center;
        justify-content: space-between;

        +.gift-details {
          border-top: 1px solid #e6e6e6;
        }

        .by-giftcard-icons {
          display: inline-block;
          font-size: 18px;
          margin-top: 5px;
          margin-right: 12px;


          &.sucess,
          &.joined {
            color: #6EB671;
          }

          &.declined {
            color: #EC5E57;
          }

          &.expired {
            color: #EC5E57;
          }

          &.expires {
            color: #4B54E2;
          }
        }

        .recipient-phone-status-wrapper {
          color: #37475B;
          font-size: 12px;
          flex-direction: column;

          @include desktop() {
            flex-direction: row;
          }

          .recipient-giftcard-status {
            @include desktop() {
              position: relative;
              padding-left: 12px;

              &::before {
                content: '';
                position: absolute;
                background: #37475B;
                width: 1px;
                height: 10px;
                left: 2px;
                top: 6px;
              }
            }
          }
        }

        .ref-follwup {
          margin-top: 10px;

          @include desktop() {
            margin-top: 0;
          }
        }
      }
    }

    .link-btn {
      font-weight: 400;
      font-size: 14px;
      font-weight: 400;
      font-size: 16px;
      padding: 0;
      border: 0;
      color: var(--primary);
      text-decoration: underline;
      background: transparent;
    }
  }

  .modal-header {
    border-bottom: none;
  }

  &.modal {
    .card-data {
      padding: 0 15px;

      .by-giftcard-list .gift-details {
        &:first-child {
          margin-top: -15px;
        }
      }
    }
  }
}