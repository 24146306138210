.age-popup {
    background-color: $modalBGColor;
    .modal-dialog {
        margin-left:auto;
        margin-right:auto;
        max-width: 440px;
        @include iphone5 {
            max-width: 320px;
        }
        @include mobile{
            width: 100%;
        }
    }
    .logo-container {
		// min-height:100px;
        margin: 0 auto;
        margin-bottom: 2rem;
        img {
            height: 100%;
            width: 100%;
        }
    }
    .modal-body{
        padding: 24px 25px 24px;
        h4{
            font-size: 25px;
            margin:42px 0px 25px;
            line-height: 1;
        }
        p{
            a{
                //color: #000;
            }
        }
        .age-yes-btn{
            font-size: 18px;
            padding: 12px 40px;
            width: 180px;
            margin: 0 auto;
            line-height: 24px;
        }
        .age-exit-btn{
            font-size: 14px;
            margin-top: 30px;
            padding: 0px;
            color: #777777;
        }
    }
}