.asap-scheduled-delivery-modal{
  background-color: $modalBGColor;
  .modal-dialog{
    @include desktop(){
      max-width: 550px;
      width:550px;
    }

  
      .close-btn{
        position: absolute;
        top: 15px;
        right: 8px;
        font-size: 13px;
      }
    

    .modal-header{
      border-bottom: none;
      color: var(--primary);
      text-align: center;
      display: block;
      position: relative;
      // padding-top: 0px;
      // padding-bottom: 5px;
      // margin-top: -10px;
      padding: 25px 50px 5px;
      h4{
        font-weight: 800;
        line-height: 1.3;
        color: #212529;
        @include desktop() {
          line-height: 1.5;
          text-align: left;

        }
      }
    }

    .modal-body{
      padding:15px 25px;
      [class^="col-"]{
        .box-holder{
          // box-shadow: 1px 1px 12px #d2d2d2;
          border-radius: 10px;
          // padding: 20px 15px;
          // padding: 12px 15px 15px;
          text-align: center;

          @include desktop() {
            // padding: 20px 15px;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
          }
  
          h5{
            color: var(--primary);
            // border-bottom: 1px solid #b4b9bb;
            // padding-bottom: 10px;
            // margin-bottom: 10px;
            // margin-bottom: 15px;
            font-weight: 800;

            @include desktop() {
              // padding-bottom: 0px;
              text-align: left;
              padding-left: 26px;
            }
          }

          .content {
            // margin: 15px 0;
            @include desktop() {
              margin: 0;
            }
          }
  
          p{
            color: #4f4f4f;
            font-size: 14px;
            margin-bottom: 0;
            @include desktop() {
              margin-bottom: 12px;
              text-align: left;
              padding-left: 25px;
            }
          }

          button {
            padding: 10px;
            border-radius: 6px;
            width: 80%;
            margin: 0px auto;
            margin-top: 10px;
          }
        }

        +[class^="col-"]{
          margin-top: 25px;
  
          @include desktop(){
            margin-top: 0;
          }
        }
      }
      .asap-delivery{
        border-bottom: 1px solid #d2d2d2;
        padding-bottom: 25px;
        @include desktop{
          border-right: 1px solid #d2d2d2;
          border-bottom: none;
          padding-bottom: 0px;
        }
      }
    }

    .modal-footer {
      border-top: none;
      padding-bottom: 20px;
      padding-top: 16px;

      .check-holder{
        color: #212529;
        font-size: 13px;
        font-weight: 500;

        .custom-checkbox{
          label{
            &::before{
              margin-top: 0;
            }
            &::after {
              border-left: 3px solid var(--grassdoor-secondary-color);
              border-bottom: 3px solid var(--grassdoor-secondary-color);
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}