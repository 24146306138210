.gd-new-volume-popup {
  .modal-dialog {
    .modal-content {
      border-radius: 10px 10px 0 0;
      @include desktop {
        border-radius: 10px;
      }
    }
    .modal-header {
      border-bottom: none;
      padding-right: 4px;
      padding-top: 24px;
    }
    .modal-body {
      max-height: 100%;
      @include desktop {
        max-height: 450px;
      }
      .img-container {
        width: 80px;
        height: 80px;
        margin: 0;
        @include desktop {
          width: 110px;
          height: 110px;
        }
      }
      .mini-description{
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        color: #616161;
      }
      .volume-popup-products-wrapper {
        padding-left: 15px;
        @include desktop {
          padding-left: 40px;
        }
        .product-brand {
          background: #f9d95b;
          border-radius: 4px;
          width: fit-content;
          padding: 3px 6px;
        }
        .product-conter-box {
          display: flex;
          justify-content: space-between;
          margin: 10px 0 0 0;
          min-width: unset;
          .bottom-section {
            background: #fff;
            color: #333;
            border: none;
            font-weight: 700;
            padding: 0;
            .price {
              font-size: 20px;
              font-weight: 700;
              line-height: 29px;
            }
            .strike-text{
              font-size: 20px;
              font-weight: 600;
              line-height: 24px;
              color: #777777;
            }
          }
          .top-section {
            border: 1px solid var(--primary);
            border-radius: 10px;
            position: absolute;
            bottom: 0;
            right: 0;
            button {
              padding: 2px 8px 0;
            }
            .count {
              color: #616161;
              font-weight: 700;
              font-size: 18px;
            }
          }
        }
        .product-name {
          max-width: 245px;
          overflow: hidden;
          // white-space: nowrap;
          text-overflow: ellipsis;
          max-height: 40px;
          display: -webkit-box !important;
          -webkit-line-clamp: 2;
          -moz-line-clamp: 2;
          -ms-line-clamp: 2;
          -o-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          font-size: 18px;
          line-height: 21.92px;
          font-weight: 700;
          margin: 6px 0 6px;
          // @include desktop {
          //   max-width: 315px;
          // }
        }
        .brand-name{
          font-size: 12px;
          font-weight: 600;
          line-height: 15px;
          color: #929393;
        }
      }
      .list-img-container {
        width: 48px;
        height: 48px;
        left: 0;
        img {
          border: 1px solid #E8E8E8;

          border-radius: 8px;
        }
      }
      .product-list-item {
        padding-left: 55px;
        margin: 6px 0 12px;
        @include desktop {
          padding-left: 60px;
        }
        .price-parent {
          margin-top: 0px !important;
        }
        .price {
          font-size: 14px;
          font-weight: 600;
          line-height: 17px;
        }
      }
      .list-product-name {
        // max-width: 170px;
        // overflow: hidden;
        // white-space: nowrap;
        // text-overflow: ellipsis;
        // line-height: 1;
        // font-size: 14px;
        // font-weight: 600;
        // line-height: 17px;

        // @include desktop {
        //   max-width: 270px;
        // }
      }
      .product-brand {
        line-height: 1;
        // font-size: 12px;
        font-weight: 400;
        line-height: 10px;
        color: #616161;
        margin:2px 0px;
      }
      .c-product-list {
        padding: 24px 16px 18px;
        border: 1px solid #e8e8e8;
        box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.15);
        border-radius: 12px;
        background: #fff;
      }
      .add-and-save {
        background: #4b7d5c;
        padding: 15px 15px 25px;
        border-radius: 12px 12px 0 0;
        color: #fff;
        position: absolute;
        width: 100%;
        left: 0;
        top: -52px;
        z-index: 0;
        i {
          font-size: 22px;
        }
      }
    }
    .promotional-text {
      color: #616161;
      margin: 15px 0 70px;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
    }
    .modal-footer {
      border-top: none;
      padding: 15px;
      background-color: #ffffff;
      h6{
        font-size: 16px;
        font-weight: 600;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: #616161;
        .total-price {
          color: #333 !important;
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
  
        }
        .strike-text{
          font-size: 16px;
          font-weight: 600;
          line-height: 19px;
          color: #777777;
        }
        .soldout-limit{
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          color:#DA5A5A;
        }
      }
      button {
        min-width: 85px;
        padding: 10px 0;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;

      }
    }
  }
  .strike-text {
    color: #999;
    &:after {
      background-color: #999;
    }
  }
}