.tesla-timer{
  display: none;
  align-items: center;
  margin-top: 10px;
  border: 1px solid var(--primary);
  border-radius: 4px;
  background: rgba(6, 160, 168, 0.03);
  cursor: pointer;
  @include desktop(){
    // margin-right: 8px;
    margin-top: 0;
    display: flex;
    padding: 8px;
    height: 36px;
  }
  @include ipadPro(){
    padding: 4px 8px;
    height: auto;
  }
  @include ipadLandscape(){
    flex-direction: column;
    align-items: center;
    height: auto;
    padding: 5px 8px;
  }
  .lottie-timer{
    height: 40px;
    width: 40px;
  }
  .timer-content{
    flex-direction: column;
    align-items: flex-start;
    line-height: 1.2;
    @include desktop(){
      flex-direction: row;
      align-items: center;
    }

    @include ipadPro(){
      flex-direction: column;
      align-items: flex-start;
    }
    @include ipadLandscape(){
      flex-direction: column;
      align-items: center;
    }

    p{
      font-size: 14px;
      color: #6e6e6e !important;
      margin-right: 2px;
    }
    .tesla-counter-wrapper{
      display: flex;
      align-items: center;
      letter-spacing: 0.5px;
      padding-left: 2px;
      color: #929292;
      .sub{
        font-weight: 400;
      }
    }
  }
  +.btn-holder{
    margin-left: 15px;
  }
}