.brand-circle-slider-container {
  justify-content: space-between; 
  margin: 0.75rem auto; 
  padding: 0.75rem;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 85px 20px calc(100% - 100px);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.23);

  .fixed-circle { 
    min-width: 85px;

    .brand-circle-container { 
    }
  }

  .brand-circle-slider { 
    margin-left: -10px;

    .brand-circle-container {
      &:first-of-type {
        margin-left: 10px;
      }
    }

    .h-list-parent {
      margin: 0;
      .h-list {
        padding: 0;
        margin: 0;
      }

      .scroll-btn.right {
        right: 0;
      }
    }
  }

  .divider {
    margin: 0 .5rem;

    .top {
      width: 1px;
      background: rgba(0, 0, 0, 0.10);
      height: 50%;
      margin-top: 1rem;
    }
  }

  .brand-circle-container {
    flex: 0 0 75px;
    margin: 0.3rem;
    position: relative;

    .brand-circle {
      border-radius: 50%;
      border: 3px solid transparent;
      overflow: hidden;
      max-width: 75px;
      margin-bottom: 0.5rem;
      position: relative;
      background-image: linear-gradient(white, white), 
                        linear-gradient(to bottom, #4baeb3, #ef7373);
      background-origin: border-box;
      background-clip: content-box, border-box;

      img {
        width: 100%;
        object-fit: cover;
        border-radius: 50%;
        border: 2px solid white;
      }
    }

    .brand-text {
      text-align: center;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 75px;
    }
    
    .new-tag {
      position: absolute;
      bottom: 23px;
      transform: translateX(-50%);
      left: 50%;
      background: #ff6d6a;
      padding: 1px 5px;
      border-radius: 10px;
      font-size: 10px;
      border: 2px solid white;
      color: white;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

  }

}