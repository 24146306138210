.tip-driver {
    padding: 20px;
    background-color: #FFFFFF;
    border-radius: 10px;
    .tipAmount {
        display: flex;
        align-items: center;
        width: auto;
        padding: 0px 15px;
        height: 33px;
       
        text-align: center;
        border-radius: 15px;
        border: 1px solid #E3E3E3;
        background: #FFFFFF;
        color: #333333;
        
        margin-right: 10px;
        font-size: 14px;
        text-decoration: none;
        cursor: default;
        // box-shadow: 0 3px 7px 2px rgba(0, 0, 0, 0.16);
        @include desktop{
            width: auto;
            padding: 0px 15px;
        }
        @include real-iphone5{
            width: 50px; 
        }
       
        &.is-active {
            @extend %brand-gradient;
            color: #FFFFFF;
            background: var(--primary);
            border:none;
        }
        
            &:focus{
                box-shadow: none;
            }
        
    
    }
    .custom-amount {
        border: none;
        width: 65px;
        text-align: center;
        display: inline-block;
        height: 31px;
        @include real-iphone5{
            width: 50px; 
        }
        &:focus{
            box-shadow: none;
        }

        &.is-active {
            @extend %brand-gradient;
            color: #FFFFFF;
            background: var(--primary);
            border:none;
        }
    }
    .ico-btn {
        i {
            margin-right: 5px;
        }
    }
    .amount-wrapper {
        position: relative;
        border-radius: 15px;
        padding-right:15px;
        border: 1px solid #E3E3E3;
        input {
            padding-left: 25px;
            font-size: 14px;
            border-radius: 15px;
            // min-height: 50px;
            &:focus{
                box-shadow: none;
            }
        }
        &:before {
            content: "$";
            position: absolute;
            top: 50%;
            left: 11px;
            font-size: 14px;
            transform: translateY(-50%);
            font-family: inherit;
        }
        
        &.is-active {
            &:before {
            color: #FFFFFF;
            }
        }
        
        &.is-active {
            .custom-amount{
                @extend %brand-gradient;
                color: #FFFFFF;
                background: var(--primary);
                border:none;
            }
        }
        &.is-active {
            @extend %brand-gradient;
            color: #FFFFFF;
            background: var(--primary);
            border:none;
        }
        .icon-close{
            position: absolute;
            right: 15px;
            transform: translateY(9px);
        }
    }
    .tip-text{
        color:var(--primary);
        font-weight: 600;
    }

    .icon-close{
        font-size: 5px;
        font-weight: bold;
        padding: 5px;
        background-color: #A7DDE0;
        border-radius: 50%;
        color:var(--primary);
        cursor: pointer;
    }

        
    
}
  
