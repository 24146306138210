$checkboxSideLength: 22px;
$checkboxBorderColor: #b3b3b3;
$checkBoxTickColor: $primary;

.custom-checkbox {
    position: relative;
    & + .custom-checkbox {
        margin-top: 20px;
    }
    input {
        display: none;
        &:checked + label {
            &:after {
                transform: rotateZ(-45deg) scale(1);
            }
            &:before {
                animation: popanim 0.3s 1;
                //border: 2px solid $checkBoxTickColor;
            }
        }
    }
    label {
        cursor: pointer;
        padding-left: $checkboxSideLength + 13px;
        display: inline-block;
        vertical-align: middle;
        line-height: $checkboxSideLength;
        position: relative;
        word-break: break-word;

        &[data-type-error-ageCheck="true"] {
            &:before {
                border-color: #f44336;
                border-width: 1.5px;
            }
        }

        &:before {
            content: "";
            position: absolute;
            width: $checkboxSideLength;
            height: $checkboxSideLength;
            border: 1px solid $checkboxBorderColor;
            //box-shadow: 1px 1px 2px 0px #c7c7c7;
            left: 0;
            box-sizing: border-box;
            border-radius: 4px;
            // animation:popanim 0.3s 1;
            // transition: 0.3s all cubic-bezier(.41,.71,.7,1.52);
            // margin-top: 10px;
            // @include desktop{
            //     margin-top: 0px;
            // }
        }
        &:after {
            content: "";
            position: absolute;
            width: 13px;
            height: 7px;
            border-left: 3px solid $checkBoxTickColor;
            border-bottom: 3px solid $checkBoxTickColor;
            border-left: 3px solid var(--primary);
            border-bottom: 3px solid var(--primary);
            transform: rotateZ(-45deg) scale(0);
            border-bottom-left-radius: 3px;
            top: 7px;
            left: 5px;
            transition: 0.3s all cubic-bezier(0.41, 0.71, 0.7, 1.52);
            // margin-top: 10px;
            // @include desktop{
            //     margin-top: 0px;
            // }
        }
    }
    &.circle-style {
        & + .circle-style {
            margin-top: 10px;
        }
        label {
            //font-size: 12px;
            // font-weight: 500;
            color: #333;
            padding-left: 33px;
            &:before {
                border-radius: 30px;
                border-width: 1px;
            }
            &:after {
                border-left: 2px solid $checkBoxTickColor;
                border-bottom: 2px solid $checkBoxTickColor;
                border-left: 2px solid var(--primary);
                border-bottom: 2px solid var(--primary);
                border-bottom-left-radius: 3px;
                top: 6px;
                left: 4px;
            }
        }
        input {
            &:checked + label {
                &:after {
                    border-color: white;
                }
                &:before {
                    border-color: var(--primary);
                    background: var(--primary);
                }
            }
        }
    }
}
@keyframes popanim {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(1);
    }
}
