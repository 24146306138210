/*for drinkcann */
.product-tile-controls{
	margin:0 -5px;
	.d-price,.d-counter{
	
		*{
			color:var(--primary);
		}
		border: 1px solid var(--primary);
		text-align: center;
		.count{
			min-width:50px;
			font-size: 17px;
			align-self: center;
		}
		.btn{
			text-align: center;
			font-size:24px;
			padding: 0 10px;
			line-height:10px;
			flex: 1;
			&:focus,&:active{
				//background-color:grey;
				box-shadow: none;
			}
		}
		.minus-btn{

    //transform: scaleX(1.8);

		}
	}
	.d-counter,.d-btn-container{
		.btn{
				min-height: 38px;
		@include desktop{
			
			min-height: 3.46vw;
		}
		}
	

	}
	.strike-text {
    margin-right: 5px;
		margin-top: 2px;
	}
	.price{
		margin-right: 5px;
	}
	.d-btn-container{
		flex:1;
		.btn{

			display:flex;
			justify-content:center;
			align-items: center;
		}
	}

	& > *{
		margin: 5px;
	}
	&.bundle-control{
		display:flex;
	}
	&.koan-product-controls{
		.d-btn-container *{
			font-size:12px;
			@include desktop{
					font-size: 1.5vw;
			}
		}
	
	}
	&.drinkcann-product-controls{
		.btn:hover *{
			color: var(--bg);
		}
		.btn:hover{
			.loader span{
				background-color: var(--bg);
			}
		}
		&.bundle-control .btn:hover{
			background-color: transparent;
			*{
				color:white;
			}
			.loader span{
				background-color: white;
			}
		}
		*{
			font-family: "GothamCondensedBold","Avenir","Roboto", sans-serif;
		}
		.btn{
			border-radius:0;
			text-transform:uppercase;
			
	
		}
		@include desktop{
			.btn{
				font-size: 1.8vw;
			}
			.d-counter {
				font-size: 1.8vw;
		}
			.count{
				font-size: 1em;
			}
		}
	}

	@include desktop{
		display:flex;
	}
}
