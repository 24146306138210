// .address-bottomsheet{
//     position: relative;
//     z-index: 1;
// }
// .react-swipeable-view-container{
//   @include desktop{
//    max-width: 600px;
//    margin: auto;
//    .ReactSwipeableBottomSheet--open{
//     border-radius: 20px;
//    }
//   }
//   .delivery-details{
  
//     padding-top: 25px;
   
//    @include desktop{
//     padding-top: 40px;
//     border-radius: 20px;
//    }
  
//   }
//  }
 .Toastify > div {
   z-index: 9999;
 }