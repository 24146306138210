.cart-item {
    padding: 10px 0;
    margin: 0 0 15px 0;
    &:last-child {
        margin-bottom: 150px;
    }
    .bottom-sec {
        margin-top: 0.3rem;
    }
    .img-container {
        padding: 0 8px;
        @include iphone5 {
            padding: 0 5px;
        } // width: 70px;
        text-align: center;
    }
    .product-name {
        font-size: 0.9em;
    }
    img {
        width: 50px;
        height: 50px;
        @include iphone5 {
            width: 45px;
            height: 45px;
        }
        object-fit: contain;
    }
    .count-holder {
        font-size: 1em;
        padding: 0 8px;
    }
    .deletebtn {
        position: absolute;
        top: -9px;
        right: -7px;
        width: 24px;
        height: 24px; // display: flex;
        // justify-content: center;
        font-size: 10px;
        line-height: 25px;
        padding: 0;
        border-radius: 50%;
        background-color: white;
        border: 1px solid #e2e2e2;
        color: #b7b7b7;
        @extend %increase-touch-area;
    }
    .price {
        font-size: 1.3em;
    }
    .strike-text {
        margin-right: 5px;
        &:after {
            height: 0.09rem;
            background: #f44336;
        }
    }
    .icon-close {
        font-size: 0.7em;
        margin: 0 6px;
        margin-left: 10px;
    }
    .update-count-btn {
        padding: 3px 5px;
        background: transparent;
        &:focus {
            box-shadow: none;
        }
        span {
            height: 20px;
            width: 20px;
            line-height: 1.2;
            border: 1px solid #bebebe;
            display: inline-block;
            border-radius: 50%;
            color: #505050; // padding: 9px 0;
        }
    }
    
    .count {
        width: 25px;
        height: 25px;
        background-color: #bebebe;
        color: black;
        border: 0;
        text-align: center;
        border-radius: 3px;
        line-height: 1.9; //height: 100%;
        input {
        }
        select {
            padding: 0;
            width: 30px;
            font-size: 1em;
            height: 26px;
            border: 0;
            background-color: #cacaca;
            color: white;
            text-align: center;
            -webkit-appearance: none;
            padding-left: 12px;
            display: inline-block;
        }
    }
  
}
