.app-store-holder{
  background: linear-gradient(135deg, var(--primary-dark) 0%, var(--primary) 100%);
  padding: 30px 0 50px;
  color: #FFFFFF;
  position: relative;
  overflow: hidden;
  .content-holder{
    .title{
      font-size: 24px;
      font-weight: 700;
      position: relative;
      z-index: 1;
      @include desktop(){
        font-size: 36px;
        margin-bottom: 10px;
      }
    }
    p{
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 30px;
      @include desktop(){
        font-size: 32px;
      }
    }
    .app{
      height: 40px;
      margin-top: 50px;
      position: relative;
      cursor: pointer;
      @include desktop(){
        height: 50px;
        margin-top: 0px;
      }
    }
  }
  .img-holder{
    position: absolute;
    height: 190px;
    bottom: 0;
    right: 10px;
    @include desktop(){
      height: 260px;
      position: unset;
      margin-top: -100px;
    }
    @include combineIpad{
      height: 250px;
      right: 60px;
    }
    @include ipad(){
      right: -140px;
    }
    img{
      height: 100%;
    }
    .mobile-screen{
      display: block;
      @include desktop{
        display: none;
      }
    }
    .desktop-screen{
      display: none;
      @include desktop(){
        display: block;
        height: 260px;
        position: absolute;
        bottom: -5px;
        right: 100px;
      }
      @include ipad(){
        left: -40px;
        display: block;
      }
    }
  }
  img[class^="gd-leaf"]{
    position: absolute;
  }
  .gd-leaf1{
    left: -40px;
    top: 32%;
    height: 120px;
    @include desktop(){
      left: -20px;
      top: 8%;
      height: 90px;
    }
  }
  .gd-leaf2{
    bottom: -34px;
    left: 33%;
    transform: rotate(290deg);
    height: 85px;
    @include desktop(){
      bottom: auto;
      top: -24px;
      left: 40%;
      transform: rotate(95deg);
      height: 100px;
    }
  }
  .gd-leaf3{
    top: 0px;
    right: -40px;
    transform: rotate(-235deg);
    height: 90px;
    @include desktop(){
      bottom: -15px;
      top: auto;
      right: -36px;
      transform: rotate(-160deg);
      height: 110px;
    }
  }
}