.bulk-product-popup{
	background-color: $modalBGColor;
	.b-right-sec{
		@include desktop{
			padding-left: 40px;
		}
	}
	.section-header{
		// background: #e6e6e6;
		// font-size: 16px;
		// margin: 0rem -15px 0rem -15px;
		// border-top: 1px solid $dividerColor;
	}
	.img-container{
		text-align: center;
		width: 200px;
		height: 200px;
		margin: 0rem auto 1rem auto;
		img{
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
	table thead th {
    border: none !important;
    background-color: #f7f7f7;
    padding: 0.25rem 1rem !important;
    color: #37475B;
    font-weight: 300;
	}
	table td {
		padding: 0.35rem 1rem;
		// border: none !important;
		border-bottom: 1px solid #dee2e6;
		border-top:none;
	}
	.product-conter-box{
		display:inline-block;
		margin:1rem auto 1.5rem auto;
		min-width: 150px;
		.top-section{

			& > *{
				vertical-align: middle;
			}
			border-radius:5px 5px 0 0;
			border:1px solid $dividerColor;
			border-bottom:none;
			.btn{
				&:focus{
					box-shadow: none;
				}
			}
		}
		.bottom-section{
			background:$primary;
			background:var(--primary);
			border-radius:0px 0px 5px 5px;
			border:1px solid $primary;
			border:1px solid var(--primary);
			padding: 5px 8px;
			font-size: 18px;
			.price{
				margin-right: 6px;
			}
		}
		&.small-box{
			min-width: 100px;
			.top-section{
				& > *{
					padding: 3px 7px;
				}
			}
			.bottom-section{
				padding: 2px 7px;
				font-size: 14px;
			}
		}
	}
	.strike-text{
		&:after{
			background-color: rgba(244, 67, 54, 0.7);
			
		}
	}
	.box-row{
		padding: 8px 5px;
		border: 1px solid $dividerColor;
		border-radius: 5px;
		margin: 0.5rem 0;
		.strike-text{
			&:after{
				background-color: #F44336;
			}
		}
	}
	.badge-primary{
		padding: 4px 8px 4px 8px;
		font-weight: 400;
		font-size: 12px;
		border-radius: 3px;
	}
	.modal-dialog{
		top:0;
		// min-height: 100vh;
		@include desktop{
			top:auto;
			min-height: auto;
		}
	}
	.modal-footer{
		position: sticky;
		bottom: 0;
		@include desktop{
			position: static;
		}
	}
}

