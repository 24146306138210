.location-pages{
	// padding: 30px 15px 36px 15px;
	margin-top: 0px !important;
	@include desktop{
		padding: 61px 0px;
	}
	h2,h3{
		// margin:0 0 30px 0px !important;
		font-size: 18px;
		@include desktop{
			font-size: 30px;
			// margin:0 0 47px 0px !important;
		}
	}
	.main-title{
		font-size: 2.5rem;
	}
	.location-banner{
		position:relative;
		img{
			width:100%;
			height:auto;
		}
		.container{
			position: absolute;
			left: 50%;
			top: 70%;
			transform: translate(-50% , -50%);
			color: white;
		}
		.b-text{
			font-size:16px ;
			margin:0;
		}
		.city-name{
			font-size: 25px;
		}
		@include desktop{
			.container{
				bottom: 30px;
				top: auto;
				transform: translate(-50%,0);
			}
			.b-text{
				font-size:30px ;
			}
			.city-name{
				font-size: 80px;
				position: relative;
    left: -5px;
			}
		}
	}
	.blog-links{
		padding-top:2rem;
		a{
			text-decoration:underline;
			display:block;
			margin-bottom:1rem;
			font-size:16px;
		line-height:1.3;
		}
		@include desktop{
			padding-top:0rem;
			padding-left:1rem;
		}
	}
	.l-container{
		@include desktop{
			padding:0;
		}
	}
	.r-text{
		font-size:14px !important;
		color: #000000;
		line-height:1.3;
		@include desktop{
			font-size: 16px;
		}
	}
	.right-sec{
		.img-container{
			margin:0 -15px;
		}
		@include desktop{
		.img-container{
			margin:0 ;
		}
			padding-top: 15px;
			padding-bottom: 15px;
		}

		background: #f8f8f8;
	}
	.faq-collapse.rc-collapse{
		background-color: transparent;
		border-radius: 0px;
		border:none;
		margin-bottom: 0px !important;
		.rc-collapse-header{
			font-size:14px;
			line-height:1.3;
			// font-weight:bold;
			color:#000;
			position: relative;
			padding: 7px 16px;
			@include desktop{
				font-size: 20px;
				padding: 9px 16px;
			}
		}
		.faq-acord-icon{
			margin:0;
			position:absolute;
			left:10px;
			color:#000;
			font-size: 16px;
			@include desktop{
				left:15px;
			}
		}
		.rc-collapse-header,.rc-collapse-content{
			padding-left:40px;
			padding-right:10px;
			background-color: #fff;
			border: 1px solid #DDDDDD;
			@include desktop{
				padding-left:45px;
			padding-right:15px;

			}
		}
		.rc-collapse-item {
			border: none;
			margin-bottom: 15px;
			padding: 0;
			@include desktop{
				padding: 0 15px;
			}

		}
	}
	.email-capture-form{
		background: #ebf7f6;
		padding:25px;
		.e-left-sec{
			text-align:center;
		}
		.e-right-sec{
			display:flex;
			flex-direction: column;
			button{
				width: 160px;
    		margin: auto;
			}
			input{
				border-radius: 0;
			}
			input + input{
				margin-top:15px;
				margin-bottom: 15px;
				// border-top-right-radius:0;
				// border-bottom-right-radius:0;
			}
		}
		@include desktop {
			display: flex;
			align-content: center;
			justify-content: center;
			align-items: center;
			.e-left-sec{
				padding-right: 25px;
			}
			.e-right-sec{
				flex-direction: row;
				input{
					width:230px;
				}
				input + input{
					margin:0 10px;
				}
				button{
					width:auto;
				}
			}

		}
	}
	.h-list-parent .scroll-btn.left {
    left: -20px;
	
	}
	.h-list{
		margin-bottom: 0;
	}
	@include notDesktop{
		.h2{
			font-size: 1.75rem;
		}
		.h3{
			font-size: 1.5rem;
		}
	}
	.a-category-name {
    font-size: 17px;
    margin-bottom: 5px;
}
	.zip-codes-container{
		display: flex;
		flex-wrap: wrap;
		div{
			a, h4{
				display: block;
				padding: 4px 0px;
				text-align: center;
				border-radius: 4px;
				background-color: #ffffff;
				margin: 0 4px 11px 4px;
				color: #ffffff;
				color: var(--primary);
				letter-spacing: 1px;
				-webkit-box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 19%);
				-moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.19);
				box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 19%);
				text-align: center;
				font-weight: 400;
				cursor: pointer;
				font-size: 14px;
			}
			h4:hover{
				color: #000000;
			}
		}
	}

}
