$loader-grey:  #fcfcfd;
$loader-lightgrey:  #F5F6FA;
.homepage-loader{
  background-color:white;
  position: relative;
  z-index: 99;
  width: 100%;
 
  .top-section{
    display: flex;
    flex-direction: column;
    
  // .loader-order:nth-of-type(1) { order: 2; }
  // .loader-order:nth-of-type(2) { order: 1; }
  // .loader-order:nth-of-type(3) { order: 3; }
  //   @include desktop{
  //     .loader-order:nth-of-type(1) { order: 1; }
  //     .loader-order:nth-of-type(2) { order: 2; }
  //     .loader-order:nth-of-type(3) { order: 3; }
  //   }
    .loader-banner , .loader-category, .loader-info, .loader-menu, .loader-delivery-msg {
      width:100%;
      border-radius: 7px;
      background-size:400px;
      background-image: linear-gradient(
        90deg,
        $loader-lightgrey 0px,
        $loader-grey 100px,
        $loader-lightgrey 200px
      );
      animation: shine 0.9s infinite linear;
    }
     .loader-banner{
      margin-top: 5px;
      margin-bottom: 1rem;
      height: 130px;
     
      @include desktop{
        height:350px;
        margin-top: 80px ;
      } 
    }
    
  .loader-category{
    margin-bottom: 10px;
    margin-top: 5px;
    height: 221px; 
    @include desktop{
      height:225px;
    } 
  }
   
  .loader-info{
    margin-bottom: 1rem;
    height: 180px;
    
    @include desktop{
      height:123px;
      margin-top: 10px;
    } 
  }
  .loader-products {
    width:100%;
    height: 100%;
    display: flex;
    flex-direction:row ;
    
    .loader-filter{
     height: 700px;
     width:0%;  
     @include desktop{
       width: 20%;    
     }
    }
    .loader-products-tile{
      height: 700px;
      width:70%;
      background-color:white;
      position: relative;
      z-index: 99;
     }
  }
  }
  .loader-menu, .loader-delivery-msg, .tile-price, .tile-cart , .tile-image,.tile-block1,.tile-block2,.tile-block3,.tile-block4, .filter-head , .filter-block, .filter-block1 {
    width:100%;
    background-size:400px;
    background-image: linear-gradient(
      90deg,
      $loader-lightgrey 0px,
      $loader-grey 100px,
      $loader-lightgrey 200px
    );
    animation: shine 0.9s infinite linear;
  }
  
  .loader-products {
     width:100%;
    display: flex;
    flex-direction:row ;
    column-gap: 0px;
    // height: 1700px;
    @include desktop{
      column-gap: 10px;
    }
   
    .loader-filter{
     display: none;
    
     .filter-head{
       height: 100%;
       width: 100%;
     }
     .filter-block1{
      margin-top: 10px;
      height: 201px;
      width: 100%;
     }
     .filter-block{
       margin-top: 10px;
       height: 42px;
       width: 70%;
     }
     @include desktop{
       width: 22.80%; 
      
       display: block;  
     }
    }
    .loader-products-tile{
      // height: 700px;
      width:100%;
      @include desktop{
        width: 77.19%;
      }
      .loader-menu{
        width:100%;
        height: 62.2px;
       }
      .loader-delivery-msg{
        width:100%;
        height: 40px;
        margin-top: 10px;
       }
    }
  
  }
  
  .loader-product-tile-structure{
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    width:100%;
    // height:340px;
    background-color: white;
    .tile{
      height: 100%;
      width:50%;
      box-shadow:0px 0px 1px 0px rgba(0, 0, 0, 0.23);
      padding: 10px;
      padding-bottom:0;
      @include desktop{
       width: 25%;
      }
      .tile-head{
        display: flex;
        column-gap: 10px;
        .tile-price{
          flex:1;
          height: 28px;
          }
        .tile-cart{
          width:28px;
          border-radius: 50%;
          height: 28px;
         }
      }
      .tile-image{
        margin-top: 10px;
        width: 100%;
        height: 170px;
       }
      .tile-block1,.tile-block2,.tile-block3,.tile-block4{
        margin-top: 10px;
        width: 100%;
        height: 15px;
       }
      .tile-block2{
        width:50%;
      }
      .tile-block3{
        width:70%;
      }
      *{
        margin: 15px 0;
        border-radius: 4px;
      }
    }
    &:first-child{
      margin-top:0;
    }
  }
  
  @keyframes shine{
    0%{
      background-position: -100%;
      
    }
    100%{
      background-position: 100%;
      
    }
  }
}
.recommended-products-loader{
  .loader-products-tile{
    width:100% !important;
    min-height: 350px;
  }
  .loader-product-tile-structure{
    .tile{
      width:45%;
      @include desktop{
       width: 23.5%;
      }
      &:last-child{
        width:10%;
        @include desktop{
        width: 6%;
        }
      }
      &:nth-child(-n+2){
        display: none;
        @include desktop{
          display: block;
        }
      }
    }
  }
}