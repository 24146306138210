.marketing-banner {
  padding: 0px 15px;
  display: block;
  margin-top: 10px !important;
  margin-bottom: 0px !important;
  max-width: 1600px;
  margin-left:auto;
  margin-right:auto;
  img{
    border-radius: 10px;
  }
  @include desktop {
    img{
      border-radius: 15px;
    }
    
    padding: 0px 7px;
      margin-top: 12px !important;
      margin-bottom: 0px !important;
      padding: 0px 15px;

    .top-banner {
      grid-column: 1/2;
      grid-row: 1/3;
    }
    .s-banner {
      grid-column: 2/3;
      grid-row: 1/2;
    }
    .t-banner {
      grid-column: 2/3;
      grid-row: 2/3;
    }
    
    &.no-secondary-banner{
      .top-banner {
        grid-column: 1/-1;
      }
    }
  }

  .banner-image{

    // height: 200px !important;
    // @include desktop{
    //   height: 430px !important;
    // }
    // @include xDesktop{
    //     height: 500px!important;
    // }
    @include notDesktop{
      height: auto;
    }
    // @include ipadProLandscape{
    //   height: 430px !important;
    // }
    // @include ipadPro{
    //   height:auto!important;
    //   // &.big-image{
    //   //   display: none !important;

    //   // }
    //   // &.small-image{
    //   //   display: block!important;
    //   // }
    // }
  }
 
  &.banner .banner-inner{
    padding-bottom: 0px;
  }
  .side-promo-container {
    display: none;
  }
  @include ipadPro{
    padding-right: 15px;
    padding-left: 15px;
  }
	.just-a-container{
			justify-content: left!important;
    align-items: flex-start!important;
		padding-top: 15px;
    padding-left: 10px;
		@include desktop{
      padding-left: 42px;
      padding-top: 42px;
		}
	}
	.banner-content{
		text-align:left;
    // padding-left: 30px;
    // padding-top: 20px;
		*{
			color:white;

		}
		h1{
			font-size: 24px;
			font-weight: bold;
			// font-family: "Intelo", "Roboto", sans-serif;
			line-height: 1.1;
			// letter-spacing: 0.3px;
			margin-bottom: 12px;
		}
		h2{
			font-size:18px;
				font-weight: bold;
				// font-family:"Intelo","Roboto", sans-serif;
				line-height: 1.2;
				// letter-spacing: 0.3px;
		}

		@include desktop{
			h1{
				font-size: 62px;
				margin-bottom:28px;
			}
			h2{
				font-size:42px;
			}
		}
    @include real-iphone5{
      h1{
				font-size: 20px;
			}
    }
    @include ipadPro{
    	h1{
				font-size: 42px;
				margin-bottom:20px;
			}
			h2{
				font-size:32px;
			}
    }

	}
}
