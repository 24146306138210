.compare-product-search-box-wrapper {
  display: flex;
  position: sticky;
  width: 100%;

  z-index: 2;
  left: 0;
  padding:0 15px;
  margin:0px 0 15px 0;
  position: sticky;
  left:0;
  // .ais-SearchBox {
  //   width: 100%;
  //   margin: 0 5%;

  // }
  .ais-SearchBox {
    margin: 0;
    border: 1px solid $dividerColor;
    //position: sticky;
    //top:0;
    //z-index: 1;
    width: 100%;
    background: white;
    border-radius: 5px;
    // position: sticky;
    top: 0px;
    z-index: 1;
    @include desktop{
      width: 600px;
      margin-left: auto;
      margin-right: auto;
    }

    .ais-SearchBox-submit {
      left: 0.5rem;
    }

    .ais-SearchBox-reset {
      right: 0.5rem;
    }

    input {
      border: 1px solid transparent;
      outline: none;
      padding: 10px;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    ::-webkit-input-placeholder {
      /* Edge */
      color: #e8e8e8;
      font-weight: bold;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #e8e8e8;
      font-weight: bold;
    }

    ::placeholder {
      color: #6c757d;
      font-weight: 400;
      font-size: 14px;
    }
  }

  .compare-product-list-dropdown {
    position: absolute;
    left: 15px;
    right: 15px;
    top: 48px;
    @include desktop{
      left: 0;
      right: 0;
      width: 600px;
      left: 50%;
    transform: translateX(-50%);
    }

    /* width: 90%; */
    max-height: 60vh;
    overflow: auto;
    z-index: 20;
    background-color: #ffffff;
    /* border: 1px solid #f1f1f1; */
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 5px 0px #e3e5ec;
    padding: 5px;
    .compare-search-no-results{
      h6{
        margin: 0 !important;
        padding: 5px 0;
      }
    }
    .ais-Hits-item {
      width: 100% !important;
      box-shadow: none !important;
      border: 0 !important;
      padding: 4px !important;
      cursor: pointer;
      .single-product-card {
        display: flex;
        align-items: center;
        border: 1px solid transparent;
        border-radius: 5px;
        overflow: hidden;
        justify-content: space-between; 
        &.already-added{
          border: 1px solid var(--primary);
          // pointer-events: none;
        }
        &:hover{
          box-shadow: 0 2px 5px 0px #e3e5ec;
        }
        .product-card-image-wrapper {
          height: 70px;
          width: 70px;
        }
        .product-card-content-wrapper {
          padding-left: 10px;
          .ais-Highlight{
            font-size: 14px;
          }
          .hit-category,
          .hit-brand,
          .weight {

            span,
            em {
              font-size: 13.2px;
            }

            font-size:13.2px;
          }
          small{
            // background-color: red;

          }
        }
      }
    }
  }
}
