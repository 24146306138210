.orders__main {
  width: 100%;
  max-width: 720px;
  margin: auto;
  .order-card-wrapper {
    width: 100%;
    p,
    h6,
    span {
      margin: 0;
      color: #333333;
    }
    .oc-date {
      padding: 4px 0;
      font-weight: bold;
      font-size: 14px;
    }
    .order-card {
      border: 0.5px solid #d6d6d6;
      border-radius: 13px;
      overflow: hidden;
      background-color: #ffffff;
      .dispatched {
        color: orange !important;
      }

      .placed {
        color: var(--primary) !important;
      }
      .cancelled {
        color: #AF4F4F !important;
      }

      .delivered {
        color: #4FAF81 !important;
      }

      .failed {
        color: #DD5A11 !important;
      }

      .refunded {
        color: rgb(45, 186, 230);
      }
      .order-card-header {
        width: 100%;
        border-bottom: 1px solid #d6d6d642;
        padding: 1rem 1rem;
        background-color: #f2f1f6;
        @include desktop {
          padding: 1rem 1rem;
        }
        p {
          color: #333333;
        }
        b {
          color: #333333;
        }
        i {
          color: #333333;
        }
        .och-above {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 5px;
          p {
            &:nth-child(1) {
              b {
                color: #333333;
              }
              span {
                color: var(--ink-color);
              }
              i {
                color: var(--ink-color);
                font-size: 12px;
              }
            }
            &:nth-child(2) {
              display: flex;
              align-items: center;
              font-size: 14px;
              i {
                color: #333333;
                font-size: 18px;
                position: relative;
                top: -2px;
                margin-right: 5px;
              }
            }
          }
        }
        .och-below {
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            color: #333333;
          }
          i {
            font-size: 18px;
          }
        }
      }
      .order-card-body {
        width: 100%;
        padding: 1rem;
        .oc-product-wrapper {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .oc-product-details {
            white-space: nowrap;
            display: flex;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 85%;
            align-items: center;
            padding: 0.5rem 0;
            .product-image {
              font-size: 16px;
            }
            p,
            span {
              font-size: 14px;
              color: #333333;
            }
            .icon-close {
              font-size: 10px;
            }
            .count {
              color: #333333;
              font-weight: 600;
              font-size: 14px;
            }
          }
          .oc-product-rate {
            display: flex;
            align-items: center;
            width: 30px;
            .icon-star-filled,
            .icon-star-outline {
              font-size: 16px;
            }
            .colored-star {
              color: #ffc107;
              font-size: 20px;
            }
            .gray-star {
              color: #cecece;
            }
            .rate-value {
              position: relative;
              top: 1px;
            }
            span {
              position: relative;
              top: 2px;
            }
            button {
              background-color: transparent;
              border: 0;
              padding: 0 !important;
            }
          }
        }

        .oc-credits {
          font-size: 12px;
          padding: 2px 0;
          p {
            span {
              color: #808080;
            }
          }
        }
        .oc-report {
          font-size: 12px;
          padding: 2px 0;
          p {
            display: flex;
            align-items: center;
            span,
            i {
              color: var(--primary);
            }
          }
        }
      }
      .order-card-footer {
        border-top: 1px solid #d6d6d642;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 1rem;
        button {
          font-size: 12px;
          border-radius: 7px;
          i {
            font-size: 12px;
          }
          &:nth-child(1) {
            display: flex;
            align-items: center;
          }
          &.rate-button {
            border: 1px solid var(--primary);
            // color: var(--primary);
          }
          &.disabled-button {
            border: 1px solid #a8a8a8;
            color: #a8a8a8;
            pointer-events: none;
          }
        }
        .order-rated {
          cursor: pointer;
        }
        .stars-wrapper {
          cursor: none;
          i {
            font-size: 16px;
            margin-right: 5px;
            cursor: pointer;
          }
          .colored-star {
            color: #ffc107;
          }
          .gray-star {
            color: #dddddd;
          }
        }
      }
    }
  }
}
