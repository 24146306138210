:root {
  --lh: 1.4em;
}
.compare-products-wrapper {
  //min-width: 100%;
  //min-height: 100%;
  //overflow-x: auto;
  // height: calc(100vh - #{$currentOrderInfoHeight});
  // flex: 1;
  padding-bottom: 25px;
  overflow-x: auto;
  @include desktop {
    height: calc(100vh - 75px);

  }
  &.single-product-available {
    @include desktop {
      min-width: 500px;
    }

    .product-image-wrapper,
    .add-to-cart-section-wrapper,
    .product-spec-container {
      justify-content: center;
    }
  }
  &.product-count-0 {
    flex: 1;
  }
  &.product-count-1 {
    flex: 1;
    @include desktop {
      flex: none;
    }
  }
  .product-image-wrapper {
    display: flex;
    .piw-single-product {
      width: 50vw;
      text-align: center;
      min-width: 50vw;
      flex: none;
      .price-container-static {
        font-weight: 600;
        .discount {
          // color: rgb(255, 127, 104);
          font-weight: 600;
        }
        .price {
          font-size: 19px;
          vertical-align: middle;
          @include desktop {
            font-size: 17px;
          }
        }
        .strike-text {
          vertical-align: middle;
          font-size: 14px;
        }
        .strike-text:after {
          // background-color: rgba(255, 99, 71, 0.6);
          background-color: var(--primary);
          transform: translateY(-50%) rotate(0deg);
        }

        @include iphone5 {
          font-size: 16px;
        }
      }
      .cp-name {
        line-height: 1.2;
        height: auto;
        color: #333;
      }
      .cp-brand {
        color: #333;
        font-size: 13px;
      }

      @include desktop {
        width: 240px;
        min-width: auto;
      }
      .single-product-image-wrap {
        padding-bottom: 1em;
        position: relative;
        .compare-image {
          height: 150px;
          width: 150px;
          object-fit: contain;
          margin: 0 auto;
        }
        .compare-cancel-button {
          position: absolute;
          right: 8px;
          top: 00px;
          color: var(--primary);
          height: 30px;
          width: 30px;
          padding: 0;
          font-size: 21px;
          color: #afafaf;
          @include desktop {
            right: 25px;
          }
          &:focus {
            box-shadow: none;
          }
        }
        a{
          .fresh-drop{
            cursor: default;
            // margin-left: 30px;
          }
          &:hover{
            text-decoration: none;
          }
        }
      }
    }
  }
  .add-to-cart-section-wrapper {
    display: flex;
    // justify-content: center;
    // margin: 1em 0;
    .add-to-cart-section {
      padding: 1em 0;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 50vw;
      width: 50vw;
      position: relative;
      flex: none;

      @include desktop {
        width: 240px;
        min-width: auto;
      }
      .product-counter-parent {
        left: 50%;
        // transform: translate(-50%, 0);

        &.p-counter {
          position: static;
          box-shadow: none;
          border: 1px solid #f5f6fa;
          // top: 30px;
        }
      }
      .add-to-cart {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);

        .icon-plus {
          position: absolute;
          left: 50%;
          top: 50%;
          font-size: 8px;
          transform: translate(-50%, -50%);
        }
        .c-icon {
          position: relative;
          // top: 1px;
          // left: 0px;
          // font-size: 20px;
          top: 3px;
          left: -1px;
          font-size: 18px;
          @include desktop {
            top: 3px;
            left: 0px;
          }
        }
        &.opacify {
          border: 1px solid var(--primary);
          span {
            opacity: 0.1;
          }
          .icon-plus {
            display: none;
          }
        }
        &.hide-button {
          visibility: hidden;
        }
        .inner-icon {
        }
        padding: 2px 4px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        // border:1px solid var(--primary);
        @include desktop {
          //padding: 4px 8px;
          // width: 45px;
          // height: 45px;
          //box-shadow: 0 0 0 1px #13c7cd;
          .c-icon {
            // font-size: 20px;
            //left: 1px;
          }
        }
        &:focus {
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
        }
      }
      .product-counter-add-cart {
        padding: 4px 12px;
        span {
          font-size: 14px;
        }
      }
    }
  }
  .product-spec-title {
    //width: 100vw;
    background-color: #f5f6fa;
    position: sticky;
    left: 0;
    top: auto;
    height: 35px;
    display: -moz-box;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8e8e8e;
    font-weight: bolder;
    font-size: 12px;
    // text-transform: uppercase;
    letter-spacing: 0.05rem;
    @include desktop {
      //width: auto;
    }
  }
  .product-spec-container {
    width: auto;
    display: flex;
    // justify-content: center;
    // margin-top: 30px;
    @include desktop {
      margin-top: 0;
    }
    &.description {
      align-items: stretch;
      padding-top: 5px;
    }
    .product-spec-single-product {
      flex: none;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 9px 0;
      // word-break: break-all;
      text-align: center;
      border-right: 1px solid #ececec;
      min-width: 50vw;
      width: 50vw; 
      .maven-description-color {
        text-align: left !important;
        position: relative;
        p {
          position: relative;
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 10px;
        }
      }
      &:last-child {
        border-right: none;
      }
      &.prod-desc {
        padding: 0 15px !important;
        position: relative;
        display: block !important;
        text-align: left;
        .see-more {
          position: absolute;
          padding-right: 8px;
          right: 0;
          bottom: 0;
          z-index: 2;
          background-color: $primary;
          font-size: 14px;
          line-height: 18px;
          cursor: pointer;
          color: var(--primary);
        }

        p {
        }
      }
      &.overflowing {
        max-height: 36px;
        overflow: hidden;
      }

      @include desktop {
        width: 240px;
        min-width: auto;
      }
    }
    &.description {
      margin-bottom: 100px;
    }
  }
  .no-compare-product{
    margin-bottom: 40rem;
    i{
      font-size: 5rem;
    color: #828282;
    }
    .cat-menu-title{
      margin-top: 10px;
    }
  }
}
// $start: 0;
// $end: 9;
// @for $i from $start through $end {
//   .col-width-#{$i} {
//     width: #{$i * 220}px;
//     @include desktop{
//      width: #{$i * 240}px;
//     }

//   }
// }
