.footer{
	// position: fixed;
	position:  relative !important;
	// left: 0;
	// right: 0 ;
	// bottom: 0;
	// background-color:#f5f5f5;
	background-color: var(--footer-background-color);
	min-height: $footerHeight;
	//padding:10px 15px;
	@include desktop{
		//padding:0 50px;
	}
	z-index:3;
	.footer-top-section{
		background-color: #f5f5f5;
		@include desktop{
			.footer-top-section-inner{
				padding: 30px 0 113px ;
			}
		}
		& + .footer-bottom-section{
      position: absolute;
      width: 100%;
			.footer-links{
				font-size: 14px !important;
			}
		}
		.footer-links{
			display:block;
			margin-bottom:0.5rem;
		}
		.footer-link-categories{
			display: flex;
    	justify-content: space-between;
			.categories-sub-section{
				margin-right: 40px;
			}
			:nth-child(3){
				margin-right: 0px;
			}
		}
		.footer-section{
			display: flex;
    	justify-content: space-between;
			.footer-sub-section{
				@include desktop{
					margin-right: 40px;
				}
			}
			:nth-child(3){
				margin-right: 0px;
			}
		}
	}
	.website-section{
		width: 100%;
		@include desktop{
			width: 30%;
		}
		.logo{
			// width:205px;
			height: 30px;
		}
		.icon-holder{
			i{
				border:0px solid transparent;
			}
			margin-left:-9px;
			margin-bottom: 17px;
			margin-top: 10px;
			// padding-bottom: 20px;
			@include notDesktop() {
				display: none;
			}
		}
		h6{
			padding: 13px 0px 9.5px 0;
			font-size: 20px;
			width: 100%;
		}
	}
	.footer-cat{
		margin: 0;
	}
	.other-info-section{
		padding-top: 33px;
		@include desktop{
			padding-top: 88px;
			display: grid;
  		grid-template-rows: 65px 35px 35px 35px 35px;
		}
		.section-title{
			font-size:20px;
			margin-top: 0;
			position:relative;
			margin-bottom:0.5rem;
			@include desktop{
				margin-top: 5px;
				margin-bottom:2rem;
			}
		}
		.section-title a:hover{
			text-decoration: underline;
		}
	}
	.referral-banner{
		padding: 0!important;
		border-radius: 6px;
		margin-bottom: 15px;
    display: block;
		img{
			border-radius:3px;
		}
		//border: 2px solid white;
		h5{
			font-size:16px;
		}
		.ref-text{
			margin-bottom: 0!important;
			font-weight:normal;
			line-height: 1.3;
			margin-top: 0px;
			font-size:12px;
		}
		.dummy-link{
			display:none;
		}
	}
	.right-sec{
		> * {
			@include notDesktop{
				margin-top: 1rem;
			}
		}
	}

	.footer-links{
		.item{
			display: inline-block;
			margin: 0 10px;
		}
	}

	.icon-holder{
		a{
			display: inline-block;
			cursor: pointer;
			margin: 0 4px;
		}
		i{
			width: 35px;
			height: 35px;
			border-radius: 50%;
			border: 1px solid currentColor;
			display: inline-block;
			text-align: center;
			line-height: 32px;
			font-size: 20px;
			//color:#333;
		}
		.icon-instagram{
			//font-weight:bold;
		}
	}
	* {
		color:#333;
		color:var(--text-on-footer-background-color);
	}
	.footer-links {
		font-size: 16px;
		background: transparent;
		border: none;
		//color: #bebebe;
		//margin: 0;
		padding: 0;
		.item:hover{
			color:currentColor;
		}

	}
	&.un-fixed-footer{
		//@include notDesktop{
			// position:static;
		//}
	}
}


.footer-links .item:hover {
	// cursor: pointer;
	// color: #005e3a;
	// text-decoration: underline;
}

.default-site{
	.footer.new-footer-design{
		background-color: #000;

		.footer-top-section{
			background: #000;
			padding-bottom: 64px;
			@include desktop {
			  padding-bottom: 0;
			}
			

			*{
				color: #ffffff;
			}

			.footer-top-section-inner{
				padding: 30px 0px 30px !important;
			}

			.website-section {
				
				.img-holder{
					text-align: left;

					@include desktop{
						text-align: left;
					}

					.logo{
					height: 72px !important;
					display: inline-block;
					margin-bottom: 5px !important;

					@include desktop{
						// margin: 0 0 0 -15px !important;
					}
				}
				}
				.title{
					font-size: 18px;
					font-weight: 500;
					margin-bottom: 15px;
					text-align: left;
					@include desktop{
						text-align: left;
						margin-bottom: 10px;
					}

				}
			}

			.section-title{
				font-size: 18px;
				color: #ffffff;
				margin-bottom: 10px;
				margin-top: 40px;

				@include desktop{
					margin-bottom: 20px;
					margin-top: 0;
				}
			}
		}
		.bottom-info-content *{
			font-size: 12px;
			color: #F4F6F8;
			text-align: center;

			.footer-links{
				text-decoration: underline;
			}
		}


		.warning-text{
			@include mobile{
				text-align: left;
			}
		}
	}
}
