
.indicator {
  display: none;
  position: absolute;
  pointer-events: none;
  top: 30%;
  left: 0;
  right: 0;
  text-align: center;
  opacity: 1;
  transition: opacity 300ms;
  &.removeanim {
      animation: removeIndicator 5s forwards;
  }

  .text {
      position: relative;
      background-color: $primary;
      background-color: var(--primary);
      text-align: center;
      font-size: 11px;
      @include desktop{
          font-size: 14px;
      }
      padding: 3px 5px;
      border-radius: 5px;
      color: white;
      display: inline-block;
      box-shadow: 0px 4px 8px 3px rgba(16, 216, 109, 0.13);
  }
  .ring {
      &:before,
      &:after {
          content: "";
          position: absolute;
          background: rgba(131, 131, 131, 0.6);
          top: 50%;
          width: 60px;
          height: 60px;
          @include desktop{
            width: 90px;
            height: 90px;
        }
          left: 50%;
          border-radius: 50%;
          z-index: 0;
          transform: translate(-50%, -50%) scale(1);
          opacity: 0.5;
      }
      &:before {
          animation: xpulse 1.8s infinite 0.8s;
      }
      &:after {
          animation: xpulse 1.8s infinite;
      }
  }
  .icon-click {
      position: absolute;
      left: 6px;
      bottom: -58px;
      font-size: 29px;
      @include desktop{
          left: 61px;
          bottom: -49px;
      }
      color: #f3ce7b;
      text-shadow: -1px 1px 2px rgba(0, 0, 0, 0.62);
      animation: clickAnim 1s infinite alternate ease-out;
  }
}


.indicator-appear {
  opacity: 0;
}
.indicator-appear-active {
  opacity: 1;
}
.indicator-exit {
  opacity: 1;
}
.indicator-exit-active {
  opacity: 0;
}

.indicator-enter {
  opacity: 0;
}
.indicator-enter-active {
  opacity: 1;
}
.indicator-exit {
  opacity: 1;
}
.indicator-exit-active {
  opacity: 0;
}



.product-category :first-child{
  .h-list:first-child{
      .product:first-child{
          .indicator{
              display: block;
          }
      }
  }
}
.v-list .product:first-child {
  .indicator{
      display: block;
  }
}


@keyframes xpulse {
  0% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(1);
  }
  80% {
      opacity: 0.5;
  }
  100% {
      transform: translate(-50%, -50%) scale(1.7);
      opacity: 0;
  }
}

@keyframes removeIndicator {
  0% {
      opacity: 1;
  }
  80% {
      opacity: 1;
  }
  100% {
      opacity: 0;
  }
}

@keyframes clickAnim {
  from {
      transform: translate(0, 0);
  }
  to {
      transform: translate(50px, -35px);
  }
}