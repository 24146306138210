
.modal,
.modal-dialog {
  transition: opacity 300ms, transform 300ms;
}

.anim-modal-appear {
  opacity: 0;
}
.anim-modal-appear-active {
  opacity: 1;
}
.anim-modal-exit {
  opacity: 1;
}
.anim-modal-exit-active {
  opacity: 0;
}

.anim-modal-appear .modal-dialog {
  opacity: 0;
  transform: scale(0.9);
}
.anim-modal-appear-active .modal-dialog {
  opacity: 1;
  transform: translateX(0);
}
.anim-modal-exit {
  opacity: 1;
}
.anim-modal-exit-active .modal-dialog {
  transform: scale(0.9);
  opacity: 0;
}



.anim-modal-enter {
  opacity: 0;
}
.anim-modal-enter-active {
  opacity: 1;
}
.anim-modal-exit {
  opacity: 1;
}
.anim-modal-exit-active {
  opacity: 0;
}

.anim-modal-enter .modal-dialog {
  opacity: 0;
  transform: scale(0.9);
}
.anim-modal-enter-active .modal-dialog {
  opacity: 1;
  transform: translateX(0);
}
.anim-modal-exit {
  opacity: 1;
}
.anim-modal-exit-active .modal-dialog {
  transform: scale(0.9);
  opacity: 0;
}

.animated-popup-wrapper{
  .modal-dialog{
    margin: 0;
    bottom: 0;
    position: fixed;
    right: 0;
    left: 0;
    max-height: 100vh;
    max-height: -webkit-fill-available;
    // overflow: auto;
    
    // transform: translateY(100%);
    transition: opacity 300ms, transform 300ms;
    &.m-scroll{//add this class for scroll
      display: flex!important;
      .modal-body{
        overflow-y:auto;
      }
    }
  }

  @include tab{
    display: flex !important;
    .modal-dialog{
      position: relative;
      left: auto;
      right: auto;
      top: auto;
      bottom: auto;
      margin: auto;
      width: 500px;
    }
  }
}

.slideup-wrapper-appear .modal-dialog  {
  transform: translateY(100%);
  opacity: 0;
}
.slideup-wrapper-appear-active .modal-dialog  {
  transform: translateY(0%);
  opacity: 1;
}
.slideup-wrapper-exit .modal-dialog  {
  transform: translateY(0%);
  opacity: 1;
}
.slideup-wrapper-exit-active .modal-dialog  {
  opacity: 1;
  transform: translateY(100%);
}
@include tab{
  .slideup-wrapper-appear .modal-dialog   {
    transform: translateY(-10%);
    opacity: 0;
  }
  .slideup-wrapper-appear-active .modal-dialog   {
    transform: translateY(0%);
    opacity: 1;
  }
  .slideup-wrapper-exit .modal-dialog   {
    transform: translateY(0%);
    opacity: 1;
  }
  .slideup-wrapper-exit-active .modal-dialog  {
    opacity: 0;
    transform: translateY(-10%);
  }
}


.header-tab-enter  {
  transform: translateY(-100%);
  opacity: 0;
}
.header-tab-enter-active  {
  transform: translateY(0%);
  opacity: 1;
}
.header-tab-exit   {
  transform: translateY(0%);
  opacity: 0;
}
.header-tab-exit-active   {
  opacity: 1;
  transform: translateY(-170%);
}