$actionBtnHeight: 68px;

.product-page-dtc {
    padding-top: calc(var(--navbar-height) + 5px);

    // min-height: calc(100vh - #{$footerMobileHeight});
    @include desktop {
        // min-height:auto;
        padding-bottom: 100px;
    }
    @include min-height-desktop;
    // height: 100%;
    // overflow: auto;
    // position: relative;
		.price-container{
            font-size: 25px;
            .price {
                font-weight: 500;
            }
            .strike-text {
                margin-left: 4px;
                font-size: 14px;
                align-self: center;
                color:var(--primary);
            }
		}
    .add-cart {
        width: 200px;
    }
    .info{
        padding:15px;
        padding-bottom: 100px;
        @include desktop {
            padding-bottom: 10px;
        }
    }
    .info-inner {
        background:white;
        padding:15px;
        // min-height: calc(100% - #{$actionBtnHeight});
       // height: calc(100% - #{$actionBtnHeight});
        //height: 100%;
      //  overflow: auto;
        // padding: 15px;
    }
    .strike-text:after {
        height: 1px;
        background-color: var(--primary);
        transform: translateY(-50%) rotate(0deg);
    }
    .product-name {
        font-size: 1.4rem;
    }
    .products-actions-btn {
        height: $actionBtnHeight;
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
		background-color:white;
        @include desktop {
            // background: $bodyBackgroundColor;
            // background: var(--body-background-color);
            position: static;
            box-shadow: none;
        }
    }
    .img-container {
        // width: 200px;
        // height: 200px;
        margin: 1rem auto;
        @include desktop {
            width: 400px;
            height: 400px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        .sold-out {
            position: absolute;
            top: 50%;
            left: 50%;
            right: 0;
            width: 80%;
            height: auto;
            transform: translate(-50%, -50%);
        }
    }

    .img-gallery-carousal {
        text-align: center;
        overflow: auto;
        ul {
            padding: 0;
            white-space: nowrap;
            // overflow: auto;
            // display: flex;
            // justify-content: center;
            padding: 5px;
            margin-bottom: 3px;
        }
        li {
            list-style-type: none;
            display: inline-block;
            border: 1px solid #eee;
            margin: 0 5px;
            border-radius: 3px;
            img {
                width: 50px;
                height: 50px;
                object-fit: contain;
            }
            &.selected {
                box-shadow: 0px 0px 0 2px $primary;
                box-shadow: 0px 0px 0 2px var(--primary);
            }
            &:only-child {
                display: none;
            }
        }
        .video-thumbnail-holder {
            position: relative;

            vertical-align: middle;

            .dummy-container {
                background-color: #4d4d4d;
                width: 52px;
                height: 52px;
            }
            i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-shadow: 0 0 3px #737373;
                color: #ffffff;
                font-size: 30px;
            }
        }
    }

    .video-container {
        video {
            width: 100%;
        }
    }
    .grid-col {
        border-radius: 4px;
        padding: 10px 0;
        border-top: 1px solid $dividerColor;
        border-bottom: 1px solid $dividerColor;
        border-radius: 0;
    }
        .strain-container {
            color: #ffffff;
            border-radius: 3px;
            padding: 1px 5px;
            background: $primary;
            background: var(--primary);
            font-size: 0.9em;
            @include desktop {
                padding: 2px 9px;
                font-size: 14px;
            }
        }
				.b-count{
					min-width: 10px;
				}
				.icon-close{
					font-size: 10px;
					margin-top: 5px;
                }
   // Hidden for all except kiva
  .prop-65{
      img{
          height:12px;
         
      }
      .p-text{
        font-size:14px;
    }
    // display:none!important;
  }
}
