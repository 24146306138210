
.ccpacompliance{
  // background: #337e96;
  padding: 15px;
  padding-top: $navbarHeight + 15px;
  height: 100%;
  overflow: auto;
  @include min-height-desktop;
  position: relative;
  .react-datepicker-input{
    border-radius: 0 !important;
    font-weight: 400 !important;
    color: #333 !important;
    height: 40px !important;
    background: #f9f9f9 !important;
  }
  .my-custom-datepicker-component{
    .icon-rc-datepicker_next:before, .icon-rc-datepicker_prev:before  {
      content: "\e93b";
    }
    .icon-rc-datepicker_prev{
      transform: scaleX(-1);
      -moz-transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      -ms-transform: scaleX(-1);
    }
  }
  .main-container{
    margin:2em 0 3em;
    .main-title{
      // color:white;
      color:var(--primary);
      font-size: 37px;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 95px;
      .num:after{
        content: '';
        height: 38px;
        position: absolute;
        top: 17px;
        width: 60px;
        margin: 0 0.6em;
        }
      .num{
        color: #c2d1d6;
        padding: 13px;
      }
    }
  }
  .policy-application{
    background-color: #fff;
    border-radius: 3px;
    padding:18px 18px 9px 18px;
    .card{
    // border: 1px solid #e0eded;
    }
    .card-header{
      padding: 15px 20px;
      border-bottom: 1px solid #e0eded;
      border-radius: 2px 2px 0 0;
      background: #fcfcfc;
      word-wrap: break-word;
      overflow-x: hidden;
      h2{
        font-size:22px;
        color:#333;
      }
      p{
        font-size: 13px;
        color: #616161;
        line-height: 20px;
        margin-bottom: 0;
        margin-top: 15px;
        a{
          color: #2067b0;
          text-decoration:none;
        }
      }
    }
    h3{
      font-size:16px;
      margin-bottom: 1.5em
    }
    .driver-app-input{
      height:40px;
      border-radius: 0;
      font-weight: normal;
      color: #333;
      padding-right: 3em;
    }
    .card-body{
      .divider{
        border-bottom: 1px solid #aaafb5;
      }
      .driver-app-input{
        background: #f9f9f9;
      }
      label{
        font-size: 13px;
      }
      .form-label:after{
        content: "*";
        position: absolute;
        margin: 0 0 0 2px;
        color: #f11a48;
        // font: normal 8px 'FontAwesome';
      }
      .select-phone{ 
        span{
          font-size: 15px;
        }
        .form-check-inline {
          margin-right: 2rem;
          .form-check-input {
            margin-right: .5rem;
            vertical-align: middle !important;
          }
          .form-check-label{
            display: unset !important;
            vertical-align: middle !important;
          }
        }
      }
      .invalid-tooltip{
        top: -38px;
        right: 0;
        left: auto;
        margin: 5px 0 0 0;
        padding: 4px 12px 4px;
        line-height: 16px;
        background: #f2dedf;
        font-size: 11px;
        color: #f11a48;
        position: absolute;
      }
      .invalid-tooltip:after{
        content:'';
        top: 100%;
        right: 50px;
        border-style: solid;
        border-width: 8px 8px 0 8px;
        border-color: #f2dfdf transparent transparent transparent;
        position: absolute;
      }
      .icon-wrap{
        position: absolute;
        top: 6px;
        width: 34px;
        height: 29px;
        line-height: 29px;
        font-size: 15px;
        color: #ccc;
        text-align: center;
        right: 5px;
        padding: 0 0 0 3px;
        border-left: 1px solid;
        z-index: 10;
      }
    }
    .form-group {
      .input-group{
        background: #f9f9f9;
        .input-group-append{
        position: absolute;
        right:0;
        height:100%;
        z-index:9;
        }
      }
      .form-textarea{
        height:57px;
        resize: none;
        border-radius: 0;
        font-weight: normal;
        padding-right: 1em;
          font-size: 15px;
        color: #333;
        background: #f9f9f9;
      }
    }
    .submit-data{
      padding: 15px 0;
      .agree-caption{
        padding: 15px 20px;
        font-size: 15px;
      }
      button , button:focus{
        // margin: 0 auto;
        // height: 42px;
        // border-radius: 0;
        // color: #FFFFFF !important;
        // background: #00AEEF !important;
        // border:none;
        // outline:none;
      }
    }
  }

  .flex-col {
    flex-direction: column;
  }
}
.ccpa{
  .modal-body{
    margin:1em 0 0 0;
  }
  .submit-button{
    width:30% !important;
    padding:9px 0 !important;
  }
  .divider{
    border-bottom: 1px solid #E0E0E0;
    margin: 1em 1.5em;
  }
  .ccpa-modal{
    .label-grey{
      color:#333;
      font-weight: 500;
      font-size: 20px;
    }
    .card {
      padding: 10px;
      border-radius: 20px;
      background: #fff;
      border: none;
      height: 350px;
      position: relative
    }

    .mobile-text {
      color: #989696b8;
      font-size: 14px;
      border:none;
      background:none;
    }

    .form-control {
      margin-right: 12px;
      width:40px  !important;
      border-radius: unset !important;
      text-align: center;
    }

    .form-control:focus {
      color: #495057;
      background-color: #fff;
      border-color: #13c7cd;
      outline: 0;
      box-shadow: none
    }

    .cursor {
      cursor: pointer
    }
  }
}