.marquee-container {
  overflow-x: hidden !important;
  display: flex !important;
  flex-direction: row !important;
  position: relative;
  width: calc(100% - 16px);

  // &:hover div {
  //   animation-play-state: var(--pause-on-hover);
  // }

  // &:active div {
  //   animation-play-state: var(--pause-on-click);
  // }
}
.marquee {
  flex: 0 0 auto;
  min-width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 5px;
  // animation: scroll var(--duration) linear var(--delay) var(--iteration-count);
  animation-play-state: var(--play);
  animation-delay: var(--delay);
  animation-direction: var(--direction);
  text-align: center;
  @keyframes scroll {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  & + .marquee{
    // margin-left: 10px;
  }
  &.m-animate{
    padding :0 var(--marquee-gap,20px);
    animation: scroll var(--duration) linear var(--delay) var(--iteration-count);
  }
}
.w-100px{
  width: 100px!important;
  min-width: 100px!important;
  background-color:green;
  height:10px;
  animation: scroll 5s linear var(--delay) var(--iteration-count)!important;
}