.slider-wrap {
  margin-bottom: 2.8rem !important;
  margin-top: 1.3rem !important;
  @include desktop {
    margin-bottom: 4.4rem !important;
    margin-top: 2.3rem !important;
  }
  .slick-track {
    display: flex !important;
  }
  .slick-slide {
    height: inherit !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    .banner-inner {
      img {
        border-radius: 8px;
      }
    }
  }

  .slick-slide > div {
    margin: 0 10px;
  }
  .slick-list {
    margin: 0 -10px;
  }
  .slick-dots li {
    height: unset !important;
    width: unset !important;
    // margin: 0px;
    margin: 0px 8px 0px 0px;

    &::before {
      content: "";
      width: 8px;
      height: 8px;
      opacity: 0.75;
      display: inline-block;
      background: #ebebeb;
      border-radius: 19px;
      cursor: pointer;
      transition: opacity 0.3s, background-color 0.3s, width 0.3s;
      transition-delay: 0.3s, 0.3s, 0s;
    }
    button {
      position: absolute;
      margin: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      &:before {
        display: none;
      }
    }
  }
  .slick-dots li.slick-active {
    &:before {
      background: var(--primary);
      width: 24px;
      opacity: 1;
      transition-delay: 0s;
    }
  }
  .banner-content {
    // display: none;
  }
  .carousel {
    overflow: unset;
    .slider-wrapper {
      // position: unset;
      // overflow: unset;
      .slider {
        // margin: 0 -10px;
        .slide {
          padding: 0px;
          // // margin: 0 10px;
          // margin-right: 10px;
          // &.selected{
          //   margin-right: 0px;
          // }
          img {
            border-radius: 8px;
          }
        }
      }
    }
    .control-dots {
      // margin: 8px 0px 0px 0px;
      position: absolute;
      bottom: -29px;
      margin: 0;
      .dot {
        margin: 0px 8px 0px 0px;
        width: 8px;
        height: 8px;
        opacity: 0.75;
        display: inline-block;
        background: #ebebeb;
        border-radius: 19px;
        cursor: pointer;
        transition: opacity 0.3s, background-color 0.3s, width 0.3s;
        transition-delay: 0.3s, 0.3s, 0s;
        box-shadow: none;
        &.selected {
          background: var(--primary);
          width: 24px;
          opacity: 1;
          transition-delay: 0s;
        }
      }
    }
  }
  .slick-arrow{
    display: none !important;
  }
  &.full-width-banner {
    margin-bottom: -50px !important;
    overflow: hidden;

    @include desktop() {
      margin: 0 !important;
    }

    .slider-wrapper {
      margin-top: 60px !important;
      height: 210px;

      @include combineIpad() {
        height: 400px;
      }

      @include ipadPro() {
        height: 400px;
      }

      @include ipadProLandscape() {
        height: 400px;
      }

      @include desktop() {
        margin: -10px 0px 0 !important;
        height: 600px;
      }

      ul {
        height: 100%;

        .banner-inner {
          height: 100%;
        }
      }

      picture {
        width: 100%;
        height: 100%;
        display: flex;

        img {
          object-fit: cover;
          height: auto;
          width: 100%;
          border-radius: 0 !important;
        }

      }
    }
  }
}
// .banner-control-2 {
//   @include desktop {
//     margin-bottom: 2.5rem !important;
//   }
// }
// .banner-control-1 {
//   margin-bottom: 1rem !important;
//   @include desktop {
//     margin-bottom: 2.5rem !important;
//   }
// }
