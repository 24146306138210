.id-upload {
    position: relative;
    padding: 0px 16px;
    margin-bottom: 1rem;
    //background: #d9f6f7;
    //background: var(--primary-light-45);
    //border: 1px solid #6ddde0;
    //border: 1px solid var(--primary-light-25);
    border-right: 0;
    border-left: 0;
    margin-top: -1px;
    .progress {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 4px;
    }
    .img-container {
        //padding-left: 15px;
        // height: 80px;
        // margin-bottom: 0.8rem;
        img {
            width: 115px;
            height: 137px;
            padding:5px;
            object-fit: contain;
            
        }
        i {
            display: inline-block;
            margin-bottom: 20px;
            font-size: 35px;
            border: 2px dotted lightgray;
            padding: 40px;
            border-radius: 13px;
            color: #CACFD4;
        }
    }
    .btn-warning:active,
    .btn-warning:hover {
        background-color: #ffc107 !important;
        border-color: #ffc107 !important;
        color: white !important;
    }
    .selfie-block,
    .photo-id-block
     {
        // width: 100%;
        // padding: 15px;
        width: 48%;
        padding: 20px;
        margin-top: 0px;
        cursor: pointer;
        @include desktop{
            // width: 48%;
            // padding: 20px;
            // margin-top: 0px;
        }

        span{
            font-size: 12px;
        }
        .icon-u-card , .icon-u-selfie{
            font-size: 20px;
        }
        img{
            border-radius: 6px;
            object-fit: contain !important;
            height: 40px !important;
            width:40px !important;
            padding: 0px !important;
           
            @include desktop{
                width: 115px;
                height: 137px;
            }
        }
        .img-container{
            display: flex;
            align-items: flex-start;
            padding:8px;
            background-color: #F8F8F8;
            margin-top: 15px;
            border-radius: 6px;
            i{
                font-size: 20px;
                color: #4CAF50;
                border:none;
                padding: 0px;
                margin-bottom: 0px;
            }
        }
        .btn-amt{
            border:1px solid var(--primary);
            margin-right: 0px;
            padding: 5px 18px;
            border-radius: 6px;
            font-size: 14px;
            i{
                color:var(--gd-black-color);
            }
        }
        .btn-plus{
            display: flex;
            height: 30px;
            width: 30px;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            border: 1px solid var(--primary);
        }
       
    }
    .medical-id-block{
      cursor: pointer;
      width:100%;
      padding: 15px;
      @include desktop{
        padding: 20px;
      }
      span{
        font-size: 12px;
       
    }
    .icon-u-card{
        font-size: 20px;
    }
    .btn-plus{
        display: flex;
        height: 30px;
        width: 30px;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        border: 1px solid var(--primary);
    }
    .img-container{
        display: flex;
        align-items: flex-start;
        padding:8px;
        background-color: #F8F8F8;
        margin-top: 15px;
        border-radius: 6px;
        i{
            font-size: 20px;
            color: #4CAF50;
            border:none;
            padding: 0px;
            margin-bottom: 0px;
        }
        img{
            width: 40px;
            height: 40px;
            padding: 5px;
            object-fit: contain;
            padding: 0px !important;
            border-radius: 6px;
        }
    }
    .text-block{
        text-align: left;
    }
    }
    &.drweedy-upload .photo-id-block{
        flex: 1;
    }  
}
