.weedy-med-card{
  .customer-info{
      padding-top: 10px !important;
      .addressbox-parent{
          .country-code{
              top: 22px;
              border: 1px solid transparent;
              left: 5px;
          }
          .phone-number{
              padding-left: 80px;
          }
      }
      .custoinfo-border, .custoinfo-padding{
          padding: 0px !important;
      }
      .custoinfo-title{
          margin-top: 10px;
      }
      .drweedy-upload{
          padding: 0px;
      }
      #customer-address{
          padding: 0;
      }
      .addressbox-parent{
          margin-bottom: 0px;
          @include desktop{
              margin-bottom: 1rem;
          }
      }
  } 
  .auth-status{
    margin: 0px;
  }
}