
$checkboxSideLength: 22px;
$checkboxBorderColor: #b3b3b3;
$checkBoxTickColor: $primary;

.custom-pill-checkbox {
    position: relative;
		// margin-right: 1px;
		
		display: inline-block;
		// margin-bottom: 10px;
    & + .custom-pill-checkbox {
        margin-left: 10px;
    }
    input {
        display: none;
        &:checked + label {
                border: 3px solid var(--primary);
            }
        }
    label {
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        line-height: $checkboxSideLength;
        position: relative;
				border: 2px solid $dividerColor;
				padding: 3px 10px;
				    border-radius: 5px;
						margin-bottom: 0;

    }
}
@keyframes popanim {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(1);
    }
}
