.happy-hours {
  padding: 0;
  // background: #FFFFFF;
  border-radius: 8px 8px 4px 4px;
  display: block;
  // margin-bottom: 2rem;

  // .hht-sec{
  //   display: none;
  // }
  @include desktop {
    background: none;
    box-shadow: none;
    border-radius: 0;
    display: flex;
    align-items: center;
    margin-top: 3px;
  }

  .timer-content {
    // width: 100%;

    .timer-head {
      text-align: center;
      font-weight: 600;
      line-height: 16px;
      // border-radius: 8px;
      font-size: 13px;

      @include desktop {
        font-size: 14px;
        line-height: 17px;
      }
    }

    .timer {
      background-color: white;
      border-radius: 4px;
      // width: 100%;

      h4 {
        text-align: center;
        letter-spacing: 0.16em;
        color: #E14662 !important;
        line-height: 19px;
        font-size: 16px;

        @include desktop {
          font-size: 24px;
          line-height: 29px;
        }

        b {
          color: #E14662 !important;
        }
      }
    }
  }

}

.hh-cartwidget {
  .happy-hours {
    margin: 0px 0 8px;
    width: 100% !important;
    .timer-content {
      // width: 100%;
      // display: flex !important;
      // border-radius: 0px;
      // justify-content: space-between;
      padding: 7px 10px !important;
      .timer-head {
        font-size: 13px;
        line-height: 16px;
      }

      .timer {
        h4 {
          font-size: 16px;
          line-height: 19px;
        }
      }
    }

    .timer-head-wrapper {
      // margin-left: 60px !important;
    }
  }

  &+.c-list {
    position: relative;
    z-index: -2;
  }
}

.prodpage-hh {
  .happy-hours {
    flex-direction: column;

    .timer-content {
      border-radius: 8px;
      justify-content: center;
    }



    .timer {
      border-radius: 8px;
        @include desktop {
          font-size: 24px !important;
        }
    }

    .timer-head-wrapper {
      margin-left: 0 !important;
    }
  }

  .timer-head {
    @include desktop {
      font-size: 16px !important;
    }
  }

  .cc-info {
    display: none;
  }
}
.prodpage-hh{
  .ship-disc-text {
    text-align: center;
    .ship-disc-text-item{
      padding: 5px;
      margin: 0 5px;
      display: inline-flex;
    }
  }
}
.hht-sec{
  .ship-disc-text {
    text-align: center;
    .ship-disc-text-item{
      padding: 0 5px;
      margin: 0;
      display: inline-flex;
    }
  }
}

.right-cart {
  .happy-hours {
    // position: fixed;
    // top: 0;
    // left: 0;
    // z-index: 11;
    // right: 0;
    // transition: top 0.5s ease-in-out;
    // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);

    // @include desktop {
    //   position: unset;
    //   box-shadow: unset;
    //   padding-top: 34px;
    // }

    // @include ipad {
    //   position: unset;
    //   box-shadow: unset;
    // }

    // .timer-content,
    // .timer {
    //   border-radius: 8px;
    // }

    // .timer-head {
    //   @include desktop {
    //     font-size: 16px !important;
    //   }
    // }
    // .timer-content{
    //   @include desktop{
    //     justify-content: center;
    //   }
    // }
    // .timer {
    //   @include desktop {
    //     font-size: 20px !important;
    //   }
    // }
    
  }

  // .sticky-timer {
  //   top: var(--navbar-height);
  // }
}

.delivery-type-toggle {
  @include ipadPro {
    padding-left: 12px !important;
  }

  @include ipadLandscape {
    padding-left: 12px !important;
  }
}

.hh-invoice-text-color {
  color: #FF6464;
}

.hh-invoice-sucesss{
  color: var(--primary) !important;
}

.hh-invoice {
  .strike-text {
    color: var(--primary) !important;
  }

  .strike-text:after {
    background-color: var(--primary) !important;
  }
  
}

.cart-fix-container .happy-hours {
  // margin: 0 0 2rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 0 !important;
}


.right-cart {
  .happy-hours{
    margin-top: 40px;
  }
}

.cart-fix-container,
.hh-cartwidget {
  .timer-sec .timer-content{
    border-radius: 0;
  }
  .hht-sec .ship-disc-text {
    i {
      width: 22px;
      height: 22px;
    }
    span {
      font-size: 14px
    }

    ;
  }
}

.cart-fix-container,
.hh-cartwidget {
  .happy-hours {
    // @include notDesktop {
    //   position: relative;
    //   box-sizing: border-box;
    //   $border: 8px;
    //   color: #FFF;
    //   background: #fff;
    //   background-clip: padding-box;
    //   border: solid 1px transparent;
    //   border-radius: 0px;

    //   &:before {
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     bottom: 0;
    //     left: 0;
    //     z-index: -1;
    //     margin: -1px;
    //     border-radius: inherit;
    //     background: linear-gradient(to right, #6B3BBF, #138C91);
    //   }
    // }
  }
}

.prodpage-hh {
  margin-top: 15px;
  @include desktop {
    margin-top: 30px;
    margin-bottom: 15px;
  }

  .happy-hours {
    margin-bottom: 0px;
    border-radius: 8px;
    position: relative;
    box-sizing: border-box;
    $border: 8px;
    color: #FFF;
    background: #fff;
    background-clip: padding-box;
    border: solid 1px transparent;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
      margin: 0;
      border-radius: inherit;
      background: linear-gradient(to right, #6B3BBF, #138C91);
    }
    .hh-tooltip{
      cursor: default;
      &.benefits-open{
        border-radius: 8px;
        .hht-sec{
          display: none;
        }
      }
    }
  }

  .ship-disc-text {
    i {
      font-size: 22px;
      color: var(--primary-dark);
      padding-right: 15px;
    }

    span {
      color: var(--primary-dark);
      line-height: 19.49px;

      @include desktop {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
}