$loader-grey:  #e8e8e8;
$loader-lightgrey:  #ddd;

.product-loader{
  background-color:white;
  position: relative;
  z-index: 99;
  margin-top: 30px;
  width:100%;
  @include desktop{
    margin-top: 150px;
  }
  .productloader-left,
  .productloader-right
    {
      width:100%;
          border-radius: 7px;
          background-size:400px;
          background-image: linear-gradient(
            90deg,
            $loader-lightgrey 0px,
            $loader-grey 40px,
            $loader-lightgrey 80px
          );
          animation: shine 0.7s infinite linear;
    }
  .productloader-left{
    height: 407px;
    width: 100%; 
    @include desktop{
      height: 500px;
    }  
  }
  .productloader-right{
    margin-top: 20px;
    height: 730px;
    width: 100%; 
    @include desktop{
      height: 500px;
      margin-top: 0px;
    }
  }
  .vertical-line:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0%;
    width: 1px;
    background: #dadada;
}
@keyframes shine{
  0%{
    background-position: -100px;
    
  }
  100%{
    background-position: 300px;
    
  }
}

}