.range-slider{
margin-top: 20px;
margin-bottom: 20px;
padding:0 15px;
  .r-inner{
    // height: '36px',
    // display: 'flex',
    // width: '100%'
    height: 36px;
  display: flex;
  width: 100%;
  }
  .r-track{
    height: 3px;
    width: 100%;
    border-radius: 4px;
    align-self: center;
  }
  .r-floting-label{

		position: absolute;
		top: -24px;
		color: #fff;
		padding:0 4px;
		border-radius: 4px;
		background-color: var(--primary);
    transition: transform 0.3s;
  }
  .r-thumb{
		--thumbDimens:23px;
		height: var(--thumbDimens);
		width: var(--thumbDimens);
		//top:var(--thumbDimens);
		border-radius: 50%;
		background-color: #FFF;
		display: flex;
		justify-content: center;
		align-items: center;
		box-shadow: 0px 2px 6px #AAA;
    
  }
  [data-position="bottom"]{
    &.r-floting-label{
      top: auto;
    bottom: -23px;
    }
  }

}
