.loyalty {
    position: relative;
    // padding-top: $navbarHeight + 5px;
    height: calc(100% - var(--navbar-height) - var(--secondary-navbar-height));
    overflow: auto;
    @include desktop {
        max-width: $desktopContentWidth;
        margin:auto;
    }
		@include min-height-desktop;

    .loyalty-content {
        padding: 15px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
    }

    .loyalty-loader {
        position: absolute;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);
    }

    .loyalty-header {
        margin-bottom: 25px;
    }

    .loyalty-discount-percent {
        color: $primary;
        color: var(--primary);
    }
}
