$actionBtnHeight: 68px;

.product-page {
	padding-top: calc(var(--navbar-height) + 5px);
	// min-height: calc(100vh - #{$footerMobileHeight});
	@include desktop {
		// min-height:auto;
		padding-bottom: 100px;
	}
	@include min-height-desktop;
	// height: 100%;
	// overflow: auto;
	// position: relative;
	.price-container {
		font-size: 25px;
		.price {
			font-weight: 500;
			&.discount {
				// color: #ff7f68 !important;
			}
		}
		.strike-text {
			margin-left: 4px;
			font-size: 14px;
			align-self: center;
			color: var(--primary);
			// color:var(--primary);
		}
	}
	.add-cart {
		width: 200px;
	}
	.info {
		padding-bottom: 40px;
		margin-top: 1rem;
		// background:white;
		@include desktop {
			padding: 15px;
			// padding-bottom: 30px;
			// box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
			// border-radius: 6px;
			margin-top: 0;
			margin-bottom: 1rem;
		}
	}
	.sale {
		background: #ff7f68;
		color: white !important;
		padding: 4px 5px;
		border-radius: 3px;
		line-height: 1;
	}
	.vertical-line {
		position: relative;
		@include desktop {
			&:after {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				left: 50%;
				width: 1px;
				background: $dividerColor;
			}
		}
	}
	.info-inner {
		background: white;
		padding: 15px;
		// min-height: calc(100% - #{$actionBtnHeight});
		// height: calc(100% - #{$actionBtnHeight});
		//height: 100%;
		//  overflow: auto;
		// padding: 15px;
	}
	.strike-text:after {
		height: 1px;
		// background-color: var(--primary);
		transform: translateY(-50%) rotate(0deg);
	}
	.product-name {
		font-size: 1.4rem;
	}
	.products-actions-btn {
		height: $actionBtnHeight;
		position: fixed;
		bottom: 0;
		right: 0;
		left: 0;
		background-color: white;
		@include desktop {
			// background: $bodyBackgroundColor;
			// background: var(--body-background-color);
			position: static;
			box-shadow: none;
		}
	}
	.img-container {
		// width: 200px;
		// height: 200px;
		margin: 0rem auto;
		@include desktop {
			width: 400px;
			height: 400px;
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
		.sold-out {
			position: absolute;
			top: 50%;
			left: 50%;
			right: 0;
			width: 80%;
			height: auto;
			transform: translate(-50%, -50%);
		}
	}

	.img-gallery-carousal {
		text-align: center;
		overflow: auto;
		ul {
			padding: 0;
			white-space: nowrap;
			// overflow: auto;
			// display: flex;
			// justify-content: center;
			padding: 5px;
			margin-bottom: 3px;
		}
		li {
			list-style-type: none;
			display: inline-block;
			border: 1px solid #eee;
			margin: 0 5px;
			border-radius: 3px;
			img {
				width: 50px;
				height: 50px;
				object-fit: contain;
			}
			&.selected {
				box-shadow: 0px 0px 0 2px $primary;
				box-shadow: 0px 0px 0 2px var(--primary);
			}
			&:only-child {
				display: none;
			}
		}
		.video-thumbnail-holder {
			position: relative;

			vertical-align: middle;

			.dummy-container {
				background-color: #4d4d4d;
				width: 52px;
				height: 52px;
			}
			i {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				text-shadow: 0 0 3px #737373;
				color: #ffffff;
				font-size: 30px;
			}
		}
	}

	.video-container {
		video {
			width: 100%;
		}
	}
	.grid-col {
		border-radius: 4px;
		padding: 10px 0;
		border-top: 1px solid $dividerColor;
		border-bottom: 1px solid $dividerColor;
		border-radius: 0;
	}
	.strain-container {
		//color: #ffffff;
		//border-radius: 3px;
		//padding: 1px 5px;
		//font-size: 0.9em;
		//@include desktop {
		//padding: 2px 9px;
		//font-size: 14px;
		//}
	}
	.b-count {
		min-width: 10px;
	}
	.icon-close {
		font-size: 10px;
		margin-top: 5px;
	}
	// Hidden for all except kiva
	.prop-65 {
		img {
			height: 12px;
		}
		.p-text {
			font-size: 14px;
		}
		// display:none!important;
	}
	.attribute-grid {
		//display:grid;
		//grid-template-columns: repeat(auto-fill,minmax(80px,auto));
		//grid-auto-flow:column;
		//grid-gap:15px;
		//display: flex;
		//margin: -5px;
		//flex-wrap: wrap;
		white-space: nowrap;
		overflow-x: auto;
		.a-item {
			display: inline-flex;
			// background: var(--primary-light-50);
			background: #e3fcfc;
			border-radius: 30px;
			padding: 8px 18px;
			min-width: 80px;
			margin: 5px;
			& > div {
				white-space: nowrap;
			}
			& > div:first-child {
				font-weight: bold;
				margin-right: 3px;
			}
			&:first-child {
				margin-left: 0;
			}
		}
		@include desktop {
			white-space: normal;
			.a-item {
				margin-left: 0;
			}
		}
	}
	.hybrid-spectrum-parent {
		.progress {
			flex: 1;
			height: 6px;
		}
		i {
			font-size: 32px;
		}
	}
	.shop-full-menu {
		min-height: auto;
		padding: 0;
		@include desktop {
			margin-top: 2rem;
			padding-top: 0 !important;
		}
		.a-category-name {
			font-size: 20px;
			margin-bottom: 5px;
			@include desktop {
				font-size: 26px;
			}
		}
	}
	.add-to-cart-holder {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 999;
		padding: 10px 15px;
		//background:var(--body-background-color);
		//box-shadow: 0 -.325rem 1.25rem -12px #999!important;
		.add-cart-inner {
			cursor: pointer;
			position: relative;
			background-color: var(--primary);
			border-radius: 0px !important;
			box-shadow: 0 -0.325rem 1.25rem -12px #999 !important;
			padding: 10px 15px;
			.wriggle-trigger {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 140px;
			}
			* {
				color: #fff;
			}
			.p-left-sec {
				* {
					vertical-align: middle;
				}
				.l-icon {
					font-size: 20px;
				}
			}
			.p-right-sec {
				.count {
					font-size: 16px;
					background: #f7f7f7;
					min-width: 30px;
					height: 30px;
					line-height: 30px;
					padding: 0 5px;
					margin: 0 8px;
					text-align: center;
					color: #333;
					border-radius: 5px;
				}
				.btn {
					font-size: 20px;
					padding: 0;
					height: 35px;
					width: 35px;
					line-height: 35px;
				}
				.plus-btn {
				}
				&.is-animating {
					animation: shake-vertical 0.5s ease-in-out;
				}
			}
		}
		@include desktop {
			position: static;
			box-shadow: none;
			//background:transparent;
			//box-shadow: none!important;
			padding: 0;
			.add-cart-inner {
				.wriggle-trigger {
					right: 162px;
				}
				padding: 10px 20px;
				.p-left-sec {
					.l-icon {
						font-size: 23px;
					}
					.pp-text {
						font-size: 18px;
					}
				}
				.p-right-sec {
					.btn {
						font-size: 23px;
						height: 35px;
						width: 40px;
					}
				}
			}
		}
		@include real-iphone5 {
			.l-icon {
				display: none;
			}
		}
		.wishlist-icon {
			.wishlist-btn {
				&:focus {
					outline: none;
					box-shadow: none;
				}
			}
			.icon-heart-outline,
			.icon-heart-filled {
				color: #dc7070;
				font-size: 20px;
			}
			.wishlist-anim {
				color: #dc7070;
				animation: pulse 0.7s;
			}
		}
	}
	.product-tile-controls {
		display: flex;
	}
	// fbf0ef

	.pdp-review-sections {
		.pdp-review-list {
			box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
			padding: 1.5rem;
			border: 1px solid rgba(0, 0, 0, 0.05);
			.pdp-review-card {
				border-bottom: 1px solid #d9d9d9;
				margin-bottom: 1.5rem;
				.pdp-rc-above {
					width: 100%;
					display: flex;
					justify-content: space-between;
					.stars-section {
						.star-info {
							display: flex;
							align-items: center;
							p {
								margin: 0;
							}
							span {
								height: 8px;
								width: 8px;
								border-radius: 50%;
								background-color: var(--primary);
							}
						}
					}
					.date {
						color: rgba(0, 0, 0, 0.4);
					}
				}
				.pdp-rc-pills {
					margin: 1rem 0;
					button {
						background-color: #fbf0ef;
						border: 0;
						padding: 0.5rem 1.5rem;
						border-radius: 2rem;
						font-size: 12px;
					}
				}
			}
		}
	}
	.terpen-title {
		font-size: 16px;
		color: #333333;
		// font-weight: bold;
		margin-bottom: 20px;
		margin-top: 30px;
	}
	.terpen-section {
		border-bottom: 1px solid #f2f2f2;
		margin-bottom: 24px;
		padding-bottom: 24px;
		.terpen-profile {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 80px;
			.terpen-icon {
				font-size: 20px;
				margin-right: 15px;
			}
			img {
				margin-right: 20px;
				height: 30px;
				width: 30px;
				overflow: hidden;
			}
		}
		.terpen-block {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: calc(100% - 80px);
			.terpen-name {
				font-size: 16px;
				font-weight: 400;
			}
		}
		.terpen-effects-flv {
			width: 100%;
			padding-left: 80px;
			.terpen-eff {
				display: flex;
				flex-wrap: wrap;
			}
			// span{
			// 	width:70px;
			// 	flex: none;
			// }
			.terpen-effects {
				background-color: #f2f2f2;
				border-radius: 3px;
				border: 0;
				padding: 4px 10px;
				font-size: 14px;
				font-weight: 400;
				margin-bottom: 5px;
				width: auto;
			}
			.terpen-flavours {
				border: 1px solid #f2f2f2;
				background-color: #ffffff;
				border-radius: 3px;
				padding: 4px 10px;
				font-size: 14px;
				font-weight: 400;
				margin-bottom: 5px;
			}
		}
		.terpen-mt {
			padding-top: 10px;
		}
	}
	.sticky-scroll {
		position: sticky;
		top: 0;
		z-index: 1;
	}
	.s-product-container {
		margin-top: 40px !important;
	}
	.stars-wrapper {
		// display: flex;
		// margin-top: 1.5rem;
		i {
			font-size: 17px;
			margin-right: 5px;
			cursor: pointer;
			transform: scale(1);
			transition: 0.3s;
			&:hover {
				transform: scale(1.2);
			}
		}
		.colored-star {
			color: #ffc107;
		}
		.gray-star {
			color: #dddddd;
		}
	}

	.disable-add-cart {
		background-color: #bcbcbc !important;
		pointer-events: none;
	}

	.delivery-tabs {
		.products-availability {
			display: none !important;
		}
	}

	.best-seller {
		font-size: 14px;
	}
	.wishlist-sec {
		display: flex;
		border: 1px solid var(--primary);
		border-radius: 4px;
		align-items: center;
	}

	.img-grid-carousel {
		.fresh-drop {
			margin-left: 15px;
			top: 0px;
			@include desktop() {
				position: absolute;
				top: 25px;
				left: 25px;
			}
		}
	}

	.product-popup-flex {
		.asapcounter {
			display: inline-flex !important;
			min-height: 60px;
			cursor: default;
			border-radius: 8px !important;
			background-color: transparent;
			border: 1px solid var(--secondary);
			padding: 5px 6px 5px 0;
			@include notDesktop() {
				width: 100%;
			}
			.counterheader {
				font-size: 14px;
				font-weight: 600;
				@include desktop() {
					// min-width: 280px;
					width: auto;
					font-size: 18px;
				}
				@include notDesktop() {
					width: 45%;
				}

				@include mobile {
					padding-top: 0;
					padding-bottom: 0;
				}
				.counter {
					font-size: 12px;

					margin-left: 20px;
					@include desktop() {
						font-size: 12px;
						margin-left: 0px;
					}

					@include mobile {
						margin-left: 0;
					}
				}
				.l-icon {
					top: 8px;
					margin-right: 0;
					left: -12px;
				}
				&.m-item {
					background-color: transparent;
				}
			}
			.scheduled-tab {
				border-radius: 0 8px 8px 0 !important;
				min-width: 210px;
				@include desktop() {
					// min-width: 280px;
					width: auto;
					font-size: 16px;
				}
				@include notDesktop() {
					width: 55%;
				}

				@include mobile {
					padding-top: 0;
					padding-bottom: 0;
					display: flex;
					flex-direction: column;
					justify-content: center;
					padding-left: 6px;
				}
				font-size: 12px;
				min-height: 60px;
			}

			&.product-delivery-tabs {
				padding: 5px;
				height: 80px;
				color: var(--secondary);

				.m-item {
					background-color: transparent;
					border-radius: 0 !important;
				}

				.counterheader {
					padding-top: 0;
					padding-bottom: 0;
					height: 100%;
					&.t-selected {
						border-radius: 8px 0 0 8px !important;
					}
				}

				.scheduled-tab {
					padding-top: 0;
					padding-bottom: 0;
					height: 100%;
					color: #000 !important;
					&.t-selected {
						border-radius: 0px 8px 8px 0 !important;
					}
				}
			}

			@include mobile {
				white-space: nowrap;
				height: 80px;
			}
		}
		.l-icon {
			font-size: 19px;
			position: relative;
			top: 0;
			margin-right: 15px;
		}
	}
}

@keyframes shake-horizontal {
	20% {
		transform: translate(-4px, 0) rotate(0);
	}
	40% {
		transform: translate(7px, 0) rotate(0);
	}
	60% {
		transform: translate(-4px, 0) rotate(0);
	}
	80% {
		transform: translate(-2px, 0) rotate(0);
	}
	100% {
		transform: translate(0px, 0) rotate(0);
	}
}

@keyframes shake-vertical {
	20% {
		transform: translate(0, -3px) rotate(0);
	}
	40% {
		transform: translate(0, 4px) rotate(0);
	}
	60% {
		transform: translate(0, -5px) rotate(0);
	}
	80% {
		transform: translate(0, 4px) rotate(0);
	}
	100% {
		transform: translate(0, 1px) rotate(0);
	}
}
