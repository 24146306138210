.shop-full-menu {
    min-height: 100vh;
    @include desktop {
        padding: 0px;
        padding-top: 0;
    }
    @include min-height-desktop;
    padding-top: var(--navbar-height);
    &.header-hidden {
        .search-category-wrapper {
            transition: 0.3s;
            top: 0;
        }
    }
    .all-products-slider .product .img-wrapper {
        // padding-top: 25px;
        height: 180px;
        @include desktop {
            height: 180px;
        }
        div,
        span {
            height: 100%;
        }
    }
    .filter-indicator {
        width: 10px;
        height: 10px;
        background-color: #f44336;
        border-radius: 50%;
        position: absolute;
        right: 0;
        top: 6px;
        @include desktop {
            top: 3px;
            right: 3px;
        }
    }
    .filter-fab-parent {
        position: relative;
        .filter-indicator {
            position: absolute;
            width: 12px;
            height: 12px;
            right: 2px;
            top: 3px;
            background-color: #f44336;
        }
    }

    .filter-btn {
        cursor: pointer;
        font-size: 16px;
        position: relative;
        & > * {
            color: $primary;
            color: var(--primary);
        }

        &.fab {
            height: 50px;
            width: 50px;
            right: 20px;
            bottom: 20px;
            transition: width 0.4s ease-in;
            border-radius: 25px;
            padding: 0 14px;
            // justify-content: center;
            // align-items: center;
            // display: flex;
            position: fixed;
            background-color: var(--primary);
            z-index: 4;
            box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.37);
            white-space: nowrap;
            //overflow: hidden;
            @include desktop {
                right: 50px;
                bottom: 80px;
                width: 163px;
                .expand-btn-text {
                    opacity: 1 !important;
                }
            }

            .icon {
                color: white;
                line-height: 50px;
                display: inline-block;
                text-align: center;
                font-size: 23px;
                vertical-align: middle;
                transform: rotate(90deg);
                font-weight: 500;
            }
            .expand-btn-text {
                vertical-align: middle;
                display: inline-block;
                color: #ffffff;
                line-height: 50px;
                margin-left: 13px;
                opacity: 0;
                transition: opacity 0.3s linear;
            }

            &.scroll-float {
                width: 163px;
                .expand-btn-text {
                    opacity: 1;
                }
                // transition: width 1s ease 0s;
            }
        }
    }
    .delivery-process {
        overflow-x: auto;
    }
}

$rotateRadius: -40px;
@keyframes sRotate {
    from {
        transform: rotate(0deg) translate($rotateRadius) rotate(0deg);
    }
    to {
        transform: rotate(360deg) translate($rotateRadius) rotate(-360deg);
    }
}

//category code

.search-category-wrapper-other {
    margin-top: calc(var(--navbar-height) + 1rem);
    .searchBar {
        border: 1px solid gray;
    }
}

.search-category-wrapper {
    // background-color: white;
    margin-top: var(--navbar-height);
    @include desktop {
        position: sticky;
        top: var(--navbar-height);
        z-index: 100;
        margin-top: 0;
    }
    .new-search-component {
        &.other-page {
            margin-top: var(--navbar-height);
        }
        width: 100%;
        display: flex;
        justify-content: center;
        @include desktop {
            align-items: center;
        }
    }
    .searchBox {
        display: flex;
        justify-content: center;
        // background-image: url(../../images/search-banner.png);
        // background-size: cover;
        width: 100%;
        height: auto;
        @include desktop {
            align-items: center;
            height: 100px;
            margin-top: 7px;
            margin-bottom: 12px;
        }
        .searchBar {
            position: relative;
            display: flex;
            background-color: unset;
            // max-width: 680px;
            max-width: 780px;
            position: relative;
            width: 100%;
            @include desktop {
                background: var(--light-primary);
                border-radius: 39px;
                min-height: 58px;
            }
            .v-line {
                display: none;
            }
            .gd-search-address {
                cursor: pointer;
                padding: 0 15px !important;
                margin-bottom: 6px;
                @include desktop {
                    // position: absolute;
                    background-color: transparent;
                    padding: 0;
                    width: 32%;
                    padding: 0 5px 0 15px !important;
                    margin-bottom: 0px;
                }
                display: flex;
                align-items: center;
                width: 100%;
                .icon-chevron-right-e {
                    transform: rotate(90deg);
                    color: var(--primary);
                }
                .set-delivery-address-btn {
                    padding: 0;
                    .bento-address {
                        display: flex;
                        align-items: center;
                        .icon-pin {
                            font-weight: 500;
                            color: #000;
                            font-size: 16px;
                            display: flex;
                            margin-right: 0;
                            margin-top: -6px;
                            margin-left: 0px;
                            @include desktop {
                                margin-top: -18px;
                                margin-left: 5px;
                            }
                        }

                        .add-field {
                            border-radius: 8px;
                            padding: 5px;
                            text-align: left;
                            position: relative;
                            display: flex;
                            left: 0;
                            @include desktop {
                                flex-direction: column;
                                gap: 0;
                            }
                            flex-direction: row;
                            gap: 5px;
                            .delivery-text {
                                display: block !important;
                                width: auto;
                                font-weight: 600;
                                font-size: 12.9756px;
                                line-height: 16px;
                                color: #333333;
                                margin-right: 5px;
                                margin-bottom: 5px;
                                @include desktop {
                                    width: 100%;
                                }
                            }
                            .render-address {
                                font-weight: 400 !important;
                                font-size: 12.9756px;
                                line-height: 16px;
                                max-width: 213px;
                                color: #333;
                                @include desktop {
                                    max-width: 150px;
                                }
                            }
                        }
                    }
                }
            }
            .ais-SearchBox {
                display: none;
                width: 100%;
                @include desktop {
                    width: 80%;
                    display: block;
                }
                .ais-SearchBox-form {
                    .ais-SearchBox-input {
                        border: 1px solid #e8e8e8;
                        box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.1);
                        border-radius: 27px;
                        padding: 0.3rem 38px 0.3rem 38px;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                    }
                    .ais-SearchBox-submit {
                        background: unset;
                        // left:0;
                        left: 22px;
                        right: unset !important;
                        height: unset;
                        width: unset;
                        .ais-SearchBox-submitIcon {
                            height: 13px;
                            width: 13px;
                        }
                    }
                    .ais-SearchBox-reset {
                        right: 13px;
                    }
                }
            }
            .search-result-wrapper {
                position: absolute;
                background-color: white;
                z-index: -1;
                width: 100%;
                top: 34px;
                box-shadow: none;
                border: 1px solid #eeeeee;
                border-top: unset;
                padding-top: 38px;
                border-radius: 0 0 8px 8px;
                box-shadow: 0px 6px 15px 0px #c9c9c9;
                .filter-items-holder {
                    width: 100%;
                    .f-left-sec {
                        // min-width: 215px;
                        border-right: 1px dashed #ddd;
                        flex-basis: 250px;
                        .sidebarBanner {
                            padding: 8px 9px;
                            border-bottom: 1px solid #dadada;
                        }
                        .rc-collapse-header {
                            padding: 7px 10px;
                        }
                    }
                    .f-right-sec {
                        // width: 100%;
                        flex: 1 1;
                        .filter-box-parent {
                            width: 100%;
                            .f-inner {
                                border-bottom: 1px solid #dadada;
                            }
                        }
                        .ais-Hits {
                            .ais-Hits-list {
                                margin: 0;
                                .ais-Hits-item {
                                    margin: 0;
                                    width: 100%;
                                    padding: 15px 10px;
                                    //margin-bottom: 0.5rem;
                                    border: 0;
                                    //border-bottom: 1px solid #f1f1f1;
                                    cursor: pointer;
                                    box-shadow: none;
                                    position: relative;
                                    padding-left: 70px;

                                    @include desktop {
                                        padding-left: 100px;
                                    }

                                    &:hover {
                                        background: #f3f3f3;
                                    }

                                    &:last-child {
                                        margin-bottom: 80px;
                                    }

                                    img {
                                        position: absolute;
                                        left: 0px;
                                        top: 50%;
                                        transform: translateY(-50%);
                                        width: 60px;
                                        height: 60px;
                                        object-fit: contain;

                                        @include desktop {
                                            width: 70px;
                                            height: 70px;
                                            left: 15px;
                                        }
                                    }

                                    .bull {
                                        font-size: 30px;
                                        line-height: 16px;
                                        vertical-align: sub;
                                        color: grey;
                                    }

                                    .hit-item-right-sec {
                                    }

                                    .hit-name {
                                        word-break: break-word;

                                        span,
                                        em {
                                            font-size: 14px;
                                        }
                                    }

                                    .hit-category,
                                    .hit-brand,
                                    .weight {
                                        span,
                                        em {
                                            font-size: 13px;
                                        }

                                        font-size: 13px;
                                    }

                                    .info-btn {
                                        position: absolute;
                                        top: 12px;
                                        right: 0;
                                        padding: 3px;
                                        width: 25px;
                                        height: 25px;
                                        border-radius: 50%;
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                    }
                }
                .menu-list,
                .ais-Hits {
                    height: 68vh;
                    overflow-y: auto;
                }
            }
        }
    }
}
