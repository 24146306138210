.order-details {
  .selfie-block,
  .photo-id-block {
    width: 100%;
    padding: 15px;
    margin-top: 20px;
    cursor: pointer;

    @include desktop {
      width: 48%;
      padding: 15px;
      margin-top: 0px;
    }

    span {
      font-size: 12px;
    }

    .icon-u-card,
    .icon-u-selfie {
      font-size: 20px;
    }

    img {
      border-radius: 6px;
      object-fit: cover !important;
      height: 40px !important;
      width: 40px !important;
      padding: 0px !important;

      @include desktop {
        width: 115px;
        height: 137px;
      }
    }

    .img-container {
      display: flex;
      align-items: flex-start;
      padding: 8px;
      background-color: #f8f8f8;
      margin-top: 15px;
      border-radius: 6px;

      i {
        font-size: 20px;
        color: #4caf50;
        border: none;
        padding: 0px;
        margin-bottom: 0px;
      }
    }

    .btn-amt {
      border: 1px solid var(--primary);
      margin-right: 0px;
      padding: 5px 18px;
      border-radius: 6px;
      font-size: 14px;

      i {
        color: var(--gd-black-color);
      }
    }

    .btn-plus {
      display: flex;
      height: 30px;
      width: 30px;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      border: 1px solid var(--primary);
    }
  }

  .ledger-green-wrapper {
    width: 100%;
    padding: 10px;
    background-color: #f5f5f5;
    border: none;

    .medical-id-block {
      .img-container {
        background-color: #f5f5f5;
        margin-top: 5px;
        padding: 0;

        img {
          object-fit: contain !important;
        }
      }
    }

    .license-text {
      line-height: 1.2;

      .sub-text {
        font-size: 12px;
        color: #717171;
        margin: 5px 5px 0 28px;
        line-height: 1.2;

        @include desktop() {
          margin-top: 0;
        }
      }
    }
  }

  .ledger-green-view-inner {
    .confirm {
      .modal-body {
        position: relative;

        .modal-title {
          position: absolute;
          top: -30px;
          left: 25px;
        }
      }
    }
  }

  background-color: #f5f6fa;

  .fs-16 {
    font-size: 16px;
  }

  .container {
    max-width: 1000px;
  }

  .map-section {
    position: relative;
    height: 40vh;
    margin-top: 70px;
  }

  .logo-container {
    .lottie-address {
      height: 120px;
      margin-bottom: 20px;

      svg {
        // height: 500px !important;
        // display: block;
      }
    }
  }

  .od-header-status {
    background-color: var(--secondary);
    min-height: 430px;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;

    @include desktop {
      min-height: 357px;
      margin-top: 70px;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      padding: 0 15px;
    }

    .status-block {
      display: flex;
      margin-bottom: 30px;
      flex-direction: column;
      gap: 10px;
      justify-content: center;
      width: 100%;
      padding: 0 15px;

      .order-type-block,
      .Eta-block {
        flex: 1;
        width: 100%;
        border-radius: 8px;
        // min-width: 170px;
        // flex: 1;
        min-height: 62px;
        background: rgba(255, 255, 255, 0.1);
        color: white;
        display: flex;
        align-items: center;
        // justify-content: center;
        padding: 10px 15px;

        @include desktop {
          width: auto;
        }

        i {
          font-size: 20px;
        }

        h6 {
          font-size: 14px;
        }

        @include desktop {
          h6 {
            font-size: 1rem;
          }

          padding: 10px 20px;
        }

        .order-type-block-title {
          white-space: nowrap;
          font-size: 15px;
        }

        h6 {
          font-size: 16px !important;
          white-space: nowrap;
        }
      }

      @include desktop {
        flex-direction: row;
        gap: 20px;
        // width: auto;
        padding: 0;
      }

      .Eta-block {
        flex: 1;
        width: 100%;
        padding: 10px 12px;

        @include desktop {
          width: auto;
          padding: 10px 20px;
        }
      }

      .lottie-address {
        margin-left: -16px !important;

        @include desktop {
          margin-left: -5px !important;
        }

        svg {
          width: 40px !important;
        }
      }
    }

    @include desktop {
      .ASAP,
      .asap,
      .asap-priority {
        max-width: 600px;
      }

      .scheduled {
        max-width: 970px;
      }
    }

    .cancelled-img {
      height: 80px;
      margin-bottom: 30px;
    }

    .logo-container {
      position: relative;
      bottom: -8px;
      z-index: 0;
    }
    .t-message {
      @include desktop {
        max-width: 970px;
      }
    }

    .eta-block-present {
      max-width: 100%;
      margin-bottom: 40px;
      @include desktop {
        max-width: 970px;
        margin-bottom: 30px;
      }
    }
    .on-hold-reason {
      margin-bottom: 15px;
      margin-top: 15px;
      padding: 15px;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.1);
    }
  }

  .bg-cancelled {
    background-color: #37475b !important;
    min-height: 300px;
  }

  .od-body {
    // background-color: #F5F6FA;
    position: relative;

    .od-status-main {
      margin-top: -30px;

      .payment-block {
        background: #ffffff;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        // display: flex;
        justify-content: space-between;
        // gap:5%;
        min-height: 80px;
        align-items: flex-start;
        padding: 20px 0px;
        position: relative;
        margin-bottom: 20px;

        .payment-mode-wrapper {
          // position: absolute;
          // top: 20px;
          // left: 15px;
          // width: 50%;
          // padding-left: 15px;
          padding: 0 15px;
        }

        i {
          color: var(--primary);
          // font-size: 25px;
        }

        .btn {
          font-size: 14px;
          white-space: nowrap;
        }
      }

      .driver-block {
        background: #ffffff;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        display: grid;
        grid-template-columns: 100%;
        min-height: 80px;
        align-items: center;
        padding: 15px;
        margin-bottom: 20px;

        @include desktop {
          grid-template-columns: 50% 50%;
        }

        .driver-rating {
          font-size: 14px;
          background: #f2f1f6;
          border-radius: 5px;
          padding: 3px 5px;
          width: 50px;
          text-align: center;

          i {
            font-size: 14px;
            margin-right: 5px;
          }
        }

        img {
          border-radius: 6px;
          object-fit: cover;
          height: 50px;
          width: 50px;
          padding: 0px;
        }

        .driver-name {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 3px;

          .icon-driver-car1 {
            font-size: 20px;
          }
        }

        i {
          font-size: 16px;
        }

        .driver-info {
          padding-right: 0;
          margin-bottom: 30px;

          @include desktop {
            padding-right: 15px;
            margin-bottom: 0px;
          }
        }

        .driver-reg-no {
          font-weight: 500;
        }

        .driver-plate {
          border: 1px solid #cfcfcf;
          border-radius: 4px;
          padding: 1px 8px;
          background-image: linear-gradient(#e8f5fc, #fff);
        }
      }

      .order-status-block {
        background: #fff;
        border-top: 6px solid var(--primary);
        color: #000;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        margin-bottom: 20px;
        padding: 15px 15px;

        &.order-assigned {
          background: #fff;
          border-top: 6px solid var(--primary);
          color: #000;
        }

        .delivery-type {
          font-size: 16px;
          font-weight: 500;
          padding: 4px 10px;
          align-content: center;
          background-color: white;
          min-width: 180px;
          height: auto;
          background: #ffffff;
          border: 2px solid var(--primary);
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: nowrap;
          position: absolute;
          top: -15px;
          left: 50%;
          transform: translateX(-50%);
          margin: 0 auto;

          &.order-assigned {
            border: 2px solid var(--primary);
          }

          .lottie-address {
            svg {
              height: 32px !important;
              width: 32px !important;
            }
          }
        }

        .priority-delivery-type {
          padding: 0 20px 0 10px;
        }

        .mt-minus {
          margin-top: -40px;
        }
      }

      .status-bar {
        color: #000;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;

        @include desktop {
          justify-content: space-between;
        }

        .status-bar-text {
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;
          margin: 16px 0px 12px;

          .status-label-text {
            font-size: 20px;
            margin-bottom: 5px !important;
          }

          .sub-text {
            line-height: 1.2;
          }

          @include desktop {
            align-items: flex-start;
            width: auto;
            flex: 1;
          }

          .sub-text {
            text-align: center;

            @include desktop {
              text-align: left;
            }
          }
        }

        .arrival-committed-wrapper {
          background: #f5f6fa;
          border-radius: 8px;
          padding: 10px;

          @include notDesktop {
            width: 100%;
          }
        }

        .Eta-block {
          // gap: 10px;
          background: rgba(255, 255, 255, 0.1);
          border-radius: 8px;
          display: flex;
          justify-content: center;

          h6 {
            font-size: 18px;
            margin-bottom: 0px;
          }

          flex-direction: column;
          align-items: flex-start;

          @include desktop {
            flex-direction: row;
            align-items: center;
          }
          &.no-comm-time {
            margin-bottom: 10px;
            @include desktop {
              margin-bottom: 0px;
            }
          }
        }

        .arrival-time-block {
          word-break: break-word;
        }

        .pr-flag {
          color: white;
          border-radius: 4px;
          // margin-left: 10px;
          padding: 2px 5px;
          white-space: nowrap;

          &.ontime {
            background: #4d8948;
          }

          &.late {
            background: #ff9800;
          }
        }
      }

      .switch-payment-wrapper {
        width: 100%;

        .rc-collapse-header {
          color: var(--primary);
          margin-right: 12px;
          justify-content: flex-end;
          padding-right: 25px;
          position: absolute;
          top: 20px;
          right: 0;
        }

        .faq-acord-icon {
          position: absolute;
          right: 0px;
        }

        .rc-collapse-content {
          // margin-right: -15px;
          padding: 0;
          border-radius: 10px;

          .rc-collapse-content-box {
            margin-bottom: 0px;

            .pay-by-card-parent {
              margin-bottom: 0;
            }
          }
        }

        .pay-by-card {
          position: relative;
          display: flex;
          align-items: center;
          margin: 15px 0px;

          img {
            height: 30px;
            width: 30px;
          }

          .inner {
            margin: 0 0 0 5px;

            p {
              margin: 0;
              font-weight: 400 !important;
            }
          }
        }

        .payment-option-container {
          background: #ecf8f6;
          padding: 20px 15px 15px 16px;
          margin: 0px -15px;

          .info-alert {
            font-size: 10px;
          }
          .info-alert-icon::before {
            position: relative;
            top: 1px;
          }

          span,
          label {
            font-weight: 500;
            color: #000;
          }

          em {
            font-weight: 400;
          }
        }

        .tip-driver {
          .tip-text {
            font-style: italic;
            color: #797979;
            font-weight: 400;
          }

          .icon-close {
            font-size: 8px;
          }

          .tipAmount.is-active {
            background-color: #fff;
            border: 1px solid var(--primary);
            color: var(--primary);
          }

          .amount-wrapper.is-active {
            .custom-amount {
              background: #fff;
              color: var(--primary);
              margin-right: 15px;
            }

            &::before {
              color: var(--primary);
              font-weight: 500;
              color: #000;
            }

            background-color: #fff;
            border: 1px solid var(--primary);
          }

          .amount-wrapper {
            padding-right: 0px;

            .icon-close {
              right: 0;
              top: -2px;
              margin-right: 15px;
            }

            &::before {
              top: 15px;
            }
          }
        }

        .card-list {
          .link-color {
            text-decoration: underline;
          }
        }
        .stronghold-list + .link-color {
          margin-top: 0.5rem;
        }
        .od-c-list {
          .custom-radio {
            margin-bottom: 15px !important;
          }
        }
      }
      .switch-payment-wrapper {
        .loader-3dots {
          &::before {
            content: none;
          }
        }
      }
    }

    .feedback-section {
      background: #ffffff;
      box-shadow: 0px 0px 5px #0000000d;
      border-radius: 10px;
      padding: 20px 15px;
      margin-top: -40px;
      margin-bottom: 50px;

      .star-rating {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          margin-right: 15px;
        }
      }
    }

    .issuetype-section {
      background: #ffffff;
      border-radius: 10px;
      padding: 20px 15px;
      margin-bottom: 20px;

      .issue-handler {
        padding: 0px;
      }
    }

    .invoice-section {
      background: #ffffff;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      margin-bottom: 20px;
      padding: 20px 0;
      flex-wrap: wrap;

      .product-listing {
        max-height: none;
        overflow: auto;

        @include desktop {
          max-height: 400px;
          padding-right: 15px;
        }

        .od-product-listing {
          display: flex;
          margin-bottom: 20px;
          border-bottom: 1px dashed #e3e3e3;
          padding-bottom: 20px;

          .product-name {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 5px;
          }

          .brand-name {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #868686;
          }

          .price,
          .qty {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
          }

          .item-price {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
          }
        }
      }

      & .cart-product + div {
        padding-left: 15px;

        @include desktop {
          padding-left: 0px;
        }
      }

      .cancel-order {
        padding: 8px 20px;
        line-height: 1;
        // border: 1px solid #f44336;
        // margin: 0px auto 0;
        border-radius: 6px;
      }
      .edit-order {
        padding: 8px 20px;
        line-height: 1;
        border: 1px solid var(--primary);
        // margin: 0px auto 0;
        border-radius: 6px;
      }

      .bring-back {
        margin-bottom: 0px !important;
      }

      .wallet-container:last-child {
        margin-bottom: 0px !important;
      }
    }

    .refer-section {
      background: #ffffff;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      // display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .title {
        font-weight: 700;
        font-size: 18px;

        @include desktop {
          font-size: 24px;
        }
      }

      .desc {
        font-weight: 400;
        font-size: 14px;

        @include desktop {
          font-size: 16px;
        }
      }

      .refer-left-body {
        padding: 15px;
      }

      .pass-the-grass-section {
        .ref-link {
          width: 80%;
          display: flex;
          align-items: center;
          padding: 7px 11px;
          justify-content: space-between;
          background: rgba(5, 160, 168, 0.05);
          border: 1px dashed var(--primary);
          border-radius: 6px;

          .od-text {
            margin-bottom: 0px;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            color: var(--primary);
          }

          .od-copy-btn {
            border-left: 1px solid var(--primary);
            border-radius: 0px;

            i {
              color: var(--primary);
              font-size: 20px;
            }
          }
        }

        .link-share-btn {
          margin-left: 8px;
          font-size: 25px;
          background: var(--primary);
          border: none;
          color: #fff;
          border-radius: 6px;
        }
      }

      .refer-img {
        display: flex;
        align-items: flex-end;

        img {
          object-fit: fill;
          height: 265px;
        }
      }
    }
    .chat-with-us {
      background: #ffffff;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      padding: 14px 15px;
      margin-bottom: 20px;
      .icon-chat-with-us,
      .icon-chat-with-us1 {
        font-size: 22px;
      }
    }
  }

  //cart item css
  .icon-wallet,
  .icon-coin,
  .icon-jerrycan,
  .icon-gift-thin {
    font-size: 18px;
    margin-right: 5px;
  }

  .item {
    margin: 0 0 0px 0;
    padding: 10px 0px;
    background: white;
    width: 100%;

    @include desktop {
      margin: 0 0 0px 0;
      padding: 10px 0px 5px 0px;
      background: white;
      width: 100%;
    }
  }

  .od-item {
    border-bottom: 1px dashed #e3e3e3;
    padding-bottom: 20px;
  }

  //cart product css
  .cart-product {
    margin: 0px;

    // max-height: 400px;
    // overflow: auto;
    @include desktop {
      max-height: 600px;
      overflow: auto;
    }

    .xy-product-offer {
      color: var(--primary);

      &.widget-offer {
        margin-left: 70px;

        a {
          text-decoration: underline;
        }

        .icon-offer-tag {
          margin-right: 8px;
        }
      }
    }
  }

  .referral {
    height: auto;
    min-height: 200px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;

    .referral-link-wrapper {
      padding: 10px 0px 20px;
    }

    .ref-link-parent {
      width: 80%;
      background: rgba(5, 160, 168, 0.05);
      border: 1px dashed var(--primary) !important;
      border-radius: 6px;

      @include desktop {
        width: 80%;
      }

      .copy-btn {
        padding-right: 10px;
        padding-left: 10px;
        border-left: 1px solid var(--primary);
        border-radius: 0;
      }

      .ref-link {
        font-weight: 400;
      }
    }

    .link-share-btn {
      .share-btn-desktop {
        display: none !important;
      }

      .link-share-icon {
        display: block !important;
      }
    }

    .referral-icons {
      display: none !important;
    }
  }

  .order-bottom-fix {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    height: 63px;
    z-index: 2;
    -webkit-box-shadow: 4px 0px 20px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 4px 0px 20px rgba(0, 0, 0, 0.15);
    background: #ffffff;
    box-shadow: 4px 0px 20px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px;
  }

  .review-card-wrapper {
    border-bottom: 1px dashed #e3e3e3;

    h5 {
      color: #000000;
      font-weight: bold;
      font-weight: 18px;
      margin-bottom: 5px;
    }

    .rc-textarea {
      height: auto;

      textarea {
        min-height: 50px !important;
      }
    }
  }

  .rating-btn {
    text-align: center;

    @include desktop {
      text-align: end;
    }
  }

  .issue-handler {
    margin-bottom: 0 !important;

    .content {
      padding: 0;
      background: none;
      flex-direction: row;
      font-size: 14px !important;
      a {
        @include notDesktop() {
          width: auto;
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }

  .review-card-wrapper h5 {
    margin: 0 !important;
  }
}

//payment mode selection popup css
.pay-with-popup {
  width: 100%;
  height: 100%;
  background-color: $modalBGColor;

  h5 {
    font-size: 20px;
  }

  .modal-dialog {
    // height: 100%;
    height: auto !important;
    // min-height: 100vh;
    top: 0;

    .modal-content {
      position: relative;
      overflow: hidden;
    }

    @include desktop {
      width: 450px;
      min-height: auto;
      max-width: 100% !important;

      top: auto;
    }
  }

  .modal-header {
    border-bottom: 1px solid #e8e8e8;
  }

  .modal-body {
    label {
      font-size: 16px;
    }

    .custom-radio {
      margin-bottom: 20px;

      .sm-text {
        font-size: 13px;
      }
    }
  }
}

.order-details-confirmation {
  padding: 20px 15px 15px;
}

.cust-details-confirmation {
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  // grid-gap: 5%;
  display: grid;
  grid-template-columns: 100%;
  margin-bottom: 20px;

  @include desktop {
    grid-template-columns: 50% 50%;
  }

  div {
    padding-right: 15px;

    + div {
      margin-top: 8px;
    }
  }
}

.last-updated-order-details {
  background-color: #fff;
  padding: 10px;
  position: absolute;
  top: 15px;
  right: 15px;
  border-radius: 4px;

  @include desktop {
    top: 35px;
    right: 35px;
  }
}

.age-restrictions-warning {
  padding: 0 15px;

  .prop-65 {
    margin-top: 15px;
  }

  // .for-order-details{
  //   width:38px !important;
  // }
}

.age-restrictions-warning :first-child {
  align-items: start !important;

  @include desktop {
    align-items: center !important;
  }
}

.pr-flag {
  color: white;
  border-radius: 4px;
  // margin-left: 10px;
  padding: 2px 5px;

  &.ontime {
    background: #4d8948;
  }

  &.late {
    background: #ff9800;
  }
}

.order-details {
  .stronghold-list {
    .custom-radio {
      // border-radius: 10px;
      padding: 10px 15px;
      padding-right: 35px;

      label {
        margin-bottom: 0;
      }
    }

    .strong-hold.delete-btn {
      top: 9px !important;
    }
  }
}
.arrival-time-collapse,
.comm-time-collapse {
  flex-direction: column;
  align-items: flex-start !important;
  display: flex;
  @include desktop {
    flex-direction: row;
    align-items: center;
  }
}
.arrival-time-collapse {
  .arrival-time-block {
    word-break: break-word;
  }
  .pr-flag {
    white-space: nowrap;
  }
  &.no-comm-time {
    margin-bottom: 10px;
    @include desktop {
      margin-bottom: 0px;
    }
  }
}
.bring-it-again-present {
  padding-bottom: 40px;
}
.od-navbar {
  @include notDesktop {
    background-color: transparent;
    box-shadow: none;
    .logo-text {
      display: none;
    }
    .left-btn {
      height: 35px;
      background: white;
      line-height: 35px;
      border-radius: 50%;
      font-size: 16px;
    }
  }
}
.order-details .map-section {
  margin-top: 0;
  @include desktop {
    margin-top: 70px;
  }
}
.empty-order-wrapper {
  min-height: 100vh !important;
  display: flex;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center;
  .order-not-exist {
    min-height: unset !important;
    margin-top: 4rem !important;
  }
  .empty-order {
    width: 80%;
    @include desktop {
      width: 400px;
      height: 320px;
    }
  }
}
.livechat-lottie {
  width: 35px;
  height: 26px;
}
