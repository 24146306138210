.secondary-navbar {
  padding: 10px;
}
.brands {
  // font-size: 16px;
  // overflow-x: hidden;
  padding-top: 0;
  @include desktop {
    padding-top: var(--navbar-height);
  }
  .b-title {
    margin-bottom: 1rem;
    margin-top: 1rem;
    font-size: 26px;
    @include desktop {
      margin-bottom: 2rem;
      // padding-left:15px;
      font-weight: bold;
      font-size: 28px;
    }
  }

  @include min-height-desktop;

  .info-sec {
    // @include desktop {
    //   box-shadow: -1px 0px $dividerColor;
    // }
  }
  .address-box a {
    border-radius: 6px;
  }

  .banner {
    //min-height: 200px;
    height: auto;
    overflow: hidden;

    //padding: 30px 0;
    @include desktop {
      //min-height: 450px;
    }

    .banner-inner {
      background-position: center center;
      padding-bottom: 20px;
      height: auto;
      .no-mobile-banner {
        min-height: 250px;
      }
      & > img {
        width: 100%;
        //height: 250px;
        object-fit: cover;
        @include desktop {
          height: auto;
          //min-height: 500px;
        }
      }

      @include desktop {
        padding-bottom: 50px;
        height: auto;
      }
    }

    &:after {
      //content: "";
      display: block;
      position: absolute;
      bottom: -50px;
      left: -50px;
      right: -50px;
      height: 70px;
      transform: rotate(-1deg);

      @include desktop {
        transform: rotate(-0.5deg);
      }

      background: var(--body-background-color);
    }
    .just-a-container {
      @include desktop {
        bottom: 30px;
      }
    }
  }

  .jumbotron {
    margin-left: -15px;
    margin-right: -15px;
    background-size: cover;
    background-repeat: no-repeat;
    //background-position: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
      url("https://static.grassdoor.com/Nugrunner/LIVE/banner/selectbg.jpg");
    color: white;
  }

  .lead {
    font-size: 1rem;
    color: #e0e0e0;

    @include desktop {
      width: 600px;
      color: white;
    }
  }

  .logo-container {
    .brand-logo-container {
      & > * {
        vertical-align: middle;
      }

      font-size: 16px;
      color: white;
      margin-top: 0.3rem;

      span {
        font-size: 1.2em;
        margin-right: 8px;
        margin-left: 3px;
      }
    }

    .select-logo {
      width: 150px;
      height: auto;
      object-fit: contain;
      height: auto;
    }

    .icon-logo-full {
      font-size: 42px;
      color: white;
    }

    .icon-close {
      font-size: 10px;
      color: white;
    }

    @include iphone5 {
      .select-logo {
        width: 100px;
      }

      .icon-logo-full {
        font-size: 40px;
      }
    }

    @include desktop {
      .select-logo {
        width: 200px;
      }

      .icon-logo-full {
        font-size: 90px;
      }

      .icon-close {
        font-size: 16px;
      }
    }
  }

  .product-grid {
    // background-color: transparent;
    // @include desktop{
    //     padding: 5px 15px;
    // }
    // .inner{
    //     // box-shadow: none;
    //     margin: 0;
    // }
    // .price-container{
    //     left:5px!important;
    // }
    // .info-btn {
    //     right: 17px !important;
    // }
  }

  .slanted-conatiner {
    padding: 20px 15px;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 2rem;

    @include desktop {
      margin-left: 0;
      margin-right: 0;
      padding: 0 0;
      margin-bottom: 2rem;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(90deg, var(--primary), rgba(var(--primary-rgb), 50%));

      @include desktop {
        background: #fff;
      }

      transform: rotate(-1deg);
      z-index: -1;

      @include desktop {
        transform: rotate(-0.5deg);
      }
    }

    p {
      color: white;

      @include desktop {
        color: #333;
      }
    }

    .sl-title {
      color: white;

      @include desktop {
        color: $primary;
        color: var(--primary);
      }
    }

    i {
      //position: absolute;
      //right: 15px;
      //top: 50%;
      //transform: translateY(-50%);
      font-size: 25px;
      margin-right: 10px;

      @include desktop {
        top: 44%;
      }
    }
  }

  .show-more {
    .show-more-btn {
      &:hover,
      &:active,
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    & > p {
      max-height: 38px;
      transition: max-height 0.2s ease-out;
    }

    p.open-des {
      max-height: 1000px;
      overflow: auto;
    }

    p.closed-des {
      height: 38px;

      overflow: hidden;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 20px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0), #fff);
      }
    }

    .b-scroll-fix {
      height: 100px;
    }

    @include desktop {
      // padding-bottom: 4rem;

      // .show-more-btn {
      //   display: none;
      // }

      // p.closed-des {
      //   height: auto;
      //   max-height: 100%;

      //   &:after {
      //     display: none;
      //   }
      // }

      // p.open-des {
      //   height: auto;
      //   max-height: 100%;
      // }
    }
  }

  // @include desktop{
  // 	.h-list-parent .h-list .product {
  // 		flex: 0 0 24%;
  // }
  // }

  .brands-desc-wrapper {
    background-color: var(--body-background-color);
    .brand-more-link {
      // text-transform: uppercase;
      color: var(--primary);
      text-decoration: none;
    }
    .brand-description {
      scroll-margin-top: 150px;
      @include desktop {
        scroll-margin-top: 200px;
      }
    }

    .short-description {
      h2 {
        margin-bottom: 30px;
        & ~ p {
          color: #555;
        }
      }
    }

    .marketing-banner .banner-image {
      height: auto !important;
      // min-height: 400px;
    }
  }
  .brands-desc {
    max-width: 1300px;
    padding: 55px 15px 0px 15px;
    margin-right: auto;
    margin-left: auto;
    p {
      margin: 0;
    }
  }

  &.category-product {
    .filter-product-page {
      .desktop-filter-container.extra-sticky {
        @include desktop {
          padding-top: 20px;
          top: calc(88px + var(--navbar-height));
        }
      }
    }
  }
}
