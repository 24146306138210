.compare-pop-up{
  background-color: $modalBGColor;
  overflow-y: hidden!important;
  .modal-dialog{
    margin: 0 auto !important;
    width: auto !important;
    align-items: flex-start;
    top:0;
    @include desktop{
      max-width: 75% !important;

    }
    .modal-content{
      @include desktop{
        // width: auto !important;
        // overflow: auto;
        // min-height: calc(100vh - #{$currentOrderInfoHeight});
      }
      .modal-header{
        border-bottom: none;

        .modal-title{
          font-size: 18px;
          font-weight: bold;
        }
      }
      .modal-body{
        height: calc(100vh - 72px);
        padding: 0 !important;
        overflow: auto;

      }
    }
  }
}