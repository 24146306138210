.location-pages{

  hr{
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .title-fs{
    font-size: 25px !important;
  }

  .schedule-title{
    font-size: 25px !important;
    margin-bottom: 2em !important;
  }

  .r-text {
    font-size: 15px;
    line-height: 1.8;
   
  }
  .city-brand-bg{
    background-color: #f1f1f1;
    h2{
      font-size: 2.5em;
    }
  }

  .service-area{
    $spacing: 11px;
    .padding-all{
      padding:$spacing;
    }
    .padding-right-left{
      padding-bottom:$spacing;
      padding-right:$spacing;
      padding-left:$spacing;
    }
    .padding-right-left-top{
      padding-top:$spacing;
      padding-right:$spacing;
      padding-left:20px;
    }


    @include notDesktop(){
      $spacing: 3px;
      .padding-all{
        padding:$spacing;
      }
      .padding-right-left{
        padding-bottom:$spacing;
        padding-right:$spacing;
        padding-left:$spacing;
      }
      .padding-right-left-top{
        padding-top:$spacing;
        padding-right:0px;
        padding-left:15px;
      }
    }

    .read-more-less{
     cursor: pointer;
     text-decoration: underline;
     color: var(--primary);
    }

  }
  .location-link{
    a{
      font-weight: bold;
      font-size:15px;
    }
  }
  .brand-button{
    color:white;
    background-color:var(--primary);
    border-radius: 15px;
    border:none;
    padding:10px;
  }
  .review-block{
      height:320px;
			background-color: #eeeeee;
			padding:33px;
			border-radius:40px;
  }
  .city-img{
    width:100px;
    height:100px;
    // object-fit:contain;
    border-radius: 50%;
  }
  .location-banner img {
     margin-top: 10px;
     @include desktop{
       margin-top:0px;
     }
    
    }
    .service-links{
      margin-top: 10px;
      @include desktop{
        margin-top:0px;
        border-left:1px solid grey;
      }
    }
    .service-leftblock{
      border-right:1px solid grey;
      padding: 10px;
    }
    .mapboxgl-map {
      height:100%;
    }
    

    .map-container {
      top: 50%;
      @include desktop{
        top: 20%;
        width:97%;
        right:10%;
        margin: 0px auto;
      }   
    }
    .map{
      height:300px;
    }
/* For screens with width < 600px, display 2 items */
// @media only screen and (max-width: 600px) {
//   .slide {
//     min-width: 50% !important;
//   }
// }

/* For all other screen sizes, display 3 items */
// .slide {
//   min-width: 33% !important;
// }
  


}