.filter-box-parent {
	.react-tabs__tab-panel {
		border-bottom: 1px solid $dividerColor;
	}

	//margin:5px -5px;
	ul {
		padding: 0;
		margin: 0;
	}

	.react-tabs__tab-list {
		padding: 10px;
		white-space: nowrap;
		overflow-x: auto;
		border-bottom: 2px solid #ddd;
	}
	.react-tabs__tab {
		border: 1px solid $dividerColor;
		border-radius: 5px;
		bottom: 0;
		&:after,
		&:before {
			font-size: 1.5em;
			line-height: 16px;
			margin-left: 6px;
			background: inherit;
			position: static;
		}
		&:after {
			content: "▼";
		}
		& + .react-tabs__tab {
			margin-left: 10px;
		}
		&:focus {
			box-shadow: none;
			border-color: $dividerColor;
			&:after {
				//content:"▼";
			}
		}
		&--selected {
			//border-color:var(--primary);
			box-shadow: 0 0 0px 2px var(--primary);
			background: var(--primary-light-50);

			&:focus {
				box-shadow: 0 0 0px 2px var(--primary);
				border-color: $dividerColor;
				&:after {
					content: "▲";
				}
			}
			&:after {
				content: "▲";
			}
		}
	}
}

.filters-block {
	border: 1px solid rgba(205, 205, 205, 0.8);
	border-radius: 8px;
	padding: 10px;
	font-size: 16px;
	margin-left: 15px;
	height: 40px;
	width: 160px;
	transition: 3s ease-out;
	font-weight: 600;
	line-height: 19px;
	background: #fff;
	box-shadow: 2px 2px 8px 2px #eee;
	&:first-child {
		margin-left: 0;
	}
	// width:200px;
	i {
		font-size: 12px;
		font-weight: 600;
	}
	.icon-angle-up {
		font-size: 14px;
		font-weight: 600;
	}
	.filter-dropdown {
		background-color: #ffffff;
		border-radius: 4px;
		margin-left: -10px;
		margin-top: 10px;
		font-size: 14px;
		font-weight: 400;
		line-height: 17px;
		z-index: 2;
		width: 100%;
		position: relative;
		padding: 10px;
		cursor: pointer;
		width: 215px;
		transition: 0.3s ease-in-out;
		transition-delay: 0.1s;
		@include desktop {
			box-shadow: 0px 8px 20px 5px rgba(0, 0, 0, 0.06);
		}
		.filters-dropdown-list {
			max-height: 50vh;
			overflow: auto;
			margin-right: -10px;
			padding-left: 2px;
		}
	}
}
.filter-stick {
	background-color: var(--primary);
	width: 4px;
	height: 25px;
	border-radius: 3px;
}

.desktop-filter-container {
	position: sticky;
	top: var(--navbar-height);
	z-index: 99;
	background: var(--body-background-color);
	margin: 0 -15px;
	// padding: 0rem 15px 6px;
	padding: 0.5rem 15px 1rem;
	text-align: center;
	display: none;
	@include desktop {
		display: block;
	}
	&.extra-sticky {
		top: calc(98px + var(--navbar-height));
		.desktop-filters {
			.filters-block {
				font-size: 14px !important;
				border-radius: 50px !important;
			}
		}
	}
	.selected-filters {
		// width: var(--filter-holder-width);
		margin: auto;
	}

	.filters-holder {
		@include desktop {
			flex-direction: column;
			align-items: flex-start !important;
		}

		.desktop-filters {
			@include desktop {
				margin: 0 auto;
			}
		}
	}
}

.sticky-filter-shadow {
	box-shadow: rgba(0, 0, 0, 0.1) 0px 15px 10px -15px;
	transition: all 0.3s;
}
