.xy-product-offers-wrapper{
  background-color: #f5fcfc;
  border-radius: 6px;
  padding: 30px 15px 15px;
  margin-top: 35px;
  margin-bottom: 8px;
  position: relative;
  width: 100%;

  .offer-tag{
    position: absolute;
    top: -15px;
    padding: 5px 15px;
    background: var(--primary);
    border-radius: 8px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    box-shadow: -1px 3px 8px rgba(93, 93, 93, 0.17);
    font-size: 13px;
    display: flex;
    align-items: center;

    span{
      margin-right: 8px;
    }
  }

  .xy-product-offer{
    .offer-product-title{
      display: flex;
      margin-bottom: 10px;

      .popup-title-icon{
        width: 30px;
        height: 30px;
        font-size: 13px;
        line-height: 30px;
        color: var(--primary);
      }

      .offer-title{
        color: var(--primary);
        font-weight: bold;
        line-height: 1.4;
        flex: 1;
        word-break: break-word;
      }
    }

    .products-holder{
      width: 100%;

      .single-product{
        display: flex;

        img{
          width: 45px;
          height: 45px;
          border-radius: 5px;
          border: 1px solid #f2f2f2;
          object-fit: contain;
        }

        .product-content{
          margin-left: 15px;
          width: 100%;

          .product-name{
            padding-right: 0;
            font-weight: 500;
            font-size: 14px;

            @include notDesktop() {
              display: flex;
              flex-direction: column;
            }

            .grams{
              padding-left: 20px;
              position: relative;
              font-weight: 200;
              font-size: 13px;

              @include notDesktop(){
                padding-left: 0;
                padding-top: 4px;
              }

              @include desktop(){
                &:before{
                  content: ".";
                  position: absolute;
                  top: 1px;
                  left: 6px;
                  line-height: 0;
                  font-size: 25px;
                }
              }
            }

          }

          .sub-content{
            margin-top: 4px;
            font-size: 15px;

            @include notDesktop() {
              display: flex;
              flex-direction: column;
            }

            small{
              color: #868686;

              &.quantity{
                padding-top: 2px;

                @include desktop(){
                  position: relative;
                  padding-left: 20px;
                  padding-top: 0;
                }
  
                @include desktop(){
                  &:before{
                    position: absolute;
                    content: "";
                    top: 0;
                    left: 9px;
                    width: 1px;
                    height: 15px;
                    background: #D3D3D3;
                  }
                }
              }
            }
          }
        }

        +.single-product{
          margin-top: 20px;
        }
      }
    }

    .offer-timer{
      text-align: center;
      margin-top: 15px;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 12px;

      span{
        // color: #36C1C8;
        color: var(--primary);
      }
    }

    .btn-outline-primary{
      min-width: 220px;
      margin-bottom: 10px;
      color: var(--primary);
      height: 35px;
      line-height: 35px;
      font-size: 15px;
      padding: 0 15px;

      @include notDesktop() {
        width: 100%;
      }

      &:hover{
        color: $white;
        background: var(--primary);
      }
    }

    .availed-text{
      font-size: 13px;
      // color: #36C1C8;
      color: var(--primary);
      margin-bottom: 8px;
      // margin-top: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: 0.4px;
      position: absolute;

      @include desktop(){
        top: 35px;
        right: 10px;
      }

      @include notDesktop(){
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        font-weight: 500;
        font-size: 15px;
      }

      span{
        font-size: 15px;
        margin-right: 3px;
        margin-top: -2px;
        font-weight: bold;
        color: var(--primary);
      }
    }

    +.xy-product-offer{
      border-top: 1px solid rgba(234, 234, 234, 0.60);
      padding-top: 15px;
      margin-top: 12px;
    }

    &.offer-applied{
      .offer-product-title{
        .offer-title{
          @include desktop{
            width: calc(100% - 145px);
            display: block;
          }
        }
      }

      .offer-timer{
        margin-top: 40px;
      }
    }
  }

  &[class*="offer-"]{
    padding-top: 45px;
    opacity: 1;

    .add-remove-offer{
      color: #ff7f68;
      background: transparent;
      border: none;
      font-weight: 500;
      padding: 0;
      border-bottom: 1px solid;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      line-height: 1;
      position: absolute;
      top: 18px;
      right: 15px;
    }

    &.offer-not-allowed{
      .xy-product-offer {
        opacity: 0.4;
      }
    }
  }
}

.cart{
  .cart-item-holder{
    .xy-product-offers-wrapper{
      .xy-product-offer{
        border: none;
        padding-top: 0;
      }

      .offer-applied{
        border-bottom: 1px solid rgba(234, 234, 234, 0.60);
        padding-top: 6px;
        padding-bottom: 10px;
      
        &:last-child{
          border-bottom: none;
          padding-bottom: 0;
        }
      }
    }
  }
}