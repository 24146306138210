.latest-product {
  flex: 0 0 200px;
  margin-right: 8px;
  // padding: 2px 0px !important;
  margin-top: 10px;
  border-radius: 10px;
  min-height: 300px;
  padding: 0px !important;
  background: #fff;
  box-shadow: 4px 1px 8px 0px #eee;
  cursor: default;
  // @include tab {
  //     flex: 0 0 205px;
  // }
  @include desktop {
    flex: 0 0 240px;
    margin-right: 16px;
    min-height: 300px;
  }
  p {
    // line-height: 1.2;
    line-height: 1.2;
    // max-height: 33px;
    height: auto;
    color: #333;
    @include desktop {
      line-height: 1.1;
      // height: 40px;
    }
  }

  .new-tag {
    // &:after{
    //   content: "";
    //   background: url(../../images/new.png) no-repeat;
    //   width: 100%;
    //   height: 37px;
    //   position: absolute;
    //   left: 6px;
    //   top: -6px;
    //   }
  }

  .info-btn {
    position: absolute;
    top: 8px;

    @include desktop {
      top: 16px;
    }

    right: 8px;
    padding: 0px;
    width: 20px;
    height: 20px;
    font-size: 18px;
    line-height: 20px;
    border-radius: 50%;
    // background-color: white;
    @extend %increase-touch-area;
    pointer-events: all;
  }

  .sold-out {
    padding-top: 50px;
    @include desktop {
      padding-top: 55px;
    }
  }

  .sold-out-label {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: var(--primary);
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 5px;
    color: white;
    text-align: center;
    height: auto !important;
    // font-weight: bold;
    font-size: 0.7rem;
    display: block;
  }

  .thc-cbd,
  .weight-container {
    font-size: 14px;
    @include iphone5 {
      font-size: 1em;
    }
  }

  .weight-brand-holder {
    display: flex;
    align-items: center;
    margin: 10px 0px;

    @include mobile{
      flex-direction: column-reverse;
      gap: 0;
      align-items: flex-start;
    }
    .thc-cbdx{
      @include mobile{
        margin-left: 0;
      }
      &::before{
        @include mobile{
          content: none;
        }
      }
    }
    .weight-product {
      // margin-top: -5px;
      // margin-bottom: 5px;

      @include mobile{
        margin-top: 4px;
      }

      .weight-container {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.56);
        line-height: 15px;
        font-weight: 400;
        display: flex;
        align-items: center;

        @include notDesktop{
          align-items: flex-start;
        }
        .icon {
          font-size: 12px;
          margin-right: 5px;

          @include notDesktop{
            margin-top: 2px;
          }
        }
      }

      + .brand-name {
        margin-left: 15px;
        &::before {
          content: "";
          position: absolute;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: rgba(0, 0, 0, 0.56);
          left: -10px;
          top: 6px;
        }
      }
    }
  }

  .thc-cbd {
    // margin-bottom: 8px !important;
    // font-size: 12px;
    // line-height: 15px;
    color: rgba(0, 0, 0, 0.56) !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    line-height: inherit !important;
    position: relative;
    margin-top: 0 !important;

    // .value,
    // .title {
    //   // color: var(--primary);
    //   color: #37475b !important;
    //   font-size: 12px !important;
    //   font-weight: 600 !important;
    //   line-height: 15px;
    // }
    // .title{
    //   font-size: 9px;
    //   font-weight: normal;
    //   color: #999;
    // }
  }
  .product-name-weight {
    margin: 0px 0 8px !important;
    &:hover {
      text-decoration: none;
    }
    p {
      font-size: 16px !important;
      line-height: 24px;
      word-break: break-word;
      margin-top: 6px !important;
      margin-bottom: 0 !important;
    }
  }
  .brand-name {
    text-align: left;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 15px;
    color: #262626 !important;
    text-transform: capitalize !important;
    position: relative;

    u {
      text-decoration: none;
    }
  }
  .product-strain-label {
    font-size: 12px;
    font-weight: 700;
    color: #37474f !important;
  }
  .strain-fix-container {
    // min-height: 16px !important;
    // line-height: 12px;
    margin: 10px 0px 0px;
  }
  .strain-container {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.56);
    // padding: 2px 5px;
    // border-radius: 3px;
    font-weight: 700;
    display: flex;
    align-items: center;
    // line-height: 12px;

    /* border: 1px solid $primary; */
    //background: $primary;
    //background: var(--primary);
    // font-size: 0.9em;

    @include notDesktop{
      align-items: flex-start;
      flex-direction: column;
      gap: 2px;
    }

    .product-strain-detail{
      @include notDesktop{
        white-space: unset;
        margin-left: 0;
      }
      &::before{
        @include notDesktop{
          content: none;
        }
      }
    }

    + .thc-cbd {
      margin-left: 15px;
      &::before {
        content: "";
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.56);
        left: -10px;
        top: 7px;
      }
    }

    .icon {
      font-size: 16px;
      margin-right: 2px;
    }

    &.mobile {
      display: inline-block;
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
      height: auto !important;
      width: auto;
    }
  }
  &.deals-product {
    // text-align:left;
    p {
      margin-top: 10px;
      font-size: 16px;
      height: 40px;
      margin-bottom: 3px;

      @include tab {
        height: 38px;
      }
    }

    .inner {
      padding-top: 25px;
    }

    height: 100%;
    padding: 5px 10px !important;
    background-color: white;
    //box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
    //border:1px solid $dividerColor;
    border-radius: 12px;
    box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.07);

    .img-wrapper {
      height: 140px;
      width: 140px;
      margin: 0 auto;

      @include desktop {
        height: 160px;
        width: 160px;
        margin: 0 auto;
      }

      img {
        // object-fit: contain;
        margin: 0 auto;
        height: 100%;
        object-fit: contain;
        object-position: center center;
        width: 100%;
      }
    }
    margin-right: 0;
  }

  .sold-out {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 75%;
    margin: auto;
    height: 100%;
    object-fit: contain;
  }

  .weight-container {
    // color: #4c4c4c;
    margin-top: 3px;
  }

  .price-container-static {
    .price {
      font-size: 18px;
      vertical-align: middle;
      @include desktop {
        font-size: 24px;
      }
    }
    .strike-text {
      vertical-align: middle;
      font-size: 14px;
    }
    .strike-text:after {
      // background-color: rgba(255, 99, 71, 0.6);
      background-color: var(--primary);
      transform: translateY(-50%) rotate(0deg);
    }

    @include iphone5 {
      font-size: 16px;
    }
  }

  .added-btn {
    border-color: $dividerColor;
  }

  .price-container {
    .price {
      font-weight: 700 !important;
      // color: #4527A0;
      letter-spacing: 0.5px;
    }
    .discount {
      font-weight: 700 !important;
      // color: #4527A0;
      letter-spacing: 0.5px;
    }
    .strike-text {
      margin-left: 3px;
      vertical-align: middle;
      font-weight: 400;
      font-size: 14px;
      line-height: 14.62px;
      // color: rgba(0, 0, 0, 0.56);
      opacity: 1;
      margin-top: -5px;
      display: inline-block;
      @include desktop{
        font-size: 16px;
        margin-top: -5px;
      }
      &:after {
        transform: translateY(-50%) rotate(0deg);
        background-color: #d32f2f;
      }
    }
    font-weight: 700 !important;
    font-size: 18px;
    line-height: 21.92px;
    @include desktop {
      bottom: 0px;
      // font-size: 18px;
      // font-weight: 600;
    }
  }
  .wishlistp-icons {
    .icon-stopwatch {
      color: var(--primary);
      font-size: 20px;
    }
    .icon-calendar-line {
      color: #d45932;
      font-size: 20px;
    }
  }

  .price-offer-cart-wrapper {
    position: relative;
    // min-height: 32px;
    margin-top: auto;

    @include mobile {
      flex-direction: column;
      align-items: flex-start !important;
      gap: 12px;
      position: relative;
      padding-bottom: 50px;
    }
    .price-container {
      @include mobile {
      }
      .price-holder {
        @include mobile {
          flex-direction: unset;
          align-items: center;
        }
      }
    }

    .new-add-to-cart {
      .product-counter-parent {
        &.p-count {
        }
      }
      .add-cart-widget {
        @include desktop {
          display: none;
        }
      }

      @include mobile {
        position: absolute;
        top: 50px;
        left: -8px;
        width: calc(100% + 16px);
        height: 50px;

        &:hover {
          background: transparent;
        }
      }
      .new-add-product-button {
        @include mobile {
          height: 100%;
          border: none;
          background: var(--light-primary);
          color: var(--dark-primary);
          font-weight: bold;
          font-size: 14px;
          border-radius: 0;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
        .inner-icon {
          @include mobile {
            display: none;
          }
        }

        &.add-to-cart.opacify {
          @include mobile {
            border: none !important;
          }
        }
      }
    }

    .product-soldout-limit {
      position: absolute;
      top: 60px;
      width: 100%;
      @include desktop {
        top: auto;
        right: 0;
        width: auto;
        // text-align: right;
      }
    }
  }

  .price-container {
    // font-weight: 500;
    // font-size: 1em;
  }

  // .product-img-custom-padding {
  //   padding-top: 50px;
  //   @include desktop {
  //     padding-top: 55px;
  //   }
  // }
  .link-wrapper {
    text-decoration: none;
    display: block;
  }
  .counter-wrap {
    .product-counter-parent {
      padding: 2px 6px;
      .btn .icon-delete,
      .btn .icon-plus {
        font-size: 12px;
        color: var(--primary);

        @include mobile {
          font-size: 16px;
          // font-weight: bold;
          color: var(--dark-primary);
        }
      }
      .version-2.p-counter .count {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #4f3131 !important;
        width: 30px;
      }
    }
  }
  .new-add-to-cart {
    // position:absolute;
    // right: 5px;
    // bottom:0px;
    position: relative;
    .add-to-cart {
      position: relative;
      background: var(--primary);
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
      .icon-plus {
        position: absolute;
        left: 50%;
        top: 50%;
        font-size: 8px;
        transform: translate(-50%, -50%);
      }
      .c-icon {
        position: absolute;
        // top: 1px;
        // left: 0px;
        // font-size: 20px;
        top: 50%;
        left: 50%;
        font-size: 12px;
        font-weight: 600;
        transform: translate(-50%, -50%);
        color: white !important;
        // @include desktop {
        //   top: 3px;
        //   left: 0px;
        // }
      }
      .product-add-fill-btn {
        display: inline-block;
        width: 84px;
        height: 29px;
        background: var(--primary);
        padding: 5px 6px 0;
        border-radius: 32px;
        right: -1px;
        bottom: -1px;
        top: unset;
        transform: none;
        left: unset;
      }
      &.opacify {
        border: 1px solid var(--primary);
        span {
          opacity: 0.1;
        }
        .icon-plus {
          display: none;
        }
        .product-add-fill-btn {
          opacity: 0;
        }
      }
      &.hide-button {
        visibility: hidden;
      }
      .inner-icon {
      }
      padding: 2px 4px;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      // border:1px solid var(--primary);
      @include desktop {
        //padding: 4px 8px;
        // width: 45px;
        // height: 45px;
        //box-shadow: 0 0 0 1px #13c7cd;
        .c-icon {
          // font-size: 20px;
          //left: 1px;
        }
      }
      &:focus {
        filter: brightness(0.8);
      }
    }
    .product-counter-parent.version-2.p-count {
      top: 0;
      right: 0;
      padding: 0;
      /* left: 0; */
      /* bottom: 0; */
      width: 100% !important;
      height: 100% !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .product-counter-parent.version-2.p-counter {
      @include mobile{
        width: 100%;
      } 
    }

    &:hover {
      .add-to-cart {
        // .product-add-fill-btn{
        //   background: var(--primary);
        //   color: #ffffff !important;
        //   border: 1px solid var(--purple-primary-light);
        // }
        &.new-add-product-button {
          @include desktop {
            background-color: var(--primary) !important;
            border: 1px solid var(--primary) !important;
          }
          .c-icon {
            color: var(--white-text-on-primary) !important;
          }
        }
      }
    }

    .new-add-product-button {
      border: 1px solid var(--primary);
      height: 35px;
      width: 40px;
      border-radius: 4px;
      background: transparent;
      box-shadow: none;

      @include mobile {
        width: 100%;
      }

      .c-icon {
        color: var(--primary) !important;
        font-size: 16px;
      }

      &.opacify {
        border: 1px solid var(--primary);
      }
    }
  }
  // box-shadow: 0px 2px 10px 5px rgb(0 0 0 / 2%);
  transition:
    box-shadow 0.3s ease-in,
    transform 0.3s ease-in;
  &:hover {
    @include desktop {
      transform: scale(1.01);
      // box-shadow: 0px 2px 15px 10px rgb(0 0 0 / 5%)
    }
  }
  // border-radius:9px;

  &.skeleton-product {
  }
  .product-space-between-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    /* align-content: space-between; */
    justify-content: space-between;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    .offer-tag {
      position: absolute;
      .offer-holder {
        // position: absolute;
        // left: 8px;
        padding-left: 8px;
        margin-top: 7px;
        .sale {
          position: static;
          z-index: 1;
          background: #b43c3c;
          border-radius: 4px;
          padding: 2px 4px;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: #ffffff !important;
          margin-left: 0 !important;
          // @include desktop{
          //   top: 35px;
          // }
        }
      }
      .buy-more-text {
        padding-left: 8px;
        font-size: 14px;
        position: relative;
        z-index: 9;
        margin-right: 40px;
        line-height: 12px;
        margin-top: 8px;
        word-break: break-word;
        // position: absolute;
        // top: 10px;
        // left: 0;
        // font-weight: 600;
        // font-size: 12px;
        // line-height: 15px;
        // color:#ffffff !important;
        // padding: 2px 18px 2px 4px;
        // // background: #3888C1;
        // background: linear-gradient(290deg, transparent 9px, #3888C1 0);
        // border-top-right-radius: 4px;
        // margin-bottom: 6px;
        // position: absolute;
        // top: 38px;
        // left: 0;
        // z-index: 1;
        // background-color: rgba(255, 255, 255, 0.86);

        @include desktop {
          // top: 40px;
          // font-size: 15px;
        }
      }
    }
    .wishlist-icon,
    .save-for-later-icon {
      position: absolute;
      right: 0;
      cursor: pointer;
      z-index: 1;
      button {
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      .icon-heart-outline,
      .icon-heart-filled {
        color: #dc7070;
        font-size: 20px;
      }
      .wishlist-anim {
        color: #dc7070;
        animation: pulse 0.7s;
      }
    }

    .save-for-later-icon {
      .icon-close-circle {
        color: #616161;
        font-size: 24px;
      }
    }
    .top-container {
      padding: 24px 8px 4px !important;
      .img-wrapper {
        margin-top: 0px !important;
        height: 140px !important;
        width: 140px;
        margin: 0 auto;
        @include desktop {
          height: 160px !important;
          width: 160px;
          margin: 0 auto;
        }
      }
    }
    .bottom-container {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      padding: 10px 8px 12px 8px !important;
      position: relative;
      .xy-product-offer {
        color: var(--primary);
        // margin-bottom: 4px;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        text-decoration-line: underline;
        .icon-offer-tag {
          font-size: 14px;
        }

        a {
          // color: inherit;
          // font-size: 15px;
          // padding-left: 6px;
          // display: inline-block;
          // text-decoration: underline;
        }
      }
      .prod-details-wrap {
        // margin-bottom: 10px;
        margin-top: 2px;
        // min-height: 65px;
      }
    }
  }
  .offer-holder {
    .sale,
    .buy-more-text {
      position: static;
    }
    // position: absolute;
    // top:30px;
    // left:0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .xy-product-offer {
    &.product-list-section {
      text-align: center;
      font-size: 15px;
      text-transform: uppercase;
      margin-top: 15px;
      margin-bottom: 10px;
      color: var(--primary);
      font-weight: bold;
      letter-spacing: 0.8px;

      .icon-offer-tag {
        margin-right: 5px;
      }

      a {
        text-decoration: underline;
        font-size: 14px;
      }
    }
  }
  .strain-container[class*="Hybrid"] {
    .hybrid {
      display: inline-block !important;
    }
  }
  .strain-container[class*="Indica"] {
    .indica {
      display: inline-block !important;
    }
  }
  .strain-container[class*="Sativa"] {
    .sativa {
      display: inline-block !important;
    }
  }
  .strain-container[class*="Hybrid"] {
    .indica {
      display: none !important;
    }
    .sativa {
      display: none !important;
    }
  }
  .strain-container[class*="Indica Hybrid"],
  .strain-container[class*="Hybrid Indica"] {
    .indica {
      display: none !important;
    }
  }
  .strain-container[class*="Sativa Hybrid"],
  .strain-container[class*="Hybrid Sativa"] {
    .sativa {
      display: none !important;
    }
  }
  // .strain-container[class*="Sativa"] {
  //   background-color: #ffd83a !important;
  // }
  // .strain-container[class*="CBD"] {
  //   background-color: #e53935 !important;
  //   color: white;
  // }
  // .strain-container[class*="CBD:THC"] {
  //   background-color: #e91e63 !important;
  //   color: white;
  // }
  // .strain-container[class*="Indica-Hybrid"] {
  //   background-color: #9c27b0 !important;
  // }
  // .strain-container[class*="CBG"] {
  //   background-color: var(--primary) !important;
  // }
  // .strain-container[class*="Sativa-Hybrid"] {
  //   background-color: #3f51b5 !important;
  // }
  // .strain-container[class*="CBN"] {
  //   background-color: #00bcd4 !important;
  // }
  // .strain-container[class*="THC"] {
  //   background-color: #4caf50 !important;
  //   color: white;
  // }
  // .strain-container[class*="THCa"] {
  //   background-color: #4caf50 !important;
  //   color: white;
  // }
  // .strain-container[class*="Total Cannabinoids"] {
  //   background-color: #ffc107 !important;
  // }
  // .strain-container[class*="THCV"] {
  //   background-color: #795548 !important;
  //   color: white;
  // }
  // .strain-container[class*="CBDa"] {
  //   background-color: #607d8b !important;
  // }
  &.bundle-card{
    .price-offer-cart-wrapper{
      .new-add-to-cart{
        @include mobile{
          top: 35px;
        }
      }
    }
  }
}

img.sold-out {
  //display:none;
}
.asap {
  display: inline-block;
  background-color: #ffb100;
  margin-bottom: 5px;
  color: #fff;
  font-size: 10px;
  padding: 4px;
  font-weight: bold;
  border-radius: 3px;
  // i{
  //   margin-left: -6px;
  //   position: relative;
  //   font-size: 12px;
  // }
  span {
    letter-spacing: 1px;
  }
}

.v-list {
  // margin-top: 18px !important;
  .product {
    margin-right: 0 !important;
  }
}

.best-seller {
  text-transform: uppercase;
  color: #ff9800 !important;
  font-weight: bold;
  font-size: 12px;
  // letter-spacing: 0.6px;
  &:before {
    content: "\e9ac";
    font-family: "icomoon" !important;
    margin-right: 3px;
  }
}

.category-product {
  .thc-cbd {
    &::before {
      top: 7px;
    }
  }
}
