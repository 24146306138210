.filter-product-page {
  @include desktop {
    display: block;
    // grid-template-columns:260px calc(100% - 290px);
    // grid-template-columns: 1fr;
    // grid-gap: 30px;
    margin-left: -15px;
    margin-right: -15px;
  }

  .remaining-time {
    //border:2px solid #898989;
    background: #fff;
    border-radius: 5px;
    margin: 0 -15px;
    padding: 0px 5px !important;
    // border-bottom: 1px solid #EFEFEF;
    border-radius: 0px;
    font-size: 13px;
   
    @include desktop {
      margin: 0 -20px;
      font-size: 14px;
      border: none;
    }
    .scheduled-delivery-message-handle, .asap-delivery-message-handle {
      border: 1px solid #ededed;
      padding: 5px 0px;
      margin: 0 10px;
      border-radius: 8px;
      @include desktop {
        border: none;
        padding: 0px;
      }
    }
    .semi-bold {
      display: none;
    }
  }
  .products-stats {
    padding: 15px;
    background: #fafafa;
    margin: 0 -15px;
    @include desktop {
      margin: 0 -20px;
    }
    margin-bottom: 1rem;
  }
  .product-category {
    .sticky-category-container {
      position: sticky;
      top: 130px;
      background: var(--body-background-color);
      z-index: 2;
      padding: 10px 15px;
      margin: 0 -15px;
      @include desktop {
        top: 126px;
      }
    }
    .compare-link {
      font-size: 16px;
      color: #283cd5;
      cursor: pointer;
    }
    .a-category-name {
      font-size: 20px;
      margin-bottom: 0px;
      text-transform: capitalize;
      @include desktop {
        font-size: 26px;
      }
    }
    .description {
      font-size: 14px;
      line-height: 1.3 !important;
      margin-bottom: 4px !important;
      @include desktop {
        // margin-bottom: 34px !important;
        font-size: 16px;
      }
    }
    margin-top: 24.5px;
    @include desktop {
      margin-top: 42.5px;
    }
    & ~ .product-category {
      margin-top: 33px;
      @include desktop {
        margin-top: 45.5px;
      }
    }
  }

  .g-right-sec {
    @include desktop {
      padding: 0 20px;
    }
  }
  .h-list {
    @include desktop {
      margin-left: -15px;
    }
  }
  &.hide-content {
    .k-filter-content {
      display: none !important;
    }
    @include desktop {
      display: block;
      grid-gap: 0px;
      .g-right-sec {
        padding: 0;
      }
    }
  }
}

.new-filters {
  .actions-btn {
    display: none;
  }
  .clear-btn:focus {
    box-shadow: none;
  }
  .custom-checkbox {
    & + .custom-checkbox {
      margin-top: 8px;
    }
  }
  @include desktop {
    position: sticky;
    top: var(--navbar-height);
    // height: calc(100vh - #{$navbarHeight});
    //     overflow: auto;

    .menu-list-parent {
      height: calc(100vh - 104px);
      @include desktop {
        height: auto;
        max-height: 70vh;
      }
      overflow: auto;
    }
  }
  .sidebarBanner {
    padding-left: 15px;
    border-bottom: 1px solid #e0e0e0;
  }
  .rc-collapse-header {
    padding: 10px 15px;
    font-weight: 400 !important;
    color: #898989 !important;
    .arrow {
      right: 15px !important;
    }
  }
  .rc-collapse-content {
    overflow: hidden;
    color: #666;
    padding: 0 20px;
    //background-color: #fff;
    background-color: inherit;
  }
  .filter-sec-parent {
    // display: grid;
    // grid-template-columns: 40% 60%;
    // align-items: start;
    display: flex;
    align-items: flex-start;
    height: 100%;
  }
  .filters-left {
    flex-basis: 140px;
    position: sticky;
    top: 0;
    padding-right: 10px;
    height: calc(100vh - 328px);
    // border-right: 1px solid lightgrey;
    &:after {
      top: 0;
      bottom: 0;
      width: 1px;
      right: 0;
      background-color: red;
      position: absolute;
    }

    .filters-b {
      margin-top: 20px;
    }
    .filters-prop {
      font-size: 16px;
    }
    .filter-selected {
      color: var(--primary);

      &::before {
        content: "";
        border: 2px solid var(--primary);
        border-radius: 4px;
        margin-right: 5px;
        margin-right: 6px;
        transform: 3s ease;
      }
    }
    .not-selected-filter {
      color: #37475b;
      &::before {
        content: "";
        border: 2px solid transparent;
        border-radius: 4px;
        margin-right: 5px;
        margin-right: 6px;
        transform: 3s ease;
      }
    }
  }
  .filters-right {
    height: 100%;
    padding-left: 10px;
    padding-top: 10px;
    flex: 1;
    border-left: 1px solid lightgrey;

    @include mobile{
      border-left: 0;
      position: relative;

      &::before{
        content: '';
        position: absolute;
        width: 1px;
        height: 60%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: #f5f5f5;
      }
    }

    .filter-dropdown {
      height: 100%;
    }
    .filters-dropdown-list {
      height: calc(100% - 50px);
      overflow: auto;
      @include desktop {
        max-height: 44vh;
        overflow-y: scroll;
      }
      padding-left: 2px;
    }
    .f-search-parent:empty + .filters-dropdown-list {
      height: calc(100% - 0px);
      @include desktop {
        max-height: 44vh;
        overflow-y: scroll;
      }
    }
  }
}

.filter-popup {
  background-color: $modalBGColor;
  .modal-dialog {
    // display: flex;
    // align-items: flex-end;
    // min-height: 100%;
    // margin: 0rem;
    .modal-content {
      // height: calc(100vh - 160px);
      // max-height:calc(100vh - 160px) ;
    }
  }
  .modal-body {
    overscroll-behavior: contain;

    height: calc(75vh - 125px);
    @include desktop {
      height: auto;
    }
    // overflow: auto;
    .sidebarContent {
      padding-bottom: 0px;
      height: 100%;
      @include desktop {
        padding-bottom: 0px;
      }
    }
  }
  .sidebarBanner {
    display: none !important;
  }

  .modal-header {
    // display: none;
  }
  .new-filters .rc-collapse-content {
    padding: 0 10px;
  }
  .new-filters .sidebarBanner {
    padding-left: 0;
  }
}
