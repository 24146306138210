.ambassador{
  margin-top: 20px !important;
  padding-top: 20px !important;
  padding: 0 10px;
  padding-bottom: 30px !important;
  &.in-app {
    margin-top: 0px !important;
    border-radius: 0px !important;
  }
  @include desktop{
    margin-top: 69px !important;
    padding: 0 25px;
    padding-bottom: 30px !important;
  }
  div.zipps-margin,p{
    font-size: 13px;
    font-weight: 500;
    line-height: 1.462;
    margin-bottom: 0;
  }
  div.zipps-margin{
    margin: 21px 0;
  }
  .one-rem-mt{
    // border-top: 1px solid #d3d3d3;
    box-shadow: -6px -7px 1px -7px #d3d3d3;
    // margin: 0 -25px;
    padding: 25px 25px 20px;
  }
  small{
    font-size: 15px;
    line-height: 1.533;
    vertical-align: super;
  }
  .label{
    vertical-align: sub;
  }
  .total{
    font-size: 18px;
  }
  & + .footer{
    display: none !important;
  }

  
  .mini-label{
    margin-bottom: 7px;
    font-size: 13px;
    font-weight:600;
    color:#373737;
  }
  .form-group{
    margin-bottom: 2rem;
  }

  .form-control{
    
    font-size:13px;
    font-weight: 500;
    min-height:40px;
  }

  .ref-link-parent{
    padding: 10px 10px;
    margin-right: 15px;
  }

  .g-referral-code{
    font-weight: 500;
    color:#4D79E0;
    text-decoration: underline;
    // margin-bottom: 97px;
  }
  .ref-link-parent {
    width: 80%;
    @include desktop{
      width: 50%;
    }
    .ref-link{
      font-weight: 500;
      font-size: 18px;
      color: var(--primary);
      padding-right: 40px;
    }
  }
  .add-bank-account{
    margin-bottom: 40px;
    @include notDesktop{
      margin-bottom: 30px;
    }
    .button-underline{
      font-size: 13px;
      font-weight: 500;
      color: #4D79E0;
      text-decoration: underline;
    }
    .card-container{
      margin-top: 40px;
      margin-bottom:15px;
      label{
        font-weight:600;
        font-size: 13px;
        color:#373737
      }
    }
    .update-card{
      
    }
  }

  .r-cash-out-button{
    padding-left: 0px;
    width:141px;
    height:45px;
    border-radius:7px;
    margin-top: 40px;
    font-weight: 700;

    @include notDesktop{
      width: 100%;
      margin-top: 70px;
    }

    &:disabled{
      color: #C4C4C4;
      background-color:#FAFAFA ;
    }

   
  }

  .a-save-address{
    margin-bottom: 77px;
    height: 40px;
    @include notDesktop{
      margin-bottom: 57px;
    }
  }

  .promo-code-container{
    input{
      // border-right: 1px solid var(--primary);
      border-radius: 3px;
      border: 1px solid #ced4da;
    }
    .apply-btn{
      width:100%;
      height:40px;
      border-radius: 6px;
      @include desktop{
        width: 60%;
      }
    }

    &.generate-custom-code{
      ::placeholder {
        color: #A3A0B4;
      }
    }
   
  }
  .generate-codes{
    margin: 10px 0px 30px 0;
    .code-list{
      .c-list-item{
        margin-bottom: 25px;
        border-bottom: 0.5px solid #D3D3D3;
        font-size: 15px;
        font-weight: 700;
        .right-sec{
          // button{
          //   i{
          //     color: #D4CEF5;
          //   }
          // }
        }
        .left-sec{
          margin-bottom: 12px;
        }
      }
    }
  }

  &.referral-inner{
    .bonus-title{
      // font-weight: bold;
      font-size: 15px;
      color: #373737;
      margin-bottom: 8px;
      @include notDesktop{
        font-size: 15px;
        margin-bottom: 2px;
      }
     
    }
    .s-bonus-amount{
      font-weight:600;
      font-size: 24px;
      line-height: 1.22;
      color: #C4C4C4;
      @include notDesktop{
        font-size: 18px;
        margin-bottom: 15px;
      }
    }
    .rewards-link{
      font-size:18px;
      color: #4D79E0;
      margin-bottom: 40px;
      text-decoration: underline;
      @include notDesktop{
        margin-bottom: 30px;
      }
    }

    .bank-account{
      &-link{
        font-size:18px;
        color: #4D79E0;
        text-decoration: underline;
        margin-bottom: 72px;
      }
      .remove-hight-color{
        color:#373737;
      }

      .badge-warning{
        background-color: #FCE09A;
      }

      .g-badge{
        margin-left: 25px;
      }
      .badge-success{
        background-color: #6AC478;
      }
    }


  .ref-link-header{
      color:#373737;
      font-size:15px;
      margin-top: 40px;
      margin-bottom: 8px;

      @include notDesktop{
        margin-top: 30px;
        margin-bottom: 10px;
      }
  }
  .ref-link-wrapper{
      margin-bottom: 21px;
      button{
        height:100%;
        border: none;
        border-left: 1px solid var(--primary);
      }
      .link-share-btn{
        height: 50px;
        font-size: 18px;
        background: var(--primary);
        border: none;
        color: #fff;
        border-radius: 6px;
        padding: 10px 15px;
        @include desktop{
          padding: 10px 30px;
        }
      }
  }

  }

  & #ambassador_bank_id 
  {
    // .my-file-input > label{
    //   color: #4D79E0 !important;
    //   text-decoration: underline;
    // }
    img.thumbnail {
      width: 50px;
      height: 50px;
      border-radius: 6px;
      object-fit: cover;   
    }
    .thumbnail{
      display: block;
        margin: 30px;
        &:before{
          color: #DED7E9;
          font-size: 46px;
        }
    }
  }
  .btn:focus{
    box-shadow: none;
  }
  
}


.ambassadorRewardsPopup{
   .modal-dialog{
    max-width: 400px;
    .modal-header{
      box-shadow: 0 0 10px 1px rgb(221 221 221 / 30%) !important;
      border-bottom: 0 !important;
    }
    .modal-body{
      padding-top: 40px !important;
      @include desktop{
        max-height: calc(100vh - 290px);
        overflow: auto;
      }
      @include notDesktop{
        height: calc(100vh - 248px) !important;
      }
    }
  }
}
.ambassador-earnings{
  width: 100%;
  @include desktop{
    width: 25%;
  }
}
// .bottom-fixed-popup{
//   .modal-dialog{
//     .logo-container{
//       margin: 8px auto 27px;
//     }
//     .modal-content{
//       border-radius: 5px;
//       border: none !important;
//     }
//     .modal-body{
//       @include desktop {
//         height: auto !important;
//         min-height: 400px;
//       }
//       padding: 25px 30px;            
//     }
//     .modal-title{
//       color: var(--primary);
//       font-size: 20px;
//       line-height: 1.25;
//     }
//     .form-control{
//       border-radius: 11px;
//       background: #f5f5f5;
//       border: none;
//       padding: 26px 30px;
//       font-size: 14px;
//       color: #353535;
//       box-shadow: none;
//       // box-shadow: inset -1px 1px 4px 0px rgba(0, 0, 0, 0.1);
//       white-space: nowrap;
//       // text-overflow: ellipsis;
//       overflow: hidden;
//       width: 100%;
//       display: inline-block;  
//       margin-top: 20px;    
//       max-width: 339px;
//     }
//     ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
//       color: #A3A0B4;
//       opacity: 1; /* Firefox */
//     }
    
//     :-ms-input-placeholder { /* Internet Explorer 10-11 */
//       color: #A3A0B4;
//     }
    
//     ::-ms-input-placeholder { /* Microsoft Edge */
//       color: #A3A0B4
//     }
//     .error-msg {
//       color: #EF4152;
//       margin-top: 5px !important;
//       max-width: 339px;
//       margin: auto;
//     }
//     @include notDesktop{        
//         margin-top:15px;
//         margin-bottom: 0 !important;
//         max-width: 100%;
//         .modal-content{
//           border-radius: 10px;
//           border-bottom-left-radius: 0;
//           border-bottom-right-radius: 0;  
//         }
//         .modal-body{
//           padding: 50px 25px 30px;     
//           height: calc(100vh - 18px);
//           overflow: auto;
//         }
//     }
//     @include desktop{        
//         max-width: 460px;        
//     }
    
//   }
// }