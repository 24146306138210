.tesla-banner{
  font-size: 16px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: cover;
  padding: 50px 20px;
  background-image: linear-gradient(#000B17, #002230, #003e4d, #000003);
  min-height: 650px;
  @include desktop(){
    min-height: 600px;
    padding: 40px;
    background-image: url(../../images/tesla-banner.jpg);
  }
  background-image: url(../../images/tesla-banner-m.jpg);
  h1{
    font-weight: 800;
    font-size: 33px;
    @include desktop(){
      font-size: 50px;
    }

    color:#fff;
    text-shadow: 2px 2px #4b4b4b, -2px 2px #707070, 2px -2px #333, -2px -2px #595959, 1px 1px 30px #000;
    // color: #063A3F; 
    // text-shadow: 
    // 1px  1px     #fff, 
    // -1px  1px     #fff, 
    // 1px -1px     #fff, 
    // -1px -1px     #fff,
    // 1px  1px 5px #555;
  }
  h2{
    font-weight: 700;
    font-size: 20px;
    padding-top: 10px;
    @include desktop(){
      font-size: 26px;
    }
    color: #fff;
    max-width: 600px;
    margin: auto;
    text-shadow: 
    1px  1px     #063A3F, 
    -1px  1px     #063A3F, 
    1px -1px     #063A3F, 
    -1px -1px     #063A3F,
    1px  1px 15px #063A3F;

  }
  .tesla-pic{
    max-width: 300px;
    @include desktop(){
      min-height: 350px;
    }
    min-height: 220px;
  }
  p{
    font-weight: 600;
    @include desktop(){
      font-size: 30px;
    }
    font-size: 18px;
    color: #fff;
  }

}
.tesla-container-width{
  max-width: 100%;
  @include desktop{
    max-width: 1200px;
  }
  margin-right: auto;
  margin-left: auto;
}
.tesla-contest-wrapper{
  margin-top: var(--navbar-height);
  .tesla-ticket-wrapper{ 
    background-color: #fff;
    margin-bottom: 70px;
    padding: 40px 15px;
    justify-content: center;
    gap: 40px;
    @include desktop{ 
      padding: 40px 0;
    }
    .ticket-count, .entries-count{
      padding: 10px 20px;
      font-size: 35px;
      line-height: 58px;
      @include desktop{
        font-size: 48px;
      }
    }
    .tesla-ticket{
      position: relative;
      background-color: #ffba68;
      border-radius: 8px;
      min-width: 170px;
      &::after,&::before{
        content: "";
        position: absolute;
        width: 15px;
        height: 15px;
        background-color:#fff;
        border-radius: 10px; 
      }
      &::after{
        right: -7px;
        top: 70px;
      }
      &::before{
        left: -7px;
        top: 70px;
      }
      .ticket-counter{
        padding: 10px;
        font-weight: 600;
        border-top: 2px dashed #fff;
        background-color: #ffd4a0;
        border-radius: 8px;
      }
    }
    .tesla-entries{ 
      position: relative;
      background-color: #ffba68;
      border-radius: 8px;
      min-width: 160px;
      &::after,&::before{
        content: "";
        position: absolute;
        width: 15px;
        height: 15px;
        background-color:#fff;
        border-radius: 10px; 
      }
      &::after{
        right: -7px;
        top: 70px;
      }
      &::before{
        left: -7px;
        top: 70px;
      }
      .entries-counter{
        padding: 10px;
        font-weight: 600;
        border-top: 2px dashed #fff;
        background-color: #ffd4a0;
        border-radius: 8px;
      }
    }
  }
  .tesla-participate-wrapper{
    margin-bottom: 50px;
    justify-content: center;
    padding: 0 30px;
    font-size: 16px;
    @include desktop{
      justify-content: space-evenly;
      margin-bottom: 100px;
      padding: 0;
    }
    .how-to-participate{
      @include desktop{
        max-width: 350px;
      }
      
    }
    .tesla-count{
      display: block;
      font-size: 20px;
      text-align: center;
      width: 32px;
      height: 32px;
      background-color: #309fa6;
      border-radius: 4px;
      margin-right: 20px;
      color: #fff;
    }
    .participate-img{
      margin-top: 50px; 
      @include desktop{
        margin-top: 0px;
      }
      img{
        width: 100%;
        @include desktop{
          width: 220px;
        }
      }
    }
  }
  .drive-home-bg{
    background-color: #eeeeee;
    @include desktop{
      padding: 80px 0;
    }
    padding: 50px 0;
    margin-bottom: 100px;
  .drive-home-tesla{
    justify-content: center;
    flex-direction: column; 
    padding: 0 15px;
    @include desktop{
      justify-content: space-between;
      flex-direction: row; 
      padding: 0;
    }
    img{
      width: 100%;
      @include desktop{
        // width: 500px;
      }
    }
    .carousel-root{ 
      .control-arrow{
        display: none;
      }
      .control-dots .dot{
        background: #868686;
      }
    }
    .tesla-carousel-para{ 
      margin-top: 50px;
      @include desktop{
        margin-top: 0px;
      }
      ul{
        padding-left: 20px;
        font-size: 16px;
      }
    }
    h2{
      font-size: 24px;
    }

    .achievement{
      display: flex; 
      flex-direction: row;
      font-weight: 700;
      gap: 100px;
      text-transform: uppercase;
      &>div{
        max-width: 150px;
        flex-wrap: wrap;
        display: flex; 
        flex-wrap: wrap;
        gap: 10px;
        img{
          max-width: 80px;
        }
      }
    }
    .desclaimer{ 
      color: #555;
    }
  }
}
  .tesla-adv{
    font-size: 24px;
    margin-bottom: 25px;
  }
  .tesla-banner{
    .tesla-counter-wrapper{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;
      width: 57px;
      height: 64px;
      background: rgba(0, 0, 0, 0.3);
      color: #fff;
      border-radius: 4px;
      margin-right: 20px;
      font-weight: 100;
      .tesla-counter{
        font-size: 30px;
        font-weight: bold;
        line-height: 1.2;
      }
    }
  }
  .tesla-daily-deals, .tesla-adv{
    padding: 0 15px;
    @include desktop{
      padding: 0;
    }
  }
  .t-share-btn{
    position: fixed;
    right: 10px;
    bottom: 10px;
    z-index: 99;
    width:50px;
    height:50px;
    border-radius: 50%;
    box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.37);
    display: flex;
    justify-content: center;
    align-items: center;
    @include desktop{
      right:20px;
      bottom:20px;
    }
  }
}