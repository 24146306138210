.prescription {
  padding: 15px;
  padding-top: $navbarHeight + 15px;
  height: 100%;
  overflow: auto;
  @include min-height-desktop;

  .c-name {
    display: none;
  }
  .check-symbol{
    font-size: 80px;
    color: var(--primary);
    margin: 2rem;
    display: block;
  }

  @include desktop {
    max-width: 450px;
    margin: auto;

  }
  .medicalID-btn{
    display: none;
  }

  .id-upload .img-container {
    height: auto;
    margin-top: 0.5rem;
    img {
      width: 100%;
      height: auto;
    }
  }


  .list {
    height: 100%;
    overflow: auto;
    padding: 15px;
    padding-bottom: 100px;
  }

  $imgDimens: 50px;

  .item {
    padding: 15px 5px 15px 70px;
    /* padding-left: 70px; */
    margin-bottom: 15px;
    position: relative;
    min-height: 60px;

    // & :last-child {
    //   margin-bottom: 100px;
    // }
    .is-active {
      position: absolute;
      right: -7px;
      top: -5px;
      padding: 3px 7px;
      background: $primary;
      background: var(--primary);
      color: white;
      border-radius: 3px;
      font-size: 0.79em;
      letter-spacing: 0.1em;
    }

    .requested,
    .Requested {
      color: #2196f3;
    }

    .approved,
    .Approved {
      color: $primary;
      color: var(--primary);
    }

    .rejected,
    .Rejected {
      color: #f44336;
    }

    img {
      width: $imgDimens;
      height: $imgDimens;
      object-fit: contain;
      position: absolute;
      left: 9px;
      top: 50%;
      transform: translateY(-50%);

      // transition: all 0.8s ease-in;
      &.floated-img {
        position: fixed;
        width: 80%;
        height: 80%;
        z-index: 1;
      }
    }
  }

  .uppy-Dashboard--modal {
    z-index: 9999;
    position: relative;
  }

  .uppy-DashboardTabs {
    padding-top: 25px;
    padding-bottom: 25px;
    border-bottom: 1px dashed #b9b9b9;
  }

  .uppy-Dashboard-close .UppyIcon {
    width: 22px;
    height: 22px;
  }

  .uppy-Dashboard-close {
    top: 25px;
    right: 25px;
  }

  .uppy-Dashboard-close:focus {
    outline: 0;
  }

  // .uppy-Dashboard-close {
  //     top: 80px;
  // }
  // .uppy-DashboardTabs {
  //     padding-top: 70px;
  // }
  .hero-image {
    width: 80px;
    height: auto;
    margin: 30% auto 25px auto;
    display: block;
  }

  .btn-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px 0;
    background-color: white;
  }

  .upload-btn {
    width: 140px;
  }
  
}