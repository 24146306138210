.bottom-sec-wrapper {
  .more-btn {
    margin-top: 28px !important;
    font-size: 14px !important;
    @include desktop {
      margin-top: 33px !important;
    }
    button {
      border: 1px solid var(--primary) !important;
      border-radius: 6px;
      background-color: transparent !important;
      color: var(--primary) !important;
      min-width: 90px;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px !important;
      padding: 7px !important;
      text-decoration: none !important;
    }
  }
  .brands-listing,
  .cities-listing {
    background: linear-gradient(360deg, #e8f2f2 0%, #bed0d0 100%) !important;
    padding: 70px 0 70px !important;
    @include desktop {
      padding: 64px 0 64px;
    }
    // .bottom-catalog-title {
    //     color: #000;
    //     font-size: 2rem;
    //     margin-bottom: 5px;
    // }
    // .bottom-catalog-title {
    //   margin-bottom: 8px;
    //   font-size: 24px !important;
    //   line-height: 29px !important;
    //   @include desktop {
    //     font-size: 32px !important;
    //     line-height: 39px !important;
    //   }
    // }
    p {
      // color: #000 !important;
      max-width: 780px;
      // margin: auto;

      // color:#000 !important;
      margin: 0 auto 20px auto;
    }
    .bottom-catalog-listing {
      font-size: 14px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      // grid-row-gap: 10px;
      margin-bottom: 0px;
      grid-column-gap: 26px;
      grid-row-gap: 15px;
      @include desktop {
        grid-template-columns: repeat(6, 1fr);
      }
      .bottom-catalog-row {
        color: #333;
        font-size: 16px;
        min-width: 0;
        text-align: left;
      }
    }
    .brand-sec-home {
      margin-bottom: 34px !important;
      margin-top: 34px !important;
      .h-list {
        padding: 0 !important;
        @include desktop {
          padding: 0px 15px 0px 15px;
        }
        .brand-name {
          flex: 0 0 130px !important;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          height: 130px;
          @include desktop {
            flex: 0 0 150px !important;
            height: 150px;
          }
          .img-brand {
            width: 100% !important;
            height: 100% !important;
            border-radius: 8px;
          }
          &:first-child {
            margin-left: auto;
          }
          &:last-child {
            margin-right: auto;
          }
        }
      }
    }
    // .more-btn {
    //     margin-top: 28px;
    //     font-size: 14px;
    //     @include desktop {
    //       margin-top: 40px;
    //     }
    //     button {
    //       border: 1px solid #333333;
    //       border-radius: 6px;
    //       background-color: transparent;
    //       color: #000;
    //       min-width: 90px;
    //       padding: 3px;
    //     }
    //   }

    .seeMore {
      // max-width: 1024px;
      margin: auto;
      overflow: hidden;
      height: 155px;
    }
  }
  .brands-listing {
    padding: 35px 0 35px !important;
    @include desktop {
      padding: 64px 0 64px !important;
    }
    .more-btn {
      // button {
      //   border: 1px solid #333333 !important;
      //   background-color: transparent !important;
      //   color: #000 !important;
      // }
    }
    .seeMore {
      // height: 188px !important;
      height: 182px !important;
    }
    .bottom-catalog-row {
      word-break: break-word;
    }
  }
  .faq-container {
    h2 {
      font-size: 24px !important;
      line-height: 29px !important;
      margin-bottom: 35px !important;
      @include desktop {
        font-size: 32px !important;
        line-height: 39px !important;
      }
    }
    .faq-qna {
      border: 1px solid #c9cfd5;
      border-radius: 16px;
      padding: 24px 24px 15px 24px;
      @include desktop {
        padding: 36px;
      }
      .faq-quest {
        margin-bottom: 15px;
        p {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          @include desktop {
            font-size: 20px;
            line-height: 24px;
          }
        }
      }
      .faq-ans {
        p {
          color: #616161;
          font-size: 14px;
          line-height: 17px;
          @include desktop {
            font-size: 16px;
            line-height: 19px;
          }
        }
      }
    }
  }
  .how-default-works,
  .why-chose-us {
    h2 {
      span {
        font-size: 24px !important;
        line-height: 29px !important;
        margin-bottom: 15px !important;
        @include desktop {
          font-size: 32px !important;
          line-height: 39px !important;
          margin-bottom: 35px !important;
        }
      }
    }
  }
  .why-chose-us {
    p {
      font-size: 16px;
      line-height: 22px;
      color: #616161;
    }
    h5 {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
    }
    .carousel-slider {
      overflow: visible;
      @include desktop {
        overflow: hidden;
      }
      .control-dots {
        // margin: 8px 0px 0px 0px;
        position: absolute;
        bottom: 10px;
        margin: 0;
        .dot {
          margin: 0px 8px 0px 0px;
          width: 8px;
          height: 8px;
          opacity: 0.75;
          display: inline-block;
          background: #ebebeb;
          border-radius: 19px;
          cursor: pointer;
          transition:
            opacity 0.3s,
            background-color 0.3s,
            width 0.3s;
          transition-delay: 0.3s, 0.3s, 0s;
          box-shadow: none;
          &.selected {
            background: var(--primary);
            width: 24px;
            opacity: 1;
            transition-delay: 0s;
          }
        }
      }
    }
  }
  .zipcodes-listing,
  .zip-code-bento {
    padding: 34px 0 42px !important;
    .bottom-catalog-listing {
      margin-left: 0px !important;
      margin-right: 0px !important;
      grid-column-gap: 10px;
    }
  }
  .cities-listing {
    background: #f3f3f3 !important;
    padding: 34px 0 42px !important;
  }
  .desc-wrap {
    padding: 20px 0;
  }
  .brands-listing,
  .cities-listing,
  .zipcodes-listing {
    .bottom-catalog-title {
      margin-bottom: 8px;
      font-size: 24px !important;
      line-height: 29px !important;
      @include desktop {
        font-size: 32px !important;
        line-height: 39px !important;
      }
    }
    p {
      color: #000 !important;
      margin: 0 auto 20px auto;
      text-align: center;
      font-size: 14px !important;
      font-weight: 400;
      line-height: 21px;
    }
  }
}
