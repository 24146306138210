
.gd-brand-menu{
    margin-bottom: 0px !important;
		@include desktop{
			margin-bottom: 37px !important;
		}

		.gc-inner{
			background-color:#fff;
			padding:0 15px;
			@include desktop {
			}
		}

		.category-item {
			.h-list-parent{
				margin:0;
				.h-list{
					padding: 5px;
					margin: 0;
		
				}
				.scroll-btn{
					display: inline-block;
					transform: translateY(0%) scale(1);
					background-color:#fff;
					display: none;
					color: #000;
					@include desktop{
						display: block;
						background-color: #fff;
					}
					box-shadow: none;
					width: auto;
					top: 0;
					bottom: 0;
					height: auto;
					border-radius: 0;
					&.right{
						// right: -46px;
						right: 0px;
					}
					&.left{
						left:0px;
					}
				}
			}
			.d-item {
				flex: 0 0 auto;
				padding: 5px;
				margin-right: 12px;
				border: 2px solid #F7F7F7;
				border-radius: 5px;
				background-color: #F2F1F6;
				// height: 26px;
				&.c-selected{
					position:relative;
					&:after{
						content:"";
						position:absolute;
						bottom: -4px;
						left:0;
						right:0;
						height:2px;
						background:var(--primary);
						@include desktop{
							bottom: -13px;
							height: 5px;
							border-radius: 10px;
							background-image: linear-gradient(to right, #71c273, #36bfc5);
						}
					}
				}
				@include desktop{
					flex: 0 0 150px;
					padding: 0;
					margin-right: 38px;
					border-radius: 14px;
					height: auto;
				}
				a {
					text-decoration: none;
					align-items: center;
					display: flex;
					flex-direction: column;
					.outer-circle {
						
						// background-image:linear-gradient(#71c273,#36bfc5) ;
						padding: 10px 12px 0px 12px;
						// border-radius: 100%;
						// display:none;
						@include desktop {
							display:block;
						}
						img{
							height: 50px;
							width: auto;
							@include desktop {
								// height: 67px;
								height: 100px;
							}
						}
						
					}
				}
				.figcaption {
					display:block;
					font-weight: bold;
					color:var(--text-on-background-color);
					font-size: 14px;
					margin: auto 0px;
					// padding: 5px;
					@include desktop{
						margin: 4px 0px 8px;
						font-size: 16px;
					}
				}
				&.edible-item{
					img{
						position: relative;
						left:3px;
						@include desktop{
							left:4px;
						}
					}
				}
			}
			.d-item:hover{
				border: 2px solid var(--primary);
			}
		}

		&.full-width{
			background-color: #ecf8f8;
			.gc-inner{
				padding: 0 15px;
				@include desktop {
					width: 1140px;
					margin: auto;
					padding: 0px;
				}
			}
		}

		
		@include ipadPro{
			padding-right: 15px;
			padding-left: 15px;
		}

    // margin-bottom: 40px !important;
		margin-top: 20px !important;
		@include desktop{
			margin-bottom: 62px !important;
			margin-top: 48px !important;
		}
		h2{
			font-size: 20px;
			font-weight: 500;
			margin-bottom: 22px;
			@include desktop{
				font-size: 26px;
				margin-bottom: 42px
			}
		}

    .img-brand{
      width:130px;
      height:130px;
      object-fit: contain;
      // border: 1px solid #EBEBEB;
      border-radius:10px;
			@include desktop{
				width:200px;
				height:200px;
			}
			transition: all 0.3s ease-in;
			// &:hover{
			// 	transform: scale(1.05);
			// }
    }
    
    .brand-name{
			border: 1px solid #EBEBEB;
    	border-radius: 10px;
      margin-right: 25px;
      padding: 0px !important;
			// @include desktop{
			// 	overflow: hidden;
			// }
       &:last-child{
        margin-right: 0px;
      }
    }
    
  }
