.friends-family-wrapper{

  @include notDesktop(){
    padding-top: 15px !important;
  }

  .header-content-holder{
    background: $friends-background;
    padding: 50px 0;

    .logo-holder{
      height: 80px;
      text-align: center;

      @include desktop(){
        text-align: left;
      }

      img{
        height: 100%;
      }
    }

    .content{
      color: $primary;
      text-align: center;

      @include desktop(){
        text-align: left;
        letter-spacing: 0.6px;
      }

      .title{
        font-weight: 600;
        font-size: 28px;
        line-height: 1.3;
        margin-bottom: 10px;
        margin-top: 30px;

        @include desktop(){
          font-size: 40px
        }

        span{
          @include desktop(){
            display: block;
          }
        }
      }

      p{
        @include desktop(){
          font-size: 16px;
        }
      }

      .bottom-handle{

        div{
          font-size: 11px;
          margin-top: 10px;
        }
      }
    }

    .img-holder{
      height: 200px;
      width: 200px;
      border-radius: 50%;
      overflow: hidden;
      margin-top: 30px;

      @include desktop(){
        height: 320px;
        width: 320px;
        margin-top: 0;
      }

      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .friends-works-holder{
    color: $friends-text-color;

    .img-holder{
      display: none;

      @include desktop(){
        height: 550px;
        text-align: center;
        display: block;
      }

      img{
        height: 100%;
      }
    }

    .content-holder{
      .title{
        font-size: 28px;
        font-weight: 600;
      }
      
      p{
        font-size: 16px;
      }

      .icons-content-section{
        display: flex;
        align-items: center;

        span[class^="icon-"]{
          font-size: 60px;
        }

        .content{
          margin-left: 20px;

          .sub-title{
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 4px;
          }

          .small-text{
            color: lighten($friends-text-color, 15%);
            font-size: 11px;
            margin-top:5px;
          }
        }
      }
    }
  }

  .info-text-holder{
    background-color: #F0F1F0;
    color: $friends-text-color;
    text-align: center;

    h1, h5{
      font-weight: 600;
      letter-spacing:1px
    }

    h1{
      font-size: 40px;
      padding-bottom: 30px;
      border-bottom: 1px solid;
      margin-bottom: 30px;

      @include desktop(){
        font-size: 40px;
        padding-bottom: 0px;
        border-bottom: 0;
        margin-bottom: 0px;
      }

      span{
        display: block;
        font-size: 85px;
        letter-spacing: 2px;
        margin-bottom: -10px;

        @include desktop(){
          display: inline-block;
          font-size: 40px;
          letter-spacing: normal;
        }
      }
    }
  }

  .benefits-holder{
    text-align: center;
    background: $friends-background;

    @include desktop(){
      background: none;
    }

    .title{
      color: $primary;
      font-weight: 600;
      margin-bottom: 10px;
      font-size: 22px;

      @include desktop(){
        color: #899090;
        margin-bottom: 40px;
      }
    }

    .row{
      padding-left: 30px;
      padding-right: 30px;

      @include desktop(){
        padding-left: 60px;
        padding-right: 60px;
      }

      .content{
        display: flex;
        background: none;
        color: $primary;
        align-items: center;  
        font-weight: 500;
        text-align: left;
        line-height: 1.3;
        font-size: 16px;
        padding-top: 20px;
        padding-bottom: 20px;

        @include desktop() {
          background: $friends-background;
          border-radius: 6px;
          padding: 0 30px;
          height: 80px;
          font-size: 15px;
        }

        span{
          font-size: 30px;
          display: block;
          margin-right: 20px;

          @include desktop() {
            font-size: 22px;
          }
        }
      }

      +.row{
        @include desktop(){
          margin-top: 40px;
        }
      }
    }
  }

  .testimonials-holder{
    margin-top: 30px;
    color: $friends-text-color;

    .container{
      @include desktop() {
        border-top: 1px solid #C4C4C4;
      }

      .title{
        font-size: 32px;
        font-weight: 600;
        letter-spacing: 1px;
      }

      .carousel-root{
        margin-top: 40px;

        .carousel-slider{
          .slider-wrapper{
            padding-bottom: 40px;

            @include desktop(){
              padding-bottom: 0;
            }

            .slider{
              @include desktop(){
                transform: none !important;
                transition-duration: unset !important;
              }
              
              .slide{
                background: #F8F8F8;
                border-radius: 20px;
                padding: 35px 30px;
                word-break: break-word;

                +.slide{
                  @include desktop(){
                    margin-left: 30px;
                  }
                }

                @include desktop() {
                  min-width: auto;
                }

                .testimonial-section{
                  .head{
                    display: flex;
                    align-items: center;

                    img{
                      height: 80px;
                      width: 80px;
                      border-radius: 50%;
                      object-fit: cover;
                    }

                    .sub-text{
                      color: #899090;
                      font: 13px;
                    }
                  }
                }
              }
            }
          }

          .control-dots{
            bottom: -10px;

            @include desktop() {
              display: none;
            }

            .dot{
              box-shadow: none;
              background: transparent;
              border: 1px solid #003F47;
              width: 12px;
              height: 12px;
              opacity: 1;

              &.selected{
                background: #003F47;
              }
            }
          }

          .carousel-status{
            display: none;
          }
        }
      }
    }
  }
}