.order-summary {
    background-color: $modalBGColor;
    .modal-body {
        max-height: 70vh;
        height: auto;
        overflow: auto;
    }
    .custom-btn {
        @include notDesktop{
            font-size: 0.8rem;
            padding: 8px;
        }
      
    }
    .c-padding {
        padding: 0 5px;
    }
    .modal-footer {
        // padding: 15px 10px;
        // justify-content: space-around;
    }
    @include iphone5 {
        .modal-body {
            max-height: 60vh;
        }
        .hide-iphone-5 {
            display: none;
        }
    }
    .small-text {
        font-size: 0.8rem;
        line-height: 16px;
    }
    .b-ctrl:last-child {
        border-bottom-width: 0;
    }
    .order {
        position: relative;
        padding: 10px;
        padding-left: 40px;
        img {
            position: absolute;
            left: 5px;
            top: 50%;
            transform: translateY(-50%);
            height: 26px;
            width: 26px;
            object-fit: contain;
        }
        .quantity {
            font-size: 1.2rem;
            margin-left: 5px;
        }
    }
    .normal-order {
        border-bottom: 1px solid $dividerColor;
    }
    .updated-order {
        background-color: rgba(255, 0, 0, 0.1);
        background-color: rgb(254, 239, 238);
        border-radius: 4px;
        box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.16);
        margin-top: -1px;
        position: relative;
        .bottom-info {
            font-size: 0.8rem;
            padding: 2px 5px;
        }
        .strike-text:after {
            background-color: #f44336;
        }
        & + .updated-order {
            margin-top: 10px;
        }
    }
}
