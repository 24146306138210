.category-product {
  // padding: 15px;
  padding-top: calc(var(--navbar-height) + 5px);
  // height: 100%;
  // overflow: auto;
  padding-bottom: 50px;
  min-height: 100vh;

  .remaining-time{
    //border:2px solid #898989;
		background:#fafafa;
    border-radius:5px;
    margin: 0 -15px;
    @include desktop {
      margin: 0 0px;
    }
  }

  .main-title {
    @include iphone5 {
      font-size: 1.4rem;
    }
  }
  .v-list{
    // grid-gap:0;
    
  }

  @include desktop {
    padding: 0;
    padding-bottom: 150px;
    .c-holder{
        margin:0 -15px;
        .delivery-tabs {
            margin-left: 0;
            margin-right: 0;
        }
    }
  }

  @include min-height-desktop;

  // .description {
  //     color: #6c6c6c;
  //     line-height: 1.3;
  //     font-size: 14px;
  //     @include notDesktop {

  //     }
  // }

  .product-grid {
    padding: 10px;
    // box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
    background: white;

    // a {
    //     text-decoration: none;
    //     color: #333;
    // }
    .name {
      font-size: 1em;
      margin: 5px 0 5px 0;
    }

    p {

      // margin-top: 10px;
      // /* font-size: 0.8em; */
      // min-height: 33px;
      // margin-bottom: 5px;
      @include iphone5 {
        //min-height: 39px;
      }

      @include real-iphone5 {
        min-height: 27px;
      }

      @include desktop {
        font-size: 14px;
        // height: 50px;
      }
    }

    .sub-category {
      font-size: 1em;
      color: #a0a0a0;
      display: block;
    }

    // .price-container {
    //     top:0px;
    // }
    .weight-container {

      //font-size: 12px;
      // margin-bottom: 3px;
      // margin-top: 4px;
      i {
        margin-right: 5px;
      }
    }

    img {
      object-fit: contain;
      margin: 0 auto;
      height: 100%;
    }

    .img-wrapper {
      height: 180px;

      // margin-bottom: 20px;
      @include desktop {
        height: 220px;
      }

      div {
        width: 100%;
        height: 100%;
      }
    }

    .sold-out {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 75%;
    }
  }
}