.compare-page-content {
  // padding-left: 0;
  // padding-right: 0;
  padding-top: ($currentOrderInfoHeight + 20px);
  // width: auto;
  // margin: 0 auto;
  // min-height: calc(100vh);
  // display: flex;
  // justify-content: center;
  // height:100vh;
  .inline-loader-wrapper-compare {
    min-height: calc(100vh - var(--navbar-height));
    padding-top: 10vh;
  }
}
.inline-loader-wrapper-compare {
  min-width: 100%;
  text-align: center;
  @include desktop {
    min-width: 530px;
  }
}
.full-page-compare.compare-products-wrapper {
  height: auto;
  overflow: auto;
}
.temp-compare-wrapper {
  .product-image-wrapper {
    .piw-single-product:first-child {
      border-right: 1px solid $dividerColor;
      @include desktop {
        position: sticky;
        left: 0;
        z-index: 3;
      }
      background-color: white;
    }
  }
  .add-to-cart-section-wrapper {
    .add-to-cart-section:first-child {
      border-right: 1px solid $dividerColor;
      @include desktop {
        position: sticky;
        left: 0;
        z-index: 3;
      }
      background-color: white;
    }
  }
  .product-spec-container {
    .product-spec-single-product:first-child {
      background-color: var(--light-primary);
      @include desktop {
        position: sticky;
        left: 0;
        z-index: 3;
      }
    }
    &.description {
      .product-spec-single-product:first-child {
        background-color: white;
      }
    }
  }
  .product-spec-container,
  .product-image-wrapper,
  .add-to-cart-section-wrapper {
    @include desktop {
      width: max-content;
      // display: inline-flex;
    }
  }
}

.inline-loader-wrapper-compare {
  min-width: 100%;
  text-align: center;
  @include desktop {
    min-width: 530px;
  }
}
