@font-face {
  src: url("./fonts/icomoon.eot?6xm6p0");
  src:
    url("./fonts/icomoon.eot?6xm6p0#iefix") format("embedded-opentype"),
    url("./fonts/icomoon.ttf?6xm6p0") format("truetype"),
    url("./fonts/icomoon.woff?6xm6p0") format("woff"),
    url("./fonts/icomoon.svg?6xm6p0#icomoon") format("svg");

  font-family: "icomoon";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close-bold:before {
  content: "\e900";
}
.icon-plus:before {
  content: "\e901";
}
.icon-minus:before {
  content: "\e902";
}
.icon-weed:before {
  content: "\e903";
}
.icon-logo-Grassdoor:before {
  content: "\e904";
}
.icon-back:before {
  content: "\e905";
}
.icon-calendar:before {
  content: "\e906";
}
.icon-card:before {
  content: "\e907";
}
.icon-cart:before {
  content: "\e908";
}
.icon-cart-with-item:before {
  content: "\e974";
}
.icon-check-circle:before {
  content: "\e909";
}
.icon-check:before {
  content: "\e90a";
}
.icon-close:before {
  content: "\e90b";
}
.icon-delete:before {
  content: "\e90c";
}
.icon-down:before {
  content: "\e90d";
}
.icon-up:before {
  content: "\e90d";
}
.icon-hamburger:before {
  content: "\e960";
}
.icon-history:before {
  content: "\e90f";
}
.icon-info:before {
  content: "\e910";
}
.icon-location:before {
  content: "\e911";
}
.icon-pin:before {
  content: "\e912";
}
.icon-schedule:before {
  content: "\e913";
}
.icon-stopwatch:before {
  content: "\e914";
}
.icon-weed2:before {
  content: "\e915";
}
.icon-search:before {
  content: "\e916";
}
.icon-otp:before {
  content: "\e917";
}
.icon-phone:before {
  content: "\e918";
}
.icon-truck:before {
  content: "\e919";
}
.icon-settings:before {
  content: "\e91a";
}
.icon-card2:before {
  content: "\e91b";
}
.icon-referalbonus:before {
  content: "\e91c";
}
.icon-discount:before {
  content: "\e91d";
}
.icon-contact:before {
  content: "\e91e";
}
.icon-user:before {
  content: "\e91f";
}
.icon-info-italic:before {
  content: "\e920";
}
.icon-rotate-phone:before {
  content: "\e921";
}
.icon-wallet:before {
  content: "\e922";
}
.icon-prescription:before {
  content: "\e926";
}
.icon-map:before {
  content: "\e927";
}
.icon-map-center:before {
  content: "\e928";
}
.icon-login:before {
  content: "\e92b";
}
.icon-logo-Nugrunner:before {
  content: "\e92c";
}
.icon-logout:before {
  content: "\e92d";
}
.icon-privacy:before {
  content: "\e92e";
}
.icon-terms-conditions:before {
  content: "\e92f";
}
.icon-whatsapp:before {
  content: "\f232";
}
.icon-envelope-bold:before {
  content: "\e930";
}
.icon-copy-content:before {
  content: "\e931";
}
.icon-facebook:before {
  content: "\f09a";
}
.icon-twitter:before {
  content: "\f099";
}
.icon-weight:before {
  content: "\e932";
}
.icon-sms:before {
  content: "\e933";
}
.icon-coin:before {
  content: "\e935";
}
.icon-feedback:before {
  content: "\e92f";
}
.icon-contact-us:before {
  content: "\e936";
}
.icon-safety:before {
  content: "\e939";
}
.icon-pure:before {
  content: "\e938";
}
.icon-reliable:before {
  content: "\e937";
}
.icon-instagram:before {
  content: "\f16d";
}
.icon-chevron-right-f:before {
  content: "\e93a";
}
.icon-chevron-right-e:before {
  content: "\e93b";
}
.icon-weed3:before {
  content: "\e925";
}
.icon-smile:before {
  content: "\e93c";
}
.icon-training:before {
  content: "\e93d";
}
.icon-idCard:before {
  content: "\e93f";
}
.icon-home:before {
  content: "\e940";
}
.icon-click:before {
  content: "\e941";
}
.icon-discount-fill:before {
  content: "\e943";
}
.icon-driver-car:before {
  content: "\e942";
}
.icon-blog:before {
  content: "\e944";
}
.icon-full-shop:before {
  content: "\e945";
}
.icon-chat-with-us:before {
  content: "\e947";
}
.icon-video-play:before {
  content: "\e946";
}
.icon-faq:before {
  content: "\e948";
}
.icon-phone-call:before {
  content: "\f095";
}
.icon-otp2:before {
  content: "\e949";
}
.icon-selfie:before {
  content: "\e94b";
}
.icon-filter:before {
  content: "\e94c";
}
.icon-tag-discount:before {
  content: "\e97f";
}
.icon-filter2:before {
  content: "\e964";
}
.icon-search-2:before {
  content: "\e97c";
}
.icon-check-spike:before {
  content: "\e980";
}
.icon-radar:before {
  content: "\e97a";
}
.icon-mix-cart:before {
  content: "\e97d";
}
.icon-daily-limit:before {
  content: "\e97e";
}
.icon-logo-full:before {
  content: "\e94a";
}
.icon-timer:before {
  content: "\e94e";
}
.icon-calendar-clock:before {
  content: "\e94d";
}
.icon-stopwatch:before {
  content: "\e914";
}
.icon-selfie2:before {
  content: "\e950";
}
.icon-id2:before {
  content: "\e94f";
}
.icon-edit:before {
  content: "\e951";
}
.icon-radio_button_checked:before {
  content: "\e952";
}
.icon-radio_button_unchecked:before {
  content: "\e953";
}
.icon-box:before {
  content: "\e954";
}
.icon-whatsapp-solid:before {
  content: "\e955";
}
.icon-id3:before {
  content: "\e956";
}
.icon-filter-funnel:before {
  content: "\f0b0";
}
.icon-add-shopping-bag:before {
  content: "\e957";
}
.icon-cloud-moon:before {
  content: "\e959";
}
.icon-humangear:before {
  content: "\e958";
}
.icon-info-thin-2:before {
  content: "\e95e";
}
.icon-question-thin:before {
  content: "\e95d";
}
.icon-location-pin:before {
  content: "\e90e";
}
.icon-trash-alt:before {
  content: "\e963";
}
.icon-three60:before {
  content: "\e961";
}
.icon-play:before {
  content: "\f04b";
}
.icon-pause:before {
  content: "\f04c";
}
.icon-cart-with-item:before {
  content: "\e974";
}
.icon-friends-earning:before {
  content: "\e983";
}
.icon-friends-sign-up:before {
  content: "\e984";
}
.icon-friends-cash:before {
  content: "\e985";
}
.icon-benefits-tickmark:before {
  content: "\e986";
}
.icon-congrats:before {
  content: "\e988";
}

.icon-family-user:before {
  content: "\e98f";
}

.icon-angle-up:before {
  content: "\f106";
}

.icon-angle-down:before {
  content: "\f107";
}

.icon-truck1:before {
  content: "\e919";
}

.icon-link-open:before {
  content: "\e994";
}
.icon-facebook-border:before {
  content: "\e982";
}
.icon-z-check:before {
  content: "\e979";
}
.icon-z-info:before {
  content: "\e93f";
}
.icon-mag-glass:before {
  content: "\e972";
}
.icon-z-filter:before {
  content: "\e973";
}
.icon-z-blog:before {
  content: "\e967";
}
.icon-z-contact-us:before {
  content: "\e968";
}
.icon-z-credit-balance:before {
  content: "\e969";
}
.icon-z-delivery-history:before {
  content: "\e96a";
}
.icon-Drive-Icon:before {
  content: "\e96b";
}
.icon-edit-address:before {
  content: "\e96c";
}
.icon-z-express:before {
  content: "\e96d";
}
.icon-refer-friends:before {
  content: "\e96f";
}
.icon-z-schduled-delivery:before {
  content: "\e970";
}
.icon-exclaim:before {
  content: "\e966";
}
.icon-star:before {
  content: "\e965";
}
.icon-z-trash:before {
  content: "\e981";
}
.icon-z-selfie:before {
  content: "\e989";
}
.icon-z-medical-card:before {
  content: "\e98a";
}
.icon-z-id-card:before {
  content: "\e98b";
}
.icon-z-run:before {
  content: "\e98d";
}
.icon-z-mind:before {
  content: "\e98e";
}
.icon-no-products:before {
  content: "\e98c";
}
.icon-u-card:before {
  content: "\e996";
}
.icon-u-selfie:before {
  content: "\e995";
}
.icon-car-check:before {
  content: "\e990";
}
.icon-medical-circle:before {
  content: "\e991";
}
.icon-piggy-bank:before {
  content: "\e992";
}
.icon-question-mark:before {
  content: "\e997";
}
.icon-fast-time:before {
  content: "\e999";
}
.icon-offer-tag:before {
  content: "\e998";
}
.icon-zen-phone:before {
  content: "\e99a";
}
.icon-offer-outline-tag:before {
  content: "\e99b";
}
.icon-cross:before {
  content: "\e99c";
}
.icon-cart-fill-2:before {
  content: "\e9a0";
}
.icon-cart-outline-2:before {
  content: "\e9a1";
}
.icon-minus-circle:before {
  content: "\e9a2";
}
.icon-plus-circle:before {
  content: "\e9a3";
}
.icon-gift:before {
  content: "\e99d";
}
.icon-gift-outline:before {
  content: "\e99e";
}
.icon-gift-thin:before {
  content: "\e99f";
}
.icon-upload:before {
  content: "\f093";
}
.icon-img-upload:before {
  content: "\e9a4";
}
.icon-linkedin:before {
  content: "\f0e1";
}
.icon-verified-user:before {
  content: "\e9a9";
}
.icon-check-bold:before {
  content: "\e9aa";
}
.icon-reorder:before {
  content: "\e9ab";
}
.icon-star-6:before {
  content: "\e9ac";
}
.icon-chat-square:before {
  content: "\e9ad";
}
.icon-z-ambassador:before {
  content: "\e9b0";
}
.icon-z-copy-clipboard:before {
  content: "\e9b2";
}
.icon-check-circle-out:before {
  content: "\e9ae";
}
.icon-smartphone-call-bold:before {
  content: "\e9af";
}
.icon-youtube-play:before {
  content: "\f16a";
}
.icon-tiktok:before {
  content: "\e9b1";
}
.icon-spotify:before {
  content: "\e9c7";
}
.icon-z-cat:before {
  content: "\e9b4";
}
.icon-z-circular-plus:before {
  content: "\e9b5";
}
.icon-priority-delivery:before {
  content: "\e9b3";
}
.icon-eraser:before {
  content: "\e9b7";
}
.icon-my-orders:before {
  content: "\e9b8";
}
.icon-jerrycan:before {
  content: "\e9b9";
}
.icon-z-redeem-gift:before {
  content: "\e9b6";
}
// Current Change
.icon-user-profile:before {
  content: "\e9cc";
}
.icon-my-documents:before {
  content: "\e9cd";
}
.icon-address-pin:before {
  content: "\e9d2";
}
.icon-my_orders_list:before {
  content: "\e9d0";
}
.icon-payment:before {
  content: "\e9ce";
}
.icon-referral:before {
  content: "\e9cf";
}
.icon-user-profile:before {
  content: "\e9cc";
}
.icon-my-documents:before {
  content: "\e9cd";
}
// Changes from Main
.icon-star-6:before {
  content: "\e9ac";
}

.icon-moon-star-filled:before {
  content: "\e9c6";
}
.icon-compare-arrows:before {
  content: "\e9a7";
}
.icon-close-circle:before {
  content: "\e9a8";
}
.icon-sun-outline:before {
  content: "\e9c5";
}
.icon-reorder:before {
  content: "\e9ab";
}
.icon-trash-alt2:before {
  content: "\e9c8";
}
.icon-envelope:before {
  content: "\e9c9";
}
.icon-clouds-and-moon:before {
  content: "\e9c1";
}
.icon-moon-2:before {
  content: "\e9c0";
}

.icon-cloud-sun-outline:before {
  content: "\e9c2";
}
.icon-day-night-outline:before {
  content: "\e9c3";
}
.icon-sun-moon:before {
  content: "\e9c4";
}
.icon-sun-outline:before {
  content: "\e9c5";
}
.icon-plus-outline:before {
  content: "\e9bf";
}
.icon-calendar-line:before {
  content: "\e906";
}
.icon-cart-outline-2:before {
  content: "\e9a1";
}
.icon-star-6:before {
  content: "\e9ac";
}
.icon-profile:before {
  content: "\e9be";
}
// Current change
.icon-my-documents:before {
  content: "\e9cd";
}
.icon-credit:before {
  content: "\e9ce";
}
// Changes from main => No CODE
.icon-star-outline:before {
  content: "\e9bc";
}
.icon-star-filled:before {
  content: "\e9bd";
}
.icon-all_orders:before {
  content: "\e9cf";
}
.icon-wallet2:before {
  content: "\e9d0";
}
.icon-pencil:before {
  content: "\e9bb";
}
.icon-edit-pencil:before {
  content: "\e9de";
}
.icon-medical-card:before {
  content: "\e9e0";
}
.icon-trash-alt2:before {
  content: "\e9c8";
}
.icon-user-info:before {
  content: "\e9e1";
}
.icon-manage-address:before {
  content: "\e9e2";
}
.icon-reward:before {
  content: "\e9e3";
}
.icon-plus-add:before {
  content: "\e9ea";
}
.icon-resolutions:before {
  content: "\e9d1";
}
.icon-thumbs-down-color .path1:before {
  content: "\e9d8";
  color: #808080;
}
.icon-thumbs-down-color .path2:before {
  content: "\e9d9";
  color: #808080;
  margin-left: -1em;
}
.icon-thumbs-down-color .path3:before {
  content: "\e9da";
  color: #808080;
  margin-left: -1em;
}
.icon-thumbs-down-color .path4:before {
  content: "\e9db";
  color: #b3b3b3;
  margin-left: -1em;
}
.icon-thumbs-down-color .path5:before {
  content: "\e9dc";
  color: #05a0a8;
  margin-left: -1em;
}
.icon-thumbs-down-color .path6:before {
  content: "\e9dd";
  color: #37475b;
  margin-left: -1em;
}
.icon-thumbs-up1 .path1:before {
  content: "\e9d2";
  color: #808080;
}
.icon-thumbs-up1 .path2:before {
  content: "\e9d3";
  color: #808080;
  margin-left: -1em;
}
.icon-thumbs-up1 .path3:before {
  content: "\e9d4";
  color: #808080;
  margin-left: -1em;
}
.icon-thumbs-up1 .path4:before {
  content: "\e9d5";
  color: #b3b3b3;
  margin-left: -1em;
}
.icon-thumbs-up1 .path5:before {
  content: "\e9d6";
  color: #05a0a8;
  margin-left: -1em;
}
.icon-thumbs-up1 .path6:before {
  content: "\e9d7";
  color: #37475b;
  margin-left: -1em;
}
.icon-resolved:before {
  content: "\e9df";
}
.icon-resolution-centre:before {
  content: "\e9eb";
}
.icon-issues:before {
  content: "\e9f3";
}
.icon-location-arrow:before {
  content: "\f124";
}
.icon-share:before {
  content: "\e934";
}
.icon-dollar-credit:before {
  content: "\e9ce";
}
.icon-mail-forward:before {
  content: "\f064";
}
.icon-dollar-circle:before {
  content: "\e9e6";
}
.icon-share-circle:before {
  content: "\e9e7";
}
.icon-share-plane:before {
  content: "\e9e8";
}
.icon-share-arrow:before {
  content: "\e9e9";
}
.icon-exclamation-circle:before {
  content: "\e9ec";
}
.icon-exclamation--triangle:before {
  content: "\e9ed";
}
.icon-snooze:before {
  content: "\e9ee";
}
.icon-check-circle-fill:before {
  content: "\e9ef";
}
.icon-dollar-exchange:before {
  content: "\e9f0";
}
.icon-dollar-hand:before {
  content: "\e9f1";
}
.icon-figure-check:before {
  content: "\e9f2";
}
.icon-address-home:before {
  content: "\e9f8";
}
.icon-address-office:before {
  content: "\e9e5";
}
.icon-address-marker:before {
  content: "\e9f9";
}
.icon-outline-delete:before {
  content: "\e9fa";
}
.icon-default-address:before {
  content: "\e9fb";
}
.icon-compare:before {
  content: "\e9fa";
}
.icon-header-profile:before {
  content: "\e9fc";
}
.icon-timer-fancy:before {
  content: "\e9fb";
}
.icon-asap-priority:before {
  content: "\e9fd";
}
.icon-asap-standard:before {
  content: "\e9fe";
}
.icon-heart:before {
  content: "\f004";
}
.icon-heart-o:before {
  content: "\f08a";
}
.icon-share-circles:before {
  content: "\e9e7";
}
.icon-driver-car1:before {
  content: "\ea04";
}
.icon-account-documents:before {
  content: "\e9f5";
}
.icon-default-address:before {
  content: "\e9ff";
}
.icon-delete-remove:before {
  content: "\ea00";
}
.icon-expired-status:before {
  content: "\ea02";
}
.icon-will-expire-status:before {
  content: "\ea03";
}
.icon-icon-snooze:before {
  content: "\e9ee";
}
.icon-card-machine:before {
  content: "\ea06";
}
.icon-money:before {
  content: "\ea05";
}
.icon-default-payment:before {
  content: "\ea05";
}
.icon-profile-payments:before {
  content: "\ea0e";
}
.icon-profile-my-referral:before {
  content: "\ea0c";
}
.icon-profile-address:before {
  content: "\ea0d";
}
.icon-profile-my-info:before {
  content: "\ea0b";
}
.icon-profile-my-wallet:before {
  content: "\ea0a";
}
.icon-profile-my-orders:before {
  content: "\ea09";
}
.icon-Categories-sidebari:before {
  content: "\ea18";
}
.icon-blog-dark:before {
  content: "\ea19";
}
.icon-down-arrow-dark:before {
  content: "\ea1a";
}
.icon-Brand:before {
  content: "\ea1e";
}
// .icon-cartdark-icon:before {
//   content: "\ea1f";
// }
.icon-profile-account:before {
  content: "\ea10";
}
.icon-Asap-Priority:before {
  content: "\ea20";
}
.icon-Asap-Standard:before {
  content: "\ea21";
}
// .icon-Help-and-Support:before {
//   content: "\ea22";
// }
.icon-account-h:before {
  content: "\ea22";
}
.icon-help-support-s:before {
  content: "\ea24";
}
.icon-cartdark-icon:before {
  content: "\ea1f";
}
.icon-no-debitcard:before {
  content: "\ea0f";
}
.icon-profile-account:before {
  content: "\ea10";
}
.icon-link-bank:before {
  content: "\ea11";
}
.icon-order-failed:before {
  content: "\ea14";
}
.icon-accounts-my-documents:before {
  content: "\ea17";
}
.icon-my-profile-user:before {
  content: "\ea23";
}
.icon-scissor:before {
  content: "\ea08";
}
.icon-discount-2:before {
  content: "\ea12";
}
.icon-wallet-i:before {
  content: "\ea13";
}
.icon-coupon-badge:before {
  content: "\ea16";
}
.icon-filter-dark-i:before {
  content: "\ea27";
}
.icon-wallet-solid::before {
  content: "\ea25";
}
.icon-truck2:before {
  content: "\ea15";
}
.icon-account-h:before {
  content: "\ea22";
}
.icon-account-thin:before {
  content: "\ea1c";
}
.icon-conc:before {
  content: "\ea30";
}
.icon-non-conc:before {
  content: "\ea31";
}
.icon-search-pill:before {
  content: "\ea2d";
}
.icon-top-search:before {
  content: "\ea26";
}
.icon-filter-dark-i:before {
  content: "\ea27";
}
.icon-heart-filled:before {
  content: "\ea29";
}
.icon-heart-outline:before {
  content: "\ea28";
}
.icon-telephone-i:before {
  content: "\ea2a";
}
.icon-ham-menu:before {
  content: "\ea2b";
}
.icon-location-down:before {
  content: "\ea2c";
}
.icon-move-t-top:before {
  content: "\ea2e";
}
.icon-tick-plus:before {
  content: "\ea2f";
}
.icon-livechat-i:before {
  content: "\ea33";
}
