.traffic-junky-page {
  h3 {
    color: var(--primary);
    font-weight: 500;
  }
 
  .email-capture-form {
    background: #ebf7f6;
    padding: 25px;

    h5 {
      color: var(--primary);
      font-weight: 500;
    }

    .e-left-sec {
      text-align: center;
    }

    .e-right-sec {
      display: flex;
      flex-direction: column;

      .btn-junky {
        width: 130px;
        margin: auto;
        background-color: rgb(0, 79, 89) !important;
        border: 1px solid rgb(0, 79, 89) !important;
 
      }

      input {
        border-radius: 0px; 
      }

      input+input {
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }

    @include desktop {
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;

      .e-left-sec {
        padding-right: 25px;
      }

      .e-right-sec {
        flex-direction: row;

        input {
          width: 230px;
        }

        input+input {
          margin: 0 10px;
        }

        button {
          width: auto;
        }
      }

    }
  }

  .h-list-parent .scroll-btn.left {
    left: -20px;
  }

  .h-list {
    margin-bottom: 0;
  }

  @include notDesktop {
    .h2 {
      font-size: 1.75rem;
    }

    .h3 {
      font-size: 1.5rem;
    }
  }

  .a-category-name {
    font-size: 17px;
    margin-bottom: 5px;
  }
}