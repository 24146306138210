.selected-filters {
    // display: none;
    // margin: 14px -15px -14px -15px;
    .s-close-btn{
        padding: 3px 5px;
        padding-right:8px;
        font-size: 10px;
        &:focus,&:active{
            box-shadow:none;
            outline: none;
            
        }
    }
    .h-list-parent{
        margin:0;
        .h-list{
            padding: 10px;
            padding-left: 0;
            margin: 0;
            @include desktop {
                padding-left: 0;
                
            }
            &:empty{
                padding: 0;
            }
        }
        .scroll-btn{
            &:focus,&:active,&:active:focus{
                box-shadow:none;
                outline: none;
                
            }
            display: inline-block;
            transform: translateY(0%) scale(1);
            background-color:#fff;
            display: none;
           
            box-shadow: none;
            width: auto;
            top: 0;
            bottom: 0;
            height: auto;
            border-radius: 0;
            &.right{
                right: 0;
            }
            &.left{
                left:0;
            }
        }
        &:hover{
            .scroll-btn{ 
                transform: translateY(0%) scale(1);
                @include desktop{
                    display: block;
                   background-color: #fff;
                   color:var(--primary);
                }
            }
        }
    }
    .afl-container{
        display:flex;
        border-right: 2px solid #d9d9d9;
        padding-top: 2px;
        padding-bottom: 2px;
        padding-right: 10px;
        margin-right: 10px;
        &:last-child{
            border-right: none;
            padding-right: 0px;
        }
        .chip:last-child{
            margin-right: 0;
        }
        &:empty{
            // display: none;
        }
    }

  .chip {
    margin-right: 10px;
    // margin-bottom: 5px;
    background: #C9CFD5;
    border-radius: 20px;
    font-weight: 600;
    white-space: nowrap;
        padding: 2px 2px 2px 12px;
  }
//   .product_price-chips , .thc-chips{
//     display: none;
//     }

    &.sticky-filter-list{
        .h-list{
            padding-bottom: 0;
            padding-top: 10px;
        }
    }
}
.mini-tabs{
    .selected-filters{
        padding: 0 15px;
    }
}