$actionBtnHeight: 68px;

@import url("https://use.typekit.net/lor5jdj.css");

.product-page {
	padding-top: calc(var(--navbar-height) + 5px);
	font-family: "PublicSans", sans-serif;
	font-weight: 400;
	font-style: normal;

	// min-height: calc(100vh - #{$footerMobileHeight});
	.overflow-auto {
		overflow: auto;
	}

	@include desktop {
		// min-height:auto;
		padding-bottom: 100px;
	}

	@include min-height-desktop;

	.gray-box {
		background-color: #f8f8f8;
		// border: 1px solid #f0f2ff;
		padding: 15px;
		border-radius: 8px;
		margin: 20px 0;
	}

	.price-containers {
		font-size: 25px;
		font-weight: 500;

		.price {
			// color: #ff7f68;
			// // color: var(--primary);
			// &.discount {
			// 	color: #ff7f68 !important;
			// }
		}

		.strike-text {
			margin-left: 4px;
			font-size: 18px;
			align-self: center;
			// color: #777;
		}
	}

	.html-dot {
		font-size: 22px;
		padding: 0 12px;
	}

	.add-cart {
		width: 200px;
	}

	.info {
		padding-bottom: 40px;
		margin-top: 1rem;

		// background:white;
		@include desktop {
			padding: 0 15px;
			// padding-bottom: 30px;
			// box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
			// border-radius: 6px;
			margin-top: 0;
			margin-bottom: 1rem;
		}

		.star-rating {
			span {
				font-size: 21px !important;
				margin-right: 3px !important;
			}

			a {
				font-size: 16px;
			}
		}

		.default-product-description {
			font-size: 16px;
		}
	}

	.seeMore {
		overflow: hidden;
		height: 75px;
	}

	.cannabinoids {
		h6 {
			font-weight: bold;
			font-size: 16px;
		}

		font-size: 16px;

		.cannbin-box {
		}
	}

	.about-brand {
		h6 {
			font-weight: bold;
			font-size: 16px;
		}

		p {
			font-size: 16px;
		}
		img {
			max-height: 100px;
			margin-bottom: 10px;
			max-width: 250px;
		}
	}

	.sale {
		margin-left: 15px;
		font-size: 14px;
		padding: 4px 5px;
		border-radius: 3px;
		line-height: 1;
	}

	.vertical-line {
		position: relative;

		@include desktop {
			&:after {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				left: 50%;
				width: 1px;
				background: $dividerColor;
				display: none;
			}
		}
	}

	.info-inner {
		background: white;
		padding: 15px;
		// min-height: calc(100% - #{$actionBtnHeight});
		// height: calc(100% - #{$actionBtnHeight});
		//height: 100%;
		//  overflow: auto;
		// padding: 15px;
	}

	.strike-text:after {
		height: 1px;
		// background-color: #777;
		transform: translateY(-50%) rotate(0deg);
	}

	.product-name {
		font-size: 30px;
		color: #333;
	}

	.strain-name {
		display: inline-block;
		padding: 3px 8px;
		color: #fff;
		border-radius: 7px;
		font-size: 18px;
	}

	.other-strains-color {
		background-color: #873299;
	}

	.sativa-color {
		background-color: #ffd83a;
		color: #333;
	}

	.indica-color {
		background-color: #46dfff;
		color: #333;
	}

	.hybrid-color {
		background-color: #60eb73;
		color: #333;
	}

	.sativa-dominant-color {
		background-color: #3f51b5;
	}

	.indica-dominant-color {
		background-color: #9c27b0;
	}
	.cbd-thc-color {
		background-color: #e91e63;
	}
	.cbn-color {
		background-color: #00bcd4;
		color: #333;
	}
	.thca-color {
		background-color: #4caf50;
	}
	.thc-color {
		background-color: #4caf50;
	}
	.total-cannabinoids-color {
		background-color: #ffc107;
		color: #333;
	}
	.thcv-color {
		background-color: #795548;
	}
	.cbda-color {
		background-color: #607d8b;
	}
	.cbg-color {
		background-color: var(--primary);
	}
	.cbd-color {
		background-color: #e53935;
	}

	.products-actions-btn {
		height: $actionBtnHeight;
		position: fixed;
		bottom: 0;
		right: 0;
		left: 0;
		background-color: white;

		@include desktop {
			// background: $bodyBackgroundColor;
			// background: var(--body-background-color);
			position: static;
			box-shadow: none;
		}
	}

	.ratings {
		font-size: 16px;

		.icon {
			color: #ffd83a;

			.icon-star-6 {
				font-size: 20px;
			}

			.grey {
				color: #eee;
			}
		}

		.rate-right {
			.circle {
				display: inline-block;
				width: 20px;
				height: 20px;
				border-radius: 100%;
				background-color: var(--primary);
				// background-color: #05a0a8;
			}
		}
	}

	.img-container {
		// width: 200px;
		// height: 200px;
		margin-top: 30px !important;
		margin: 0rem auto;
		padding: 30px 0;

		@include desktop {
			width: 400px;
			height: 400px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}

		.sold-out {
			position: absolute;
			top: 50%;
			left: 50%;
			right: 0;
			width: 80%;
			height: auto;
			transform: translate(-50%, -50%);
		}
	}

	.img-gallery-carousal {
		text-align: center;
		overflow: auto;

		ul {
			padding: 0;
			white-space: nowrap;
			// overflow: auto;
			// display: flex;
			// justify-content: center;
			padding: 5px;
			margin-bottom: 3px;
		}

		li {
			list-style-type: none;
			display: inline-block;
			border: 1px solid #eee;
			margin: 0 5px;
			border-radius: 3px;

			img {
				width: 50px;
				height: 50px;
				object-fit: contain;
			}

			&.selected {
				box-shadow: 0px 0px 0 2px $primary;
				box-shadow: 0px 0px 0 2px var(--primary);
			}

			&:only-child {
				display: none;
			}
		}

		.video-thumbnail-holder {
			position: relative;

			vertical-align: middle;

			.dummy-container {
				background-color: #4d4d4d;
				width: 52px;
				height: 52px;
			}

			i {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				text-shadow: 0 0 3px #737373;
				color: #ffffff;
				font-size: 30px;
			}
		}
	}

	.video-container {
		video {
			width: 100%;
		}
	}

	.grid-col {
		border-radius: 4px;
		padding: 10px 0;
		border-top: 1px solid $dividerColor;
		border-bottom: 1px solid $dividerColor;
		border-radius: 0;
	}

	.strain-container {
		//color: #ffffff;
		//border-radius: 3px;
		//padding: 1px 5px;
		//font-size: 0.9em;
		//@include desktop {
		//padding: 2px 9px;
		//font-size: 14px;
		//}
	}

	.b-count {
		min-width: 10px;
	}

	.icon-close {
		font-size: 10px;
		margin-top: 5px;
	}

	// Hidden for all except kiva
	.prop-65 {
		img {
			height: 12px;
		}

		.p-text {
			font-size: 14px;
			color: #777;
		}

		// display:none!important;
	}

	.comp-feature {
		border-bottom: 1px solid #dddddd;
		padding: 15px 0;
		position: relative;

		.compare-check {
			font-size: 16px;

			.icon-info {
				position: absolute;
				top: 3px;
			}
		}

		.compare-link {
			font-size: 16px;
			color: #283cd5;
			cursor: pointer;
		}

		.icon-z-blog {
		}
	}

	.cc-block {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		background-color: #fff;
		padding: 5px 15px;
		margin-right: 15px;
		border-radius: 10px;
		border: 1px solid #efefef;
		flex: none;

		@include desktop {
			padding: 5px 15px;
			margin-right: 15px;
		}

		span {
			white-space: nowrap;
			text-align: center;
			font-size: 16px;
			font-weight: 500;
		}

		label {
			color: #555555;
			font-size: 14px;
			// word-break: keep-all;
			white-space: nowrap;
			text-align: center;
		}
	}

	.r-effects {
		display: inline-flex;
		background: #fbefef;
		border-radius: 30px;
		padding: 5px 22px;
		min-width: 80px;
		margin: 5px;
		font-size: 12px;
	}

	.flav-aroma {
		display: inline-flex;
		background: #eff1fb;
		border-radius: 30px;
		padding: 5px 22px;
		min-width: 80px;
		margin: 5px;
		font-size: 12px;
	}

	.abt-brand {
		img {
			// height: 67px;
			max-width: 50%;
			border-radius: 5px;
		}

		button {
			height: 32px;
			margin: auto 0px;
		}
	}

	.attribute-grid {
		white-space: nowrap;
		overflow-x: auto;

		.a-item {
			font-size: 22px;
			display: inline-flex;
			background: none;
			border-radius: 30px;
			padding: 0px;
			min-width: 80px;
			margin: 5px;

			.prop-title {
				font-weight: 100;
				color: #9b9b9b;
			}

			& > div {
				white-space: nowrap;
			}

			& > div:first-child {
				font-weight: 500;
				margin-right: 3px;
			}

			&:first-child {
				margin-left: 0;
			}
		}

		@include desktop {
			white-space: normal;

			.a-item {
				margin-left: 0;
			}
		}
	}

	.hybrid-spectrum-parent {
		.progress {
			flex: 1;
			height: 6px;
		}

		i {
			font-size: 32px;
		}
	}

	.shop-full-menu {
		min-height: auto;
		padding: 0;

		@include desktop {
			margin-top: 2rem;
		}

		.a-category-name {
			font-size: 17px;
			margin-bottom: 5px;
		}
		.compare-link {
			font-size: 16px;
			color: #283cd5;
			cursor: pointer;
			box-shadow: none !important;
			.inline-loader {
				position: relative;
				color: #283cd5;
				font-size: 16px;
				top: 0;
				right: 0;
				&:after {
					// border: 2px solid #283cd5;
					border: 2px solid #283cd5;
					border-bottom: 2px solid transparent;
				}
				// padding-right: 0px;
			}
		}
	}

	.add-to-cart-holder {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 999;
		padding: 10px 15px;
		background: var(--body-background-color);

		// box-shadow: 0 -0.325rem 1.25rem -12px #999 !important;
		.add-cart-inner {
			position: relative;
			background-color: var(--primary);
			border-radius: 8px;
			box-shadow: none !important;
			padding: 5px 15px;
			width: 100%;
			.wriggle-trigger {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 140px;
			}

			* {
				color: #fff;
			}

			.p-left-sec {
				height: 40px;
				display: flex;
				align-items: center;

				* {
					vertical-align: middle;
				}

				.l-icon {
					font-size: 20px;
				}
			}

			.p-right-sec {
				.count {
					font-size: 16px;
					background: #f7f7f7;
					min-width: 30px;
					height: 30px;
					line-height: 30px;
					padding: 0 5px;
					margin: 0 8px;
					text-align: center;
					color: #333;
					border-radius: 5px;
					.new-loadx {
						// display: none;
						top: 5px;
						left: -3px;
						&::after {
							height: 22px;
							width: 22px;
						}
					}
				}

				.btn {
					font-size: 20px;
					padding: 0;
					height: 35px;
					width: 35px;
					line-height: 35px;
				}

				.plus-btn {
				}

				&.is-animating {
					animation: shake-vertical 0.5s ease-in-out;
				}
			}
		}

		.p-left-sec {
			.l-icon {
				font-size: 16px;
				display: block !important;
			}

			.pp-text {
				font-size: 18px;
			}
		}

		@include desktop {
			position: static;
			box-shadow: none;

			//background:transparent;
			//box-shadow: none!important;
			padding: 0;

			.add-cart-inner {
				.wriggle-trigger {
					right: 162px;
				}

				padding: 5px 20px;
				width: 100%;

				.p-left-sec {
					.l-icon {
						font-size: 18px;
					}

					.pp-text {
						font-size: 18px;
					}
				}

				.p-right-sec {
					.btn {
						font-size: 23px;
						height: 35px;
						width: 40px;
					}
				}
			}
		}

		@include real-iphone5 {
			.l-icon {
				display: none;
			}
		}
	}

	.delivery-threshold {
		background-color: transparent;
		color: #333;
		font-size: 14px;
		margin-bottom: 0;
		padding: 15px 10px 0 10px;
	}

	.product-tile-controls {
		display: flex;
	}

	.grey-color {
		font-size: 16px;
	}

	.gd-link {
		color: #283cd5;
	}

	.units-table {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: #f7f7f7;
		padding: 6px 7px;
		grid-gap: 5px;
		gap: 5px;
		margin-bottom: 6px;
		margin-right: 5px;
		font-size: 12px;
		@include desktop {
			font-size: 14px;
		}
	}
}

@keyframes shake-horizontal {
	20% {
		transform: translate(-4px, 0) rotate(0);
	}

	40% {
		transform: translate(7px, 0) rotate(0);
	}

	60% {
		transform: translate(-4px, 0) rotate(0);
	}

	80% {
		transform: translate(-2px, 0) rotate(0);
	}

	100% {
		transform: translate(0px, 0) rotate(0);
	}
}

@keyframes shake-vertical {
	20% {
		transform: translate(0, -3px) rotate(0);
	}

	40% {
		transform: translate(0, 4px) rotate(0);
	}

	60% {
		transform: translate(0, -5px) rotate(0);
	}

	80% {
		transform: translate(0, 4px) rotate(0);
	}

	100% {
		transform: translate(0, 1px) rotate(0);
	}
}
