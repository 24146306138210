.img-grid-carousel {
  margin: 1rem -15px;
  @include desktop {
    margin: 0rem 0;
    border-bottom: 0px solid transparent;
  }

  .ig-inner {
    // height: 450px;
    padding-top: 100%;
    position: relative;
  }

  .image-magnify-container {
    img {
      max-width: none !important;
    }
  }

  .carousel-root {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    .control-arrow {
      top: 50%;
      bottom: auto;
      transform: translateY(-50%);
      height: 30px;
      line-height: 23px;
      padding: 0;
      width: 30px;
      text-align: center;
      background-color: transparent;
      opacity: 1;
      &:hover {
        background-color: transparent;
      }

      &:before {
        border: 0;
        color: #a7a7a7;
        font-family: "icomoon" !important;
        display: inline-block;
        margin: 0;
      }

      &.control-next:before {
        content: "\e93b";
      }

      &.control-prev:before {
        content: "\e93b";
        transform: scale(-1, 1);
      }
    }
  }

  .carousel-slider {
    height: 100%;
  }

  .carousel .control-dots .dot {
    box-shadow: 0px 0px 0px 1px var(--primary);
    opacity: 1;
    background: transparent;
    outline: none;

    &.selected {
      background: var(--primary);
    }
  }

  .carousel .slider-wrapper {
    padding-bottom: 2rem;
  }

  .carousel .slide {
    background: transparent;
    display: flex;
    justify-content: center;
  }

  .carousel .slide iframe {
    display: inline-block;
    width: 100%;
    margin: auto;
    border: 0;
  }

  .iv-img-container,
  .slider-wrapper,
  .slider,
  .slide {
    height: 100%;
  }

  .video-container {
    height: 100%;
    padding: 0 25px;
    background-color: #fff;
    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-shadow: 0 0 3px #737373;
      color: #ffffff;
      font-size: 30px;
    }
    @include desktop {
      background-color: black;

      padding: 0;
    }
  }

  video {
    width: 100%;
  }

  img {
    width: 100%;
    height: 100%;

    @include desktop {
      // height: 400px!important;
    }

    object-fit: contain;
  }

  .carousel .thumb img {
    height: auto;
  }

  .three-60-mobile-view {
    position: relative;
    padding: 0 20px;
    @include desktop {
      padding: 0px;
    }
    .icon-three60 {
      position: absolute;
      top: 0;
      right: 20px;
      font-size: 47px;
      z-index: 1;
      color: grey;
    }
  }

  .double-tap {
    position: absolute;
    bottom: 0;
    font-size: 13px;
    color: grey;
    left: 0;
    right: 0;
  }

  .image-video-grid {
    min-height: 450px;

    .iv-item {
      height: 250px;
      display: inline-block;
    }

    .video-container {
      height: 100%;
      height: 430px;
      width: 400px;
      margin: 0rem auto;
    }

    .c-thumbs-parent {
      // width: 100px;
      margin-top: 30px;
      height: 100%;
      overflow: auto;
      padding: 2px 5px;
      display: inline;
      white-space: nowrap;
      justify-content: center;
      text-align: center;

      .c-thumb {
        width: 80px;
        height: 80px;
        margin-right: 15px;
        border-radius: 5px;
        display: inline-block;
        overflow: hidden;
        flex-shrink: 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        margin-bottom: 12px;
        cursor: pointer;
        box-shadow: 0 0 0 1px #e8e8e8;

        &.selected {
          box-shadow: 0 0 0 2px var(--primary);
        }
      }

      .video-thumbnail-holder {
        position: relative;
        background-color: grey;

        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-shadow: 0 0 3px #737373;
          color: #ffffff;
          font-size: 30px;
        }
      }

      .three-60-thumbnail-holder {
        position: relative;
        background-color: #fff;

        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          color: #333;
          font-size: 40px;
        }
      }
    }

    .left-thumb-carousel {
      position: relative;
      padding-left: 100px;

      div.carousel:not(.carousel-slider) {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 100px;
        overflow: auto;

        .thumbs-wrapper {
          margin: 0;
        }

        button {
          display: none;
        }

        ul {
          white-space: normal;
          padding: 0;
          transition: none;
          transform: translate3d(0, 0px, 0px) !important;

          li {
            width: 80px;
            height: 80px;
          }
        }
      }
    }
  }

  .iv-item:only-child {
    @include desktop {
      max-width: 100%;
      width: 100%;
      flex-basis: 100%;
      height: 400px;
    }
  }
}

.image-popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background: white;
  padding: 0px;
  padding-top: 60px;
  height: 100%;

  &.extra-padding {
    padding: 15px;
    padding-top: 98px;
  }

  // overflow:auto;
  .inner {
    height: 100%;
  }

  .react-transform-wrapper {
    // transition: opacity 300ms;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  .z-wrapper {
    // opacity:0;
  }

  .zoom-box {
    overflow: auto;
    height: 100%;
    overscroll-behavior: contain;

    img {
      width: 200%;
      height: 200%;
      touch-action: manipulation;
    }
  }

  // .zoom-image{

  //   img{
  //     transform: scale(2.5);
  //     touch-action: manipulation;
  //   }
  // }

  .back-btn {
    position: absolute;
    /* bottom: 0; */
    top: 10px;
  }

  .close-btn {
    position: absolute;
    top: 8px;
    right: 8px;
    background: #ffffff69;
    z-index: 3;
    width: 40px;
    height: 40px;
    padding: 0;

    i {
      font-size: 17px;
      /* text-shadow: 0 0 4px white; */
      line-height: 40px;
      color: grey;
    }
  }

  .c-wrapper {
    height: 100%;
    transition: opacity 500ms;
  }

  .carousel-root {
    height: 100%;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
  }

  .carousel {
    .slide {
      background: #fff;
    }

    .iv-img-container,
    .slider-wrapper,
    .slider,
    .slide,
    .c-wrapper {
      height: 100%;
    }

    height: 100%;

    .thumbs-wrapper {
      margin: 0;

      ul {
        margin-bottom: 0;
      }

      li {
        width: 60px;
      }
    }

    .thumb.selected,
    .thumb:hover {
      border: 1px solid var(--primary);
    }

    .control-dots .dot {
      box-shadow: 0px 0px 0px 1px var(--primary);
      opacity: 1;
      background: transparent;
      outline: none;

      &.selected {
        background: var(--primary);
      }
    }
  }

  .double-tap {
    position: absolute;
    bottom: 68px;
    left: 50%;

    transform: translateX(-50%);
    padding: 10px 20px;

    background: white;

    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.27);
    border-radius: 8px;
    pointer-events: all;
  }

  &.three-60-popup {
    padding: 15px;
    padding-top: 0px;
    padding-bottom: 80px;

    .inner {
      display: flex;
      align-items: center;
    }

    .t-btn-holder {
      margin-top: 1rem;
    }
  }
}

.three-60-parent {
  padding: 20px 0 0px 0;

  @include desktop {
    padding: 0;
  }

  // position:relative;
  &.img-container {
    height: 100%;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    @include desktop {
      width: 400px;
    }
  }

  .t-btn-holder {
    position: absolute;
    right: 0px;
    bottom: 5px;

    @include desktop {
      position: static;
    }
  }

  .control-btn {
    width: 30px;
    height: 30px;
    line-height: 30px;
    padding: 0;
    font-size: 15px;

    & + .control-btn {
      margin-left: 10px;
    }
  }

  .icon-play {
    text-indent: 3px;
    display: inline-block;
  }
}

body.zoom-modal-open {
  position: fixed;
  overflow-y: hidden;
}

.my-node-enter {
  opacity: 0;
}

.my-node-enter-active {
  opacity: 1;
  transition: opacity 800ms;
}

.my-node-enter-done {
  // opacity: 1!important;
}

.my-node-exit {
  opacity: 1;
}

.my-node-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}
.mobile-zoom-image {
  min-width: 1000px;
  min-height: 1000px;
}
