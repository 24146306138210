.fixed-bottom-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 99;
  transition: 0.3s;
  &.hide-bottom-navigation {
    transition: 0.3s;
    transform: translateY(100%);
  }
  .fbb-content {
    transition: 0.3s;
    // height: auto;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(-100%);
    background-color: white;
    z-index: -1;
    width: 100%;
    border-top: 1px solid #e5e5e5;
    &.not-at-top {
      // height: 0;
      transform: translateY(64px);
    }
    .d-timing {
      align-items: center !important;
      .time-table {
        white-space: nowrap;
      }
    }
    .delivery-message-wrapper {
      padding-right: 30px;
      position: relative;
      .icon-close {
        position: absolute;
        z-index: 1;
        background-color: rgb(223, 223, 223);
        right: 28px;
        top: 50%;
        transform: translate(50%, -50%);
        font-size: 8px;
        padding: 5px;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .bottom-tab {
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.06);
    padding: 14px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    min-height: 64px;
    padding-left: 22px;
    @include desktop {
      padding-right: 12px;
      padding-left: 12px;
    }
  }
  .freight-message-banner {
    // position: absolute;
    // left: 0;
    // top: 0;
    // transform: translateY(-100%);
    padding: 8px 2px 8px 13px;
    font-weight: 400;
    line-height: 17px;
    display: flex;
    align-items: center;
    .btn {
      font-size: 10px;
      font-weight: 600;
    }
  }
  box-shadow: 0 0 10px #aaa;

  .asapcounter {
    display: flex !important;
  }
  // .delivery-type-toggle {
  //   display: inline-block;
  //   vertical-align: sub;
  //   transition: opacity 300ms, transform 300ms;
  //   background: #EBEBEB;
  //   border-radius: 39px;
  //   .btn {
  //     width: auto;
  //     min-width: 88px;
  //     height: 32px;
  //     padding: 5px;
  //     line-height: 1;
  //     font-weight: 500;
  //     font-size: 14px;
  //     padding: 5px 10px;
  //     &.d-selected{
  //       color: var(--text-on-primary-color);
  //       background-color: var(--primary);
  //       border: 1px solid var(--primary);
  //       border-radius: 39px !important;
  //     }
  //     &:not(.not-selected) {
  //       display: inline-block;
  //     }
  //     // &:not(.not-selected) {
  //     //   display: none;
  //     // }
  //     &:first-child {
  //       border-radius: 39px 0 0 39px;
  //     }
  //     &:last-child {
  //       border-radius: 0px 39px 39px 0px;
  //     }
  //     &:focus {
  //       box-shadow: none;
  //     }
  //   }

  //   margin: auto 0px;
  // }

  .asap-on {
    padding: 7px 0;
    display: flex;
    flex-direction: row;
    margin-left: 15px;
    // justify-content: space-around;
    i {
      display: block;
      font-size: 16px;
      color: #616161;
      margin-right: 5px;
    }
    .standard,
    .priority,
    .next-slot {
      color: #999;
      font-weight: bold;
      font-size: 12px;
      min-width: 50%;
      font-weight: 600;
      line-height: 15px;
      display: flex;
      align-items: center;
      margin-right: 10px !important;
      .standard-time,
      .priority-time {
        color: #616161;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        margin-left: 4px;
      }
    }
  }
  .schedule-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // padding: 6px 10px;
    padding: 7px 13px;
  }

  .filter-btn.fab {
    background-color: transparent;
    box-shadow: none;
    bottom: 12px;
    right: 0;
    top: 0;
    position: relative;
    height: auto;
    width: auto;
    padding: 0px 3px 0px 0px;
    // font-size: 18px;
    .icon {
      color: #37475b;
      line-height: unset;
      font-size: 21px;
      font-weight: bolder;
      position: relative;
    }
    .expand-btn-text {
      display: none;
    }
    .filter-indicator {
      width: 8px;
      height: 8px;
      position: absolute;
      right: -4px;
      top: -2px;
    }
  }
  &.not-at-top {
    // .delivery-timing,
    // .freight-message-banner {
    //   display: none;
    // }
  }
}
