$desktop-width: 992px;
$tab-width: 768px;
$x-desktop-width: 1200px;
$iphone5-real-width: 320px;
@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}
@mixin tab {
  @media (min-width: #{$tab-width}) {
    @content;
  }
}

@mixin notDesktop {
  @media (max-width: #{$desktop-width - 0.02}) {
    @content;
  }
}

@mixin notTab {
  @media (max-width: #{$tab-width - 0.02}) {
    @content;
  }
}
@mixin xDesktop {
  @media (min-width: #{$x-desktop-width}) {
    @content;
  }
}
@mixin ipadPro {
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    @content;
  }
}

@mixin ipadProLandscape {
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) and (hover: none) {
    @content;
  }
}
@mixin ipad {
  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) and (hover: none) {
    @content;
  }
}
@mixin ipadLandscape {
  @media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) and (hover: none) {
    @content;
  }
}
@mixin real-iphone5 {
  @media (max-width:#{$iphone5-real-width}) {
    @content;
  }
}

body.version-2 {
  .gd-search-address {
    .d-icon {
      font-size: 16px;
      color: #000;
      margin-top: -8px;
      transform: scale(1.2);

      @include desktop() {
        margin-top: 18px;
      }
    }
  }
  .login-popup .country-code,
  .login .country-code {
    left: 3px;
    text-align: center;
  }
  .new-header {
    .left-btn {
      // top: 9px !important;
      position: static;
      width: auto;
      height: auto;
      // transform: rotate(180deg);
      @include desktop {
        top: 50% !important;
        position: absolute;
        width: 35px;
        height: 50px;
        transform: translateY(-50%);
      }
      .icon {
        transform: rotate(180deg);
        font-size: 24px;
      }
    }
    .icon-hamburger {
      color: #37475b;
      font-size: 20px;
      @include desktop {
        font-size: 24px;
      }
    }
    .item-add-count span {
      color: #000 !important;
    }
    .right-btn-container {
      .timer-sec {
        margin-right: 0 !important;

        @media (min-width: 991px) and (max-width: 1200px) {
          display: none !important;
        }
      }
      .icon-down {
        color: #000;
      }
    }
    .main-logo {
      padding-left: 15px !important;
      @include desktop {
        padding-left: 40px !important;
      }

      .d-img {
        display: none;
        @include desktop {
          display: block;
        }
        @include ipadPro {
          display: none;
        }
        @include ipadLandscape {
          display: none;
        }
      }
      .m-img {
        display: block;
        @include desktop {
          display: none;
        }
        @include ipadPro {
          display: block;
        }
        @include ipadLandscape {
          display: block;
        }
      }
    }
    .gd-logo {
      .d-img {
        display: none;
        @include desktop {
          display: block;
        }
        @include ipadPro {
          display: none;
        }
        @include ipadLandscape {
          display: none;
        }
      }
      .m-img {
        display: block;
        @include desktop {
          display: none;
        }
        @include ipadPro {
          display: block;
        }
        @include ipadLandscape {
          display: block;
        }
      }
    }
  }
  .lottie-live-chat {
    svg {
      height: 25px !important;
      width: 32px !important;
    }
  }
  .slider-wrap {
    .banner-content {
      h1,
      h2,
      div {
        display: none;
      }
      // display: none;
    }
  }
  .right-btn-container {
    position: unset !important;
    gap: 25px;
    @include desktop {
      gap: 32px;
    }
    .cart-btn-info,
    .user-info-btn {
      .small-text {
        margin-left: 0px !important;
        @include desktop {
          margin-left: 0.5rem !important;
        }
      }
      .icon {
        margin-left: 0px !important;
        font-size: 24px !important;
      }
    }
  }
  .featured-tiles-wrapper {
    margin: 1rem 0 4.5em;
    @include desktop {
      margin: 2.5rem 0 0;
    }
    .carousel-slider {
      overflow: visible;
      .slide {
        background: none;
        text-align: left;
      }
      .control-dots {
        // margin: 8px 0px 0px 0px;
        position: absolute;
        bottom: -32px;
        margin: 0;
        .dot {
          margin: 0px 8px 0px 0px;
          width: 8px;
          height: 8px;
          opacity: 0.75;
          display: inline-block;
          background: #ebebeb;
          border-radius: 19px;
          cursor: pointer;
          transition:
            opacity 0.3s,
            background-color 0.3s,
            width 0.3s;
          transition-delay: 0.3s, 0.3s, 0s;
          box-shadow: none;
          &.selected {
            background: var(--primary);
            width: 24px;
            opacity: 1;
            transition-delay: 0s;
          }
        }
      }
    }
    .product-counter-parent.version-2 .count {
      color: #333;
    }
    .h-list-parent .h-list {
      padding-bottom: 0 !important;
    }
    &.one-product-available {
      margin-bottom: 2rem !important;
      .control-dots {
        display: none;
      }
    }
  }
  .search-tabs {
    .search-action-btns {
      gap: 25px;
    }
  }
  .special-container {
    margin-bottom: 3rem;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: red;
    }
    &::-webkit-scrollbar {
      width: 12px;
      background-color: yellow;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #555;
    }
    position: relative;
    display: flex;
    justify-content: space-between;
    border-radius: 16px;
    // background: #e3e2f3;
    flex-direction: column;
    @include desktop() {
      // flex-direction: row;
    }
    .deals-caption-holder {
      margin: 20px 12px 0;
      .deals-caption-new {
        display: flex;
        justify-content: space-between;
        h5 {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
        }

        a {
          display: inline-block;
          width: 70px;
          text-align: right;
        }
      }

      p {
        margin-bottom: 0;
        margin-top: 5px;
        color: #616161;
        font-size: 16px;
      }

      @include desktop() {
        margin: 24px 31px 0;
      }
    }

    .deals-caption {
      position: relative;
      padding: 9px;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;
      min-width: 229px;
      flex: none;
      box-shadow: 9.44px 0px 44.8731px rgba(0, 0, 0, 0.2);
      border-radius: 17.9492px;
      @include desktop() {
        box-shadow: 20px 0px 40px rgba(0, 0, 0, 0.2);
        border-radius: 16px;
        padding: 10px;
      }
      text-transform: uppercase;
      color: #fff;
      // &::after {
      //   content: "";
      //   // background-image: url(../../images/R.svg);
      //   position: absolute;
      //   background-repeat: no-repeat;
      //   bottom: -18px;
      //   width: 70px;
      //   height: 20px;
      //   left: calc(50% - 30px);
      //   @include desktop() {
      //     width: 100%;
      //     // background-image: url(../../images/Rd.svg);
      //     bottom: calc(50% - 25px);
      //     width: 100%;
      //     height: 50px;
      //     left: 100%;
      //   }
      // }
      .deals-title {
        font-size: 24px;
        @include desktop() {
          font-size: 30px;
        }
        line-height: 1;
      }
      // span {
      //   font-size: 24px;
      //   @include desktop() {
      //     font-size: 54.2002px;
      //     display: block;
      //   }
      //   font-weight: 800;
      // }
      a {
        color: #fff;
        margin-top: 6px;
        font-size: 13.4619px;
        line-height: 16px;
        font-weight: 400;
        text-decoration: underline;
        text-transform: capitalize;
        @include desktop() {
          margin-top: 20px;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
    .product-list {
      display: flex;
      overflow-x: auto;
      padding: 13px 0 22px 12px;
      width: 100%;
      @include desktop() {
        padding: 15px 0 24px 31px;
      }
      .product,
      .latest-product {
        background-color: #fff;
      }
    }
    .product-space-between-container {
      border: none;
    }

    // .category-banner-extra {
    //   width: 100%;
    //   margin-top: 10px;
    //   margin-right: 35px;
    //   background: rgba(247, 249, 253, 0.5);
    //   padding: 32px;
    //   display: none;
    //   border-radius: 8px;
    //   @include desktop {
    //     display: block;
    //     position: relative;
    //   }
    //   // @include ipadPro {
    //   //   padding: 32px;
    //   // }
    //   h4 {
    //     font-size: 25.21px;
    //     line-height: 31px;
    //     font-weight: 700;
    //   }
    //   .btn {
    //     // border: 1px solid var(--primary);
    //     margin-top: 1rem;
    //     // color: var(--primary);
    //     font-weight: 600;
    //   }
    //   // check for dtc
    //   &::after {
    //     @include desktop {
    //       content: "";
    //       background-image: url(../../images/see-more-image.svg);
    //       background-repeat: no-repeat;
    //       position: absolute;
    //       bottom: 14px;
    //       right: 0;
    //       z-index: 1;
    //       /* max-height: 182px; */
    //       /* max-width: 182px; */
    //       left: 0;
    //       top: 0;
    //       background-position: right bottom;
    //       opacity: 0.1;
    //       z-index: 99;
    //       background-size: 140px;
    //     }
    //     @include ipadPro {
    //       background-size: 105px;
    //     }
    //     @include ipadLandscape() {
    //       background-size: 105px;
    //     }
    //   }
    // }
  }
  .special-container[class*="bring-it-again"] {
    // background: #e3e2f3;
    .deals-caption {
      background: linear-gradient(0deg, #6da738 -4.89%, #e3e2f3 77.44%);
      @include desktop() {
        background: linear-gradient(0deg, #6da738 -4.89%, #e3e2f3 77.44%);
      }
    }
  }
  .special-container[class*="deal-of-the-day"] {
    // background: #f0e4e0;
    .deals-caption {
      background: linear-gradient(0deg, #d45932 -4.89%, #f0e4e0 77.44%);
      @include desktop() {
        background: linear-gradient(0deg, #d45932 -4.89%, #f0e4e0 77.44%);
      }
    }
  }
  .special-container[class*="recommended"] {
    // background: #f4f4f4;
    .deals-caption {
      background: linear-gradient(0deg, #3f8b95 -4.89%, #f4f4f4 77.44%);
      @include desktop() {
        background: linear-gradient(0deg, #3f8b95 -4.89%, #f4f4f4 77.44%);
      }
    }
  }
  .filter-product-page {
    @include desktop {
      margin-left: -15px !important;
      margin-right: -15px !important;
    }
    // .product-category {
    //   margin-top: 40px !important;
    //   @include desktop {
    //     margin-top: 45px !important;
    //   }
    // }
    .special-container {
      margin-bottom: 3rem;
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: red;
      }
      &::-webkit-scrollbar {
        width: 12px;
        background-color: yellow;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #555;
      }
      position: relative;
      display: flex;
      justify-content: space-between;
      border-radius: 16px;
      // background: #e3e2f3;
      flex-direction: column;
      @include desktop() {
        // flex-direction: row;
      }
      .product-list {
        display: flex;
        overflow-x: auto;
        padding: 13px 0 22px 12px;
        width: 100%;
        @include desktop() {
          padding: 15px 0 24px 31px;
        }
        .product,
        .latest-product {
          background-color: #fff;
          border: 1px solid #0000001f;
        }
      }
      .product-space-between-container {
        border: none;
      }

      .category-banner-extra {
        width: 100%;
        margin-top: 10px;
        margin-right: 35px;
        background: rgba(247, 249, 253, 0.5);
        padding: 32px;
        display: none;
        border-radius: 8px;
        @include desktop {
          display: block;
          position: relative;
        }
        // @include ipadPro {
        //   padding: 32px;
        // }
        h4 {
          font-size: 25.21px;
          line-height: 31px;
          font-weight: 700;
        }
        .btn {
          // border: 1px solid var(--primary);
          margin-top: 1rem;
          // color: var(--primary);
          font-weight: 600;
          z-index: 1;
          position: relative;
        }
        // check for dtc
        &::after {
          @include desktop {
            content: "";
            background-image: url(../../images/see-more-image.svg);
            background-repeat: no-repeat;
            position: absolute;
            bottom: 14px;
            right: 5px;
            // z-index: 1;
            /* max-height: 182px; */
            /* max-width: 182px; */
            // left: 0;
            // top: 0;
            // background-position: right bottom;
            opacity: 0.1;
            // z-index: 99;
            background-size: 145px;
            height: 145px;
            width: 145px;
          }
          // @include ipadPro {
          //   background-size: 105px;
          //   height: 105px;
          //   width: 105px;
          // }
          // @include ipadLandscape() {
          //   background-size: 105px;
          //   height: 105px;
          //   width: 105px;
          // }
        }
      }
    }
    .special-container[class*="bring-it-again"] {
      // background: #e3e2f3;
      .deals-caption {
        background: linear-gradient(0deg, #6da738 -4.89%, #e3e2f3 77.44%);
        @include desktop() {
          background: linear-gradient(0deg, #6da738 -4.89%, #e3e2f3 77.44%);
        }
        .deals-title {
          font-size: 24px;
          background-color: red;
          @include desktop() {
            font-size: 43px !important;
          }
        }
      }
    }
    .special-container[class*="deal-of-the-day"] {
      // background: #f0e4e0;
      .deals-caption {
        background: linear-gradient(0deg, #d45932 -4.89%, #f0e4e0 77.44%);
        @include desktop() {
          background: linear-gradient(0deg, #d45932 -4.89%, #f0e4e0 77.44%);
        }
        &::after {
          background-image: url(../../images/deal-caret-m.svg);
          @include desktop() {
            background-image: url(../../images/deal-caret-l.svg);
          }
        }
      }
    }
    .special-container[class*="recommended"] {
      // background: #f4f4f4;
      .deals-caption {
        background: linear-gradient(0deg, #3f8b95 -4.89%, #f4f4f4 77.44%);
        @include desktop() {
          background: linear-gradient(0deg, #3f8b95 -4.89%, #f4f4f4 77.44%);
        }
        &::after {
          background-image: url(../../images/green-arrow-m.svg);
          @include desktop() {
            background-image: url(../../images/green-arrow-l.svg);
          }
        }
      }
      .deals-title {
        @include desktop {
          font-size: 26px;
          // line-height: 28px;
        }
        span {
          @include desktop {
            font-size: 45.5px;
            // line-height: 27px;
          }
        }
      }
    }
  }
  .default-category-section {
    margin-top: 12px;
    padding: 0;
    margin: 0px 0 !important;
    margin-top: 12px !important;
    @include desktop {
      // margin-top: 8px !important;
      margin: 8px auto !important;
      padding-top: 20px;
    }
    @media (max-width: 992px) and (min-width: 767px) {
      margin-top: 30px !important;
    }
    .gc-inner {
      .category-items {
        .h-list {
          padding: 0 0 10px 0 !important;
          @include desktop {
            padding-bottom: 4px !important;
          }
          @include ipadPro {
          }
        }
        .category-block {
          flex: 0 0 110px;
          position: relative;
          margin-right: 10px;
          @include desktop {
            flex: 0 0 140px;
            margin-right: 10px;
          }
          a {
            .category-icon-wrap {
              margin: 0 auto;
              height: 40px;
              @include desktop {
                height: 40px;
              }
              img,
              picture {
                height: 100%;
              }
            }
            .category-name {
              padding-bottom: 6px;
              padding-top: 5px;
              @include desktop {
              }
              h4 {
                line-height: 18px;
                text-align: center;
                color: #333333;
                @include desktop {
                  font-size: 16px;
                  line-height: 24px;
                }
              }
            }
          }
          &.c-selected::after {
            content: "";
            position: absolute;
            bottom: 2;
            left: 0;
            right: 0;
            background: var(--primary);
            height: 2px;
            border-radius: 12px;
            @include desktop {
              // height: 5.24px;
              // border-radius: 18.3514px;
            }
          }
        }
        .category-block:last-child {
          margin-right: auto;
        }
        .category-block:first-child {
          margin-left: auto;
        }
      }
    }
  }
  .product-category,
  .category-product,
  .faq-wrapper {
    .a-category-name {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
    }
    .see-more-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #616161;
      margin-top: 10px;
      @include desktop {
        font-size: 16px;
        line-height: 19px;
      }
      &.restrict-two span {
        bottom: 1.5px;
        @include desktop {
          bottom: -2px;
        }
        u {
          cursor: pointer;
        }
      }
    }
  }
  // .filter-btn{
  //   display:none !important;
  // }

  .gift-card-hp {
    margin: 1rem 0 1rem 0;
    background: rgba(40, 151, 116, 0.12);
    display: flex;
    padding: 25px 15px !important;
    border-radius: 16px;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    @include desktop {
      padding: 15px 20px !important;
      flex-direction: row;
      gap: 96px;
      text-align: left;
    }
    .earn-text {
      font-size: 24px;
    }
    .btn:focus {
      filter: brightness(0.8);
    }
    .card-details {
      padding: 10px 0 0 0 !important;
      @include desktop {
        padding-bottom: 3rem !important;
        padding-top: 1.5rem !important;
        z-index: 3;
      }
      h2 {
        font-size: 24px;
        font-weight: 800;
        line-height: 29px;
        // padding-top: 1.5rem !important;
        @include desktop {
          font-size: 32px;
          font-weight: 800;
          line-height: 39px;
          padding-top: 1.5rem !important;
        }
      }
      .earn-text .credit {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        @include desktop {
          font-size: 24px;
          font-weight: 400;
          line-height: 29px;
        }
      }
      .btn {
        min-width: 200px;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        min-height: 54px;
        margin-top: 26px !important;
        @include desktop {
          margin-top: 32px !important;
        }
      }
      p {
        min-width: 200px;
        line-height: 15px;
        @include desktop {
          width: fit-content;
        }
      }
    }
    &::after {
      //not able to click
      @include desktop {
        content: "";
        background-image: url(../../images/giftbg-icon.png);
        background-repeat: no-repeat;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;
        left: 0;
        top: 0;
        background-position: right bottom;
        z-index: 2;
        background-size: 10%;
      }
    }
  }
  .login-drawer {
    .top-sec {
      i {
        display: block;
        font-size: 26px;
      }
    }
  }
  .search-product {
    .modal-header {
      .btn {
        padding: 0px;
      }
    }
    .search-box-parent {
      min-height: unset;
      .ais-SearchBox {
        border: unset;
        margin-bottom: 5px;
        margin-top: 10px;
        .ais-SearchBox-form {
          input {
            background: #ffffff;
            border: 1px solid #cdcdcd;
            box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.1);
            border-radius: 27px;
            padding: 0 30px !important;
            // color: #999999;
            min-height: 40px;
            // font-size: 14px;
            // font-weight: 400;
          }
          ::-webkit-input-placeholder {
            /* Edge */
            font-size: 14px;
            color: #999999;
            font-weight: 400;
          }

          :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            font-size: 14px;
            color: #999999;
            font-weight: 400;
          }

          ::placeholder {
            font-size: 14px;
            color: #999999;
            font-weight: 400;
          }
        }
      }
    }
    .category-list-holder {
    }
    .default-category-section {
      margin-top: 10px;
      padding: 0 6px;
      .h-list {
        flex-wrap: wrap;
        .category-block {
          margin-bottom: 10px;
          border-radius: 4px;
          border: 1px solid #dadada;

          &::after {
            display: none;
          }
          &:first-child {
            margin-left: 0px !important;
          }
          &:last-child {
            margin-right: 0px !important;
          }
        }
      }
    }
  }
  .bottom-right {
    display: flex;
    align-items: center;
    gap: 24px;
    @include real-iphone5 {
      gap: 20px;
    }
    .filter-btn.fab .icon {
      font-size: 24px;
      font-weight: normal;
      transform: rotate(0deg);
    }
  }
  .brands {
    .searchBox {
      // padding-top: 7px !important;
      // padding-bottom: 12px !important;
    }
    .brand-wrap {
      .desc-pd {
        padding: 0 !important;
      }
    }
    // .all-category{
    //   display: none;
    //   @include desktop {
    //     display: block;
    //   }
    // }
    .default-category-section {
      padding: 0px;
      @include desktop {
        padding: 0 0 10px 0 !important;
        padding-top: 20px !important;
      }
      // .category-block {
      //   flex: 0 0 120px;
      //   .category-icon-wrap{
      //     height: 64px;
      //   }
      //   .category-name h4{
      //     font-size: 16px;
      //     line-height: 24px;
      //   }
      // }
    }
    .gift-card {
      margin-bottom: 3rem;
    }
    .brands-desc-wrapper {
      margin-top: 18px;
      background-color: var(--body-background-color);
      @include desktop {
        margin-top: 0px;
      }
      .short-description {
        margin-top: 0px !important;
      }
      .dtc-container {
        padding-top: 0px !important;
      }
      .banner {
        overflow: unset;
        // margin: 1.5rem 0 2rem !important;
        // @include desktop {
        //   padding-left: 0px ;
        //   padding-right: 0px ;
        // }
        .banner-inner {
          padding-bottom: 0px !important;
        }
      }
      .banner-control-2 {
        @include desktop {
          margin-bottom: 0.5rem !important;
        }
      }
      // .banner-control-1 {
      //   margin-bottom: 0.5rem !important;
      //   @include desktop {
      //     margin-bottom: 0.5rem !important;
      //   }
      // }
    }
    .default-category-section {
      margin-bottom: 1rem !important;
    }
  }
  //pdp page ui
  .product-page {
    .outer-layer-pdp {
      margin-top: 1.5rem !important;
      @include desktop {
        margin-top: 1rem !important;
      }
    }
    .search-category-wrapper {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
  .product-page-popup {
    .pdp-top-info {
      padding-top: 0px;
      // padding-right: 30px;
    }
    .pdp-bottom-info {
      // padding-right: 30px;
    }
    .product-close-btn {
      // padding: 8px 12px;
      padding: 8px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.8);
      display: flex;
      @include desktop {
      }
    }
    .product-page .outer-layer-pdp {
      margin-top: 0 !important;
      padding-top: 0 !important;
    }
    .s-product-container {
      margin-top: -2rem !important;
    }
  }
  .product-page-popup,
  .product-page {
    a,
    .compare-link u,
    .default-brand-description label,
    .see-more-content label {
      color: var(--primary) !important;
    }
    .compare-link .icon {
      color: var(--primary) !important;
    }
    .delivery-tabs {
      border-radius: 39px;
      background-color: #ebebeb;
      // width: fit-content;
      margin: 18px auto 30px;
      @include desktop {
        width: fit-content;
        margin: 50px auto 30px;
      }
      .m-item {
        // border-radius: 39px;
        border-radius: 39px !important;
        background-color: #ebebeb;
        text-align: center;
        @include desktop {
          // min-width: 280px;
          width: auto;
        }
        &.t-selected {
          border-radius: 39px !important;
          background-color: var(--secondary) !important;
        }
      }
    }
    .similar-products {
      .s-product-container {
        margin-top: 0rem !important;
        padding-bottom: 2.5rem;
        @include desktop {
          margin-top: 0px !important;
          padding-bottom: 1rem;
        }
      }
    }

    .p-right-sec .info {
      padding-top: 0px !important;
      margin-top: 0px !important;
      @include desktop {
        padding-bottom: 40px !important;
        margin-top: 0rem !important;
        padding-top: 8px !important;
      }
    }
    .img-grid-carousel {
      .carousel-slider {
        .slider-wrapper {
          padding-bottom: 3.5rem;
        }
        .control-arrow {
          display: none;
        }
      }
      .fresh-drop {
        margin-left: 15px;
        margin-top: 40px;
        @include desktop {
          margin-left: -25px;
          margin-top: 50px;
        }
      }
      .ig-inner {
        height: 260px;
        padding-top: 0px;
        @include ipad {
          padding-top: 100%;
          height: auto;
        }
      }
      // .iv-img-container{
      //   width: 200px;
      //   height: 200px;
      // }
      .product-tag-label {
        z-index: 99;
        // position: absolute !important;
        top: 0;
        left: 0;
      }
      .img-container {
        width: auto;
        height: auto;
        .product-tag-label {
          // position: relative !important;
        }
      }
      .control-dots {
        position: absolute;
        bottom: 10px;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        @include desktop {
          bottom: -12px;
        }
        .dot {
          margin: 0px 8px 0px 0px;
          width: 8px;
          height: 8px;
          opacity: 0.75;
          display: inline-block;
          background: #ebebeb;
          border-radius: 19px;
          cursor: pointer;
          transition:
            opacity 0.3s,
            background-color 0.3s,
            width 0.3s;
          transition-delay: 0.3s, 0.3s, 0s;
          box-shadow: none;
          border: none;
          padding: 0;
          &.selected {
            background: var(--primary);
            width: 24px;
            opacity: 1;
            transition-delay: 0s;
          }
        }
      }
      .double-tap {
        margin-top: 8px;
        color: #000;
        font-size: 12px;
        font-weight: 400;
        bottom: auto !important;
      }
    }
    // .slider-pdp {
    //   .carousel-slider {
    //     overflow: visible;
    //     .slider-wrapper {
    //       padding-bottom: 0px;
    //     }
    //     .control-dots {
    //       // margin: 8px 0px 0px 0px;
    //       position: absolute;
    //       bottom: -5px;
    //       margin: 0;
    //       .dot {
    //         margin: 0px 8px 0px 0px;
    //         width: 8px;
    //         height: 8px;
    //         opacity: 0.75;
    //         display: inline-block;
    //         background: #ebebeb;
    //         border-radius: 19px;
    //         cursor: pointer;
    //         transition: opacity 0.3s, background-color 0.3s, width 0.3s;
    //         transition-delay: 0.3s, 0.3s, 0s;
    //         box-shadow: none;
    //         &.selected {
    //           background: var(--primary);
    //           width: 24px;
    //           opacity: 1;
    //           transition-delay: 0s;
    //         }
    //       }
    //     }
    //   }
    //   // .slider-wrapper {
    //   //   overflow: visible;
    //   //   .slider{
    //   //     .slide{
    //   //       visibility: hidden;
    //   //       &.selected{
    //   //         visibility: visible !important;
    //   //       }
    //   //     }
    //   //   }
    //   // }
    // }
    .price-chemical-wrap {
      display: flex;
      // align-items: center;
      flex-direction: column;
      .price-wrap {
        order: 2;
        margin: 19px 0px 5px;
        @include desktop {
          order: unset;
          margin: 19px 0px 0px;
        }
        .price-containers {
          display: flex;
          .price {
            font-size: 32px;
            font-weight: 700;
            line-height: 39px;
            // color: #37475b !important;
            letter-spacing: 0.04em;
            margin-right: 8px;
          }
          .strike-text {
            font-size: 26px;
            font-weight: 400;
            // color: #616161;
            line-height: 31.67px;
          }
        }
        .sale {
          font-size: 26px;
          font-weight: 400;
          line-height: 32px;
          color: #399e33 !important;
          background: #ffffff;
          margin-left: 8px;
          span {
            font-weight: 400 !important;
            .minus-sale {
              display: inline-block;
            }
            .off-sale {
              display: none;
            }
          }
        }
      }
      .chemical-content-wrap {
        @include desktop {
          margin-top: 20px;
        }
        .chemical-heading {
          font-size: 12px;
          font-weight: 700;
          line-height: 15px;
          color: #616161;
          margin-bottom: 4px;
          @include desktop {
            margin-bottom: 6px;
          }
        }
        .units-table {
          border-radius: 24px;
          background-color: #f7f7f7;
          padding: 4px 12px;
          div {
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
            color: #999999;
            @include desktop {
              font-size: 14px;
              line-height: 17px;
            }
          }
          .cont-value {
            font-weight: 600 !important;
            color: #616161;
          }
        }
      }
    }
    .add-to-cart-holder {
      padding: 15px;
      margin-left: 1px;
      margin-right: 1px;
      @include desktop {
        padding: 0;
        margin-left: 0px;
        margin-right: 0px;
      }
      .delivery-threshold {
        padding: 8px 10px 0 10px;
        @include desktop {
          padding: 15px 10px 0 10px;
        }
      }
      .add-cart-inner {
        border-radius: 12px 12px 0px 0px;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;

        @include desktop {
          min-height: 60px;
          border-radius: 8px;
          font-size: 20px;
          line-height: 24px;
          border-radius: 12px;
        }
        .p-left-sec {
          .l-icon {
            position: relative;
            top: -1px;
          }
          .pp-text {
            @include desktop {
              font-size: 20px;
              font-weight: 700;
              line-height: 24px;
            }
          }
        }
      }
      .delivery-threshold {
      }
    }
    .compare-check {
      margin-bottom: 0px;
    }
    .pdp-top-info {
      display: block;
      padding-right: 40px;
      @include desktop {
        display: none;
      }
      .product-name {
        font-size: 20px;
        font-weight: 700;
        line-height: 22px;
        color: #333;
        margin-bottom: 8px;
        span {
          font-size: 20px;
          font-weight: 500;
          line-height: 22px;
          color: #37475b;
        }
      }
      .link-wrap {
        .gd-link {
          font-size: 14px;
          font-weight: 500;
          line-height: 17px;
          color: var(--primary);
          span {
            text-transform: uppercase;
          }
        }
      }
      .strain-name {
        border-radius: 4px;
        font-size: 12px;
        font-weight: 600;
        line-height: 12px;
        display: inline-flex;
        align-items: center;
        gap: 4px;
        padding: 6px;
        margin: 8px 4px 4px 0px;
      }
    }
    .pdp-bottom-info {
      display: none;
      .product-name {
        font-size: 30px;
        font-weight: 700;
        line-height: 37px;
        color: #333;
        span {
          font-size: 30px;
          font-weight: 400;
          line-height: 37px;
          color: #333;
        }
      }
      .link-wrap {
        margin-top: 6px;
        margin-bottom: 0px;
      }
      .gd-link {
        color: var(--primary);
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        span {
          text-transform: uppercase;
        }
      }
      .strain-name {
        border-radius: 4px;
        font-size: 12px;
        font-weight: 600;
        line-height: 12px;
        display: inline-flex;
        align-items: center;
        gap: 4px;
        padding: 6px;
        margin: 14px 4px 4px 0px;
      }
      @include desktop {
        display: block;
      }
    }
    .all-products-slider {
      .h-list-parent .h-list {
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
    }
  }
  .banner-control-2 {
    @include desktop {
      margin-bottom: 2.5rem !important;
    }
  }
  .banner-control-1 {
    margin-bottom: 1rem !important;
    @include desktop {
      margin-bottom: 2.5rem !important;
    }
  }
  .product-tag-label {
    height: 28px;
    position: relative;
    // border-radius: 4px;
    font-weight: 600;
    line-height: 13px;
    padding: 0 12px 0 12px;
    color: #ffffff;
    width: fit-content;

    // border-right: solid 14px transparent;
    // border-bottom: solid 14px #d43232;
    // border-top: solid 14px #d43232;

    background-color: #d43232;
    padding-right: 20px !important;
    @include desktop {
      padding-right: 25px !important;
    }
    &::after {
      content: "";
      width: 20px;
      height: 20px;
      background-color: #fff;
      overflow: hidden;
      transform: rotate(45deg);
      position: absolute;
      top: 4px;
      right: -10px;
    }

    // &::after{
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    //   border-right: solid 14px #ffffff;
    //   border-bottom: solid 14px transparent;
    //   border-top: solid 14px transparent;
    //   border-radius: 4px;
    // }
  }
  .filter-popup {
    .modal-title {
      font-size: 19px;
      font-weight: 700;
      line-height: 23px;
    }
    .modal-body {
      padding: 0px 3px;
    }
    .modal-footer {
      .btn-outline {
        font-size: 19px;
        font-weight: 700;
        line-height: 23px;
        color: #063a3f;
      }
      .btn-primary {
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        min-height: 43px;
      }
    }
    .filter-sec-parent {
      .filters-left {
        padding-top: 10px;
        .filters-b {
          margin-top: 0px;
          // padding:12px 5px;
          .filters-prop {
            font-size: 16px;
            font-weight: 600;
            line-height: 19px;
            color: #37475b;
            padding: 10.5px 0px 10.5px 16px;
            margin-bottom: 5px;
            &.filter-selected {
              position: relative;
              color: var(--primary) !important;
              &::before {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                border: 2px solid var(--primary);
                margin-right: 0px !important;
              }
            }
            &.not-selected-filter {
              &::before {
                margin-right: 0px !important;
                border: 0;
              }
            }
          }
        }
      }
      .filters-right {
        padding-right: 10px;
        .filters-dropdown-list {
          .custom-checkbox label {
            color: #999999;
          }
        }
      }
    }
  }
  .freight-message-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    .text-message {
      font-weight: 400;
      line-height: 17px;
      font-size: 14px;
      line-height: 17px;
      @include desktop {
        line-height: 19px;
        font-size: 16px;
      }
    }
    .btn {
      margin-left: 5px;
    }
  }
  .freight-message-holder {
    @include notDesktop {
      position: fixed;
      bottom: -2px;
      left: 0;
      z-index: 999;
    }
    right: 0;
    .freight-message-banner {
      padding-left: 8px;
    }
  }
  .latest-product {
    .product-counter-parent.version-2.p-counter {
      height: 100% !important;
      border-radius: 0;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border: none;

      @include desktop {
        border-radius: 4px;
        height: 35px !important;
        box-shadow: none;
        border: 1px solid var(--primary);
      }

      button {
        i {
          color: var(--dark-primary);
          @include desktop() {
            color: var(--primary);
          }
        }
      }
      .count {
        font-size: 15px;
        color: var(--dark-primary) !important;
        @include desktop() {
          color: var(--primary) !important;
          font-size: 16px;
        }
      }
    }
    .product-counter-parent.version-2.p-count {
      .count {
        color: var(--dark-primary);
        @include desktop() {
          color: var(--primary);
        }
      }
    }
    .new-add-to-cart:hover {
      .add-to-cart {
        @include desktop() {
          background-color: transparent;
          border: 1px solid var(--primary);
        }
        // .c-icon {
        //   color: var(--primary) !important;
        // }
      }
      // &.counter-wrap{
      //   .add-to-cart:hover{
      //     .p-count .count{
      //       color : var(--primary) !important;
      //     }
      //   }
      // }
      .product-counter-parent {
        .count {
          @include desktop() {
            color: var(--white-text-on-primary);
          }
        }
      }
    }
    &.cart-product-tile {
      margin-right: 12px;
      flex: 0 0 185px !important;
      min-height: 255px !important;
      margin-bottom: 15px;
      @include desktop {
        margin-right: 15px;
        margin-bottom: 0;
      }
      .top-container {
        .img-wrapper {
          height: 100px !important;
        }
      }
      .offer-holder {
        padding-left: 8px;
        margin-top: 7px;
        .sale {
          position: static;
          z-index: 1;
          background: #b43c3c;
          border-radius: 4px;
          padding: 2px 4px;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: #ffffff !important;
          margin-left: 0 !important;
        }
      }
    }
  }
  .yellow-divider {
    color: #f9d95b !important;
  }
  .secondary-b {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin: 2rem 0 3rem !important;
    .carousel-item {
      img {
        border-radius: 8px;
      }
    }
    .slick-slide {
      @include ipad {
        display: block !important;
      }
    }
  }
  .footer {
    .footer-top-section {
      padding-bottom: 185px;
      @include desktop {
        padding-bottom: 0;
      }
    }
    .footer-bottom-section {
      padding-bottom: 81px !important;
      @include desktop {
        padding-bottom: 20px !important;
      }
    }
  }
  .friend-family-banner .fnf-box,
  .friend-family-banner .pay-box {
    justify-content: flex-start;
    flex-direction: column;
    .content-wrap {
      flex: 1 1 auto;
      flex-direction: column;
      margin: 0 auto;
    }
    h2 {
      font-size: 24px;
      font-weight: 800;
      line-height: 29px;
      @include desktop {
        font-size: 32px;
        line-height: 39px;
      }
    }
    .perk-box {
      flex-grow: 1;
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        text-align: center;
        padding: 0 20px;
        @include desktop {
          color: #000;
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
    .btn {
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
      border-radius: 6px;
      padding: 10px;
      min-height: 33px;
      min-width: 112px;
      margin: 5px 0px 0.5rem;
      @include desktop {
        font-size: 18px;
        line-height: 22px;
        border-radius: 6px;
        min-height: 54px;
        min-width: 200px;
      }
    }
    .m-image {
      display: block;
      @include desktop {
        display: none;
      }
      @include ipad {
        display: none;
      }
    }
    .d-image {
      display: none;
      @include desktop {
        display: block;
      }
      @include ipad {
        display: block;
      }
    }
  }
  .friend-family-banner {
    .fnf-wrap,
    .pay-wrap {
      @include ipad {
        width: 50% !important;
      }
    }
  }
  // .delivery-cities{
  //   .default-category-section{
  //     margin-top:2rem !important;
  //   }
  // }
  .category-main-page {
    margin-bottom: 1rem;
  }
  .brandp-wrap,
  .categoryp-wrap,
  .cityp-wrap {
    .search-category-wrapper {
      padding-bottom: 12px;
      @include desktop {
        padding-bottom: 0px;
      }
    }
  }
  .brandp-wrap {
    .default-category-section {
      margin-top: 0 !important;
      margin-bottom: 2rem !important;
      @include desktop {
        // margin-top: 1rem !important;
        margin-bottom: 0 !important;
      }
    }
  }
  .categoryp-wrap,
  .cityp-wrap {
    .default-category-section {
      margin-top: 2rem !important;
      margin-bottom: 2rem !important;
    }
    .categories-b .container {
      padding: 0;
      @include desktop {
        padding: 0 15px;
      }
    }
    .shop-full-menu {
      padding-top: 0 !important;
    }
  }
  .categories-parent,
  .delivery-cities,
  .categoryp-wrap,
  .cityp-wrap {
    .default-category-section {
      padding: 0 !important;
    }
    // .container{
    //  padding:0;
    //   @include desktop {
    //     padding: 0 15px;
    //   }
    // }
    .search-category-wrapper {
      position: sticky;
      top: var(--navbar-height);
      z-index: 9;
      // background: white;
    }
  }
  .categoryp-wrap {
    .delivery-cities {
      @include desktop {
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
    }
    .shop-full-menu {
      padding-top: 0 !important;
    }
  }
  .v-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    margin-top: 1.5rem !important;
    // margin: 0 -10px;
    @include desktop {
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 24px;
    }
    @media (max-width: 1280px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }
    .latest-product {
      margin-right: 0px !important;
      margin-top: 0px !important;
    }
    @include ipad {
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
    }
    @include ipadLandscape() {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 16px;
    }
    @include ipadPro() {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 24px;
    }
  }

  .default-site-products {
    .filter-product-page {
      .product-category {
        margin-top: 40px !important;
        @include desktop {
          margin-top: 45px !important;
        }
      }
    }
    margin-top: -18px;
    @include desktop() {
      margin-top: 0px;
    }
  }
  .zipps-see-all {
    margin-top: 2rem;
    @include desktop {
      margin-top: 3rem;
    }
  }
  .category-product {
    h6 {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #616161;
      margin-top: 10px;
      @include desktop {
        font-size: 16px;
        line-height: 19px;
      }
    }
    .default-category-section {
      // @include desktop {
      //   // margin-bottom: 2.3rem !important;
      //   margin-top: 0 !important;
      // }
    }
  }
  .scroll-to-top {
    position: fixed;
    bottom: 120px;
    right: 20px;
    z-index: 1000;
    background-color: white;
    border-radius: 50%;
    height: 42px;
    width: 42px;
    border-radius: 50%;
    background-color: #f2f1f6;
    box-shadow: 0px 6.916666507720947px 12.104166030883789px 0px #00000033;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    @include desktop {
      right: 58px;
      bottom: 100px;
    }
    .icon {
      color: #37475b;
      font-size: 35px;
    }
    &.compare-available {
      bottom: 150px !important;
      @include desktop {
        bottom: 100px !important;
      }
    }
    &.filter {
      bottom: 60px;
      .icon {
        color: #37475b;
        font-size: 24px;
      }
      @include desktop {
        display: none;
      }
    }
  }
  .delivery-timing-new {
    p {
      margin: 0;
    }
    .asap-remaining {
      padding: 4px 7px 7px;
      position: relative;
      p {
        font-size: 12px;
        font-weight: 600;
        color: #e14662;
        line-height: 15px;

        span {
          color: var(--primary);
          font-variant-numeric: tabular-nums;

          font-size: 14px;
          font-weight: 700;
          line-height: 17px;
        }
      }
    }
    @include desktop {
      display: flex;
      align-items: center;

      .asap-remaining {
        // border-right: 1px solid grey;
        padding: 0px 12px;
        order: 0;
        p {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        &::after {
          content: "";
          position: absolute;
          right: 0;
          top: 0;
          height: 26px;
          width: 1px;
          background-color: #999;
          transform: translateY(-50%);
          top: 50%;
        }
      }
      .asap-on {
        order: 1;
      }
    }
  }
  .categories-in-search {
    .default-category-section {
      .h-list {
        display: grid !important;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 15px;
        .category-block {
          margin: 0 !important;
          cursor: pointer;
          &.c-selected {
            border: 1px solid var(--primary);
          }
        }
      }
    }
  }
  .how-default-works {
    img {
      height: auto;
      width: auto !important;
    }
  }
  .profile-account {
    // margin-top: var(--navbar-height);
    .profile-content-holder {
      .latest-product {
        background-color: #ffffff;
      }
    }
  }
  .location-pages {
    .default-category-section {
      margin-top: 30px;
    }
    .search-category-wrapper {
      z-index: 10;
      padding-bottom: 8px;
      @include desktop {
        padding-bottom: 0px;
      }
    }
  }
  .desktop-filter-container {
    padding: 1px 15px 6px;
    @include desktop {
      padding: 8px 15px 6px;
    }
  }
  .default-category-menu {
  }
}

.category-container {
  @include desktop() {
    &.extra-sticky {
      top: calc(-1px + var(--navbar-height));
      position: sticky;
      z-index: 101;
      background: var(--body-background-color);
      margin: 0 !important;
      // padding: 0rem 15px 6px;
      padding: 0.5rem 15px 1rem;
      text-align: center;
      max-width: 100%;
      padding-top: 20px !important;
    }
  }
}
